import { Address } from '../customer/types';

// constant types
export const UPDATE_BILLING_MODULE = 'checkout/billing/UPDATE_BILLING_MODULE';
export const UPDATE_ADDRESS = 'checkout/billing/UPDATE_ADDRESS';
export const UPDATE_COUNTRIES = 'checkout/billing/UPDATE_COUNTRIES';
export const UPDATE_PAYMENT_METHODS = 'checkout/billing/UPDATE_PAYMENT_METHODS';
export const SELECT_PAYMENT_METHOD = 'checkout/billing/SELECT_PAYMENT_METHOD';
export const UPDATE_SAVED_CARDS = 'checkout/billing/UPDATE_SAVED_CARDS';
export const UPDATE_SELECTED_SAVED_CARD = 'checkout/billing/UPDATE_SELECTED_SAVED_CARD';
export const UPDATE_REDIRECT_DESCRIPTION = 'checkout/billing/UPDATE_REDIRECT_DESCRIPTION';
export const UPDATE_PAYMENT = 'checkout/billing/UPDATE_PAYMENT';
export const UPDATE_NEW_CARD = 'checkout/billing/UPDATE_NEW_CARD';
export const UPDATE_IS_ON_EXPRESS_JOURNEY = 'checkout/billing/UPDATE_IS_ON_EXPRESS_JOURNEY';
export const UPDATE_PAYMENT_METHODS_DATA = 'checkout/billing/UPDATE_PAYMENT_METHODS_DATA';
export const UPDATE_SHOW_CARD_SELECTOR_VIEW = 'checkout/billing/UPDATE_SHOW_CARD_SELECTOR_VIEW';
export const UPDATE_DEFAULT_SAVED_CARD = 'checkout/billing/UPDATE_DEFAULT_SAVED_CARD';
export const UPDATE_GIFT_CARD_ERROR = 'checkout/billing/UPDATE_GIFT_CARD_ERROR';
export const UPDATE_STOCK_CHECK_DATA = 'checkout/billing/UPDATE_STOCK_CHECK_DATA';

// action types
interface updateBillingModule {
    type: typeof UPDATE_BILLING_MODULE,
    payload: Billing,
};

interface updateBillingAddress {
    type: typeof UPDATE_ADDRESS,
    payload: Address
};

interface updateBillingCountries {
    type: typeof UPDATE_COUNTRIES,
    payload: BillingCountry[]
};

interface updatePaymentMethods {
    type: typeof UPDATE_PAYMENT_METHODS,
    payload: {
        methods: PaymentMethods,
        customerPreferredPaymentId?: string,
    }
};

interface selectPaymentMethod {
    type: typeof SELECT_PAYMENT_METHOD,
    payload: string
};

interface updateSavedCards {
    type: typeof UPDATE_SAVED_CARDS,
    payload: SavedCard[],
};

interface updateSelectedSavedCard {
    type: typeof UPDATE_SELECTED_SAVED_CARD,
    payload: SavedCard,
};

interface updateNewCard {
    type: typeof UPDATE_NEW_CARD,
    payload: NewCard,
};

interface updateRedirectDescription {
    type: typeof UPDATE_REDIRECT_DESCRIPTION,
    payload: string,
};

interface updatePayment {
    type: typeof UPDATE_PAYMENT,
    payload: {
        orderClientID: string,
        status: string,
        isPaid: boolean,
        invoiceID: string,
    },
};
interface updateIsOnExpressPaymentJourney {
    type: typeof UPDATE_IS_ON_EXPRESS_JOURNEY,
    payload: boolean,
};

interface updateShowCardSelectorView {
    type: typeof UPDATE_SHOW_CARD_SELECTOR_VIEW,
    payload: boolean,
};

interface updatePaymentMethodsData {
    type: typeof UPDATE_PAYMENT_METHODS_DATA,
    payload: {
        paymentMethodName: string,
        data: any,
    },
};

interface updateDefaultSavedCard {
    type: typeof UPDATE_DEFAULT_SAVED_CARD,
    payload: string
};

interface updateGiftCardError {
    type: typeof UPDATE_GIFT_CARD_ERROR,
    payload: boolean,
}

interface updateStockCheckData {
    type: typeof UPDATE_STOCK_CHECK_DATA;
    payload: StockCheckData;
}


export type BillingActionTypes = updateBillingModule | updateBillingAddress | updateBillingCountries
                                | updatePaymentMethods | selectPaymentMethod | updateSavedCards
                                | updatePayment | updateRedirectDescription | updateSelectedSavedCard
                                | updateNewCard | updateIsOnExpressPaymentJourney
                                | updatePaymentMethodsData | updateShowCardSelectorView | updateDefaultSavedCard
                                | updateGiftCardError | updateStockCheckData;

export type BillingCountry = {
    code: string,
    name: string,
    default: boolean,
    requiresState: boolean,
    pcaPredictSupported: boolean
};

export type PaymentMethod = {
    name: string,
    id: string,
    label: string,
    description?: React.ReactNode | string,
    logo?: React.ReactNode | string, // string for URL
    active: boolean,
    provider?: string,
    method?: string,
    additionalData?: {
        [key: string]: any
    },
    clickAndCollectSupported?: boolean,
};

export type PaymentMethods = {
    payNow: PaymentMethod[],
    payLater: PaymentMethod[],
    express: PaymentMethod[],
};

export type SavedCard = {
    id: string,
    name: string,
    brand: string,
    lastFour: string,
    holderName: string,
    expiryMonth: number,
    expiryYear: number,
    isPreferredSavedCard? : boolean
};

export type NewCard = {
    brand: string,
    lastFour: string,
    holderName: string,
    expiryDate: string,
}

export type Payment = {
    orderClientID: string,
    status: string,
    isPaid: boolean,
    invoiceID: string,
}

export type PaymentMethodAdyenCardData = {
    adyenData: any,
    isValid: boolean,
    shouldSaveCard: boolean,
};

export type StockCheckData = {
    paymentID?: string;
};

export interface Billing {
    address: Address,
    countries: BillingCountry[],
    paymentMethods: PaymentMethods,
    selectedPaymentMethod: PaymentMethod,
    savedCards: SavedCard[],
    selectedSavedCard: SavedCard,
    newCard: NewCard,
    redirectDescription: string,
    payment: Payment,
    isOnExpressJourney: boolean,
    showCardSelectorView: boolean,
    giftCardError: boolean,
    paymentMethodsData: {
        adyenCard: PaymentMethodAdyenCardData,
    },
    productStockCheck: StockCheckData;
};
