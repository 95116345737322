import merge from 'deepmerge';
import { SecurityOutline } from '../components/Icon';
import jdTheme, { CustomTheme } from '.';

const theme: CustomTheme = {
    config: {
        displayName: "PerrySport",

        headerLogo: '/Logo-Perrysport-Navigation.png',
        headerHeight: '64px',
        headerLogoSize: {height: '40px'},
        headerShowLogo: true,
        headerBackgroundColor: 'white',

        fontSrc: 'https://fonts.googleapis.com/css2?family=Roboto&display=swap',

        icons: {
            securityIcon: SecurityOutline,
        },
        titleFontWeight: 'bold',
        stepFontWeight: 'bold',
    },

    global: {
        font: {
            family: '"Roboto", sans-serif',
        },

    },

    button: {
        border: {
            radius: '0px'
        },
        extend: 'height: 48px; font-weight: bold',
    },

    radioButton: {
        font: {
            weight: 'bold',
        }
    },

};

const perrySportTheme: CustomTheme = merge(jdTheme, theme);

export default perrySportTheme;
