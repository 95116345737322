import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Box, Text,  ResponsiveContext } from "grommet";
import { ThemeContext } from 'styled-components';
import { CustomTheme } from '../../theme';
import Separator from '../Separator/Separator';

function Step({ title, children, icon, showSeparator = true, showBorder = true, showPadding = true }: StepProps) {
    const theme: CustomTheme = useContext(ThemeContext);
    const size = React.useContext(ResponsiveContext);
    const isDesktop = size !== 'small';
    const renderBorder = showBorder && isDesktop  && (theme?.config?.stepBorder ?? false) ;
    
    return (
        <Box fill="horizontal" flex={false}>
            <Box
                align="start"
                direction="column"
                pad= {{
                    vertical: showPadding ? (theme?.config?.stepSeparatorMargins?.vertical ?? "large") : '',
                    horizontal: showPadding ? (theme?.config?.stepSeparatorMargins?.horizontal ?? "medium") : '',
                }}
                background="white"
                border= { renderBorder }
                fill="horizontal"
                flex={false}
            >
                {/* Title and icon */}
                {(title || icon) ? 
                    <Box
                        fill="horizontal"
                        align="start"
                        margin={{bottom: 'xsmall'}}
                        direction="row"
                    >
                        
                        <Box fill="horizontal"
                             style={{
                                 'textTransform': theme?.config?.stepTextTransform ?? "unset",
                                 'letterSpacing': theme?.config?.textLetterSpacing ?? "normal",
                             }}
                        >
                            <Text
                                as="h3"
                                margin="none"
                                size={theme?.config?.stepFontSize ?? 'medium'}
                                weight={theme?.config?.stepFontWeight ?? 'bold'}
                            >{title}</Text>
                        </Box>
                        <Box alignSelf="center">
                            {icon}
                        </Box>
                    </Box> : null 
                }

                {/* Component(s) to display */}
                <Box 
                    alignSelf="start" 
                    direction="row-responsive" 
                    fill="horizontal"
                >
                    {children}
                </Box>
            </Box>
            {/* UI Separator */}
            { showSeparator &&
            <Separator
                height={isDesktop
                    ? theme?.config?.stepSeparatorHeight ?? '16px'
                    : theme?.config?.stepSeparatorHeightMobile ?? '16px'}
                margin={{horizontal: theme?.config?.stepSeparatorHorizontalMargin ?? '0px'}}
                color= {isDesktop
                    ? (theme?.config?.desktop?.desktopStepSeparatorColour?? 'light-1' )
                    :  theme?.config?.stepSeparatorColour?? 'light-1'}
            />
            }
        </Box>
    );
}

type StepProps = {
    title?: string,
    children: React.ReactNode,
    icon?: React.ReactNode,
    showSeparator?: boolean,
    showBorder?: boolean,
    size? : string,
    showPadding?: boolean
}

Step.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node.isRequired,
    icon: PropTypes.node,
    showSeparator: PropTypes.bool,
    showBorder: PropTypes.bool,
    showPadding: PropTypes.bool
}

export default Step;
