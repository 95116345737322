import React, { useContext, useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { useSelector } from 'react-redux';
import { Text, Box, ResponsiveContext } from 'grommet';
import { ThemeContext } from 'styled-components';
import { RootState } from '../../store/reducers';
import { CustomTheme } from '../../theme';
import { getProductsSubtotal, getTotal, getGiftCardSubtotal, getAppliedGiftCards, getTotalCartDiscounts, getProductSubtotalBeforeDiscounts, getTotalProductDiscounts } from '../../modules/cart';
import { getSelectedMethod } from '../../modules/delivery';
import { getOrder } from '../../modules/order';
import { translate } from '../../modules/translation';
import Currency from '../Currency/Currency';
import Separator from '../Separator/Separator';
import { getMarketingDetails, getMarketingEmailOptout, getMarketingSmsOptout, getShowMarketingCheckboxes } from "../../modules/config";
import store from '../../store';
import { getCustomerPreferences, updateMarketingOptIns } from '../../modules/customer';
import SquareCheckBox from '../SquareCheckBox/SquareCheckBox';
import { getIsOnExpressJourney } from '../../modules/billing';
import { CustomerPreferences } from '../../modules/customer/types';


function TotalSummary({ dataSource = 'cart', disableEmailAndSmsOptIn }: TotalSummaryProps) {
    const theme: CustomTheme = useContext(ThemeContext);
    const cart = useSelector((state: RootState) => state.cart);
    const delivery = useSelector((state: RootState) => state.delivery);
    const billing = useSelector((state: RootState) => state.billing);
    const orderState = useSelector((state: RootState) => state.order);
    const translation = useSelector((state: RootState) => state.translation);
    const config = useSelector((state: RootState) => state.config);
    const customer = useSelector((state: RootState) => state.customer);
    const expressPayment = getIsOnExpressJourney(billing);
    const initialEmailOptIn = getMarketingEmailOptout(config);
    const initialSmsOptIn = getMarketingSmsOptout(config);
    const customerPreferences = getCustomerPreferences(customer);
    const [isEmailOptInTicked, setIsEmailOptInTicked] = useState(initialEmailOptIn);
    const [isSmsOptInTicked, setIsSmsOptInTicked] = React.useState(initialSmsOptIn);

    const order = getOrder(orderState);
    const subTotal = dataSource === 'order'
        ? order.productsSubtotal
        : getProductSubtotalBeforeDiscounts(cart);
    const discount = dataSource === 'order'
        ? getTotalCartDiscounts(cart) //TODO: Investigate discounts from order object.
        : getTotalProductDiscounts(cart);
    const giftCardSubtotal = dataSource === 'order'
        ? order.giftcardsSubtotal
        : getGiftCardSubtotal(cart);

    const selectedMethod = getSelectedMethod(delivery);
    const isDeliveryMethodSelected = Object.keys(selectedMethod).length > 0;

    const cartTotal = isDeliveryMethodSelected
        ? getTotal(cart)
        : getProductsSubtotal(cart);

    const total = dataSource === 'order'
        ? order.balanceToPay
        : cartTotal;
    const deliverySubtotal = dataSource === 'order'
        ? order.deliveryTotal.amount
        : selectedMethod?.price?.amount;

    const isSeparatorAbove = theme?.config?.summarySeparatorIsAbove ?? false;
    const isZeroPayment = cart.balanceToPay.amount === "0.00" && cart.giftcardsApplied?.length;
    const totalAmount = isZeroPayment ? cart.balanceToPay.amount : total.amount;

    // UI
    const displayedSubtotal = <Currency amount={subTotal.amount} currency={subTotal.currency} />
    const displayedTotal = <Currency amount={totalAmount} currency={total.currency} />
    const displayedDiscount = discount.amount !== '0.00' ? <Currency amount={`-${discount.amount}`} currency={discount.currency} /> : null;
    const displayedDeliveryPrice = dataSource === 'order'
        ? <Currency amount={order.deliveryTotal.amount} currency={order.deliveryTotal.currency} />
        : (isDeliveryMethodSelected ? <Currency amount={selectedMethod.price.amount} currency={selectedMethod.price.currency} /> : null);
    const displayedDeliveryMethodName = dataSource === 'order'
        ? order.deliveryMethod.name
        : selectedMethod.displayName;
    const summarySeparator = <Separator margin={theme?.config?.summarySeparatorMargins ?? { top: '7px', bottom: 'large' }} />;
    const giftCards = getAppliedGiftCards(cart);
    const discountTextColour = theme?.config?.addColorToDiscounts ? (theme?.global?.colors?.red ?? 'red') : '';
    const showMarketingCheckboxes = getShowMarketingCheckboxes(config);
    const marketingDetails = getMarketingDetails(config);
    const size = React.useContext(ResponsiveContext);
    const isDesktop = size !== 'small';

    const strings = {
        subtotal: translate(translation, 'subtotal', 'Subtotal'),
        free: translate(translation, 'free', 'Free'),
        eGiftCard: translate(translation, 'eGiftCard', 'eGift Card'),
        discountSavings: translate(translation, 'discountSavings', 'Discount & Savings'),
        total: translate(translation, 'total', 'Total'),
        excludingDelivery: translate(translation, 'excludingDelivery', 'excluding delivery'),
    };

    useEffect(() => {
     store.dispatch(updateMarketingOptIns({
        ...customer.marketingOptIns,
         emailOptIn:initialEmailOptIn,
         smsOptIn:initialSmsOptIn
        }));
         // eslint-disable-next-line
    },[])
    return (
        <>
            {isSeparatorAbove && summarySeparator}
            <Box direction="row" justify="between" margin={{ top: 'medium' }}>
                <Box direction="row" align="baseline"><Text>{strings.subtotal}</Text></Box>
                <Box flex={false}><Text>{displayedSubtotal}</Text></Box>
            </Box>
            {!isSeparatorAbove && summarySeparator}

            {/* Delivery Method */}
            {isDeliveryMethodSelected &&
                <>
                    {isSeparatorAbove && summarySeparator}
                    <Box direction="row" justify="between">
                        <Box><Text>{displayedDeliveryMethodName}</Text></Box>
                        <Box flex={false}>{deliverySubtotal === '0.00' ? <Text>{strings.free}</Text> : <Text>{displayedDeliveryPrice}</Text>}</Box>
                    </Box>
                    {!isSeparatorAbove && summarySeparator}
                </>
            }
            {giftCardSubtotal.amount !== '0.00' &&
                <>
                    {isSeparatorAbove && summarySeparator}
                    {giftCards?.map((cards, index) => (
                        <Box key={index} >
                            <Box direction="row" justify="between">
                                <Text>{strings.eGiftCard}</Text>
                                <Text
                                    size={theme?.config?.summaryTotalFontSize ?? 'medium'}
                                    weight={theme?.config?.titleFontWeight ?? 'bold'}
                                >
                                    <Currency amount={`-${cards.amount.amount}`} currency={giftCardSubtotal.currency} />
                                </Text>
                            </Box>
                            {index < giftCards.length - 1 && summarySeparator}
                        </Box>
                    ))}
                    {!isSeparatorAbove && summarySeparator}
                </>
            }
            {discount.amount !== '0.00' &&
                <>
                    {isSeparatorAbove && summarySeparator}
                    <Box direction="row" justify="between">
                        <Box direction="row" align="baseline">
                            <Text color={discountTextColour}>{strings.discountSavings}</Text>
                        </Box>
                        <Box flex={false}>
                            <Text
                                size={theme?.config?.summaryTotalFontSize ?? 'medium'}
                                color={discountTextColour}
                            >
                                {displayedDiscount}
                            </Text>
                        </Box>
                    </Box>
                    {!isSeparatorAbove && summarySeparator}
                </>
            }
            {isSeparatorAbove && summarySeparator}
            <Box direction="row" justify="between">
                <Box direction="row" align="baseline">
                    <Text
                        size={theme?.config?.summaryTotalFontSize ?? 'medium'}
                        weight={theme?.config?.titleFontWeight ?? 'bold'}
                    >
                        {strings.total} {!isDeliveryMethodSelected &&
                            <Text size={'xsmall'} weight={'normal'}>({strings.excludingDelivery})</Text>
                        }
                    </Text>
                </Box>
                <Box flex={false}><Text size={theme?.config?.summaryTotalFontSize ?? 'medium'} weight={theme?.config?.titleFontWeight ?? 'bold'}>{displayedTotal}</Text></Box>
            </Box>
            {!isSeparatorAbove && summarySeparator}
            {showMarketingCheckboxes && config.nike_connected && (customer.isGuest || (!customer.isGuest &&  Object.keys(customerPreferences as CustomerPreferences).length === 0)) && !expressPayment && !order.ID &&
                <Box
                    fill="horizontal"
                    pad={{
                        top: isDesktop ? undefined : 'medium',
                        bottom: isDesktop ? 'xsmall' : 'small',
                    }}
                >
                    <Text size="small" margin={{ bottom: 'small' }}> {marketingDetails.text} </Text>
                    <SquareCheckBox
                        checked={isEmailOptInTicked}
                        name={marketingDetails.emailLabel}
                        disabled={disableEmailAndSmsOptIn}
                        id="squareCheckBox"
                        onChange={async (event) => {
                            setIsEmailOptInTicked(event.target.checked);
                            store.dispatch(updateMarketingOptIns({ emailOptIn: event.target.checked }))
                        }}
                    />
                    <SquareCheckBox
                        checked={isSmsOptInTicked}
                        name={marketingDetails.smsLabel}
                        disabled={disableEmailAndSmsOptIn}
                        id="squareCheckBox"
                        onChange={async (event) => {
                            setIsSmsOptInTicked(event.target.checked);
                            store.dispatch(updateMarketingOptIns({ smsOptIn: event.target.checked }))
                        }}
                    />
                </Box>
            }
        </>
    );
}

type TotalSummaryProps = {
    dataSource?: 'cart' | 'order',
    disableEmailAndSmsOptIn?: boolean
};

TotalSummary.propTypes = {
    dataSource: PropTypes.string,
    disableEmailAndSmsOptIn: PropTypes.bool
};


export default TotalSummary;
