import { RootState } from '../store/reducers';
import adaptor from './adaptors';

export interface AppConfig {
    [key: string]: any
} 


export interface ConfigService {
    getAll: (store: string) => Promise<AppConfig>
}

function init(state : RootState) {
    const service = adaptor('firebase', 'config', state) as ConfigService;
    return service;
}

export default init;
