import mesh from './mesh';
import meshPayments from './meshPayments';
import firebase from './firebase';
import trackers from './trackers';
import sessions from './sessions';
import language from './language';
import { CustomerService } from '../customers';
import { CartService } from '../carts';
import { DeliveryCountriesService } from '../deliveryCountries';
import { BillingCountriesService } from '../billingCountries';
import { PagesService } from '../pages';
import { AddressesService } from '../addresses';
import { OrdersService } from '../orders';
import { PaymentMeshService, PaymentMeshPaymentsService, HostedPaymentService } from '../payments';
import { EnsightenService } from '../ensighten/ensighten';
import { ConfigService } from '../config';
import { SessionService } from '../session';
import { LanguageFrameworksService } from '../language';
import { RootState } from '../../store/reducers';


export type AdaptorsTypes = 'mesh'|'meshPayments'|'firebase'|'trackers'|'sessions'|'language';

export type Service = CustomerService | CartService | DeliveryCountriesService | BillingCountriesService | PagesService | AddressesService | PaymentMeshService | PaymentMeshPaymentsService | HostedPaymentService | ConfigService | EnsightenService | OrdersService | SessionService | LanguageFrameworksService;

interface Adaptor {
    [service: string]: Service
}

interface AdaptorsMap {
    [adaptorKey: string]: (state: RootState) => Adaptor
}

// a map of all adaptors
const adaptors: AdaptorsMap = {
    mesh,
    meshPayments,
    firebase,
    trackers,
    sessions,
    language,
}

/**
 * @description Loads in the correct adaptor method if available. This adaptor layer
 * is here to act as a 'plug-in' layer between our front end 'services' and the 
 * SDK's we may use. For example if we want to migrate payments from using the mesh sdk to 
 *  a new 'payments' service then you can add a new adaptor which will map the new SDK
 * logic back to our existing payment logic. This means our payment service can stay the same, 
 * all our code that uses the payment service can stay the same and we can easily switch 
 * between the old and new adaptor. 
 * 
 * 
 * @param type 
 * @param service 
 */
function adaptor(type: AdaptorsTypes, service: string, state: RootState): Service {
    if (!adaptors[type]) {
        throw new Error("Adaptor provided does not exist or has not been defined.")
    }
    const adaptor = adaptors[type](state);
    if (!adaptor[service]) {
        throw new Error("Adaptor does not expose the provided service.")
    }
    return adaptor[service];
}

export default adaptor;
