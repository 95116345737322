import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from 'grommet';
import { CircleInformation } from "grommet-icons";
import { ThemeContext } from 'styled-components';
import { CustomTheme } from '../../theme';
import styled from "styled-components";

const TextUnderlined = styled(Text)`
    text-decoration: underline;
`;

function InfoBox({ icon, background, text="", actionButton=null }: InfoBoxProps) {
    const theme: CustomTheme = useContext(ThemeContext);

    const isMultilines = Array.isArray(text);

    let oneLine = '';
    let multilines: string[] = [];
    if (isMultilines) multilines = text as string[];
    else oneLine = text as string;

    return (
        <Box
            direction="row"
            background={background || "light-3"}
            alignContent="start"
        >
            <Box pad={{right: 'small'}} flex={false}>
                {icon ||
                    <CircleInformation size="small" />
                }
            </Box>

            <Box>
                {
                    isMultilines
                        ?
                            multilines.map((line, index) => (
                                <Text
                                    key={index}
                                    size="small"
                                    margin={{bottom: index !== multilines.length-1 ?'xsmall' : undefined}}
                                >
                                    {line}
                                </Text>
                            ))
                        :
                            <Text size="small">
                                {oneLine}
                            </Text>
                }
            </Box>

            {/* Only render the action button if the param is set */}
            { actionButton &&
                <Box pad={{left: 'xsmall'}} onClick={actionButton.callback} align="end" flex="grow">
                    <TextUnderlined size="small" weight={theme?.config?.formFieldLabelWeight ?? 500}>
                        {actionButton.label}
                    </TextUnderlined>
                </Box>
            }
        </Box>
    );
}

type InfoBoxProps = {
    icon?: React.ReactNode,
    background?: string,
    text?: string | string[],
    actionButton?: {
        label: string,
        callback: () => void,
    } | null
};

InfoBox.propTypes = {
    icon: PropTypes.node,
    background: PropTypes.string,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    actionButton: PropTypes.shape({
        label: PropTypes.string,
        callback: PropTypes.func
    })
};


export default InfoBox;
