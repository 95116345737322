// import axios from 'axios';
import { AppThunk } from '../thunk';
import {
    Billing,
    BillingActionTypes,
    BillingCountry,
    NewCard,
    Payment,
    PaymentMethod as PaymentMethodType,
    PaymentMethods,
    SavedCard,
    PaymentMethodAdyenCardData,
    StockCheckData,
    SELECT_PAYMENT_METHOD,
    UPDATE_ADDRESS,
    UPDATE_BILLING_MODULE,
    UPDATE_COUNTRIES,
    UPDATE_DEFAULT_SAVED_CARD,
    UPDATE_NEW_CARD,
    UPDATE_PAYMENT,
    UPDATE_PAYMENT_METHODS,
    UPDATE_REDIRECT_DESCRIPTION,
    UPDATE_SAVED_CARDS,
    UPDATE_SELECTED_SAVED_CARD,
    UPDATE_IS_ON_EXPRESS_JOURNEY,
    UPDATE_PAYMENT_METHODS_DATA,
    UPDATE_SHOW_CARD_SELECTOR_VIEW,
    UPDATE_GIFT_CARD_ERROR,
    UPDATE_STOCK_CHECK_DATA
} from './types';
import { Address } from '../customer/types';
import { carts, billingCountries, payments, tracking } from '../../services';
import { AppResponse } from '../../services/response';
import { getCustomerPreferences } from '../customer';
import { AxiosError } from 'axios/index';
import { ErrorLoggerEvent } from '../../services/payment-sdk/models';
import { updateDialog, updateNotification } from '../../modules/notification';
import newRelicData from '../../newrelic';
import { getCart } from "../cart";

export const initialState: Billing = {
    address: {} as Address,
    countries: [] as BillingCountry[],
    paymentMethods: {
        payNow: [] as PaymentMethodType[],
        payLater: [] as PaymentMethodType[],
        express: [] as PaymentMethodType[],
    },
    selectedPaymentMethod: {} as PaymentMethodType,
    savedCards: [] as SavedCard[],
    selectedSavedCard: {} as SavedCard,
    newCard: {} as NewCard,
    redirectDescription: '',
    payment: {
        orderClientID: '',
        status: '',
        isPaid: false,
        invoiceID: '',
    } as Payment,
    isOnExpressJourney: false,
    showCardSelectorView: false,
    giftCardError: false,
    paymentMethodsData: {
        adyenCard: {
            adyenData: {},
            isValid: false,
            shouldSaveCard: false,
        }
    },
    productStockCheck: {} as StockCheckData,
};

// Reducer
export default function reducer(state = initialState, action: BillingActionTypes): Billing {
    switch (action.type) {
        case UPDATE_BILLING_MODULE:
            return {...action.payload};
        
        case UPDATE_ADDRESS:
            return {...state, address: action.payload};
        
        case UPDATE_COUNTRIES:
            return {...state, countries: action.payload};
        
        case UPDATE_PAYMENT_METHODS:
            const preferredMethod = action.payload.customerPreferredPaymentId;
            const allMethods = [
                ...action.payload.methods.payNow,
                ...action.payload.methods.payLater,
                ...action.payload.methods.express,
            ];

            // If the customer has a preferred method, preselect this one
            if (preferredMethod) {
                const selectedPaymentMethod = allMethods.find(method => method.id === preferredMethod);
                if (selectedPaymentMethod) {
                    return {
                        ...state,
                        paymentMethods: putSelectedPaymentMethodOnTop(action.payload.methods, preferredMethod),
                        selectedPaymentMethod: selectedPaymentMethod,
                    };
                }
            }

            return {...state, paymentMethods: action.payload.methods};
        
        case SELECT_PAYMENT_METHOD:
            // Empty payload: the component wants to clear the selection.
            if (action.payload === '') return { ...state, selectedPaymentMethod: {} as PaymentMethodType};
            const methodWithId = getPaymentMethodByMethodId(state, action.payload);
            if (methodWithId) return {...state, selectedPaymentMethod: methodWithId};
            console.error(`${SELECT_PAYMENT_METHOD} No payment method found with ID ${action.payload}, state unchanged`);
            return state;

        case UPDATE_SAVED_CARDS:
            return {...state, savedCards: action.payload};

        case UPDATE_SELECTED_SAVED_CARD:
            return {...state, selectedSavedCard: action.payload};

        case UPDATE_NEW_CARD:
            return {...state, newCard: action.payload};
        
        case UPDATE_REDIRECT_DESCRIPTION:
            return {...state, redirectDescription: action.payload};

        case UPDATE_PAYMENT: {
            const updatedPayment = {
                orderClientID: action.payload.orderClientID,
                status: action.payload.status,
                isPaid: action.payload.isPaid,
                invoiceID: action.payload.invoiceID,
            };
            return {
                ...state,
                payment: updatedPayment,
            };
        }

        case UPDATE_IS_ON_EXPRESS_JOURNEY: {
            return {...state, isOnExpressJourney: action.payload};
        }
        
        case UPDATE_SHOW_CARD_SELECTOR_VIEW: {
            return {...state, showCardSelectorView: action.payload};
        }

        case UPDATE_GIFT_CARD_ERROR: {
            return {...state, giftCardError: action.payload};
        }

        case UPDATE_PAYMENT_METHODS_DATA: {
            return {
                ...state,
                paymentMethodsData: {
                    ...state.paymentMethodsData,
                    [action.payload.paymentMethodName]: action.payload.data,
                },
            }
        }

        case UPDATE_DEFAULT_SAVED_CARD: {
            const cardId = action.payload;
            const updatedSavedCardList = state.savedCards.map(card => {
                if (card.id === cardId) {
                    card = { ...card, isPreferredSavedCard: true }
                } else {
                    card = { ...card, isPreferredSavedCard: false }
                }
                return card;
            });

            return {
                ...state,
                savedCards: updatedSavedCardList
            }
        }
        case UPDATE_STOCK_CHECK_DATA:
            return { ...state, productStockCheck: action.payload };
        default:
            return state;
    }
}

// Selectors
export const getBillingAddress = (state: Billing) => state.address;
export const getBillingCountries = (state: Billing) => state.countries;
export const getPaymentMethods = (state: Billing) => state.paymentMethods;
export const getSelectedPaymentMethod = (state: Billing) => state.selectedPaymentMethod;
export const getPaymentMethodByMethodId = (state: Billing, methodId: string) => {
    const allMethods = [
        ...state.paymentMethods.payNow,
        ...state.paymentMethods.payLater,
        ...state.paymentMethods.express,
    ];
    
    return allMethods.find(method => method.id === methodId);
};

export const isUnionPayEnabled = (state: Billing) => {
    const allMethods = [
        ...state.paymentMethods.payNow,
        ...state.paymentMethods.payLater,
        ...state.paymentMethods.express,
    ];  
    const unionPayMethod = allMethods.find(method => method.id === 'UNIONPAY');
    return unionPayMethod ? unionPayMethod.active : false;
}

export const getSavedCards = (state: Billing) => state.savedCards;
export const getSavedCardById = (state: Billing, cardId: string) => {
    return state.savedCards.find(card => card.id === cardId);
};
export const getSelectedSavedCard = (state: Billing) => state.selectedSavedCard;
export const getNewCard = (state: Billing) => state.newCard;
export const getPayment = (state: Billing) => state.payment;
export const getRedirectDescription = (state: Billing) => state.redirectDescription;
export const getIsOnExpressJourney = (state: Billing) => state.isOnExpressJourney;
export const getPaymentMethodsData = (state: Billing) => state.paymentMethodsData;
export const getShowCardSelectorView = (state: Billing) => state.showCardSelectorView;
export const getDefaultSavedCardId = (state: Billing) => {
    return state.savedCards.find(card => card.isPreferredSavedCard === true)?.id;
};
export const getHasGiftCardError = (state: Billing) => state.giftCardError;
export const getStockCheckPayment = (state: Billing) => state.productStockCheck;

// Action Creators
export function updateBillingModule(billingState: Billing): BillingActionTypes {
    return {
        type: UPDATE_BILLING_MODULE,
        payload: billingState,
    };
};

export function updateBillingAddress(address: Address): BillingActionTypes {
    return {
        type: UPDATE_ADDRESS,
        payload: address
    };
};

export function updateBillingCountries(countries: BillingCountry[]): BillingActionTypes {
    return {
        type: UPDATE_COUNTRIES,
        payload: countries
    };
};

export function updatePaymentMethods(methods: PaymentMethods, customerPreferredPaymentId?: string): BillingActionTypes {
    return {
        type: UPDATE_PAYMENT_METHODS,
        payload: {
            methods,
            customerPreferredPaymentId,
        }
    };
};

export function selectPaymentMethod(methodId: string): BillingActionTypes {
    return {
        type: SELECT_PAYMENT_METHOD,
        payload: methodId
    };
};

export function updateSavedCards(cards: SavedCard[]): BillingActionTypes {
    return {
        type: UPDATE_SAVED_CARDS,
        payload: cards
    };
};

export function updateSelectedSavedCard(card: SavedCard): BillingActionTypes {
    return {
        type: UPDATE_SELECTED_SAVED_CARD,
        payload: card
    };
};

export function updateNewCard(card: NewCard): BillingActionTypes {
    return {
        type: UPDATE_NEW_CARD,
        payload: card
    };
};

export function updateDefaultSavedCard(cardId: string): BillingActionTypes {
    return {
        type: UPDATE_DEFAULT_SAVED_CARD,
        payload: cardId
    };
}

export function updateRedirectDescription(description: string): BillingActionTypes {
    return {
        type: UPDATE_REDIRECT_DESCRIPTION,
        payload: description,
    };
};

export function updatePayment(orderClientID: string, status: string, isPaid: boolean, invoiceID?: string): BillingActionTypes {
    return {
        type: UPDATE_PAYMENT,
        payload: {
            orderClientID,
            status,
            isPaid,
            invoiceID: invoiceID ?? '',
        },
    };
};

export function updateIsOnExpressJourney(isExpress: boolean): BillingActionTypes {
    return {
        type: UPDATE_IS_ON_EXPRESS_JOURNEY,
        payload: isExpress,
    };
};

export function updateShowCardSelectorView(show: boolean): BillingActionTypes {
    return {
        type: UPDATE_SHOW_CARD_SELECTOR_VIEW,
        payload: show,
    };
};

export function updateGiftCardError(giftCardError: boolean): BillingActionTypes {
    return {
        type: UPDATE_GIFT_CARD_ERROR,
        payload: giftCardError,
    };
}

export function updatePaymentMethodsData<
    T extends PaymentMethodDataType
>(paymentMethodName: T, data: PaymentMethodData<T>): BillingActionTypes {
    return {
        type: UPDATE_PAYMENT_METHODS_DATA,
        payload: {paymentMethodName, data},
    };
};

export function updateStockCheckData(stockData: StockCheckData): BillingActionTypes {
    return {
        type: UPDATE_STOCK_CHECK_DATA,
        payload: stockData,
    };
}


type PaymentMethodDataType = 'adyenCard';
type PaymentMethodData<T> = T extends 'adyenCard' ? PaymentMethodAdyenCardData : never;


// Side effects
export function loadBillingCountries(): AppThunk<Promise<AppResponse<BillingCountry[]>>> {
    return async dispatch => {
        try {
            const countries = await billingCountries().getBillingCountries();
            dispatch(updateBillingCountries(countries.data));
            return countries;
        } catch(e){
            console.error(`Billing countries response is invalid`);
            newRelicData({ actionName: 'billing', function: 'loadBillingCountries', message: (e as Error).message });
            return Promise.reject();
        }
    }
};

/**
 * Load payment methods from the external service into Redux, and init them.
 * @param cartId Cart ID
 * @param methodIdToInit If provided, only the method with this ID will be initialised (useful for the express payment journey), otherwise all methods will be initialised.
 */
export function loadPaymentMethods(cartId: string, methodIdToInit?: string ): AppThunk<void> {
    return async (dispatch, getState) => {
        const preferences = getCustomerPreferences(getState().customer);

        if (cartId) {
            const paymentMethods = await carts().getCartPaymentMethods(cartId);
            if (paymentMethods.data) {
                const filteredMethods = await filterAndInitialiseMethods(paymentMethods.data, methodIdToInit);
                let prefPaymentMethod = undefined;
                if(preferences?.paymentMethodPreference?.value) {
                    try {
                        prefPaymentMethod = JSON.parse(preferences.paymentMethodPreference.value).method
                    } catch (error) {
                        console.warn("The payment method preference couldn't be parsed", error);
                    }
                }
                dispatch(updatePaymentMethods(filteredMethods, prefPaymentMethod ));
            }
            else {
                console.error(`cart error`);
            }
        }
    };
};

/**
 * Filter out payment methods that we get from mesh as "active" but that can't be used according to the payment module.
 * Initialise the methods that pass this filter.
 * @param methods List of payment methods coming from Mesh
 * @param methodIdToInit If provided, only the method with this ID will be initialised.
 */
async function filterAndInitialiseMethods(methods: PaymentMethods, methodIdToInit?: string,) {
    // Filter a simple array of methods
    async function filterMethodsArray(methods: PaymentMethodType[]) {
        const methodsToRender = [] as PaymentMethodType[];
        let isSupported: boolean|undefined = false;

        for (let i = 0 ; i < methods.length ; i++) {
            let paymentMethod = methods[i].id;
            if(methods[0]?.additionalData?.hostedPaymentGateway === 'MolPay' &&  methods[i].id === 'CARD') {
                paymentMethod = 'molpaycard';
            }

            if(methods[i].id === 'UNIONPAY' && methods[i].active) {
                paymentMethod = 'card';
            }
            
            const paymentModule = await payments().getPaymentMethodModule(paymentMethod);
            isSupported = await paymentModule?.checkPaymentMethodSupported();
            if (isSupported) {
                if (methodIdToInit === undefined || methodIdToInit === methods[i].id) {
                    await paymentModule?.initialise(methods[i].additionalData);
                }
                methodsToRender.push(methods[i]);
            }
        }
        return methodsToRender;
    }

    const filteredMethods = {
        payNow: [] as PaymentMethodType[],
        payLater: [] as PaymentMethodType[],
        express: [] as PaymentMethodType[],
    } as PaymentMethods;
    filteredMethods.payNow = await filterMethodsArray(methods.payNow);
    filteredMethods.payLater = await filterMethodsArray(methods.payLater);
    filteredMethods.express = await filterMethodsArray(methods.express);
    return filteredMethods;
}

/**
 * Select a payment method in Redux. Send a tracking event for it.
 * @param methodId
 */
export function selectPaymentMethodAndTrack(methodId: string): AppThunk<void> {
    return async (dispatch, getState) => {
        dispatch(selectPaymentMethod(methodId));
        const method = getPaymentMethodByMethodId(getState().billing, methodId);
        const cart = getCart(getState().cart)
        if (method) tracking().trackSelectPaymentMethod(method, cart);
    };
};

/**
 * Check selected product stock and add stock response to the Redux store
 * @param cartId
 */
export function stockCheck(cartId: string): AppThunk<void> {
    return async (dispatch, getState) => {
        const selectedPaymentMethod = getSelectedPaymentMethod(getState().billing);

        if (cartId && Object.keys(selectedPaymentMethod).length > 0) {
            const { methodId, provider, method } = getAdditionalPaymentServiceDetails(selectedPaymentMethod);
            const paymentMethods = getPaymentMethods(getState().billing);
            const isExpress =  paymentMethods?.express?.find(item => item.id === methodId) ? true : false;
            if(provider && method) {
                try {
                    const stockResponse = await payments().prePaymentStockCheck(isExpress, provider, method, {ID: cartId});
                    if (stockResponse?.data?.paymentID) {
                        const paymentID = { paymentID: stockResponse.data.paymentID };
                        dispatch(updateStockCheckData(paymentID));
                        newRelicData({ actionName: 'billing', function: 'stockCheck', message: `Payment id received from stock check ${paymentID}`});
                    } else {
                        newRelicData({ actionName: 'billing', function: 'stockCheck', message: 'Error in stock check payment id not received' });
                    }
                }
                catch (error) {
                    const errorResponse: any = (error as AxiosError<ErrorLoggerEvent>).response?.data;
                    if (errorResponse) {
                        newRelicData({ actionName: 'billing', function: 'stockCheck', message: `Received error code: ${errorResponse.InternalCode}, message: ${errorResponse.ErrorMessage}`});
                        // Handle postPrePaymentCheck known errors
                        switch (errorResponse.InternalCode) {
                            case "CART_LOCK_06": // Out of stock or Fluent error
                            case "STOCK_3": // Out of stock error
                                dispatch(updateDialog('out_of_stock_return_to_cart'));
                                break;
                            default: // Other error from the postPrePaymentCheck error system
                                dispatch(updateNotification('payment_service_error', {
                                    code: errorResponse.ErrorCode,
                                    message: errorResponse.ErrorMessage,
                                }));
                        }
                    }
                }
            } else {
                console.error(`Something went wrong in Payment service details!`);
                newRelicData({ actionName: 'billing', function: 'stockCheck', message: 'Something went wrong in Payment service details' });
            }
        }
    };
}


// Helper functions
/**
 * Sorts the list of payment methods so if one method has the ID provided it will be top of the list.
 * @param paymentMethods
 * @param firstMethodId
 * @returns sorted paymentMethods
 */
function putSelectedPaymentMethodOnTop(paymentMethods: PaymentMethods, firstMethodId?: string): PaymentMethods {
    const payNowMethods = paymentMethods.payNow;
    payNowMethods.sort(function (x, y) {
        return x.id === firstMethodId ? -1 : y.id === firstMethodId ? 1 : 0;
    });

    const payLaterMethods = paymentMethods.payLater;
    payLaterMethods.sort(function (x, y) {
        return x.id === firstMethodId ? -1 : y.id === firstMethodId ? 1 : 0;
    });

    return {
        payNow: payNowMethods,
        payLater: payLaterMethods,
        express: paymentMethods.express
    };
}

/**
 * Get the selected payment method gateway, method and clientKey dynamically to pass initPayment() function
 * @returns the selected payment method methodId, gateway, method and clientKey
 */
export function getAdditionalPaymentServiceDetails(selectedPaymentMethod: PaymentMethodType) {
    if (selectedPaymentMethod?.additionalData?.paymentServiceDetails !== undefined && Object.keys(selectedPaymentMethod?.additionalData?.paymentServiceDetails).length > 0) {
        return { methodId: selectedPaymentMethod?.id, methodName: selectedPaymentMethod?.name, ...selectedPaymentMethod?.additionalData?.paymentServiceDetails };
    } else {
        return { methodId: selectedPaymentMethod?.id, methodName: selectedPaymentMethod?.name, provider: selectedPaymentMethod?.provider, method: selectedPaymentMethod?.method };
    }
}
