import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Text, Table, TableBody, TableRow, TableCell } from 'grommet';
import { MarginType } from 'grommet/es6/utils';
import { Store } from '../../modules/delivery/types';
import { translate } from '../../modules/translation';
import OpeningHoursTable from '../OpeningHoursTable/OpeningHoursTable';
import Separator from '../Separator/Separator';
import FormattedAddress from '../FormattedAddress/FormattedAddress';
import { RootState } from '../../store/reducers';

/**
 * A component showing the store details meant to be displayed just underneath a store name after the user has clicked on 'View Store Details'.
 * To be used on desktop only (mobile shows a full page layer with the component 'StoreDetails').
 * @param store {object} A full store object
 * @param showAddress {boolean} Whether or not to show the store address at the beginning.
 * @param margin {grommet margin type} Margins applied to the outer-most box of this component.
 * @param onGetDirections Callback for when the user clicks on the 'Get Directions' button.
 */
function StoreDetailsDesktop ({ store, showAddress, margin, onGetDirections } : StoreDetailsProps) {
    const translation = useSelector((state: RootState) => state.translation);

    const strings = {
        phoneNumber: translate(translation, 'phoneNumber', 'Phone Number'),
    };

    return(
        <Box margin={margin}>
            {/* Store Address */}
            {showAddress &&
                <Box margin={{bottom: 'medium'}}>
                    <FormattedAddress details={store.address} type="storeAddress" />
                </Box>
            }

            <Separator margin={{vertical: 'small'}} />

            {/* Opening Hours Table */}
            <OpeningHoursTable openingHours={store.openingHours} />

            {/* Phone if provided */}
            {store.phone &&
                <>
                    <Separator margin={{vertical: 'large'}} />
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell pad={{horizontal: '0px'}}>
                                    <Text weight='bold'>{strings.phoneNumber}</Text>
                                </TableCell>
                                <TableCell flex='grow' pad={{horizontal: '0px'}}>
                                    <Text weight='bold' textAlign="end">
                                        {store.phone}
                                    </Text>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </>
            }
        </Box>
    );
}


type StoreDetailsProps = {
    store: Store,
    showAddress?: boolean,
    margin?: MarginType,
    onGetDirections?: (event: React.MouseEvent) => void,
}

const openingHoursPropTypes = PropTypes.shape({
    open: PropTypes.bool,
    opensAt: PropTypes.string,
    closesAt: PropTypes.string,
    formattedOpeningHours: PropTypes.string.isRequired
}).isRequired;

StoreDetailsDesktop.propTypes = {
    store: PropTypes.shape({
        ID: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        address: PropTypes.shape({
            address1: PropTypes.string.isRequired,
            address2: PropTypes.string,
            address3: PropTypes.string,
            town: PropTypes.string.isRequired,
            county: PropTypes.string,
            postcode: PropTypes.string.isRequired,
            country: PropTypes.string.isRequired,
            locale: PropTypes.string
        }),
        phone: PropTypes.string,
        openingHours: PropTypes.shape({
            Monday: openingHoursPropTypes,
            Tuesday: openingHoursPropTypes,
            Wednesday: openingHoursPropTypes,
            Thursday: openingHoursPropTypes,
            Friday: openingHoursPropTypes,
            Saturday: openingHoursPropTypes,
            Sunday: openingHoursPropTypes
        }),
        distance: PropTypes.string.isRequired
    }).isRequired,
    showAddress: PropTypes.bool,
    margin: PropTypes.object,
    onGetDirections: PropTypes.func,
};

export default StoreDetailsDesktop;
