import { RootState } from '../store/reducers';
import adaptor from './adaptors';
import { EnsightenService } from './ensighten/ensighten';

export type TrackingService =   EnsightenService;

function init(state: RootState) {
    const service = {
        ...adaptor('trackers', 'ensighten', state) as EnsightenService,
    } as TrackingService;
    return service;
}

export default init;
