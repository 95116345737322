import {
    CustomerActionTypes, Customer,
    UPDATE, UPDATE_EMAIL, UPDATE_IS_ON_GUEST_JOURNEY,
    UPDATE_ADDRESS_BOOK_TYPE, UPDATE_PREFERENCES,
    UPDATE_AUTH_TOKEN, UPDATE_MARKETING_OPT_INS, UPDATE_CUSTOMER_IP, SHOW_CLICK_AND_COLLECT,
    Address, CustomerPreferences, MarketingOptIns
} from './types';
import { AppThunk } from '../thunk';
import { AppResponse } from '../../services/response';
import { getMeshErrorAndDelete } from '../../services/mesh-sdk/client';
import { customers, tracking } from '../../services';
import {
    updateDeliveryAddress, loadDeliveryMethods,
    getSelectedMethod, getSelectedStore, getSelectedDeliveryOption,
    getCollectionDetails, loadPreferredCAndCDetails, updateIsGeorestrictionError, validateDeliveryAddressAndSaveToCart,
} from '../delivery';
import {
    getDefaultSavedCardId, getIsOnExpressJourney,
    getSelectedPaymentMethod, updateBillingAddress,
} from '../billing';
import { clearNotification, updateNotification } from '../../modules/notification';
import { AddressBookType } from './types'
import { putSession } from '../session';
import { AuthToken } from '../session/types';
import { CustomerPreferenceType } from '../../services/mesh-sdk/customers';
import { SavedCard } from "../billing/types";
import { getIsNikeConnected, getShowMarketingCheckboxes } from '../config';
import newRelicData from '../../newrelic';
import { local as browserLocalStorage } from "store2";

export const initialState: Customer = {
    ID: '',
    email: '',
    returning: false,
    firstName: '',
    lastName: '',
    phone: '',
    isActive: false,
    // isGuest is managed by Platform
    isGuest: false,
    // isOnGuestJourney managed in this app only, true if user clicked 'Continue as Guest'
    isOnGuestJourney: false,
    isRegistered: false,
    addresses: [],
    addressBookType: 'delivery',
    marketingPreferencesURL: '',
    marketingCancellationURL: '',
    preferences: {},
    additionalData: {},
    marketingOptIns: { // Used by Ensighten for Redeye
        emailOptIn: false,
        smsOptIn: false,
        postOptIn: false,
    },
    ip: '',
    isClickAndCollectAvailable: true,
}

// Reducer
export default function reducer(state = initialState, action: CustomerActionTypes) : Customer {
    switch (action.type) {
        case UPDATE:
            // Avoid overriding the additonal data (eg auth token) with a blank value.
            if (action.customer.additionalData === undefined) {
                delete action.customer.additionalData;
            }
            return { ...state, ...action.customer };
        case UPDATE_EMAIL:
            return { ...state, email: action.email, returning: action.returning ?? false };
        case UPDATE_PREFERENCES:
            return { ...state, preferences: action.preferences };
        case UPDATE_IS_ON_GUEST_JOURNEY:
            return { ...state, isOnGuestJourney: action.isOnGuestJourney };
        case UPDATE_ADDRESS_BOOK_TYPE:
            return { ...state, addressBookType: action.addressBookType };
        case UPDATE_AUTH_TOKEN: {
            return {
                ...state,
                additionalData: {
                    ...state.additionalData,
                    authToken: action.authToken,
                },
            };
        }
        case UPDATE_MARKETING_OPT_INS: {
            return {
                ...state,
                marketingOptIns: {
                    emailOptIn: action.marketingOptIns.emailOptIn ?? state.marketingOptIns?.emailOptIn ?? false,
                    smsOptIn: action.marketingOptIns.smsOptIn ?? state.marketingOptIns?.smsOptIn ?? false,
                    postOptIn: action.marketingOptIns.postOptIn ?? state.marketingOptIns?.postOptIn ?? false,
                },
            };
        }
        case UPDATE_CUSTOMER_IP:
            return { ...state, ip: action.ip };
        case SHOW_CLICK_AND_COLLECT:
            return { ...state, isClickAndCollectAvailable: action.isClickAndCollectAvailable };
        default:
            return state;
    }
}

// Selectors
export const getCustomer = (state: Customer) => state;
export const getCustomerID = (state: Customer) => state.ID as string;
export const getEmail = (state: Customer) => state.email;
export const getReturning = (state: Customer) => state.returning;
export const getAddresses = (state: Customer) => state.addresses;
export const getAddressById = (state: Customer, addressId: string) => {
    return state.addresses.find(address => address.ID === addressId);
};
export const getDefaultDeliveryAddress = (state: Customer) => state.addresses.find(address => address.isDefaultDeliveryAddress);
export const getDefaultBillingAddress = (state: Customer) => state.addresses.find(address => address.isDefaultBillingAddress);
export const getIsGuest = (state: Customer) => state.isGuest;
export const getIsOnGuestJourney = (state: Customer) => state.isOnGuestJourney;
export const getAddressBookType = (state: Customer) => state.addressBookType;
export const getCustomerPreferences = (state: Customer) => state.preferences;
export const getAuthToken = (state: Customer) => state.additionalData?.authToken;
export const getMarketingOptIns = (state: Customer) => state.marketingOptIns;
export const getCustomerIP = (state: Customer) => state.ip;
export const getClickAndCollect = (state: Customer) => state.isClickAndCollectAvailable;

// Action Creators
export function updateCustomer(customer: Customer): CustomerActionTypes {
    return {
        type: UPDATE,
        customer
    };

}

export function updateCustomerPreferences(preferences: CustomerPreferences): CustomerActionTypes {
    return {
        type: UPDATE_PREFERENCES,
        preferences,
    };
}

export function updateEmail(email: string, returning: boolean): CustomerActionTypes {
    return {
        type: UPDATE_EMAIL,
        email,
        returning
    };
}

export function updateIsOnGuestJourney(isOnGuestJourney: boolean): CustomerActionTypes {
    return {
        type: UPDATE_IS_ON_GUEST_JOURNEY,
        isOnGuestJourney,
    };
}

export function updateAddressBookType(addressBookType: AddressBookType): CustomerActionTypes {
    return {
        type: UPDATE_ADDRESS_BOOK_TYPE,
        addressBookType,
    };
}

export function updateAuthToken(authToken: AuthToken): CustomerActionTypes {
    return {
        type: UPDATE_AUTH_TOKEN,
        authToken,
    };
}

export function updateMarketingOptIns(marketingOptIns: MarketingOptIns): CustomerActionTypes {
    return {
        type: UPDATE_MARKETING_OPT_INS,
        marketingOptIns,
    };
}

export function updateCustomerIP(ip: string): CustomerActionTypes {
    return {
        type: UPDATE_CUSTOMER_IP,
        ip
    };
}

export function updateClickAndCollect(isClickAndCollectAvailable: boolean) {
    return {
        type: SHOW_CLICK_AND_COLLECT,
        isClickAndCollectAvailable
    };
}

// Side effects

/**
 * Thunk that will check if the email provided exists and commit it to the state
 * 
 * @param email 
 */
export function submitEmail(email: string): AppThunk<Promise<boolean>> {
    return async dispatch => {
        try {
            let resp = await customers().exists(email);

            if (resp.status === 200){
                dispatch(updateEmail(email, true));
                return Promise.resolve(true);
            } else {
                dispatch(updateEmail(email, false));
                return Promise.reject();
            }
        } catch(e){
            newRelicData({ actionName: 'customer', function: 'submitEmail', message: (e as Error).message, customerEmail: email.slice(email.indexOf('@') - 4) });
            dispatch(updateEmail(email, false));
        }
        return Promise.reject();
    }
}

/**
 * Thunk that will log a returning customer in or sign up a new customer, then add the customer
 * to the state on success.
 * 
 * @param email
 * @param password
 */
export function loginOrSignUp(email: string, password: string, firstName: string, lastName: string): AppThunk<Promise<AppResponse<Customer>>> {
    return async (dispatch, getState) => {

        let loginResponse: AppResponse<Customer>;
        const state = getState();
        const config = state.config;
        const returning = getReturning(state.customer);
        const isGuest = getIsGuest(state.customer);
        const optIns = getMarketingOptIns(state.customer);
        const customerIP = getCustomerIP(state.customer);
        const sendMarketingData = getShowMarketingCheckboxes(state.config);
        let customerPreferences: CustomerPreferences = {};
        let registeredUserPrefrences = true; 

        try {
            if (returning && !isGuest ){
                loginResponse = await customers().login(email.trim(), password);
                newRelicData({ actionName: 'customer', function: 'loginOrSignUp', message: `returning customer: ${loginResponse.data.ID}`});
                const customerPreferencesResponse = await customers().getCustomerPreferences(loginResponse.data.ID);
                customerPreferences = customerPreferencesResponse.data;
                const loadedCustomer = loginResponse.data;
                // The email opt-in box in should override the opt in data from the loaded user object.
                loadedCustomer.marketingOptIns = {
                    ...loadedCustomer.marketingOptIns,
                    emailOptIn: optIns?.emailOptIn,
                };
                if (config.nike_connected && Object.keys(customerPreferences).length > 0) {
                    registeredUserPrefrences = false;
                }
                tracking().trackLogin({...loadedCustomer, ip: customerIP}, registeredUserPrefrences ? sendMarketingData : false);
            } else {
                if (password) {
                    loginResponse = await customers().createAndAuth(email.trim(), password, firstName.trim(), lastName.trim(), false, {marketingOptIns: optIns});
                    newRelicData({ actionName: 'customer', function: 'loginOrSignUp', message: `guest user signUp ${loginResponse.data.ID}`});
                }
                else {
                    loginResponse = await customers().create(email.trim(), false, {marketingOptIns: optIns});
                }
                loginResponse.data.marketingOptIns = optIns;
                tracking().trackRegister({...loginResponse.data, ip: customerIP}, sendMarketingData, false);
            }
            const customerLoggedIn = {
                ...loginResponse.data,
                preferences: customerPreferences,
            };
            dispatch(updateCustomer(customerLoggedIn));
            if (returning && !isGuest ){
                dispatch(loadPreferredCAndCDetails());
            }
            dispatch(putSession({
                ...state.session,
                customerID: customerLoggedIn.ID,
                authToken: customerLoggedIn.additionalData?.authToken,
                commands: {
                    createCsrfToken: true,
                },
            }));
            newRelicData({ actionName: 'customer', function: 'loginOrSignUp', message: `customerID updated to session ${customerLoggedIn.ID}`});
            const addressChangeResult = await dispatch(setDeliveryAndBillingAddresses(customerLoggedIn));
            if (addressChangeResult.deliveryAddressChanged) {
                await dispatch(setDeliveryMethods(customerLoggedIn));
            }
            else {
                const defaultDeliveryAddress = customerLoggedIn.addresses.find(address => address.isDefaultDeliveryAddress);
                if (defaultDeliveryAddress) dispatch(updateDeliveryAddress(defaultDeliveryAddress));
            }

            return loginResponse;
        } catch (error) {
            const errorMessage:string = (error as Error).message ?? 'login_error';
            dispatch(updateNotification(errorMessage));
            newRelicData({ actionName: 'customer', function: 'loginOrSignUp', message: errorMessage});
            return Promise.reject();
        }
    }
}

export function createGuest(email: string, isOnGuestJourney?: boolean): AppThunk<Promise<AppResponse<Customer>>> {
    return async (dispatch, getState) => {
        const state = getState();
        const customer = state.customer;
        const config = state.config;
        const billing = state.billing;
        const isNikeConnected = getIsNikeConnected(config);
        const hasPreferences = browserLocalStorage.get('hasPreference');
        const storedCustomerID = browserLocalStorage.get('customerId');
        const customerIP = getCustomerIP(state.customer);
        let sendMarketingData = getShowMarketingCheckboxes(config);
        
        if( isNikeConnected && hasPreferences){
            sendMarketingData = false;
        }
        
        let marketingOptIns = sendMarketingData
            ? getMarketingOptIns(customer)
            : undefined;
        if (marketingOptIns) {
            marketingOptIns = {
                ...marketingOptIns,
                thirdPartyOptIn: config.nike_connected ? (marketingOptIns?.emailOptIn || marketingOptIns?.smsOptIn) : false,
            };
        } 
        try {
            const response = await customers().create(email.trim(), true, {marketingOptIns});
            const { data } = response;
            newRelicData({ actionName: 'customer', function: 'createGuest', message: 'guest customer', customerID: data.ID });
            //check email exists or not and update returning state accordingly
            dispatch(submitEmail(email));

            //Prevent the customer.returning value from being overriden by the default value on `data`.
            data.returning = getReturning(getState().customer);
            if (isOnGuestJourney) data.isOnGuestJourney = true;

            dispatch(updateCustomer(data));
            const isOnExpressJourney = getIsOnExpressJourney(billing);
            tracking().trackRegister({...data, marketingOptIns: customer.marketingOptIns, ip: customerIP }, sendMarketingData, isOnExpressJourney);

            if(isOnExpressJourney && isNikeConnected && storedCustomerID && !hasPreferences) {
                await customers().patchCustomer(storedCustomerID, {
                    enrolledForEmailMarketing: marketingOptIns?.emailOptIn,
                    enrolledForSMSMarketing: marketingOptIns?.smsOptIn,
                    enrolledForPostageMarketing: marketingOptIns?.postOptIn,
                    enrolledForThirdPartyMarketing: marketingOptIns?.thirdPartyOptIn
                });
            }

            return response;
        } catch(e) {
            newRelicData({ actionName: 'customer', function: 'loginOrSignUp', message: (e as Error).message });
            return Promise.reject();
        }
    };
}

export function deleteSavedCard(customerId: string, cardId: string): AppThunk<Promise<AppResponse<SavedCard[]>>> {
    return async (dispatch, getState) => {
        try {
            const response = await customers().deleteSavedCard(customerId, cardId);
            return response;
        } catch (error) {
            console.error('Error while deleting card', error);
            dispatch(updateNotification('delete_card_error'));
            return Promise.reject();
        }
    };
}

export function loadCustomer(customerID: string): AppThunk<Promise<AppResponse<Customer>>> {
    return async (dispatch) => {
        try {
            const customerResponses = await Promise.all([
                await customers().getCustomer(customerID),
                await customers().getCustomerPreferences(customerID),
            ]);

            const customer = customerResponses[0].data;
            const preferences = customerResponses[1].data;
            const customerWithPreferences: Customer = {
                ...customer,
                preferences: preferences,
            }
            dispatch(updateCustomer(customerWithPreferences));

            await dispatch(setDeliveryAndBillingAddresses(customer));

            return {
                ...customerResponses[0],
                data: customerWithPreferences,
            };

        } catch (error) {
            return Promise.reject();
        }
    }
}

export function promoteCustomer(customerID: string, password: string): AppThunk<Promise<AppResponse<Customer>>> {
    return async (dispatch, getState) => {
        try {
            const customerResponse = await customers().promoteCustomer(customerID, password);
            let customer = customerResponse.data;
            const customerWithAuthResponse = await customers().login(customer.email, password);
            customer = customerWithAuthResponse.data;

            dispatch(updateCustomer(customer));

            const session = getState().session;
            dispatch(putSession({
                ...session,
                customerID: customer.ID,
                cartID: '', // The cart is cleared
                authToken: customer.additionalData?.authToken,
                commands: {
                    ...session.commands,
                    createCsrfToken: true,
                    invalidateCartCache: true,
                },
            }));

            return customerWithAuthResponse;
        } catch (error) {
            return Promise.reject();
        }
    }
}

/**
 * Update an existing address to Mesh.
 * Gets the list of addresses again as the update can change other existing addresses (eg. change of primary address)
 * @param customerID
 * @param address
 */
export function updateExternalAddress(customerID: string, address: Address): AppThunk<Promise<AppResponse<Address>>> {
    return async (dispatch, getState) => {
        try {
            const addressResponse = await customers().updateAddress(customerID, address);
            if (!addressResponse.data) throw new Error('no response data from address update');
            const customerResponse = await customers().getCustomer(customerID);

            //Prevent the customer.returning vallue being overriden by the default value on `data`.
            const state = getState();
            const customer = state.customer;
            const returning = getReturning(customer);
            customerResponse.data.returning = returning;

            dispatch(updateCustomer(customerResponse.data));
            return addressResponse;
        }
        catch (error) {
            newRelicData({ actionName: 'customer', function: 'updateExternalAddress', message: { error:(error as Error)?.message, type:'unable to update address' }});
            console.error('Error updating delivery addresses', error);
            dispatch(updateNotification('address_error'));
            return Promise.reject();
        }
    }
}

/**
 * Save a new address to Mesh.
 * Gets the list of addresses again as the update can change other existing addresses (eg. change of primary address)
 * @param customerID
 * @param address
 * @param isCandC Is this address a store address that shouldn't appear in the user address book (typically a post office address)
 */
export function saveExternalAddress(customerID: string, address: Address, isCAndC = false, shouldUpdateCustomer = true, needCustomerUpdate = false): AppThunk<Promise<AppResponse<Address>>> {
    return async (dispatch, getState) => {
        try {
            const state = getState();
            const customer = state.customer;
            const returning = getReturning(customer);
            const needsCustomerDetails = (!customer.firstName || !customer.lastName);

            // If no customer name/phone, we infer these details based on the address details just provided.
            if ((needsCustomerDetails && shouldUpdateCustomer) || needCustomerUpdate) {
                let updatedCustomer = {
                    ...state.customer,
                    firstName: address.firstName,
                    lastName: address.lastName,
                    phone: state.customer.phone || address.phone || '',
                }
                await customers().updateCustomer(customerID, updatedCustomer);
            }
            const addressResponse = await customers().saveAddress(customerID, address, isCAndC);
            if (!addressResponse.data) throw new Error('no response data from address save save');
            const customerResponse = await customers().getCustomer(customerID);
            //Stop the `returning` flag being altered. The customer isn't `returning` if they're unregustered and on a guest account.
            customerResponse.data.returning = returning;
            dispatch(updateCustomer(customerResponse.data));
            return addressResponse;
        }
        catch (error) {
            newRelicData({ actionName: 'customer', function: 'saveExternalAddress', message: { error:(error as Error)?.message, type: 'unable to update address' }});
            if((error as Error).message === "Could not inflate address - Invalid postcode."){
                throw new Error('CART_PROPOSED_ADDRESS_4');
            }
            console.error('Error saving delivery addresses', error);
            dispatch(updateNotification('address_error'));
            return Promise.reject();
        }
    }
}

export function setDeliveryAndBillingAddresses(customer: Customer): AppThunk<Promise<{deliveryAddressChanged: boolean, billingAddressChanged: boolean}>> {
    return async (dispatch) => {
        let result = {deliveryAddressChanged: false, billingAddressChanged: false};
        try {
            const defaultDeliveryAddress = customer.addresses.find(address => address.isDefaultDeliveryAddress);
            const defaultBillingAddress = customer.addresses.find(address => address.isDefaultBillingAddress);
            if (defaultDeliveryAddress) {
                const isDeliveryAddressChanged = await dispatch(validateDeliveryAddressAndSaveToCart(defaultDeliveryAddress, null));
                if (isDeliveryAddressChanged) dispatch(updateDeliveryAddress(defaultDeliveryAddress));
                result.deliveryAddressChanged = isDeliveryAddressChanged;
            }
            if (defaultBillingAddress) dispatch(updateBillingAddress(defaultBillingAddress));
            result.billingAddressChanged = true;
            return result;
        }
        catch(error) {
            newRelicData({ actionName: 'customer', function: 'setDeliveryAndBillingAddresses', message: { error:(error as Error)?.message, type:'unable to update address' }});
            console.error('Error updating addresses', error);
            dispatch(updateNotification('address_error'));
            return result;
        }
    }
}

export function saveMarketingOptInChoice(customer: Customer, marketingOptIns: MarketingOptIns): AppThunk<Promise<void>> {
    return async (dispatch, getState) => {
        const customerId = getCustomerID(customer);
        const returning = getReturning(customer);
        const config = getState().config;
        const updatedCustomer = await customers().patchCustomer(customerId, {
            enrolledForEmailMarketing: marketingOptIns.emailOptIn,
            enrolledForSMSMarketing: marketingOptIns.smsOptIn,
            enrolledForPostageMarketing: marketingOptIns.postOptIn,
            enrolledForThirdPartyMarketing: config.nike_connected ? (marketingOptIns.emailOptIn || marketingOptIns.smsOptIn) : false
        });
        updatedCustomer.data.returning = returning;
        dispatch(updateCustomer(updatedCustomer.data));
    }
}

export function setDeliveryMethods(customer: Customer): AppThunk<Promise<void>> {
    return async (dispatch) => {
        try {
            const defaultDeliveryAddress = customer.addresses.find(address => address.isDefaultDeliveryAddress);
            const postcode = defaultDeliveryAddress?.postcode;
            const locale = defaultDeliveryAddress?.locale;
            if (locale) {
                const methodQuery = {location: postcode};
                await dispatch(loadDeliveryMethods(methodQuery, locale));
            }
            return Promise.resolve();
        }
        catch(error) {
            if (error instanceof Error && error.message === 'GEORESTRICTED_DESTINATION') {
                // The georestriction will be handled on the delivery page (ignored here)
                getMeshErrorAndDelete();
                dispatch(clearNotification());
                dispatch(updateIsGeorestrictionError(true));
                return Promise.resolve();
            }
            else dispatch(updateNotification('delivery_method_update_error'));
            console.error('Error updating the delivery method', error);
            return Promise.reject();
        }
    }
}

/**
 * Will save the customer preferences to the external customer object, either by creating new or overwriting existing preference data.
 */
export function createOrUpdateCustomerPreferences(): AppThunk<Promise<void>> {
    async function createOrUpdate(customerId: string, preferenceType: CustomerPreferenceType, newValue: string, preferenceId?: string) {
        try {
            if (preferenceId) {
                customers().updateCustomerPreference(
                    customerId,
                    preferenceId,
                    preferenceType,
                    newValue,
                );
            }
            else {
                customers().createCustomerPreference(
                    customerId,
                    preferenceType,
                    newValue,
                );
            }
        }
        catch (error) {
            console.error(`Could not create/update preference ${preferenceType}`, error);
        }
    }

    return async (dispatch, getState) => {
        const customer = getState().customer;
        const delivery = getState().delivery;
        const billing = getState().billing;
        const collection = getCollectionDetails(delivery);
        const isGuest = getIsOnGuestJourney(customer);
        const deliveryOption = getSelectedDeliveryOption(delivery).value;
        const preferredCardId = getDefaultSavedCardId(billing);
        const isExpress = getIsOnExpressJourney(billing);
        const storedCustomerId = browserLocalStorage.get('customerId');
        const hasPreferences = browserLocalStorage.get('hasPreference');

        if (!isGuest || (isExpress && storedCustomerId && !hasPreferences)) {
            const customerId = !storedCustomerId ? getCustomerID(customer) : storedCustomerId;
            const preferences = getCustomerPreferences(customer);
            const selectedDeliveryMethod = getSelectedMethod(delivery);
            const selectedPaymentMethod = getSelectedPaymentMethod(billing);
            const selectedStore = getSelectedStore(delivery);
            const deliveryDetails = JSON.stringify({
                id: selectedDeliveryMethod.ID,
                option: deliveryOption
            });
            const collectionDetails = JSON.stringify({
                firstName: collection.firstName,
                lastName: collection.lastName,
                phone: collection.phone,
            });
            const paymentDetails = JSON.stringify({
                method: selectedPaymentMethod.id,
                methodData: preferredCardId ?
                    JSON.stringify({ preferredSavedCard: preferredCardId }) : ''
            });
            
            if(isExpress && storedCustomerId && !hasPreferences) {
                createOrUpdate(customerId, 'DELIVERY_METHOD', deliveryDetails, preferences?.deliveryMethodPreference?.ID);
            } else {
                createOrUpdate(customerId, 'DELIVERY_METHOD', deliveryDetails, preferences?.deliveryMethodPreference?.ID);
                createOrUpdate(customerId, 'PAYMENT_METHOD', paymentDetails, preferences?.paymentMethodPreference?.ID);
                if (selectedStore.clientId) {
                    createOrUpdate(customerId, 'STORE_LOCATION', selectedStore.clientId, preferences?.storePreference?.ID);
                    createOrUpdate(customerId, 'COLLECTION_DETAIL', collectionDetails, preferences?.collectionPreference?.ID);
                }
            }
        }
    };
}

export function loadCustomerIP(): AppThunk<void> {
    return async (dispatch) => {
        try {
            const response = await fetch("https://whatsmyip.jdmesh.co");
            const data = await response.json();
            const ip = data.ip;
            dispatch(updateCustomerIP(ip));
        }
        catch (error) {
            console.error("couldn't get update customer IP", error);
        }
    }
}