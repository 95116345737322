import merge from 'deepmerge';
import { SecurityOutline } from '../components/Icon';
import jdTheme, { CustomTheme } from '.';

const theme: CustomTheme = {
    config: {
        displayName: "Supply And Demand",

        headerLogo: '/Logo-Supplyanddemand-Navigation.png',
        headerHeight: '64px',
        headerLogoSize: {height: '40px', width: '150px'},
        headerShowLogo: true,
        headerBackgroundColor: 'white',
        headerLogoAndTextSpacing: {horizontal: "110px"},
        faviconLogo: 'supplyanddemand/favicon.ico',

        icons: {
            securityIcon: SecurityOutline,
        },
        titleFontWeight: 'bold',
        stepFontWeight: 'bold',
    },

    global: {
        font: {
            family: '"GothamCondensedBook", "Helvetica","Arial", "sans-serif"',
        },

    },

    button: {
        border: {
            radius: '0px'
        },
        extend: 'height: 48px; font-weight: bold',
    },

    radioButton: {
        font: {
            weight: 'bold',
        }
    },

};

const supplyAndDemandTheme: CustomTheme = merge(jdTheme, theme);

export default supplyAndDemandTheme;
