import React, { useContext, useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { Box, Text, ResponsiveContext, Layer, CheckBox, Stack, Button, Form, Anchor} from 'grommet';
import { Edit } from '../Icon/index';
import { RootState } from '../../store/reducers';
import { AppThunkDispatch } from '../../modules/thunk';
import { payments, tracking } from '../../services';
import { getReturnURL, getPaymentOptionsDescriptions, getPayLaterDetailsLinks, getShowMarketingCheckboxes,
    getPrivacyPolicy, getMarketingSmsOptout, getExpressOptoutCheckbox, isMarketingAllOptoutText,
    getMarketingEmailOptout, getPayInthreeExpressDetails, getMarketingDetails
} from '../../modules/config';
import { getSelectedPaymentMethod } from '../../modules/billing';
import { ExtraRenderedComponents, PaymentMethodModule } from '../../services/payments/types';
import {
    selectOption,
    getSelectedDeliveryOption,
    getSelectedStore,
    getCollectionDetails,
    selectStoreForDetailedView,
    getShowStoreDetailedView,
    getStoreForDetailedView,
    loadDeliveryMethods,
    getMethods as getDeliveryMethods,
    selectMethod as selectDeliveryMethod,
    getSelectedMethod as getselectedDeliveryMethod,
    selectStore, updatePostOffices, updateStores
} from '../../modules/delivery';
import Currency from "../Currency/Currency";
import OptionList, { OptionTypes } from "../OptionList/OptionList";
import { CustomTheme } from '../../theme';
import { PageRefContext } from "../Page/Page";
import { Shop, Delivery } from '../Icon/index';
import ClickAndCollectPage from '../../containers/ClickAndCollectPage';
import TitleBar from '../../components/TitleBar/TitleBar';
import Step from '../../components/Step/Step';
import StoreDetails from '../../components/StoreDetails/StoreDetails';
import { Close } from '../Icon/index';
import TotalSummary from '../TotalSummary/TotalSummary';
import { updateNotification } from '../../modules/notification';
import EditableDetails from '../EditableDetails/EditableDetails';
import { translate, getPhraseWithLink } from '../../modules/translation';
import { getClickAndCollect, updateMarketingOptIns } from '../../modules/customer';
import { removeExternalCartDeliveryMethod, getHasDropshipProducts, updateExternalCartDeliveryMethod } from "../../modules/cart";
import { getPaymentMethodRenderedExtras}  from "../../lib/paymentMethodRenderedExtras/paymentMethodRenderedExtras";
import { local as browserLocalStorage } from 'store2';
import SquareCheckBox from '../SquareCheckBox/SquareCheckBox';
import newRelicData from '../../newrelic';

function ExpressPaymentPage() {
    const [isButtonVisible, setButtonVisible] = useState(false);
    const [isClickAndCollectSelected, setIsClickAndCollectSelected] = useState(false);
    const [bodyComponent, setBodyComponent] = useState(<></>);
    const [isUsingExtraComponents, setIsUsingExtraComponents] = useState(false);
    const [saveContinueDisabled, setSaveContinueDisabled] = React.useState(true);
    const shouldRerender = useRef(false);
    const history = useHistory();
    const pageRef = useContext(PageRefContext);
    const theme: CustomTheme = useContext(ThemeContext);
    const size = React.useContext(ResponsiveContext);
    const isDesktop = size !== 'small';
    const dispatch: AppThunkDispatch = useDispatch();

    const delivery = useSelector((state: RootState) => state.delivery);
    const billing = useSelector((state: RootState) => state.billing);
    const config = useSelector((state: RootState) => state.config);
    const cart = useSelector((state: RootState) => state.cart);
    const translation = useSelector((state: RootState) => state.translation);
    const payLaterDetailsLinks = getPayLaterDetailsLinks(config); 
    const returnURL = getReturnURL(config);
    const payInThreeLabel = getPayInthreeExpressDetails(config);
    const showMarketingCheckboxes = getShowMarketingCheckboxes(config);
    const selectedStore = getSelectedStore(delivery);
    const collectionDetails = getCollectionDetails(delivery);
    const showStoreDetailedView = getShowStoreDetailedView(delivery);
    const storeForDetailedView = getStoreForDetailedView(delivery);
    const paymentMethod = getSelectedPaymentMethod(billing); 
    const {description, link} = getPaymentMethodRenderedExtras(paymentMethod, payLaterDetailsLinks);
    const hasDropshipProducts = getHasDropshipProducts(cart);
    const isClickAndCollectEnabledForFascia = config.click_and_collect;
    const paymentOptionsDescriptions = getPaymentOptionsDescriptions(config);
    const isClickAndCollectEnableForMethod = paymentMethod.clickAndCollectSupported;
    const initialMarketingSmsOptout = getMarketingSmsOptout(config);
    const initialMarketingEmailOptout = getMarketingEmailOptout(config);
    const OptoutCheckboxForAll = getExpressOptoutCheckbox(config);
    const isMarketingAllOptoutMessage = isMarketingAllOptoutText(config);
    const [isSMSOptionTicked, setIsSMSOptionTicked] = React.useState(initialMarketingSmsOptout);
    const [isEmailOptionTicked, setIsEmailOptionTicked] = React.useState(initialMarketingEmailOptout);
    const [isOptInTicked, setIsOptInTicked] = React.useState(initialMarketingSmsOptout && initialMarketingEmailOptout);
    const privacyPolicy = getPrivacyPolicy(config);
    const locale = config.localisation.countryCode.toLowerCase();
    const selectedMethod = getselectedDeliveryMethod(delivery);
    const customer = useSelector((state: RootState) => state.customer);
    const isClickAndCollectAvailable = getClickAndCollect(customer);
    const marketingDetails = getMarketingDetails(config);
    const isNikeConnected = config.nike_connected;
    
    const strings = {
        home: {
            TITLE: translate(translation, 'homeDelivery', 'Home Delivery'),
        },
        clickAndCollect: {
            TITLE: translate(translation, 'cAndC', 'Click & Collect'),
            DELIVERY_METHOD_TITLE: translate(translation, 'cAndCOptions', 'Click & Collect Options'),
        },
        UNAVAILABLE: translate(translation, 'unavailableForBasketItems', 'Unavailable for items in your basket'),
        expressPayment: translate(translation, 'expressPayment', 'Express Payment'),
        deliveryOptions: translate(translation, 'deliveryOptions', 'Delivery Options'),
        howToReceiveOrder: translate(translation, 'howToReceiveOrder', 'How would you prefer to receive your order?'),
        marketingOptInAll: translate(translation, 'marketingOptInAll', 'We will share our latest launches, offers and new drops with you unless you untick this box.'),
        marketingOptInSms: translate(translation, 'marketingOptInSms', 'We will share our latest launches, offers and new drops with you via SMS, unless you untick this box.'),
        marketingOptInEmail: translate(translation, 'marketingOptInEmail', 'We will share our latest launches, offers and new drops with you via Email, unless you untick this box.'),
        privacyNoticeInfo: translate(translation, 'privacyNoticeInfo', 'We will use your information in accordance with our (privacy notice).'),
        saveContinue: translate(translation, 'saveContinue', 'Save and Continue'),
        updatedOnDate: translate(translation, 'updatedOnDate', '', {date: privacyPolicy.date}),
        free: translate(translation, 'free', 'Free'),
    };

    const storedCustomerId = browserLocalStorage.get('customerId');
    const hasPreferences = browserLocalStorage.get('hasPreference');
    
    let selectedDeliveryOption = getSelectedDeliveryOption(delivery)?.value;

    if(selectedDeliveryOption === "clickAndCollect" && !isClickAndCollectEnableForMethod) {
        selectedDeliveryOption = "home";
    }

    type DeliveryOption = 'home'|'clickAndCollect';
    let privacyPolicyTextString = strings.privacyNoticeInfo;
    if (privacyPolicy.date) privacyPolicyTextString += ` ${strings.updatedOnDate}`;
    const privacyPolicyText = getPhraseWithLink(privacyPolicyTextString, privacyPolicy.link, {size: 'xsmall'});

    const showClickAndCollect = selectedDeliveryOption === 'clickAndCollect';
    const showTotalAndCTA = selectedDeliveryOption === 'home' || (
        selectedDeliveryOption === 'clickAndCollect' &&
        selectedStore.ID &&
       ((collectionDetails.firstName &&
        collectionDetails.lastName) || collectionDetails.notYouCollectingFirstName)
    );
    const radioMethods: OptionTypes[] = [];
    useEffect(() => {
        tracking()?.trackPaymentView(cart);
            dispatch(updateMarketingOptIns({
                emailOptIn: isEmailOptionTicked,
                smsOptIn: isSMSOptionTicked,
            }));
        //dispatch(stockCheck(getCartId(cart)));
        // eslint-disable-next-line
    }, []);

    useEffect(() => { 
        dispatch(removeExternalCartDeliveryMethod());
        dispatch(loadDeliveryMethods({partialPostcode: true}, locale,true));
        setIsClickAndCollectSelected(false); 
        setSaveContinueDisabled(true);
        // eslint-disable-next-line
    }, [selectedDeliveryOption]);


    const onSubmitForm = (event: any) => {
        setIsClickAndCollectSelected(true);
        newRelicData({ actionName: 'ExpressPaymentPage', function: 'onSubmitForm', message: `${selectedMethod.displayName} delivery method is selected.`});
    }

    if(selectedDeliveryOption === 'clickAndCollect')
    {
       
        const deliveryMethods = getDeliveryMethods(delivery);
        deliveryMethods.forEach(method => {
            const methodPrice = method.typeCategory === 'clickAndCollect'
                            ? method.price.amount === '0.00' ? <Text weight="bold">{strings.free}</Text> : <Currency currency={method.price.currency} amount={method.price.amount} /> 
                            : <Currency currency={method.price.currency} amount={method.price.amount} />;
        
                radioMethods.push({
                    id: method.ID,
                    value: method.ID,
                    label: method.displayName,
                    description: method.description,
                    hasTimeSlots: !!method.slots?.length,
                    extra: <Text weight="bold">{methodPrice}</Text>,
                    needsConfirmation: method.needsConfirmation
                });
            
        });		
    }
    useEffect(() => {
        async function renderCheckoutButton() {
            const paymentModule = await payments().getPaymentMethodModule(paymentMethod.id) as PaymentMethodModule;
            try {
                paymentModule.setHistory(history);
                const moduleExtra: ExtraRenderedComponents = await paymentModule.render();

                if (moduleExtra?.body) {
                    setIsUsingExtraComponents(true); 
                    setBodyComponent(React.createElement(moduleExtra?.body?.component, moduleExtra?.body?.props));
                }

    
            }
            catch (error) {
                console.error(error);
                dispatch(updateNotification('express_payment_error'));
                newRelicData({ actionName: 'ExpressPaymentPage', function: 'renderCheckoutButton', message: { msg: (error as Error)?.message }});
            }
            newRelicData({ actionName: 'ExpressPaymentPage', function: 'renderCheckoutButton', message: `${paymentMethod.name} payment method selected`});
        }

        function removeAllChildNodes(parent:any) {
            while (parent.firstChild) {
                parent.removeChild(parent.firstChild);
            }
        }
        
        if (showTotalAndCTA) {
            if (!isButtonVisible) {
                setButtonVisible(true);
                renderCheckoutButton();
                shouldRerender.current = true;
            }
            else if( shouldRerender.current === true && !isUsingExtraComponents)
            {
                const container = document.querySelector('#expressMethod-button');
                removeAllChildNodes(container);
                renderCheckoutButton();
               
            }
        }
        else {
            setButtonVisible(false);
        }

        // eslint-disable-next-line
    }, [ showTotalAndCTA]);
    
    const deliveryMethodList = <Step title={strings['clickAndCollect'].DELIVERY_METHOD_TITLE}>
        <Box direction="column" flex="grow">
            <Stack fill={true}>
                <OptionList
                    options={radioMethods}
                    defaultSelected={selectedMethod.ID}
                    onOptionChange={async event => {
                        const methodId = event.target.value;
                        dispatch(updateStores([])); // clear store state so that we aren't returning unavailable stores for same day c&c
                        dispatch(updatePostOffices([]));
                        setSaveContinueDisabled(false);
                        //dispatch(updateExternalCartDeliveryMethod(methodId, locale));
                        
                        if (selectedDeliveryOption !== 'clickAndCollect') {
							try {
								await dispatch(updateExternalCartDeliveryMethod(methodId, locale));
							}
							catch (error) {
								dispatch(updateNotification('error_loading_payment_methods'));
								console.error('Error loading the payment methods', error);
								newRelicData({ actionName: 'ExpressPaymentPage', function: 'onOptionChange', message: { msg: (error as Error)?.message }});
							}
						} else {
							// Do nothing just show the postcode input as this is needed for the next stage
							dispatch(selectDeliveryMethod(methodId));
							dispatch(selectStore(''));
						}



                    
                    }}
                />
            </Stack>
        </Box>
    </Step>; 



    // Store Details table to be shown either in a <Box> (desktop) or a <Layer> (mobile)
    const storeDetails = storeForDetailedView.ID
        ?
        <StoreDetails
            store={storeForDetailedView}
            onGetDirections={() => {}}
            onCallStore={() => {}}
            onClose={() => dispatch(selectStoreForDetailedView(''))}
        />
        :
        <></>;

    /**
     * Makes a single button for the choice of delivery option
     * @param type Type of button to render
     * @param isSelected 
     * @returns JSX
     */
    function makeOption(type: DeliveryOption, isSelected = false) {
        const isClickAndCollect = type === 'clickAndCollect';

        const isGreyedOut = (isClickAndCollect && (
            !isClickAndCollectEnableForMethod ||
            !isClickAndCollectEnabledForFascia ||
            hasDropshipProducts || !isClickAndCollectAvailable))

        const iconStyle = {
            fill: isGreyedOut ? 'lightgrey' : isSelected ? 'white' : 'black',
            height: theme?.icon?.size?.medium ?? '24px',
        };
        const content = {
            home: {
                icon: <Delivery {...iconStyle} />,
                title: strings.home.TITLE,
                description: paymentOptionsDescriptions.homeDelivery,
            },
            clickAndCollect: {
                icon: <Shop {...iconStyle} />,
                title: strings.clickAndCollect.TITLE,
                description: hasDropshipProducts
                    ? strings.UNAVAILABLE
                    : paymentOptionsDescriptions.clickAndCollect,
            },
        };
        let backgroundColor, borderColor;
        if (isSelected) {
            backgroundColor = theme?.global?.colors?.['dark-1'] ?? '#404044';
            borderColor = backgroundColor;

        }
        else {
            backgroundColor = 'white';
            borderColor = theme?.global?.colors?.['light-2'] ?? '#e7e8e9';
        }

        return (
            <Box
                align="center"
                background={backgroundColor}
                border={{color: borderColor}}
                pad="large"
                flex="grow"
                basis="0px"
                onClick={
                    !isGreyedOut ?
                        () => {
                            dispatch(selectOption(type));
                            newRelicData({ actionName: 'ExpressPaymentPage', function: 'onClick', message: `${type} delivery option selected`});
                        }
                    : undefined
                }
            >
                <Box flex={false}>{content[type].icon}</Box>
                <Text
                    textAlign="center"
                    weight="bold"
                    margin={{vertical: 'xsmall'}}
                    color={isGreyedOut ? 'lightgrey' : undefined}
                >
                    {content[type].title}
                </Text>
                <Text
                    textAlign="center"
                    size="small"
                    color={isGreyedOut ? 'lightgrey' : undefined}
                >
                    {content[type].description}
                </Text>
            </Box>
        );
    }



    return (
                <>
            <TitleBar title={paymentMethod.name === "PAYINTHREE_EXPRESS" ? payInThreeLabel.label :  strings.expressPayment} icon={<a href={`${returnURL}cart`}><Close /></a>} showBorder={false} />
                <Step title={strings.deliveryOptions}>
                    <Box direction="column" fill="horizontal">
                        <Text>{strings.howToReceiveOrder}</Text>
                        <Box direction="row" justify="between" gap="small" margin={{top: 'medium'}}>
                            {makeOption('home', selectedDeliveryOption === 'home')}
                            {makeOption('clickAndCollect', selectedDeliveryOption === 'clickAndCollect')}
                        </Box>
                    </Box>
                </Step>
            

            { showClickAndCollect && !isClickAndCollectSelected &&
                <Step showPadding={isDesktop}>
                    <Form onSubmit={onSubmitForm}>
                        <Box flex={false} direction="column" fill="horizontal">
                            {deliveryMethodList }
                            <Button
                                primary
                                type="submit"
                                label={strings.saveContinue}
                                fill="horizontal"
                                disabled={saveContinueDisabled}
                            />
                        </Box>  
                    </Form>
                </Step>   
            }
                        
            { isClickAndCollectSelected &&
                <>
                    <Step title={strings['clickAndCollect'].DELIVERY_METHOD_TITLE}>
                        <Box flex={false} direction="column" fill="horizontal">
                            <EditableDetails
                                title={selectedMethod.displayName}
                                id={selectedMethod.ID}
                                showPadding={false}
                                extra={
                                    <Anchor>
                                    <Edit
                                        onClick={() => {
                                            setIsClickAndCollectSelected(false);
                                        }} />
                                    </Anchor>
                                }
                                main={selectedMethod.description}
                            />
                        
                        </Box>
                    </Step>        
                    <Step showPadding={isDesktop}>
                        <Box direction="column" fill="horizontal">     
                            <ClickAndCollectPage
                                showTitleBar={false}
                                showStoreDetailsInCAndCView={false}
                                onClose={() => {}}
                                showMarketingCheckbox={false}
                            />
                        </Box>
                    </Step>
                </>
            }
            {selectDeliveryMethod}
            { showTotalAndCTA &&
            <>
                <Step>
                    <Box direction="column" fill="horizontal">
                        <TotalSummary />
                    </Box>
                </Step>
                <Box direction="column" flex="grow">
                { showMarketingCheckboxes && !isNikeConnected &&
                    <Box margin={{
                        left: 'medium',
                        top: isDesktop ? undefined : 'medium',
                        bottom: 'medium',
                    }}>
                        { OptoutCheckboxForAll ?
                            <>
                                <CheckBox
                                    checked={isEmailOptionTicked}
                                    onChange={(event) => {
                                        setIsEmailOptionTicked(event.target.checked);
                                        dispatch(updateMarketingOptIns({
                                            emailOptIn: event.target.checked
                                        }));
                                    }}
                                    label={<Text size="small">{strings.marketingOptInEmail}</Text>}
                                />
                                <CheckBox
                                    checked={isSMSOptionTicked}
                                    onChange={(event) => {
                                        setIsSMSOptionTicked(event.target.checked);
                                        dispatch(updateMarketingOptIns({
                                            smsOptIn: event.target.checked
                                        }));
                                    }}
                                    label={<Text size="small">{strings.marketingOptInSms}</Text>}
                                />
                            </>
                            :
                            <CheckBox
                                checked={isOptInTicked}
                                onChange={(event) => {
                                    setIsOptInTicked(event.target.checked);
                                    dispatch(updateMarketingOptIns({
                                        emailOptIn: event.target.checked,
                                        smsOptIn: event.target.checked,
                                    }));
                                }}
                                label={ isMarketingAllOptoutMessage ?
                                    <Text size="small">{strings.marketingOptInAll}</Text> :
                                    <Text size="small">{strings.marketingOptInEmail}<br/>{strings.marketingOptInSms}</Text>}
                            />}
                    </Box>
                }
                {
                    showMarketingCheckboxes && isNikeConnected && (!storedCustomerId || (storedCustomerId && !hasPreferences) ) &&
                    <Box margin={{
                        left: 'medium',
                        top: isDesktop ? undefined : 'medium',
                        bottom: 'medium',

                    }}>
                        <Text size="small" margin={{ bottom: 'small' }}> {marketingDetails.text} </Text>
                        <>
                            <SquareCheckBox
                                checked={isEmailOptionTicked}
                                name={marketingDetails.emailLabel}
                                id="squareCheckBox"
                                onChange={(event) => {
                                    setIsEmailOptionTicked(event.target.checked);
                                    dispatch(updateMarketingOptIns({
                                        emailOptIn: event.target.checked
                                    }));
                                }}
                            />
                            <SquareCheckBox
                                checked={isSMSOptionTicked}
                                name={marketingDetails.smsLabel}
                                id="squareCheckBox"
                                onChange={(event) => {
                                    setIsSMSOptionTicked(event.target.checked);
                                    dispatch(updateMarketingOptIns({
                                        smsOptIn: event.target.checked
                                    }));
                                }}
                            />
                        </>
                    </Box>
                }
                {/* Placeholder html tag for the button to be rendered by the payment method SDK */}
                { !isUsingExtraComponents 
                    ?
                        <Box
                            margin={{bottom: 'medium'}}
                        >
                            <Box
                                id="expressMethod-button"
                                data-testid="expressMethod-button"
                                flex={true}
                                alignSelf="center"
                                align='center'
                                fill="vertical"
                                pad={{horizontal: 'medium'}}
                                width="medium"
                            /> 
                            <Text alignSelf="center">{description}{link}</Text>
                        </Box>
                    :
                        <Box
                            margin={{top: 'small', bottom: 'small'}}
                            pad={{horizontal: 'medium'}}
                        >
                            {/* Additional rendered content - e.g. CPEX button. */}
                            {bodyComponent}
                            <Text alignSelf="center">{description}{link}</Text>
                        </Box>
                }
                    {/* Legal links */}
                    <Box direction="row"  margin={{ horizontal: 'medium'}}>
                        {privacyPolicyText}
                    </Box>
                </Box>    
            </>
            }
            
            { showStoreDetailedView && !isDesktop &&
            <Layer
                target={pageRef?.current || undefined}
                responsive={false}
                animation="slide"
                position="bottom"
                full={true}
            >
                {storeDetails}
            </Layer>
            }
        </>
    );
}

export default ExpressPaymentPage;
