import React, { useEffect, useState, useContext, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { ThemeContext, Box, Text, defaultProps, Form, ResponsiveContext, Tip  } from "grommet";
import { StatusGood, CreditCard, CircleQuestion } from 'grommet-icons';
import styled from 'styled-components';
import AdyenCheckout from '@adyen/adyen-web';
import {
    CbObjOnError as AdyenErrorState,
    CbObjOnConfigSuccess as AdyenConfigState,
    CbObjOnFieldValid as AdyenFieldValidityState,
} from '@adyen/adyen-web/dist/types/components/internal/SecuredFields/lib/types';
import { RootState } from '../../store/reducers';
import { AppThunkDispatch } from '../../modules/thunk';
import { getLocalisation } from '../../modules/config';
import { translate } from '../../modules/translation';
import { CustomTheme } from '../../theme';
import {
    getSelectedSavedCard, updatePaymentMethodsData,
} from '../../modules/billing';
import { AdyenCardPaymentInputContainerProps, AdyenCardPaymentIconProps, AdyenChangeState, AdyenCardPaymentComponent } from '../AdyenCardPaymentForm/AdyenCardPaymentForm';
import SavedCardDetails from '../SavedCardDetails/SavedCardDetails';
import { SavedCard } from '../../modules/billing/types';

const StyledInputContainer = styled.div<AdyenCardPaymentInputContainerProps>`
    height: ${props => props.height};
    width: 100%;
    display: block;
    position: relative;
    margin: 8px 0;
    border: ${(props) => (props.status === 'error' ? "1px solid "+props.errorBorderColor :
    props.status === 'success' ? "1px solid "+props.successBorderColor :
        "1px solid "+props.defaultBorderColor)};
    ${(props) => (props.focus ? "border: 1px solid "+props.focusBorderColor+";" : "")};
`;

const iconSize: string = defaultProps.theme?.icon?.size?.medium ?? '30px';
const boxPadSize: string = defaultProps.theme?.global?.edgeSize?.small ?? '16px';

const StyledStatusGoodIcon = styled(StatusGood)<any>`${(props: AdyenCardPaymentIconProps) => props.iconstyles || ''}`;
const StyledCreditCardIcon = styled(CreditCard)<any>`${(props: AdyenCardPaymentIconProps) => props.iconstyles || ''}`;

/**
 * AdyenReturningCardForm displays the CVV input required for the user to use a card already saved in Adyen
 * 
 */
function AdyenReturningCardForm(props: AdyenReturningCardFormProps) {
    
    const theme = useContext(ThemeContext) as CustomTheme;
    type ThemeValue = {
        horizontal?: string,
        vertical?: string,
        light?: string,
    };
    const fontSize = theme?.text?.medium?.size ?? '14px';
    const inputPaddingHorizontal = (theme?.global?.input?.padding as ThemeValue)?.horizontal ?? '8px';
    const inputPaddingVertical = (theme?.global?.input?.padding as ThemeValue)?.vertical ?? '16px';
    const backgroundErrorColor = (theme?.global?.colors?.['background-error'] as ThemeValue)?.light ?? 'rgba(255,0,0,0.08)';
    const defaultTextColor = (theme?.global?.colors?.text as ThemeValue)?.light ?? '#444444';
    const placeholderTextColor = (theme?.global?.colors?.placeholder as string) ?? '#AAAAAA';
    const defaultBorderColor = (theme?.global?.colors?.border as ThemeValue)?.light ?? '#e7e8e9';
    const errorBorderColor = (theme?.global?.colors?.red as ThemeValue)?.light ?? '#FF0000';
    const focusBorderColor = (theme?.global?.focus?.border?.color as string) ?? '#4bd4b0';
    const successBorderColor = (theme?.global?.colors?.green as ThemeValue)?.light ?? defaultBorderColor;
    const cvvFieldWidth = theme?.config?.cvvFieldWidth ?? '130px';
    const size = React.useContext(ResponsiveContext);
    const isDesktop = size !== 'small';

    const isAdyenComponentLoaded = useRef(false);

    const adyenCheckoutStyles = {
        base: {
            fontSize: fontSize,
            padding: `${inputPaddingVertical} ${inputPaddingHorizontal}`
        },
        error: {
            color: defaultTextColor,
            background: backgroundErrorColor
        },
        validated: {
            color: defaultTextColor
        },
        placeholder: {
            color: placeholderTextColor
        }
    }

    const iconStyles: string = `
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: calc(100% - ${iconSize} - ${inputPaddingHorizontal});
    `;

    const infoIconStyles = {
        height: 'auto',
        buttom: '1px',
        display: 'inline-block',
        position: 'relative' as 'relative',
        top: '3px',
        cursor: 'pointer',
    };
    
    const initialStatuses = {
        encryptedSecurityCode: 'default'
    };
    const initialErrorMessages = {
        encryptedSecurityCode: ''
    };

    const dispatch: AppThunkDispatch = useDispatch();

    const billing = useSelector((state: RootState) => state.billing);
    const config = useSelector((state: RootState) => state.config);
    const translation = useSelector((state: RootState) => state.translation);

    const strings = {
        cvv: translate(translation, 'cvv', 'CVV'),
        CVVInfo: translate(translation, 'cvvInfo', 'CVV is an additional three or four digit security code that is printed (not embossed) on the front or the back of your card.'),
    }

    const savedCard = getSelectedSavedCard(billing);

    const [status, setStatus] = useState(initialStatuses);
    const [errorMessage, setErrorMessage] = useState(initialErrorMessages);

    const adyenCheckoutAriaLabels = {
        lang: getLocalisation(config).language.code,
        encryptedSecurityCode: {
            label: "Iframe for card data input field"
        }
    };

    function handleAdyenOnChange(state: AdyenChangeState, component: AdyenCardPaymentComponent) {
        const adyenData = state.data;
        adyenData.paymentMethod.storedPaymentMethodId = savedCard.id;
        adyenData.paymentMethod.brand = savedCard.brand;
        /* If selected saved card is expired then we are disabling "Checkout Securely" button */
        state.isValid = (state.isValid) ? !isCardExpired(savedCard) : state.isValid;
        dispatch(updatePaymentMethodsData('adyenCard', {
            adyenData: state.data,
            isValid: state.isValid,
            shouldSaveCard: false,
        }));
    }

    function handleAdyenOnValid(state:AdyenChangeState, component:AdyenCardPaymentComponent) {
        // Executed when the form switches between valid and invalid.
    }

    function handleAdyenConfigSuccess(state: AdyenConfigState) {
        // Executed when Adyen has loaded successfully
    }

    function handleAdyenFieldValid(state: AdyenFieldValidityState) {
        if (state.valid) {
            setStatus((prevStatus) => ({...prevStatus, [state.fieldType]: 'success'}));
            setErrorMessage((prevErrorMessage) => ({...prevErrorMessage, [state.fieldType]: ''}));
        } else {
            setStatus((prevStatus) => ({...prevStatus, [state.fieldType]: 'default'}));
            setErrorMessage((prevErrorMessage) => ({...prevErrorMessage, [state.fieldType]: ''}));
        }
    }

    function handleAdyenError(state: AdyenErrorState) {
        setStatus((prevStatus) => ({...prevStatus, [state.fieldType]: 'error'}));
        setErrorMessage((prevErrorMessage) => ({...prevErrorMessage, [state.fieldType]: state.errorI18n}));
    }

    /* Check wheather selected saved card is expired or not */
    function isCardExpired(savedCard: SavedCard){
        const now = new Date();
        const currentMonth = now.getMonth() + 1;
        const currentYear = now.getFullYear();
        const expiryYear = savedCard.expiryYear;
        const expiryMonth = savedCard.expiryMonth;
        return (currentYear > expiryYear || (currentYear === expiryYear && currentMonth >= expiryMonth)) ? true : false;
    }

    useEffect(() => {

        const configuration = {
            ...props.config.adyenCardCheckoutConfig,
            onChange: handleAdyenOnChange
        }
        
        const options = {
            type: 'card',
            brands: [savedCard.brand],
            styles: adyenCheckoutStyles,
            ariaLabels: adyenCheckoutAriaLabels,
            onValid: handleAdyenOnValid,
            onConfigSuccess: handleAdyenConfigSuccess,
            onFieldValid: handleAdyenFieldValid,
            onError: handleAdyenError,
        };
        (async() => {
            const checkout = await AdyenCheckout(configuration);

            if (isAdyenComponentLoaded.current) { // The component was already mounted and needs updating
                checkout.create('securedfields', options).mount('#customCard-container');
                checkout.update();
            }
            else { // The component has never been mounted
                checkout.create('securedfields', options).mount('#customCard-container');
                isAdyenComponentLoaded.current = true;
            }
            
        })()
    // eslint-disable-next-line
    }, [savedCard.id]);
    


    return (
        <Box margin={{top: 'small'}}>
            <Form>
                <SavedCardDetails savedCard={savedCard} />
                <div id="customCard-container">
                    <Box data-testid={`encryptedSecurityCode-container`}>
                        <label>
                            <Text size="medium" weight={theme?.config?.formFieldLabelWeight ?? 500}>{strings.cvv}</Text>
                            <Tip
                                content={
                                    <Box width={{ max: isDesktop? '400px' : 'large' }} >
                                        {strings.CVVInfo}
                                    </Box>
                                }
                                dropProps={{ align:{left: 'right'} }}
                            >
                                <Box style={infoIconStyles}>
                                    <CircleQuestion size="small" />
                                </Box>
                            </Tip>
                            <Box direction="row" style={{'position': 'relative'}}
                                pad={{'right':`calc(${iconSize} + ${boxPadSize} + ${inputPaddingHorizontal})`}}
                                width={cvvFieldWidth}
                            >
                                <StyledInputContainer
                                    status={status.encryptedSecurityCode}
                                    height={theme?.config?.adyenFormFieldHeight ?? '48px'}
                                    inputPaddingHorizontal={inputPaddingHorizontal}
                                    inputPaddingVertical={inputPaddingVertical}
                                    defaultBorderColor={defaultBorderColor}
                                    errorBorderColor={errorBorderColor}
                                    focusBorderColor={focusBorderColor}
                                    successBorderColor={successBorderColor}
                                >
                                    <span data-cse="encryptedSecurityCode"></span>
                                    {status.encryptedSecurityCode === 'success' ? <StyledStatusGoodIcon color={focusBorderColor} iconstyles={iconStyles} /> : null}
                                </StyledInputContainer>
                                <StyledCreditCardIcon iconstyles={iconStyles} data-testid="adyenCreditCardIcon" />
                            </Box>
                            { errorMessage.encryptedSecurityCode &&
                                <Text size="medium" color="red" margin={{top: 'xsmall'}}>{errorMessage.encryptedSecurityCode}</Text>
                            }
                        </label>
                    </Box>
                </div>
            </Form>
        </Box>
    );
}

type AdyenReturningCardFormProps = {
    config: {
        adyenCardCheckoutConfig: {
            locale: string,
            environment: string,
            clientKey: string,
        }
    },
};


export default AdyenReturningCardForm;
