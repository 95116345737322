import merge from 'deepmerge';
import { CustomTheme } from '.';
import sizeTheme from "./size";

const theme: CustomTheme = {
    config: {
        displayName: "Size FI",
        headerLogoSize: {height: '30px'},
    },
};

const sizefiTheme: CustomTheme = merge(sizeTheme, theme);

export default sizefiTheme;