import merge from 'deepmerge';
import { CustomTheme } from '.';
import './choice.css';
import choiceTheme from './choicegb';
import { TickError, TickFilledBase, TickSuccess } from "../components/Icon/index";

const theme: CustomTheme = {
    config: {
        displayName: "Base GB",
        titleFontWeight: 'bold',
        formFieldLabelWeight: 500,
        headerLogo: '/Logo-Base-Navigation.svg',
        headerLogoSize: {height: '72px', width: '72px'},
        stepFontWeight: 'bold',
        summaryShowTotalsTitle: false,
        giftCardShowLegacyInstruction: true,
        giftCardMaxCharacterLength: 25,
        fontSrc: '/fonts/redhat.css',
        icons: {
            tickIcon: TickFilledBase,
            successTickIcon: TickSuccess,
            errorTickIcon: TickError
        }
    },

    global: {
        font: {
            family: 'RedHatDisplay, sans-serif',
        },
        colors: {
            active: '#0274ff',
            red: {
                dark: '#be4949',
                light: '#be4949'
            },
            green: {
                dark: '#21ab8f',
                light: '#21ab8f'
            },
            "background-error": {
                dark: '#FFFFFF',
                light: '#FFFFFF',
            },
        },
    },
    text: {
        xsmall: {
            'size': '10px',
            'height': '1.33',
        },
        small: {
            'size': '12px',
            'height': '1.33',
        },
        medium: {
            'size': '14px',
            'height': '1.75'
        },
        large: {
            'size': '20px',
            'height': '1.75'
        },
        xlarge: {
            'size': '24px',
            'height': '1.75'
        },
        '2xl?': {}, // Error in module type definition ('2xl?' instead of '2xl'?) makes these mandatory
        '3xl?': {},
        '4xl?': {},
        '5xl?': {},
        '6xl?': {},
    },
    radioButton: {
        color: 'active',
        check: {
            // @ts-ignore
            color: 'active'
        }
    },
    checkBox: {
        icons: {
            checked: TickFilledBase,
        },
        check: {
            extend: (props) => {
                return props.checked ? `background: white; box-shadow: none !important; border-radius: 4px;` : `box-shadow: none !important; border-radius: 4px;`
            },
        },
        size: '16px',
        border: {
            color: '#9b9b9b',
            width: '1px'
        },
        extend: (props) => {
            return props.checked ? `div:hover { border-radius: 4px; }` : '';
        },
    },
    tab: {
        border: {
            active: {
                color: 'active'
            },
            hover: {
                color: 'active'
            }
        },
    },
    formField: {
        error: {
            background: 'background-error',
            color: 'red',
        },
        border: {
            error: {
                color: 'red',
            }
        },
        // @ts-ignore
        extend: (props) => {
            return props.className === 'valid' ? `div { border-color: #21ab8f; }` : '';
        }
    }
};

const baseTheme: CustomTheme = merge(choiceTheme, theme);

export default baseTheme;
