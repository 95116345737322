import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Box, Text, Button, Heading, ResponsiveContext } from "grommet";
import styled from "styled-components";
import { RootState } from "../../store/reducers";
import { translate } from "../../modules/translation";

const TextUnderlined = styled(Text)`
    text-decoration: underline;
`;

/**
 * This component shows a possible delivery location for Click & Collect.
 * It displays a logo, shop name and a description.
 * The click handlers send back events where currentTarget.value contains the store name.
 * 
 * @param icon {node} Logo of the store to collect from
 * @param name {string | node} Store name (required)
 * @param description {string | node} Description displayed at the bottom
 * @param distance {string} How far away from the user the store is. No unit to be provided (miles assumed)
 */
function StoreLocationItem({ icon, name, locationId, distance, distanceUnits, description, onSelect, onDetailsClick } : StoreLocationItemProps) {
    const translation = useSelector((state: RootState) => state.translation);
    const size = React.useContext(ResponsiveContext);
    const isDesktop = size !== 'small'; 

    const strings = {
        viewStoreDetails: translate(translation, 'viewStoreDetails', 'View Store Details'),
        select: translate(translation, 'select', 'Select'),
        miles: translate(translation, 'miles', 'Miles'),
    }

    return (
        <Box
            pad="small"
            gap="small"
            direction="row"
            fill="horizontal"
        >
            {/* This box will show only if an icon is provided. */
            icon &&
                <Box
                    pad="small"
                    align="center"
                    basis="xxsmall"
                    flex={{shrink: 0}}
                >
                    {icon}
                </Box>
            }
            <Box
                pad="small"
                gap="none"
                direction="column"
            >
                <Heading level={5} margin="none">{name}</Heading>
                <Text>{description}</Text>

                {/* View Store Details button. Will not show if no callback provided. */}
                <Button
                        label={onDetailsClick ? <TextUnderlined>{strings.viewStoreDetails}</TextUnderlined> : null}
                        plain={true}
                        value={locationId}
                        focusIndicator={false}
                        onClick={onDetailsClick}
                    />
            </Box>
            <Box
                pad="small"
                align="end"
                direction="column"
                justify="between"
                flex="grow"
            >
                <Text weight="bold" size={!isDesktop ? "medium" : "large"} style={!isDesktop ? { maxWidth : 100} : {} }>{distance} {distanceUnits ?? strings.miles}</Text>
                <Button
                    label={strings.select}
                    secondary={true}
                    value={locationId}
                    onClick={onSelect}
                />
            </Box>
        </Box>
    );
};

type StoreLocationItemProps = {
    icon?: React.ReactNode,
    name: string,
    locationId: string,
    distance: string,
    distanceUnits: string,
    description?: string | React.ReactNode,
    onSelect: (event: React.MouseEvent<HTMLButtonElement>) => void,
    onDetailsClick?: (event: React.MouseEvent<HTMLButtonElement>) => void,
};

StoreLocationItem.propTypes = {
    icon: PropTypes.node,
    name: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]).isRequired,
    locationId: PropTypes.string.isRequired,
    distance: PropTypes.string,
    description: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    onSelect: PropTypes.func.isRequired,
    onDetailsClick: PropTypes.func,
};

export default StoreLocationItem;
