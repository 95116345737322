import { EnsightenService, fireEnsightenEvent } from '../ensighten/ensighten';
import {
    OrderProduct, EnsightenOrder, EnsightenUser, EnsightenDeliveryMethod, EnsightenBasket, EnsightenCheckoutViewData,
    EnsightenLocationEnum, EnsightenFindAddressType, EnsightenPickupLocationSearchType, EnsightenPickupLocationSelectData,EnsightenMarketingPreferences, LoginData, RegisterData, EnsightenAddressChangeData,
} from '../ensighten/types';
import { Address, Customer } from '../../modules/customer/types';
import { Cart } from '../../modules/cart/types';
import { Billing, Payment, PaymentMethod } from '../../modules/billing/types';
import { DeliveryMethod, DeliveryCountry, Store, CandCLocation, StoreAddress } from '../../modules/delivery/types';
import { Order } from '../../modules/order/types';
import { local as browserLocalStorage } from "store2";

/**
 * Makes a 'User' Ensighten object based on the app Customer.
 * @param customer
 * @return {EnsightenUser}
 */
function getEnsightenUserFromAppCustomer(customer: Customer, sendMarketingData?: boolean, isNikeConnected?: boolean): EnsightenUser {
    const hasPreferences = browserLocalStorage.get('hasPreference');
    const storedCustomerId = browserLocalStorage.get('customerId');
    const user: EnsightenUser = {
        Forename: customer.firstName ?? '',
        Surname: customer.lastName ?? '',
        Email: customer.email,
        MobileNumber: customer.phone ?? '',
        IsGuest: customer.isOnGuestJourney ?? false,
        IPAddress: customer.ip ?? '',
    };
    if (sendMarketingData) {
        user.Marketing = getEnsightenMarketingFromAppCustomer(customer, isNikeConnected);
    }
    if (isNikeConnected) {
        user.IsNewlyRegistered = false;
        if (storedCustomerId && !hasPreferences) {
            user.IsNewlyRegistered = true;
        }
        else if (!storedCustomerId && !customer.isGuest && !hasPreferences) {
            user.IsNewlyRegistered = true;
        }
    }
    return user;
}

/**
 * Ensighten expects a user object for the address change events.
 * This makes a 'User' Ensighten object based on an app Address, and
 * fill-in the blanks with a customer object.
 * @param address
 * @param customer
 * @return {EnsightenUser}
 */
function getEnsightenUserFromAppAddressAndCustomer(address: Address, customer: Customer, sendMarketingData?: boolean, isNikeConnected?: boolean): EnsightenUser {
    const user: EnsightenUser = {
        Forename: address.firstName ?? '',
        Surname: address.lastName ?? '',
        Email: address.email,
        MobileNumber: address.phone ?? '',
        IsGuest: customer.isOnGuestJourney ?? false,
        IPAddress: customer.ip ?? '',
    };
    if (sendMarketingData) {
        user.Marketing = getEnsightenMarketingFromAppCustomer(customer, isNikeConnected);
    }
    return user;
}

/**
 * Makes a 'Marketing' Ensighten object based on the app Customer.
 * @param customer
 * @return {EnsightenMarketingPreferences}
 */
function getEnsightenMarketingFromAppCustomer(customer: Customer, isNikeConnected?: boolean): EnsightenMarketingPreferences {
    return {
        EmailOptIn: customer.marketingOptIns?.emailOptIn,
        SmsOptIn: customer.marketingOptIns?.smsOptIn,
        PostOptIn: customer.marketingOptIns?.postOptIn,
        ThirdPartyOptIn: isNikeConnected ? (customer.marketingOptIns?.emailOptIn || customer.marketingOptIns?.smsOptIn) : false
    };
}

/**
 * Makes a 'Basket' Ensighten object based on the app Cart.
 * @param cart
 * @return {EnsightenBasket}
 */
function getEnsightenBasketFromAppCart(cart: Cart): EnsightenBasket {
    const basket: EnsightenBasket = {
        Total: parseFloat(cart.balanceToPay.amount),
        SubTotal: parseFloat(cart.subtotal.amount),
        // Voucher: TODO where to get this? To check
        Products: cart.products.map((product): OrderProduct => ({
            Id: product.trackingSKU ?? "",
            LocaleId: product.trackingSKU ?? "",
            Name: `${product.name}`,
            Description: '',
            UnitPrice: parseFloat(product?.unitPrice?.amount),
            RRPUnitPrice: product.previousUnitPrice ? parseFloat(product.previousUnitPrice?.amount) : parseFloat(product.unitPrice?.amount),
            PreviousUnitPrice: product.previousUnitPrice ? parseFloat(product.previousUnitPrice?.amount) : parseFloat(product.unitPrice?.amount),
            Brand: product.brandName ?? "",
            Categories: [product.categories?.map(x => x.name) ?? [""]],
            IsDiscounted: product.isDiscounted ?? false,
            Colour: product.colour ?? "",
            Media: {
                Reference: product.image?.originalURL
            },
            Quantity: product.quantity,
            Variant: {
                Id: product.SKU?.split('.')[1],
                Upc: product.SKU?.split('.')[1], // TODO Upc seems equal to Variant.Id in practice but should come from product.UPC not available on the mesh cart. To check.
                Name: product.optionValues?.Size ?? '',
                IsInStock: true,
                IsDropship: product.stockPool?.isDropship,
            },
            TotalPrice: parseFloat(product.subtotal.amount),
        }))
    };
    if (cart.tax?.amount) basket.Tax = parseFloat(cart.tax.amount);

    return basket;
}

/**
 * Make an address as expected by Ensighten for post offices (CSV string with no postcode)
 * @param address Object containing address details
 * @return string Comma separated values of address data
 */
function getPostOfficeAddressAsCsv (address: StoreAddress) {
    let postOfficeAddress = address.address1;
    postOfficeAddress += address.address2 ? `,${address.address2}` : '';
    postOfficeAddress += address.address3 ? `,${address.address3}` : '';
    postOfficeAddress += address.town ? `,${address.town}` : '';
    postOfficeAddress += address.county ? `,${address.county}` : '';
    return postOfficeAddress;
}

export function ensightenAdaptor(): EnsightenService {

    async function trackLogin(customer: Customer, sendMarketingData: boolean): Promise<void> {
        const loginData: LoginData = {
            User: getEnsightenUserFromAppCustomer(customer, sendMarketingData),
            Location: EnsightenLocationEnum.Checkout,
        };
        fireEnsightenEvent('User.Login', loginData);
    }

    async function trackRegister(customer: Customer, sendMarketingData: boolean, isOnExpressJourney: boolean, isNikeConnected?: boolean): Promise<void> {
        const registerData: RegisterData = {
            Email: customer.email,
            FirstName: customer.firstName ?? '',
            LastName: customer.lastName ?? '',
            PhoneNumber: customer.phone ?? '',
            IsGuest: customer.isOnGuestJourney ?? false,
            Type: isOnExpressJourney ? 'express' : 'standard',
            Location: EnsightenLocationEnum.Checkout,
            IPAddress: customer.ip ?? '',
        };
        if (sendMarketingData) {
            registerData.Marketing = getEnsightenMarketingFromAppCustomer(customer, isNikeConnected)
        }
        fireEnsightenEvent('User.Register', registerData);
    }

    async function trackForgotPasswordLinkClick(): Promise<void> {
        fireEnsightenEvent('User.ForgotPassword', {Location: EnsightenLocationEnum.Checkout});
    }

    async function trackError(message: string, Exception: string): Promise<void> {
        fireEnsightenEvent('Checkout.Error', {Title: message, Body: Exception});
    }

    async function trackSelectDeliveryCountry(country: DeliveryCountry): Promise<void> {
        const countryData = {
            CountryName: country.name,
            CountryCode: country.code,
        };
        fireEnsightenEvent('Checkout.Delivery.SelectCountry', countryData);
    }

    async function trackFindAddress (type: EnsightenFindAddressType): Promise<void> {
        fireEnsightenEvent('Checkout.Delivery.FindAddress', {Type: type});
    }

    async function trackAddNewAddress (address: Address, customer: Customer, sendMarketingData: boolean, isExpress: boolean, isNikeConnected: boolean): Promise<void> {
        const addressChangeData: EnsightenAddressChangeData = {
            User: getEnsightenUserFromAppAddressAndCustomer(address, customer, sendMarketingData, isNikeConnected),
            Type: isExpress ? 'express' : 'standard',
        }
        fireEnsightenEvent('User.Addresses.Add', addressChangeData);
    }

    async function trackUpdateAddress (address: Address, customer: Customer, sendMarketingData: boolean, isExpress: boolean, isNikeConnected: boolean): Promise<void> {
        const addressChangeData: EnsightenAddressChangeData = {
            User: getEnsightenUserFromAppAddressAndCustomer(address, customer, sendMarketingData, isNikeConnected),
            Type: isExpress ? 'express' : 'standard',
        }
        fireEnsightenEvent('User.Addresses.Update', addressChangeData);
    }

    async function trackSelectDeliveryMethod(deliveryMethod: DeliveryMethod, cart: Cart): Promise<void> {
        const type = deliveryMethod.typeCategory === 'home' ? 'home'
            : deliveryMethod.typeCategory === 'clickAndCollect' ? 'store'
            : 'other';
        
        const methodData: EnsightenDeliveryMethod = {
            Type: type,
            MethodHumanName: deliveryMethod.displayName,
            Basket: getEnsightenBasketFromAppCart(cart)
        };
        fireEnsightenEvent('Checkout.Delivery.SelectMethod', methodData);
    }

    async function trackPickupLocationSearch(type: EnsightenPickupLocationSearchType): Promise<void> {
        fireEnsightenEvent('Checkout.Delivery.StoreSearch', {Type: type});
    }

    async function trackSelectPaymentMethod(paymentMethod: PaymentMethod, cart: Cart): Promise<void> {
        const methodData = {
            Name: paymentMethod.name,
            HumanName: paymentMethod.label,
            Basket: getEnsightenBasketFromAppCart(cart),
        };
        fireEnsightenEvent('Checkout.Payment.SelectMethod', methodData);
    }

    async function trackSelectStore(store: Store): Promise<void> {
        let methodData: EnsightenPickupLocationSelectData<'store'|'post-office'|'other'>;
        if (store.locationType === CandCLocation.JD) {
            methodData = {
                Type: 'store',
                Location: {
                    Id: store.ID,
                    FasciaName: store.fullFasciaName,
                    FasciaCode: store.fasciaCode,
                    Postcode: store.address.postcode,
                },
            } as EnsightenPickupLocationSelectData<'store'>;
        }

        else if (store.locationType === CandCLocation.PostOffice) {
            methodData = {
                Type: 'post-office',
                Location: {
                    Address: getPostOfficeAddressAsCsv(store.address),
                    PostCode: store.address.postcode,
                    Lat: store.latitude,
                    Long: store.longitude,
                    Distance: parseFloat(store.distance),
                },
            } as EnsightenPickupLocationSelectData<'post-office'>;
        }

        else {
            methodData = {
                Type: 'other',
                TypeName: 'other',
                Location: {
                    Id: store.ID,
                    Name: store.name,
                    Postcode: store.address.postcode,
                },
            } as EnsightenPickupLocationSelectData<'other'>;
        }

        fireEnsightenEvent('Checkout.Delivery.StoreSelect', methodData);
    }

    async function trackOrderConfirmation(sendMarketingData: boolean, cart: Cart, payment: Payment, deliveryMethod: DeliveryMethod, customer: Customer, billing: Billing, order: Order, isNikeConnected?: boolean): Promise<void> {
        const user = getEnsightenUserFromAppCustomer(customer, sendMarketingData, isNikeConnected);
        
        const IsRevisit = order.ID ? true : false; 
        const orderData: EnsightenOrder = {
            Id: payment.orderClientID,
            Delivery: {
                Name: deliveryMethod.displayName,
                Cost: parseFloat(deliveryMethod.price?.amount),
            },
            Payment: {
                Name: billing.selectedPaymentMethod.name,
                HumanName: billing.selectedPaymentMethod.label,
            },
            ...getEnsightenBasketFromAppCart(cart),
			Vouchers: cart.discountCodes,
            Type: billing.isOnExpressJourney ? 'express' : 'standard'
        };

        fireEnsightenEvent('View.Confirm', {User: user, Order: orderData, IsRevisit});
    }

    async function trackDeliveryView (cart: Cart, customer: Customer): Promise<void> {
        const user = getEnsightenUserFromAppCustomer(customer);
        const basket = getEnsightenBasketFromAppCart(cart);

        fireEnsightenEvent('View.Checkout.Delivery', {User: user, Basket: basket});
    }

    async function trackLoginOrRegisterView (): Promise<void> {
		// TODO: try to set the value of `Location` properly
		fireEnsightenEvent('View.Login', { Location: EnsightenLocationEnum.Checkout })
	}
    
    async function trackPaymentView (cart: Cart, customer?: Customer, isNikeConnected?: boolean): Promise<void> {
        const paymentViewData: EnsightenCheckoutViewData = {
            Basket: getEnsightenBasketFromAppCart(cart),
        };

        if (customer) paymentViewData.User = getEnsightenUserFromAppCustomer(customer, true, isNikeConnected);

        fireEnsightenEvent('View.Checkout.Payment', paymentViewData);
    }

    return {
        trackLogin,
        trackRegister,
        trackForgotPasswordLinkClick,
        trackSelectDeliveryCountry,
        trackFindAddress,
        trackAddNewAddress,
        trackUpdateAddress,
        trackSelectDeliveryMethod,
        trackPickupLocationSearch,
        trackSelectPaymentMethod,
        trackSelectStore,
        trackOrderConfirmation,
        trackDeliveryView,
        trackLoginOrRegisterView,
        trackPaymentView,
        trackError,
    };
}




function trackersAdaptor() {
    return {
        ensighten: ensightenAdaptor(),
    }
}

export default trackersAdaptor;
