import merge from 'deepmerge';
import { CustomTheme } from '.';
import footpatrolTheme from "./footpatrol";

const theme: CustomTheme = {
    config: {
        displayName: "Footpatrol DE",
        showAddress1And2OnSameLine: true,
    },
};

const footpatroldeTheme: CustomTheme = merge(footpatrolTheme, theme);

export default footpatroldeTheme;