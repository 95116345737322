// test-utils.js
import React from 'react'
import { render as rtlRender } from '@testing-library/react'
import rootReducer from '../store/reducers'
import { applyMiddleware, createStore } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { initialState as billingState } from './billing/index';
import { initialState as cartState } from './cart/index';
import { initialState as configState } from './config/index';
import { initialState as customerState } from './customer/index';
import { initialState as deliveryState } from './delivery/index';
import { initialState as orderState } from './order/index';
import { initialState as notificationState } from './notification/index';
import { initialState as sessionState } from './session/index';
import { initialState as translationState } from './translation/index';


export const initialState = {
    billing: billingState,
    cart: cartState,
    config: configState,
    customer: customerState,
    delivery: deliveryState,
    order: orderState,
    notification: notificationState,
    session: sessionState,
    translation: translationState,
};

/**
 * This function superesedes the render function from testing-library.
 * This wraps the provided react element with a <Provider> node that has a store
 * made from the state object passed in parameter.
 * Typical use in test files: render(<PaymentMethodSelector />, { initialState: state })
 * @param {React node} ui the component to render
 * @param {object} param1 
 */
function render(
    ui,
    {
        initialState,
        store = createStore(rootReducer, initialState, applyMiddleware(thunk)),
        ...renderOptions
    } = {}
) {
    function Wrapper({ children }) {
        return <Provider store={store}>{children}</Provider>
    }
    return rtlRender(ui, { wrapper: Wrapper, ...renderOptions })
}



/**
 * Wraps a JSX element in a Provider and creates a store for it based on the state object provided (defaults to initial state)
 * @param {React node} element The component to display
 * @param {*} state full state object
 */
function storyWrapper(element, state = initialState) {
    const store = createStore(rootReducer, state, applyMiddleware(thunk), );
    return <Provider store={store}>{element}</Provider>
}


// Re-export all the testing-library but override its 'render' function
export * from '@testing-library/react'
export { render, storyWrapper };
