
import adaptor from '../services/adaptors';
import { AppResponse } from './response';
import { Customer, Address, CustomerPreferences } from '../modules/customer/types';
import { Order } from '../modules/order/types';
import { CustomerPreferenceType } from './mesh-sdk/customers';
import { RootState } from '../store/reducers';
import { SavedCard } from "../modules/billing/types";

export interface CustomerService {
    login: (username: string, password: string) => Promise<AppResponse<Customer>>,
    getCustomer: (customerId: string) => Promise<AppResponse<Customer>>,
    getCustomerOrder: (customerId: string, orderId: string) => Promise<AppResponse<Order>>
    create: (username: string, isGuest: boolean, extraCustomerData: Partial<Customer>) => Promise<AppResponse<Customer>>,
    createAndAuth: (username: string, password: string, firstName: string, lastName: string, isGuest: boolean, extraCustomerData: Partial<Customer>) => Promise<AppResponse<Customer>>,
    promoteCustomer: (customerID: string, password: string) => Promise<AppResponse<Customer>>,
    exists: (username: string) => Promise<AppResponse<boolean>>,
    saveAddress: (customerID: string, address: Address, isCAndC?: boolean) => Promise<AppResponse<Address>>,
    updateAddress: (customerID: string, address: Address) => Promise<AppResponse<Address>>,
    updateCustomer: (customerID: string, customer: Customer) => Promise<AppResponse<Customer>>,

    /**
     * Merges the provided updates with the existing customer data, not changing anything else.
     */
    patchCustomer: (customerId: string, updates: {[key: string]: any}) => Promise<AppResponse<Customer>>,
    deleteSavedCard: (customerID: string, cardId: string) => Promise<AppResponse<SavedCard[]>>,
    getCustomerPreferences: (customerID: string) => Promise<AppResponse<CustomerPreferences>>,
    createCustomerPreference: (customerID: string, preferenceType: CustomerPreferenceType, preferenceValue: string) => Promise<AppResponse<boolean>>,
    updateCustomerPreference: (customerID: string, preferenceId: string, preferenceType: CustomerPreferenceType, preferenceValue: string) => Promise<AppResponse<boolean>>,
}


function init(state: RootState){
    const service = adaptor('mesh', 'customers', state) as CustomerService;
    return service;
}

export default init;