import React from 'react';
import Icon from './Icon';
import { IconProps } from './Icon';
import theme from '../../theme';

export const AddToBag = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="add-to-bag" /> }
export const Afterpay = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="afterpay" /> };
export const AliPay = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="ali-pay" /> }
export const Amex = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="amex" /> }
export const ApplePay = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="apple-pay" /> };
export const Arrow = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="arrow" /> };
export const ArrowTessuti = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="arrow-tessuti" /> };
export const ArrowDisabledTessuti = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="arrow-disabled-tessuti" /> };
export const ArrowDown = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="arrow-down" /> };
export const ArrowUp = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="arrow-up" /> };
export const ArrowRight = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="arrow-right" /> };
export const Bag = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="bag" /> };
export const BurgerMenu = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="burger-menu" /> };
export const Boost = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="boost" /> };
export const Close = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="close" /> };
export const CVV = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="cvv" /> };
export const Chat = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="chat" /> };
export const Clearpay = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="clearpay" /> };
export const ClearpayLoop = ({ ...props }: IconProps): JSX.Element => {return <Icon {...props} name="clearpay-loop" />};
export const Delivery = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="delivery" /> };
export const Edit = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="edit" /> };
export const Facebook = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="facebook" /> };
export const MyAccount = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="my-account" /> };
export const Help = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="help" /> };
export const Information = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="information" /> };
export const Ideal = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="ideal" /> };
export const Zippay = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="zippay" /> };
export const JDNavigation = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="jd-navigation"/> };
export const JDFascia = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="jd-fascia" /> };
export const SizeFascia = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="size-fascia" /> };
export const FootpatrolFascia = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="footpatrol-fascia" /> };
export const OipolloiFascia = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="oipolloi-fascia" /> };
export const WellgoshFascia = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="wellgosh-fascia" /> };
export const HipstoreFascia = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="hipstore-fascia" /> };
export const ScottsFascia = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="scotts-fascia" /> };
export const TessutiFascia = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="tessuti-fascia" /> };
export const Trustly = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="trustly" /> }
export const Klarna = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="klarna" /> };
export const KlarnaLogo = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="klarna-logo" /> };
export const Laybuy = ({ ...props}: IconProps): JSX.Element => { return <Icon {...props} name="laybuy" /> };
export const Giropay = ({ ...props}: IconProps): JSX.Element => { return <Icon {...props} name="giropay" /> };
export const Shopback = ({ ...props}: IconProps): JSX.Element => { return <Icon {...props} name="shopback" /> };
export const Fpx = ({ ...props}: IconProps): JSX.Element => { return <Icon {...props} name="fpx" /> };
export const Maestro = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="maestro" /> }
export const Mastercard = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="mastercard" /> }
export const Maybank = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="maybank" /> }
export const Nike = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="nike" /> };
export const Openpay = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="openpay" /> };
export const PayPal = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="paypal" /> };
export const PayPalPayInThree = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="paypal-pay-in-three" /> };
export const GooglePay = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="googlepay" /> };
export const Refresh = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="refresh" /> };
export const Returns = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="returns" /> };
export const Sale = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="sale" /> };
export const SearchNavigation = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="search-navigation"/> };
export const Security = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="security-fill" /> };
export const SecurityLock = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="security-lock" /> };
export const SecurityOutline = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="security-outline" /> };
export const Shop = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="shop" /> };
export const TickFilled = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} fill={theme?.global?.colors?.green as string ?? '#4bd4b0'} name="tick-filled" /> };
export const TickFilledChoice = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="tick-filled-choice" /> };
export const TickFilledBase = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="tick-filled-base" /> };
export const TickFilledBlackRounded = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="tick-filled-black-rounded" /> };
export const TickFilledTessuti = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} fill={theme?.global?.colors?.green as string ?? '#5B9E76'} name="tick-filled" /> };
export const TickFilledScotts = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} fill={theme?.global?.colors?.green as string ?? '#74E779'} name="tick-filled" /> };
export const TickSuccess = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="tick-success" /> };
export const TickError = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="tick-error" /> };
export const TickEmpty = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="tick-empty" /> };
export const TickEmptyChoice = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="tick-empty-choice" /> };
export const Tagstar = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="tagstar" /> };
export const TagstarInDemand = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="tagstar-in-demand"/> };
export const Twitter = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="twitter" /> };
export const UnionPay = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="union-pay" /> };
export const QuantityMinus = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="quantity-minus" /> };
export const QuantityPlus = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="quantity-plus" /> };
export const Visa = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="visa" /> };
export const WeChat = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="we-chat" /> };
export const PostOffice = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="post-office"/> };
export const Heart = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="heart"/> };
export const Preferences = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="preferences"/> };
export const Order = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="order"/> };
export const HidePassword = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="hide-password"/> };
export const ShowPassword = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="show-password"/> };
export const Location = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="location"/> };
export const KlarnaDebitRisk = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="klarna-debit-risk"/> };
export const Touch = ({...props}: IconProps): JSX.Element => { return <Icon {...props} name="touchn" />};
export const Grabpay = ({ ...props }: IconProps): JSX.Element => { return <Icon {...props} name="grabpay" /> };


