import { IconProps } from '../../components/Icon/Icon';
export const UPDATE_NOTIFICATION = 'checkout/errorhandler/UPDATE_NOTIFICATION';
export const UPDATE_DIALOG = 'checkout/errorhandler/UPDATE_DIALOG';
export const UPDATE_GENERIC_ERROR = 'checkout/errorhandler/UPDATE_GENERIC_ERROR';
export const CLEAR_NOTIFICATION = 'checkout/errorhandler/CLEAR_NOTIFICATION';

export interface NotificationState {
    notificationCode: string,
    notificationData?: NotificationExtraData,
    genericErrorType: string,
    genericErrorData: any,
    dialogCode: string,
    dialogData?: DialogExtraData,
}

interface UpdateNotification {
    type: typeof UPDATE_NOTIFICATION,
    payload: {
        notificationCode: string,
        notificationData: NotificationExtraData,
    },
}

interface ClearNotification {
    type: typeof CLEAR_NOTIFICATION,
}

interface UpdateDialog {
    type: typeof UPDATE_DIALOG,
    payload: {
        dialogCode: string,
        dialogData: DialogExtraData,
    },
}

interface UpdateGenericError {
    type: typeof UPDATE_GENERIC_ERROR,
    payload: {
        genericErrorType: string,
        genericErrorData: any,
    },
}

export type NotificationExtraData = {
    // Variables that should be used by specific error messages
    deletedProductsCount?: number,
    code?: string,
    message?: string,
    removeDropshipProducts?: boolean,
    removeRestrictedProducts?: boolean,
    methodName?: string,
    skusToRemove?: string[];
}

export type DialogExtraData = {
    // Variables that should be used by specific dialog modals
    content?: string,
    title?: string,
    removeDropshipProducts?: boolean,
    removeRestrictedProducts?: boolean,
    skusToRemove?: string[];
}

export type NotificationActionTypes = UpdateNotification | ClearNotification | UpdateDialog | UpdateGenericError;

export interface NotificationDetails {
    type: 'error' | 'info',
    hideNotificationCloseButton?: boolean,
    message: string,
    code: string,
    anchorText?: string,
    href?: string,
    onClick?: () => void,
    icon?: ({ ...props }: IconProps) => JSX.Element,
    backgroundColor?: string,
    textColor?: string,
    fontWeight?: number | 'normal' | 'bold',
}

export interface DialogDetails {
    title?: string,
    showCloseIcon: boolean,
    body: JSX.Element,
    buttons: {
        label: string,
        onClick: () => void,
    }[],
}
