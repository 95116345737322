import React, { useContext, useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clone from 'rfdc';
import { Box, Text, Layer, Button, ResponsiveContext, Form } from 'grommet';
import { persistor } from '../../store';
import { Heart, Order as OrderIcon, Preferences } from '../Icon/index';
import { ThemeContext } from 'styled-components';
import { CustomTheme } from '../../theme/index';
import { RootState } from '../../store/reducers';
import { localisedDate } from '../../lib/date/date';
import { createDataObject } from '../../lib/dataObject/dataObject';
import { AppThunkDispatch } from '../../modules/thunk';
import {
    getSelectedMethod, getSelectedDeliveryOption, getSelectedStore,
    selectStoreForDetailedView, getShowStoreDetailedView, getCollectionDetails,
    getStoreForDetailedView, getDeliveryAddress
} from '../../modules/delivery';
import {
    getOrder, updateOrder,
} from '../../modules/order';
import { Order } from '../../modules/order/types';
import {
    getReturnURL, getPrivacyPolicy,
    getLocalisation, getGiftCardSkuDetails, getShowMarketingCheckboxes,
} from '../../modules/config';
import {
    getSelectedPaymentMethod, getPayment, getSelectedSavedCard,
    getNewCard, getIsOnExpressJourney, getBillingAddress
} from '../../modules/billing';
import { putSession, getSession } from '../../modules/session';
import { PageRefContext } from '../Page/Page';
import Step from '../Step/Step';
import InfoBox from "../InfoBox/InfoBox";
import ProductsSummary from '../ProductsSummary/ProductsSummary';
import EditableDetails from '../EditableDetails/EditableDetails';
import Currency from '../Currency/Currency';
import StoreDetails from '../StoreDetails/StoreDetails';
import Separator from '../Separator/Separator';
import TotalSummary from '../TotalSummary/TotalSummary';
import FormattedAddress from '../FormattedAddress/FormattedAddress';
import LoadingIcon from '../LoadingIcon/LoadingIcon';
import { tracking, customers, orders } from '../../services';
import ValidatedInput from '../ValidatedInput/ValidatedInput';
import StoreDetailsDesktop from '../StoreDetailsDesktop/StoreDetailsDesktop';
import { maxLength30Rule, passwordRule, requiredRule } from '../../lib/validate/validate';
import {
    getIsOnGuestJourney, promoteCustomer,
    createOrUpdateCustomerPreferences, getAuthToken, getMarketingOptIns, getReturning,
} from '../../modules/customer';
import { getEgiftcardDetails, getOrderDetailsFromState } from '../../modules/cart';
import { updateNotification, updateDialog, clearNotification } from '../../modules/notification';
import { getPhraseWithLink, translate } from '../../modules/translation';
import { getPaymentMethodRenderedExtras, getIconForCardType } from "../../lib/paymentMethodRenderedExtras/paymentMethodRenderedExtras";
import newRelicData from '../../newrelic';
import { local as browserLocalStorage } from "store2";
import qualtricsSurvey from "../../qualtricsSurvey";
import { useReactToPrint } from 'react-to-print';

let intialRender = false;

function Confirmation() {
    const logOnce = useRef(intialRender);
    if (!logOnce.current) {
        newRelicData({ actionName: 'confirmation', function: 'confirmation', message: 'confirmation page function started executing'});
        intialRender = true;
    }

    const theme: CustomTheme = useContext(ThemeContext);

    const dispatch: AppThunkDispatch = useDispatch();
    const delivery = useSelector((state: RootState) => state.delivery);
    const cart = useSelector((state: RootState) => state.cart);
    const billing = useSelector((state: RootState) => state.billing);
    const config = useSelector((state: RootState) => state.config);
    const customer = useSelector((state: RootState) => state.customer);
    const orderState = useSelector((state: RootState) => state.order);
    const sessionState = useSelector((state: RootState) => state.session);
    const translation = useSelector((state: RootState) => state.translation);

    const payment = getPayment(billing);
    const selectedStore = getSelectedStore(delivery);
    const selectedDeliveryOption = getSelectedDeliveryOption(delivery);
    const selectedDeliveryMethod = getSelectedMethod(delivery);
    const deliveryAddress = getDeliveryAddress(delivery);
    const selectedPaymentMethod = getSelectedPaymentMethod(billing);
    const isExpress = getIsOnExpressJourney(billing);
    const savedCard = getSelectedSavedCard(billing);
    const billingAddress = getBillingAddress(billing);
    const showStoreDetailedView = getShowStoreDetailedView(delivery);
    const storeForDetailedView = getStoreForDetailedView(delivery);
    const collectionDetails = getCollectionDetails(delivery);
    const returnURL = getReturnURL(config);
    const privacyPolicy = getPrivacyPolicy(config);
    const localisation = getLocalisation(config);
    const authToken = getAuthToken(customer);
    const isOnGuestJourney = getIsOnGuestJourney(customer);
    const isReturning = getReturning(customer);
    const isOnExpressJourney = getIsOnExpressJourney(billing);
    let order = getOrder(orderState);
    const paymentIcon = getPaymentMethodRenderedExtras(selectedPaymentMethod);

    const isHomeDelivery = selectedDeliveryOption.value === 'home';
    const isClickAndCollect = selectedDeliveryOption.value === 'clickAndCollect';
    const isTotalSeparatorAbove = theme?.config?.summarySeparatorIsAbove ?? false;
    const giftcardSku = getGiftCardSkuDetails(config);
    const cartHasOnlyGiftCardProducts = order.products.every(product => product.trackingSKU === giftcardSku.eGiftcardSku);
    const egiftcardDetails = getEgiftcardDetails(cart, config, translation);
    
    const [passwordCheckInProgress, setPasswordCheckInProgress] = useState(false);
    const [isGuestPromoted, setIsGuestPromoted] = useState(false);
    const useOrderDetailsFromState = useRef(false);
    const isNikeConnected = config.nike_connected;
    const storedCustomerID = browserLocalStorage.get('customerId');
    const hasPreferences = browserLocalStorage.get('hasPreference');
    const qualtricsEnabled = config.enable_qualtrics;

    const printGiftReceiptRef = useRef<HTMLInputElement>(null);

    if (useOrderDetailsFromState.current) {
        order = getOrderDetailsFromState(cart, delivery, billing, customer);
    }

    order = {
        ...order,
        deliveryAddress: {
            ...order.deliveryAddress,
            country: delivery.address.country,
        },
        billingAddress: {
            ...order.billingAddress,
            country: billing.address.country,
        }
    };

    const strings = {
        errorOccurred: translate(translation, 'errorOccurred', 'An error occurred.'),
        expiryDate: translate(translation, 'expiryDate', 'Expiry Date'),
        thanksForOrderName: translate(translation, 'thanksForOrderName', `Thank you for your order, ${order.customer.firstName}`, {firstName: order.customer.firstName}),
        orderNumber: translate(translation, 'orderNumber', 'Order Number'),
        knowOrderBeenDispatched: translate(translation, 'knowOrderBeenDispatched', "We’ll let you know once your item(s) have been dispatched."),
        deliveryAddress: translate(translation, 'deliveryAddress', 'Delivery Address'),
        storeAddress: translate(translation, 'storeAddress', 'Store Address'),
        deliveryMethod: translate(translation, 'deliveryMethod', 'Delivery Method'),
        deliverySlot: translate(translation, 'deliverySlot', 'Delivery Slot'),
        paymentMethod: translate(translation, 'paymentMethod', 'Payment Method'),
        billingAddress: translate(translation, 'billingAddress', 'Billing Address'),
        sameAsDeliveryAddress: translate(translation, 'sameAsDeliveryAddress', 'Same as delivery address'),
        totalsSummary: translate(translation, 'totalsSummary', 'Totals Summary'),
        continueShopping: translate(translation, 'continueShopping', 'Continue Shopping'),
        guestSignUpTitle: translate(translation, 'guestSignUpTitle', 'Want to check out faster next time?'),
        guestSignUpText: translate(translation, 'guestSignUpText', 'Simply enter a password below to create an account. Next time you shop with us all of your delivery details will be saved for fast and easy checkout.'),
        guestSignupCTA: translate(translation, 'guestSignupCTA', 'Save your details and checkout faster'),
        guestTrackYourOrders: translate(translation, 'guestTrackYourOrders', 'Track your orders easily'),
        guestShareWishlist: translate(translation, 'guestShareWishlist', 'Create and share your wishlist'),
        createPassword: translate(translation, 'createPassword', 'Create password'),
        enterPassword: translate(translation, 'enterPassword', 'Enter password'),
        createAccount: translate(translation, 'createAccount', 'Create account'),
        marketingToUpdatePreferences: translate(translation, 'marketingToUpdatePreferences', 'To update your preferences, please (click here).'),
        marketingToOptOutPreferences: translate(translation, 'marketingToOptOutPreferences', 'If you would like to opt out from hearing from us, please (click here).'),
        clickHere: translate(translation, 'clickHere', 'Click Here'),
        privacyNoticeInfo: translate(translation, 'privacyNoticeInfo', 'We will use your information in accordance with our (privacy notice).'),
        updatedOnDate: translate(translation, 'updatedOnDate', '', {date: privacyPolicy.date}),
        symbolsInPassword: translate(translation, 'symbolsInPassword', 'Password must be at least 8 characters, containing at least one character, one digit and one symbol'),
        noSymbolsInPassword: translate(translation, 'noSymbolsInPassword', 'Password must be at least 8 characters, containing at least one character, one digit and no symbol'),
        homeDelivery: translate(translation, 'homeDelivery', 'Home Delivery'),
        cAndC: translate(translation, 'cAndC', 'Click & Collect'),
        collectionDetails: translate(translation, 'collectionDetails', 'Collection Details'),
        printGiftReceipt: translate(translation, 'printGiftReceipt', 'Print Gift Receipt'),
    };
    const showTotalsTitle = theme?.config?.summaryShowTotalsTitle;
    let privacyPolicyTextString = strings.privacyNoticeInfo;
    if (privacyPolicy.date) privacyPolicyTextString += ` ${strings.updatedOnDate}`;
    const privacyPolicyText = getPhraseWithLink(privacyPolicyTextString, privacyPolicy.link, {size: 'xsmall'});

    const isBillingSameAsDelivery = order.billingAddress.firstName === order.deliveryAddress.firstName
        && order.billingAddress.lastName === order.deliveryAddress.lastName
        && order.billingAddress.address1 === order.deliveryAddress.address1
        && order.billingAddress.address2 === order.deliveryAddress.address2
        && order.billingAddress.address3 === order.deliveryAddress.address3
        && order.billingAddress.postcode === order.deliveryAddress.postcode
        && order.billingAddress.town === order.deliveryAddress.town
        && order.billingAddress.country === order.deliveryAddress.country;


    const pageRef = useContext(PageRefContext);

    // uses tick icon dependent on theme
    const tickIcon = theme?.config?.icons?.tickIcon ? React.createElement(theme?.config?.icons?.tickIcon) : null;
    const size = React.useContext(ResponsiveContext);
    const isDesktop = size !== 'small';

    //Pull icon size from theme.
    const confirmationGuestCheckoutIconSize: string = theme?.config?.confirmationGuestCheckoutIconSize ?? '50px';

    // To print the order confirmation details
    const handlePrint = useReactToPrint({
        contentRef: printGiftReceiptRef,
        documentTitle: 'Order Confirmation',
        onAfterPrint: () => newRelicData({ actionName: 'confirmation', function: 'handlePrint', message: 'Print Successfull!'}),
    });

    useEffect(() => {
        
        async function loadExternalOrder() {
            newRelicData({ actionName: 'confirmation', function: 'useEffect', message: 'in loadExternalOrder function'});
            let orderLoaded: Order|null = null;
            if (!order.ID) {
                newRelicData({ actionName: 'confirmation', function: 'useEffect', message: 'inside !order.ID if block'});
                if (payment.invoiceID) {
                    const orderResponse = await orders()?.getOrder(payment.invoiceID);
                    orderLoaded = orderResponse.data;
                    dispatch(updateOrder(orderLoaded));
                    newRelicData({ actionName: 'confirmation', function: 'useEffect', message: 'updated the order to the store'});
                }
                else if (authToken && customer.ID) {
                    const customerOrderResponse = await customers()?.getCustomerOrder(customer.ID, payment.orderClientID);
                    orderLoaded = customerOrderResponse.data;
                    dispatch(updateOrder(orderLoaded));
                    newRelicData({ actionName: 'confirmation', function: 'useEffect', message: 'updated the order to the store from else if block'});
                }
                else {
                    useOrderDetailsFromState.current = true;
                    newRelicData({ actionName: 'confirmation', function: 'useEffect', message: 'used order details from the state'});
                }
            }

            // (Express only) inform Ensighten of a new guest user if it has been created outside of this app.
            if (isOnExpressJourney && (!storedCustomerID || (storedCustomerID && !hasPreferences))) {
                newRelicData({ actionName: 'confirmation', function: 'useEffect', message: 'is on express journey'});
                if (orderLoaded?.customer) {
                    newRelicData({ actionName: 'confirmation', function: 'useEffect', message: 'inside orderLoaded?.customer'});
                    orderLoaded.customer = { ...orderLoaded.customer, isOnGuestJourney: customer.isOnGuestJourney}
                }
                const customerForEnsighten = clone()(orderLoaded?.customer ?? getOrderDetailsFromState(cart, delivery, billing, customer).customer);
                customerForEnsighten.marketingOptIns = getMarketingOptIns(customer);
                tracking().trackRegister(customerForEnsighten, getShowMarketingCheckboxes(config), isOnExpressJourney, isNikeConnected);
            }

            // We remove the cart from the website
            const session = getSession(sessionState);
            dispatch(putSession({
                ...session,
                cartID: '',
                commands: {
                    ...session.commands,
                    invalidateCartCache: true,
                }
            }));
            newRelicData({ actionName: 'confirmation', function: 'useEffect', message: 'removed cart id from session' });
        }
        loadExternalOrder();

        if (payment.isPaid) {
            newRelicData({ actionName: 'confirmation', function: 'useEffect', message: 'inside payment.isPaid if block' });
            try {
                // if the payment has gone through we can remove the 
                // saved state from the storage
                newRelicData({ actionName: 'confirmation', function: 'useEffect', message: 'inside try block'});
                persistor.purge();
                const sendMarketingData = getShowMarketingCheckboxes(config);
                let registeredUserPrefrences = true;
                if(isNikeConnected && hasPreferences) {
                    registeredUserPrefrences = false;
                }
                if(config.custom_order_tracking){
                    tracking()?.trackOrderConfirmation(registeredUserPrefrences ? sendMarketingData : false, cart, payment, selectedDeliveryMethod, customer, billing, order, isNikeConnected);
                } else {
                    tracking()?.trackOrderConfirmation(registeredUserPrefrences ? sendMarketingData : false, cart, payment, selectedDeliveryMethod, customer, billing, order, isNikeConnected);
                }
            } catch (e) {
                newRelicData({ actionName: 'confirmation', function: 'useEffect', message: { msg:'inside catch block Unable to track order', error: (e as Error).message}});
                console.warn('Unable to track order.')
            }
            if (!isOnExpressJourney || (isOnExpressJourney && storedCustomerID && !hasPreferences)) dispatch(createOrUpdateCustomerPreferences());
        }
        
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(order.ID) {
         newRelicData({ actionName: 'confirmation', function: 'Confirmation', message: "order created", orderId: order.ID, paymentName: selectedPaymentMethod.name, isExpress: isExpress, deliveryMethod: `${selectedDeliveryMethod.displayName} - ${selectedDeliveryMethod.ID}`, deliveryAddressId: deliveryAddress.ID, billingAddressId: billingAddress.ID, cartItemCount: cart.count })
        }
        // eslint-disable-next-line
    }, [order.ID]);

    const passwordHelp = useSelector((state: RootState) => {
        if (state.config.localisation?.passwordHelpText) {
            return state.config.localisation.passwordHelpText;
        }
        if (state.config.localisation.symbolsInPassword) {
            return strings.symbolsInPassword;
        }
        else {
            return strings.noSymbolsInPassword;
        }
    });
    const deliverySlot = {date: '', time: ''};
    if (order.selectedDeliveryDate) {
        deliverySlot.date = localisedDate(order.selectedDeliveryDate.date, localisation.language.code);
        deliverySlot.time = `${order.selectedDeliveryTimeSlotStart?.substr(0, 5)} - ${order.selectedDeliveryTimeSlotEnd?.substr(0, 5)}`;
    }
    if(order.ID) {
        //to add dataobject to window on the confirmation page
        const displayName = theme?.config?.displayName ?? ''; 
        createDataObject(cart, config, billing, customer, order, displayName);
        qualtricsSurvey(qualtricsEnabled, config, customer);
    }

    // Store Details table to be shown either in a <Box> (desktop) or a <Layer> (mobile)
    const storeDetails = selectedStore.ID
        ? <StoreDetails
            store={selectedStore}
            onGetDirections={() => { }}
            onCallStore={() => { }}
            onClose={() => dispatch(selectStoreForDetailedView(''))}
        />
        : <></>;

    
    if (!payment.isPaid || !payment.orderClientID) {
        newRelicData({ actionName: 'confirmation', function: 'Confirmation', message: 'some error occured while creating the order' })
        return (
            <Text>{strings.errorOccurred}</Text>
        );
    }
    else if (!order.ID) {
        // Show a blank loading screen while the final order object is loading.
        newRelicData({ actionName: 'confirmation', function: 'Confirmation', message: 'Show a blank loading screen' });
        return (
            <Box
                flex="grow"
                justify="center"
                gap="medium"
            >
                <LoadingIcon color="black" size="50px"/>
            </Box>
        );
    }

    let cardDetailsDescription: string | React.ReactNode = '';
    let expiryDate: string = '';
    
    if(selectedPaymentMethod.name === 'CARD') {
        if (savedCard?.name) {
            expiryDate = `${(savedCard.expiryMonth + '').padStart(2)}/${(savedCard.expiryYear + '').substr(2)}`;
        }
        
        const newCard = getNewCard(billing);    
        const brandName = savedCard.brand || newCard.brand;
        const holderName = savedCard.holderName || newCard.holderName;
        const lastFour = savedCard.lastFour || newCard.lastFour;
        const stringDate = expiryDate || newCard.expiryDate;
        const brandDetails = getIconForCardType(brandName);

        /* Hide card details for phase 1, need to show card details on next phase */
        cardDetailsDescription = <Box fill="horizontal" style={{ display: "none"}}>
                {brandDetails.name ? <Text>{brandDetails.name} (**** **** **** {lastFour})</Text> : ''}
                <Text>{holderName}</Text>
                <Text>{strings.expiryDate}: {stringDate}</Text>
            </Box>;
        
        paymentIcon.icon = <Box style={{ display: "none"}}>{brandDetails.icon}</Box>
    }
    let collectionData =<Box></Box>;
    if(collectionDetails.notYouCollectingFirstName && collectionDetails.notYouCollectingFirstName && collectionDetails.notYouCollectingPhone) 
    {
        collectionData = <Box>
                <Text> {collectionDetails.notYouCollectingFirstName} {collectionDetails.notYouCollectingLastName}</Text> 
                <Text>{collectionDetails.notYouCollectingPhone}</Text>
            </Box>;
    }
    else {
        collectionData = <Box>
                <Text> {collectionDetails.firstName} {collectionDetails.lastName}</Text> 
                <Text>{collectionDetails.phone}</Text>
            </Box>;

    }
    if(order.paymentDetails.method === 'HOOLAH_BNPL'){
        order.paymentDetails.method = 'SHOPBACK'
    }

    return (
        <>
            <Box fill="horizontal" flex="grow">
                <Box ref={printGiftReceiptRef}>
                    {/* Confirmation Message */}
                    <Step>
                        <Box direction="column">
                            <Text
                                size="xlarge"
                                weight={theme?.config?.titleFontWeight}
                                style={{
                                    'textTransform': theme?.config?.stepTextTransform ?? "unset"
                                }}
                            >
                                {strings.thanksForOrderName}
                            </Text>
                            <Text size="large" weight={theme?.config?.titleFontWeight}>{strings.orderNumber} #{order.ID}</Text>
                            <Text>{strings.knowOrderBeenDispatched}</Text>
                        </Box>
                    </Step>

                    {/* List of Products */}
                    <Step>
                        <ProductsSummary />
                    </Step>

                    {/* Home Delivery Address */}
                    {isHomeDelivery && !cartHasOnlyGiftCardProducts &&
                        <Step title={strings.deliveryAddress}>
                            <Box direction="column">
                                <EditableDetails
                                    main={<FormattedAddress type="homeAddress" details={order.deliveryAddress} />}
                                />
                            </Box>
                        </Step>
                    }

                    {/* C&C Delivery Address */}
                    {isClickAndCollect &&
                        <Step title={strings.storeAddress}>
                            <Box direction="column" fill="horizontal">
                                <Box direction="column">
                                    <EditableDetails
                                        title={selectedStore.name}
                                        main={<FormattedAddress type="storeAddress" details={order.deliveryAddress} />}
                                        isDetailsButton={!(isDesktop && showStoreDetailedView && storeForDetailedView.ID === selectedStore.ID)}
                                        onDetailsClick={() => dispatch(selectStoreForDetailedView(selectedStore.ID))}
                                    />

                                    {/* C&C Store Details in Desktop only */}
                                    {isDesktop && showStoreDetailedView && storeForDetailedView.ID === selectedStore.ID &&
                                        <Box margin={{top: 'large'}}>
                                            <StoreDetailsDesktop store={selectedStore} />
                                        </Box>
                                    }
                                </Box>
                                <Separator margin={{ vertical: "large" }} />
                                <Text weight={theme?.config?.titleFontWeight}>{strings.collectionDetails}</Text>
                                <EditableDetails
                                    main={(
                                        collectionData
                                    )}
                                />
                            </Box>
                        </Step>
                    }

                    {/* Delivery Method */}
                    <Step title={strings.deliveryMethod}>
                        <Box direction="column">
                        { selectedDeliveryMethod.displayName === order.deliveryMethod.name || isClickAndCollect
                            ?
                            <Box>
                                <EditableDetails
                                    title={selectedDeliveryMethod.displayName}
                                    main={selectedDeliveryMethod.description}
                                />
                                {!cartHasOnlyGiftCardProducts &&
                                    < Currency
                                        amount={selectedDeliveryMethod.price.amount}
                                        currency={selectedDeliveryMethod.price.currency}
                                        textFormat={{weight: 'bold'}}
                                    />
                                }
                            </Box>
                            : // If mismatch, we display the limited data from the order
                            <Box direction="column">
                                <EditableDetails
                                    title={order.deliveryMethod.name === 'Home Delivery' ? strings.homeDelivery : order.deliveryMethod.name}
                                    main=""
                                />
                            </Box>
                        }
                        { deliverySlot.date &&
                        <>
                            <Separator />

                            <EditableDetails
                                title={strings.deliverySlot}
                                main={
                                    <>
                                        <Text>{deliverySlot.date}</Text>
                                        <Text>{deliverySlot.time}</Text>
                                    </>
                                }
                            />
                        </>
                        }
                        { egiftcardDetails.length > 0 &&
                            egiftcardDetails.map(giftCard => (
                                <Box flex={false} background="light-3" pad="small" margin={{ bottom: "medium" , "top":"medium"}}>
                                    <InfoBox text={giftCard.informationMessage} />
                                </Box>
                            ))
                        }
                        </Box>
                    </Step>

                    {/* Payment Method */}
                    { selectedPaymentMethod.name === order.paymentDetails.method
                        ?
                            <Step title={strings.paymentMethod}>
                                <Box direction="row" flex="grow">
                                    <Box flex="grow">
                                        <EditableDetails
                                            title={selectedPaymentMethod.name === 'CARD'  ? translate(translation,'creditDebitCard',selectedPaymentMethod.label):
                                            translate(translation,selectedPaymentMethod.label,selectedPaymentMethod.label)  }
                                            main=""
                                        />
                                        {selectedPaymentMethod.name === 'CARD' ? cardDetailsDescription : ''}
                                    </Box>
                                    <Box align="end" width={{max: "40px"}}>
                                        {paymentIcon.icon}
                                    </Box>
                                </Box>
                            </Step>
                        : // If mismatch, we display the limited data from the order
                            <Step title={strings.paymentMethod}>
                                <Box direction="row" flex="grow">
                                    <Box flex="grow">
                                        <EditableDetails
                                            title={order.paymentDetails.method === 'CARD'  ? translate(translation,'creditDebitCard',order.paymentDetails.method):
                                                translate(translation,order.paymentDetails.method,order.paymentDetails.method)  }
                                            main=""
                                        />
                                    </Box>
                                    <Box align="end" width={{max: "40px"}}>
                                        {paymentIcon.icon}
                                    </Box>
                                </Box>
                            </Step>
                    }

                    {/* Billing Address */}
                    <Step title={strings.billingAddress}>
                        <Box flex="grow">
                            {/* Tickbox for default billing address (for home delivery only) */}
                            { (isHomeDelivery && isBillingSameAsDelivery && !cartHasOnlyGiftCardProducts)
                                ?   // Tickbox for default billing address
                                    <Box direction="row" align='center'>
                                        {tickIcon} <Text margin={{ left: 'small' }}>{strings.sameAsDeliveryAddress}</Text>
                                    </Box>
                                :   // Full, specific (non-default) billing details
                                    <EditableDetails
                                        main={
                                            <FormattedAddress
                                                type="homeAddress"
                                                details={order.billingAddress}
                                            />
                                        }
                                    />
                            }
                        </Box>
                    </Step>
                </Box>
                <Box>
                    {/* Price Total */}
                    <Step
                        title={showTotalsTitle ? strings.totalsSummary : undefined}
                        showSeparator={false}
                    >
                        <Box direction="column" fill="horizontal" gap="small">
                            <TotalSummary dataSource='order' />
                            <Button
                                primary
                                label={strings.continueShopping}
                                fill={true}
                                a11yTitle={strings.continueShopping}
                                margin={{ top: isTotalSeparatorAbove ? 'large' : 'none' }}
                                onClick={() => { window.location.href = `${returnURL}`}}
                            />
                            { isDesktop &&
                                <Button
                                    primary
                                    label={strings.printGiftReceipt}
                                    fill={true}
                                    a11yTitle={strings.printGiftReceipt}
                                    onClick={ () => handlePrint() }
                                /> 
                            }
                        </Box>
                        {/* temp way to clear carts */}
                        <iframe title="title" style={{ display: "none"}} src={`${returnURL}?flushCart=1`}/>
                    </Step>

                    {/* Page Layers */}
                    {isClickAndCollect && showStoreDetailedView && !isDesktop &&
                        <Layer target={pageRef?.current || undefined}
                            responsive={false}
                            animation="slide"
                            position="bottom"
                            full={true}
                        >
                            {storeDetails}
                        </Layer>
                    }


                    {/* Guest Signup */}
                    {isOnGuestJourney && !isGuestPromoted && !isReturning &&
                        <Step>
                            <Box direction="column" fill="horizontal">
                                <Text size="large" weight={theme?.config?.titleFontWeight}>{strings.guestSignUpTitle}</Text>
                                <Text>{strings.guestSignUpText}</Text>
                                <Box direction="row" pad={{top: "large", bottom: "large"}} justify="evenly">
                                    <Box
                                        direction="column"
                                        align="center"
                                    >
                                        <Preferences height={ confirmationGuestCheckoutIconSize }/>
                                        <Text margin={{top: "small"}} textAlign="center">{strings.guestSignupCTA}</Text>
                                    </Box>
                                    <Box
                                        direction="column"
                                        align="center"
                                    >
                                        <OrderIcon height={ confirmationGuestCheckoutIconSize }/>
                                        <Text margin={{top: "small"}} textAlign="center">{strings.guestTrackYourOrders}</Text>
                                    </Box>
                                    <Box
                                        direction="column"
                                        align="center"
                                    >
                                        <Heart height={ confirmationGuestCheckoutIconSize }/>
                                        <Text margin={{top: "small"}} textAlign="center">{strings.guestShareWishlist}</Text>
                                    </Box>
                                </Box>
                                <Form
                                    onSubmit={(event: any) => {
                                        setPasswordCheckInProgress(true);
                                        dispatch(promoteCustomer(customer.ID, event.value.password))
                                            .then(() => {
                                                setIsGuestPromoted(true);
                                                dispatch(clearNotification());
                                                dispatch(updateDialog('account_creation_success'));
                                                /*
                                                TODO: redirect to Account page on main site - as per https://jdsportsplc.slack.com/archives/G013DAJ8WUF/p1627029630262700
                                                this redirection is dependent on https://jdplc.atlassian.net/browse/JDG-33734
                                                */
                                            })
                                            .catch(() => {
                                                dispatch(updateNotification('ADD_PASSWORD_TO_GUEST'));
                                            })
                                            .finally(() => setPasswordCheckInProgress(false));
                                    }}
                                >
                                    <ValidatedInput
                                        rules={[requiredRule(strings.createPassword), passwordRule(strings.createPassword), { name: 'min', params: { 'length': 8 } }, maxLength30Rule]}
                                        name="password"
                                        type="password"
                                        label={strings.createPassword}
                                        disabled={passwordCheckInProgress}
                                        placeholder={strings.enterPassword}
                                        info={passwordHelp}
                                        margin={{ bottom: 'large' }}
                                    />
                                    <Box direction="row" flex="grow">
                                        <Button
                                            primary
                                            fill="horizontal"
                                            type="submit"
                                            disabled={passwordCheckInProgress}
                                            label={passwordCheckInProgress ? <LoadingIcon /> : strings.createAccount}
                                        />
                                    </Box>
                                </Form>
                            </Box>
                        </Step>
                    }

                    {/* Legal links */}
                    <Box
                        direction="column"
                        flex={false}
                        margin={{ horizontal: 'medium', top: 'medium', bottom: 'large' }}
                        gap="xsmall"
                    >
                        <Box direction="row">
                            {privacyPolicyText}
                        </Box>

                    </Box>
                </Box>
            </Box>
        </>
    );
}

export default Confirmation;
