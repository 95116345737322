import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Box, Anchor, Text, Table, TableBody, TableRow, TableCell, Heading, ResponsiveContext } from "grommet";
import { Close } from "../Icon/index";
import TitleBar from "../TitleBar/TitleBar";
import EditableDetails from "../EditableDetails/EditableDetails";
import Step from "../Step/Step";
import FormattedAddress from "../FormattedAddress/FormattedAddress";
import OpeningHoursTable from "../OpeningHoursTable/OpeningHoursTable";
import { RootState } from "../../store/reducers";
import { translate } from "../../modules/translation";
import { Store } from "../../modules/delivery/types";

/**
 * A table is displayed containing the opening hours for each day for a particular shop.
 * The first row shows the opening times for the current day.
 * @param store {object} A full store object
 * @param onGetDirections {callback} To execute when clicking the 'Get Directions' button
 * @param onCallStore {callback} To execute when clicking the 'Call Store' button
 * @param onClose {callback} To execute when clicking on the close icon in the header
 */
function StoreDetails ({ store, onGetDirections, onCallStore, onClose } : StoreDetailsProps) {
    const translation = useSelector((state: RootState) => state.translation);
    const size = React.useContext(ResponsiveContext);
    const isDesktop = size !== 'small';

    const strings = {
        storeDetails: translate(translation, 'storeDetails', 'Store Details'),
        normalOpeningHours: translate(translation, 'normalOpeningHours', 'Normal Opening Hours'),
        phoneNumber: translate(translation, 'phoneNumber', 'Phone Number'),
        miles: translate(translation, 'miles', 'Miles'),
    };

    return(
        <Box
            background="light-1"
            width="100vw"
            height={isDesktop ? undefined : '100vh'}
            overflow="auto"
            responsive={false}
        >
            <TitleBar
                title={strings.storeDetails}
                icon={<Anchor><Close onClick={onClose} data-testid="closeIcon" /></Anchor>}
            />

            <Step>
                <Box direction="column" flex="grow">
                    <Box>
                        {/* Store Address */}
                        <EditableDetails
                            title={store.name}
                            extra={<Text weight="bold">{store.distance} {store.distanceUnits ?? strings.miles}</Text>}
                            main={<FormattedAddress type="storeAddress" details={store.address} />}
                            isDetailsButton={false}
                        />
                    </Box>

                    <Box height="1px" margin={{top: "medium"}} background="light-1" />

                    <Box><Heading level={4}>{strings.normalOpeningHours}</Heading></Box>

                    <Box direction="column" flex={true}>
                        {/* Opening Hours Table */}
                        <OpeningHoursTable openingHours={store.openingHours} />

                        <Box height="1px" margin={{vertical: "medium"}} background="light-1" />

                        {/* Phone */}
                        {store.phone &&
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell pad={{horizontal: '0px'}}>
                                            <Text weight='bold'>{strings.phoneNumber}</Text>
                                        </TableCell>
                                        <TableCell flex='grow' pad={{horizontal: '0px'}}>
                                            <Text weight='bold' textAlign="end">
                                                {store.phone}
                                            </Text>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        }

                        {store.phone &&
                            <Box height="1px" margin={{vertical: "medium"}} background="light-1" />
                        }
                    </Box>
                </Box>
            </Step>
        </Box>
    );
}


type StoreDetailsProps = {
    store: Store,
    onGetDirections: (event: React.MouseEvent) => void,
    onCallStore: (event: React.MouseEvent) => void,
    onClose: (event: React.MouseEvent) => void
}

const openingHoursPropTypes = PropTypes.shape({
    open: PropTypes.bool,
    opensAt: PropTypes.string,
    closesAt: PropTypes.string,
    formattedOpeningHours: PropTypes.string.isRequired
}).isRequired;
StoreDetails.propTypes = {
    store: PropTypes.shape({
        ID: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        address: PropTypes.shape({
            address1: PropTypes.string.isRequired,
            address2: PropTypes.string,
            address3: PropTypes.string,
            town: PropTypes.string.isRequired,
            county: PropTypes.string,
            postcode: PropTypes.string.isRequired,
            country: PropTypes.string.isRequired,
            locale: PropTypes.string
        }),
        phone: PropTypes.string,
        openingHours: PropTypes.shape({
            Monday: openingHoursPropTypes,
            Tuesday: openingHoursPropTypes,
            Wednesday: openingHoursPropTypes,
            Thursday: openingHoursPropTypes,
            Friday: openingHoursPropTypes,
            Saturday: openingHoursPropTypes,
            Sunday: openingHoursPropTypes
        }),
        distance: PropTypes.string.isRequired,
        distanceUnits: PropTypes.string.isRequired,
    }),
    onGetDirections: PropTypes.func,
    onCallStore: PropTypes.func,
    onClose: PropTypes.func
};

export default StoreDetails;
