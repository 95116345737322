import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Box, Text, ResponsiveContext } from 'grommet';
import { ThemeContext } from 'styled-components';
import { CustomTheme } from '../../theme/index'
import Currency from '../Currency/Currency';
import ResizedImage from '../ResizedImage/ResizedImage';
import { Product } from '../../modules/cart/types';
import { translate } from '../../modules/translation';
import { ArrowRight } from '../Icon/index';
import Separator from '../Separator/Separator';
import { useSelector } from "react-redux";
import { RootState } from "../../store/reducers";
import { localisedDate } from "../../lib/date/date";
import { getGiftCardSkuDetails, getLocalisation } from "../../modules/config";

const nbsp = '\xa0'; // non-breaking space character

function ProductDetails({
    product
    }: ProductDetailsProps) {
    const theme = useContext(ThemeContext) as CustomTheme;
    const sizeString = product.optionValues?.Size ?? null;
    const size = React.useContext(ResponsiveContext);
    const isDesktop = size !== 'small';
    const customisationSetsLength =  product?.customisationSets?.length ?? 0;
    const config = useSelector((state: RootState) => state.config);
    const translation = useSelector((state: RootState) => state.translation);
    const order = useSelector((state:RootState) => state.order);
    const categoryGroupingName = order.products.find((orderproduct) => orderproduct.SKU === product.SKU)?.categoryGroupingName;
    const localisation = getLocalisation(config);
    const giftcardSku = getGiftCardSkuDetails(config);

    const giftCardData = product.cartProductNotification;
    const strings = {
        productDetailsSize: translate(translation, 'productDetailsSize', `Size:${nbsp}${sizeString}`, {data: sizeString}),
        productDetailsCode: translate(translation, 'productDetailsCode', `Code:${nbsp}${product.parentSKU}`, {data: product.parentSKU}),
        productDetailsQty: translate(translation, 'productDetailsQty', `Qty:${nbsp}${product.quantity}`, {data: product.quantity}),

        giftCardSendVia: translate(translation, 'giftCardSendVia', `Send Via: ${giftCardData?.deliveryType}`, {data: giftCardData?.deliveryType ?? ''}),
        giftCardSendFrom: translate(translation, 'giftCardSendFrom', `Send From: ${giftCardData?.sendFrom}`, {data: giftCardData?.sendFrom ?? ''}),
        giftCardSendTo: translate(translation, 'giftCardSendTo', `Send To: ${giftCardData?.sendTo}`, {data: giftCardData?.sendTo ?? ''}),
        giftCardSendMessage: translate(translation, 'giftCardSendMessage', `Message: ${giftCardData?.personalMessage}`, {data: giftCardData?.personalMessage ?? ''}),
        personalisation: translate(translation, 'personalisation', 'Personalisation'),
    };

    let detailsString = sizeString ? `${strings.productDetailsSize} |${nbsp}` : '';
    detailsString += `${strings.productDetailsCode} |${nbsp}${strings.productDetailsQty}`;

    return(
        <>
        <Box direction="row" align="center" fill="horizontal" flex={false}>
            {/* Image */}
            <Box margin={{right: 'medium', left: !isDesktop ?'xsmall' : ''}} flex={false}>
                <ResizedImage width={80} height={80} image={product?.image?.resizingService} />
            </Box>

            {/* Product Details */}
            <Box direction="column">
                <Text size="small" margin={{bottom: 'xxsmall'}}>{product.name}</Text>
               { categoryGroupingName && <Text size="small" margin={{bottom: 'xxsmall'}}>{categoryGroupingName}</Text> }
                <Text>
                    {product.previousUnitPrice && product.previousUnitPrice.amount !== product.unitPrice.amount &&
                        <Currency
                            amount={(parseFloat(product.previousUnitPrice.amount) * product.quantity)}
                            currency={product.previousUnitPrice.currency}
                            textFormat={{size: 'small', weight: theme?.config?.titleFontWeight ?? 'bold'}}
                            strikeThrough={true}
                        />
                    }
                    {product.discountAmount && product.discountAmount?.amount!== '0.00' && !product.previousUnitPrice &&
                        <Currency
                            amount={(parseFloat(product.unitPriceBeforeDiscounts.amount) * product.quantity)}
                            currency={product.unitPriceBeforeDiscounts.currency}
                            textFormat={{size: 'small', weight: theme?.config?.titleFontWeight ?? 'bold'}}
                            strikeThrough={!product.previousUnitPrice && !!product.previousPrice}
                        />
                    }
                    {(product.discountAmount && product.discountAmount?.amount=== '0.00')  &&
                        <Currency
                            amount={(parseFloat(product.unitPrice.amount) * product.quantity)}
                            currency={product.unitPrice.currency}
                            textFormat={{
                                size: 'small', 
                                weight: theme?.config?.titleFontWeight ?? 'bold', 
                                color: (product.isDiscounted && theme?.config?.addColorToDiscounts) 
                                    ? theme?.global?.colors?.red as string ?? 'red' 
                                    : ''
                            }}
                        />
                    }
                    { product.unitPriceBeforeDiscounts?.amount !== product.unitPrice.amount && 
                        <Currency
                            amount={(parseFloat(product.unitPrice.amount) * product.quantity)}
                            currency={product.unitPrice.currency}
                            textFormat={{
                                size: 'small',
                                weight: theme?.config?.titleFontWeight ?? 'bold',
                                color: (theme?.config?.addColorToDiscounts)
                                    ? theme?.global?.colors?.red as string ?? 'red'
                                    : ''
                            }}
                        />
                    }
                </Text>

                <Box direction="row">
                    <Text size="small" margin={{top: 'xxsmall'}}>{detailsString}</Text>
                </Box>
            </Box>
        </Box>
        {product.trackingSKU === giftcardSku.eGiftcardSku &&
            <Box flex={false} pad={{horizontal: !isDesktop ? 'xsmall' : ''}}>
                <Separator margin={{ vertical: "medium" }} />
                <Text size="small" margin={{top: 'xxsmall'}}>{strings.giftCardSendVia}</Text>
                <Text size="small" margin={{top: 'xxsmall'}}>{strings.giftCardSendTo}</Text>
                <Text size="small" margin={{top: 'xxsmall'}}>{strings.giftCardSendFrom}</Text>
                <Text size="small" margin={{top: 'xxsmall'}}>
                    {translate(translation, 'giftCardSendDate',
                        `Send Date: ${localisedDate(product.cartProductNotification?.scheduledDelivery as string, localisation.language.code) ?? ''}`,
                        {date: localisedDate(giftCardData?.scheduledDelivery as string, localisation.language.code) ?? ''}
                    )}
                </Text>
                <Text size="small" margin={{top: 'xxsmall'}}>{strings.giftCardSendMessage}</Text>
            </Box>
        }
        { customisationSetsLength > 0  &&
            <Box direction="row" align="end" fill="horizontal">
                <Box margin={{right: 'medium',left: 'large'}} >
                    <Box margin={{right: 'medium'}}>
                        <ArrowRight width="30" height="30" />
                    </Box>
                </Box>

                <Box direction="column" margin={isDesktop ? { left: "small" } : ""}>
                    <Text size="small" weight="bold">{strings.personalisation}</Text>
                    { product?.customisationSets?.map((productCustomisations, index) => (
                        productCustomisations.customisations.map((customisation, index) => (
                            <Text size="small" key={index}>{customisation.value}</Text>
                            ))
                        ))
                    }
                </Box>
            </Box>
        }
    </>

);
}

type ProductDetailsProps = {
    product: Product
};
ProductDetails.propTypes = {
    product: PropTypes.shape({
        SKU: PropTypes.string.isRequired,
        parentSKU: PropTypes.string.isRequired,
        trackingSKU: PropTypes.string,
        name: PropTypes.string,
        type: PropTypes.string,
        brandName: PropTypes.string,
        isDiscounted: PropTypes.bool,
        discountAmount: PropTypes.shape({
            amount: PropTypes.string.isRequired,
            currency: PropTypes.string.isRequired
        }),
        image: PropTypes.shape({
            ID: PropTypes.string,
            altText: PropTypes.string,
            originalURL: PropTypes.string,
            srcset: PropTypes.string,
            resizingService: PropTypes.shape({
                href: PropTypes.string,
                originalURL: PropTypes.string,
                properties: PropTypes.shape({
                    width: PropTypes.string,
                    height: PropTypes.string,
                }),
            }),

        }),
        colourDescription: PropTypes.string,
        colour: PropTypes.string,
        optionValues: PropTypes.shape({
            Size: PropTypes.string,
        }),
        unitPrice: PropTypes.shape({
            amount: PropTypes.string.isRequired,
            currency: PropTypes.string.isRequired
        }),
        subtotal: PropTypes.shape({
            amount: PropTypes.string.isRequired,
            currency: PropTypes.string.isRequired
        }),
        previousPrice: PropTypes.shape({
            amount: PropTypes.string,
            currency: PropTypes.string
        }),
        previousUnitPrice: PropTypes.shape({
            amount: PropTypes.string,
            currency: PropTypes.string
        }),
        saving: PropTypes.shape({
            amount: PropTypes.string,
            currency: PropTypes.string
        }),
        unitPriceBeforeDiscounts: PropTypes.shape({
            amount: PropTypes.string,
            currency: PropTypes.string
        }),
        quantity: PropTypes.number.isRequired,
        customisationSets: PropTypes.arrayOf(PropTypes.shape({
            ID: PropTypes.string,
            href: PropTypes.string,
            name: PropTypes.string,
            clientI: PropTypes.string,
            appliesTo: PropTypes.string,
            sortOrder: PropTypes.string,
            price: PropTypes.shape({
                amount: PropTypes.string,
                currency: PropTypes.string
            }),
            SKU: PropTypes.string,
            icon: PropTypes.shape({
                name: PropTypes.string,
                URL:  PropTypes.string
            }),
            customisations:
                PropTypes.shape({
                    customisation: PropTypes.shape({
                        href: PropTypes.string,
                        ID: PropTypes.string,
                        name: PropTypes.string,
                        displayName: PropTypes.string,
                        description: PropTypes.string,
                        isOptional: PropTypes.bool,
                        type: PropTypes.string,
                        customisationType: PropTypes.string,
                        icon: PropTypes.shape({
                            name: PropTypes.string,
                            URL:  PropTypes.string
                        }),
                        minLength: PropTypes.number,
                        maxLength: PropTypes.number,
                        allowedCharacters:  PropTypes.string ,
                        allowedValues: PropTypes.string
                    }),
                    value: PropTypes.string
                })

        })),
        conflictCode: PropTypes.string,
        conflictMessage: PropTypes.string,
        dateAdded: PropTypes.string,
        stockPool: PropTypes.shape({
            ID: PropTypes.string,
            isDropship: PropTypes.bool,
        }),
        fulfilment:PropTypes.shape( {
            fulfilmentMessage: PropTypes.string,
            fulfilmentMessage2: PropTypes.string,
            imageURL: PropTypes.string,
            isDefault: PropTypes.bool,
            deliveryMessage: PropTypes.shape( {
                message: PropTypes.string,
                message2: PropTypes.string,
                imageURL: PropTypes.string,
                leadTime: PropTypes.string,
                isDefault: PropTypes.bool,
            }),
            informationPage: PropTypes.shape( {
                ID: PropTypes.string,
        })
    })})
}

export default ProductDetails;
