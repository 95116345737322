import { 
    MeshEnvironment, 
    MeshEnvironmentConfigs, 
    MeshClientConfig, 
    MeshChannel } from './types';

const config = {
    timeout: 25000
}

const environmentConfigs: MeshEnvironmentConfigs = {
    uat: {
        // baseURL: "https://uat.jdgroupmeshtest.cloud"
		baseURL: "https://expclickandcollect.uat.jdgroupmeshtest.cloud"
    }, 
    prod: {
        baseURL: "https://prod.jdgroupmesh.cloud"
    }
}

export function createGlobalHeaders(apiKey: string, channel: MeshChannel){
    return {
        'X-Api-Channel' : channel,
        'X-Api-Key' : apiKey
    }
}


/**
 * 
 * @param environment - mesh envrionment uat, prod or a mesh url to use a non-standard url.
 * @param apiKey - mesh api key to use
 * @param channel - mesh channel e.g mobile
 * @param basePath - path to prefix api calls with 
 */
export function createClientConfig(environment: MeshEnvironment | string, apiKey: string, channel: MeshChannel, basePath: string): MeshClientConfig {

	const globalHeaders = createGlobalHeaders(apiKey, channel);
    const meshUrl = environmentConfigs[environment] ? environmentConfigs[environment].baseURL : environment;
    const baseURL = `${meshUrl}${basePath}`;
    return {
        ...config,
        ...environmentConfigs[environment],
        baseURL,
        headers: globalHeaders, 
        globalParams: {
            'api_key': apiKey
        }
    }
}
