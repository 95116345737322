import firebase from 'firebase/app';
import "firebase/analytics";
import newRelicData from "../../newrelic";

export function initialise(store: string) {
    if (isFirebaseInitialised()){
        return firebase.app();
    }

    let firebaseConfig = {};
    let app;
    try {
        firebaseConfig = JSON.parse(process.env[`REACT_APP_FIREBASE_CONFIG_${process.env.REACT_APP_MESH_ENV !== 'prod' ? 'NONPROD' : 'PROD'}_${store}`] || "");
        app = firebase.initializeApp(firebaseConfig);
    } catch (e) {
        newRelicData({ actionName: 'firebase', function: 'initialise', message: 'Unable to initialise firebase.'});
        console.error('Unable to initialise firebase.')
    }
    return app;
}

export function isFirebaseInitialised() {
    return firebase.apps.length > 0;
}