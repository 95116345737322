import React, { useEffect, useState } from 'react';
import { Box, Anchor, Button, ResponsiveContext, CheckBox, Text, RadioButton } from 'grommet';
import { useSelector, useDispatch } from 'react-redux';
import { AppThunkDispatch } from '../../modules/thunk';
import { RootState } from '../../store/reducers';
import {
    getSavedCardById, getSavedCards, getSelectedSavedCard, updateSavedCards, updateSelectedSavedCard,
    updateShowCardSelectorView, updateDefaultSavedCard
} from '../../modules/billing';
import { SavedCard } from '../../modules/billing/types';
import { Close } from '../Icon/index';
import TitleBar from '../TitleBar/TitleBar';
import Step from '../Step/Step';
import Separator from '../Separator/Separator';
import SavedCardDetails from '../SavedCardDetails/SavedCardDetails';
import { deleteSavedCard, getAuthToken } from '../../modules/customer';
import { translate } from '../../modules/translation';

/**
 * UI Selector of saved payment cards.
 */
function PaymentCards() {
    const translation = useSelector((state: RootState) => state.translation);

    const strings = {
        myPaymentCards: translate(translation, 'myPaymentCards', 'My Payment Cards'),
        useNewCard: translate(translation, 'useNewCard', 'Use New Payment Card'),
        creditDebitCard: translate(translation, 'creditDebitCard', 'Credit / Debit Card'),
        saveContinue: translate(translation, 'saveContinue', 'Save and Continue'),
        setAsDefaultPayment: translate(translation, 'setAsDefaultPayment', 'Set as default payment method'),
        delete: translate(translation, 'delete', 'Delete'),
    };
    
    const billing = useSelector((state: RootState) => state.billing);
    const customer = useSelector((state: RootState) => state.customer);
    let savedCards = getSavedCards(billing);
    const authToken = getAuthToken(customer);
    const selectedSavedCard = getSelectedSavedCard(billing);
    const [selectedCardInList, setSelectedCardInList] = useState<SavedCard>(selectedSavedCard);

    const size = React.useContext(ResponsiveContext);
    const isDesktop = size !== 'small';

    const dispatch: AppThunkDispatch = useDispatch();

    useEffect(() => {
        setSelectedCardInList(selectedSavedCard);

        // eslint-disable-next-line
    }, []);

    const cardSelectorBox = <Box flex="grow" direction="column">
        {/* 'Add New Card' button */}
        <Button
            label={strings.useNewCard}
            a11yTitle={strings.useNewCard}
            onClick={(event) => {
                dispatch(updateSelectedSavedCard({} as SavedCard));
                dispatch(updateShowCardSelectorView(false));
            }}
            fill={true}
        />
        <Separator margin={{ vertical: 'large' }} />

        {/* List of saved cards */}
        { savedCards.map((card, index) => (
            <Box key={index} direction="column" align='start'>
                <RadioButton
                    name='radio'
                    value={card.id}
                    label={<Text size="medium"><strong>{strings.creditDebitCard}</strong></Text>}
                    checked={selectedCardInList.id === card.id}
                    onChange={async (event) => {
                        const card = getSavedCardById(billing, event.target.value);
                        if (card?.id) {
                            setSelectedCardInList(card);
                        }
                    }}
                />
                <Box fill="horizontal">
                    <Box pad={{left: "large"}} margin={{left: "xsmall"}}><SavedCardDetails savedCard={card} /></Box>
                    <Box pad={{top: "xxsmall", bottom: "small"}} direction="row">
                        <CheckBox
                            checked={card.isPreferredSavedCard}
                            onChange={async (event) => {
                                if (event.target.checked) {
                                    dispatch(updateDefaultSavedCard(card.id));
                                }
                            }}
                            label={<Text size="small">{strings.setAsDefaultPayment}</Text>}
                        />
                        <Box align="end" flex="grow">
                            <Anchor size="xsmall"
                                onClick={async () => {
                                    if (authToken) {
                                        const deletedCardResponse = await dispatch(deleteSavedCard(customer.ID, card.id));
                                        if(deletedCardResponse.data) {
                                            const filteredCards = savedCards.filter(Cards => Cards.id !== card.id);
                                            dispatch(updateSavedCards(filteredCards));
                                            if(filteredCards.length > 0) {
                                                dispatch(updateSelectedSavedCard(filteredCards[0]));
                                            } else {
                                                dispatch(updateSelectedSavedCard({} as SavedCard));
                                                dispatch(updateShowCardSelectorView(false));
                                            }
                                            if (selectedSavedCard.id === card.id) {
                                                dispatch(updateSelectedSavedCard({} as SavedCard));
                                            }
                                        }
                                    }
                                }}>
                                {strings.delete}
                            </Anchor>
                        </Box>
                    </Box>
                    {index < savedCards.length - 1 && <Separator margin={{ top: 'medium', bottom: 'large' }} />}
                </Box>
            </Box>
        ))}

        {/* 'Save and Continue' button */}
        <Button
            primary
            label={strings.saveContinue}
            a11yTitle={strings.saveContinue}
            onClick={(event) => {
                dispatch(updateSelectedSavedCard(selectedCardInList));
                dispatch(updateShowCardSelectorView(false));
            }}
            fill={true}
        />
    </Box>;


    return(
        <Box
            background={isDesktop ? '' : 'light-1'}
            width="100vw"
            height={isDesktop ? '' : '100vh'}
            overflow={isDesktop ? '' : 'scroll'}
            responsive={false}
            margin={{ bottom: 'medium' }}
        >
            {/* Desktop: minimal rendering as this goes inside the payment list.
                Mobile: will be rendered as a standalone layer so need a title and a <Step> wrapping.
            */}
            { isDesktop
                ?
                    cardSelectorBox
                :
                <>
                    <TitleBar
                        title={strings.myPaymentCards}
                        icon={
                            <Anchor>
                                <Close
                                    onClick={(event) => {
                                        dispatch(updateShowCardSelectorView(false));
                                    }} />
                            </Anchor>
                        }
                    />
                    <Step>
                        {cardSelectorBox}
                    </Step>
                </>
            }


        </Box>
    );
};

export default PaymentCards;
