import adaptor from './adaptors';
import { AppResponse } from './response';
import { RootState } from '../store/reducers';
import { SessioApiGansFpG9nO9jOs as SessionBody } from './sessions-sdk/models';

export interface SessionService {
    getSession: (id: string) => Promise<AppResponse<SessionBody>>,
    updateSession: (id: string, body: SessionBody) => Promise<AppResponse<SessionBody>>,
}


function init(state: RootState){
    const service = adaptor('sessions', 'session', state) as SessionService;
    return service;
}

export default init;
