import { createClientConfig } from './config';
import { createMeshClient } from './client';
import customers from './customers';
import carts from './carts';
import deliveryCountries from './deliveryCountries';
import billingCountries from './billingCountries';
import pages from './pages';
import orders from './orders';
import addresses from './addresses';

import { MeshConfig } from './types';
import payments from './payments';
import hostedPayments from './hostedPayment';

function init(meshConfig: MeshConfig){
    const { environment, apiKey, store, channel, locale } = meshConfig;

    if (!store || !channel || !environment || !apiKey || !locale){
        throw new Error("Required config not provided.")
    }

    const storesPath = `/stores/${store}`;
    const oauthPath = `/oauth2/${store}`

    const config = createClientConfig(environment, apiKey, channel, storesPath, );
    const client = createMeshClient(config);

    const oauthConfig = createClientConfig(environment, apiKey, channel, oauthPath);
    const oauthClient = createMeshClient(oauthConfig);
    

    return {
        customers: customers(client, oauthClient, meshConfig),
        carts: carts(client, meshConfig),
        deliveryCountries: deliveryCountries(client, meshConfig),
        billingCountries: billingCountries(client, meshConfig),
        pages: pages(client, meshConfig),
        orders: orders(client, meshConfig),
        addresses: addresses(client, meshConfig),
        payments: payments(client, meshConfig),
        hostedPayments: hostedPayments(client, meshConfig),
    }
}

export type MeshService = ReturnType<typeof init>;

export default init;