import React from "react";
import PropTypes from "prop-types";
import { Image, ImageProps } from 'grommet';
import { ResizingServiceImage } from '../../lib/imageResizing/types';
import { resizeImage } from '../../lib/imageResizing/imageResizing';

function ResizedImage(props: ResizedImageProps & ImageProps & JSX.IntrinsicElements['img']) {
    const { image, ...imageProps } = props;
    let src = resizeImage(image.srcset ?? image.href, { ...props }, image.properties);

        return (
            <Image
                src={src}
                srcSet={image.srcset}
                {...imageProps}
            />
        );
}

type ResizedImageProps = {
    image: ResizingServiceImage
};

ResizedImage.propTypes = {
    image: PropTypes.shape({
        originalURL: PropTypes.string,
        href: PropTypes.string.isRequired,
        properties: PropTypes.object.isRequired
    }),
    ...Image.propTypes
};

export default ResizedImage;