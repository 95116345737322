import React, { useContext, useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { AppThunkDispatch } from '../../modules/thunk';
import { carts, tracking } from '../../services';
import { Box, Text, Button, Form, Anchor, CheckBox, ResponsiveContext } from 'grommet';
import Step from '../Step/Step';
import Notification from '../Notification/Notification';
import ValidatedInput from '../ValidatedInput/ValidatedInput';
import TitleBar from '../TitleBar/TitleBar';
import LoadingIcon from '../LoadingIcon/LoadingIcon';
import { maxLength30Rule, requiredRule, emailRule, passwordRule, maxLengthRule, nameRule } from '../../lib/validate/validate';
import { RootState } from '../../store/reducers';
import {
    getEmail, getReturning, submitEmail, loginOrSignUp,
    createGuest, updateIsOnGuestJourney, updateEmail,
    updateMarketingOptIns, updateClickAndCollect, getCustomerPreferences,
} from '../../modules/customer';
import {
    getReturnURL, getPrivacyPolicy, getShowMarketingCheckboxes, getMarketingEmailOptout,
    getIsNikeConnected,
} from '../../modules/config';
import { getNotificationCode } from '../../modules/notification';
import { ThemeContext } from 'styled-components';
import { CustomTheme } from "../../theme";
import GuestLogin from "../GuestLogin/GuestLogin";
import { Address, AuthCredentials, CustomerPreferences } from "../../modules/customer/types";
import { updateDeliveryAddress } from '../../modules/delivery';
import { getCartId, updateCustomerToCart } from '../../modules/cart';
import { updateBillingAddress } from '../../modules/billing';
import { getPhraseWithLink, translate } from '../../modules/translation';
import { getIsServiceAgent, getSession, putSession } from '../../modules/session';
import { local as browserLocalStorage } from 'store2';
import { v4 as uuid } from 'uuid';
import newRelicData from "../../newrelic";
import isEmail from "validator/lib/isEmail";

/**
 * Login is the component that wraps the content for a login page
 *
 */
function Login() {

    const dispatch: AppThunkDispatch = useDispatch();
    const history = useHistory();
    const customer = useSelector((state: RootState) => state.customer);
    const config = useSelector((state: RootState) => state.config);
    const notification = useSelector((state: RootState) => state.notification);
    const translation = useSelector((state: RootState) => state.translation);
    const session = useSelector((state: RootState) => state.session);
    const userCart = useSelector((state: RootState) => state.cart);
    const initialEmailOptIn = getMarketingEmailOptout(config);
    const [isOptInTicked, setIsOptInTicked] = React.useState(initialEmailOptIn);
    const [detailsTyped, setdetailsTyped] = React.useState({
        email: '',
        password: '',
        firstName: '',
        lastName: ''
    });
    const email = getEmail(customer);
    const returning = getReturning(customer);
    const returnURL = getReturnURL(config);
    const privacyPolicy = getPrivacyPolicy(config);
    const isServiceAgent = getIsServiceAgent(session);
    const showMarketingCheckboxes = getShowMarketingCheckboxes(config);
    const cartId = getCartId(userCart);
    const isNikeConnected = getIsNikeConnected(config);

    const strings = {
        signIn: translate(translation, 'signIn', 'Sign In'),
        createAnAccount: translate(translation, 'createAnAccount', 'Create an account'),
        continueGuest: translate(translation, 'continueGuest', 'Continue as a guest'),
        welcomeBackSignIn: translate(translation, 'welcomeBackSignIn', 'Welcome Back! Sign in for faster checkout.'),
        welcomeCreateAccount: translate(translation, 'welcomeCreateAccount', 'Welcome! Enter a password to create an account.'),
        forgotPassword: translate(translation, 'forgotPassword', 'Forgot Password?'),
        welcomeToCheckout: translate(translation, 'welcomeToCheckout', 'Welcome to secure checkout'),
        enterEmailToStart: translate(translation, 'enterEmailToStart', 'Enter your email address to get started.'),
        emailAddress: translate(translation, 'emailAddress', 'Email Address'),
        continue: translate(translation, 'continue', 'Continue'),
        password: translate(translation, 'password', 'Password'),
        marketingOptInEmail: translate(translation, 'marketingOptInEmail', 'We will share our latest launches, offers and new drops with you via Email, unless you untick this box.'),
        privacyNoticeInfo: translate(translation, 'privacyNoticeInfo', 'We will use your information in accordance with our (privacy notice).'),
        updatedOnDate: translate(translation, 'updatedOnDate', '', {date: privacyPolicy.date}),
        symbolsInPassword: translate(translation, 'symbolsInPassword', 'Password must be at least 8 characters, containing at least one character, one digit and one symbol'),
        noSymbolsInPassword: translate(translation, 'noSymbolsInPassword', 'Password must be at least 8 characters, containing at least one character, one digit and no symbol'),
        firstName: translate(translation, 'firstName', 'First Name'),
        lastName: translate(translation, 'lastName', 'Last Name'),
    };

    const getPasswordHelp = useSelector((state: RootState) => {
        if (state.config.localisation?.passwordHelpText) {
            return state.config.localisation.passwordHelpText;
        }
        if (state.config.localisation.symbolsInPassword) {
            return strings.symbolsInPassword;
        }
        else {
            return strings.noSymbolsInPassword;
        }
    });
    const passwordHelp = returning ? `` : getPasswordHelp;

    const continueButton = returning ? strings.signIn : strings.createAnAccount;
    const guestCheckoutButton = strings.continueGuest;
    const LoginSignupDescription = returning ? strings.welcomeBackSignIn : strings.welcomeCreateAccount;
    const forgotPasswordLink = strings.forgotPassword;
    const forgottenPasswordHref = `${returnURL}myaccount/forgot-password/`;

    let privacyPolicyTextString = strings.privacyNoticeInfo;
    if (privacyPolicy.date) privacyPolicyTextString += ` ${strings.updatedOnDate}`;
    const privacyPolicyText = getPhraseWithLink(privacyPolicyTextString, privacyPolicy.link, {size: 'small'});

    const [emailCheckInProgress, setEmailCheckInProgress] = useState(false);
    const [passwordCheckInProgress, setPasswordCheckInProgress] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [emailFieldUsed, setEmailFieldUsed] = useState(false);
    const [passwordFieldUsed, setPasswordFieldUsed] = useState(false);
    const [firstNameFieldUsed, setFirstNameFieldUsed] = useState(false);
    const [lastNameFieldUsed, setLastNameFieldUsed] = useState(false);
    
    const theme: CustomTheme = useContext(ThemeContext);
    const linkWeight = theme?.config?.formFieldLabelWeight ?? 500;
    const headerText = theme?.config?.desktop?.desktopLoginHeader ?? true;
    const size = React.useContext(ResponsiveContext);
    const isDesktop = size !== 'small';

    // Error update
    const notificationCode = getNotificationCode(notification);

    const onFormChange = (values: unknown) => {
        const newValues = values as AuthCredentials;
        setdetailsTyped({
            email: newValues.email || '',
            password: newValues.password || '',
            firstName: newValues.firstName || '',
            lastName: newValues.lastName || ''
        });
    }
    
    if(returning && !firstNameFieldUsed){
        setFirstNameFieldUsed(true);
        setLastNameFieldUsed(true);
    }

    // UI elements
    function getWelcomeCTALabel() {
        if (emailCheckInProgress) return <LoadingIcon />;
        if (isServiceAgent) return strings.continueGuest;
        return strings.continue;
    }

    useEffect(() => {
        tracking()?.trackLoginOrRegisterView();
        if(!isNikeConnected) {
            dispatch(updateMarketingOptIns({
                emailOptIn: initialEmailOptIn,
            }));
        }
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (customer.ID) {
            const customerPreferences = getCustomerPreferences(customer);
            if (Object.keys(customerPreferences as CustomerPreferences).length > 0) {
                browserLocalStorage.set('hasPreference', true);
            }
        }
        // eslint-disable-next-line
    }, [customer.ID]);

    return (
        <>
        { headerText && <TitleBar title={strings.welcomeToCheckout} /> }

            {/* Error message shown on login error */}
            { (passwordError && notificationCode !== "") &&
                <Notification />
            }
            <Step>
                {/* Check Email Exists form shows first */}
                {!email ?
                    <Box flex="grow">
                        <Box margin={{ bottom: 'medium' }}>
                            { !headerText &&
                                <>
                                    <Text weight="bold" size="large">
                                        {strings.welcomeToCheckout}
                                    </Text>
                                    <br/>
                                </>
                            }
                            <Text size="medium">{strings.enterEmailToStart}</Text>
                        </Box>

                        <Box>
                            <Form
                                onSubmit={() => {
                                    setEmailCheckInProgress(true);
                                    newRelicData({ actionName: 'login', function: 'onSubmit', message: 'user clicked continue button', desktop: isDesktop});
                                    if (isServiceAgent) {
                                        dispatch(updateEmail(email, false));
                                        dispatch(createGuest(detailsTyped.email))
                                            .then(async () => {
                                                try {
                                                    dispatch(updateIsOnGuestJourney(true));
                                                    dispatch(updateDeliveryAddress({} as Address));
                                                    dispatch(updateBillingAddress({} as Address));
                                                    const locale = config.localisation.countryCode.toLowerCase();
                                                    const deliveryMethods = await carts().getCartDeliveryMethods(cartId, '', locale, true);
                                                    if (deliveryMethods?.data?.clickAndCollect && deliveryMethods?.data?.clickAndCollect.length <= 0) {
                                                        await dispatch(updateClickAndCollect(false));
                                                    }
                                                    browserLocalStorage.set('uuid', uuid());
                                                    history.push('/delivery');
                                                } catch (e) {
                                                        if(e?.message === 'INFO_CART_ALREADY_PAID') {
                                                            // We remove the cart from the website
                                                            const sessionState = getSession(session);
                                                            dispatch(putSession({
                                                                ...sessionState,
                                                                cartID: '',
                                                                commands: {
                                                                    ...sessionState.commands,
                                                                    invalidateCartCache: true,
                                                                }
                                                            }));
                                                            newRelicData({actionName: 'login', function: 'onSubmit', message: 'cart already paid, removed cart id from session' });
                                                            window.location.href = `${returnURL}`;
                                                        }
                                                    }
                                                })
                                            .finally(() => {
                                                setEmailCheckInProgress(false);
                                            });
                                    }
                                    else {
                                        dispatch(submitEmail(detailsTyped.email))
                                        .then(() =>
                                            setEmailCheckInProgress(false))
                                        .catch(() => setEmailCheckInProgress(false));
                                    }
                                }}
                                onChange={onFormChange}
                                value={detailsTyped}
                            >
                                <ValidatedInput
                                    rules={[requiredRule(strings.emailAddress), emailRule(strings.emailAddress), maxLengthRule(45,strings.emailAddress)]}
                                    name="email"
                                    label={strings.emailAddress}
                                    margin={{ bottom: 'large' }}
                                    onChange={(event) => {
                                        event.target.value.trim().length > 0 ? setEmailFieldUsed(true) : setEmailFieldUsed(false);
                                    }}
                                />
                                <Box direction="row" flex="grow">
                                    <Button
                                        primary
                                        fill="horizontal"
                                        type="submit"
                                        label={getWelcomeCTALabel()}
                                        disabled={!emailFieldUsed}
                                    />
                                </Box>
                            </Form>
                        </Box>
                    </Box>
                    :
                    <Box flex="grow">
                        <Box pad={{ bottom: 'medium' }}>
                            <Text weight="bold" size="large">{LoginSignupDescription}</Text>
                        </Box>
                        <Box>
                            <Form
                                onSubmit={() => {
                                    setPasswordCheckInProgress(true);
                                    dispatch(loginOrSignUp(detailsTyped.email, detailsTyped.password, detailsTyped.firstName, detailsTyped.lastName))
                                        .then(async () => {
                                            dispatch(updateCustomerToCart());
                                            const locale = config.localisation.countryCode.toLowerCase();
                                            const deliveryMethods = await carts().getCartDeliveryMethods(cartId, '', locale, true);
                                            if (deliveryMethods?.data?.clickAndCollect && deliveryMethods?.data?.clickAndCollect.length <= 0) {
                                                await dispatch(updateClickAndCollect(false));
                                            }
                                            setPasswordCheckInProgress(false);
                                            browserLocalStorage.set('uuid', uuid());
                                            const formattedEmail = detailsTyped.email.slice(email.indexOf('@') - 4);
                                            newRelicData({ actionName: 'login', function: 'loginOrSignUp', message: `user authentication successful with email id ${formattedEmail}, redirecting to delivery page`, desktop: isDesktop });
                                            history.push('/delivery');
                                        })
                                        .catch((e) => {
                                            setPasswordError(true);
                                            setPasswordCheckInProgress(false);
                                            if(e?.message === 'INFO_CART_ALREADY_PAID') {
                                                // We remove the cart from the website
                                                const sessionState = getSession(session);
                                                dispatch(putSession({
                                                    ...sessionState,
                                                    cartID: '',
                                                    commands: {
                                                        ...sessionState.commands,
                                                        invalidateCartCache: true,
                                                    }
                                                }));
                                                newRelicData({actionName: 'Login', function: 'loginOrSignUp', message: 'cart already paid, removed cart id from session' });
                                                window.location.href = `${returnURL}`;
                                            }
                                        })
                                }}
                                onChange={onFormChange}
                                value={detailsTyped}
                            >
                                <ValidatedInput
                                    rules={[requiredRule(strings.emailAddress), emailRule(strings.emailAddress), maxLengthRule(45,strings.emailAddress)]}
                                    name="email"
                                    label={strings.emailAddress}
                                    onChange={(event) => {
                                        event.target.value.trim().length > 0 ? setEmailFieldUsed(true) : setEmailFieldUsed(false);
                                    }}
                                />
                                <ValidatedInput
                                    rules={ returning ? [requiredRule(strings.password)] : [requiredRule(strings.password), passwordRule(strings.password), { name: 'min', params: { 'length': 8 } }, maxLength30Rule]}
                                    name="password"
                                    type="password"
                                    label={strings.password}
                                    info={passwordHelp}
                                    margin={{ bottom: returning ? 'large' : '' }}
                                    onChange={() => {
                                        if (!passwordFieldUsed) setPasswordFieldUsed(true);
                                    }}
                                />
                                { !returning &&
                                    <Box>
                                        <ValidatedInput
                                            rules={[requiredRule(strings.firstName), maxLength30Rule(strings.firstName), nameRule]}
                                            name="firstName"
                                            label={strings.firstName}
                                            addStarIfRequired={false}
                                            onChange={() => {
                                                if (!firstNameFieldUsed) setFirstNameFieldUsed(true);
                                            }}
                                        />
                                        <ValidatedInput
                                            rules={[requiredRule(strings.lastName), maxLength30Rule(strings.lastName), nameRule]}
                                            name="lastName"
                                            label={strings.lastName}
                                            addStarIfRequired={false}
                                            onChange={() => {
                                                if (!lastNameFieldUsed) setLastNameFieldUsed(true);
                                            }}
                                        />
                                    </Box>
                                }

                                <Box direction="row" flex="grow">
                                    <Button
                                        primary
                                        fill="horizontal"
                                        type="submit"
                                        label={passwordCheckInProgress ? <LoadingIcon /> : continueButton}
                                        disabled={!emailFieldUsed || !passwordFieldUsed || !firstNameFieldUsed || !lastNameFieldUsed}
                                    />
                                </Box>
                            </Form>
                            {   returning &&
                                <Anchor
                                    href={forgottenPasswordHref}
                                    margin={{ top: "small" }}
                                    a11yTitle={forgotPasswordLink}
                                    onClick={() => {tracking().trackForgotPasswordLinkClick();}}
                                >
                                    <Text weight={linkWeight}>{forgotPasswordLink}</Text>
                                </Anchor>
                            }
                        </Box>
                    </Box>
                }
            </Step>
            {!!email &&
                <Step>
                    <GuestLogin
                        onClick={() => {
                            if(isEmail(detailsTyped.email.trim())) {
                                newRelicData({ actionName: 'login', function: '<GuestLogin>', message: 'user clicked  guest login button', desktop: isDesktop });
                                setPasswordCheckInProgress(true);
                                dispatch(updateIsOnGuestJourney(true));
                                dispatch(createGuest(detailsTyped.email, true))
                                    .then(async () => {
                                        try {
                                            dispatch(updateDeliveryAddress({} as Address));
                                            dispatch(updateBillingAddress({} as Address));
                                            dispatch(updateCustomerToCart());
                                            const locale = config.localisation.countryCode.toLowerCase();
                                            const deliveryMethods = await carts().getCartDeliveryMethods(cartId, '', locale, true);
                                            if (deliveryMethods?.data?.clickAndCollect && deliveryMethods?.data?.clickAndCollect.length <= 0) {
                                                await dispatch(updateClickAndCollect(false));
                                            }
                                            browserLocalStorage.set('uuid', uuid());
                                            browserLocalStorage.set('hasPreference', false);
                                            newRelicData({actionName: 'Login', function: 'createGuest', message: 'logged in as guest moving to delivery page' });
                                            history.push('/delivery');
                                        } catch (error) {
                                            if(error?.message === 'INFO_CART_ALREADY_PAID') {
                                                // We remove the cart from the website
                                                const sessionState = getSession(session);
                                                dispatch(putSession({
                                                    ...sessionState,
                                                    cartID: '',
                                                    commands: {
                                                        ...sessionState.commands,
                                                        invalidateCartCache: true,
                                                    }
                                                }));
                                                newRelicData({actionName: 'Login', function: 'createGuest', message: 'cart already paid, removed cart id from session' });
                                                window.location.href = `${returnURL}`;
                                            }
                                            newRelicData({actionName: 'Login', function: 'createGuest', message: error?.message });
                                            console.log(error);
                                        }
                                    })
                                    .finally(() => {
                                        setPasswordCheckInProgress(false);
                                        }
                                    )
                        }}}
                        buttonLabel={passwordCheckInProgress ? <LoadingIcon /> : guestCheckoutButton}
                        disableButton={passwordCheckInProgress || !emailFieldUsed}
                    />
                </Step>
            }
            { email && showMarketingCheckboxes && !isNikeConnected &&
                <Box
                    flex={false}
                    fill="horizontal"
                    margin={{
                        left: 'medium',
                        top: isDesktop ? undefined : 'medium',
                        bottom: isDesktop ? 'xsmall' : 'small',
                    }}
                >
                    <CheckBox
                    checked={isOptInTicked}
                    onChange={(event) => {
                        setIsOptInTicked(event.target.checked);
                        dispatch(updateMarketingOptIns({emailOptIn: event.target.checked}));
                    }}
                    label={<Text size="small">{strings.marketingOptInEmail}</Text>}
                    />
                </Box>
            }
            { email && privacyPolicy.link &&
                <Box
                    margin={{ left: "medium", top: "xxsmall"}}
                    direction="row">
                    {privacyPolicyText}
                </Box>
            }

        </>
    )
}

export default Login;