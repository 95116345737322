
/**
 * 
 * @param host string
 * @returns sessionID string
 * 
 * @example
 * 
 * buildSessionID('fashion-uat-uatmesh-basegb-mobile.nonprod.jdmesh.co') // fashion-uat-uatmesh-basegb-mobile.nonprod.jdmesh.co__SESSION:123456789
 * buildSessionID('https://www.choicestore.com') // choicestore.com__SESSION:123456789
 */
export function buildSessionID(host: string) {
    const sessionCookieID = getCookie('session.ID');

    if(sessionCookieID) {
        return `${trimHost(host)}__SESSION:${sessionCookieID}`;
    }
}

/**
 * 
 * @param host string
 * @returns trimeedHost string
 * 
 * @example
 * 
 * trimHost('https://fashion-uat-uatmesh-basegb-mobile.nonprod.jdmesh.co/') // fashion-uat-uatmesh-basegb-mobile.nonprod.jdmesh.co
 * trimHost('https://www.choicestore.com/') // choicestore.com
 * trimHost('https://m.choicestore.com/') // m.choicestore.com
 */
export function trimHost(host: string) : string {
    return host
        .replace(/(^\w+:|^)\/\//, '') // remove 'https://'
        .replace(/^\/+|\/+$/g, '') // remove multiple slashes
        .replace(/^www\./, ''); // remove www.
}


/**
 * 
 * @param name string
 * @returns cookeieValue string
 * 
 * @example
 * 
 * getCookie('session.ID') // 12345677889
 */
export function getCookie(name: string) : string | undefined {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts && parts.length === 2) {
      const cookiePart = parts.pop();
      if(cookiePart){
          return cookiePart.split(';').shift();
      }
  }
}