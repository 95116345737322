import merge from 'deepmerge';
import { CustomTheme } from '.';
import baseTheme from './basegb';
import './index';
import { TickFilledBlackRounded } from '../components/Icon/index';

const theme: CustomTheme = {
    config: {
        displayName: "Cricket",
        headerLogo: '/Logo-Cricket-Navigation.svg',
        headerLogoSize: {height: '80px', width: '80px'},
        headerBackgroundColor: '#f2f1f1',
        headerBottomBorderColor: 'light-2',
        fontSrc: '/fonts/SemplicitaPro.css',
        titleFontWeight: 300,
        stepTextTransform: 'uppercase',
        stepFontWeight: 'normal',
        stepSeparatorHeightMobile: '1px',
        formFieldLabelWeight: 500,
        icons: {
            tickIcon: TickFilledBlackRounded,
        },
        textLetterSpacing: '1.6px',
        titleTextColor: '#1d1d1d',
    },

    global: {
        font: {
            family: 'Semplicita Pro, sans-serif',
            size: '16px',
        },
        colors: {
            active: '#000',
            text: "#000",
        },
    },

    text: {
        extend: `padding-right: 4px;`,
        xsmall: {
            'size': '10px',
            'height': '1.33',
        },
        small: {
            'size': '12px',
            'height': '1.33',
        },
        medium: {
            'size': '16px',
            'height': '1.75'
        },
        large: {
            'size': '20px',
            'height': '1.75'
        },
        xlarge: {
            'size': '24px',
            'height': '1.45'
        },
        '2xl?': {}, // Error in module type definition ('2xl?' instead of '2xl'?) makes these mandatory
        '3xl?': {},
        '4xl?': {},
        '5xl?': {},
        '6xl?': {},
    },

    button: {
        border: {
            radius: '0px'
        },
        extend: 'height: 48px; font-weight: bold',
    },

    checkBox: {
        check: {
            extend: (props) => {
                return props.checked ? `background: white; box-shadow: none !important;` : `box-shadow: none !important;`;
            }
        },
        icons: {
            checked: TickFilledBlackRounded,
        }
    },
};

const cricketTheme: CustomTheme = merge(baseTheme, theme);

export default cricketTheme;
