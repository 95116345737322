import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Anchor, Box, ResponsiveContext } from 'grommet';
import { RootState } from '../../store/reducers';
import { PaymentMethodModule, ExtraRenderedComponents } from '../../services/payments/types';
import { getSelectedPaymentMethod } from '../../modules/billing';
import { translate } from '../../modules/translation';
import { payments, tracking } from '../../services';
import { CustomTheme } from '../../theme';
import { Close } from '../Icon';
import TitleBar from '../TitleBar/TitleBar';
import { Maestro, Mastercard, Visa, Amex } from '../Icon/index';
import LoadingIcon from "../LoadingIcon/LoadingIcon";

/**
 * This page shows the form or validation button for the selected payment method.
 * It holds the div for the paymentMethod scripts to be loaded onto should a method have one.
 */
function PaymentPage() {

    const billing = useSelector((state: RootState) => state.billing);
    const cart = useSelector((state: RootState) => state.cart);
    const customer = useSelector((state: RootState) => state.customer);
    const translation = useSelector((state: RootState) => state.translation);
    const config = useSelector((state: RootState) => state.config);

    const selectedMethod = getSelectedPaymentMethod(billing);

    const [bodyComponent, setBodyComponent] = useState(<></>);
    const [submitComponent, setSubmitComponent] = useState(<></>);
    const [isRendered, setRendered] = useState(false);
    const [visible, setVisible] = useState(false);
    const history = useHistory();

    const theme: CustomTheme = useContext(ThemeContext);

    // styling for Desktop
    const maxWidthPayment = theme?.config?.desktop?.maxWidth ?? "800px";

    const size = React.useContext(ResponsiveContext);
    const isNikeConnected = config.nike_connected;

    const strings = {
        payWithProvider: translate(translation, 'payWithProvider', `Pay with ${selectedMethod.label}`, {provider: selectedMethod.label}),
        weAccept: translate(translation, 'weAccept', 'We Accept'),
    };

    const paymentIcons = () => {
        return (
            <Box direction="row" border={"between"} gap="xxsmall" responsive={true}>
                <Visa />
                <Mastercard />
                <Maestro />
                <Amex />
            </Box>
        );
    }

    useEffect(() => {
        tracking()?.trackPaymentView(cart, customer, isNikeConnected);
        if(selectedMethod.id === "PAYPAL") {
            setTimeout(() => {
                setVisible(true);
            }, 5000);
        } else {
            setVisible(true);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const renderModule = async () => {
            const paymentModule = await payments()?.getPaymentMethodModule(selectedMethod.id) as PaymentMethodModule;
            paymentModule?.setHistory(history);
            paymentModule?.setRedirectDescription();

            const moduleExtra: ExtraRenderedComponents = await paymentModule?.render();
            if (moduleExtra?.body) {
                setBodyComponent(React.createElement(moduleExtra?.body?.component, moduleExtra?.body?.props));
            }
            if (moduleExtra?.submit) {
                setSubmitComponent(React.createElement(moduleExtra?.submit?.component, {
                    ...moduleExtra?.submit?.props,
                    onClick: async () => {
                        await paymentModule.authorise();
                        await paymentModule.onComplete();
                        history.push('/confirm');
                    }
                })
                );
            }
            setRendered(true);
        };

        if (!isRendered) renderModule();
    }, [isRendered, history, selectedMethod.id]);

    function onClose() {
        history.push('/delivery');
    }


    return (
        <Box
            alignContent="center"
            fill="horizontal"
            responsive={false}
            alignSelf="center"
            width={size !== "small" ? { max: maxWidthPayment } : ""}
        >
            <TitleBar title={strings.payWithProvider} icon={<Anchor><Close onClick={onClose} /></Anchor>}></TitleBar>
            {selectedMethod.id === "CARD" ? <TitleBar title={strings.weAccept} icon={paymentIcons()}></TitleBar> : null}
            <Box
                alignContent="center"
                fill="horizontal"
                flex="grow"
            >
                {/* Container for the provider scripts */}
                <Box
                    align="center"
                    alignSelf="center"
                    flex="grow"
                    margin={{top: "xsmall"}}
                    width={size !== "small" ? "100%" : ""}
                    style={{
                        position: selectedMethod.id === "PAYPAL" ? "absolute" : "static",
                        visibility: visible ? "visible" : "hidden"
                    }}
                    id="payment-method-container-payment-page">
                </Box>
                {/* Submit button - if provided (eg. Klarna) */}
                {submitComponent}
                { !visible && <LoadingIcon color="black" /> }
            </Box>
            <Box pad="medium">
                {/* Form - if provided (eg. Adyen) */}
                {bodyComponent}
            </Box>
        </Box>
    )
}


export default PaymentPage;
