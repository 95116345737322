import { ResizingServiceImageProperties, ResizeOptions } from './types';
/**
 * @param resizeUrl  Url containing '{&queryParameters*}'to be replaced with params
 * @param options    A set of option params to resize the image by
 * @param imageProperties Allowed image properties, used to filter out options
 * 
 * @returns          A valid URL reiszed with the provided options
 */
export function resizeImage(resizeUrl: string, options: ResizeOptions, imageProperties: ResizingServiceImageProperties) : string {
    const replace = `{&queryParameters*}`;
    const newResizeUrl = resizeUrl.replace(replace, '');
    const url = new URL(newResizeUrl);
    
    for (let key in options){
        if (imageProperties[key]){
            let paramToAdd = imageProperties[key].replace('queryParameters/', '');
            let valueToAdd = options[key];
            url.searchParams.append(paramToAdd, valueToAdd);
        }
    }

    return url.href;
}