import merge from 'deepmerge';
import { Security } from '../components/Icon';
import { CustomTheme } from '.';
import baseTheme from './basegb';
import './index';

const theme: CustomTheme = {
    config: {
        displayName: "Wellgosh",
        headerLogo: '/Logo-Wellgosh-Navigation.svg',
        headerLogoSize: {height: 'auto', width: '80'},
        headerBackgroundColor: '#454545',
        headerBottomBorderColor: '#454545',
        icons: {
            securityIcon: Security,
        },
    },
};

const wellgoshTheme: CustomTheme = merge(baseTheme, theme);

export default wellgoshTheme;
