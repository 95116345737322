import merge from 'deepmerge';
import defaultTheme, { CustomTheme } from '.';
import { SecurityOutline, TickFilledChoice, TickEmptyChoice } from '../components/Icon/index';
import './choice.css';

const theme: CustomTheme = {
    config: {

        displayName: "Choice GB",

        titleFontWeight: 500,

        appBackground: 'white',

        stepFontSize: 'large',
        stepFontWeight: 500,
        stepSeparatorHorizontalMargin: '16px',
        stepSeparatorHeight: '1px',
        stepSeparatorColour: 'light-2',
        stepBorder: false,
        stepSeparatorMargins: {
            vertical: 'large',
            horizontal: 'medium'
        },

        headerLogo: '/Logo-Choice-Navigation.png',
        headerLogoSize: {height: '18px'},
        headerShowLogo: true,
        headerBackgroundColor: 'white',
        headerBottomBorderColor: 'light-2',
        headerIconColor: 'black',
        headerHeight: '64px',
        headerTitleFontSize: 'medium',
        headerLogoContainerMinWidth: '75px',

        imageListBorder: true,

        summaryStepShowSeparator: false,
        summaryShowTotalsTitle: true,
        summaryTotalFontSize: 'large',
        summarySeparatorIsAbove: true,
        summarySeparatorMargins: {
            top: '14px',
            bottom: 'medium'
        },
        
        icons: {
            tickIcon: TickFilledChoice,
            untickIcon: TickEmptyChoice,
            securityIcon: SecurityOutline,
        },

        fontSrc: 'https://fonts.googleapis.com/css2?family=Sora:wght@400;500;700&display=swap',

        desktop: {
            desktopViewHorizontalPaddingLogin : "13vw",
            desktopLoginHeader : true,
            desktopViewCentralMargin: "10vw",
            desktopStepSeparatorColour: "light-2",
            boxBorder: false,
            borderSide: 'bottom',
        }
    },

    global: {
        font: {
            family: 'Sora, sans-serif',
        },
        colors: {
            brand: 'black',
        },
    },

    button: {
        border: {
            radius: '0px'
        },
        extend: 'height: 48px; font-weight: normal',
    },

    checkBox: {
        check: {
            radius: '0px',
            thickness: '1px',
            extend: (props) => {
                return props.checked ? `background: white; border-color: #e7e8e9; box-shadow: none !important;` : `box-shadow: none !important;`;
            }
        },
        icons: {
            checked: TickFilledChoice,
        }
    },

    radioButton: {
        font: {
            weight: 500,
        }
    },

    tab: {
        pad: {horizontal: '6px', bottom: 'small'},
        border: {
            active: {
                color: 'brand'
            },
            hover: {
                color: 'brand'
            }
        },
    },
};

const choiceTheme: CustomTheme = merge(defaultTheme, theme);

export default choiceTheme;
