import React from 'react';
import ReactDOM from 'react-dom';
import store, { persistor } from './store';
import { Provider } from 'react-redux';
import { Grommet } from 'grommet';
import * as serviceWorker from './serviceWorker';
import { PersistGate } from 'redux-persist/integration/react';
import { Config } from './modules/config/types';
import localStore from './lib/localStore/localStore';
import { local as browserLocalStorage } from 'store2';



import blankTheme from './theme/blank';
import jdsportsukTheme, {CustomTheme} from './theme'; // JD
import choiceTheme from './theme/choicegb';
import App from './components/App/App';
import baseTheme from './theme/basegb';
import tessutiTheme from './theme/tessuti';
import scottsTheme from './theme/scottsmenswear';
import sizeTheme from './theme/size';
import perrySportTheme from './theme/perrysport';
import aktieSportTheme from './theme/aktiesport';
import hipstoreTheme from './theme/hipstore';
import footpatrolTheme from './theme/footpatrol';
import cricketTheme from './theme/cricketgb';
import oipolloiTheme from './theme/oipolloigb';
import wellgoshTheme from './theme/wellgoshgb';
import jdsportsdeTheme from './theme/jdsportsde';
import jdsportsatTheme from './theme/jdsportsat';
import sizedeTheme from "./theme/sizede";
import sizeitTheme from "./theme/sizeit";
import sizefiTheme from "./theme/sizefi";
import footpatroldeTheme from "./theme/footpatrolde";
import footpatrolfiTheme from "./theme/footpatrolfi";
import jdwfaTheme from "./theme/jdwfa";
import jdsfaTheme from "./theme/jdsfa";
import jdnfaTheme from "./theme/jdnfa";
import supplyAndDemandTheme from "./theme/supplyanddemand";
import { updateDialog } from "./modules/notification";

const queryParams = window.location.search;
// Theme override by query string
const storeMatch = queryParams.match(/[?&]store=(\w+)/);
const cartMatch = queryParams.match(/[?&]cart=(\w+)/);
const localeMatch = queryParams.match(/[?&]locale=(\w+)/) ?? '';
let locale = localeMatch[1];
if(locale !== undefined) {
    browserLocalStorage.set('countryCode', locale);
} else {
    locale = browserLocalStorage.get('countryCode');
}
switch (locale) {
    case 'dk' :
        locale = 'da';
        break;
    case 'se' :
        locale = 'sv';
        break;
}
document.getElementsByTagName("html")[0].setAttribute('lang', locale);

// A user with an hour inactive session will be redirected to basket 
// This configuration is set in DynamoDB
setTimeout(() => {
    store.dispatch(updateDialog('session_expired'));
}, [3600000])

// if it's a new session OR cart is passed from website clear any saved state
// otherwise persist the state for the session
if(!sessionStorage.getItem('session') || cartMatch){
    persistor.purge();
    sessionStorage.setItem('session', 'true');
}

function getFascia(){
    let fascia = storeMatch?.length === 2 ? storeMatch[1] : '';
    if (!fascia) {
        // attempt to restore from session
        const sessionConfig : Config = localStore.get('state.config') || store.getState().config;
        fascia = browserLocalStorage.get('store') || sessionConfig?.store || 'blank';
    }
    return fascia;
}

interface Themes {
    [key: string]: CustomTheme
}
// TODO: make  this dynamic 
const themes: Themes = {
    'blank': blankTheme,
    'jdsportsuk': jdsportsukTheme,
    'jdsportsde': jdsportsdeTheme,
    'jdsportsat': jdsportsatTheme,
    'jdsportsnz': {...jdsportsukTheme, config: {...jdsportsukTheme.config, displayName: 'JD Sports NZ', faviconLogo: 'jdsportsuk/favicon.ico'}} as CustomTheme,
    'jdsportsdk': {...jdsportsukTheme, config: {...jdsportsukTheme.config, displayName: 'JD Sports DK', faviconLogo: 'jdsportsuk/favicon.ico'}} as CustomTheme,
    'jdsportsfr': {...jdsportsukTheme, config: {...jdsportsukTheme.config, displayName: 'JD Sports FR', faviconLogo: 'jdsportsuk/favicon.ico'}} as CustomTheme,
    'jdsportsit': {...jdsportsukTheme, config: {...jdsportsukTheme.config, displayName: 'JD Sports IT', faviconLogo: 'jdsportsuk/favicon.ico'}} as CustomTheme,
    'jdsportsnl': {...jdsportsukTheme, config: {...jdsportsukTheme.config, displayName: 'JD Sports NL', faviconLogo: 'jdsportsuk/favicon.ico'}} as CustomTheme,
    'jdsportspt': {...jdsportsukTheme, config: {...jdsportsukTheme.config, displayName: 'JD Sports PT', faviconLogo: 'jdsportsuk/favicon.ico'}} as CustomTheme,
    'jdsportsmyf': {...jdsportsukTheme, config: {...jdsportsukTheme.config, displayName: 'JD Sports MY',faviconLogo: 'jdsportsuk/favicon.ico'}} as CustomTheme,
    'jdsportsbe': {...jdsportsukTheme, config: {...jdsportsukTheme.config, displayName: 'JD Sports BE', faviconLogo: 'jdsportsuk/favicon.ico'}} as CustomTheme,
    'jdsportssg': {...jdsportsukTheme, config: {...jdsportsukTheme.config, displayName: 'JD Sports SG', faviconLogo: 'jdsportsuk/favicon.ico'}} as CustomTheme,
    'jdsportsie': {...jdsportsukTheme, config: {...jdsportsukTheme.config, displayName: 'JD Sports IE', faviconLogo: 'jdsportsuk/favicon.ico'}} as CustomTheme,
    'jdsportsil': {...jdsportsukTheme, config: {...jdsportsukTheme.config, displayName: 'JD Sports IL', faviconLogo: 'jdsportsuk/favicon.ico'}} as CustomTheme,
    'jdsportses': {...jdsportsukTheme, config: {...jdsportsukTheme.config, displayName: 'JD Sports ES', faviconLogo: 'jdsportsuk/favicon.ico'}} as CustomTheme,
    'jdsportsse': {...jdsportsukTheme, config: {...jdsportsukTheme.config, displayName: 'JD Sports SE', faviconLogo: 'jdsportsuk/favicon.ico'}} as CustomTheme,
    'jdsportsauf': {...jdsportsukTheme, config: {...jdsportsukTheme.config, displayName: 'JD Sports AU',faviconLogo: 'jdsportsuk/favicon.ico'}} as CustomTheme,
    'jdsportsfi': {...jdsportsukTheme, config: {...jdsportsukTheme.config, displayName: 'JD Sports FI', faviconLogo: 'jdsportsuk/favicon.ico'}} as CustomTheme,
    'jdsportsth': {...jdsportsukTheme, config: {...jdsportsukTheme.config, displayName: 'JD Sports TH', faviconLogo: 'jdsportsuk/favicon.ico'}} as CustomTheme,
    'jdsportsjx': {...jdsportsukTheme, config: {...jdsportsukTheme.config, displayName: 'JD Sports JX', faviconLogo: 'jdsportsuk/favicon.ico'}} as CustomTheme,
    'choicegb': choiceTheme,
    'basegb': baseTheme,
    'scottsmenswear': scottsTheme,
    'tessuti': tessutiTheme,
    'size': sizeTheme,
    'hipstoregb': hipstoreTheme,
    'footpatrolgb': footpatrolTheme,
    'cricketgb': cricketTheme,
    'oipolloigb': oipolloiTheme,
    'wellgoshgb': wellgoshTheme,
    'sizefr' : {...sizeTheme, config: {...sizeTheme.config, displayName: 'Size FR'}} as CustomTheme,
    'sizede' : sizedeTheme,
    'sizefi' : sizefiTheme,
    'sizeit' : sizeitTheme,
    'sizeie' : {...sizeTheme, config: {...sizeTheme.config, displayName: 'Size IE'}} as CustomTheme,
    'sizenl' : {...sizeTheme, config: {...sizeTheme.config, displayName: 'Size NL'}} as CustomTheme,
    'sizees' : {...sizeTheme, config: {...sizeTheme.config, displayName: 'Size ES'}} as CustomTheme,
    'sizedk' : {...sizeTheme, config: {...sizeTheme.config, displayName: 'Size DK'}} as CustomTheme,
    'sizebe' : {...sizeTheme, config: {...sizeTheme.config, displayName: 'Size BE'}} as CustomTheme,
    'sizese' : {...sizeTheme, config: {...sizeTheme.config, displayName: 'Size SE'}} as CustomTheme,
    'footpatrolfr' : {...footpatrolTheme, config: {...footpatrolTheme.config, displayName: 'Footpatrol FR'}} as CustomTheme,
    'footpatrolde' : footpatroldeTheme,
    'footpatrolit' : {...footpatrolTheme, config: {...footpatrolTheme.config, displayName: 'Footpatrol IT'}} as CustomTheme,
    'footpatrolie' : {...footpatrolTheme, config: {...footpatrolTheme.config, displayName: 'Footpatrol IE'}} as CustomTheme,
    'footpatrolnl' : {...footpatrolTheme, config: {...footpatrolTheme.config, displayName: 'Footpatrol NL'}} as CustomTheme,
    'footpatroldk' : {...footpatrolTheme, config: {...footpatrolTheme.config, displayName: 'Footpatrol DK'}} as CustomTheme,
    'footpatrolfi' : footpatrolfiTheme,
    'footpatrolse' : {...footpatrolTheme, config: {...footpatrolTheme.config, displayName: 'Footpatrol SE'}} as CustomTheme,
    'aktiesport' : aktieSportTheme,
    'perrysport' : perrySportTheme,
    'supplyanddemand' : supplyAndDemandTheme,
    'jdnfa' : jdnfaTheme,
    'jdsfa' : jdsfaTheme,
    'jdwfa' : jdwfaTheme,
};
// this needs to be wrapped so 'getFascias' can be run after the persistor 
// has restored the state
const AppContent = () => (
    <Grommet theme={themes[getFascia()]}>
        <App fascia={getFascia()} />
    </Grommet>
)

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <AppContent/>
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// serviceWorker.register();
serviceWorker.unregister();
