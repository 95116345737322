import React, { createContext, useContext, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, ResponsiveContext, BoxProps } from 'grommet';
import { ThemeContext } from 'styled-components';
import { RootState } from '../../store/reducers';
import { getGenericErrorMessage, getNotificationCode } from '../../modules/notification';
import { CustomTheme } from '../../theme';
import { updateDialog } from '../../modules/notification';
import { AppThunkDispatch } from '../../modules/thunk';

export const PageRefContext = createContext<React.RefObject<HTMLDivElement>|null>(null);

/**
 * A box that wraps all the content for a standalone page (eg. Login, Delivery, Confirmation, etc.)
 * Scrolls while letting the AppHeader component fixed on top of the screen.
 * Takes the same props as the Grommet Box component.
 */
function Page({ children, ...rest }: PageProps) {
    //Scroll to the top of the container 
    useEffect(() => {
        if (refContainer?.current?.scrollTo) refContainer?.current?.scrollTo(0, 0);
        // eslint-disable-next-line
    }, [window.location.href]);

    const theme: CustomTheme = useContext(ThemeContext);
    const refContainer = useRef<HTMLDivElement>(null);
    const mobileNavHeight = theme?.config?.headerHeight ?? '48px'
    const desktopNavHeight = theme?.config?.desktop?.desktopNavHeight ?? "72px";
    const screenSize = React.useContext(ResponsiveContext);
    
    const isDesktop = screenSize !== 'small';
    const headerHeight = isDesktop ? desktopNavHeight : mobileNavHeight;
    const desktopViewVerticalPadding = theme?.config?.desktop?.desktopViewVerticalPadding ?? "20px";
    const desktopViewHorizontalPadding = window.location.pathname !== '/login' ? (theme?.config?.desktop?.desktopViewHorizontalPadding ??  "120px") : (theme?.config?.desktop?.desktopViewHorizontalPaddingLogin ??  "120px");
    const notificationBoxHeight = theme?.config?.notificationBoxHeight ?? "48px";

    const notification = useSelector((state: RootState) => state.notification);
    const notificationCode = getNotificationCode(notification);
    const genericErrorMessage = getGenericErrorMessage(notification);
    const notificationBoxCurrentHeight = (notificationCode || genericErrorMessage) && window.location.pathname !== '/login'
        ? `${notificationBoxHeight}`
        : '0px';
    const pageHeight = `calc(100vh - ${headerHeight} - ${notificationBoxCurrentHeight})`;
    const dispatch: AppThunkDispatch = useDispatch();
    const cart = useSelector((state: RootState) => state.cart);

    const showSessionExpiredError = !cart.ID && window.location.pathname !== '/';

    useEffect(() => {
        if(showSessionExpiredError) {
            dispatch(updateDialog('session_expired'));
        }
    // eslint-disable-next-line
    }, []);

    // Workaround to force the layers height to be recalculated. When a notification is triggered
    // while layers are rendered, the notification is hidden until a scroll event refreshes the page and shrinks the layers
    useEffect(() => {
        if (refContainer?.current?.scrollBy) refContainer?.current?.scrollBy(0,1);
        if (refContainer?.current?.scrollBy) refContainer?.current?.scrollBy(0,-1);
    }, [notificationCode]);

    return(
        <Box
            ref={refContainer}
            flex={false}
            responsive={false}
            height={pageHeight}
            overflow="auto"
            pad={isDesktop ? { vertical: desktopViewVerticalPadding, horizontal: desktopViewHorizontalPadding } : ""}
            {...rest}
        >
            <PageRefContext.Provider value={refContainer}>
                { !showSessionExpiredError && children }
            </PageRefContext.Provider>
        </Box>
    );
};

type PageProps = BoxProps & React.ClassAttributes<HTMLDivElement> & React.HTMLAttributes<HTMLDivElement>;

export default Page;
