import { AppThunk } from '../thunk';
import { Session, SessionActionTypes, UPDATE_IS_SERVICE_AGENT, UPDATE_SESSION } from './types'
import { buildSessionID } from '../../lib/session/session';
import { session } from '../../services';
import newRelicData from "../../newrelic";

export const initialState: Session = {
    ID: '',
    cartID: '',
    authToken: {},
    customerID: '',
    serviceAgent: false,
}

// Reducer
export default function reducer(state = initialState, action: SessionActionTypes) {
    switch (action.type) {
        case UPDATE_SESSION: {
            return {...state, ...action.payload };
        }
        case UPDATE_IS_SERVICE_AGENT: {
            return {...state, serviceAgent: action.isServiceAgent };
        }
        default: {
            return state;
        }
    }
}

// Selectors
export const getSession = (state: Session) => state;
export const getIsServiceAgent = (state: Session) => state.serviceAgent === true;

// Actions
export function updateSession(update: Session): SessionActionTypes {
    return {
        type: UPDATE_SESSION,
        payload: update
    };
};

export function updateIsServiceAgent(isServiceAgent: boolean): SessionActionTypes{
    return {
        type: UPDATE_IS_SERVICE_AGENT,
        isServiceAgent
    };
};

// side effects
export function loadSession(host?: string) : AppThunk<Promise<Session>> {
    return async (dispatch, getState) => {
        const currentState = getState();
        let sessionID: string | undefined = currentState.session.ID;
        if(!host){
            const hostFromConfig = currentState.config.returnUrl;
            if(!hostFromConfig) {
                newRelicData({ actionName: 'session', function: 'loadSession', message: 'Missing host' });
                throw new Error("Unable to retreive session. Missing host.")
            }
            host = hostFromConfig;
        }

        if(!sessionID){
            // we haven't worked out what our session ID is yet
            const newSessionID = buildSessionID(host);
            newRelicData({ actionName: 'session', function: 'loadSession', message: 'New Session ID: ' + newSessionID});
            if(!newSessionID){
                newRelicData({ actionName: 'session', function: 'loadSession', message: 'Unable to retreive session. Missing could not find session id.'});
                throw new Error("Unable to retreive session. Missing could not find session id.")
            }
            sessionID = newSessionID;
        }

        const sessionResponse = await session().getSession(sessionID);
        const currentSession : Session = {
            ID: sessionID,
            ...sessionResponse.data,
            // customerID can be returned as null by the service, but the service will throw an error when trying to PUT with this value.
            customerID: sessionResponse.data.customerID ?? '',
        };
        dispatch(updateSession(currentSession));
        return currentSession;
    }
}

export function putSession(sessionUpdate: Session) : AppThunk<Promise<Session | undefined>> {
    return async (dispatch, getState) => {
        const currentState = getState();
        const sessionID: string = currentState.session.ID;
        if(sessionID) {
            const sessionResponse = await session().updateSession(sessionID, sessionUpdate);
            const currentSession: Session = {ID:sessionID, ...sessionResponse.data};
            dispatch(updateSession(currentSession));
            return currentSession;
        } else {
            // we might not have a session
            return;
        }
    }
}
    