import merge from 'deepmerge';
import { CustomTheme } from '.';
import sizeTheme from "./size";

const theme: CustomTheme = {
    config: {
        displayName: "Size DE",
        showAddress1And2OnSameLine: true,
    },
};

const sizedeTheme: CustomTheme = merge(sizeTheme, theme);

export default sizedeTheme;