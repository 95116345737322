import adaptor from './adaptors';
import { AppResponse } from './response';
import { RootState } from '../store/reducers';
import { Order } from '../modules/order/types';

export interface OrdersService {
    /**
     * @param orderID Can also be an 'invoiceID', in which case the OAuth check will be bypassed platform side, if the call is made
     * within 60 second of the order creation.
     */
    getOrder: (orderID: string) => Promise<AppResponse<Order>>,
}


function init(state: RootState){
    const service = adaptor('mesh', 'orders', state) as OrdersService;
    return service;
}

export default init;
