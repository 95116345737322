import React, { useContext, useEffect, useRef, useState } from 'react';
import { ResponsiveContext, Form, Box, Button, Anchor,Text} from "grommet";
import { ThemeContext } from 'styled-components';
import { CVV, ArrowUp, ArrowDown } from '../Icon/index';
import Step from '../Step/Step';
import { useDispatch } from 'react-redux';
import ValidatedInput from '../ValidatedInput/ValidatedInput';
import { requiredRule, validationOverrideRule } from '../../lib/validate/validate';
import { AppThunkDispatch } from '../../modules/thunk';
import { addGiftCard, getGiftCards, removeGiftCard, getCartId, getIsEligibleForGiftCards } from "../../modules/cart";
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';
import { CustomTheme } from '../../theme/index';
import InfoBox from '../InfoBox/InfoBox';
import TitleBar from '../TitleBar/TitleBar';
import { getHasGiftCardError, updateGiftCardError, loadPaymentMethods } from '../../modules/billing';
import { translate } from '../../modules/translation';
import newRelicData from '../../newrelic';
import store from "../../store";
import {updateDialog} from "../../modules/notification";

let intialRender = false;
function GiftCardForm() {
    const dispatch: AppThunkDispatch = useDispatch();
    const translation = useSelector((state: RootState) => state.translation);

    const strings = {
        addGiftCard: translate(translation, 'addGiftCard', 'Add Gift Card'),
        giftCardNumber: translate(translation, 'giftCardNumber', 'Gift Card Number'),
        fourDigitPin: translate(translation, 'fourDigitPin', '4-Digit PIN'),
        apply: translate(translation, 'apply', 'Apply'),
        remove: translate(translation, 'remove', 'Remove'),
        anatwineGiftCard: translate(translation, 'anatwineGiftCard', 'Sorry you cannot purchase a product that is direct from the supplier with a Gift Card. We are working on it.'),
        invalidPin: translate(translation, 'invalidPin', 'Invalid PIN provided.'),
        invalidGiftCard: translate(translation, 'invalidGiftCard', 'Invalid giftcard number provided.'),
        giftCardLegacyInstructions: translate(translation, 'giftCardLegacyInstructions', "If you don't have a pin code on the back of your gift card please use 1111."),
    };
    const size = React.useContext(ResponsiveContext);
    const [giftCardNumber, setGiftCardNumber] = useState('');
    const [pinNumber, setPinNumber] = useState('');
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const cart = useSelector((state: RootState) => state.cart);
    const billing = useSelector((state: RootState) => state.billing);
    const giftcards = getGiftCards(cart) ?? [];
    const isEligibleForGiftCards = getIsEligibleForGiftCards(cart);
    const hasGiftCardError = getHasGiftCardError(billing);
    const theme: CustomTheme = useContext(ThemeContext);
    const tickIcon = theme?.config?.icons?.tickIcon ? React.createElement(theme?.config?.icons?.tickIcon) : null;
    const desktopBoxWidth = theme?.config?.desktop?.boxWidth ?? "80vw";
    const [isGiftCardBoxOpen, setIsGiftCardBoxOpen] = useState(false);
    const isDesktop = size !== 'small';
    const cartId = getCartId(cart);
    const logOnce = useRef(intialRender);
    
    const titleBarDropdownIcon =
        <Anchor onClick={() => setIsGiftCardBoxOpen(!isGiftCardBoxOpen)}>
            {isGiftCardBoxOpen
                ?
                <ArrowUp />
                :
                <ArrowDown />
            }
        </Anchor>;

    useEffect(() => {
        if (giftcards.length)  {
            setIsGiftCardBoxOpen(true)
        }
        // eslint-disable-next-line
    }, []);
    
    useEffect(() => {
        if(!isEligibleForGiftCards) {
            store.dispatch(updateDialog('unavailable_for_gift_cards'));
        }
        // eslint-disable-next-line
    }, [hasSubmitted, !isEligibleForGiftCards])

    if(hasSubmitted && !isEligibleForGiftCards && !logOnce.current) {
        newRelicData({actionName: 'giftCardForm', function: 'inside step', message:'Sorry you cannot purchase a product that is direct from the supplier with a Gift Card. We are working on it.'});
        logOnce.current = true;
    }

    return (
        <Box
            background={isDesktop ? '' : { color: "light-1" }}
            width={isDesktop ? desktopBoxWidth : "100vw"}
            overflow={!isDesktop ? "scroll" : ""}
            responsive={false}
        >
            <TitleBar
                title={strings.addGiftCard}
                icon={titleBarDropdownIcon}
                borderSide={isDesktop ? theme?.config?.desktop?.borderSide ?? 'bottom' : 'bottom'}
            />

            <Box style= { !isGiftCardBoxOpen? {'display': 'none'} : {'display': 'block'}}  >
                <Form
                    onSubmit={async() => {
                        setHasSubmitted(true);
                        if (!isEligibleForGiftCards) return;

                        //Add the gift card
                        await dispatch(addGiftCard(giftCardNumber, pinNumber));
                        await dispatch(loadPaymentMethods(cartId));

                        //Clear the fields
                        setGiftCardNumber('');
                        setPinNumber('');
                    }}
                >
                    <Box>
                        <Step>
                            <Box width={isDesktop ? "100vw" : ''}>
                                <ValidatedInput
                                    rules={[
                                        requiredRule(strings.giftCardNumber),
                                        hasSubmitted && hasGiftCardError ?
                                            validationOverrideRule(false, strings.invalidGiftCard) :
                                            validationOverrideRule(isEligibleForGiftCards)
                                    ]}
                                    name="giftCardNumber"
                                    placeholder={strings.giftCardNumber}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setGiftCardNumber(event.currentTarget.value);
                                        setHasSubmitted(false);
                                        dispatch(updateGiftCardError(false));
                                        logOnce.current = false;
                                    }}
                                    value={giftCardNumber}
                                />
                                <Box
                                    direction="row"
                                >
                                    <ValidatedInput
                                        rules={[
                                            requiredRule(strings.fourDigitPin), { name: 'pin',fieldName:strings.fourDigitPin },
                                            hasSubmitted && hasGiftCardError ?
                                                validationOverrideRule(false, strings.invalidPin) :
                                                validationOverrideRule(isEligibleForGiftCards)
                                            ]}
                                        name="pinNumber"
                                        placeholder={strings.fourDigitPin}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setPinNumber(event.currentTarget.value);
                                            setHasSubmitted(false);
                                            dispatch(updateGiftCardError(false));
                                            logOnce.current = false;
                                        }}
                                        value={pinNumber}
                                    />
                                    <Box
                                        margin="medium"
                                    >
                                        <CVV/>
                                    </Box>
                                    <Box align="end" flex="grow">
                                        <Button
                                            primary
                                            disabled={!giftCardNumber || !pinNumber || hasGiftCardError}
                                            type="submit"
                                            label={strings.apply}
                                            a11yTitle={strings.apply}
                                        />
                                    </Box>
                                </Box>

                                { theme?.config?.giftCardShowLegacyInstruction &&
                                    <Text size={"small"}>{strings.giftCardLegacyInstructions}</Text>
                                }
                                {/* { hasSubmitted && !isEligibleForGiftCards &&
                                    <Text color={errorTextColour}>{strings.anatwineGiftCard}</Text>
                                } */}
                                {
                                    giftcards.map((giftcard, index) => (
                                        <Box pad={{bottom: 'xsmall'}} key={index}>
                                            <InfoBox
                                                icon={tickIcon}
                                                text={translate(translation, 'eGiftCardApplied', `eGift Card ${giftcard.cardNumber} Applied`, {cardNumber: giftcard.cardNumber})}
                                                background="white"
                                                actionButton={{label: strings.remove, callback: async() => {
                                                    await dispatch(removeGiftCard(giftcard.cardNumber));
                                                    await dispatch(loadPaymentMethods(cartId));
                                                }}}
                                            />
                                        </Box>
                                    ))
                                }
                            </Box>
                        </Step>
                    </Box>
                </Form>
            </Box>
        </Box>
    );
}

export default GiftCardForm;
