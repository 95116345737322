import { AxiosInstance, AxiosResponse } from 'axios';
import { MeshConfig } from './types';

export interface PagesResponse {
    CSS: string,
    ID: string,
    body: string,
    channels: string[],
    content: string,
    externalCSS: string[],
    href: string,
    icon: any,
    id: string,
    metaData: any,
    pageType: string,
    pageTypes: string,
    scripts: string[],
    slug: string | null,
    sortOrder: number,
    title: string,
}

function pages (client: AxiosInstance, meshConfig: MeshConfig) {

    const { channel, locale} = meshConfig;

    async function getPages(pageId:string): Promise<AxiosResponse<PagesResponse>>{
        let pagesResponse = await client.get<PagesResponse>(`pages/${pageId}`, {
            params: {
                channel,
                locale
            }
        });

        return pagesResponse;
    }

    return {
        getPages
    };
}

export type PagesService = ReturnType<typeof pages>;

export default pages;
