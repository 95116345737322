import { RootState } from '../store/reducers';
import adaptor from './adaptors';
import { AppResponse } from './response';


export type AddressPredictItem = {
    ID: string,
    rel: string,
    href: string,
    addressString: string
};

export type AddressRetrieved = {
    address1: string,
    address2: string,
    town: string,
    county: string,
    postcode: string,
    country: string
};

export interface AddressesService {
    getPredictedAddresses: (query: string, lastId?:string, locale?: string) => Promise<AppResponse<AddressPredictItem[]>>,
    getRetrievedAddress: (retrieve: string, locale?: string) => Promise<AppResponse<AddressRetrieved>>,
}


function init(state: RootState){
    const service = adaptor('mesh', 'addresses', state) as AddressesService;
    return service;
}

export default init;
