import React from 'react';
import PropTypes from 'prop-types';
import iconMap from './icon-map'
import './index';


const EmptyIcon = () => <div/>

/**
 * Reusable icon component which uses the name given in icon-map.ts
 * Icons are downloaded from Zeplin as svgs https://support.zeplin.io/en/articles/1338605-downloading-all-available-assets-in-a-screen
 * 
 * @param {string} name name of the icon specified in icon-map.ts
 * @param {string} fill color of the icon/svg fill
 * @param {string} width 
 * @param {string} height 
 * @param {function} onClick
 */
function Icon({ name, fill, height, width, onClick, ...rest }: IconProps) {
    const Icon = name ? iconMap[name] : EmptyIcon;

    return (
        <Icon aria-label={`${name} icon`} fill={fill} onClick={onClick} style={{ width: width, height: height, fill: fill}} {...rest}/>
    );
}

export type IconProps = {
    name?: string,
    fill?: string,
    height?: string,
    width?: string,
    onClick?: (event: React.MouseEvent<SVGElement>) => void
};

Icon.propTypes = {
    name: PropTypes.string,
    fill: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    onClick: PropTypes.func
};


export default Icon;
