import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../store/reducers';
import Delivery from '../components/Delivery/Delivery';
import { selectOption } from '../modules/delivery';
import {
    getDeliveryOptions, getSelectedDeliveryOption, getStores, getPostOffices, getSelectedStore, getShowStoreDetailedView, selectStoreForDetailedView,
    getCollectionDetails, selectStore, updateCollectionDetails, getMethods,
    getDeliveryAddress, updateDeliveryAddress,
    getSelectedMethod, selectMethod,
} from '../modules/delivery';
import { getBillingAddress, updateBillingAddress, getSelectedPaymentMethod } from '../modules/billing';
import { getProducts } from '../modules/cart';
import { CollectionDetails } from '../modules/delivery/types';
import { Address } from '../modules/customer/types';


const mapStateToProps = (state : RootState) => {
    return {
        deliveryOptions: getDeliveryOptions(state.delivery),
        selectedDeliveryOption: getSelectedDeliveryOption(state.delivery),
        deliveryMethods: getMethods(state.delivery),
        selectedMethod: getSelectedMethod(state.delivery),
        products: getProducts(state.cart),
        stores: getStores(state.delivery),
        postoffices: getPostOffices(state.delivery),
        selectedStore: getSelectedStore(state.delivery),
        showStoreDetailedView: getShowStoreDetailedView(state.delivery),
        collectionDetails: getCollectionDetails(state.delivery),
        deliveryAddress: getDeliveryAddress(state.delivery),
        billingAddress: getBillingAddress(state.billing),
        selectedPaymentMethod: getSelectedPaymentMethod(state.billing),
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => {
    return {
        onSelectOption: (option : string) => dispatch(selectOption(option)),
        onSelectMethod: (methodId : string) => dispatch(selectMethod(methodId)),
        selectStore: (storeId : string) => dispatch(selectStore(storeId)),
        selectStoreForDetailedView: (storeId : string) => dispatch(selectStoreForDetailedView(storeId)),
        updateCollectionDetails: (details: CollectionDetails) => dispatch(updateCollectionDetails(details)),
        updateDeliveryAddress: (details: Address) => dispatch(updateDeliveryAddress(details)),
        updateBillingAddress: (details: Address) => dispatch(updateBillingAddress(details)),
    };
};

export type DeliveryMapToProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const DeliveryPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(Delivery);

export default DeliveryPage;
