import { createLanguageClientConfig } from './config';
import { createLanguageClient } from './client';
import { LanguageConfig } from './types';
import languageFrameworks from './frameworks';

/**
 * Retrieves the fascia under which the translations will be.
 * Example: jdsportsde -> jdsports; footpatrolfr -> footpatrol
 * @param fascia The current fascia, eg. `jdsportsde`
 * @return The fascia in which the translations will actually be set in Mesh Control, eg. `jdsports`.
 */
function getParentStore(fascia: string) {
    if (fascia.match(/^jdsports/)) {
        return 'jdsports';
    }
    else if (fascia.match(/^size/)) {
        return 'size';
    }
    else if (fascia.match(/^footpatrol/)) {
        return 'footpatrol';
    }
    else {
        return fascia;
    }
}

function init(languageConfig: LanguageConfig){
    const { environment, apiKey, store } = languageConfig;
    if (!store || !environment || !apiKey){
        throw new Error("Required language config not provided.")
    }

    const storesPath = `/stores/${getParentStore(store)}`;
    const config = createLanguageClientConfig(environment, apiKey, storesPath);
    const client = createLanguageClient(config);

    return {
        languageFrameworks: languageFrameworks(client, languageConfig),
    }
}

export type LanguageService = ReturnType<typeof init>;

export default init;
