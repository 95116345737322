import React from 'react';
import PropTypes from 'prop-types';
import { Box, Image } from 'grommet';
import { FillType } from 'grommet/utils';


function LoadingIcon({size='20px', color='white', fill='horizontal'}: LoadingIconProps) {
    const file = `${process.env.PUBLIC_URL}/img/icons/preload-${color}.gif`;

    return (
        <Box height={size} width={size} fill={fill} align="center">
            <Image src={file} fit="contain" />
        </Box>
    );
}

type LoadingIconProps = {
    size?: string | {max?: string, min?: string},
    color?: 'white' | 'black'
    fill?: FillType,
};


LoadingIcon.propTypes = {
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    color: PropTypes.string
};

export default LoadingIcon;
