import merge from 'deepmerge';
import { CustomTheme } from '.';
import jdsportsukTheme from "./index";


const theme: CustomTheme = {
    config: {
        displayName: "JD Northern Ireland Football Shop",
        showAddress1And2OnSameLine: true,
        faviconLogo: 'jdsportsuk/favicon.ico',
        headerLogo: '/Logo-Jdnfa-Navigation.png',
        headerLogoSize: {height: '32px'},
    },
};

const jdnfaTheme: CustomTheme = merge(jdsportsukTheme, theme);

export default jdnfaTheme;