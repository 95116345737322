import { Billing } from '../../modules/billing/types'
import { Customer } from '../../modules/customer/types';
import { Cart, Product } from '../../modules/cart/types';
import { Order } from '../../modules/order/types';
import { AdyenLineItem } from '../../services/payments/adyenHelper/types';
import { Config } from "../../modules/config/types";

export function createDataObject (cart: Cart, config: Config, billing: Billing, customer: Customer, order: Order, displayName: string) {
    const promotionProducts:AdyenLineItem[] = [];
    const allProducts = cart.products.map(product => {
        //check for promotion group products
        if(product.products){
            for (let promotionProduct of product.products){
                promotionProducts.push(getProductDataforAdyen(promotionProduct));
            }
            return {};
        }
        return getProductDataforAdyen(product);
    });

    let productsForAdyen = [...allProducts, ...promotionProducts];

    //to remove empty objects
    productsForAdyen = productsForAdyen.filter((productForAdyen) => {
        return !!productForAdyen.description;
    });

    window.dataObject= {
        custAddress1: order.billingAddress.address1,
        custAddress2: order.billingAddress.address2,
        custAddress3: order.billingAddress.town,
        custAddress4: order.billingAddress.county,
        custCountry: order.billingAddress.locale,
        custEmail: order.customer.email,
        custId: order.customer.email,
        custName: order.billingAddress.firstName + " " + order.billingAddress.lastName,
        custPhone: order.customer.phone,
        custPostcode: order.billingAddress.postcode,
        custType: "New",
        customerId: order.customer.ID,
        firstName: order.billingAddress.firstName,
        lastName: order.billingAddress.lastName,
        delAddress1: order.deliveryAddress.address1,
        delAddress2: order.deliveryAddress.address2,
        delAddress3: order.deliveryAddress.town,
        delAddress4: order.deliveryAddress.county,
        delCountry: order.deliveryAddress.country ?? (cart.deliveryAddress ? cart.deliveryAddress.country : ''),
        delName: order.deliveryAddress.firstName + " " + order.deliveryAddress.lastName,
        delPostcode: order.deliveryAddress.postcode,
        fascia: config.store,
        isFirstOrderView: true,
        oiOrderID: order.ID,
        orderCard: "",
        orderCurrency: order.productsSubtotal.currency,
        orderDate: order.dateAdded,
        orderDateOnly: order.dateAdded?.split("T")[0],
        orderDiscount: order.totalSavings.amount,
        orderEstDeliveryDate: order.estimatedDeliveryDate?.deliveryDate,
        orderEstShippingDate: "",
        orderID: order.ID,
        orderId: order.ID,
        orderShipping: order.deliveryTotal.amount,
        orderShippingProvider: order.courier?.name || null,
        orderShippingType: order.deliveryMethod.name,
        orderTotal: order.productsSubtotal.amount,
        pageName: displayName + " Order Confirmation",
        pageType: "confirmation",
        paymentMethod: order.paymentMethod || '',
        platform: cart.channel,
        voucherCode: order.appliedDiscountCodes,
        items: productsForAdyen
    };
};

export function createWHDObject (storeName: string) {
    window.WHD = {
        meshStoreId : storeName
    };
};

function getProductDataforAdyen(product:Product):  AdyenLineItem {
    return {
        plu: product.parentSKU,
        sku: product.SKU.replace(product.parentSKU + '.',''),
        description: product.name,
        colour: product.colour,
        size: product.optionValues?.Size,
        unitPrice: product.unitPrice.amount,
        quantity: product.quantity.toString(),
        totalPrice: product.subtotal.amount,
        category: product.categories?.[product.categories?.length-1]?.path || '', //End level category
        categoryId: product.categories?.[product.categories?.length-1]?.['clientID'] || '', //End level category Id
        sale: product.isDiscounted ?? false,
        isDiscounted: product.isDiscounted ?? false,
        brand: product.brandName
    }
}