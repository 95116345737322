export type EnsightenEvent = 'Checkout.Error'
|'Checkout.Delivery.SelectCountry'|'Checkout.Delivery.SelectMethod'|'Checkout.Delivery.FindAddress'
|'Checkout.Delivery.StoreSearch'|'Checkout.Delivery.StoreSelect'
|'Checkout.Payment.SelectMethod'
|'Product.Gallery.Select'|'Product.Gallery.PlayVideo'
|'Product.AddToBasket'|'Product.AddToWishlist'
|'ProductListing.Search.Submit'|'ProductListing.Search.Recent'|'ProductListing.Search.SuggestedProduct'
|'ProductListing.ShowMoreProducts'
|'User.Login'|'User.Register'|'User.Logout'|'User.ForgotPassword'|'User.Addresses.Add'|'User.Addresses.Update'
|'View.Home'|'View.Product'|'View.Basket'|'View.Confirm'
|'View.Checkout.Delivery'|'View.Checkout.Payment'
|'View.Login';

type NoData = undefined;

type SearchData = {
    Keywords: string,
};

export type RegisterData = {
    FirstName: string,
    LastName: string,
    Email: string,
    PhoneNumber: string,
    Type: CheckoutType,
    IsGuest: boolean,
    Marketing?: EnsightenMarketingPreferences,
    Location: EnsightenLocationEnum,
    IPAddress?: string,
};

export enum EnsightenLocationEnum {
    Account = 'account',
    Checkout = 'checkout',
}

export interface LoginData {
    User: EnsightenUser,
    Location: EnsightenLocationEnum,
}

export type EnsightenMarketingPreferences = {
    EmailOptIn?: boolean;
    PostOptIn?: boolean;
    SmsOptIn?: boolean;
    ThirdPartyOptIn?: boolean;
}

export type EnsightenFindAddressType = 'PCA Predict'; // Add union types if we need to cover other cases in the future besides PCA.

interface Product {
    Id: string,
    LocaleId: string,
    Name: string,
    Description: string,
    UnitPrice: number,
    RRPUnitPrice: number,
    PreviousUnitPrice: number,
    Brand: string,
    Categories: string[][],
    IsDiscounted: boolean,
    Colour: string,
    Media: {
        Reference: string,
    },
}

export interface OrderProduct extends Product {
    Quantity: number,
    Variant: {
        Id: string,
        Upc: string,
        Name: string,
        IsInStock?: boolean,
        IsDropship?: boolean,
    },
    TotalPrice: number,
}

export interface EnsightenUser {
    Forename: string,
    Surname: string,
    MobileNumber: string,
    Email: string,
    IsGuest: boolean,
    IsNewlyRegistered?: boolean,
    Marketing?: EnsightenMarketingPreferences,
    IPAddress?: string,
}

export interface EnsightenBasket {
    Total: number,
    SubTotal?: number,
    Tax?: number,
    Vouchers?: string[],
    Products: OrderProduct[],
}

export interface EnsightenOrder extends EnsightenBasket {
    Id: string,
    Delivery: {
        Name: string,
        Cost: number,
    },
    Payment: {
        Name: string;
        HumanName: string;
    },
    Type: CheckoutType,
}

export type EnsightenDeliveryMethod = {
    Type: 'store' | 'home' | 'locker' | 'other',
    MethodHumanName: string,
    Basket: EnsightenBasket,
}

type CheckoutType = 'express' | 'standard';

export type EnsightenPickupLocationSearchType = 'text' | 'postcode' | 'location' | 'other';
interface PickupLocationSearchData {
    Type: EnsightenPickupLocationSearchType,
}

interface Store {
    /** ID of the store */
    Id: number | string;
    /**
     * Human readable
     * @example "JD Sports"
     */
    FasciaName: string;
    /** @example "JD" */
    FasciaCode: string;
    Postcode: string;
}

interface PostOffice {
    /**
     * CSV of address lines, excluding empty/null/postcode
     * @example "77 Oldham Road"
     */
    Address: string;
    PostCode: string;
    Lat: string; // string to avoid float variance etc
    Long: string;
    Distance: number;
}

interface Pickup {
    Id?: number | string;
    Name?: string;
    Postcode?: string;

}

type PickupType = "store" | "locker" | "post-office" | "other";
export interface EnsightenPickupLocationSelectData<T extends PickupType> {
    Type: T;
    /** only for "other" */
    TypeName: T extends "other" ? string : undefined | null;
    Location?: T extends "store" ? Store
        : T extends "post-office" ? PostOffice
        : Pickup;
}

export type EnsightenCheckoutViewData = {
    User?: EnsightenUser,
    Basket: EnsightenBasket,
}

export type EnsightenAddressChangeData = {
    User: EnsightenUser,
    Type: CheckoutType,
}

// Type for the data to be passed on, depending on the user event
export type EnsightenData<T> = T extends 'Checkout.Error' ? {Title: string, Body: string}
: T extends 'Checkout.Delivery.SelectCountry' ? {CountryName: string, CountryCode: string}
: T extends 'Checkout.Delivery.FindAddress' ? {Type: EnsightenFindAddressType}
: T extends 'Checkout.Delivery.SelectMethod' ? EnsightenDeliveryMethod
: T extends 'Checkout.Delivery.StoreSearch' ? PickupLocationSearchData
: T extends 'Checkout.Delivery.StoreSelect' ? EnsightenPickupLocationSelectData<PickupType>
: T extends 'Checkout.Payment.SelectMethod' ? {Name: string, HumanName: string}
: T extends 'Product.Gallery.Select' ? {Product: Product}
: T extends 'Product.Gallery.PlayVideo' ? {Product: Product}
: T extends 'Product.AddToBasket' ? any
: T extends 'Product.AddToWishlist' ? any
: T extends 'ProductListing.Search.Submit' ? SearchData
: T extends 'ProductListing.Search.Recent' ? SearchData
: T extends 'ProductListing.Search.SuggestedProduct' ? Product
: T extends 'ProductListing.ShowMoreProducts' ? NoData
: T extends 'User.Login' ? LoginData
: T extends 'User.Register' ? RegisterData
: T extends 'User.Logout' ? NoData
: T extends 'User.ForgotPassword' ? {Location: EnsightenLocationEnum}
: T extends 'User.Addresses.Add' ? EnsightenAddressChangeData
: T extends 'User.Addresses.Update' ? EnsightenAddressChangeData
: T extends 'View.Home' ? NoData
: T extends 'View.Product' ? Product
: T extends 'View.Close.Product' ? Product
: T extends 'View.Basket' ? any
: T extends 'View.Login' ? any
: T extends 'View.Confirm' ? {Order: EnsightenOrder, User: EnsightenUser, IsRevisit: boolean}
: T extends 'View.Checkout.Delivery' ? EnsightenCheckoutViewData
: T extends 'View.Checkout.Payment' ? EnsightenCheckoutViewData
: any;
