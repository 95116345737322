import deepmerge from 'deepmerge';
import meshSDK from '../mesh-sdk';
import {
    MeshConfig, MeshEnvironment, CustomersClient, CartsClient, AddressesClient,
    DeliveryCountriesClient, BillingCountriesClient, PaymentClient, HostedPaymentClient, MeshChannel,
    OrdersClient, PagesClient,
} from '../mesh-sdk/types';
import { PaymentResponse } from '../mesh-sdk/payments';
import {
    AddressResponse, CustomerResponse,
    MeshAddress, CustomerUpdatePayload,
    CustomerStoreResourceResponse, CustomerPreferenceType, DeleteCardResponse
} from '../mesh-sdk/customers';
import { CustomerService } from '../customers';
import { CartService, CartUpdates, ZeroPaymentResponse } from '../carts';
import { CartsResponse, Coordinates, ProductResponse } from '../mesh-sdk/carts';
import { BillingCountriesService } from '../billingCountries';
import { PagesService, Pages } from '../pages';
import { DeliveryCountriesService } from '../deliveryCountries';
import { OrdersService } from '../orders';
import { AddressesService, AddressPredictItem, AddressRetrieved } from '../addresses';
import { PaymentMeshService } from '../payments';
import { Cart, Product } from '../../modules/cart/types';
import { Order } from '../../modules/order/types';
import { AdditionalData, Address, Customer, CustomerPreferences } from '../../modules/customer/types';
import { PaymentMethods, BillingCountry, SavedCard } from '../../modules/billing/types';
import {
    DeliveryCountry, DeliveryMethod, DeliveryMethods,
    StoreCoordinates, Store, DeliveryMethodSlot,
    PostOfficeOpeningHours, CandCLocation,
    DeliveryMethodType, DeliveryMethodTypeCategory, DeliveryMethodAvailability,
} from '../../modules/delivery/types';
import { createResponse } from '../response';
import { HostedPaymentService } from '../payments';
import { HostedPaymentResponse } from '../mesh-sdk/hostedPayment';
import { OrderAddressMeshResponse, OrderProductMeshResponse } from '../mesh-sdk/orders';
import { RootState } from '../../store/reducers';


// Helper Functions used across multiple adapters
function convertMeshOrderProductToAppProduct(meshProduct: OrderProductMeshResponse): Product {
    // Converts {"optionType1": "Size","optionValue1": "S","optionType2": null, "optionValue2": null,}
    // into {Size: 'S'}
    const optionValues = {
        ...(!!meshProduct.optionType1 && {[meshProduct.optionType1]: meshProduct.optionValue1 ?? ''}),
        ...(!!meshProduct.optionType2 && {[meshProduct.optionType2]: meshProduct.optionValue2 ?? ''}),
    };

    return {
        SKU: meshProduct.SKU,
        parentSKU: meshProduct.parentSKU,
        trackingSKU: meshProduct.trackingSKU,
        name: meshProduct.name,
        type: meshProduct.type,
        brandName: meshProduct.brandName,
        isDiscounted: meshProduct.isDiscounted,
        image: {
            ID: meshProduct.resizedMainImage?.ID ?? '',
            originalURL: meshProduct.resizedMainImage?.originalURL ?? '',
            resizingService: {
                href: meshProduct.resizedMainImage?.resizingService?.href ?? '',
                originalURL: meshProduct.resizedMainImage?.resizingService?.originalURL ?? '',
                properties: meshProduct.resizedMainImage?.resizingService?.properties ?? {width: '', height: ''},
            },  
        },
        optionValues,
        unitPrice: meshProduct.unitPrice,
        saving: meshProduct.saving,
        subtotal: meshProduct.subtotal ?? '',
        previousUnitPrice: meshProduct.previousUnitPrice,
        previousPrice: meshProduct.previousPrice,
        unitPriceBeforeDiscounts: meshProduct.unitPrice,
        quantity: meshProduct.quantity,
        customisationSets: null,
        categoryGroupingName: meshProduct.categoryGroupingName,
    };
}

    /**
     *
     * @param address Addres from the Order endpoint response. This object is different than the address from eg. Customer endpoint (no ID, no country)
     * @returns An address formatted for this app.
     */
    function convertMeshOrderAddressToAppAddress(address: OrderAddressMeshResponse): Address {
        return {
            ID: '',
            firstName: address.firstName,
            lastName: address.lastName,
            email: '',
            phone: '',
            country: '',
            address1: address.address1,
            address2: address.address2 ?? undefined,
            address3: address.address3 ?? undefined,
            town: address.town,
            county: address.county,
            postcode: address.postcode,
            isDefaultDeliveryAddress: false,
            isDefaultBillingAddress: false,
            locale: address.locale,
        }
    }


// Adapters
export function customersAdaptor(mesh: CustomersClient): CustomerService {

    function getPreferenceKeyFromPreferenceType(preferenceType: CustomerPreferenceType): string {
        switch(preferenceType) {
            case 'DELIVERY_METHOD':
                return 'delivery_method';
            case 'PAYMENT_METHOD':
                return 'payment_method';
            case 'STORE_LOCATION':
                return 'STORE_CLIENT_ID';
            case 'COLLECTION_DETAIL':
                return 'collection_details';
            default:
                return '';
        }
    }

    /**
     * Converts fields of the app customer object into fields of the mesh customer object.
     * @param appCustomer Partial Customer data
     * @returns An object with fields that can be spread in the payload of the mesh calls expecting Mesh Customer data.
     */
    function convertExtraAppCustomerDataToExtraMeshCustomerData(appCustomer: Partial<Customer>) {
        const extraMeshData: CustomerUpdatePayload = {};
        if (appCustomer.marketingOptIns) {
            extraMeshData.enrolledForEmailMarketing = appCustomer.marketingOptIns.emailOptIn;
            extraMeshData.enrolledForSMSMarketing = appCustomer.marketingOptIns.smsOptIn;
            extraMeshData.enrolledForPostageMarketing = appCustomer.marketingOptIns.postOptIn;
            extraMeshData.enrolledForThirdPartyMarketing = appCustomer.marketingOptIns.thirdPartyOptIn;
        }
        return extraMeshData;
    }

    async function create(username: string, isGuest: boolean, extraCustomerData: Partial<Customer>) {
        const placeholderName = "";
        const createCustomerResponse = await mesh.create(
            username,
            '',
            placeholderName,
            placeholderName,
            convertExtraAppCustomerDataToExtraMeshCustomerData(extraCustomerData),
            isGuest
        );
        const { status: customerStatus, data: customerData, headers: customerHeaders } = createCustomerResponse;

        return createResponse<Customer>(customerStatus, convertMeshCustomerToAppCustomer(customerData, false), customerHeaders);
    }

    async function createAndAuth(username: string, password: string, firstName: string, lastName: string, isGuest: boolean, extraCustomerData: Partial<Customer>) {
        const createCustomerResponse = await mesh.create(
            username,
            password,
            firstName,
            lastName,
            convertExtraAppCustomerDataToExtraMeshCustomerData(extraCustomerData),
            isGuest
        );
        const { data: customerData } = createCustomerResponse;

        const authResponse = await mesh.login(username, password);
        const { status: authStatus, data: authData, headers: authHeaders } = authResponse;
        const additionalData: AdditionalData = {
            authToken: authData,
        };
        return createResponse<Customer>(authStatus, convertMeshCustomerToAppCustomer(customerData, false, additionalData), authHeaders);
    }

    async function promoteCustomer(customerID: string, password: string) {
        const promoteResponse = await mesh.promoteCustomer(
            customerID,
            password
        );
        const { status, data, headers } = promoteResponse;

        //set isReturning to true on convertMeshCustomerToAppCustomer() because the user just created an account.
        return createResponse<Customer>(status, convertMeshCustomerToAppCustomer(data, true), headers);
    }

    async function login(username: string, password: string) {
        const loginResponse = await mesh.login(username, password);

        const { status: loginStatus, data: loginData, headers: loginHeaders } = loginResponse;
        const additionalData : AdditionalData = {
            authToken: loginData
        }
        // customerID is like: "https://uat.jdgroupmeshtest.cloud/stores/jdsportsuk/customers/9E81350A0EB94A77B7BB7A92AAB4C00F"
        const customerID = loginData?.customerID?.match(/customers\/(\w+)/)?.[1];
        if (customerID) {
            const customerResponse = await mesh.getCustomer(customerID);

            const { status: customerStatus, data: customerData, headers: customerHeaders } = customerResponse;
            return createResponse<Customer>(customerStatus, convertMeshCustomerToAppCustomer(customerData, true, additionalData), customerHeaders);
        }
        else {
            // Error: an empty customer object is returned.
            return createResponse<Customer>(loginStatus, convertMeshCustomerToAppCustomer(null, false), loginHeaders);
        }
    }

    /**
     * Function to be used when the user was already logged in in the website framework
     * and already has an auth token.
     * @param customerId Mesh customer ID
     * @param authToken OAuth token
     */
    async function getCustomer(customerId: string) {
        const customerResponse = await mesh.getCustomer(customerId);

        // login with mesh returns an access token to be used on
        // further requests
        const { status, data, headers } = customerResponse;
        return createResponse<Customer>(status, convertMeshCustomerToAppCustomer(data, true), headers);
    }

    async function patchCustomer(customerId: string, updates: {[key: string]: any}) {
        const customerResponse = await mesh.getCustomer(customerId);
        const customerToUpdate = customerResponse.data;
        const updatedCustomer = deepmerge(customerToUpdate, updates);
        const updatedCustomerResponse = await mesh.updateCustomer(customerId, updatedCustomer);
        
        const { status, data, headers } = updatedCustomerResponse;
        return createResponse<Customer>(status, convertMeshCustomerToAppCustomer(data, true), headers);
    }

    /** Retrieves the preferred delivery method, payment method and store of a customer.
     * @param customerId Mesh customer ID
     */
    async function getCustomerPreferences(customerId: string) {
        const customerPreferencesResponse = await mesh.getCustomerPreferences(customerId);
        const { status, data, headers } = customerPreferencesResponse;
        
        const preferences: CustomerPreferences = {};
        data.forEach(preference => {
            switch(preference.type) {
                case 'DELIVERY_METHOD':
                    preferences.deliveryMethodPreference = {
                        ID: preference.ID,
                        value: preference.resource as string,
                    };
                    break;
                case 'PAYMENT_METHOD':
                    preferences.paymentMethodPreference = {
                        ID: preference.ID,
                        value: preference.resource as string,
                    };
                    break;
                case 'STORE_LOCATION':
                    preferences.storePreference = {
                        ID: preference.ID,
                        value: (preference.resource as CustomerStoreResourceResponse).storeID,
                    };
                    preferences.storeAddressPreference = {
                        value: (preference.resource as CustomerStoreResourceResponse).postcode,
                    };
                    break;
                case 'COLLECTION_DETAIL':
                    preferences.collectionPreference = {
                        ID: preference.ID,
                        value: preference.resource as string,
                    };
                    break;
                default:
                    break;
            }
        })
        return createResponse<CustomerPreferences>(status, preferences, headers);
    }

    async function getCustomerOrder (customerId: string, orderId: string) {
        const response = await mesh.getCustomerOrder(customerId, orderId);
        const { status, data, headers } = response;

        const extractedFirstName = data.customer.fullName.match(/^(\w+)/)?.[1];
        const extractedLastName = data.customer.fullName.match(/(\w+)$/)?.[1];
        const firstName = extractedFirstName || data.customer.fullName;
        const lastName = extractedLastName || '';

        const order: Order = {
            ID: data.ID,
            invoiceID: data.invoiceID,
            status: data.status,
            isDiscounted: data.isDiscounted,
            estimatedDeliveryDate: data.estimatedDeliveryDate,
            channel: data.channel,
            paymentMethod: data.paymentMethod,
            paymentDetails: {
                gateway: data.paymentDetails.gateway,
                method: data.paymentDetails.method,
                isCaptured: data.paymentDetails.isCaptured,
                amountCaptured: data.paymentDetails.amountCaptured,
            },
            products: data.lines.map(meshProduct => {
                return convertMeshOrderProductToAppProduct(meshProduct);
            }),
            productsSubtotal: data.productsSubtotal,
            deliveryMethod: {name: data.deliveryMethod.name},
            deliveryTotal: data.deliveryTotal,
            giftcardsSubtotal: data.giftcardsSubtotal,
            appliedDiscountCodes: data.totalSavings,
            total: data.total,
            dateAdded:data.dateAdded,
            grandTotal: data.grandTotal,
            balanceToPay: data.balanceToPay,
            totalSavings: data.totalSavings,
            customer: {
                ID: data.customer.ID,
                email: data.customer.email,
                returning: false,
                addresses: [],
                firstName,
                lastName,
                phone: data.customer.phoneNumber,
                isActive: false,
                isGuest: data.customer.isGuestCustomer,
                isRegistered: false,
                marketingPreferencesURL: data.customer.marketingPreferencesURL ?? '',
                marketingCancellationURL: data.customer.marketingCancellationURL ?? '',
            },
            billingAddress: convertMeshOrderAddressToAppAddress(data.billingAddress),
            deliveryAddress: convertMeshOrderAddressToAppAddress(data.deliveryAddress),
            isDeliverToStore: data.isDeliverToStore,
            selectedDeliveryDate: data.selectedDeliveryDate,
            selectedDeliveryTimeSlotStart: data.selectedDeliveryTimeSlotStart,
            selectedDeliveryTimeSlotEnd: data.selectedDeliveryTimeSlotEnd,
            hasFailed: data.hasFailed,
            orderShippingType: data.deliveryMethod.name,
        }
        return createResponse<Order>(status, order, headers);
    }

    /** Create a new preference object for a customer
     * @param customerId Mesh customer ID
     * @param preferenceType Either DELIVERY_METHOD, PAYMENT_METHOD or STORE_LOCATION
     * @param preferenceValue
     */
    async function createCustomerPreference(customerId: string, preferenceType: CustomerPreferenceType, preferenceValue: string) {
        const preferencePayload = {
            type: preferenceType,
            prefKey: getPreferenceKeyFromPreferenceType(preferenceType),
            prefValue: preferenceValue,
        }
        const customerPreferenceResponse = await mesh.createCustomerPreference(customerId, preferencePayload);
        const { status, headers } = customerPreferenceResponse;
        return createResponse<boolean>(status, true, headers);
    }

    /** Create a new preference object for a customer
     * @param customerId Mesh customer ID
     * @param preferenceType Either DELIVERY_METHOD, PAYMENT_METHOD or STORE_LOCATION
     * @param preferenceValue
     */
    async function updateCustomerPreference(customerId: string, preferenceId: string, preferenceType: CustomerPreferenceType, preferenceValue: string) {
        const preferencePayload = {
            type: preferenceType,
            prefKey: getPreferenceKeyFromPreferenceType(preferenceType),
            prefValue: preferenceValue,
        }
        const customerPreferenceResponse = await mesh.updateCustomerPreference(customerId, preferenceId, preferencePayload);
        const { status, headers } = customerPreferenceResponse;
        return createResponse<boolean>(status, true, headers);
    }

    function updateCustomer(customerID: string, customer: Customer) {
        const { addresses, ...rest }  = customer;
        let updatedCustomer: CustomerUpdatePayload = rest;

        return mesh.updateCustomer(customerID, updatedCustomer)
            .then(response => {
                const { status, data, headers } = response;
                return createResponse<Customer>(status, convertMeshCustomerToAppCustomer(data, true), headers);
            });
    }

    function exists(username : string) {
        return mesh.isRegistered({
            email: username.trim()
        }).then((response) => {
            const { status, data, headers} = response;
            const isRegistered = Array.isArray(data)
                ? data[0].registered
                : data.registered;
            return createResponse<boolean>(status, isRegistered, headers)
        })
    }


    function saveAddress(customerID: string, address: Address, isCAndC = false) {
        const saveAddressPayload = {
            firstName: address.firstName,
            lastName: address.lastName,
            ...(address.phone && {phone: address.phone}), // add the 'phone' key only if not empty
            address1: address.address1,
            address2: address.address2 ?? '',
            town: address.town,
            county: address.county ?? '',
            postcode: address.postcode,
            title: '',
            country: address.country,
            locale: address.locale,
            isPrimaryBillingAddress: address.isDefaultBillingAddress,
            isPrimaryAddress: address.isDefaultDeliveryAddress,
            isCAndC: isCAndC,
        } as MeshAddress;

        return mesh.saveAddress(customerID, saveAddressPayload)
            .then(response => {
                const { status, data, headers } = response;
                return createResponse<Address>(status, convertMeshAddressToAppAddress(data), headers);
            });
    }

    function updateAddress(customerID: string, address: Address) {
        // Convert address to format expected by mesh for this endpoint
        const updateAddress: MeshAddress = {
            title: '',
            firstName: address.firstName,
            lastName: address.lastName,
            phone: address.phone ?? '',
            address1: address.address1,
            address2: address.address2 ?? '',
            town: address.town,
            locale: address.locale,
            postcode: address.postcode,
            county: address.county ?? '',
            country: address.country ?? '',
            isPrimaryAddress: address.isDefaultDeliveryAddress,
            isPrimaryBillingAddress: address.isDefaultBillingAddress,
            isCAndC: false,
        };


        return mesh.updateAddress(customerID, address.ID, updateAddress)
            .then(response => {
                const { status, data, headers } = response;
                return createResponse<Address>(status, convertMeshAddressToAppAddress(data), headers);
            });
    }

    /**
     * Converts a Customer object received in a Mesh response to a Customer object used by this app.
     * @param customerResponse Mesh customer
     * @param returning True for a logged-in customer, false for a newly created customer.
     */
    function convertMeshCustomerToAppCustomer(meshCustomer: CustomerResponse| null, isReturning: boolean, addtionalData?: AdditionalData): Customer {
        const customer: Customer = {
            ID: meshCustomer?.ID ?? '',
            email: meshCustomer?.email ?? '',
            returning: isReturning,
            firstName: meshCustomer?.firstName ?? '',
            lastName: meshCustomer?.lastName ?? '',
            phone: meshCustomer?.phone ?? '',
            isActive: meshCustomer?.isActive ?? false,
            isGuest: meshCustomer?.isGuest ?? false,
            isRegistered: meshCustomer?.isRegistered ?? false,
            addresses: meshCustomer?.addresses?.map(meshAddress => convertMeshAddressToAppAddress(meshAddress)) ?? [] as Address[],
            additionalData: addtionalData,
            marketingPreferencesURL: meshCustomer?.marketingPreferencesURL ?? '',
            marketingCancellationURL: meshCustomer?.marketingCancellationURL ?? '',
            marketingOptIns: {
                emailOptIn: meshCustomer?.enrolledForEmailMarketing ?? false,
                smsOptIn: meshCustomer?.enrolledForSMSMarketing ?? false,
                postOptIn: meshCustomer?.enrolledForPostageMarketing ?? false,
            },
        };
        return customer;
    }

    async function deleteSavedCard(customerID: string, cardId: string) {
        const deleteResponse = await mesh.deleteSavedCard(customerID, cardId)
        const { status, data, headers } = deleteResponse;
        return createResponse<SavedCard[]>(status, convertMeshCardToAppCard(data), headers);
    }

    /**
     * Converts an delete saved card response object received in a Mesh response to an saved acrd object used by this app.
     * @param DeleteCardResponse Mesh delete card response
     * @param returning remaining saved cards availble post delete
     */
    function convertMeshCardToAppCard(meshCards: DeleteCardResponse): SavedCard[] {
        const appSavedCards: SavedCard[] = meshCards?.cards.map(card => {
            return {
                id: card.ID,
                name: card.type,
                brand: card.type,
                lastFour: card.cardNumber.substr(-4),
                holderName: card.clientName,
                expiryMonth: parseInt(card.expiryDate.toString().substr(0,2)),
                expiryYear: parseInt(card.expiryDate.toString().substr(-4)),
                isPreferredSavedCard: false
            } as SavedCard;
        });
        return appSavedCards;
    }


    /**
     * Converts an Address object received in a Mesh response to an Address object used by this app.
     * @param customerResponse Mesh customer
     * @param returning True for a logged-in customer, false for a newly created customer.
     */
    function convertMeshAddressToAppAddress(meshAddress: AddressResponse ): Address {
        const appAddress: Address = {
            ID: meshAddress.ID,
            firstName: meshAddress.firstName,
            lastName: meshAddress.lastName,
            address1: meshAddress.address1,
            address2: meshAddress.address2,
            address3: meshAddress.address3,
            town: meshAddress.town,
            county: meshAddress.county ?? '',
            postcode: meshAddress.postcode ?? '',
            country: meshAddress.country ?? '',
            locale: meshAddress.locale,
            email: meshAddress.email ?? '',
            phone: meshAddress.phone,
            isDefaultDeliveryAddress: meshAddress.isPrimaryAddress,
            isDefaultBillingAddress: meshAddress.isPrimaryBillingAddress,
        };
        return appAddress;
    }

    return {
        login,
        getCustomer,
        getCustomerPreferences,
        getCustomerOrder,
        patchCustomer,
        createCustomerPreference,
        updateCustomerPreference,
        create,
        createAndAuth,
        promoteCustomer,
        exists,
        saveAddress,
        updateAddress,
        updateCustomer,
        deleteSavedCard
    }
}



export function cartsAdaptor(mesh: CartsClient): CartService {
    /**
     * Converts a cart products received in a Mesh response to a Cart products object used by this app.
     * @param ProductItem Mesh cart
     */
    function makeAppProductFromMeshProduct(productItem: ProductResponse): Product{
        return   {
            SKU: productItem.SKU,
            parentSKU: productItem.parentSKU,
            trackingSKU: productItem.trackingSKU,
            name: productItem.name,
            type: productItem.type,
            brandName: productItem.brandName,
            isDiscounted: productItem.isDiscounted,
            categories: productItem.categories,
            image: productItem.image,
            colourDescription: productItem.colourDescription,
            colour: productItem.colour,
            optionValues: productItem.optionValues,
            unitPrice: productItem.unitPrice,
            subtotal: productItem.subtotal,
            previousUnitPrice: productItem.previousUnitPrice,
            unitPriceBeforeDiscounts: productItem.unitPriceBeforeDiscounts,
            previousPrice: productItem.previousPrice,
            discountAmount: productItem.discount_amount,
            saving: productItem.saving,
            quantity: productItem.quantity,
            customisationSets: productItem.customisationSets,
            stockPool: {
                ID: productItem.stockPool?.ID,
                isDropship: productItem.stockPool?.dropShip,
            },
            status: productItem.status,
            fulfilment: {
                fulfilmentMessage: productItem.fulfilment?.fulfilmentMessage,
                    fulfilmentMessage2: productItem.fulfilment?.fulfilmentMessage2,
                    imageURL: productItem.fulfilment?.imageURL,
                    isDefault: productItem.fulfilment?.isDefault,
                    deliveryMessage: {
                    message: productItem.fulfilment?.deliveryMessage.message,
                        message2: productItem.fulfilment?.deliveryMessage.message2,
                        imageURL: productItem.fulfilment?.deliveryMessage.imageURL,
                        leadTime: productItem.fulfilment?.deliveryMessage.leadTime,
                        isDefault: productItem.fulfilment?.deliveryMessage.isDefault,
                },
                informationPage:{
                    ID: productItem.fulfilment?.informationPage?.ID,
                }
            },
            cartProductNotification: {
                deliveryType: productItem.cartProductNotification?.deliveryType,
                scheduledDelivery: productItem.cartProductNotification?.scheduledDelivery,
                personalMessage: productItem.cartProductNotification?.personalMessage,
                sendFrom: productItem.cartProductNotification?.sendFrom,
                sendTo: productItem.cartProductNotification?.sendTo
            },
            products: productItem.products?.map((productItem): Product => makeAppProductFromMeshProduct(productItem))
        };
    }

    /**
     * Converts a cart received in a Mesh response to a Cart object used by this app.
     * @param cartResponse Mesh cart
     */
    function convertMeshCartToAppCart(meshCart: CartsResponse): Cart {
        return {
            ID: meshCart.ID,
            reference: meshCart.reference,
            paid: meshCart.paid,
            count: meshCart.count,
            channel: meshCart.channel,
            subtotal: meshCart.previousBalanceToPay,
            rawCartTotal: meshCart.rawCartTotal,
            total: meshCart.total,
            tax: meshCart.tax ?? null,
            giftcards: meshCart.giftcards,
            giftcardsApplied: meshCart.giftcardsApplied,
            giftcardsSubtotal: meshCart.giftcardsSubtotal,
            subtotalBeforeDiscounts: meshCart.subtotalBeforeDiscounts,
            balanceToPay: meshCart.balanceToPay,
            containsUnavailableProducts: meshCart.containsUnavailableProducts,
            eligibleForGiftCards: meshCart.eligibleForGiftCards,
            allowToPayWithGiftCard : meshCart.allowToPayWithGiftCard,
            estimatedDeliveryDate: meshCart.estimatedDeliveryDate,
            estimatedDispatchDate: meshCart.estimatedDispatchDate,
            customer: meshCart.customer,
            billingAddress: meshCart.billingAddress,
            deliveryAddress: meshCart.deliveryAddress,
            deliveryOptions: meshCart.deliveryOptions,
            totalDiscount: meshCart.totalSavings.total,
            cartDiscounts: meshCart.cartDiscounts,
            deliveryMethod: {
                ID: meshCart.delivery?.deliveryMethodID ?? '',
                displayName: meshCart.delivery?.deliveryMethodDisplayName ?? '',
                description: meshCart.delivery?.deliveryMethodDescription ?? ''
            },
            deliverySubtotal: meshCart.deliverySubtotal,
            productsSubtotal: meshCart.productsSubtotal,
            totalSavings: {
                productSavings: meshCart.totalSavings.productSavings,
                deliverySavings: meshCart.totalSavings.deliverySavings,
                total: meshCart.totalSavings.total
            },
            delivery: meshCart.delivery,
			discountCodes: meshCart.discountCodes,
            products: meshCart.contents?.map((productItem): Product => makeAppProductFromMeshProduct(productItem)),
        } as Cart;
    }

    function getCart(cartId: string) {
        return mesh.getCart(
            cartId
        ).then((response) => {

            const { status, data, headers } = response;

            return createResponse<Cart>(status, convertMeshCartToAppCart(data), headers);
        });
    }

    function updateCart(cartId: string, update: CartUpdates, locale?: string) {
        return mesh.updateCart(cartId, update, locale)
            .then(response => {
                const { status, data, headers } = response;
                return createResponse<Cart>(status, convertMeshCartToAppCart(data), headers);
            })
    }

    async function makeZeroPayment(cartId: string) {
        const paymentResponse = await mesh.makeZeroPayment(cartId);
        const { status, data, headers } = paymentResponse;
        return createResponse<ZeroPaymentResponse>(status, data, headers);
    }

    function getCartPaymentMethods(cartId: string) {
        const supportedMethods: { name: string, displayName: string, type: 'payNow' | 'payLater' | 'express', clickAndCollectSupported?: boolean, provider?: string, method?: string }[] = [
            { type: 'payLater', name: 'KLARNA CREDIT', displayName: 'Klarna', provider: 'klarna', method: 'klarna' },
            { type: 'payLater', name: 'CLEARPAY', displayName: 'Clearpay', provider: 'clearpay', method: 'clearpay' },
            { type: 'payLater', name: 'LAYBUY', displayName: 'Laybuy', provider: 'laybuy', method: 'laybuy' },
            { type: 'payLater', name: 'ZIPPAY', displayName: 'Zip', provider: 'adyen', method: 'zip' },
            { type: 'payLater', name: 'OPENPAY', displayName: 'Openpay',provider: 'openpay', method: 'openpay' },
            { type: 'payLater', name: 'PAYPAL CREDIT', displayName: 'Paypal Credit' },
            { type: 'payLater', name: 'AFTERPAY', displayName: 'Afterpay', provider: 'afterpay', method: 'afterpay' },
            { type: 'payLater', name: 'SHOPBACK', displayName: 'ShopBack', provider: 'shopback', method: 'SHOPBACK' },
            { type: 'payLater', name: 'RELY', displayName: 'Rely', provider: 'rely', method: 'RELY' },
            { type: 'payLater', name: 'PAYINTHREE', displayName: 'PayPal Pay Later', clickAndCollectSupported: false, provider: 'paypal', method: 'payinthree' },
            { type: 'payNow', name: 'CARD', displayName: 'Credit / Debit Card', provider: 'adyen', method: 'scheme' },
            { type: 'payNow', name: 'PAYPAL', displayName: 'PayPal', provider: 'adyen', method: 'paypal' },
            { type: 'payNow', name: 'ALIPAY', displayName: 'AliPay', provider: 'adyen', method: 'alipay' }, //method: 'alipay_wap' dynamically
            { type: 'payNow', name: 'GIROPAY', displayName: 'GiroPay', provider: 'adyen', method: 'giropay' },
            { type: 'payNow', name: 'UNIONPAY', displayName: 'UnionPay' },
            { type: 'payNow', name: 'WECHAT', displayName: 'WeChat', provider: 'adyen', method: 'wechatpayWeb' },
            { type: 'payNow', name: 'VISA_CHECKOUT', displayName: 'Visa Checkout' },
            { type: 'payNow', name: 'MASTERPASS', displayName: 'Masterpass' },
            { type: 'payNow', name: 'PAYPAL_MOBILE', displayName: 'PayPal Mobile' },
            // {type: 'payNow', name: 'OFFLINE', displayName: 'Offline'},
            // {type: 'payNow', name: 'PIN ENTRY DEVICE', displayName: 'Pin Entry Device'},
            // {type: 'payNow', name: 'TOKENIZED_CARD', displayName: 'Tokenized Card'},
            {type: 'payNow', name: 'ANDROID PAY', displayName: 'Android Pay'},
            {type: 'payNow', name: 'SOFORT', displayName: 'Klarna', provider: 'adyen', method: 'directEbanking'},
            {type: 'payNow', name: 'GIROPAY', displayName: 'Giropay'},
            {type: 'payNow', name: 'IDEAL', displayName: 'iDEAL', provider: 'adyen', method: 'ideal'},
            {type: 'payNow', name: 'CREDITGUARD', displayName: 'Credit Guard', provider: 'creditguard', method: 'CREDITGUARD' },
            {type: 'payNow', name: 'KCP_BANKTRANSFER', displayName: 'KCP Bank Transfer'},
            {type: 'payNow', name: 'KCP_CARD', displayName: 'KCP Card'},
            {type: 'payNow', name: 'KCP_PAYCO', displayName: 'KCP PAYCO'},
            {type: 'payNow', name: 'TRUSTLY', displayName: 'Trustly', provider: 'adyen', method: 'trustly'},
            {type: 'payNow', name: 'JDX PAY', displayName: 'JDX Pay'},
            {type: 'payNow', name: 'ADYEN_EBANKING', displayName: 'Adyen eBanking'},
            {type: 'payNow', name: 'ADYEN_PAYSBUY', displayName: 'Adyen Paysbuy'},
            {type: 'payNow', name: 'FPX', displayName: 'FPX', provider: 'molpay', method: 'fpx'},
            {type: 'payNow', name: 'RAZERPAY', displayName: 'Razorpay'},
            {type: 'payNow', name: 'TOUCHN_GO_EWALLET', displayName: 'Touch \'n Go eWallet', provider: 'molpay', method: 'touchn_go_ewallet'},
            {type: 'payNow', name: 'BOOST', displayName: 'Boost', provider: 'molpay', method: 'boost'},
            {type: 'payNow', name: 'GRABPAY', displayName: 'Grabpay', provider: 'molpay', method: 'grabpay'},
            {type: 'payNow', name: 'MAYBANK_QR_PAY', displayName: 'Maybank QR Pay', provider: 'molpay', method: 'maybank_qr_pay'},
            {type: 'payNow', name: 'RAZER_GOLD', displayName: 'Razer Gold'},
            {type: 'payNow', name: 'WEBCASH', displayName: 'WebCash'},
            {type: 'payNow', name: 'AMAZONPAY', displayName: 'Amazon Pay'},
            {type: 'payNow', name: 'PAYPAL_STANDARD', displayName: 'PayPal Standard'},
            {type: 'payNow', name: 'MULTIBANCO', displayName: 'Multibanco'},
            {type: 'payNow', name: 'BRAINTREE', displayName: 'Credit / Debit Card', provider: 'braintree', method: 'braintree'},
            {type: 'payNow', name: 'GOOGLE PAY', displayName: 'Google Pay', provider: 'adyen', method: 'paywithgoogle'},
            {type: 'payNow', name: 'APPLE PAY', displayName: 'Apple Pay', provider: 'adyen', method: 'applepay'},
            {type: 'express', name: 'PAYPAL_EXPRESS', displayName: 'Paypal Express', provider: 'paypalexpress', method: 'paypalexpress'},
            {type: 'express', name: 'KLARNA_EXPRESS', displayName: 'Klarna Express', clickAndCollectSupported: false, provider: 'klarnaexpress', method: 'klarnaexpress'},
            {type: 'express', name: 'CLEARPAY_EXPRESS', displayName: 'Clearpay Express', provider: 'clearpayexpress', method: 'clearpayexpress'},
            {type: 'express', name: 'AFTERPAY_EXPRESS', displayName: 'Afterpay Express', provider: 'afterpayexpress', method: 'afterpayexpress'},
            {type: 'express', name: 'PAYINTHREE_EXPRESS', displayName: 'Paypal Pay In 3', clickAndCollectSupported: false, provider: 'paypalexpress', method: 'payinthree'},
            {type: 'express', name: 'APPLEPAY EXPRESS', displayName: 'Apple Pay', provider: 'adyen', method: 'applepay'},
            {type: 'express', name: 'GOOGLEPAY EXPRESS', displayName: 'Google Pay', provider: 'adyen', method: 'paywithgoogle'}
        ];

        return mesh.getCartPaymentMethods(
            cartId
        ).then((response) => {

            const { status, data, headers } = response;
            let methods = data;

            // Filter out methods returned as inactive according to Mesh
            methods = methods.filter(method => method.active === true);

            // Filter out methods not part of the 'supportedMethods' whitelist and sort them
            const sortedMethods: PaymentMethods = {
                payNow: [],
                payLater: [],
                express: [],
            };
            methods.forEach(method => {
                let whitelistItem = supportedMethods.find(item => item.name === method.name);
                if (whitelistItem?.type) {

                    if(method.additionalData?.hostedPaymentGateway === 'MolPay' &&  method.name === 'CARD') {
                        whitelistItem.provider = 'molpay';
                        whitelistItem.method = 'card';
                    }
                    
                    sortedMethods[whitelistItem.type].push({
                        name: method.name,
                        id: method.name,
                        label: whitelistItem.displayName,
                        active: method.active,
                        provider: whitelistItem.provider ?? "",
                        method: whitelistItem.method ?? "",
                        additionalData: method.additionalData ?? {},
                        clickAndCollectSupported: whitelistItem.clickAndCollectSupported === false ? false : true, // undefined true 
                    });
                }
            });


            return createResponse<PaymentMethods>(status, sortedMethods, headers);
        });
    }

    async function getCartDeliveryMethods(cartId: string, search: string | StoreCoordinates, locale: string, partialPostcode = false) {

        let response;

        function getMethodTypeCategory(methodType: DeliveryMethodType): DeliveryMethodTypeCategory {
            switch(methodType) {
                case 'C+C': // Click & Collect
                case 'COLLECT+':
                case 'POLC': // Post Office Local Collect
                    return 'clickAndCollect';

                case '':
                case 'D': // Deliver
                case 'S': // Same day delivery
                case 'Deliveroo':
                    return 'home';

                case 'V': // Virtual - TODO not currently handled in the app
                default:
                    return 'home';
            }
        }

        // theres a bit of nonsense in mesh which means you need to use a different endpoint for
        // a partial postcode, the response is also slightly different
        if (partialPostcode){
            // post to delivery quotationfirst
            await mesh.updateCart(cartId, {
                deliveryQuotationAddress: {
                    locale,
                    postcode: search as string
                }
            })

            const deliveryMethodsResponse = await mesh.getCartDeliveryMethods(cartId)
            const deliveryMethods = deliveryMethodsResponse.data;
            response = {
                ...deliveryMethodsResponse,
                data: {
                    deliveryMethods
                }
            }
        } else {
            response = await mesh.getCartDeliveryMethodsForAddress(
                cartId,
                search,
                locale,
            )
        }

        const methods = {
            home: [] as DeliveryMethod[],
            clickAndCollect: [] as DeliveryMethod[]
        };

        const { status, data, headers } = response;

        data?.deliveryMethods?.forEach(method => {
			let item: DeliveryMethod = {
				ID: method.ID,
				type: method.type,
				typeCategory: getMethodTypeCategory(method.type),
				displayName: method.name,
				description: method.description,
				price: {
					amount: method.price.amount,
					currency: method.price.currency
				},
				slots: method.slots?.map(meshSlot => {
					const slot: DeliveryMethodSlot = {
						date: meshSlot.date,
						start: meshSlot.start,
						end: meshSlot.end,
						displayTime: `${meshSlot.start.substr(0, 5)} - ${meshSlot.end.substr(0, 5)}`,
						type: meshSlot.type,
						selected: meshSlot.selected,
						greenSlot: meshSlot.greenSlot,
						recommended: meshSlot.recommended,
					};
					return slot;
				}),
				selected: method.selected,
				needsConfirmation: method.needsConfirmation
			};

			if (method.type === 'C+C' || method.type === 'POLC') { // POLC is post office
				methods.clickAndCollect.push(item);
			}
			else { // Type can also be "D" or ""
				methods.home.push(item);
			}
		});

        return createResponse<DeliveryMethods>(status, methods, headers);

    }

    function getStoresByLocation(cartId: string, location: string | Coordinates, units: string, from: number = 0, max: number = 10) {
        return mesh.getStoresByLocation(
            cartId,
            location,
            units,
            from,
            max,
        ).then((response) => {
            const { status, data, headers } = response;
            const totalNumberOfStores = data.totalFound ?? 0;
            const stores = data.stores.map(store => {
                const appStore: Store = {
                    ID: store.storeID,
                    clientId: store.clientID,
                    name: store.name,
                    locationType: CandCLocation.JD,
                    address: {
                        address1: store.address.address1,
                        address2: store.address.address2,
                        address3: store.address.address3,
                        town: store.address.town,
                        county: store.address.county,
                        postcode: store.address.postcode,
                        country: store.address.country,
                        locale: store.address.locale
                    },
                    latitude: store.latitude,
                    longitude: store.longitude,
                    phone: store.phone,
                    openingHours: store.openingHours,
                    distance: store.distance,
                    distanceUnits: store.distanceUnits,
                    fullFasciaName: store.fullFasciaName,
                    href: store.href,
                    fasciaCode: store.fasciaCode,
                };
                return appStore;
            })

            return createResponse<{stores: Store[], totalNumberOfStores: number }>(status, {stores, totalNumberOfStores}, headers);
        });
    }

    function getPostOfficesByLocation(cartId: string, location: string | Coordinates, units: string, from: number = 0, max: number = 10) {
        return mesh.getPostOfficesByLocation(
            cartId,
            location,
            units
        ).then((response) => {
            const { status, data, headers } = response;

            const mapDate = (day: PostOfficeOpeningHours) => {
                //Truncate the seconds from the opening/closing times.
                const openTime = day.openTime.substr(0, 5);
                const closeTime = day.closeTime.substr(0, 5);

                return {
                    open: day.openTime !== 'Closed' && day.closeTime !== 'Closed',
                    opensAt: openTime,
                    closesAt: closeTime,
                    formattedOpeningHours: openTime + ' - ' + closeTime,
                }
            };

            const postOffices = data.postoffices.map(po => {
                return {
                    ID: po.name,
                    name: po.name,
                    locationType: CandCLocation.PostOffice,
                    address: {
                        address1: po.address.address1,
                        address2: po.address.address2,
                        address3: null,
                        town: po.address.town,
                        county: po.address.county,
                        postcode: po.address.postcode,
                        country: "GB",
                        locale: "GB"
                    },
                    phone: null,
                    openingHours: {
                        'Monday': mapDate(po.openingHours.monday),
                        'Tuesday': mapDate(po.openingHours.tuesday),
                        'Wednesday': mapDate(po.openingHours.wednesday),
                        'Thursday': mapDate(po.openingHours.thursday),
                        'Friday': mapDate(po.openingHours.friday),
                        'Saturday': mapDate(po.openingHours.saturday),
                        'Sunday': mapDate(po.openingHours.sunday),
                    },
                    latitude: po.lat,
                    longitude: po.lng,
                    distance: po.distance,
                    distanceUnits: po.distanceUnits,
                }
            });

            return createResponse<Store[]>(status, postOffices, headers)
        });
    }

    async function deleteProductFromCart(cartId: string, sku: string) {
        const response = await mesh.deleteProductFromCart(cartId, sku);
        const { status, headers } = response;
        return createResponse<void>(status, undefined, headers);
    }

	async function getCartDeliveryMethodAvailability(cartId: string, location: string, deliveryMethodId: string) {
		const response = await mesh.getCartDeliveryOptionAvailability(cartId, location, deliveryMethodId);
		const { status, headers, data } = response;

		const available = data.available;
		const reason = data.reason;
        //@ts-ignore
			const stores = data.storeLocations?.map(store => {
				const appStore: Store = {
					ID: store.storeID,
					clientId: store.clientID,
					name: store.name,
					locationType: CandCLocation.JD,
					address: {
						address1: store.address.address1,
						address2: store.address.address2,
						address3: store.address.address3,
						town: store.address.town,
						county: store.address.county,
						postcode: store.address.postcode,
						country: store.address.country,
						locale: store.address.locale
					},
					latitude: store.latitude,
					longitude: store.longitude,
					phone: store.phone,
					openingHours: store.openingHours,
					distance: store.distance,
					distanceUnits: store.distanceUnits,
					fullFasciaName: store.fullFasciaName,
					href: store.href,
					fasciaCode: store.fasciaCode,
				}
				return appStore;
			});

		return createResponse<DeliveryMethodAvailability>(status, {stores, available, reason}, headers);
	}

    return {
        getCart,
        updateCart,
        makeZeroPayment,
        getCartPaymentMethods,
        getCartDeliveryMethods,
        getStoresByLocation,
        getPostOfficesByLocation,
        deleteProductFromCart,
		getCartDeliveryMethodAvailability
    }
}

export function deliveryCountriesAdaptor(mesh: DeliveryCountriesClient): DeliveryCountriesService {
    function getDeliveryCountries() {
        return mesh.getDeliveryCountries().then((response) => {
            const { status, data, headers } = response;
            return createResponse<DeliveryCountry[]>(status, data.countries, headers);
        })
    };

    return {
        getDeliveryCountries
    };
}

export function ordersAdaptor(mesh: OrdersClient): OrdersService {

    async function getOrder(orderID: string) {
        const response = await mesh.getOrder(orderID);
        const { status, data, headers } = response;

        const extractedFirstName = data.customer.fullName.match(/^(\w+)/)?.[1];
        const extractedLastName = data.customer.fullName.match(/(\w+)$/)?.[1];
        const firstName = extractedFirstName || data.customer.fullName;
        const lastName = extractedLastName || '';

        const order: Order = {
            ID: data.ID,
            invoiceID: data.invoiceID,
            status: data.status,
            paymentMethod: data.paymentMethod,
            isDiscounted: data.isDiscounted,
            dateAdded:data.dateAdded,
            estimatedDeliveryDate: data.estimatedDeliveryDate,
            channel: data.channel,
            paymentDetails: {
                gateway: data.paymentDetails.gateway,
                method: data.paymentDetails.method,
                isCaptured: data.paymentDetails.isCaptured,
                amountCaptured: data.paymentDetails.amountCaptured,
            },
            products: data.lines.map(meshProduct => {
                return convertMeshOrderProductToAppProduct(meshProduct);
            }),
            productsSubtotal: data.productsSubtotal,
            deliveryMethod: {name: data.deliveryMethod.name},
            deliveryTotal: data.deliveryTotal,
            giftcardsSubtotal: data.giftcardsSubtotal,
            appliedDiscountCodes: data.totalSavings,
            total: data.total,
            grandTotal: data.grandTotal,
            balanceToPay: data.balanceToPay,
            totalSavings: data.totalSavings,
            customer: {
                ID: data.customer.ID,
                email: data.customer.email,
                returning: false,
                addresses: [],
                firstName,
                lastName,
                phone: data.customer.phoneNumber,
                isActive: false,
                isGuest: data.customer.isGuestCustomer,
                isRegistered: false,
                marketingPreferencesURL: data.customer.marketingPreferencesURL ?? '',
                marketingCancellationURL: data.customer.marketingCancellationURL ?? '',
            },
            billingAddress: convertMeshOrderAddressToAppAddress(data.billingAddress),
            deliveryAddress: convertMeshOrderAddressToAppAddress(data.deliveryAddress),
            isDeliverToStore: data.isDeliverToStore,
            selectedDeliveryDate: data.selectedDeliveryDate,
            selectedDeliveryTimeSlotStart: data.selectedDeliveryTimeSlotStart,
            selectedDeliveryTimeSlotEnd: data.selectedDeliveryTimeSlotEnd,
            hasFailed: data.hasFailed,
            orderShippingType: data.deliveryMethod.name,
            courier: {
                name: '',
            },
            orderShippingProvider: ''
        }
        return createResponse<Order>(status, order, headers);
    };

    return {
        getOrder,
    };
}

export function hostedPaymentAdapter(mesh: HostedPaymentClient): HostedPaymentService {

    function initHostedPaypalPayment(cartId: string, type: string, locale: string, terminals: any) {

        return mesh.initHostedPaypalPayment(
            cartId,
            type,
            locale,
            terminals
        ).then((response) => {
            const { status, data, headers } = response;
            return createResponse<HostedPaymentResponse>(status, data, headers);
        });
    }

    function authoriseHostedPayment(paymentID: string, HostedPaymentPageResult: string) {

        return mesh.authoriseHostedPayment(
            paymentID,
            HostedPaymentPageResult,
        ).then((response) => {
            const { status, data, headers } = response;
            return createResponse<HostedPaymentResponse>(status, data, headers);
        });
    }

    return {
        initHostedPaypalPayment,
        authoriseHostedPayment,
    }

}

export function paymentsAdapter(mesh: PaymentClient): PaymentMeshService {

    function completePayment(paymentID: string, locale: string) {
        return mesh.completePayment(paymentID, locale)
        .then(response => {
            const { status, data, headers } = response;
            return createResponse<PaymentResponse>(status, data, headers);
        });
    }

    async function getMeshPayment(paymentID: string, locale: string) {
        return await mesh.getMeshPayment(paymentID, locale);
    }

    return {
        completePayment,
        getMeshPayment,
    };
}

export function billingCountriesAdaptor(mesh: BillingCountriesClient): BillingCountriesService {
    function getBillingCountries() {
        return mesh.getBillingCountries().then((response) => {
            const { status, data, headers } = response;
            return createResponse<BillingCountry[]>(status, data.countries, headers);
        });
    }

    return {
        getBillingCountries
    };
}

export function pagesAdaptor(mesh: PagesClient): PagesService {
    async function getPages(pageId:string) {
        const pagesResponse = await mesh.getPages(pageId);
        const { status, data, headers } = pagesResponse;
        return createResponse<Pages>(status, { content: data.content, title: data.title }, headers);
    }
    return {
        getPages
    };
}

export function addressesAdaptor(mesh: AddressesClient): AddressesService {

    function getPredictedAddresses(query: string, lastId?: string, locale?: string) {
        return mesh.getPredictedAddresses(
            query,
            lastId,
            locale,
        ).then(response => {
            const { status, data, headers } = response;
            return createResponse<AddressPredictItem[]>(status, data.map(item => {
                return {
                    ID: item.ID,
                    rel: item.links[0].rel,
                    href: item.links[0].href,
                    addressString: item.addressString
                };
            }), headers);
        });
    }

    function getRetrievedAddress(lastId: string, locale?: string) {
        return mesh.getRetrievedAddress(
            lastId,
            locale,
        ).then(response => {
            const { status, data, headers } = response;
            return createResponse<AddressRetrieved>(status, {
                address1: data[0]?.address1 ?? '',
                address2: data[0]?.address2 ?? '',
                town: data[0]?.town ?? '',
                county: data[0]?.county ?? '',
                postcode: data[0]?.postcode ?? '',
                country: data[0]?.country ?? '',
            }, headers);
        });
    }

    return {
        getPredictedAddresses,
        getRetrievedAddress,
    };
}


function meshAdaptor(state: RootState) {

    const config = state.config;

    const meshConfig: MeshConfig = {
       environment: config.api.mesh?.url || process.env.REACT_APP_MESH_ENV as MeshEnvironment || 'uat',
        apiKey: config.api.mesh?.apiKey,
        channel: config.channel as MeshChannel,
        store: config.store,
        locale: config.localisation.countryCode.toLowerCase()
    }

    let mesh = meshSDK(meshConfig);

    return {
        customers: customersAdaptor(mesh.customers),
        carts: cartsAdaptor(mesh.carts),
        deliveryCountries: deliveryCountriesAdaptor(mesh.deliveryCountries),
        billingCountries: billingCountriesAdaptor(mesh.billingCountries),
        pages: pagesAdaptor(mesh.pages),
        orders: ordersAdaptor(mesh.orders),
        addresses: addressesAdaptor(mesh.addresses),
        payments: paymentsAdapter(mesh.payments),
        hostedPayments: hostedPaymentAdapter(mesh.hostedPayments),
    }
}

export default meshAdaptor;
