import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';
import { getReturnURL } from '../../modules/config';

/**
 * In this component, extra logic can be added for when the user uses the 'back' and 'forward' browser buttons.
 * It doesn't render anything, this has been made into a React component only so we can use the React hooks useHistory, useState, etc.
 */
function HistoryManager() {
    const history = useHistory();
    const config = useSelector((state: RootState) => state.config);
    const returnUrl = getReturnURL(config);

    const [locationNames, setLocationNames] = useState([] as string[]);

    useEffect(() => {
        return history.listen(location => {
            if (history.action === 'PUSH') {
                setLocationNames([location.pathname])
            }

            if (history.action === 'POP') {
                if (locationNames[1] === location.pathname) {
                    setLocationNames(([_, ...pathnames]) => pathnames)
                    // 'FORWARD' event
                    // do nothing
                }
                else {
                    setLocationNames((pathnames) => [location.pathname, ...pathnames])
                    // 'BACK' event
                    const from = locationNames[0];
                    // const to = location.pathname;

                    // From delivery to login: we redirect to the cart page instead
                    if (from === '/delivery') {
                        history.replace('/delivery'); // Prevent the login page from loading before the cart page starts to load.
                        window.location.href = `${returnUrl}cart`;
                    } 
                    else if (from === '/expresspayment') {
                        history.replace('/expresspayment');
                        window.location.href = `${returnUrl}cart`;
                    }
                }
            }
        })
        // eslint-disable-next-line
    }, [locationNames])

    return <></>;
}

export default HistoryManager;
