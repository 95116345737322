"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Payments service
 * Payments service
 *
 * OpenAPI spec version: 1.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultApi = exports.DefaultApiFactory = exports.DefaultApiFp = exports.DefaultApiAxiosParamCreator = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var base_1 = require("../base");
/**
 * DefaultApi - axios parameter creator
 * @export
 */
exports.DefaultApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Get available Gateways for a store
         * @param {string} storeName Mesh Store Name e.g. jdsportsuk
         * @param {string} traceID UUID for this call
         * @param {string} channel Mesh Channel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableGateways: function (storeName, traceID, channel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, query, key, key, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'storeName' is not null or undefined
                    if (storeName === null || storeName === undefined) {
                        throw new base_1.RequiredError('storeName', 'Required parameter storeName was null or undefined when calling getAvailableGateways.');
                    }
                    // verify required parameter 'traceID' is not null or undefined
                    if (traceID === null || traceID === undefined) {
                        throw new base_1.RequiredError('traceID', 'Required parameter traceID was null or undefined when calling getAvailableGateways.');
                    }
                    // verify required parameter 'channel' is not null or undefined
                    if (channel === null || channel === undefined) {
                        throw new base_1.RequiredError('channel', 'Required parameter channel was null or undefined when calling getAvailableGateways.');
                    }
                    localVarPath = "/v1/{storeName}/availableGateways"
                        .replace("{" + "storeName" + "}", encodeURIComponent(String(storeName)));
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (traceID !== undefined && traceID !== null) {
                        localVarHeaderParameter['traceID'] = String(traceID);
                    }
                    if (channel !== undefined && channel !== null) {
                        localVarHeaderParameter['channel'] = String(channel);
                    }
                    query = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        query.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.params) {
                        query.set(key, options.params[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(query)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Get available methods for gateway
         * @param {string} storeName Mesh storeName e.g. jdsportsuk
         * @param {string} gateway Gateway name
         * @param {string} traceID UUID for this call
         * @param {string} channel Mesh Channel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableMethods: function (storeName, gateway, traceID, channel, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, query, key, key, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'storeName' is not null or undefined
                    if (storeName === null || storeName === undefined) {
                        throw new base_1.RequiredError('storeName', 'Required parameter storeName was null or undefined when calling getAvailableMethods.');
                    }
                    // verify required parameter 'gateway' is not null or undefined
                    if (gateway === null || gateway === undefined) {
                        throw new base_1.RequiredError('gateway', 'Required parameter gateway was null or undefined when calling getAvailableMethods.');
                    }
                    // verify required parameter 'traceID' is not null or undefined
                    if (traceID === null || traceID === undefined) {
                        throw new base_1.RequiredError('traceID', 'Required parameter traceID was null or undefined when calling getAvailableMethods.');
                    }
                    // verify required parameter 'channel' is not null or undefined
                    if (channel === null || channel === undefined) {
                        throw new base_1.RequiredError('channel', 'Required parameter channel was null or undefined when calling getAvailableMethods.');
                    }
                    localVarPath = "/v1/{storeName}/gateway/{gateway}/availableMethods"
                        .replace("{" + "storeName" + "}", encodeURIComponent(String(storeName)))
                        .replace("{" + "gateway" + "}", encodeURIComponent(String(gateway)));
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (traceID !== undefined && traceID !== null) {
                        localVarHeaderParameter['traceID'] = String(traceID);
                    }
                    if (channel !== undefined && channel !== null) {
                        localVarHeaderParameter['channel'] = String(channel);
                    }
                    query = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        query.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.params) {
                        query.set(key, options.params[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(query)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Get available methods for a gateway and a specific cart
         * @param {Cart} body Basic representation of a cart
         * @param {string} traceID UUID for this call
         * @param {string} channel Mesh Channel
         * @param {string} storeName Mesh storeName e.g. jdsportsuk
         * @param {string} gateway Gateway name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableMethodsForCart: function (body, traceID, channel, storeName, gateway, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, query, key, key, headersFromBaseOptions, needsSerialization;
                return __generator(this, function (_a) {
                    // verify required parameter 'body' is not null or undefined
                    if (body === null || body === undefined) {
                        throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling getAvailableMethodsForCart.');
                    }
                    // verify required parameter 'traceID' is not null or undefined
                    if (traceID === null || traceID === undefined) {
                        throw new base_1.RequiredError('traceID', 'Required parameter traceID was null or undefined when calling getAvailableMethodsForCart.');
                    }
                    // verify required parameter 'channel' is not null or undefined
                    if (channel === null || channel === undefined) {
                        throw new base_1.RequiredError('channel', 'Required parameter channel was null or undefined when calling getAvailableMethodsForCart.');
                    }
                    // verify required parameter 'storeName' is not null or undefined
                    if (storeName === null || storeName === undefined) {
                        throw new base_1.RequiredError('storeName', 'Required parameter storeName was null or undefined when calling getAvailableMethodsForCart.');
                    }
                    // verify required parameter 'gateway' is not null or undefined
                    if (gateway === null || gateway === undefined) {
                        throw new base_1.RequiredError('gateway', 'Required parameter gateway was null or undefined when calling getAvailableMethodsForCart.');
                    }
                    localVarPath = "/v1/{storeName}/gateway/{gateway}/availableMethodsForCart"
                        .replace("{" + "storeName" + "}", encodeURIComponent(String(storeName)))
                        .replace("{" + "gateway" + "}", encodeURIComponent(String(gateway)));
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (traceID !== undefined && traceID !== null) {
                        localVarHeaderParameter['traceID'] = String(traceID);
                    }
                    if (channel !== undefined && channel !== null) {
                        localVarHeaderParameter['channel'] = String(channel);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    query = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        query.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.params) {
                        query.set(key, options.params[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(query)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
                    localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Check if the service is healthy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, query, key, key, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/v1h/healthcheck";
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    query = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        query.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.params) {
                        query.set(key, options.params[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(query)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary POST Initialise a payment
         * @param {PaymentInit} body Payment initialisation payload
         * @param {string} traceID UUID for this call
         * @param {string} channel Mesh Channel
         * @param {string} storeName Mesh storeName e.g. jdsportsuk
         * @param {string} gateway Gateway name
         * @param {string} method Payment Method being initialised
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInitPayment: function (body, traceID, channel, storeName, gateway, method, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, query, key, key, headersFromBaseOptions, needsSerialization;
                return __generator(this, function (_a) {
                    // verify required parameter 'body' is not null or undefined
                    if (body === null || body === undefined) {
                        throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling postInitPayment.');
                    }
                    // verify required parameter 'traceID' is not null or undefined
                    if (traceID === null || traceID === undefined) {
                        throw new base_1.RequiredError('traceID', 'Required parameter traceID was null or undefined when calling postInitPayment.');
                    }
                    // verify required parameter 'channel' is not null or undefined
                    if (channel === null || channel === undefined) {
                        throw new base_1.RequiredError('channel', 'Required parameter channel was null or undefined when calling postInitPayment.');
                    }
                    // verify required parameter 'storeName' is not null or undefined
                    if (storeName === null || storeName === undefined) {
                        throw new base_1.RequiredError('storeName', 'Required parameter storeName was null or undefined when calling postInitPayment.');
                    }
                    // verify required parameter 'gateway' is not null or undefined
                    if (gateway === null || gateway === undefined) {
                        throw new base_1.RequiredError('gateway', 'Required parameter gateway was null or undefined when calling postInitPayment.');
                    }
                    // verify required parameter 'method' is not null or undefined
                    if (method === null || method === undefined) {
                        throw new base_1.RequiredError('method', 'Required parameter method was null or undefined when calling postInitPayment.');
                    }
                    localVarPath = "/v1/{storeName}/gateway/{gateway}/method/{method}/initPayment"
                        .replace("{" + "storeName" + "}", encodeURIComponent(String(storeName)))
                        .replace("{" + "gateway" + "}", encodeURIComponent(String(gateway)))
                        .replace("{" + "method" + "}", encodeURIComponent(String(method)));
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign({ method: 'POST' }, baseOptions);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (traceID !== undefined && traceID !== null) {
                        localVarHeaderParameter['traceID'] = String(traceID);
                    }
                    if (channel !== undefined && channel !== null) {
                        localVarHeaderParameter['channel'] = String(channel);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    query = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        query.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.params) {
                        query.set(key, options.params[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(query)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
                    localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary POST Payment Result
         * @param {PaymentResult} body Payment Result payload
         * @param {string} traceID UUID for this call
         * @param {string} channel Mesh Channel
         * @param {string} storeName Mesh storeName e.g. jdsportsuk
         * @param {string} gateway Gateway name
         * @param {string} method Payment Method being updated
         * @param {string} paymentID The payment ID to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPaymentResult: function (body, traceID, channel, storeName, gateway, method, paymentID, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, query, key, key, headersFromBaseOptions, needsSerialization;
                return __generator(this, function (_a) {
                    // verify required parameter 'body' is not null or undefined
                    if (body === null || body === undefined) {
                        throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling postPaymentResult.');
                    }
                    // verify required parameter 'traceID' is not null or undefined
                    if (traceID === null || traceID === undefined) {
                        throw new base_1.RequiredError('traceID', 'Required parameter traceID was null or undefined when calling postPaymentResult.');
                    }
                    // verify required parameter 'channel' is not null or undefined
                    if (channel === null || channel === undefined) {
                        throw new base_1.RequiredError('channel', 'Required parameter channel was null or undefined when calling postPaymentResult.');
                    }
                    // verify required parameter 'storeName' is not null or undefined
                    if (storeName === null || storeName === undefined) {
                        throw new base_1.RequiredError('storeName', 'Required parameter storeName was null or undefined when calling postPaymentResult.');
                    }
                    // verify required parameter 'gateway' is not null or undefined
                    if (gateway === null || gateway === undefined) {
                        throw new base_1.RequiredError('gateway', 'Required parameter gateway was null or undefined when calling postPaymentResult.');
                    }
                    // verify required parameter 'method' is not null or undefined
                    if (method === null || method === undefined) {
                        throw new base_1.RequiredError('method', 'Required parameter method was null or undefined when calling postPaymentResult.');
                    }
                    // verify required parameter 'paymentID' is not null or undefined
                    if (paymentID === null || paymentID === undefined) {
                        throw new base_1.RequiredError('paymentID', 'Required parameter paymentID was null or undefined when calling postPaymentResult.');
                    }
                    localVarPath = "/v1/{storeName}/gateway/{gateway}/method/{method}/paymentResult/{paymentID}"
                        .replace("{" + "storeName" + "}", encodeURIComponent(String(storeName)))
                        .replace("{" + "gateway" + "}", encodeURIComponent(String(gateway)))
                        .replace("{" + "method" + "}", encodeURIComponent(String(method)))
                        .replace("{" + "paymentID" + "}", encodeURIComponent(String(paymentID)));
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (traceID !== undefined && traceID !== null) {
                        localVarHeaderParameter['traceID'] = String(traceID);
                    }
                    if (channel !== undefined && channel !== null) {
                        localVarHeaderParameter['channel'] = String(channel);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    query = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        query.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.params) {
                        query.set(key, options.params[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(query)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
                    localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Pre Payment checks on Cart.
         * @param {PrePaymentCheck} body Basic representation of a cart
         * @param {string} traceID UUID for this call
         * @param {string} channel Mesh Channel
         * @param {string} storeName Mesh storeName e.g. jdsportsuk
         * @param {string} gateway Gateway name
         * @param {string} method Payment Method being initialised
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPrePaymentCheck: function (body, traceID, channel, storeName, gateway, method, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, query, key, key, headersFromBaseOptions, needsSerialization;
                return __generator(this, function (_a) {
                    // verify required parameter 'body' is not null or undefined
                    if (body === null || body === undefined) {
                        throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling postPrePaymentCheck.');
                    }
                    // verify required parameter 'traceID' is not null or undefined
                    if (traceID === null || traceID === undefined) {
                        throw new base_1.RequiredError('traceID', 'Required parameter traceID was null or undefined when calling postPrePaymentCheck.');
                    }
                    // verify required parameter 'channel' is not null or undefined
                    if (channel === null || channel === undefined) {
                        throw new base_1.RequiredError('channel', 'Required parameter channel was null or undefined when calling postPrePaymentCheck.');
                    }
                    // verify required parameter 'storeName' is not null or undefined
                    if (storeName === null || storeName === undefined) {
                        throw new base_1.RequiredError('storeName', 'Required parameter storeName was null or undefined when calling postPrePaymentCheck.');
                    }
                    // verify required parameter 'gateway' is not null or undefined
                    if (gateway === null || gateway === undefined) {
                        throw new base_1.RequiredError('gateway', 'Required parameter gateway was null or undefined when calling postPrePaymentCheck.');
                    }
                    // verify required parameter 'method' is not null or undefined
                    if (method === null || method === undefined) {
                        throw new base_1.RequiredError('method', 'Required parameter method was null or undefined when calling postPrePaymentCheck.');
                    }
                    localVarPath = "/v1/{storeName}/gateway/{gateway}/method/{method}/prePaymentCheck"
                        .replace("{" + "storeName" + "}", encodeURIComponent(String(storeName)))
                        .replace("{" + "gateway" + "}", encodeURIComponent(String(gateway)))
                        .replace("{" + "method" + "}", encodeURIComponent(String(method)));
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign({ method: 'POST' }, baseOptions);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (traceID !== undefined && traceID !== null) {
                        localVarHeaderParameter['traceID'] = String(traceID);
                    }
                    if (channel !== undefined && channel !== null) {
                        localVarHeaderParameter['channel'] = String(channel);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    query = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        query.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.params) {
                        query.set(key, options.params[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(query)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
                    localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary POST Update a payment
         * @param {PaymentUpdate} body Payment Update payload
         * @param {string} traceID UUID for this call
         * @param {string} channel Mesh Channel
         * @param {string} storeName Mesh storeName e.g. jdsportsuk
         * @param {string} gateway Gateway name
         * @param {string} method Payment Method being updated
         * @param {string} paymentID The payment ID to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUpdatePayment: function (body, traceID, channel, storeName, gateway, method, paymentID, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, query, key, key, headersFromBaseOptions, needsSerialization;
                return __generator(this, function (_a) {
                    // verify required parameter 'body' is not null or undefined
                    if (body === null || body === undefined) {
                        throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling postUpdatePayment.');
                    }
                    // verify required parameter 'traceID' is not null or undefined
                    if (traceID === null || traceID === undefined) {
                        throw new base_1.RequiredError('traceID', 'Required parameter traceID was null or undefined when calling postUpdatePayment.');
                    }
                    // verify required parameter 'channel' is not null or undefined
                    if (channel === null || channel === undefined) {
                        throw new base_1.RequiredError('channel', 'Required parameter channel was null or undefined when calling postUpdatePayment.');
                    }
                    // verify required parameter 'storeName' is not null or undefined
                    if (storeName === null || storeName === undefined) {
                        throw new base_1.RequiredError('storeName', 'Required parameter storeName was null or undefined when calling postUpdatePayment.');
                    }
                    // verify required parameter 'gateway' is not null or undefined
                    if (gateway === null || gateway === undefined) {
                        throw new base_1.RequiredError('gateway', 'Required parameter gateway was null or undefined when calling postUpdatePayment.');
                    }
                    // verify required parameter 'method' is not null or undefined
                    if (method === null || method === undefined) {
                        throw new base_1.RequiredError('method', 'Required parameter method was null or undefined when calling postUpdatePayment.');
                    }
                    // verify required parameter 'paymentID' is not null or undefined
                    if (paymentID === null || paymentID === undefined) {
                        throw new base_1.RequiredError('paymentID', 'Required parameter paymentID was null or undefined when calling postUpdatePayment.');
                    }
                    localVarPath = "/v1/{storeName}/gateway/{gateway}/method/{method}/paymentUpdate/{paymentID}"
                        .replace("{" + "storeName" + "}", encodeURIComponent(String(storeName)))
                        .replace("{" + "gateway" + "}", encodeURIComponent(String(gateway)))
                        .replace("{" + "method" + "}", encodeURIComponent(String(method)))
                        .replace("{" + "paymentID" + "}", encodeURIComponent(String(paymentID)));
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (traceID !== undefined && traceID !== null) {
                        localVarHeaderParameter['traceID'] = String(traceID);
                    }
                    if (channel !== undefined && channel !== null) {
                        localVarHeaderParameter['channel'] = String(channel);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    query = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        query.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.params) {
                        query.set(key, options.params[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(query)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
                    localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Cart update for a store
         * @param {Cart} body Representation of a cart
         * @param {string} traceID UUID for this call
         * @param {string} channel Mesh Channel
         * @param {string} storeName Mesh Store Name e.g. jdsportsuk
         * @param {string} cartID The cart ID to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUpdateCart: function (body, traceID, channel, storeName, cartID, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, query, key, key, headersFromBaseOptions, needsSerialization;
                return __generator(this, function (_a) {
                    // verify required parameter 'body' is not null or undefined
                    if (body === null || body === undefined) {
                        throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling putUpdateCart.');
                    }
                    // verify required parameter 'traceID' is not null or undefined
                    if (traceID === null || traceID === undefined) {
                        throw new base_1.RequiredError('traceID', 'Required parameter traceID was null or undefined when calling putUpdateCart.');
                    }
                    // verify required parameter 'channel' is not null or undefined
                    if (channel === null || channel === undefined) {
                        throw new base_1.RequiredError('channel', 'Required parameter channel was null or undefined when calling putUpdateCart.');
                    }
                    // verify required parameter 'storeName' is not null or undefined
                    if (storeName === null || storeName === undefined) {
                        throw new base_1.RequiredError('storeName', 'Required parameter storeName was null or undefined when calling putUpdateCart.');
                    }
                    // verify required parameter 'cartID' is not null or undefined
                    if (cartID === null || cartID === undefined) {
                        throw new base_1.RequiredError('cartID', 'Required parameter cartID was null or undefined when calling putUpdateCart.');
                    }
                    localVarPath = "/v1/{storeName}/updateCart/{cartID}"
                        .replace("{" + "storeName" + "}", encodeURIComponent(String(storeName)))
                        .replace("{" + "cartID" + "}", encodeURIComponent(String(cartID)));
                    localVarUrlObj = new URL(localVarPath, 'https://example.com');
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (traceID !== undefined && traceID !== null) {
                        localVarHeaderParameter['traceID'] = String(traceID);
                    }
                    if (channel !== undefined && channel !== null) {
                        localVarHeaderParameter['channel'] = String(channel);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    query = new URLSearchParams(localVarUrlObj.search);
                    for (key in localVarQueryParameter) {
                        query.set(key, localVarQueryParameter[key]);
                    }
                    for (key in options.params) {
                        query.set(key, options.params[key]);
                    }
                    localVarUrlObj.search = (new URLSearchParams(query)).toString();
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
                    localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
                    return [2 /*return*/, {
                            url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * DefaultApi - functional programming interface
 * @export
 */
exports.DefaultApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Get available Gateways for a store
         * @param {string} storeName Mesh Store Name e.g. jdsportsuk
         * @param {string} traceID UUID for this call
         * @param {string} channel Mesh Channel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableGateways: function (storeName, traceID, channel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.DefaultApiAxiosParamCreator(configuration).getAvailableGateways(storeName, traceID, channel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get available methods for gateway
         * @param {string} storeName Mesh storeName e.g. jdsportsuk
         * @param {string} gateway Gateway name
         * @param {string} traceID UUID for this call
         * @param {string} channel Mesh Channel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableMethods: function (storeName, gateway, traceID, channel, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.DefaultApiAxiosParamCreator(configuration).getAvailableMethods(storeName, gateway, traceID, channel, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get available methods for a gateway and a specific cart
         * @param {Cart} body Basic representation of a cart
         * @param {string} traceID UUID for this call
         * @param {string} channel Mesh Channel
         * @param {string} storeName Mesh storeName e.g. jdsportsuk
         * @param {string} gateway Gateway name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableMethodsForCart: function (body, traceID, channel, storeName, gateway, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.DefaultApiAxiosParamCreator(configuration).getAvailableMethodsForCart(body, traceID, channel, storeName, gateway, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Check if the service is healthy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.DefaultApiAxiosParamCreator(configuration).healthCheck(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary POST Initialise a payment
         * @param {PaymentInit} body Payment initialisation payload
         * @param {string} traceID UUID for this call
         * @param {string} channel Mesh Channel
         * @param {string} storeName Mesh storeName e.g. jdsportsuk
         * @param {string} gateway Gateway name
         * @param {string} method Payment Method being initialised
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInitPayment: function (body, traceID, channel, storeName, gateway, method, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.DefaultApiAxiosParamCreator(configuration).postInitPayment(body, traceID, channel, storeName, gateway, method, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary POST Payment Result
         * @param {PaymentResult} body Payment Result payload
         * @param {string} traceID UUID for this call
         * @param {string} channel Mesh Channel
         * @param {string} storeName Mesh storeName e.g. jdsportsuk
         * @param {string} gateway Gateway name
         * @param {string} method Payment Method being updated
         * @param {string} paymentID The payment ID to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPaymentResult: function (body, traceID, channel, storeName, gateway, method, paymentID, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.DefaultApiAxiosParamCreator(configuration).postPaymentResult(body, traceID, channel, storeName, gateway, method, paymentID, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Pre Payment checks on Cart.
         * @param {PrePaymentCheck} body Basic representation of a cart
         * @param {string} traceID UUID for this call
         * @param {string} channel Mesh Channel
         * @param {string} storeName Mesh storeName e.g. jdsportsuk
         * @param {string} gateway Gateway name
         * @param {string} method Payment Method being initialised
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPrePaymentCheck: function (body, traceID, channel, storeName, gateway, method, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.DefaultApiAxiosParamCreator(configuration).postPrePaymentCheck(body, traceID, channel, storeName, gateway, method, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary POST Update a payment
         * @param {PaymentUpdate} body Payment Update payload
         * @param {string} traceID UUID for this call
         * @param {string} channel Mesh Channel
         * @param {string} storeName Mesh storeName e.g. jdsportsuk
         * @param {string} gateway Gateway name
         * @param {string} method Payment Method being updated
         * @param {string} paymentID The payment ID to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUpdatePayment: function (body, traceID, channel, storeName, gateway, method, paymentID, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.DefaultApiAxiosParamCreator(configuration).postUpdatePayment(body, traceID, channel, storeName, gateway, method, paymentID, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Cart update for a store
         * @param {Cart} body Representation of a cart
         * @param {string} traceID UUID for this call
         * @param {string} channel Mesh Channel
         * @param {string} storeName Mesh Store Name e.g. jdsportsuk
         * @param {string} cartID The cart ID to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUpdateCart: function (body, traceID, channel, storeName, cartID, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, exports.DefaultApiAxiosParamCreator(configuration).putUpdateCart(body, traceID, channel, storeName, cartID, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, function (axios, basePath) {
                                    if (axios === void 0) { axios = axios_1.default; }
                                    if (basePath === void 0) { basePath = base_1.BASE_PATH; }
                                    var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                                    return axios.request(axiosRequestArgs);
                                }];
                    }
                });
            });
        },
    };
};
/**
 * DefaultApi - factory interface
 * @export
 */
exports.DefaultApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Get available Gateways for a store
         * @param {string} storeName Mesh Store Name e.g. jdsportsuk
         * @param {string} traceID UUID for this call
         * @param {string} channel Mesh Channel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableGateways: function (storeName, traceID, channel, options) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    return [2 /*return*/, exports.DefaultApiFp(configuration).getAvailableGateways(storeName, traceID, channel, options).then(function (request) { return request(axios, basePath); })];
                });
            });
        },
        /**
         *
         * @summary Get available methods for gateway
         * @param {string} storeName Mesh storeName e.g. jdsportsuk
         * @param {string} gateway Gateway name
         * @param {string} traceID UUID for this call
         * @param {string} channel Mesh Channel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableMethods: function (storeName, gateway, traceID, channel, options) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    return [2 /*return*/, exports.DefaultApiFp(configuration).getAvailableMethods(storeName, gateway, traceID, channel, options).then(function (request) { return request(axios, basePath); })];
                });
            });
        },
        /**
         *
         * @summary Get available methods for a gateway and a specific cart
         * @param {Cart} body Basic representation of a cart
         * @param {string} traceID UUID for this call
         * @param {string} channel Mesh Channel
         * @param {string} storeName Mesh storeName e.g. jdsportsuk
         * @param {string} gateway Gateway name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableMethodsForCart: function (body, traceID, channel, storeName, gateway, options) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    return [2 /*return*/, exports.DefaultApiFp(configuration).getAvailableMethodsForCart(body, traceID, channel, storeName, gateway, options).then(function (request) { return request(axios, basePath); })];
                });
            });
        },
        /**
         *
         * @summary Check if the service is healthy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    return [2 /*return*/, exports.DefaultApiFp(configuration).healthCheck(options).then(function (request) { return request(axios, basePath); })];
                });
            });
        },
        /**
         *
         * @summary POST Initialise a payment
         * @param {PaymentInit} body Payment initialisation payload
         * @param {string} traceID UUID for this call
         * @param {string} channel Mesh Channel
         * @param {string} storeName Mesh storeName e.g. jdsportsuk
         * @param {string} gateway Gateway name
         * @param {string} method Payment Method being initialised
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInitPayment: function (body, traceID, channel, storeName, gateway, method, options) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    return [2 /*return*/, exports.DefaultApiFp(configuration).postInitPayment(body, traceID, channel, storeName, gateway, method, options).then(function (request) { return request(axios, basePath); })];
                });
            });
        },
        /**
         *
         * @summary POST Payment Result
         * @param {PaymentResult} body Payment Result payload
         * @param {string} traceID UUID for this call
         * @param {string} channel Mesh Channel
         * @param {string} storeName Mesh storeName e.g. jdsportsuk
         * @param {string} gateway Gateway name
         * @param {string} method Payment Method being updated
         * @param {string} paymentID The payment ID to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPaymentResult: function (body, traceID, channel, storeName, gateway, method, paymentID, options) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    return [2 /*return*/, exports.DefaultApiFp(configuration).postPaymentResult(body, traceID, channel, storeName, gateway, method, paymentID, options).then(function (request) { return request(axios, basePath); })];
                });
            });
        },
        /**
         *
         * @summary Pre Payment checks on Cart.
         * @param {PrePaymentCheck} body Basic representation of a cart
         * @param {string} traceID UUID for this call
         * @param {string} channel Mesh Channel
         * @param {string} storeName Mesh storeName e.g. jdsportsuk
         * @param {string} gateway Gateway name
         * @param {string} method Payment Method being initialised
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPrePaymentCheck: function (body, traceID, channel, storeName, gateway, method, options) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    return [2 /*return*/, exports.DefaultApiFp(configuration).postPrePaymentCheck(body, traceID, channel, storeName, gateway, method, options).then(function (request) { return request(axios, basePath); })];
                });
            });
        },
        /**
         *
         * @summary POST Update a payment
         * @param {PaymentUpdate} body Payment Update payload
         * @param {string} traceID UUID for this call
         * @param {string} channel Mesh Channel
         * @param {string} storeName Mesh storeName e.g. jdsportsuk
         * @param {string} gateway Gateway name
         * @param {string} method Payment Method being updated
         * @param {string} paymentID The payment ID to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUpdatePayment: function (body, traceID, channel, storeName, gateway, method, paymentID, options) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    return [2 /*return*/, exports.DefaultApiFp(configuration).postUpdatePayment(body, traceID, channel, storeName, gateway, method, paymentID, options).then(function (request) { return request(axios, basePath); })];
                });
            });
        },
        /**
         *
         * @summary Cart update for a store
         * @param {Cart} body Representation of a cart
         * @param {string} traceID UUID for this call
         * @param {string} channel Mesh Channel
         * @param {string} storeName Mesh Store Name e.g. jdsportsuk
         * @param {string} cartID The cart ID to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUpdateCart: function (body, traceID, channel, storeName, cartID, options) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    return [2 /*return*/, exports.DefaultApiFp(configuration).putUpdateCart(body, traceID, channel, storeName, cartID, options).then(function (request) { return request(axios, basePath); })];
                });
            });
        },
    };
};
/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
var DefaultApi = /** @class */ (function (_super) {
    __extends(DefaultApi, _super);
    function DefaultApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Get available Gateways for a store
     * @param {string} storeName Mesh Store Name e.g. jdsportsuk
     * @param {string} traceID UUID for this call
     * @param {string} channel Mesh Channel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getAvailableGateways = function (storeName, traceID, channel, options) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, exports.DefaultApiFp(this.configuration).getAvailableGateways(storeName, traceID, channel, options).then(function (request) { return request(_this.axios, _this.basePath); })];
            });
        });
    };
    /**
     *
     * @summary Get available methods for gateway
     * @param {string} storeName Mesh storeName e.g. jdsportsuk
     * @param {string} gateway Gateway name
     * @param {string} traceID UUID for this call
     * @param {string} channel Mesh Channel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getAvailableMethods = function (storeName, gateway, traceID, channel, options) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, exports.DefaultApiFp(this.configuration).getAvailableMethods(storeName, gateway, traceID, channel, options).then(function (request) { return request(_this.axios, _this.basePath); })];
            });
        });
    };
    /**
     *
     * @summary Get available methods for a gateway and a specific cart
     * @param {Cart} body Basic representation of a cart
     * @param {string} traceID UUID for this call
     * @param {string} channel Mesh Channel
     * @param {string} storeName Mesh storeName e.g. jdsportsuk
     * @param {string} gateway Gateway name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.getAvailableMethodsForCart = function (body, traceID, channel, storeName, gateway, options) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, exports.DefaultApiFp(this.configuration).getAvailableMethodsForCart(body, traceID, channel, storeName, gateway, options).then(function (request) { return request(_this.axios, _this.basePath); })];
            });
        });
    };
    /**
     *
     * @summary Check if the service is healthy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.healthCheck = function (options) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, exports.DefaultApiFp(this.configuration).healthCheck(options).then(function (request) { return request(_this.axios, _this.basePath); })];
            });
        });
    };
    /**
     *
     * @summary POST Initialise a payment
     * @param {PaymentInit} body Payment initialisation payload
     * @param {string} traceID UUID for this call
     * @param {string} channel Mesh Channel
     * @param {string} storeName Mesh storeName e.g. jdsportsuk
     * @param {string} gateway Gateway name
     * @param {string} method Payment Method being initialised
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postInitPayment = function (body, traceID, channel, storeName, gateway, method, options) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, exports.DefaultApiFp(this.configuration).postInitPayment(body, traceID, channel, storeName, gateway, method, options).then(function (request) { return request(_this.axios, _this.basePath); })];
            });
        });
    };
    /**
     *
     * @summary POST Payment Result
     * @param {PaymentResult} body Payment Result payload
     * @param {string} traceID UUID for this call
     * @param {string} channel Mesh Channel
     * @param {string} storeName Mesh storeName e.g. jdsportsuk
     * @param {string} gateway Gateway name
     * @param {string} method Payment Method being updated
     * @param {string} paymentID The payment ID to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postPaymentResult = function (body, traceID, channel, storeName, gateway, method, paymentID, options) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, exports.DefaultApiFp(this.configuration).postPaymentResult(body, traceID, channel, storeName, gateway, method, paymentID, options).then(function (request) { return request(_this.axios, _this.basePath); })];
            });
        });
    };
    /**
     *
     * @summary Pre Payment checks on Cart.
     * @param {PrePaymentCheck} body Basic representation of a cart
     * @param {string} traceID UUID for this call
     * @param {string} channel Mesh Channel
     * @param {string} storeName Mesh storeName e.g. jdsportsuk
     * @param {string} gateway Gateway name
     * @param {string} method Payment Method being initialised
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postPrePaymentCheck = function (body, traceID, channel, storeName, gateway, method, options) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, exports.DefaultApiFp(this.configuration).postPrePaymentCheck(body, traceID, channel, storeName, gateway, method, options).then(function (request) { return request(_this.axios, _this.basePath); })];
            });
        });
    };
    /**
     *
     * @summary POST Update a payment
     * @param {PaymentUpdate} body Payment Update payload
     * @param {string} traceID UUID for this call
     * @param {string} channel Mesh Channel
     * @param {string} storeName Mesh storeName e.g. jdsportsuk
     * @param {string} gateway Gateway name
     * @param {string} method Payment Method being updated
     * @param {string} paymentID The payment ID to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.postUpdatePayment = function (body, traceID, channel, storeName, gateway, method, paymentID, options) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, exports.DefaultApiFp(this.configuration).postUpdatePayment(body, traceID, channel, storeName, gateway, method, paymentID, options).then(function (request) { return request(_this.axios, _this.basePath); })];
            });
        });
    };
    /**
     *
     * @summary Cart update for a store
     * @param {Cart} body Representation of a cart
     * @param {string} traceID UUID for this call
     * @param {string} channel Mesh Channel
     * @param {string} storeName Mesh Store Name e.g. jdsportsuk
     * @param {string} cartID The cart ID to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.putUpdateCart = function (body, traceID, channel, storeName, cartID, options) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, exports.DefaultApiFp(this.configuration).putUpdateCart(body, traceID, channel, storeName, cartID, options).then(function (request) { return request(_this.axios, _this.basePath); })];
            });
        });
    };
    return DefaultApi;
}(base_1.BaseAPI));
exports.DefaultApi = DefaultApi;
