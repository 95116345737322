import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, Text } from 'grommet';
import { ThemeContext } from 'styled-components';
import { CustomTheme } from "../../theme";
import { RootState } from '../../store/reducers';
import { translate } from '../../modules/translation';

function GuestLogin({title, description, buttonLabel, onClick, disableButton}: GuestLoginProps) {
    const theme: CustomTheme = useContext(ThemeContext);
    const translation = useSelector((state: RootState) => state.translation);

    const strings = {
        guestCheckout: translate(translation, 'guestCheckout', 'Guest Checkout'),
        canCreateAcountAfterCheckout: translate(translation, 'canCreateAcountAfterCheckout', 'You can create an account after checkout for a faster experience next time.'),
        continueGuest: translate(translation, 'continueGuest', 'Continue as a guest'),
    };

    return (
        <Box direction="column" flex="grow">
            <Box direction="row"
                 style={{
                     'textTransform': theme?.config?.stepTextTransform ?? "unset",
                     'letterSpacing': theme?.config?.textLetterSpacing ?? "normal",
                 }}
            >
                <Text size="large"
                      weight={theme?.config?.titleFontWeight ?? 'bold'}
                      margin="none"
                      color={theme?.config?.titleTextColor ?? ''}
                >
                    { !!title ? title : strings.guestCheckout }
                </Text>
            </Box>
            <Box direction="row" pad={{top: "small", bottom: "small"}}>
                <Text>{ !!description ? description : strings.canCreateAcountAfterCheckout }</Text>
            </Box>
            <Box direction="row">
                <Button 
                    primary
                    fill="horizontal"
                    type="submit"
                    onClick={onClick}
                    label={ !!buttonLabel ? buttonLabel : strings.continueGuest }
                    disabled={disableButton}
                />
            </Box>
        </Box>
    );
}

type GuestLoginProps = {
    title?: string,
    description?: string,
    buttonLabel?: string | JSX.Element,
    onClick: (arg?: any) => void,
    disableButton: boolean,
};

export default GuestLogin;