import { AxiosInstance, AxiosResponse } from 'axios';
import { MeshConfig } from './types';

type Country = {
    code: string,
    name: string,
    default: boolean,
    requiresState: boolean,
    pcaPredictSupported: boolean
};

export interface DeliveryCountriesResponse {
    name: string,
    count: number,
    countries: Country[]
}


function deliveryCountries (client: AxiosInstance, meshConfig: MeshConfig) {

    const { channel, locale} = meshConfig;

    async function getDeliveryCountries(): Promise<AxiosResponse<DeliveryCountriesResponse>>{
        let countriesResponse = await client.get<DeliveryCountriesResponse>(`/deliverycountries`, {
            params: {
                channel,
                locale
            }
        });

        return countriesResponse;
    }

    return {
        getDeliveryCountries
    };
}

export type DeliveryCountriesService = ReturnType<typeof deliveryCountries>;

export default deliveryCountries;
