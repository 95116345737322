import { OrderActionTypes, UPDATE, Order } from './types';
import { Product } from '../cart/types';

const initialMoneyAmount = {
    amount: '0.00',
    currency: 'GBP',
};
const initialAddress = {
    ID: '',
    firstName: '',
    lastName:'',
    email: '',
    country: '',
    address1: '',
    town: '',
    postcode: '',
    isDefaultDeliveryAddress: false,
    isDefaultBillingAddress: false,
    locale: '',
};

export const initialState: Order = {
    ID: '',
    invoiceID: '',
    paymentMethod: '',
    status: '',
    isDiscounted: false,
    estimatedDeliveryDate: {
        hasRange: false,
    },
    channel: 'mobile',
    paymentDetails: {
        gateway: '',
        method: '',
        isCaptured: false,
        amountCaptured: initialMoneyAmount,
    },
    products: [] as Product[],
    productsSubtotal: initialMoneyAmount,
    deliveryMethod: {
        name: '',
    },
    courier: {
        name: '',
    },
    deliveryTotal: initialMoneyAmount,
    giftcardsSubtotal: initialMoneyAmount,
    total: initialMoneyAmount,
    grandTotal: initialMoneyAmount,
    balanceToPay: initialMoneyAmount,
    totalSavings: initialMoneyAmount,
    appliedDiscountCodes: initialMoneyAmount,
    customer: {
        ID: '',
        email: '',
        returning: false,
        firstName: '',
        lastName: '',
        phone: '',
        isActive: false,
        isGuest: false,
        isRegistered: false,
        addresses: [],
        marketingPreferencesURL: '',
        marketingCancellationURL: '',
    },
    billingAddress: initialAddress,
    isDeliverToStore: false,
    deliveryAddress: initialAddress,
    selectedDeliveryDate: null,
    selectedDeliveryTimeSlotStart: null,
    selectedDeliveryTimeSlotEnd: null,
    hasFailed: false,
    orderShippingType: '',
    orderShippingProvider: ''
}


// Reducer
export default function reducer(state = initialState, action: OrderActionTypes) {
    switch (action.type) {
        case UPDATE: {
            return { ...action.payload }
        }

        default: {
            return state;
        }
    }
}

// Selectors
export const getOrder = (state: Order) => state;
export const getOrderMarketingPreferencesURL = (state: Order) => state.customer.marketingPreferencesURL;
export const getOrderMarketingCancellationURL = (state: Order) => state.customer.marketingCancellationURL;

// Action Creators
export function updateOrder(order: Order): OrderActionTypes {
    return {
        type: UPDATE,
        payload: order,
    };
}
