import axios, { AxiosInstance, AxiosError, AxiosResponse } from 'axios';
import qs from 'qs';
import { session as store2 } from 'store2';
import { AuthToken } from '../../modules/session/types';
import { OrderMeshResponse } from './orders';
import { MeshConfig } from './types';

type OauthResponse = {
    refresh_expires_in: number,
    refresh_token: string,
    token_type: string,
    access_token: string,
    customerID: string,
    expires_in: number
}

export interface CustomerUpdatePayload {
    id?: string,
    ID?: string,
    addresses?: Array<AddressResponse>,
    canEditEmailAddress?: boolean,
    canSetPrimaryAddress?: boolean,
    canSetPrimaryBillingAddress?: boolean,
    clientDateAdded?: string,
    clientID?: string | null,
    dateOfBirth?: string | null,
    email?: string,
    enrolledForEmailMarketing?: boolean,
    enrolledForPostageMarketing?: boolean,
    enrolledForSMSMarketing?: boolean,
    enrolledForThirdPartyMarketing?: boolean,
    firstName?: string,
    isActive?: boolean,
    isGuest?: boolean,
    isRegistered?: boolean,
    lastName?: string,
    marketingCancellationURL?: string,
    marketingPreferencesURL?: string,
    mobile?: string | null,
    otherPhoneNumber?: string | null,
    phone?: string | null,
    preferredContactMethod?: "phone" | "email" | "sms",
    registrationGiftcardSent?: boolean,
}


export interface CustomerResponse {
    id: string,
    ID: string,
    addresses: Array<AddressResponse>,
    canEditEmailAddress: boolean,
    canSetPrimaryAddress: boolean,
    canSetPrimaryBillingAddress: boolean,
    clientDateAdded: string,
    clientID: string | null,
    dateOfBirth: string | null,
    email: string,
    enrolledForEmailMarketing: boolean,
    enrolledForPostageMarketing: boolean,
    enrolledForSMSMarketing: boolean,
    enrolledForThirdPartyMarketing: boolean,
    firstName: string,
    isActive: boolean,
    isGuest: boolean,
    isRegistered: boolean,
    lastName: string,
    marketingCancellationURL: string,
    marketingPreferencesURL: string,
    mobile: string | null,
    otherPhoneNumber: string | null,
    phone: string | null,
    preferredContactMethod: "phone" | "email" | "sms",
    registrationGiftcardSent: boolean,
}

export type CustomerPreferenceType = 'DELIVERY_METHOD' | 'PAYMENT_METHOD' | 'STORE_LOCATION' | 'COLLECTION_DETAIL';
type StoreDayOpeningTypes = {
    open: boolean,
    opensAt: string,
    closesAt: string,
    formattedOpeningHours: string,
};
export type CustomerStoreResourceResponse = {
    id: string,
    ID: string,
    storeID: string,
    href: string,
    clientID: string,
    fasciaCode: string,
    active: boolean,
    clientStoreID: string,
    name: string,
    fullFasciaName: string,
    address1: string,
    address2: string | null,
    address3: string | null,
    address4: string | null,
    postcode: string,
    country: string,
    address: {
        address1: string,
        address2: string | null,
        address3: string | null,
        town: string,
        county: string | null,
        postcode: string,
        country: string,
        locale: string,
    },
    latitude: string,
    longitude: string,
    phone: string,
    openMon: string,
    openTues: string,
    openWed: string,
    openThurs: string,
    openFri: string,
    openSat: string,
    openSun: string,
    openingHours: {
        Monday: StoreDayOpeningTypes,
        Tuesday: StoreDayOpeningTypes,
        Wednesday: StoreDayOpeningTypes,
        Thursday: StoreDayOpeningTypes,
        Friday: StoreDayOpeningTypes,
        Saturday: StoreDayOpeningTypes,
        Sunday: StoreDayOpeningTypes,
    },
    directions: null,
    distance: null,
    distanceUnits: string,
    storeType: string,
    additional1: string,
    additional2: string,
    additional3: string,
    collection: {
        leadTime: string,
        collectionDays: {
            Monday: boolean,
            Tuesday: boolean,
            Wednesday: boolean,
            Thursday: boolean,
            Friday: boolean,
            Saturday: boolean,
            Sunday: boolean,
        },
        allowCollectFromStore: boolean,
    },
    delivery: {
        leadTime: string,
        collectionDays: {
            Monday: boolean,
            Tuesday: boolean,
            Wednesday: boolean,
            Thursday: boolean,
            Friday: boolean,
            Saturday: boolean,
            Sunday: boolean,
        },
        allowCollectFromStore: boolean,
        allowClickAndCollect: boolean,
    },
    editable: boolean,
    editableFields: string[],
    instoreMode: boolean,
    accessibility: { type: string, name: string }[],
};

export interface CustomerPreferenceResponse {
    ID: string,
    customerID: string,
    href: string,
    type: CustomerPreferenceType,
    prefKey: string,
    prefValue: string,
    resource: string | CustomerStoreResourceResponse,
}

export interface MeshCustomerPreferencePayload {
    type: CustomerPreferenceType,
    prefKey: string,
    prefValue: string,
}

export type AddressResponse = {
    ID: string,
    id: string,
    clientID: string | null,
    name: string | null,
    title: string | null,
    firstName: string,
    lastName: string,
    address1: string,
    address2: string,
    address3: string,
    town: string,
    county: string | null,
    postcode: string | null,
    country: string | null,
    locale: string,
    email: string | null,
    phone: string,
    isPrimaryAddress: boolean,
    isPrimaryBillingAddress: boolean,
    canSetPrimaryAddress: boolean,
    canSetPrimaryBillingAddress: boolean,
}

export type MeshAddress = {
    firstName: string,
    lastName: string,
    phone: string,
    address1: string,
    address2: string,
    town: string,
    county: string,
    postcode: string,
    title: string,
    country: string,
    locale: string,
    isPrimaryBillingAddress?: boolean,
    isPrimaryAddress?: boolean,
    useDeliveryAsBilling?: boolean,
    isCAndC: boolean,
}

type CustomerSearchParams = {
    email: string
}

/**
 * The response structure is different depending on the fascia:
 * JD UK and some others: RegisteredResponseAsObject
 * JD DK and some others: RegisteredResponseAsArray
 */
export type RegisteredResponse = RegisteredResponseAsObject | RegisteredResponseAsArray;
type RegisteredResponseAsObject = {
    email: string,
    registered: boolean
}
type RegisteredResponseAsArray = RegisteredResponseAsObject[];

export interface DeleteCardResponse {
    "cards": AdyenSavedCard[],
    "canEditClientName": boolean
}

type AdyenSavedCard = {
    ID: string,
    clientName: string,
    type: string,
    cardNumber: string,
    expiryDate: number
}

function customers(client: AxiosInstance, oauthClient: AxiosInstance, meshConfig: MeshConfig) {

    const { channel, store, locale } = meshConfig;

    async function isRegistered({ email }: CustomerSearchParams): Promise<AxiosResponse<RegisteredResponse>> {
        let registeredResponse = await client.get(`/customers/registered`, {
            params: {
                email
            }
        });

        return registeredResponse;
    }


    async function login(username: string, password: string): Promise<AxiosResponse<OauthResponse>> {
        // URL-encoding of the body content
        const params = qs.stringify({
            username,
            password,
            grant_type: "password",
            client_id: `${store}-${channel}`
        });

        let loginResponse = await oauthClient.post<OauthResponse>(`/token`, params).then((response) => {
            setOauthToken({
                access_token: response.data.access_token,
                refresh_token: response.data.refresh_token,
            });
            // Add a response interceptor to handle refreshing token
            client.interceptors.response.use((response) => response, oauthExpiredHandler);
            return response;
        });

        return loginResponse;
    }

    async function getCustomer(customerID: string): Promise<AxiosResponse<CustomerResponse>> {
        const getCallParams = {
            params: {
                channel,
                excludeCAndCAddress:1,
            },
        };

        let customerResponse = await client.get<CustomerResponse>(`/customers/${customerID}`, getCallParams)

        return customerResponse;
    }

    async function getCustomerPreferences(customerID: string): Promise<AxiosResponse<CustomerPreferenceResponse[]>> {
        const customerPreferencesResponse = await client.get<CustomerPreferenceResponse[]>(`/customers/${customerID}/preferences`);

        return customerPreferencesResponse;
    }

    async function getCustomerOrder(customerId: string, orderId: string, expand?: string): Promise<AxiosResponse<OrderMeshResponse>> {
        const getCallParams = {
            params: {
                channel,
            } as {channel: string, expand?: string},
        }
        
        if (expand) getCallParams.params.expand = expand;
        
        let orderResponse = await client.get<OrderMeshResponse>(`/customers/${customerId}/orders/${orderId}`, getCallParams);

        return orderResponse;
    }

    async function createCustomerPreference(customerID: string, preference: MeshCustomerPreferencePayload): Promise<AxiosResponse<CustomerPreferenceResponse>> {
        const customerPreferenceResponse = await client.post<CustomerPreferenceResponse>(
            `/customers/${customerID}/preferences`,
            preference,
        );
        return customerPreferenceResponse;
    }

    async function updateCustomerPreference(customerID: string, preferenceID: string, preference: MeshCustomerPreferencePayload): Promise<AxiosResponse<CustomerPreferenceResponse>> {
        const customerPreferenceResponse = await client.put<CustomerPreferenceResponse>(
            `/customers/${customerID}/preferences/${preferenceID}`,
            preference,
        );
        return customerPreferenceResponse;
    }

    async function create(username: string, password: string, firstName: string, lastName: string, extraCustomerData: CustomerUpdatePayload, isGuest = false): Promise<AxiosResponse<CustomerResponse>> {

        let createResponse = await client.post<CustomerResponse>(`/customers`, {
            email: username,
            password,
            firstName,
            lastName,
            isGuest,
            ...extraCustomerData,
        })

        return createResponse;
    }


    /**
     * Promote a guest account to a full account.
     * 
     * @param customerID the ID of the guest that we want to promote to a customer
     * @param password The password to set on the customer
     */
    async function promoteCustomer(customerID: string, password: string): Promise<AxiosResponse<CustomerResponse>> {
        let payload = {
            customerID: customerID,
            password: password,
        };
        
        let promotionResponse = await client.put<CustomerResponse>(`customers/${customerID}/promote`, payload);
    
        return promotionResponse;
    }

    /**
     * Save an address to Mesh.
     * @param customerID string
     * @param address object The address we want to save in Mesh
     */
    async function saveAddress(customerID: string, address: MeshAddress): Promise<AxiosResponse<AddressResponse>> {
        const queryParams = {
            params: {
                locale: address.locale,
                channel,
                excludeCAndCAddress:1,
            }
        }

        let addressResponse = await client.post<AddressResponse>(`/customers/${customerID}/addresses`, address, queryParams);
        return addressResponse;
    }

    /**
     * Update an address to Mesh.
     * @param customerID string
     * @param address object The address we want to save in Mesh
     */
    async function updateAddress(customerID: string, addressID: string, updatedAddress: MeshAddress): Promise<AxiosResponse<AddressResponse>> {
        const queryParams = {
            params: {
                locale: updatedAddress.locale,
                channel,
            }
        }
        let addressResponse = await client.put<AddressResponse>(`/customers/${customerID}/addresses/${addressID}`, updatedAddress, queryParams);

        return addressResponse;
    }

    /**
     * Update an address to Mesh.
     * @param customerID string
     * @param address object The address we want to save in Mesh
     */
    async function updateCustomer(customerID: string, updatedCustomer: CustomerUpdatePayload): Promise<AxiosResponse<CustomerResponse>> {
        const queryParams = {
            params: {
                channel,
                excludeCAndCAddress:1
            }
        }
        let customerResponse = await client.put<CustomerResponse>(`/customers/${customerID}`, updatedCustomer, queryParams);

        return customerResponse;
    }

    async function refreshOauthToken(): Promise<AxiosResponse<OauthResponse>> {
        let refreshResponse = await oauthClient.post<OauthResponse>(`/token`, {
            refresh_token: "",
            grant_type: "refresh_token",
            client_id: `${store}-${channel}`
        }).then((response) => {
            setOauthToken({
                access_token: response.data.access_token || '',
                refresh_token: response.data.refresh_token || '',
            });
            return response;
        })

        return refreshResponse;
    }

    

    async function oauthExpiredHandler(error: AxiosError) {
        const response = error.response ? error.response.data : null;
    
        if (response?.hint && response?.hint === `Token has expired`) {
            // refresh token 
            await refreshOauthToken()
            // update token in client 
            // retry initial request
            return axios.request(error.config);
        }

        return Promise.reject(error);
    }

    function setOauthToken(token: AuthToken) {
        const { access_token, refresh_token } = token;
        if (access_token) {
            client.defaults.headers['Authorization'] = `Bearer ${access_token}`;
            store2.set('auth.access_token', token.access_token);
        }
        if (refresh_token) store2.set('auth.refresh_token', token.refresh_token);
    }

    /**
     * Delete saved card for customer
     * @param customerID string
     * @param cardId string
     */
    async function deleteSavedCard(customerID: string, cardId: string) {
        const queryParams = {
            params: {
                channel,
                locale,
                displayadyencards:1
            }
        }
        let cardsResponse = await client.delete<DeleteCardResponse>(`/customers/${customerID}/paymentcard/${cardId}`, queryParams);

        return cardsResponse;
    }

    return {
        login,
        getCustomer,
        getCustomerPreferences,
        getCustomerOrder,
        createCustomerPreference,
        updateCustomerPreference,
        create,
        promoteCustomer,
        isRegistered,
        saveAddress,
        updateAddress,
        setOauthToken,
        updateCustomer,
        deleteSavedCard
    }
}

export type CustomersService = ReturnType<typeof customers>;

export default customers;