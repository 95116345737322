import merge from 'deepmerge';
import { CustomTheme } from '.';
import jdsportsukTheme from "./index";


const theme: CustomTheme = {
    config: {
        displayName: "JD Scotland Football Shop",
        showAddress1And2OnSameLine: true,
        faviconLogo: 'jdsportsuk/favicon.ico',
        headerLogo: '/Logo-Jdsfa-Navigation.png',
        headerLogoSize: {height: '32px'},
    },
};

const jdsfaTheme: CustomTheme = merge(jdsportsukTheme, theme);

export default jdsfaTheme;