
export type AppResponse<Data = any> = {
    status: number,
    data: Data,
    headers?: Object
}

export function createResponse<ReturnData = any>(status: number, data: ReturnData, headers?: Object): AppResponse<ReturnData> {
    return {
        status, 
        data, 
        headers
    }
}


