import merge from 'deepmerge';
import defaultTheme, { CustomTheme } from '.';
import { Security, TickFilledChoice, TickEmptyChoice } from '../components/Icon/index';
import './index';

const theme: CustomTheme = {
    config: {

        displayName: "size",

        titleFontWeight: 'bold',

        appBackground: 'white',

        stepFontSize: 'large',
        stepFontWeight: 'bold',
        stepSeparatorHorizontalMargin: '16px',
        stepSeparatorHeight: '16px',
        stepSeparatorHeightMobile: '1px',
        stepSeparatorColour: 'light-2',
        stepSeparatorMargins: {
            vertical: 'large',
            horizontal: 'medium'
        },

        headerLogo: '/Logo-Size-Navigation.png',
        headerLogoSize: {height: '35px'},
        headerShowLogo: true,
        headerBackgroundColor: '#252525',
        headerIconColor: 'white',
        headerHeight: '64px',
        headerTitleFontSize: 'medium',
        headerLogoContainerMinWidth: '75px',

        imageListBorder: true,

        summaryStepShowSeparator: true,
        summaryShowTotalsTitle: true,
        summaryTotalFontSize: 'large',
        summarySeparatorIsAbove: true,
        summarySeparatorMargins: {
            top: '14px',
            bottom: 'medium'
        },
        
        icons: {
            tickIcon: TickFilledChoice,
            untickIcon: TickEmptyChoice,
            securityIcon: Security,
        },

        fontSrc: '/fonts/proxima-nova.css',
        faviconLogo: 'size/favicon.ico'
    },

    global: {
        font: {
            family: 'proxima-nova-medium, sans-serif',
        },
        colors: {
            brand: 'black',
        },
    },

    button: {
        border: {
            radius: '0px'
        },
        extend: 'height: 48px; font-weight: bold',
    },

    checkBox: {
        check: {
            radius: '0px',
            thickness: '1px',
            extend: (props) => {
                return props.checked ? `background: white; border-color: #e7e8e9; box-shadow: none !important;` : `box-shadow: none !important;`;
            }
        },
        icons: {
            checked: TickFilledChoice,
        }
    },

    radioButton: {
        font: {
            weight: 'bold',
        }
    },

    tab: {
        border: {
            active: {
                color: 'brand'
            },
            hover: {
                color: 'brand'
            }
        },
    },
};

const sizeTheme: CustomTheme = merge(defaultTheme, theme);

export default sizeTheme;
