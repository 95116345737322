import React from 'react';
import { Box, Text, Anchor } from 'grommet';
import {
    KlarnaLogo,
    Maestro,
    Mastercard,
    Visa,
    Amex,
    ApplePay,
    PayPal,
    PayPalPayInThree,
    Afterpay,
    Clearpay,
    Openpay,
    Laybuy,
    Giropay,
    Ideal,
    AliPay,
    GooglePay,
    Zippay,
    Fpx,
    WeChat,
    Shopback,
    Touch,
    Maybank,
    Boost,
    Trustly,
    Grabpay,
    UnionPay,
    KlarnaDebitRisk,
} from '../../components/Icon/index';
import { PaymentMethod } from '../../modules/billing/types';
import { isUnionPayEnabled } from '../../modules/billing';
import { PayLaterLinks} from "../../modules/config/types";
import { getCart } from "../../modules/cart/index";
import store from  '../../store';
import PaymentMethodSelectorAdyenCardItem from '../../components/PaymentMethodSelectorAdyenCardItem/PaymentMethodSelectorAdyenCardItem';
import { translate } from "../../modules/translation";

/**
 *
 * @param method : PaymentMethod
 * @param payLaterDetailsLinks : PayLaterLinks
 * @returns Returns the paymentMethod icon and description for each paymentMethod method
 */
export function getPaymentMethodRenderedExtras(method: PaymentMethod, payLaterDetailsLinks?: PayLaterLinks): PaymentIconData{
    const storeName = store.getState().config.store;
    const hideKlarnaLinks = store.getState().config.hide_klarna_disclosure_links;
    const getPayInThreeText = store.getState().config.payinthree_disclosure_text;
    const translation = store.getState().translation;
    const klarnaCreditText = (storeName === 'oipolloigb' || storeName === 'wellgoshgb') ? <Text size="small">Pay <Text weight='bold' size="small">monthly</Text>in <Text weight='bold' size="small">3 interest-free</Text>instalments or pay 30 days after delivery. <Text weight='bold' size="small">18+, T&Cs Apply, Credit Subject To Status.</Text> </Text> :
    <Text size="small">Pay <Text weight='bold' size="small">monthly</Text>in <Text weight='bold' size="small">3 interest-free</Text>instalments or pay 30 days after delivery. 18+ terms apply </Text>;
    const klarnaCreditDisclosureText = <Text size="small">You must be 18+ to use this credit product. Missed payments are visible to lenders and may affect your ability to obtain credit, including with Klarna. By continuing, I accept the </Text>;
    const clearpayDisclosureText = <Text size="small">Clearpay lends you a fixed amount of credit to allow you to pay for your purchase over 4 instalments. Late fees chargeable. T&Cs and eligibility criteria apply  </Text>;
    const klarnaTc = store.getState().config.pay_later_links.KLARNA_TC;
    const klarnaPayInThree = store.getState().config.pay_later_links.KLARNA_PAYINTHREE;
    const unionPayEnabled = isUnionPayEnabled(store.getState().billing);
    const strings = {
        paymentTerms: translate(translation, 'paymentTerms','18+ terms Apply'),
        clearpayDescription : translate(translation, 'clearpayDescription','<Text size="small">Pay <Text weight="bold" size="small">every 2 weeks</Text>in <Text weight="bold" size="small">4 interest-free </Text>instalments. 18+ terms apply. </Text>'),
        clearpayDisclosureText: translate(translation, 'clearpayDisclosureText', clearpayDisclosureText as unknown as string), 
        klarnaCreditDescription: translate(translation, 'klarna_credit_description', klarnaCreditText as unknown as string),
        klarnaCreditDisclosureText : translate(translation, 'klarna_credit_disclosure', klarnaCreditDisclosureText as unknown as string), 
        klarnaDisclosureLink : translate(translation, 'pay_later_terms', 'Pay Later Terms'),
        openPayDescription: translate(translation,'openPayDescription','<Text size="small">Pay <Text weight="bold" size="small">monthly</Text>in <Text weight="bold" size="small">4 interest-free</Text>instalments. No interest. Ever. 18+ terms apply </Text>'),
        laybuyDescription: translate(translation,'laybuyDescription','<Text size="small">Pay <Text weight="bold" size="small">weekly</Text>in <Text weight="bold" size="small">6 interest-free</Text>instalments. 18+ terms apply </Text>'),
        laybuyDisclosureLink: translate(translation,'laybuyDisclosureLink','<Text size="small">Laybuy is an interest-free credit product allowing you to pay for your purchase over 6 equal weekly instalments. Missed payments will show on your credit file potentially making credit harder to obtain and will result in late fees being added to the debt.</Text>'),
        zipPayDescription: translate(translation,'zipPayDescription','<Text size="small">Pay <Text weight="bold" size="small">every 2 weeks</Text> in <Text weight="bold" size="small">4 interest-free</Text>instalments. 18+ terms apply.</Text>'),
        applePayDescription: translate(translation,'applePayDescription','<Text weight="bold">3 payments</Text>'),
        payInTerms: translate(translation, 'pay_in_terms', 'Pay in 3 terms'),
        learnMore: translate(translation, 'learnMore', 'Learn More')
    }
    function makeLink(href?: string, label?: string): React.ReactNode | undefined {
        if (href) {
            return <Text size="small"><Anchor href={href} target="_blank">{label}</Anchor></Text>;
        }
        else return undefined;
    }

    const clearPayTCLink = payLaterDetailsLinks?.CLEARPAY_TC_LINK ?? "https://www.clearpay.co.uk/en-GB/terms-of-service";
    const clearPayTCLabel = payLaterDetailsLinks?.CLEARPAY_TC_LABEL ?? "clearpay.co.uk/terms";
    const clearpayInformation = makeLink(clearPayTCLink, clearPayTCLabel);

    const clearpayDisclosureLinkText = payLaterDetailsLinks?.CLEARPAY_TC_LABEL ? 
                                        <Text>{clearpayInformation}</Text> : 
                                        <Text>[{clearpayInformation}]</Text>;

    function makeLinkWithText(text:string, href: string, label: string ): React.ReactNode {
        if (label && text) {
            const link  = <Anchor label={label} href={href} target="_blank"></Anchor>;
            const splitText = text.split(label);
            return <>{splitText[0]}{link}{splitText[1]}</>;
        }
    }
    
    const klarnaLinkUpdate = () => (
        <Text>
            {klarnaTc && klarnaPayInThree 
                ? <span>[{makeLink(klarnaTc, strings.klarnaDisclosureLink)}] [{makeLink(klarnaPayInThree, strings.payInTerms)}]</span> 
                : klarnaPayInThree 
                ? <span>[{makeLink(klarnaPayInThree, strings.payInTerms)}]</span> 
                : klarnaTc 
                ? <span>[{makeLink(klarnaTc, strings.klarnaDisclosureLink)}]</span> 
                : <span>
                    [{makeLink("https://cdn.klarna.com/1.0/shared/content/legal/terms/0/en_gb/pay_after_delivery/", strings.klarnaDisclosureLink)}] 
                    [{makeLink("https://cdn.klarna.com/1.0/shared/content/legal/terms/0/en_gb/slice_it_card/", strings.payInTerms)}
                  </span>
                }
        </Text>
    );
    let hostedPaymentType = null;
    if(method?.additionalData?.hostedPaymentGateway !== "MolPay") {
        hostedPaymentType = <PaymentMethodSelectorAdyenCardItem />;
    }
    switch (method.id) {
        case "CARD":
            return {
                icon: <Box direction="row" border={"between"} gap="xxsmall" responsive={false} wrap={true}>
                        <Visa/>
                        <Mastercard/>
                        <Maestro/>
                        <Amex/>
                        {unionPayEnabled && <UnionPay/>}
                    </Box>,
                description: hostedPaymentType
            }

        case "KLARNA CREDIT":
            return {
                icon: <KlarnaLogo/>,
                description: strings.klarnaCreditDescription,
                link: makeLink(payLaterDetailsLinks?.KLARNA_CREDIT, strings.learnMore),
                disclosure: hideKlarnaLinks ? '' : strings.klarnaCreditDisclosureText,
                disclosureLink: hideKlarnaLinks ? '' :  klarnaLinkUpdate()
            }
        case "KLARNA_EXPRESS":
            return {
                icon: <KlarnaLogo />,
                description: <Text size="small">{strings.paymentTerms}</Text>,
                link: makeLink(payLaterDetailsLinks?.KLARNA_CREDIT, strings.learnMore)
            } 
        case "CLEARPAY_EXPRESS":
            return {
                icon: <Clearpay/>,
                description: <Text size="small">{strings.paymentTerms}</Text>,
                link: makeLink(payLaterDetailsLinks?.CLEARPAY, strings.learnMore)
            }
        case "AFTERPAY_EXPRESS":
            return {
                icon: <Afterpay/>
            }    
        case "PAYINTHREE_EXPRESS":
            return {
                icon: <PayPal/>,
                description: <Text size="small">{strings.paymentTerms}</Text>,
                link: ''
            }                
        case"PAYPAL":
        case "PAYPAL CREDIT":
        case "PAYPAL_EXPRESS":   
            return {
                icon: <PayPal/>,
            }
        case "GOOGLE PAY":
        case "GOOGLEPAY EXPRESS":    
            return {
                icon: <GooglePay width="50" height="35"/>,
            }

        case "GIROPAY":
            return {
                icon: <Giropay width="75" height="35"/>,
            }

        case "FPX":
            return {
                    icon: <Fpx width="40" height="25"/>,
            }
                    
        case "PAYINTHREE":
            const cart = getCart(store.getState().cart);
            const total = cart.total.amount;
            return {
                icon: <PayPalPayInThree/>,
                description: <div 
                    data-pp-message 
                    data-pp-amount={total} 
                    data-pp-style-layout='text' 
                    data-pp-style-logo-type='none' 
                    data-pp-style-text-color='monochrome'/>, 
                link: makeLink(payLaterDetailsLinks?.PAYINTHREE, strings.learnMore),
                disclosure: getPayInThreeText.required ? 
                    makeLinkWithText(getPayInThreeText.value, getPayInThreeText.link, getPayInThreeText.linkLabel) : ''
            }

        case "APPLEPAY CREDIT":
            return {
                icon: <ApplePay/>,
                description:strings.applePayDescription ,
                link: makeLink(payLaterDetailsLinks?.APPLEPAY_CREDIT, strings.learnMore)
            }

        case "APPLE PAY":
            return {
                icon: <ApplePay/>,
            }

        case "AFTERPAY":
            return {
                icon: <Afterpay/>,
                description: strings.clearpayDescription,
                link: makeLink(payLaterDetailsLinks?.AFTERPAY, strings.learnMore),
            }
        
        case "CLEARPAY":
            return {
                icon: <Clearpay/>,
                description: strings.clearpayDescription,
                link: makeLink(payLaterDetailsLinks?.CLEARPAY, strings.learnMore),
                disclosure: strings.clearpayDisclosureText,
                disclosureLink: clearpayDisclosureLinkText,
                extraDisclosure: payLaterDetailsLinks?.CLEARPAY_LATE_FEE
            }

        case "OPENPAY":
            return {
                icon: <Openpay/>,
                description: strings.openPayDescription
            }
        case "SHOPBACK":
            return {
                icon: <Shopback />,
                description: '',
                link: ''
            }                
            
        case "LAYBUY":
            return {
                icon: <Laybuy/>,
                description: strings.laybuyDescription,
                link: makeLink(payLaterDetailsLinks?.LAYBUY, strings.learnMore),
                disclosure: strings.laybuyDisclosureLink,
            }
        case "MAYBANK_QR_PAY":
            return {
                icon: <Maybank/>,
            }
        case "ZIPPAY":
            return {
                icon: <Zippay/>,
                description: strings.zipPayDescription,
                link: makeLink(payLaterDetailsLinks?.ZIP, strings.learnMore),
            }
        case "SOFORT":
            return {
                icon: <KlarnaDebitRisk/>,
            }

        case "IDEAL":
            return {
                icon: <Ideal/>,
            }
        case "ALIPAY":
            return {
                icon: <AliPay/>,
            }
        case "BRAINTREE":
            return {
                icon: <Box direction="row" border={"between"} gap="xxsmall" responsive={false} wrap={true}>
                        <Visa/>
                        <Mastercard/>
                        <Maestro/>
                        <Amex/>
                    </Box>,
            }
        case "RELY":
            return {
                icon: '',
                description: '',
                link: ''
            }
        case "CREDITGUARD":
            return {
                icon: '',
                description: '',
                link: ''
            }
        case "WECHAT":
            return {
                icon: <WeChat/>,
            }
        case "TOUCHN_GO_EWALLET":
            return {
                icon: <Touch />
            }
        case "BOOST":
            return {
                icon: <Boost width="40" height="25"/>,
            }
        case "GRABPAY":
            return {
                icon: <Grabpay />
            }

        case "TRUSTLY":
            return {
                icon: <Trustly/>,
            }    

        default:
            return { };
    }
}

/**
 *
 * @param brandName : card brand name
 * @returns Returns an icon depending on the value of card type
 */
export function getIconForCardType(brandName: string): CardIconData{
    switch (brandName) {
        case 'visa':
        case 'visaproprietary':
        case 'visagold':
        case 'visacorporate':
        case 'visahipotecario':
        case 'visaalphabankbonus':
        case 'vpay':
        case 'visaclassic':
        case 'visacredit':
        case 'visaplatinum':
        case 'visapurchasing':
        case 'visacommercialcredit':
        case 'visadankort':
        case 'visabusiness':
        case 'visasignature':
        case 'visadebit':
        case 'electron':
            return {
                icon: <Visa/>,
                name: 'Visa'
            }
        case 'mc':
        case 'mccredit':
        case 'mccorporate':
        case 'mcalphabankbonus':
        case 'mccommercialcredit':
        case 'mcatm':
        case 'mcpro':
            return {
                icon: <Mastercard/>,
                name: 'MasterCard'
            }
        case 'maestrouk':
        case 'maestro':
            return {
                icon:  <Maestro/>,
                name: 'Maestro'
            }
        case 'amex':
            return {
                icon: <Amex/>,
                name: 'Amex'
            }
        case 'cup':
            return {
                icon: <UnionPay/>,
                name: 'Unionpay'
            }
        default:
            return {}
    }
}

type PaymentIconData = {
    icon?: React.ReactNode,
    description?: React.ReactNode,
    link?: React.ReactNode,
    disclosure?: React.ReactNode,
    disclosureLink?: React.ReactNode,
    extraDisclosure?: React.ReactNode,
};

type CardIconData = {
    icon?: React.ReactNode,
    name?: string
};