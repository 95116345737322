import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Text } from 'grommet';
import { ThemeContext } from 'styled-components';
import { CustomTheme } from '../../theme/index';
import { RootState } from '../../store/reducers';
import { persistor } from '../../store';
import { AppThunkDispatch } from '../../modules/thunk';
import { translate } from '../../modules/translation';
import { getSession, putSession } from '../../modules/session';
import Step from '../Step/Step';

function ServiceAgentConfirmation() {
    const theme: CustomTheme = useContext(ThemeContext);
    const dispatch: AppThunkDispatch = useDispatch();
    const translation = useSelector((state: RootState) => state.translation);
    const sessionState = useSelector((state: RootState) => state.session);

    const strings = {
        serviceAgentConfirmtion: translate(translation, 'serviceAgentConfirmtion', 'Service Agent Confirmation'),
        paymentCompletedByRepresentative: translate(translation, 'paymentCompletedByRepresentative', 'Payment will be completed externally by one of our customer service representatives.'),
        thanksForOrder: translate(translation, 'thanksForOrder', 'Thank you for your order.'),
    };

    useEffect(() => {
        // We remove the saved state from the storage.
        persistor.purge();

        // We flush this cart from the website.
        const session = getSession(sessionState);
        dispatch(putSession({
            ...session,
            cartID: '',
            commands: {
                ...session.commands,
                invalidateCartCache: true,
            }
        }));
        // eslint-disable-next-line
    }, []);

    return (
        <Step>
            <Box direction="column">
                <Text
                    size="xlarge"
                    weight={theme?.config?.titleFontWeight}
                    style={{
                        textTransform: theme?.config?.stepTextTransform ?? 'unset',
                    }}
                >
                    {strings.serviceAgentConfirmtion}
                </Text>
                <Text weight={theme?.config?.titleFontWeight}>{strings.thanksForOrder}</Text>
                <Text>{strings.paymentCompletedByRepresentative}</Text>
            </Box>
        </Step>
    );
}

export default ServiceAgentConfirmation;
