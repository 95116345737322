// constant types
export const UPDATE_PHRASES = 'checkout/order/UPDATE_PHRASES';
export const UPDATE_TRANSLATION_MODULE = 'checkout/order/UPDATE_TRANSLATION_MODULE';

// action types
interface UpdatePhrases {
    type: typeof UPDATE_PHRASES,
    payload: Phrases,
}

interface UpdateTranslationModule {
    type: typeof UPDATE_TRANSLATION_MODULE,
    payload: Translation,
}

export type TranslationActionTypes = UpdatePhrases | UpdateTranslationModule;

// other
export type Translation = {
    phrases: Phrases,
};

export type Phrases = {
    [key: string]: string,
};
