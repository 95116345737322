import { ValidationRule } from './types';
import isMobilePhone from 'validator/lib/isMobilePhone';
import {MobilePhoneLocale} from '../../../node_modules/@types/validator/lib/isMobilePhone';
import isEmail from 'validator/lib/isEmail';
import isNumeric from 'validator/lib/isNumeric';
import isLength from 'validator/lib/isLength';
import isAlpha from 'validator/lib/isAlpha';
import isPostalCode from 'validator/lib/isPostalCode';
import validator from 'validator'
import store from '../../store';
import { getLocalisation } from '../../modules/config';
import  { translate } from '../../modules/translation';

const DEFAULT_LOCALE: validator.AlphaLocale = "en-GB";
// common rules
export function maxLength30Rule(fieldName: string): ValidationRule{ 
   return { name: 'max', params: { 'length': 30 }, fieldName }
}
export function passwordRule(fieldName: string): ValidationRule {
   return { name: 'password', fieldName }
};
export function emailRule(fieldName: string): ValidationRule {
   return { name:'email', fieldName }
 };
export const nameRule: ValidationRule = { name: 'nameField'}
export function addressRule(fieldName: string): ValidationRule {
    return { name: 'addressField', fieldName }
}
export function postcodeRule (locale: string,fieldName :string): ValidationRule {
    return { name: 'postcode', params: { postCodeLocale: locale }, fieldName }
}
export function maxLengthRule(length: number,fieldName: string): ValidationRule {
    return { name: 'max', params: { length },fieldName }
}
export function requiredRule(fieldName: string): ValidationRule {
    return { name: 'required', fieldName }
}
export function validationOverrideRule(isValid: boolean, message = ''): ValidationRule {
    return { name: 'override', params: { isValid, message } };
}

function capitaliseFirstLetter(message: string) {
    return message.charAt(0).toUpperCase() + message.slice(1);
}



function required(value: string, fieldName?: string) {
    if (!isLength(value.trim(), { min: 1 })) {
        const translation = store.getState().translation;
        const messageSubject = fieldName ? capitaliseFirstLetter(fieldName) : 'This field';
        const message = translate(translation, 'validation_fieldRequired', `${messageSubject} is required.`, {field: messageSubject});
        return { isValid: false, message };
    }
    return { isValid: true, message: '' };
}

function email(value: string, fieldName?: string) {
   const messageSubject = fieldName ? fieldName : "This field";
    if (!isEmail(value.trim())) {
        const translation = store.getState().translation;
        const message = translate(translation, 'validation_fieldInvalid', `${messageSubject} is invalid.`, {field:messageSubject})
        return { isValid: false, message };
    }
    return { isValid: true, message: '' };
}

function phone(value: string, fieldName?: string) {
    const localisation = getLocalisation(store.getState().config);
    const countryCode = localisation.countryCode;
    let charNumberLimit = { min: 8, max: 14};
    if(countryCode === 'DE') {
        charNumberLimit = { min: 9, max: 13};
    }
    const translation = store.getState().translation;
    const messageSubject = fieldName ? capitaliseFirstLetter(fieldName) : 'Contact number';
    const message = translate(translation, 'validation_fieldInvalid', `${messageSubject} is invalid.`, {field: messageSubject});
    if(countryCode === 'MY') {
        if (!/^(\+?6?0)[0-9]-*[0-9]{7,9}$/.test(value)) { 
            return { isValid: false, message };
        }
    }
    else { 
        if(!(isMobilePhone(value, localisation.language.code as MobilePhoneLocale) || (isNumeric(value) && isLength(value, charNumberLimit)))) {
            return { isValid: false, message };
        }   
    }
    return { isValid: true, message: '' };
}

function pin(value: string, fieldName?: string) {
    if (!isLength(value.trim(), { min: 4, max: 4 })) {
        const translation = store.getState().translation;
        const messageSubject = fieldName ? capitaliseFirstLetter(fieldName) : '4-digit pin';
        const message = translate(translation, 'validation_fieldInvalid', `${messageSubject} is invalid.`, {field: messageSubject});
        return { isValid: false, message };
    }
    return { isValid: true, message: '' };
}

function postcode(value: string, fieldName?: string, postCodeLocale?: string) {
    // isPostalCode() throws an error if the locale is not part of a specific list of countries it recognises.
    const isLocaleSupported = (validator.isPostalCodeLocales as string[]).includes(postCodeLocale ?? '');

    if (isLocaleSupported) {
        if(postCodeLocale === 'IE') {
            if (!isLength(value.trim(), { min: 3, max: 7 })) {
                return range(value, 3, 7, fieldName);
            }
        } else if (postCodeLocale === 'GB') {
            // GB postcode regex rule overiding
            if (value.length > 3) {
                if (!(isPostalCode(value, postCodeLocale as validator.PostalCodeLocale))) {
                    return postcodeError(fieldName);
                }
            } else {
                return postcodeError(fieldName);
            }
        } else if (!(isPostalCode(value, postCodeLocale as validator.PostalCodeLocale))) {
            return postcodeError(fieldName);
        }
    }
    else {
        if (!value) {
            const messageSubject = fieldName ? capitaliseFirstLetter(fieldName) : 'Postcode';
            const translation = store.getState().translation;
            const message = translate(translation, 'validation_fieldRequired', `${messageSubject} is required.`, {field: messageSubject});
            return { isValid: false, message };
        }
    }
    return { isValid: true, message: '' };
}

function postcodeError(fieldName?: string) {
    const translation = store.getState().translation;
    const messageSubject = fieldName ? capitaliseFirstLetter(fieldName) : 'Postcode';
    const message = translate(translation, 'validation_fieldInvalid', `${messageSubject} is invalid.`, {field: messageSubject});
    return { isValid: false, message };
}

function password(value: string, fieldName?: string) {
    const {
        symbolsInPassword,
        restrictSameCharacter3TimesInPassword,
    } = getLocalisation(store.getState().config);
    const translation = store.getState().translation;
    const messageSubject = fieldName ? capitaliseFirstLetter(fieldName) : 'This field';
        
    if (!/^(.*[A-Z a-z].*)$/.test(value)) {
       const message = translate(translation, 'validation_requiresletter', `${messageSubject} requires at least 1 letter.`, {field: messageSubject});
       return { isValid: false, message };
    }
    if (symbolsInPassword && !/^(.*[@#*%&!^$`~,.<>;':"/\u005b\u005d|{}()=_+-].*)$/.test(value)) {
        const message = translate(translation, 'validation_requiressymbol', `${messageSubject} requires at least 1 symbol.`, {field: messageSubject});
        return { isValid: false, message };
    }
    else if (!symbolsInPassword && /^(.*[@#*%&!^$`~,.<>;':"/\u005b\u005d|{}()=_+-].*)$/.test(value)) {
        const message = translate(translation, 'validation_nosymbol', `${messageSubject} must have no symbol.`, {field: messageSubject});
        return { isValid: false, message };
    }
    if (!/^(.*\d.*)$/.test(value)) {
        const message = translate(translation, 'validation_requiresnumber', `${messageSubject} requires at least 1 number.`, {field: messageSubject});
        return { isValid: false, message };
    }
    if (restrictSameCharacter3TimesInPassword && /(.)\1{2,}/.test(value)) {
        const message = translate(translation, 'validation_noconsecutiveletter', `${messageSubject} must not have the same character 3 times consecutively.`, {field: messageSubject});
        return { isValid: false, message: message };
    }
    return { isValid: true, message: '' };
}

function nameField(value: string, fieldName?: string) {
    // This rule is deactivated until more research is done on what character to allow/disallow

    //\u0E00-\u0E7F - thai characters
    //\u0590-\u05FF - hebrew characters
    if (!/^[.'/\-a-zA-ZÀ-ÿ\u0E00-\u0E7F \u0590-\u05FF]*$/.test(value)) {
        // return { isValid: false, message: setMessage('This field', 'is invalid.', fieldName) };
    }
    return { isValid: true, message: '' };
}

function addressField(value: string, fieldName?: string) {
    // This rule is deactivated until more research is done on what character to allow/disallow

    if (!/^[.&,\-_'/a-zA-ZÀ-ÿ0-9\u0E00-\u0E7F \u0590-\u05FF]*$/.test(value)) {
        // return { isValid: false, message: setMessage('This field', 'is invalid.', fieldName) };
    }
    return { isValid: true, message: '' };
}

function number(value: string, fieldName?: string) {
    if (!isNumeric(value)) {
        const translation = store.getState().translation;
        const messageSubject = fieldName ? capitaliseFirstLetter(fieldName) : 'This field';
        const message = translate(translation, 'validation_fieldInvalid', `${messageSubject} is invalid.`, {field: messageSubject});
        return { isValid: false, message };
    }
    return { isValid: true, message: '' };
}

function minimum(value: string, length: number, fieldName?: string) {
    if (!isLength(value.trim(), { min: length })) {
        const translation = store.getState().translation;
        const messageSubject = fieldName ? capitaliseFirstLetter(fieldName) : 'This field';
        const message = translate(translation,
            'validation_fieldTooSmall',
            `${messageSubject} should be at least ${length} characters long.`,
            {field: messageSubject, number: length},
        );
        return { isValid: false, message };
    }
    return { isValid: true, message: '' };
}

function maximum(value: string, length: number, fieldName?: string) {
    if (!isLength(value, { max: length })) {
        const translation = store.getState().translation;
        const messageSubject = fieldName ? capitaliseFirstLetter(fieldName) : 'This field';
        const message = translate(translation,
            'validation_fieldTooBig',
            `${messageSubject} should be at most ${length} characters long.`,
            {field: messageSubject, number: length},
        );
        return { isValid: false, message };
    }
    return { isValid: true, message: '' };
}

function range(value: string, min: number, max: number, fieldName?: string) {
    if (!isLength(value.trim(), { min, max })) {
        const translation = store.getState().translation;
        const messageSubject = fieldName ? capitaliseFirstLetter(fieldName) : 'This field';
        const message = translate(translation,
            'validation_fieldOutsideRange',
            `${messageSubject} should be between ${min} and ${max} characters long.`,
            {field: messageSubject, min, max},
        );
        return { isValid: false, message };
    }
    return { isValid: true, message: '' };
}

export function getError(value: string, rules?: ValidationRule[]) {
    if (rules !== undefined) {
        for (let rule of rules) {
            if (rule.name === 'required' && !required(value).isValid) {
                return required(value, rule.fieldName);
            }

            if (rule.name === 'password' && !password(value).isValid) {
                return password(value, rule.fieldName);
            }

            if (rule.name === 'nameField' && !nameField(value).isValid) {
                return nameField(value, rule.fieldName);
            }

            if (rule.name === 'addressField' && !addressField(value).isValid) {
                return addressField(value, rule.fieldName);
            }

            if (rule.name === 'email' && !email(value).isValid) {
                return email(value, rule.fieldName);
            }
            
            if (rule.name === 'number' && !number(value).isValid) {
                return number(value, rule.fieldName);
            }

            if (rule.name === 'phone' && !phone(value.replace(/\s/g, "")).isValid) {
                return phone(value.replace(/\s/g, ""), rule.fieldName);
            }

            if (rule.name === 'pin' && !pin(value).isValid) {
                return pin(value, rule.fieldName);
            }

            if (rule.name === 'postcode' && rule.params?.postCodeLocale) {
                return postcode(value, rule.fieldName, rule.params.postCodeLocale);
            }

            if (rule.name === 'min' && rule.params && rule.params.length && !minimum(value, rule.params.length).isValid) {
                return minimum(value, rule.params.length, rule.fieldName);
            }

            if (rule.name === 'max' && rule.params && rule.params.length && !maximum(value, rule.params.length).isValid) {
                return maximum(value, rule.params.length, rule.fieldName);
            }

            if (rule.name === 'range' && rule.params && rule.params.min && !range(value, rule.params.min, rule.params.max).isValid) {
                return range(value, rule.params.min, rule.params.max, rule.fieldName);
            }

            if (rule.name === 'isAlpha') {
                return isAlpha(value, rule.locale || DEFAULT_LOCALE) ? { isValid: true, message: '' } : { isValid: false, message: `${rule.fieldName !== undefined ? rule.fieldName : 'This field'} is invalid, it should only contain letters.` }
            }

            if (rule.name === 'override') {
                return {
                    isValid: rule.params.isValid,
                    message: rule.params.message,
                };
            }
        }
    }

    return { isValid: true, message: '' };
}


