import axios, { AxiosInstance } from 'axios';
import { LanguageClientConfig } from './types';

/**
 * @description Creates an Axios instance with some defaults. This can be a single
 * place to set up api keys, global params and base URLs
 * @param config
 */
export function createLanguageClient(config: LanguageClientConfig): AxiosInstance {
    const { baseURL, timeout, headers, globalParams } = config;
    const client = axios.create({
        baseURL,
        timeout,
        headers,
        params: globalParams
    });

    client.interceptors.request.use(config => {
        config.params = {
            // add your default ones
            ...globalParams,
            // spread the requests' params
            ...config.params,
        };
        return config;
    });

    return client;
}
