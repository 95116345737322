import merge from 'deepmerge';
import { CustomTheme } from '.';
import sizeTheme from "./size";

const theme: CustomTheme = {
    config: {
        displayName: "Size IT",
        headerLogoSize: {height: '30px'},
    },
    //@ts-ignore 
    text: {
        xsmall: {
            'size': '12px',
        },
        small: {
            'size': '14px',
        },
        medium: {
            'size': '16px',
        },
        large: {
            'size': '18px',
        },
    },
};

const sizeitTheme: CustomTheme = merge(sizeTheme, theme);

export default sizeitTheme;