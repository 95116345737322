import React, { useState, useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, Text } from 'grommet';
import { ThemeContext } from 'styled-components';
import { RootState } from '../../store/reducers';
import { CustomTheme } from '../../theme/index';
import { AppThunkDispatch } from '../../modules/thunk';
import { getDialogDetails, clearDialog, getDialogCode } from '../../modules/notification';
import Modal from '../Modal/Modal';
import LoadingIcon from '../LoadingIcon/LoadingIcon';
import { updateNotification, getDialogExtraData } from '../../modules/notification';
import { DialogDetails } from '../../modules/notification/types';

/**
 * Modal dialog box
 */
function Dialog() {
    const fullState = useSelector((state: RootState) => state);
    const notificationState = useSelector((state: RootState) => state.notification);
    const dispatch: AppThunkDispatch = useDispatch();

    const [actionInProgress, setActionInProgress] = useState(false);
    const [dialogDetails, setDialogDetails] = useState<DialogDetails|undefined>(undefined);

    const dialogCode = getDialogCode(notificationState);

    const theme: CustomTheme = useContext(ThemeContext);
    const backgroundColor = theme?.config?.dialogBackgroundColor ?? 'white';

    useEffect(() => {
        setDialogDetails(getDialogDetails(fullState, dispatch));
    // eslint-disable-next-line
    }, [fullState.notification.dialogCode]);

    function closeDialog() {
        if (dialogCode === 'remove_georestricted_products_dialog') {
            const dialogExtraData = getDialogExtraData(fullState.notification);
            dispatch(updateNotification('remove_georestricted_products_notification', {
                removeDropshipProducts: dialogExtraData?.removeDropshipProducts,
                removeRestrictedProducts: dialogExtraData?.removeRestrictedProducts,
                skusToRemove: dialogExtraData?.skusToRemove,
            }));
        }
        dispatch(clearDialog());
    }

    // If no dialog box needed nothing is rendered.
    if (!dialogDetails) return <></>;

    return (
        <Modal
            onClose={dialogDetails.showCloseIcon ? closeDialog : undefined}
            showCloseIcon={dialogDetails.showCloseIcon}
        >
            <Box
                background={backgroundColor}
                round="small"
                pad={{horizontal: 'medium', vertical: 'large'}}
            >
                <Text weight="bold" margin={{bottom: 'medium'}}>{dialogDetails.title}</Text>
                <Box>
                    {dialogDetails.body}
                </Box>
                <Box
                    direction="row"
                    gap="small"
                    margin={{top: "medium"}}
                >
                    {dialogDetails.buttons.map((button, index) => (
                        <Button
                            key={index}
                            type="button"
                            label={actionInProgress ? <LoadingIcon color="black" /> : button.label}
                            onClick={async() => {
                                setActionInProgress(true);
                                await button.onClick();
                                setActionInProgress(false);
                            }}
                            fill='horizontal'
                        />
                    ))}
                </Box>
            </Box>
        </Modal>
    );
}

export default Dialog;
