import { Cart, PaymentServiceCart } from '../../modules/cart/types';
import { Customer, Address } from '../../modules/customer/types';
import { Store } from '../../modules/delivery/types';
import { AppResponse } from '../response';
import adaptor from '../adaptors';
import { PaymentResponse } from '../mesh-sdk/payments';
import { HostedPaymentResponse } from '../mesh-sdk/hostedPayment';
import { ServiceResponse, MeshCreateResponse, PrePaymentCheckResponse } from '../payment-sdk/models';
import { PaymentMethodModule } from './types';
import { RootState } from '../../store/reducers';
import { PaymentProvider, PaymentMethodsResponse } from '../adaptors/meshPayments';

export interface PaymentMeshService {
    completePayment: (paymentID: string, locale: string) => Promise<AppResponse<PaymentResponse>>,
    getMeshPayment: (paymentID: string, locale: string) => Promise<AppResponse<PaymentResponse>>,
}

export interface HostedPaymentService {
    initHostedPaypalPayment: (
        cartId: string,
        type: string,
        locale:string,
        terminals: {
            "timeoutURL": string,
            "failureURL": string,
            "successURL": string
        }) => Promise<AppResponse<HostedPaymentResponse>>,
    authoriseHostedPayment: (paymentId: string, hostedPaymentPageResult: string) => Promise<AppResponse<HostedPaymentResponse>>,
}

export interface PaymentMeshPaymentsService {

    /**
     * Gets the available payment methods from the payment gateway.
     * @param provider eg. adyen
     * @param cart Used to make the cart object expected by the payment service
     * @param customer Used to make the cart object expected by the payment service
     * @param billingAddress Used to make the cart object expected by the payment service
     * @param deliveryAddress Used to make the cart object expected by the payment service
     */
    getAvailableMethodsForCart: <T extends PaymentProvider>(provider: T, cart: Cart, customer: Customer, billingAddress: Address, deliveryAddress: Address, locale: string) => Promise<AppResponse<PaymentMethodsResponse<T>>>,

    /**
     * Initialise a payment with the external payment service
     * @param isExpress eg. CARD, PAYPAL_EXPRESS
     * @param provider eg. adyen
     * @param method eg. 'scheme' for Adyen payment cards
     * @param cart Used to make the cart object expected by the payment service
     * @param initPayload Provider-specific payload needed to initiate a payment (eg. encrypted card details, etc.)
     */
    initPayment: (isExpress: boolean, provider: PaymentProvider, method: string, merchantReference: string, cart: Cart, customer: Customer, billingAddress: Address, deliveryAddress: Address, selectedStore: Store|null, locale: string, initPayload: string) => Promise<AppResponse<ServiceResponse>>,
    /**
     * Creates an order from a successfully initiated payment
     * @param provider eg. adyen
     * @param method eg. scheme
     * @param gatewayReference If using Adyen, this should be the pspReference
     * @param initResultCode eg. 'Authorised'
     * @param transactionID ID obtained after initiating the payment with initPayment
     */
    finalisePayment: (provider: PaymentProvider, method: string, gatewayReference: string, initResultCode: string, transactionID: string) => Promise<AppResponse<MeshCreateResponse>>,

    /**
     * Update a payment to the payment service, typically after a 3DSecure redirect action.
     * @param provider eg. 'adyen', etc.
     * @param method eg. 'scheme', etc.
     * @param updatePayload Stringified object to send to the provider
     * @param transactionID ID obtained after initiating the payment with initPayment
     * @param type Should be '3DS' for a 3DS update.
     */
    updatePayment: (provider: PaymentProvider, method: string, updatePayload: string, transactionID: string, type: string) => Promise<AppResponse<ServiceResponse>>,
    /**
     * Prepament stock check
     * @param isExpress eg. CARD, PAYPAL_EXPRESS
     * @param provider eg. 'adyen', etc.
     * @param method eg. 'scheme', etc.
     * @param cart Used to make the cart object expected by the payment service
     */
    prePaymentStockCheck: (isExpress: boolean, provider: PaymentProvider, method: string, cart: PaymentServiceCart) => Promise<AppResponse<PrePaymentCheckResponse>>,
}

export interface PaymentOtherService {
    getPaymentMethodModule(methodName: string): Promise<PaymentMethodModule | null>,
}

export type PaymentService = PaymentMeshService & PaymentOtherService & PaymentMeshPaymentsService & HostedPaymentService;

async function getPaymentMethodModule(methodName: string): Promise<PaymentMethodModule | null> {
    try {
        const directory = methodName.toLowerCase().replace(' ', '').replace('_', '');
        const fileName = directory.charAt(0).toUpperCase() + directory.slice(1);
        const module = await import(/* webpackPreload: true */ `./${directory}/${fileName}`);

        return module.default;
    }
    catch (e) {
        return null;
    }
}


function init(state: RootState) {
    return {
        ...adaptor('mesh', 'payments', state),
        ...adaptor('mesh', 'hostedPayments', state),
        ...adaptor('meshPayments', 'gateway', state),
        getPaymentMethodModule,
    } as PaymentService;
}

export default init;
