import './index.css';
import { ThemeType } from 'grommet';
import { Security, TickFilled, TickEmpty, ArrowDown, ArrowUp } from '../components/Icon/index';
import { IconProps } from '../components/Icon/Icon';

export interface CustomTheme extends ThemeType {
    config: {
        displayName?: string,
        titleFontWeight?: 'normal' | 'bold' | number,
        formFieldLabelWeight?: 'normal' | 'bold' | number,
        separatorColour?: string,
        appBackground?: string,
        headerLogo?: string,
        headerLogoMobile?: string,
        faviconLogo?: string,
        headerLogoSize?: {width?: string, height?: string},
        headerLogoAndTextSpacing?: {horizontal?: string, vertical?: string},
        headerShowLogo?: boolean,
        headerBackgroundColor?: string,
        headerBottomBorderColor?: string,
        headerIconColor?: string,
        headerHeight?: string,
        headerTitleFontSize?: string,
        headerLogoContainerMinWidth?: string,
        headerCartIconContainerMinWidth?: string,
        notificationErrorBackgroundColor?: string,
        notificationErrorTextColor?: string,
        notificationErrorFontWeight?: 'normal' | 'bold' | number,
        notificationFontSize?: string,
        notificationBoxHeight?: string,
        notificationIconsSize?: string,
        dialogBackgroundColor?: string,
        stepFontSize?: string,
        stepTextTransform?: 'unset' | 'uppercase' | 'lowercase',
        stepFontWeight?: 'normal' | 'bold' | number,
        stepSeparatorHeight?: string,
        stepSeparatorHeightMobile?: string,
        stepSeparatorHorizontalMargin?: string,
        stepSeparatorColour?: string,
        stepBorder?: boolean,
        stepSeparatorMargins?: {
            vertical: string,
            horizontal: string
        },
        textTransform?: 'unset' | 'uppercase' | 'lowercase',
        textLetterSpacing?: 'normal' | string ,
        titleTextColor?: string,

        imageListBorder?: boolean,
        summaryStepShowSeparator?: boolean,
        summaryShowTotalsTitle?: boolean,
        summaryTotalFontSize?: 'small' | 'medium' | 'large',
        summarySeparatorIsAbove?: boolean,
        summarySeparatorMargins?: {
            top: string,
            bottom: string
        },
        summaryDesktopBoxMaxHeight?: string,
        cvvFieldWidth?: string,
        adyenFormFieldHeight?: string,
        confirmationGuestCheckoutIconSize?: string,
        giftCardShowLegacyInstruction?: boolean,
        giftCardMaxCharacterLength?: number,
        addStarToRequiredField?: boolean,
        requiredFieldStarColour?: string,
        showAddress1And2OnSameLine?: boolean,
        addColorToDiscounts?: boolean,
        icons?: {
            tickIcon?: ({ ...props }: IconProps) => JSX.Element,
            untickIcon?: ({ ...props }: IconProps) => JSX.Element,
            securityIcon?: ({ ...props }: IconProps) => JSX.Element,
            expandNodeIcon?: ({ ...props }: IconProps) => JSX.Element,
            collapseNodeIcon?: ({ ...props }: IconProps) => JSX.Element,
            successTickIcon?: ({ ...props }: IconProps) => JSX.Element,
            errorTickIcon?: ({ ...props }: IconProps) => JSX.Element,
            arrowIcon?: ({ ...props }: IconProps) => JSX.Element,
            arrowDisabledIcon?: ({ ...props }: IconProps) => JSX.Element
        }
        desktop?: {
            desktopViewHorizontalPadding?: string,
            desktopViewVerticalPadding?: string,
            desktopViewHorizontalPaddingLogin?: string
            desktopLoginHeader?: boolean,
            desktopStepSeparatorColour?: string,

            // delivery page
            webBoxWidth?: string,
            desktopViewCentralMargin?: string,

            //home delivery
            boxWidth?: string,
            boxBorder?: boolean,
            borderSide?: 'top'
                | 'left'
                | 'bottom'
                | 'right'
                | 'start'
                | 'end'
                | 'horizontal'
                | 'vertical'
                | 'all'
                | 'between',
            buttonMargin?: string;

            //payment page
            maxWidth?: string,

            //app header
            desktopNavHeight?: string,

            //Payment methods
            tabWidth?: string,

        },
        fontSrc?: string
        stretch?: string
        checkoutSecurelyButtonColor?: string,
        showCheckoutIcon?: boolean,
    },
};

const theme: CustomTheme = {
    config: {

        displayName: 'JD Sports UK',

        // Data for general use
        titleFontWeight: 'bold',
        formFieldLabelWeight: 'bold',
        separatorColour: 'light-2', // Separator bars between option list items or other blocks of UI

        // Component specific data
        appBackground: 'white',

        // AppHeader
        headerShowLogo: true,
        headerLogo: '/Logo-JD-Navigation.svg',
        headerBackgroundColor: 'black',
        headerBottomBorderColor: 'black',
        headerIconColor: 'white',
        headerHeight: '48px',
        headerTitleFontSize: 'small', // Size of the "Secure Checkout" text
        headerLogoContainerMinWidth: '36px',
        headerCartIconContainerMinWidth: '45px',

        // Step
        stepFontSize: 'large', // Title formatting
        stepTextTransform: 'unset',
        stepFontWeight: 'bold',
        stepSeparatorHeight: '16px', // Formats the horizontal line at the bottom of the Step
        stepSeparatorHeightMobile: '16px',
        stepSeparatorHorizontalMargin: '0px',
        stepSeparatorColour: 'light-1', // The separator at the bottom of the Step component
        stepBorder : true,
        stepSeparatorMargins: {
            vertical: 'small',
            horizontal: 'medium'
        },
        textTransform: 'unset',
        textLetterSpacing: 'normal',
        titleTextColor: '',

        //ImageList
        imageListBorder: false, // Add a border and padding around the product images

        // Delivery
        summaryStepShowSeparator: true, // Separation line below the summary pictures and the totals table
        summaryShowTotalsTitle: false, // Add title "Totals Summary" to the Step
        summaryTotalFontSize: 'large',
        summarySeparatorIsAbove: false, // Whether to add the UI separator lines above or below each row
        summarySeparatorMargins: {
            top: '7px',
            bottom: 'large'
        },
        summaryDesktopBoxMaxHeight: '350px',
        giftCardShowLegacyInstruction: false,
        giftCardMaxCharacterLength: 10,
        addStarToRequiredField: true,
        requiredFieldStarColour: '#43d5b0',
        showAddress1And2OnSameLine: false,
        addColorToDiscounts: false,
        cvvFieldWidth: '130px',

        // Notification
        notificationErrorBackgroundColor: "red",
        notificationErrorTextColor: "white",
        notificationErrorFontWeight: 500,
        notificationFontSize: "12px",
        notificationBoxHeight: "48px",
        notificationIconsSize: "15px",

        // Dialog
        dialogBackgroundColor: "white",

        // Desktop specific style
        desktop: {
            desktopViewHorizontalPadding: "13vw",
            desktopViewVerticalPadding: "56px",
            desktopViewHorizontalPaddingLogin : "25vw",
            desktopLoginHeader : false,
            desktopStepSeparatorColour :"white",

            // delivery page
            webBoxWidth: "29vw",
            desktopViewCentralMargin: "5vw",

            //home delivery
            boxWidth: "80vw",
            boxBorder: true,
            borderSide : "all",
            buttonMargin: "100px",

            //payment page
            maxWidth: "800px",

            //app header
            desktopNavHeight: "72px",

            //Payment methods
            tabWidth: "14.5vw",

        },

        // Icons specific to theme
        icons: {
            tickIcon: TickFilled,
            untickIcon: TickEmpty,
            securityIcon: Security,
            expandNodeIcon: ArrowDown,
            collapseNodeIcon: ArrowUp,
            successTickIcon: TickFilled
        },

        // AdyenCardPaymentForm
        adyenFormFieldHeight: '48px',
        confirmationGuestCheckoutIconSize: '50px',
        fontSrc: '/fonts/gotham.css',
        stretch: 'normal',
        checkoutSecurelyButtonColor: '',
        showCheckoutIcon: false
    },

    global: {
        breakpoints: {
            small: {
                edgeSize: {
                    xxsmall: '4px',
                    xsmall: '8px',
                    medium: '16px',
                    large: '24px',
                },
            },
            medium: {
                edgeSize: {
                    xxsmall: '4px',
                    xsmall: '8px',
                    medium: '16px',
                    large: '24px',
                },
            },
            large: {
                edgeSize: {
                    xxsmall: '4px',
                    xsmall: '8px',
                    medium: '16px',
                    large: '24px',
                },
            },
        },
        edgeSize: {
            xxsmall: '4px',
            xsmall: '8px',
            medium: '16px',
            large: '24px',
        },
        font: {
            family: 'Gotham SSm A,Gotham SSm B,Arial,sans-serif',
            size: '14px',
        },
        
        colors: {

            brand: {
                light: "#43d5b0",
                dark: "#000"
            },
            background: {
                dark: "#111111",
                light: "#FFFFFF",
            },
            "background-error": {
                dark: "rgba(255,0,0,0.08)",
                light: "rgba(255,0,0,0.08)",
            },
            white: "#FFFFFF",
            "light-1": "#f7f7f7",
            "light-2": "#e7e8e9",
            "light-3": "#f2f2f2", // grey
            "dark-1": "#404044",
            "accent-1": '#4bd4b0', // green
            black: "#000000",
            border: {
                dark: "#FFFFFF",
                light: "#e7e8e9"
            },
            red: {
                dark: "#FF0000",
                light: "#FF0000"
            },
            "status-critical": "#FF4040",
            "status-warning": "#FFAA15",
            "status-ok": "#00C781",
            "status-unknown": "#e7e8e9",
            "status-disabled": "#e7e8e9",
            "status-disabled-text": "#90929a",
        },

        focus: {
            border: {
                color: 'light-2'
            },
            shadow: {
                size: '1px'
            },
        },

        input: {
            font: {
                weight: 'normal',
                size: 'medium',
            },
            padding: {
                horizontal: '8px',
                vertical: '16px'
            }
        },

        control: {
            border: {
                radius: '0'
            }
        }
    },
    
    button: {
        extend: `height: 48px; font-weight: 500`,
        border: {
            width: "2px",
            radius: "4px"
        },
        disabled: {
            border: {
                color: "status-disabled"
            },
            background: "status-disabled",
            color: "status-disabled-text",
            opacity: 1,
        },
        default: {
            background: "background",
            border: {
                color: "border",
                width: "1px"
            }
        }, 
        primary: {
            background: "brand",
            color: "#FFFFFF"
        },
        secondary: {
            background: "black",
            color: "white",
        },
        size: {
            large: {
                border: {
                    radius: '4px'
                },
                pad: {
                    vertical: '16px'
                }
            }
        }
    },

    formField: {
        border: {
            side: "all",
            size: "xsmall",
            color: "border"
        },
        error: {
            background: "background-error",
            color: "red",
            margin: {
                left: "0"
            }
        },
        help: {
            margin: {
                start: "none",
                bottom: "small"
            }
        },
        info: {
            margin: {
                start: "none",
                bottom: "small"
            }
        },
        label: {
            weight: "bold",
            size: '14px',
            margin: {
                vertical: "xsmall",
                horizontal: "none"
            }
        },
        margin: {
            "bottom": "medium"
        }
    },

    checkBox: {
        extend: `box-shadow: none !important; font-size:14px;`,
        border: {
            color: '#90929a',
            width: '1.25px'
        },
        check: {
            radius: '20px',
            thickness: '1.25px',
            extend: (props) => {
                return props.checked ? `border-color: white; box-shadow: none !important;` : `box-shadow: none !important;`;
            }
        },
        color: 'white',
        gap: '8px',
        size: '20px',
        icon: {
            size: '17px',
        },
        icons: {
            checked: TickFilled,
        }
    },

    radioButton: {
        font: {
            weight: 'bold'
        },
        gap: '8px',
    },
    //@ts-ignore BUG in theme definition will be fixed in next release https://github.com/grommet/grommet/commit/e48684ed4898bf0155e84bea3ff70938068d73a1
    text: {
        extend: `letter-spacing: -0.02em; padding-right: 4px`,
        xsmall: {
            'size': '10px',
            'height': '1.33',
        },
        small: {
            'size': '12px',
            'height': '1.33',
        },
        medium: {
            'size': '14px',
            'height': '1.75'
        },
        large: {
            'size': '16px',
            'height': '1.25'
        },
        xlarge: {
            'size': '24px',
            'height': '1.75'
        },
    },

    textInput: {
        'extend': (props) => {
            return `border: none; height:48px`;
        }
    },

    select: {
        icons: {
            color: 'black',
        },
    },

    box: {
    },
    icon: {
        size: {
            small: '16px',
            medium: '24px'
        }
    },
    tabs: {
        header: {
            extend: (props) => {
                return `width: 100vw;`;
            }
        }
    },
    tab: {
        margin: {horizontal: '0px', top: 'small'},
        pad: {horizontal: '0px', bottom: 'small'},
        border: {
            active: {
                color: 'accent-1',
            },
            color: 'light-2',
            size: '4px',
            hover: {
                color: 'accent-1',
            }
        },
    },
    anchor: {
        color: "black",
        textDecoration: "underline"
    },
    tip: {
        content: {
            background: "#e8e8e8",
            pad: { vertical: "xsmall", horizontal: "small" },
        }
    },
};

export default theme;
