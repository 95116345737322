import React, { useContext, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation
} from "react-router-dom";
import { Box, ResponsiveContext } from 'grommet';
import { ThemeContext } from 'styled-components';
import { CustomTheme } from '../../theme';
import Login from '../Login/Login';
import DeliveryPage from '../../containers/DeliveryPage';
import Confirmation from '../Confirmation/Confirmation';
import ServiceAgentConfirmation from '../ServiceAgentConfirmation/ServiceAgentConfirmation';
import Page from '../Page/Page';
import StartUp from '../StartUp/StartUp';
import AppHeader from '../AppHeader/AppHeader';
import PaymentPage from '../PaymentPage/PaymentPage';
import Notification from '../Notification/Notification';
import Dialog from '../Dialog/Dialog';
import { clearNotification, updateNotification, getNotificationDetails, updateGenericError, getGenericErrorMessage, updateDialog } from '../../modules/notification';
import PaymentRedirectPage from '../PaymentRedirectPage/PaymentRedirectPage';
import { useSelector, useDispatch } from 'react-redux';
import ExpressPaymentPage from '../ExpressPaymentPage/ExpressPaymentPage';
import HistoryManager from '../HistoryManager/HistoryManager';
import WebFont from 'webfontloader';
import { AppThunkDispatch } from '../../modules/thunk';
import { initServices } from '../../services';
import { RootState } from '../../store/reducers';
import { initialise } from '../../lib/firebase/firebase';
import { useQuery } from '../../lib/url/url';
import { getMeshErrorAndDelete } from '../../services/mesh-sdk/client';
import { getHasGiftCardError } from "../../modules/billing";
import { getCustomer } from '../../modules/customer';
import ErrorDetails from '../ErrorDetails/ErrorDetails';
import { createWHDObject } from '../../lib/dataObject/dataObject';
import { local as browserLocalStorage } from 'store2';

function App({ fascia }: AppProps) {
    const size = React.useContext(ResponsiveContext);
    const isDesktop = size !== 'small';
    const theme: CustomTheme = useContext(ThemeContext);
    const currentState = useSelector((state: RootState) => state);
    // styling for Desktop
    const logo = (!isDesktop && theme?.config?.headerLogoMobile) ? theme?.config?.headerLogoMobile : theme?.config?.headerLogo;
    const displayName = theme?.config?.displayName ?? "";
    const fontSrc = theme?.config?.fontSrc ?? ""
    const fontFamilies = theme?.global?.font?.family ?? "";
    const favionLogo = theme?.config?.faviconLogo;
    const willMount = useRef(true);
    const bingAddsId = currentState.config.bing_adds_id;

    function getFavicon() {
        return document.getElementById("favicon") as HTMLLinkElement;
    }

    function getAppleTouchIcons() {
        return document.getElementsByClassName("appletouchicon") as HTMLCollectionOf<HTMLLinkElement>;
    }

    // equivilent to componentwillmount
    if (willMount.current) {
        // make sure iniialise the services on reload 
        initServices(currentState);
        // initialise firebase once when we start the app
        initialise(fascia || "");
        willMount.current = false;
    }

    // Set the correct title and favicon for the fascia
    useEffect(() => {
        document.title = `${displayName} Payment`;
        const favicon = getFavicon();
        const appletouchicons = getAppleTouchIcons();
       
        if (getFavicon()) {
            favicon.href = favionLogo ? `/img/icons/${favionLogo}` : `/img/icons/${fascia}/favicon.ico`;
        }
        if (getAppleTouchIcons()) {
            Array.from(appletouchicons).forEach((icon) => {
                icon.href = icon.sizes.value ? `/img/icons/${fascia}/apple-touch-icon-${icon.sizes}.png` : `/img/icons/${fascia}/apple-touch-icon.png`;
            });
        }

        WebFont.load({
            custom: {
                families: fontFamilies.split(','),
                urls: [fontSrc]
            }
        });

    }, [fascia, displayName, fontSrc, fontFamilies, favionLogo]);

    useEffect(() => {
        const script = document.createElement("script");
        if(process.env.REACT_APP_MESH_ENV === 'prod') {
            script.setAttribute("src", process.env.REACT_APP_PROD_NEWRELIC_LINK as string);
        } else {
            script.setAttribute("src", process.env.REACT_APP_NONPROD_NEWRELIC_LINK as string);
        }
        document.head.appendChild(script);
	}, []);

    useEffect(() => {
        const isBootstrapScriptLoaded = document.getElementById('bootstrap-script');
        const fasciaValue = fascia ?? browserLocalStorage.get('store');
        if(fasciaValue && !isBootstrapScriptLoaded) {
            // Create Wolfhound metadata
            createWHDObject(fasciaValue);
            // Add ensighten script
            const script = document.createElement('script');
            script.src = "https://nexus.ensighten.com/jdplc/global/Bootstrap.js";
            script.async = true;
            script.id = "bootstrap-script";
            let body: HTMLElement = document.getElementsByTagName('body')[0];
            body.appendChild(script);
        }
    })

    // error information
    const fullState = useSelector((state: RootState) => state);
    const dispatch: AppThunkDispatch = useDispatch();
    const notificationDetails = getNotificationDetails(fullState, dispatch);
    const genericError = getGenericErrorMessage(fullState.notification);

    useEffect(() => {
        const meshError = getMeshErrorAndDelete();
        const customer = getCustomer(fullState.customer);
        if (meshError && !getHasGiftCardError(fullState.billing) && customer.isRegistered !== false ) {
            dispatch(updateGenericError('mesh_error', meshError));
        }
    });

    useEffect(() => {
        if (fascia === 'blank') {
            dispatch(updateDialog('session_expired'));
        }
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(bingAddsId){
            const scriptText:string = `(function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"${bingAddsId}", enableAutoSpaTracking: true};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");`;
            const scriptTag = document.createElement('script');
            scriptTag.innerHTML = scriptText ;
            document.head.appendChild(scriptTag);
        }
    },[bingAddsId]);

    const location = window.location.pathname;

    return (
        <Box
            className="App"
            fill={true}
            background={theme?.config?.appBackground ?? 'light-1'}
            height={{ min: "100vh" }}
        >
            <AppHeader logoSrc={logo} />
            {((notificationDetails.message !== "" || genericError) && (location !== "/login")) &&
                <Notification />}
            <Page>
                <Router>
                    <HistoryManager />
                    <RouteChange />
                    <Switch>
                        <Route path="/login">
                            <Login />
                        </Route>
                        <Route path="/delivery">
                            <DeliveryPage />
                        </Route>
                        <Route path="/payment">
                            <PaymentPage />
                        </Route>
                        <Route path="/expresspayment">
                            <ExpressPaymentPage />
                        </Route>
                        <Route path="/paymentredirect/:type">
                            <PaymentRedirectPage />
                        </Route>
                        <Route path="/confirm">
                            <Confirmation />
                        </Route>
                        <Route path="/serviceagentconfirmation">
                            <ServiceAgentConfirmation />
                        </Route>
                        <Route path="/">
                            <StartUp />
                        </Route>
                        <Route path="*">
                            No Match
                    </Route>
                    </Switch>
                </Router>
            </Page>
            <Dialog />
            <ErrorDetails />
        </Box>
    );
}

function RouteChange() {
    const location = useLocation();
    const dispatch: AppThunkDispatch = useDispatch();
    const query = useQuery();

    const queryNotification = query.get('notification');
    const notificationExtraData: {[key:string]: string} = {};
    query.forEach((value, key) => {
        // Each query param name appended with '***NotificationData' will be considered a param for the notification.
        // Eg. `?notification=payment_method_initialise_error&methodNameNotificationData=Paypal` will do:
        // dispatch(updateNotification('payment_method_initialise_error', {methodName: 'Paypal'}))
        const notificationMatch = key.match(/(\w+)NotificationData$/);
        if (notificationMatch?.[1]) {
            notificationExtraData[notificationMatch?.[1]] = value;
        }
    });

    useEffect(() => {
        // runs on location, i.e. route, change
        // clear the error on route change unless an '?error' query param is detected
        if (queryNotification) {
            dispatch(updateNotification(queryNotification, notificationExtraData));
        }
        else {
            dispatch(clearNotification());
        }
    // eslint-disable-next-line
    }, [location, dispatch, queryNotification]);

    return (
        <></>
    );
}

type AppProps = {
    fascia?: string | null
}

export default App;
