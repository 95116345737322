
export interface Session {
    ID: string,
    cartID?: string,
    authToken?: AuthToken,
    customerID?: string,
    commands?: {
        invalidateCartCache?: boolean,
        createCsrfToken?: boolean,
    },
    serviceAgent?: boolean,
}

export interface AuthToken {
    refresh_token?: string,
    access_token?: string,
    token_type?: string,
    expires_in?: number,
    refresh_expires_in?: number,
    customerID?: string
}

// constant types
export const GET_SESSION = 'checkout/session/GET_SESSION';
export const UPDATE_SESSION = 'checkout/session/UPDATE_SESSION';
export const UPDATE_IS_SERVICE_AGENT = 'checkout/session/UPDATE_IS_SERVICE_AGENT'

export type SessionActionTypes = getSession | updateSession | updateIsServiceAgent;

interface getSession {
    type: typeof GET_SESSION
};

interface updateSession {
    type: typeof UPDATE_SESSION,
    payload: Session
};

interface updateIsServiceAgent {
    type: typeof UPDATE_IS_SERVICE_AGENT,
    isServiceAgent: boolean,
}