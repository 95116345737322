import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch} from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Text } from 'grommet';
import { RootState } from '../../store/reducers';
import { AppThunkDispatch } from '../../modules/thunk';
import { updateDialog, updateNotification } from '../../modules/notification';
import { useQuery } from '../../lib/url/url';
import { payments } from '../../services';
import LoadingIcon from '../LoadingIcon/LoadingIcon';
import { getRedirectDescription, getSelectedPaymentMethod } from '../../modules/billing';
import { initServices } from '../../services';
import { translate } from "../../modules/translation";
import store from "../../store";
import newRelicData from '../../newrelic';

function PaymentRedirectPage() {
    const dispatch: AppThunkDispatch = useDispatch();
    const query = useQuery();
    const history = useHistory();
    const type = useParams<{type: string}>().type;

    const currentState = useSelector((state: RootState) => state);
    const billing = useSelector((state: RootState) => state.billing);
    const redirectDescription = getRedirectDescription(billing);
    const [showLoadingIcon, setShowLoadingIcon] = useState(true);
    const selectedPaymentMethod = getSelectedPaymentMethod(billing);
    
    useEffect(() => {
        async function processPayment() {
            setShowLoadingIcon(true);
            initServices(currentState);

            const queryParams = Object.fromEntries(query.entries());

            const payment = await payments().getPaymentMethodModule(type);

            if (payment) {
                payment.setHistory(history);
                try {
                    newRelicData({ actionName: 'paymentRedirectPage', function: 'processPayment', message: 'Before handleRedirect' });
                    await payment.handleRedirect(queryParams);
                }
                catch(error) {
                    const rawResponse = error?.response?.data?.rawResponse !== undefined && JSON.parse(error?.response?.data?.rawResponse)
                    const newRelicResponse = { status: rawResponse?.status, errorCode: rawResponse?.errorCode, message: rawResponse?.message, errorType: rawResponse?.errorType };
                    const translation = store.getState().translation;
                    if (error?.response?.data?.InternalCode === "EXTERNAL_PAYMENT_ERROR_1") {
                        const message = translate(translation, 'thereWasAnErrorWhenAttemptingTo', "There was an error when attempting to pay, please try again. No money has been taken and your cart is still intact.");
                        store.dispatch(updateDialog('adyen_issue_on_redirection', {content: message}));
                        newRelicData({ actionName: 'paymentRedirectPage', function: 'processPayment', message: newRelicResponse, resultCode: queryParams.resultCode, type: queryParams.type, paymentName: selectedPaymentMethod.name });
                    }
                    else if (rawResponse?.errorCode === "11" && rawResponse?.errorType === "Refused") {
                        history.push('/delivery');
                        const message = translate(translation, 'err_transaction_refused', 'The transaction was refused');
                        store.dispatch(updateNotification('adyen_error', {
                            code: rawResponse?.errorCode, 
                            message: message
                        }));
                        newRelicData({ actionName: 'paymentRedirectPage', function: 'processPayment', message: newRelicResponse, resultCode: queryParams.resultCode, type: queryParams.type, paymentName: selectedPaymentMethod.name });
                    }
                    else if (rawResponse?.errorCode === "24") {
                        history.push('/delivery');
                        store.dispatch(updateNotification('adyen_error', {
                            code: rawResponse?.errorCode,
                            message: "cvv declined"
                        }));
                        newRelicData({ actionName: 'paymentRedirectPage', function: 'processPayment', message: newRelicResponse, resultCode: queryParams.resultCode, type: queryParams.type, paymentName: selectedPaymentMethod.name });
                    }
                    else {
                        newRelicData({ actionName: 'paymentRedirectPage', function: 'processPayment', message: newRelicResponse, resultCode: queryParams.resultCode, type: queryParams.typ, paymentName: selectedPaymentMethod.name });
                        setShowLoadingIcon(false);
                        window.location.href = `/delivery?notification=redirect_page_redirection_error`;
                    }
                }
            }
            else {
                setShowLoadingIcon(false);
                dispatch(updateNotification('redirect_page_unknown_method'));
                newRelicData({ actionName: 'paymentRedirectPage', function: 'processPayment', message: 'payment is not successful', } );
            }
            
        }
        processPayment();
        // eslint-disable-next-line
    }, []);

    return (
        <Box
            flex="grow"
            justify="center"
            gap="medium"
        >
            {showLoadingIcon && 
                <LoadingIcon color="black" size="50px"/>
            }
            <Text alignSelf="center">{redirectDescription}</Text>
        </Box>
    );
}

export default PaymentRedirectPage;
