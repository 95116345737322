import React, {useState, ChangeEvent, useEffect, useContext} from 'react';
import {FormField, FormFieldProps, TextInput, Text, Box} from 'grommet';
import {ThemeContext} from 'styled-components';
import {CustomTheme} from '../../theme';
import {getError} from '../../lib/validate/validate';
import {ValidationRule} from '../../lib/validate/types';
import {HidePassword, ShowPassword} from '../Icon/index';
import {getCountryCode} from "../../modules/config";
import store from "../../store";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';


function ValidatedInput(props: FormFieldProps & ValidatedInputProps) {

    const {validate, rules, value, onChange, name, placeholder, type, label, addStarIfRequired, ...fieldProps} = props;
    const [status, setStatus] = useState('default');
    const [errorMessage, setErrorMessage] = useState('');
    const countryCode = getCountryCode(store.getState().config).toLowerCase();
    const contentPropsForBorder = name !== "phone" ? true : false ;

    const theme: CustomTheme = useContext(ThemeContext);
    const successTickIcon = theme?.config?.icons?.successTickIcon? React.createElement(theme?.config?.icons?.successTickIcon): null;
    const errorTickIcon = theme?.config?.icons?.errorTickIcon? React.createElement(theme?.config?.icons?.errorTickIcon): null;

    const [showPassword, setShowPassword] = useState(false);

    const isRequired = rules.some(rule => rule.name === 'required');

    let styledLabel = <Text weight={theme?.config?.formFieldLabelWeight ?? 500}>{label}</Text>;
    if (addStarIfRequired && theme?.config?.addStarToRequiredField && isRequired) {
        styledLabel = <Text weight={theme?.config?.formFieldLabelWeight ?? 500}><Text color={theme?.config?.requiredFieldStarColour}>*</Text>{label}</Text>;
    }

    const validateField = (fieldValue: string) => {
        // get error can't accept undefined or null
        if (!fieldValue) {
            fieldValue = "";
            //handle when field not required and no value entered. 
            // This should not show an error state
            if (!rules.find((validationRule) => validationRule.name === 'required')) {
                setStatus('default');
                setErrorMessage('');
                return undefined;
            }       
        }
        
        const result = getError(fieldValue, rules);
        if (result.isValid) {
            setStatus('success');
            return undefined;
        } else {
            setStatus('error');
            setErrorMessage(result.message);
        }
        return errorMessage;  
    }

    useEffect(() => {
        if (value) {
            validateField(value);
        }
    });

    function makeValidationIcon(status: string): JSX.Element | undefined {
        switch (status) {
            case 'success':
                return <Box flex={false} data-testid="validatedInput-successIcon">{successTickIcon}</Box>;
            case 'error':
                return <Box flex={false} data-testid="validatedInput-errorIcon">{errorTickIcon}</Box>;
            default:
                return undefined;
        }
    }

    return (
        <FormField
            {...fieldProps}
            label={label ? styledLabel : undefined}
            validate={validateField}
            error={status === 'error' ? errorMessage || <></> : ""}
            name={name}
            htmlFor={`${name}-id`}
            className={status === 'success' ? "valid" : "invalid"}
            contentProps={{border: contentPropsForBorder}}
        >

            <Box direction="row" align="center">
                { name !== "phone" ?
                    <TextInput
                        plain={true}
                        id={`${name}-id`}
                        name={name}
                        placeholder={placeholder}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            if (onChange) onChange(e);
                        }}
                        onBlur={(e: ChangeEvent<HTMLInputElement>) => {
                            return validateField(e.target.value);
                        }}
                        value={value}
                        icon={makeValidationIcon(status)}
                        reverse={true}
                        focusIndicator={false}
                        data-testid="validatedInput"
                        size="medium"
                        type={type === 'password' ? (showPassword ? "text" : "password") : type}

                    /> 
                    :
                    <Box direction="row" align="center" width={'100%'} border={true}>
                        <PhoneInput
                            country={countryCode}
                            inputStyle={{
                                backgroundColor: "transparent",
                                borderRadius: "0px",
                                width: '90%',
                                height: '48px',
                                border: "none"
                            }}
                            buttonStyle={{
                                border: 'none',
                                borderRadius: '0px',
                                backgroundColor: '#fff',
                            }}
                            inputProps={{
                                name: name,
                                id: `${name}-id`,
                                "data-testid":"validatedInput",
                            }}
                            placeholder={''}
                            autoFormat={false}
                            value={value}
                            onBlur={(e: ChangeEvent<HTMLInputElement>) => {
                                return validateField(e.target.value);
                            }}
                            onChange={(e: any) => {
                                if(e !== '') e = `+${e}`
                                if (onChange) onChange(e)
                            }}
                        />
                        <Box width={'5%'}>
                            {makeValidationIcon(status)}
                        </Box>
                    </Box>
                }
                {type === 'password' &&
                <Box pad={{horizontal: "xsmall"}}>
                    {showPassword ?
                        <ShowPassword data-testid="showpassword" onClick={() => setShowPassword(!showPassword)}/>
                        :
                        <HidePassword data-testid="hidepassword" onClick={() => setShowPassword(!showPassword)}/>
                    }
                </Box>
                }

            </Box>
        </FormField>
    )
};

type ValidatedInputProps = {
    rules: ValidationRule[],
    value?: string,
    onBlur?: (e: any) => void,
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void,
    placeholder?: string,
    type?: string,
    addStarIfRequired?: boolean
}

export default ValidatedInput;
