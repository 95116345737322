import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Text } from 'grommet';
import { RootState } from '../../store/reducers';
import { translate } from '../../modules/translation';
import { SavedCard } from '../../modules/billing/types';
import { getIconForCardType } from '../../lib/paymentMethodRenderedExtras/paymentMethodRenderedExtras';

function SavedCardDetails({ savedCard }: SavedCardDetailsProps) {
    const translation = useSelector((state: RootState) => state.translation);

    const strings = {
        expiryDate: translate(translation, 'expiryDate', 'Expiry Date'),
        expired: translate(translation, 'expired', 'Expired'),
    };

    const expiryDate = `${(savedCard.expiryMonth + '').padStart(2, '0')}/${(savedCard.expiryYear + '').substring(2, 4)}`;
    const brandDetails = getIconForCardType(savedCard.brand);
    const nonBreakingSpace = '\xa0';
    const now=new Date();
    const currMonth = now.getMonth()+1;
    const currYear = now.getFullYear();
    const expiryYear = savedCard.expiryYear
    const expiryMonth = savedCard.expiryMonth;
    let expireText = <Text>{strings.expiryDate}:{nonBreakingSpace}{expiryDate}</Text>;
    if (currYear > expiryYear || (currYear === expiryYear && currMonth >= expiryMonth)){
        expireText = <Text color="red"> {strings.expired}: {nonBreakingSpace}{expiryDate}</Text>;
    }

    return (
        <Box
            fill="horizontal"
            margin={{bottom: 'small'}}
        >
            <Box
                direction="row"
                align="center"
            >
                <Text>{savedCard.name ? savedCard.name+' ' : ''}({savedCard.lastFour})</Text>
                {brandDetails.icon}
            </Box>
            <Text>{savedCard.holderName}</Text>
            <Text> {expireText}</Text>
        </Box>
    );
}

type SavedCardDetailsProps = {
    savedCard: SavedCard,
};

SavedCardDetails.propTypes = {
    savedCard: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        brand: PropTypes.string.isRequired,
        lastFour: PropTypes.string.isRequired,
        holderName: PropTypes.string.isRequired,
        expiryMonth: PropTypes.number.isRequired,
        expiryYear: PropTypes.number.isRequired,
    }).isRequired,
};

export default SavedCardDetails;
