import React, {useContext} from 'react';
import { useSelector } from 'react-redux';
import { Box, Header, Text, Image, ResponsiveContext, Anchor } from 'grommet';
import styled, { ThemeContext } from 'styled-components';
import { CustomTheme } from '../../theme';
import { RootState } from '../../store/reducers';
import { getReturnURL } from '../../modules/config';
import { translate } from '../../modules/translation';


const OneLineText = styled(Text)`
    white-space: nowrap;
`;


function AppHeader({ logoSrc, svgIcon, background }: AppHeaderProps){
    const theme: CustomTheme = useContext(ThemeContext);

    const config = useSelector((state: RootState) => state.config);
    const translation = useSelector((state: RootState) => state.translation);
    const returnURL = getReturnURL(config);

    const showLogo = theme?.config?.headerShowLogo ?? true;
    const titleFontWeight = theme?.config?.titleFontWeight ?? 'bold';
    const securityIcon = theme?.config?.icons?.securityIcon ? React.createElement(theme?.config?.icons?.securityIcon): null;
    const headerLogoSize = theme?.config?.headerLogoSize;
    const logoContainerMinWidth = theme?.config?.headerLogoContainerMinWidth ?? '0px';
    const headerLogoAndTextSpacing = theme?.config?.headerLogoAndTextSpacing;
    const mobileNavHeight = theme?.config?.headerHeight ?? '48px'

    // styling for Desktop
    const desktopNavHeight = theme?.config?.desktop?.desktopNavHeight ?? "72px";
    const headerBottomBorderColor = theme?.config?.headerBottomBorderColor ?? "black";
    const size = React.useContext(ResponsiveContext);

    return (
        <Header
            background={background || (theme?.config?.headerBackgroundColor ?? 'black')}
            pad={{horizontal: 'medium'}}
            border={{side:'bottom', color: headerBottomBorderColor}}
            height={{min: size !== "small" ? desktopNavHeight : mobileNavHeight}}
            flex={true}
        >
            {/* Logo */}
            {showLogo &&
            <Box
                height={theme?.icon?.size?.medium ?? '24px'}
                justify="center"
                flex={true}
                width={{min: logoContainerMinWidth}}
            >
                <Anchor href={returnURL}>
                    {svgIcon ? svgIcon : <Image
                        fit="contain"
                        src={logoSrc || "/Logo-JD-Navigation.svg"}
                        alt="Logo"
                        width={headerLogoSize?.width}
                        height={headerLogoSize?.height}
                    />}
                </Anchor>
                
            </Box>
            }

            {/* Title */}
            <Box
                direction="row"
                margin={{horizontal: headerLogoAndTextSpacing?.horizontal}}
            >
                <div data-testid="header-secureLock">{securityIcon}</div>
                <Box margin={{ left: "xsmall" }}
                    style={{ 'textTransform': theme?.config?.textTransform ?? "unset" }}>
                    <OneLineText
                        size={theme?.config?.headerTitleFontSize ?? 'small'}
                        weight={titleFontWeight}
                        wordBreak="keep-all"
                    >
                        {translate(translation, 'secureCheckout', '')}
                    </OneLineText>
                </Box>
            </Box>

            {/* 3rd empty element so the 1st element (logo) will be left align and the 2nd (title) will be centered. */}
            {showLogo &&
            <Box flex={true}></Box>
            }
        </Header>
    )
}

type AppHeaderProps = {
    logoSrc?: string, 
    background?: string,
    svgIcon?: any
}

export default AppHeader;