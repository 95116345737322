import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Select, Text } from 'grommet';
import { RootState } from '../../store/reducers';
import { localisedDate } from '../../lib/date/date';
import { AppThunkDispatch } from '../../modules/thunk';
import {
    getDeliverySlotDaysByMethodId, getDeliverySlotByMethodId,
    getDeliverySlotsByMethodId, selectTimeSlotOnDeliveryMethod,
} from '../../modules/delivery';
import { getLocalisation } from '../../modules/config';
import { translate } from '../../modules/translation';


function TimeSlotSelector({methodId}: TimeSlotSelectorProps) {
    const dispatch: AppThunkDispatch = useDispatch();
    const delivery = useSelector((state: RootState) => state.delivery);
    const config = useSelector((state: RootState) => state.config);
    const translation = useSelector((state: RootState) => state.translation);
    const localisation = getLocalisation(config);
    
    const [dayOptions, setDayOptions] = useState<{ value: string, display: string}[]>([]);
    const [day, setDay] = useState('');
    const [timeOptions, setTimeOptions] = useState<{ value: string, start: string, end: string, display: string, isGreen: boolean }[]>([]);
    const [time, setTime] = useState('');

    const selectedTimeOption = timeOptions.find(option => option.value === time);

    const strings = {
        greenSlotSelected: translate(translation, 'greenSlotSelected', "We've selected the most environmentally friendly slot for you"),
    }

    useEffect(() => {
        // Load the day options
        const dayOptionsFromStore = getDeliverySlotDaysByMethodId(delivery, methodId)?.map(day => {
            return {
                value: day,
                display: localisedDate(day, localisation.language.code),
            };
        });
        if (dayOptionsFromStore) setDayOptions(dayOptionsFromStore);

        // Set the day showing by default
        const selectedTimeSlot = getDeliverySlotByMethodId(delivery, methodId, {type: 'selected'});
        const defaultDay = selectedTimeSlot?.date ?? dayOptionsFromStore?.[0].value ?? ''
        setDay(defaultDay);
        
        // Load the time options (hours) for that day
        const newTimeOptions = makeTimeOptionsFromDay(defaultDay);
        if (newTimeOptions) setTimeOptions(newTimeOptions);

        // Select by default the selected slot, or the green slot if no selection yet, or the first hour if no green slot either.
        let defaultTime = '';
        if (selectedTimeSlot) {
            defaultTime = `${selectedTimeSlot.start}-${selectedTimeSlot.end}`;
            dispatch(selectTimeSlotOnDeliveryMethod(methodId, defaultDay, selectedTimeSlot.start, selectedTimeSlot.end));
        }
        else {
            const greenOption = newTimeOptions.find(option => option.isGreen);
            defaultTime = greenOption?.value ?? newTimeOptions?.[0].value ?? '';
            dispatch(selectTimeSlotOnDeliveryMethod(methodId, defaultDay, greenOption?.start ?? '', greenOption?.end ?? ''));
        }
        setTime(defaultTime);
        // eslint-disable-next-line
    }, []);

    /**
     * Makes an object to be used by the Select component to choose a specific time
     * @param day eg. "2021-05-13"
     * @returns Array of time slot objects
     */
    function makeTimeOptionsFromDay(day: string) {
        const daySlots = getDeliverySlotsByMethodId(delivery, methodId, day);
        const timeOptions = daySlots?.map(slot => {
            return {
                value: `${slot.start}-${slot.end}`,
                display: slot.displayTime,
                start: slot.start,
                end: slot.end,
                isGreen: slot.greenSlot,
            }
        });
        return timeOptions ?? [];
    }

    return (
        <Box>
            <Select
                options={dayOptions}
                value={day}
                size="medium"
                labelKey="display"
                valueKey={{ key: 'value', reduce: true }}
                onChange={({ value: selectedDay }) => {
                    setDay(selectedDay);
                    const newTimeOptions = makeTimeOptionsFromDay(selectedDay);
                    const greenTimeOption = newTimeOptions.find(option => option.isGreen) ?? newTimeOptions[0];
                    setTimeOptions(newTimeOptions);
                    dispatch(selectTimeSlotOnDeliveryMethod(methodId, selectedDay, greenTimeOption.start ?? '', greenTimeOption.end ?? ''));
                    setTime(greenTimeOption.value);
                }}
            />
            <Select
                options={timeOptions}
                value={time}
                size="medium"
                labelKey="display"
                valueKey={{ key: 'value', reduce: true }}
                onChange={({ value: selectedTime }) => {
                    setTime(selectedTime);
                    const [ start, end ] = selectedTime.split('-');
                    dispatch(selectTimeSlotOnDeliveryMethod(methodId, day, start ?? '', end ?? ''));
                }}
                margin={{ top: 'xsmall' }}
            />
            { selectedTimeOption?.isGreen &&
            <Text size="small" margin={{top: 'medium'}}>
                {strings.greenSlotSelected}
            </Text>
            }
        </Box>
    );
}

type TimeSlotSelectorProps = {
    methodId: string,
}

TimeSlotSelector.propTypes = {
    methodId: PropTypes.string.isRequired,
};

export default TimeSlotSelector;
