import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Text } from 'grommet';
import { ThemeContext } from 'styled-components';
import { CustomTheme } from '../../theme';
import { RootState } from '../../store/reducers';
import { translate } from '../../modules/translation';
import LoadingIcon from '../LoadingIcon/LoadingIcon';

/**
 * This component can be used within a grommet Stack component on top of content that should be made temporarily unresponsive.
 * Typical use:
 * ```jsx
 * <Stack>
 *    <Box> ****** some content ******* </Box>
 *    {showOverlay &&
 *    <LoadingOverlay />
 *    }
 * </Stack>
 * ```
 * @param background {color, opacity} for the overlay
 * @param spinnerOptions {size, color} for the spinning animation
 * @param text string to be written below the spinner
 * @param textOptions {mask, weight} for the text. Mask dictates if the text should have a blank box underneath, the colour of which will be the same as the overlay background colour.
 */
function LoadingOverlay({background, spinnerOptions, text, textOptions} : LoadingOverlayProps) {

    const theme: CustomTheme = useContext(ThemeContext);
    const translation = useSelector((state: RootState) => state.translation);

    const textWeight = textOptions?.weight ?? theme?.config?.titleFontWeight ?? 'bold';

    const loadingText = text ?? translate(translation, 'loadingInProgress', 'Loading in progress');
    const backgroundOptions = {
        color: background?.color ?? 'white',
        opacity: background?.opacity ?? 0.8,
    };

    const textMask = textOptions?.mask === undefined ? true : textOptions.mask;

    return (
        <Box
            background={backgroundOptions}
            fill={true}
        >
            <Box margin="auto">
                {/* Spinner */}
                <Box flex={false}>
                    <LoadingIcon
                        size={spinnerOptions?.size ?? undefined}
                        color={spinnerOptions?.color ?? 'black'} />
                </Box>
                {/* Text */}
                <Box
                    background={textMask === true ? backgroundOptions : undefined}
                    pad={{horizontal: 'small'}}
                    round
                >
                    <Text weight={textWeight}>{loadingText}</Text>
                </Box>
            </Box>
        </Box>
    );
}

type LoadingOverlayProps = {
    text?: string,
    background?: {
        color?: string,
        opacity?: number|boolean|'strong'|'weak'|'medium',
    },
    spinnerOptions?: {
        color?: 'black'|'white',
        size?: string | {max?: string, min?: string},
    },
    textOptions?: {
        mask?: boolean,
        weight?: number|'bold'|'normal',
    }
};

LoadingOverlay.propTypes = {
    text: PropTypes.string,
    background: PropTypes.shape({
        color: PropTypes.string,
        opacity: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    }),
    spinnerProps: PropTypes.shape({
        color: PropTypes.string,
        size: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({
            max: PropTypes.string,
            min: PropTypes.string,
        })]),
    }),
    textOptions: PropTypes.shape({
        mask: PropTypes.bool,
        weight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
};


export default LoadingOverlay;
