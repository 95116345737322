import React, { useRef, useMemo, useContext, useEffect, ChangeEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clone from 'rfdc';
import { AppThunkDispatch } from '../../modules/thunk';
import { RootState } from '../../store/reducers';
import { Box, Text, Tabs, Tab, Button, Form, Anchor, Layer, ResponsiveContext, CheckBox} from 'grommet';
import { debounce } from 'throttle-debounce';
import { Edit, Close, Shop, JDFascia, SizeFascia, FootpatrolFascia, OipolloiFascia, WellgoshFascia, HipstoreFascia, ScottsFascia, TessutiFascia, PostOffice } from '../Icon/index';
import { getIsNotYouCollecting, getStoreByStoreId, getStores, getStoresCount, updateIsNotYouCollecting, updateStores, updatePostOffices, getSelectedMethod} from '../../modules/delivery';
import { Store, CollectionDetails, CandCLocation } from '../../modules/delivery/types';
import { ClickAndCollectMapToProps } from '../../containers/ClickAndCollectPage';
import TitleBar from '../TitleBar/TitleBar';
import Step from '../Step/Step';
import LocationInput from '../LocationInput/LocationInput';
import EditableDetails from '../EditableDetails/EditableDetails';
import StoreDetailsDesktop from '../StoreDetailsDesktop/StoreDetailsDesktop';
import StoreLocationItem from '../StoreLocationItem/StoreLocationItem';
import StoreDetails from '../StoreDetails/StoreDetails';
import ValidatedInput from '../ValidatedInput/ValidatedInput';
import InfoBox from '../InfoBox/InfoBox';
import {maxLength30Rule, requiredRule} from '../../lib/validate/validate';
import { CircleInformation } from "grommet-icons";
import styled from 'styled-components';
import { carts, tracking } from '../../services';
import { Coordinates } from '../../services/mesh-sdk/carts';
import { updateNotification } from '../../modules/notification';
import { getMarketingSmsOptout, getShowMarketingCheckboxes, getStoreDescriptions } from '../../modules/config';
import { Address, CustomerPreferences } from '../../modules/customer/types';
import { translate } from '../../modules/translation';
import { getMarketingOptIns, saveMarketingOptInChoice, updateMarketingOptIns, getAddresses, getCustomerPreferences } from '../../modules/customer';
import Separator from '../Separator/Separator';
import { ThemeContext } from 'styled-components';
import { CustomTheme } from "../../theme";
import { getIsOnExpressJourney, loadPaymentMethods } from '../../modules/billing';
import { getCartId, updateDeliveryMethodAvailability, updateExternalCartDeliveryMethod } from '../../modules/cart';
import newRelicData from '../../newrelic';
import { CartUpdates } from "../../services/carts";

const TODAY = 'Today';
const TOMORROW = 'Tomorrow';
const ANYTIME = 'Anytime';

const TextUnderlined = styled(Text)`
    text-decoration: underline;
`;

const descriptions = {
    Sameday: <p>Collect for FREE.<br />Order before <strong>2pm</strong> for Same Day Collection</p>,
    Today: <p>Collect <strong>Today</strong> for £5.99<br />Order before <strong>5pm</strong></p>,
    Tomorrow: <p>Collect <strong>Tomorrow</strong> for £4.99<br />Order before <strong>9pm</strong></p>,
    Anytime: <p>Collect in <strong>5-7 days</strong> for FREE</p>
}

/**
 * Presentational component for the C&C layer to be used on the 'Delivery' page.
 * @param stores {array} List of store objects
 * @param loadStores {callback} Action to add stores to the redux store
 * @param appendStores {callback} Action to add extra stores to the redux store, at the end of the existing list
 * @param selectStore {callback} Action to commit to state the store name selected by the user.
 * @param selectedStore {Object} Store/location that has been selected (from Redux)
 * @param storeForDetailedView {Object} Store to display in the 'Store Details' view
 * @param selectStoreForDetailedView {callback} Action to select a store for the 'Store Details' view
 * @param showStoreDetailedView {boolean} True to show the 'Store Details' view
 * @param loadPostOffices {callback} Load Post Offices into store
 * @param loadDeliveryMethods {callback} Load delivery methods into Redux
 * @param collectionDetails {object} Name and phone of the person who will collect the C&C item.
 * @param updateCollectionDetails {callback} Update redux with new collection details
 * @param onClose {callback} Closes the layer this page is in.
 * @param showTitleBar {boolean} Whether to show the title 'Find a Store'.
 * @param mobileFullHeight {boolean} Whether to have this component take maximum height on mobile (needed on the Delivery page as the layer takes full space, but not as an in-page component in the ExpressPaymentPage)
 * @param showStoreDetailsInCAndCView {boolean} If true, the layer showing the Store details will be rendered here. Set to false if it needs to be rendered on other components.
 * @param saveClickAndCollectLocation {callback} Saves the address of a (non-JD) C&C location.
 * @param showMarketingCheckbox {boolean} Will show the opt-in marketing box if it is also enabled at fascia level. Defaults to true.
 */
function ClickAndCollect({
    stores, loadStores, appendStores, appendPostOffice, selectStore, selectedStore,
    storeForDetailedView, selectStoreForDetailedView,
    showStoreDetailedView, loadPostOffices, loadDeliveryMethods,
    collectionDetails, updateCollectionDetails,icon,
    onClose, showTitleBar = true, mobileFullHeight,
    showStoreDetailsInCAndCView, saveClickAndCollectLocation,
	showMarketingCheckbox,
    }: ClickAndCollectProps & ClickAndCollectMapToProps) {

    const config = useSelector((state: RootState) => state.config);
    const delivery = useSelector((state: RootState) => state.delivery);
    const billing = useSelector((state: RootState) => state.billing);
    const translation = useSelector((state: RootState) => state.translation);
    const customer = useSelector((state: RootState) => state.customer);
    const cart = useSelector((state: RootState) => state.cart);
    const hideDayTabs = !config.click_and_collect_tabs;
    const isMarketingOptInEnabled = getShowMarketingCheckboxes(config);
    const totalNumberOfStores = getStoresCount(delivery);
    const isOnExpressJourney = getIsOnExpressJourney(billing);
    const [isStoreListVisible, setStoreListVisible] = React.useState(false);
    const [locationInput, setLocationInput] = React.useState('');
    const [postcodeInvalid, setPostcodeInvalid] = React.useState(true);
    const [coordinateInput, setCoordinateInput] = React.useState({
        latitude: '',
        longitude: ''
    });
    const [detailsTyped, setdetailsTyped] = React.useState({
        firstName: collectionDetails.firstName?? '',
        lastName: collectionDetails.lastName?? '',
        phone: collectionDetails.phone?? '',
        notYouCollectingFirstName: collectionDetails.notYouCollectingFirstName?? '',
        notYouCollectingLastName: collectionDetails.notYouCollectingLastName?? '',
        notYouCollectingPhone: collectionDetails.notYouCollectingPhone?? '',
    });
    const [saveContinueDisabled, setSaveContinueDisabled] = React.useState(true);
    const [positionCallbackClicked, setPositionCallbackClicked] = React.useState(false);
	const [showCollectionDetails, setShowCollectionDetails] = React.useState(false);
    const meshStores = getStores(delivery); // Exclude post office locations.
    const meshPostOffices = getStores(delivery);
    // Will hold a reference of the outer-most box, passed to the layer so the layer affects only the main area of the screen.
	const refContainer = useRef<HTMLDivElement>(null);
    const [isSameDayCC, setIsSameDayCC] = React.useState(false);
    const isNikeConnected = config.nike_connected;
    const strings = {
        findStore: translate(translation, 'findStore', 'Find a Store'),
        findStoreNearMe: translate(translation, 'findStoreNearMe', 'Find a Store Near Me'),
        priceDetails: translate(translation, 'priceDetails', 'Collect for FREE.'),
        deliveryDetails: translate(translation, 'deliveryDetails', 'Order before 2PM for Same Day Collection'),
        storeAddress: translate(translation, 'storeAddress', 'Store Address'),
        whenCollectOrder: translate(translation, 'whenCollectOrder', 'When would you like to collect your order?'),
        collectionDetails: translate(translation, 'collectionDetails', 'Collection Details'),
        collectorDetails: translate(translation, 'collectorDetails', 'Collector details'),
        firstName: translate(translation, 'firstName', 'First Name'),
        lastName: translate(translation, 'lastName', 'Last Name'),
        contactNumber: translate(translation, 'contactNumber', 'Contact Number'),
        contactForOrderUpdates:translate(translation, 'contactForOrderUpdates', 'We will contact you with updates on your order'),
        cAndCCollectionInstructions: translate(translation, 'cAndCCollectionInstructions', 'You need to bring ID and your order receipt when collecting your order. You can nominate someone else to collect your order.'),
        saveContinue: translate(translation, 'saveContinue', 'Save and Continue'),
        collectionPointNearest: translate(translation, 'collectionPointNearest', 'Your nearest collection point'),
        collectionPointSameDay: translate(translation, 'collectionPointSameDay', 'Your nearest available store'),
        collectionPointsNear: translate(translation, 'collectionPointsNear', 'Collection points near you'),
        loadMore: translate(translation, 'loadMore', 'Load More'),
        today: translate(translation, 'today', 'Today'),
        tomorrow: translate(translation, 'tomorrow', 'Tomorrow'),
        anytime: translate(translation, 'anytime', 'Anytime'),
		marketingOptInSms: translate(translation, 'marketingOptInSms', 'We will share our latest launches, offers and new drops with you via SMS, unless you untick this box.'),
		noStoresFound: translate(translation, 'noStoresFound', 'Sorry no stores available'),
		postcodeIsInvalid: translate(translation, 'postcodeIsInvalid', 'Please enter a valid postcode'),
		chooseAlternativeDeliveryMethod: translate(translation, 'chooseAlternativeDeliveryMethod', 'Please choose and alternative delivery method, or search for a different location.'),
		notYouCollecting: translate(translation, 'notYouCollecting', 'Not you collecting?'),
		notYouCollectingInstructions: translate(translation, 'notYouCollectingInstructions', 'If it\'s not you collecting this order we will need the collectors name and phone number. They will need to bring their ID and your order receipt.'),
		phoneHelperText: translate(translation, 'phoneHelperText', '8 - 14 characters and should only contain numbers or a “+” symbol'),
		miles: translate(translation, 'miles', 'Miles')
    };

    //UI
    showMarketingCheckbox = showMarketingCheckbox === undefined ? true : showMarketingCheckbox;
    const showMarketingCheckboxesForFascia = getShowMarketingCheckboxes(config);
    const customerPreferences = getCustomerPreferences(customer);
    const initialSmsOptIn = getAddresses(customer).length === 0
        ? getMarketingSmsOptout(config) // New/guest users (with no address yet) have not formally opted in but will see the box ticked/unticked based on firebase config.
        : getMarketingOptIns(customer)?.smsOptIn ?? true;
    const [isSMSOptInTicked, setIsSMSOptInTicked] = React.useState(initialSmsOptIn);
    const size = React.useContext(ResponsiveContext);
    const theme: CustomTheme = useContext(ThemeContext);
    const desktopBoxWidth = theme?.config?.desktop?.boxWidth ?? "80vw";
	const isDesktop = size !== 'small';

    // Check if selectedStore and collectionDetails contain actual data (not {})
    const isStoreSelected = Object.keys(selectedStore).length > 0;
    const isCollectionDetailsSelected = Object.keys(collectionDetails).length > 0;

    // Get store descriptions from firebase config
    const storeDescriptions = getStoreDescriptions(config);
    
    const dispatch: AppThunkDispatch = useDispatch();
    const isNotYouCollecting = getIsNotYouCollecting(delivery);
    
    let selectedStoreAddress = '';
    if (isStoreSelected) {
        selectedStoreAddress = '' + selectedStore?.address?.address1;
        selectedStoreAddress += selectedStore?.address?.address2 ? `, ${selectedStore?.address?.address2}` : '';
        selectedStoreAddress += `, ${selectedStore?.address?.postcode}`;
    }

    /**
     * Callback executed on each keystroke inside the 'Find a Store' input.
     * Will load store items by making external API calls.
     * @param event Contains the keyword entered by the user
     */
    const onStoreLocationInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const MIN_CHAR_LENGTH = 4;

        if (event.target.value.length < MIN_CHAR_LENGTH) {
			if (isStoreListVisible) setStoreListVisible(false);
			setPostcodeInvalid(true);
            return;
		}
	
		if (delivery.selectedMethod.needsConfirmation) { // remove ID check when platform is ready
			if (!isStoreListVisible) setStoreListVisible(true);
			setPostcodeInvalid(false);
		} else {
			if (delivery.selectedMethod.type === 'POLC') {
				loadPostOfficesWithDelay(event.target.value);
			} else{
				loadStoresWithDelay(event.target.value);
            }
			if (!isStoreListVisible) setStoreListVisible(true);
			setPostcodeInvalid(false);
		}
        
    };

	const MULTI_CALLS_DELAY = 500; // TODO use config

    /**
     * Loads stores from the external API after a cooldown period.
     * @param query A location to search.
     */
    const loadStoresWithDelay = useMemo(
        () => debounce(MULTI_CALLS_DELAY, (query: string | Coordinates) => {
            loadStores(query);
            tracking().trackPickupLocationSearch(typeof query === 'string' ? 'text' : 'location');
        }
    ), [loadStores]);

    /**
     * Loads Post Offices from the external API after a cooldown period.
     * @param query A location to search.
     */
    const loadPostOfficesWithDelay = useMemo(
        () => debounce(MULTI_CALLS_DELAY, (query: string | Coordinates) => {
            loadPostOffices(query);
        }
    ), [loadPostOffices]);

    const onSelectStore = async (event: React.MouseEvent) => {
        const selectedStoreId = (event.target as HTMLButtonElement).value;
        selectStore(selectedStoreId);
        const selectedMethod = getSelectedMethod(delivery);
        const cartId = getCartId(cart);
        if(!selectedMethod.needsConfirmation) {
            await dispatch(updateExternalCartDeliveryMethod(selectedMethod.ID));
        }
        await dispatch(loadPaymentMethods(cartId));
        newRelicData({ actionName: 'clickandcollect', function: 'onSelectStore', message: 'user clicked select store button'});
        
        //If the C&C location isn't a JD store, we need to save its address in platform, and update the selectedStore ID to the returned ID.
        saveClickAndCollectLocation(selectedStoreId);

        tracking().trackSelectStore(getStoreByStoreId(delivery, selectedStoreId));

         //@ts-ignore
        // If collectionDetails are already set, we close the layer directly and go back to Delivery page.
        if (isCollectionDetailsSelected) {
            onClose(event);
        }

        selectStoreForDetailedView('');
        setPostcodeInvalid(false);
    }

    // Callback executed when clicking on a 'Show Store Details' button. Brings up the Store Details view.
    const onViewStoreDetailsClick = (event: React.MouseEvent) => {
        newRelicData({ actionName: 'clickandcollect', function: 'onViewStoreDetailsClick', message: 'user clicked view store details link'});
        selectStoreForDetailedView((event.currentTarget as HTMLButtonElement).value);
    }

    /**
     * Applies changes to the collection details form (controlled form)
     * @param values Values up-to-date with what has just been typed.
     */
    const onFormChange = (values: unknown) => {
        const newValues = values as CollectionDetails;
        setdetailsTyped({
            firstName: newValues.firstName || '',
            lastName: newValues.lastName || '',
            phone: newValues.phone || '',
            notYouCollectingFirstName: newValues.notYouCollectingFirstName || '',
            notYouCollectingLastName: newValues.notYouCollectingLastName || '',
            notYouCollectingPhone: newValues.notYouCollectingPhone || '',
        });
        setSaveContinueDisabled(true);
        
        if(isNotYouCollecting) {
            if (newValues.notYouCollectingFirstName && newValues.notYouCollectingLastName && newValues.notYouCollectingPhone) {
                setSaveContinueDisabled(false);
            }
        } else {
            if (newValues.firstName && newValues.lastName && newValues.phone) {
                setSaveContinueDisabled(false);
            }
        }
    }
    
    const onSubmitForm = async (event: any) => {
        updateCollectionDetails(detailsTyped);
        setShowCollectionDetails(true);
        let registeredUserPrefrences = true;
        // Ensighten update
        const currentMarketingOptIns = getMarketingOptIns(customer)
        !isNikeConnected && dispatch(updateMarketingOptIns({smsOptIn: isSMSOptInTicked}));
        const ensightenCustomer = clone()(customer); // Clone to avoid having customer not yet updated from the dispatch above.
        if (ensightenCustomer.marketingOptIns) ensightenCustomer.marketingOptIns.smsOptIn = isSMSOptInTicked;
        // Ensighten needs the store physical address but the phone and name of the collection details
        const addressToTrack: Address = {
            ID: '',
            firstName: detailsTyped.firstName,
            lastName: detailsTyped.lastName,
            email: ensightenCustomer.email,
            phone: detailsTyped.phone,
            country: selectedStore.address.country,
            address1: selectedStore.address.address1,
            address2: selectedStore.address.address2 ?? undefined,
            address3: selectedStore.address.address3 ?? undefined,
            town: selectedStore.address.town,
            county: selectedStore.address.county ?? undefined,
            postcode: selectedStore.address.postcode,
            isDefaultDeliveryAddress: false,
            isDefaultBillingAddress: false,
            locale: selectedStore.address.locale,
        };
        if (isNikeConnected && Object.keys(customerPreferences as CustomerPreferences).length > 0) {
            registeredUserPrefrences = false;
        } 
        tracking()?.trackAddNewAddress(addressToTrack, ensightenCustomer, registeredUserPrefrences ? isMarketingOptInEnabled : false, isOnExpressJourney, isNikeConnected);
   
        // Mesh customer update
        const hasSmsOptInChanged = isSMSOptInTicked !== currentMarketingOptIns?.smsOptIn;
        if (!isNikeConnected && isMarketingOptInEnabled && hasSmsOptInChanged) {
            dispatch(saveMarketingOptInChoice(customer, {
                ...currentMarketingOptIns,
                smsOptIn: isSMSOptInTicked,
            }));
        }
        newRelicData({ actionName: 'clickandcollect', function: 'onSubmitForm', message: `collection details provided`});
        onClose(event);
        
        const nominatedCollector = isNotYouCollecting ?
            {
                firstName: detailsTyped.notYouCollectingFirstName,
                lastName: detailsTyped.notYouCollectingLastName,
                phone: detailsTyped.notYouCollectingPhone
            } :
            {
                firstName: detailsTyped.firstName,
                lastName: detailsTyped.lastName,
                phone: detailsTyped.phone,
            }

        let updates = {
            delivery: {
                deliveryMethodID: getSelectedMethod(delivery).ID,
                nominatedCollector: nominatedCollector,
            }
        } as CartUpdates;
        if (delivery.selectedMethod.type !== "POLC") {
            updates = {...updates, delivery: {...updates.delivery, storeID: selectedStore.ID}} as unknown as CartUpdates;
        }

        await carts().updateCart(cart.ID, updates);
        
    }

    useEffect(() => {
        if(isCollectionDetailsSelected) {
            setShowCollectionDetails(true);
            setdetailsTyped({
                firstName: collectionDetails.firstName || '',
                lastName: collectionDetails.lastName || '',
                phone: collectionDetails.phone || '',
                notYouCollectingFirstName: collectionDetails.notYouCollectingFirstName || '',
                notYouCollectingLastName: collectionDetails.notYouCollectingLastName || '',
                notYouCollectingPhone: collectionDetails.notYouCollectingPhone || '',
            });
        }

        // eslint-disable-next-line
    }, [isCollectionDetailsSelected]);

    useEffect(() => {
        if(isNotYouCollecting || detailsTyped.firstName || detailsTyped.lastName || detailsTyped.phone) {
            setSaveContinueDisabled(true);
        }

        if(!isNotYouCollecting && detailsTyped.firstName && detailsTyped.lastName && detailsTyped.phone) {
            setSaveContinueDisabled(false);
        }

        if(!isNotYouCollecting && !detailsTyped.firstName  && !detailsTyped.lastName && !detailsTyped.phone) {
            setSaveContinueDisabled(true);
        }

        // eslint-disable-next-line
    }, [isNotYouCollecting]);

    return (
        <Box
            ref={refContainer}
            background={isDesktop ? "" : "light-1"}
            width={isDesktop ? desktopBoxWidth : "100vw"}
            height={!isDesktop && mobileFullHeight ? "100vh" : undefined}
            overflow={isDesktop ? "" : "auto"}
            responsive={false}
        >

            { showTitleBar && !isDesktop &&
            <TitleBar
                title={delivery.selectedMethod.needsConfirmation ? strings.findStoreNearMe : strings.findStore}
                icon={<Anchor><Close onClick={onClose} /></Anchor>}
                borderSide={isDesktop ? theme?.config?.desktop?.borderSide ?? 'bottom' : 'bottom'}
            />

            }

            <Step
                title={delivery.selectedMethod.needsConfirmation ? '' : strings.storeAddress}
                showBorder={false}
                showPadding={!isDesktop}
            >
                <Box flex="grow" background="white">
					{/* Don't show the location button if delivery method needs further confirmation */}
                    {!isStoreSelected  &&
						<LocationInput
                        disableGeolocate={delivery.selectedMethod.needsConfirmation}
						onClickLocation={() => {
							
							setPositionCallbackClicked(true);

							const successCallback: PositionCallback = (position) => {
								let coords = {
									latitude: position.coords.latitude.toString(),
									longitude: position.coords.longitude.toString()
								} as Coordinates;

								//Cast the position data to a coordinates object
								setCoordinateInput({
									latitude: coords.latitude,
									longitude: coords.longitude,
								});

								//Load all stores and Post Offices
								loadStoresWithDelay(coords);
								if(delivery.selectedMethod.type === 'POLC') {
								    loadPostOfficesWithDelay(coords);
								}

								//Display the loaded stores.
								if (!isStoreListVisible) setStoreListVisible(true);
								setPositionCallbackClicked(false);
							};
							const errCallback: PositionErrorCallback = (err) => {
								dispatch(updateNotification('geolocation_error'));
								setPositionCallbackClicked(false);
							};

							navigator.geolocation.getCurrentPosition(successCallback, errCallback);
                        }}
                            isLoading={positionCallbackClicked}
						onChange={async (event) => {
                            setIsSameDayCC(false);

                            setLocationInput(event.target.value);

							onStoreLocationInputChange(event);
							
							const selectedClickAndCollectMethod = delivery.selectedMethod;
							// needsConfirmation is an attribute which is returned from the call to GET carts/:cartID/deliveryOptions for each C&C delivery Option
							// If the delivery Method needs confirmation we need to make another call to deliveryMethods/:deliveryMethodID/deliveryAvailabilityCheck
                            
							if (selectedClickAndCollectMethod.needsConfirmation) {
								setIsSameDayCC(true);
                                setPostcodeInvalid(false);
                                const deliveryMethodID = selectedClickAndCollectMethod.ID;
                                try {
                                    //make the call to the new endpoint /deliveryAvailabilityCheck get back availability true/false and list of stores    
                                    // This currently makes the call but only returns {"available":true} as platform is WIP    
                                    const isDeliveryMethodNotAvailable = await dispatch(updateDeliveryMethodAvailability(deliveryMethodID, event.target.value));
                                    if(isDeliveryMethodNotAvailable) {
                                        setPostcodeInvalid(true);
                                        dispatch(updateStores([]))
                                        dispatch(updatePostOffices([]))
                                    } else {
                                        setPostcodeInvalid(false);
                                    }   
                                } catch (error) {
                                    console.log(error);
                                }
							} else {
								//for standard flow
								onStoreLocationInputChange(event);
								setLocationInput(event.target.value);
								updateExternalCartDeliveryMethod(selectedClickAndCollectMethod.ID, locationInput);
							}
									
						}}
                        />
                    }
                    {isStoreSelected &&
                        <>
                            <EditableDetails
                                title={selectedStore.name}
                                id={selectedStore.ID}
                                extra={
                                    <Anchor>
                                    <Edit
                                        onClick={() => {
                                            selectStore('');
                                            selectStoreForDetailedView('');
                                        }} />
                                    </Anchor>
                                }
                                main={selectedStoreAddress}
                                isDetailsButton={!(isDesktop && showStoreDetailedView && storeForDetailedView.ID === selectedStore.ID)}
                                onDetailsClick={onViewStoreDetailsClick}
                                showPadding={false}
                            />
                            {isDesktop && showStoreDetailedView && storeForDetailedView.ID === selectedStore.ID &&
                                <Box margin={{top: 'large'}}>
                                    <StoreDetailsDesktop store={selectedStore} />
                                </Box>
                            }
                        </>
                    }
                </Box>
            </Step>

            {isStoreListVisible && !isStoreSelected && stores.length >= 1 &&
                <Box background="white">
                    {hideDayTabs
                    ?
                    makeStoreList()
                    :
                    <>
                        <Text margin={{ vertical: "small", horizontal: "medium" }}>{strings.whenCollectOrder}</Text>
                        <Tabs
                            justify="center"
                            alignControls="stretch"
                            margin={{horizontal: "medium" }}
                        >
                            {makeTab(TODAY)}
                            {makeTab(TOMORROW)}
                            {makeTab(ANYTIME)}
                        </Tabs>
                    </>
                    }
                </Box>
			}
			{/*  No stores found message */}
			{ ((isStoreListVisible && (delivery.stores.length === 0 && delivery.selectedMethod.type !== 'POLC')) || postcodeInvalid ) &&
				<Box background="white" pad={!isDesktop ? "medium": ''}>
					<Text weight="bold">{postcodeInvalid ? strings.postcodeIsInvalid : strings.noStoresFound}</Text>
					<Text >{strings.chooseAlternativeDeliveryMethod}</Text>
				</Box>
			}
            { isDesktop && isStoreSelected && theme?.config?.stepBorder ? <Separator margin={{ vertical: "small" }} /> : '' }
            {isStoreSelected &&
                <Step
                    title={strings.collectionDetails}
                    showBorder={isDesktop ? false : true}
                    showPadding={!isDesktop}
                    showSeparator={false}
                >
                    <Box
                        fill="horizontal"
                        background="white"
                    >
                        {!showCollectionDetails &&
                            <Form
                                value={detailsTyped}
                                onChange={onFormChange}
                                onSubmit={onSubmitForm}
                            >
                                {/* Info box */}
                                <Box
                                    direction="row"
                                    margin={{vertical: 'large'}}
                                >
                                    <Box flex={false} margin={{right: 'xxsmall'}}>
                                        {icon || <CircleInformation size="small" />}
                                    </Box>

                                    <Text size="small">
                                        {strings.cAndCCollectionInstructions}
                                    </Text>
                                </Box>
                                <ValidatedInput
                                    rules={!isNotYouCollecting ? [{ name: 'required', fieldName: strings.firstName }, maxLength30Rule(strings.firstName)] : []}
                                    name="firstName"
                                    label={strings.firstName}
                                    value={detailsTyped.firstName}
                                />
                                <ValidatedInput
                                    rules={!isNotYouCollecting ? [{ name: 'required', fieldName: strings.lastName }, maxLength30Rule(strings.lastName)] : []}
                                    name="lastName"
                                    label={strings.lastName}
                                    value={detailsTyped.lastName}
                                />
                                <ValidatedInput
                                    rules={!isNotYouCollecting ? [requiredRule(strings.contactNumber), { name: 'phone',fieldName: strings.contactNumber }, maxLength30Rule(strings.contactNumber)] : []}
                                    label={strings.contactNumber}
                                    name="phone"
                                    type="tel"
                                    help={<Text size="small">{strings.phoneHelperText}</Text>}
                                    value={detailsTyped.phone}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                        onFormChange({...detailsTyped, phone: e});
                                    }}
                                />
                                <Box margin={{bottom: 'medium',top: 'medium'}}>
                                    <CheckBox
                                        checked={isNotYouCollecting}
                                        onChange={(event) => {                
                                            dispatch(updateIsNotYouCollecting(event.target.checked));
                                            setdetailsTyped({
                                                firstName: detailsTyped.firstName,
                                                lastName: detailsTyped.lastName,
                                                phone: detailsTyped.phone,
                                                notYouCollectingFirstName:  '',
                                                notYouCollectingLastName:  '',
                                                notYouCollectingPhone:  '',
                                            });
                                        }}
                                        label={<Text size="small">{strings.notYouCollecting}</Text>}
                                    />
                                </Box>
                                { isNotYouCollecting &&
                                    <Box>
                                        <Box direction="row" margin={{vertical: 'large'}}>
                                            <Box flex={false} margin={{right: 'xxsmall'}}>
                                                {icon || <CircleInformation size="small" />}
                                            </Box>
        
                                            <Text size="small">
                                                {strings.notYouCollectingInstructions}
                                            </Text>
                                        </Box>
                                        <Box direction="row" margin="none" pad={{vertical: 'small'}}>
                                            <Text weight={theme?.config?.stepFontWeight ?? 'bold'} 
                                                  size="large">{strings.collectorDetails}
                                            </Text>
                                        </Box>
                                    
                                        <ValidatedInput
                                            rules={[{ name: 'required', fieldName: strings.firstName }, maxLength30Rule(strings.firstName)]}
                                            name="notYouCollectingFirstName"
                                            label={strings.firstName}
                                            value={detailsTyped.notYouCollectingFirstName}
                                        />
                                        <ValidatedInput
                                            rules={[{ name: 'required', fieldName: strings.lastName }, maxLength30Rule(strings.lastName)]}
                                            name="notYouCollectingLastName"
                                            label={strings.lastName}
                                            value={detailsTyped.notYouCollectingLastName}
                                        />
                                        <ValidatedInput
                                            rules={[requiredRule(strings.contactNumber), { name: 'phone',fieldName: strings.contactNumber }, maxLength30Rule(strings.contactNumber)]}
                                            label={strings.contactNumber}
                                            name="notYouCollectingPhone"
                                            help={<Text size="small">{strings.phoneHelperText}</Text>}
                                            type="tel"
                                            value={detailsTyped.notYouCollectingPhone}
                                        />
                                    </Box>
                                }
                                 <InfoBox
                                    text={strings.contactForOrderUpdates}
                                    background="white"
                                />
                                
                                { showMarketingCheckboxesForFascia && showMarketingCheckbox && !isNikeConnected &&
                                    <Box margin={{bottom: 'medium',top: 'medium'}}>
                                        <CheckBox
                                            checked={isSMSOptInTicked}
                                            onChange={(event) => {
                                                setIsSMSOptInTicked(event.target.checked);
                                            }}
                                            label={<Text size="small">{strings.marketingOptInSms}</Text>}
                                        />
                                    </Box>
                                }
                                <Box direction="row" gap="medium">
                                    <Button
                                        primary
                                        type="submit"
                                        label={strings.saveContinue}
                                        fill="horizontal"
                                        disabled={saveContinueDisabled}
                                    />
                                </Box>
                            </Form>
                        }
                        {showCollectionDetails &&
                            <EditableDetails
                                main={
                                    <Box>
                                        {isNotYouCollecting ?
                                        <><Text>{`${(collectionDetails as CollectionDetails).notYouCollectingFirstName} ${(collectionDetails as CollectionDetails).notYouCollectingLastName}`}</Text>
                                        <Text>{(collectionDetails as CollectionDetails).notYouCollectingPhone}</Text>
                                        </>
                                        :
                                        <><Text>{`${(collectionDetails as CollectionDetails).firstName} ${(collectionDetails as CollectionDetails).lastName}`}</Text>
                                        <Text>{(collectionDetails as CollectionDetails).phone}</Text>
                                        </>                   
                                        } 
                                    </Box>
                                }
                                extra={<Anchor><Edit onClick={() => 
                                    {setShowCollectionDetails(false);}
                                } /></Anchor>}
                                showPadding={false}
                            />
                        }
                    </Box>
                </Step>
            }
            { showStoreDetailsInCAndCView && showStoreDetailedView && !isDesktop &&
                // On Desktop, the store details box shows on the right hand side of the Delivery page so is not managed here.
                <Layer
                    target={refContainer.current || undefined}
                    responsive={false}
                    animation="slide"
                    position="bottom"
                >
                    <StoreDetails
                        store={storeForDetailedView}
                        onClose={() => selectStoreForDetailedView('')}
                        onGetDirections={() => { }}
                        onCallStore={() => { }}
                    />
                </Layer>
            }
        </Box>
    );

    function tabTitle(label: string) {
        return (
            <Box width="33vw">
                <Text alignSelf="center">{label}</Text>
            </Box>
        );
    }

    /**
     * Create tab components containing a list of store location items.
     * @param {string} day Either 'Today', 'Tomorrow' or 'Anytime': this will update the items' description.
     */
    function makeTab(day: typeof TODAY | typeof TOMORROW | typeof ANYTIME) {
        let titleLabel = '';
        switch (day) {
            case TODAY:
                titleLabel = strings.today;
                break;
            case TOMORROW:
                titleLabel = strings.tomorrow;
                break;
            case ANYTIME:
                titleLabel = strings.anytime;
                break;
        }
        return (
            <Box width="33%">
                <Tab title={tabTitle(titleLabel)}>
                    {makeStoreList(descriptions[day])}
                </Tab>
            </Box>
        );
    }

    //getIconForStore returns an icon depending on the value of store.locationType
	function getIconForStore(store: Store): JSX.Element {
		
        switch (store.locationType) {
            case CandCLocation.JD:
                let storeLocation = getStoreLocation(store.href);
                //check for stores with different names like jdnfa, jdsfa, jdwfa
                if(storeLocation.match(/jd([^/]+)fa/)){
                    storeLocation = 'footpatrol';
                }
                return getStoreLocationIcon(storeLocation);
            case CandCLocation.PostOffice:
                return <PostOffice />
            default:
                return <Shop />
        }
    }

    function getStoreLocation(storeLocationReference?: string) {
        if(storeLocationReference) {
            const storeLocationPath = storeLocationReference.match(/\/stores\/([^/]+)\/storelocations/);
            if(storeLocationPath?.length) {
                return storeLocationPath[1];
            }
            return '';
        }
        return '';
    }

    function getStoreLocationIcon(storeLocation: string): JSX.Element {
        switch (true) {
            case storeLocation.startsWith("size"):
                return <SizeFascia width='32' height='32'/>;
            case storeLocation.startsWith("hipstore"):
                return <HipstoreFascia width='32' height='32'/>;
            case storeLocation.startsWith("scottsmenswear"):
                return <ScottsFascia width='32' height='32'/>;
            case storeLocation.startsWith("tessuti"):
                return <TessutiFascia width='32' height='32'/>;
            case storeLocation.startsWith("footpatrol"):
                return <FootpatrolFascia  width='50' height='50' />;
            case storeLocation.startsWith("oipolloi"):
                return <OipolloiFascia width='32' height='32' />;   
            case storeLocation.startsWith("wellgosh"):
                return <WellgoshFascia width='32' height='32' />;    
            default:
                return <JDFascia  width='32' height='32' />;
        }
    }

    //getDescriptionForStore returns description depending on the value of store.locationType
    function getDescriptionForStore(store: Store) {
        switch (store.locationType) {
            case CandCLocation.JD:
                if(isSameDayCC){
                    return (
                      <Box direction="column">
                        <Text>{strings.priceDetails}</Text>
                        <Text>{strings.deliveryDetails}</Text>
                      </Box>
                    );
                }else{
                    return storeDescriptions.store;
                }
            case CandCLocation.PostOffice:
				return storeDescriptions.postOffice;
            default:
                return null;
        }
    }

    function makeStoreList(storeDescription?: React.ReactNode) {

        // Change collection point text if it's same day click and collect
        let collectionPointText: string = strings.collectionPointNearest;
        if(isSameDayCC){
            collectionPointText = strings.collectionPointSameDay;
        }

        return(
            <Box align="center" margin={isDesktop ? { top: "large" } : undefined}>
                <Step title={collectionPointText} showBorder={false} showPadding={!isDesktop}>
                    <Box fill='horizontal' border="top" margin={!isDesktop ? {top: 'large', bottom: 'medium'} : ''}>

                        <StoreLocationItem
                            icon={getIconForStore(stores[0])}
                            name={stores[0].name}
                            locationId={stores[0].ID}
                            distance={stores[0].distance}
                            distanceUnits={stores[0].distanceUnits ?? strings.miles}
                            description={storeDescription? storeDescription : getDescriptionForStore(stores[0]) }
                            onDetailsClick={isDesktop && showStoreDetailedView && storeForDetailedView.ID === stores[0].ID ? undefined : onViewStoreDetailsClick}
                            onSelect={onSelectStore}
                        />
                        {isDesktop && showStoreDetailedView && storeForDetailedView.ID === stores[0].ID &&
                            <StoreDetailsDesktop
                                store={stores[0]}
                                margin={{horizontal: 'large'}}
                                showAddress={true}
                            />
                        }
                    </Box>
				</Step>
				
                { stores.length > 1 &&
                    <>
                        <Step title={strings.collectionPointsNear} showBorder={false} showPadding={!isDesktop}>
                            <Box fill='horizontal'>
                                {// show all stores except the first one
                                    stores.filter((store, index) => index > 0).map((store: Store) =>
                                        <Box key={store.name} border="top" margin={!isDesktop ? {top: 'large'} : '' }>
                                            <StoreLocationItem
                                                icon={getIconForStore(store)}
                                                name={store.name}
                                                locationId={store.ID}
                                                distance={store.distance}
                                                distanceUnits={store.distanceUnits ?? strings.miles}
                                                description={storeDescription? storeDescription : getDescriptionForStore(store)}
                                                onDetailsClick={isDesktop && showStoreDetailedView && storeForDetailedView.ID === store.ID ? undefined : onViewStoreDetailsClick}
                                                onSelect={onSelectStore}
                                            />
                                            {isDesktop && showStoreDetailedView && storeForDetailedView.ID === store.ID &&
                                                <StoreDetailsDesktop
                                                    store={store}
                                                    margin={{horizontal: 'large', bottom: 'large'}}
                                                    showAddress={true}
                                                />
                                            }
                                        </Box>
                                    )
                                }
                            </Box>
                        </Step>
                        {meshStores.length < totalNumberOfStores &&
                            <Button
                                label={<TextUnderlined>{strings.loadMore}</TextUnderlined>}
                                onClick={(event) => 
                                    delivery.selectedMethod.type === 'POLC' ?
                                    appendPostOffice(locationInput === '' ? coordinateInput as Coordinates : locationInput, meshPostOffices.length)
                                    :
                                    appendStores(locationInput === '' ? coordinateInput as Coordinates : locationInput, meshStores.length)}
                                plain={true}
                                margin="medium"
                                focusIndicator={false}
                                value='Load More'
                            />
                        }    
                    </>
                }
            </Box>
        );
    }
};

type ClickAndCollectProps = {
    onClose: (event: React.MouseEvent) => void,
    showTitleBar?: boolean,
    mobileFullHeight?: boolean,
    showStoreDetailsInCAndCView?: boolean,
    icon?: React.ReactNode,
	showMarketingCheckbox?: boolean,
	selectedClickAndCollectMethod?: string
}

export default ClickAndCollect;
