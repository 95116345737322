

import { useLocation } from "react-router-dom";

// A custom hook that builds on useLocation to parse
// the query string for you.
export function useQuery() {
    return new URLSearchParams(useLocation().search);
}

/**
 * Gets the current URL, including the port if relevant.
 * @returns {string} The URL
 */
export function getCurrentUrl() {
    const urlPort = window.location.port;
    const isPortNeeded = process.env.REACT_APP_MESH_ENV !== 'prod' && urlPort && urlPort !== '0';
    const urlPortString = isPortNeeded ? `:${urlPort}` : '';

    return `${window.location.protocol}//${window.location.hostname}${urlPortString}`;
}

/**
 * If the app lost all redux data (including the return URL), this function will infer
 * the correct return URL to the engagement layer (main website) based on the current URL.
 * @returns The url of the engagement layer, or '' if nothing could be provided or on localhost.
 */
export function getReturnUrlFromCurrentHostName() {
    const currentHost = window.location.hostname;
    const originHostMatch = currentHost.match(/checkout.(.+)/);
    const originHost = originHostMatch?.[1] ?? '';
    if (currentHost === 'localhost' || !originHost) {
        return '';
    }
    else if (process.env.REACT_APP_MESH_ENV === 'prod' && process.env.REACT_APP_STAGE !== 'staging') {
        return `https://www.${originHost}`; // mobiles will then be redirected to `m.<host>` from there.
    }
    else { // UAT URL
        return `https://${originHost}`;
    }
}
