import { FunctionComponent, SVGProps } from 'react';

import { ReactComponent as AddToBag } from './icons/icon-add-to-bag.svg';
import { ReactComponent as Afterpay } from './icons/logo-payment-afterpay.svg';
import { ReactComponent as Amex } from './icons/logo-payment-amex.svg';
import { ReactComponent as AliPay } from './icons/graphic-logo-payment-ali-pay.svg';
import { ReactComponent as ApplePay } from './icons/logo-payment-apple-pay.svg';
import { ReactComponent as Arrow } from './icons/icon-arrow.svg';
import { ReactComponent as ArrowTessuti } from './icons/icon-arrow-tessuti.svg';
import { ReactComponent as ArrowDisabledTessuti } from './icons/icon-arrow-disabled-tessuti.svg';
import { ReactComponent as ArrowDown } from './icons/icon-arrow-down.svg';
import { ReactComponent as ArrowRight } from './icons/icon-arrow-right.svg';
import { ReactComponent as ArrowUp } from './icons/icon-arrow-up.svg';
import { ReactComponent as Bag } from './icons/icon-bag-outline.svg';
import { ReactComponent as BurgerMenu } from './icons/icon-burger-menu.svg';
import { ReactComponent as Boost } from './icons/logo-payment-boost.svg';
import { ReactComponent as Chat } from './icons/icon-chat.svg';
import { ReactComponent as Close } from './icons/icon-close.svg';
import { ReactComponent as CVV } from './icons/icon-cvv.svg';
import { ReactComponent as Clearpay } from './icons/logo-payment-clearpay.svg';
import { ReactComponent as ClearpayLoop } from './icons/logo-payment-clearpay-loop.svg';
import { ReactComponent as Delivery } from './icons/icon-delivery.svg';
import { ReactComponent as Edit } from './icons/icon-edit.svg';
import { ReactComponent as Help } from './icons/icon-help.svg';
import { ReactComponent as Information } from './icons/icon-alert-information.svg';
import { ReactComponent as Ideal } from './icons/logo-payment-ideal.svg';
import { ReactComponent as JDFascia } from './icons/logo-fascia-jd.svg';
import { ReactComponent as SizeFascia } from './icons/logo-fascia-size.svg';
import { ReactComponent as FootpatrolFascia } from './icons/logo-fascia-footpatrol.svg';
import { ReactComponent as OipolloiFascia } from './icons/logo-fascia-oipolloi.svg';
import { ReactComponent as WellgoshFascia } from './icons/logo-fascia-wellgosh.svg';
import { ReactComponent as HipstoreFascia } from './icons/logo-fascia-hipstore.svg';
import { ReactComponent as ScottsFascia } from './icons/logo-fascia-scotts.svg';
import { ReactComponent as TessutiFascia } from './icons/logo-fascia-tessuti.svg';
import { ReactComponent as Trustly } from './icons/graphic-logo-payment-trustly.svg';
import { ReactComponent as JDNavigation } from './icons/logo-jd-navigation.svg';
import { ReactComponent as Klarna } from './icons/logo-klarna.svg';
import { ReactComponent as KlarnaLogo } from './icons/logo-payment-klarna.svg';
import { ReactComponent as Laybuy } from './icons/logo-payment-laybuy.svg';
import { ReactComponent as Giropay } from './icons/logo-payment-giropay.svg';
import { ReactComponent as Shopback } from './icons/logo-payment-shopback.svg';
import { ReactComponent as Fpx } from './icons/logo-payment-razerpay.svg';
import { ReactComponent as Mastercard } from './icons/logo-payment-mastercard.svg';
import { ReactComponent as Maestro } from './icons/logo-payment-maestro.svg';
import { ReactComponent as KlarnaDebitRisk } from './icons/logo-payment-klarna-debit-risk.svg';
import { ReactComponent as Zippay } from './icons/logo-payment-zippay.svg';
import { ReactComponent as MyAccount } from './icons/icon-my-account.svg';
import { ReactComponent as Nike } from './icons/logo-nike.svg';
import { ReactComponent as Openpay } from './icons/logo-payment-openpay.svg';
import { ReactComponent as PayPal } from './icons/logo-payment-pay-pal.svg';
import { ReactComponent as PayPalPayInThree } from './icons/logo-payment-pay-pal-payinthree.svg';
import { ReactComponent as GooglePay } from './icons/google-pay-mark.svg';
import { ReactComponent as Sale } from './icons/icon-sale.svg'; 
import { ReactComponent as SearchNavigation } from './icons/icon-search-navigation.svg';
import { ReactComponent as Security } from './icons/icon-security-fill.svg';
import { ReactComponent as SecurityLock } from './icons/icon-security-lock.svg';
import { ReactComponent as SecurityOutline } from './icons/graphic-icon-account-security-outline.svg';
import { ReactComponent as Shop } from './icons/icon-store.svg';
import { ReactComponent as Facebook } from './icons/icon-social-facebook.svg';
import { ReactComponent as Refresh } from './icons/icon-action-refresh.svg';
import { ReactComponent as Returns } from './icons/icon-returns.svg';
import { ReactComponent as Twitter } from './icons/icon-social-twitter.svg';
import { ReactComponent as Tagstar } from './icons/icon-tagstar.svg';
import { ReactComponent as TagstarInDemand } from './icons/tagstar-in-demand.svg';
import { ReactComponent as QuantityMinus } from './icons/icon-quantity-minus-fill.svg';
import { ReactComponent as QuantityPlus } from './icons/icon-quantity-plus-fill.svg';
import { ReactComponent as Visa } from './icons/logo-payment-visa.svg';
import { ReactComponent as TickFilled } from './icons/icon-action-tick-filled.svg';
import { ReactComponent as TickFilledChoice } from './icons/icon-action-tick-filled-choice.svg';
import { ReactComponent as TickFilledBase } from './icons/icon-action-tick-filled-base.svg';
import { ReactComponent as TickFilledBlackRounded } from './icons/icon-action-tick-filled-black-rounded.svg';
import { ReactComponent as TickSuccess } from './icons/icon-action-tick-success.svg';
import { ReactComponent as TickError } from './icons/icon-action-tick-error.svg';
import { ReactComponent as TickEmpty } from './icons/icon-radio-inactive.svg';
import { ReactComponent as TickEmptyChoice } from './icons/icon-radio-inactive-choice.svg';
import { ReactComponent as UnionPay } from './icons/graphic-logo-payment-union-pay.svg';
import { ReactComponent as WeChat } from './icons/graphic-logo-payment-we-chat.svg';
import { ReactComponent as PostOffice } from './icons/logo-store-post-office.svg';
import { ReactComponent as Heart } from './icons/icon-heart.svg';
import { ReactComponent as Preferences } from './icons/icon-preferences.svg';
import { ReactComponent as Order } from './icons/icon-order.svg';
import { ReactComponent as HidePassword } from './icons/icon-password-hidden.svg';
import { ReactComponent as ShowPassword } from './icons/icon-password-show.svg';
import { ReactComponent as Location } from './icons/icon-location.svg';
import { ReactComponent as Touchn } from "./icons/logo-payment-touchn.svg";
import { ReactComponent as Maybank } from './icons/logo-payment-maybank.svg';
import { ReactComponent as Grabpay } from './icons/logo-payment-grabpay.svg';

/**
 * 
 */
interface IconMap {
    [key: string]: FunctionComponent<SVGProps<SVGSVGElement>>
}

export default {

    "add-to-bag": AddToBag,
    "afterpay": Afterpay,
    "amex": Amex,
    "ali-pay": AliPay,
    "apple-pay": ApplePay,
    "arrow": Arrow,
    "arrow-tessuti": ArrowTessuti,
    "arrow-disabled-tessuti": ArrowDisabledTessuti,
    "arrow-down": ArrowDown,
    "arrow-up": ArrowUp,
    "arrow-right": ArrowRight,
    "bag": Bag,
    "burger-menu": BurgerMenu,
    "boost": Boost,
    "chat": Chat,
    "close": Close,
    "cvv": CVV,
    "clearpay": Clearpay,
    "clearpay-loop": ClearpayLoop,
    "delivery": Delivery,
    "edit": Edit,
    "facebook": Facebook,
    "help": Help,
    "information": Information,
    "ideal": Ideal,
    "jd-fascia": JDFascia,
    "size-fascia": SizeFascia,
    "footpatrol-fascia": FootpatrolFascia,
    "oipolloi-fascia": OipolloiFascia,
    "wellgosh-fascia": WellgoshFascia,
    "hipstore-fascia": HipstoreFascia,
    "scotts-fascia": ScottsFascia,
    "tessuti-fascia": TessutiFascia,
    "trustly": Trustly,
    "jd-navigation": JDNavigation,
    "klarna": Klarna,
    "klarna-logo": KlarnaLogo,
    "laybuy": Laybuy,
    "giropay": Giropay,
    "shopback": Shopback,
    "fpx":Fpx,
    "maestro": Maestro,
    "klarna-debit-risk": KlarnaDebitRisk,
    "zippay": Zippay,
    "mastercard": Mastercard,
    "my-account": MyAccount,
    "nike": Nike,
    "openpay": Openpay,
    "paypal": PayPal,
    "paypal-pay-in-three": PayPalPayInThree,
    "googlepay": GooglePay,
    "refresh": Refresh,
    "returns": Returns,
    "sale": Sale,
    "search-navigation": SearchNavigation,
    "security-fill": Security,
    "security-lock": SecurityLock,
    "security-outline": SecurityOutline,
    "shop": Shop,
    "tagstar": Tagstar,
    "tagstar-in-demand": TagstarInDemand,
    "tick-filled": TickFilled,
    "tick-filled-choice": TickFilledChoice,
    "tick-filled-base": TickFilledBase,
    "tick-filled-black-rounded": TickFilledBlackRounded,
    "tick-success": TickSuccess,
    "tick-error": TickError,
    "tick-empty": TickEmpty,
    "tick-empty-choice": TickEmptyChoice,
    "twitter": Twitter,
    "quantity-minus": QuantityMinus,
    "quantity-plus": QuantityPlus,
    "visa": Visa,
    "union-pay": UnionPay,
    "we-chat": WeChat,
    "post-office": PostOffice,
    "heart": Heart,
    "preferences": Preferences,
    "order": Order,
    "hide-password": HidePassword,
    "show-password": ShowPassword,
    "location": Location,
    "touchn": Touchn,
    "maybank": Maybank,
    "grabpay": Grabpay,
} as IconMap;