import React, {useContext, useState} from 'react';
import clone from 'rfdc';
import { Box, Anchor, Button, CheckBox, ResponsiveContext, Text, RadioButton} from 'grommet';
import { useSelector, useDispatch } from 'react-redux';
import { AppThunkDispatch } from '../../modules/thunk';
import { RootState } from '../../store/reducers';
import {
    getAddresses,
    updateExternalAddress,
    getCustomerID,
    getAddressById,
    getAddressBookType,
} from '../../modules/customer';
import {
    updateDeliveryAddress, loadDeliveryMethods,
    validateDeliveryAddressAndSaveToCart, getDeliveryAddress,
} from '../../modules/delivery';
import { updateBillingAddress } from '../../modules/billing';
import { Address } from '../../modules/customer/types';
import { Close } from '../Icon/index';
import TitleBar from '../TitleBar/TitleBar';
import Step from '../Step/Step';
import Separator from '../Separator/Separator';
import FormattedAddress from '../FormattedAddress/FormattedAddress';
import EditableDetails from '../EditableDetails/EditableDetails';
import LoadingIcon from '../LoadingIcon/LoadingIcon';
import { updateNotification } from '../../modules/notification';
import { ThemeContext } from 'styled-components';
import { CustomTheme } from '../../theme';
import { translate } from '../../modules/translation';
import newRelicData from '../../newrelic';
/**
 * Layer for the Delivery page showing a list of user addresses
 * @param onClose Callback to close the layer and return to the Delivery page.
 * @param onAddNewAddress Callback executed when clicking on the 'Add New Address' button.
 * @param onEditAddress Callback executed when clicking on the 'Edit' icon of an address.
 */
function AddressBook({ onClose, onAddNewAddress, onEditAddress }: AddressBookProps) {
    const customer = useSelector((state: RootState) => state.customer);
    const translation = useSelector((state: RootState) => state.translation);
    const addresses = getAddresses(customer);
    const customerID = getCustomerID(customer);
    const addressBookType = getAddressBookType(customer);
    const billing = useSelector((state: RootState) => state.billing);
    const delivery = useSelector((state: RootState) => state.delivery);
    const theme: CustomTheme = useContext(ThemeContext);
    const size = React.useContext(ResponsiveContext);
    const dispatch: AppThunkDispatch = useDispatch();
    const isDesktop = size !== 'small';

    const [isAddressChanged, setIsAddressChanged] = useState(false);
    const [isLoadInProgress, setIsLoadInProgress] = useState(false);

    const strings = {
        LAYER_TITLE: translate(translation, 'addressBook', 'My Address Book'),
        ADD_NEW_ADDRESS: translate(translation, 'addNewAddress', 'Add New Address'),
        SAVE_ADDRESS: translate(translation, 'saveContinue', 'Save and Continue'),
        setDefaultDeliveryAddress: translate(translation, 'setDefaultDeliveryAddress', 'Set as default delivery address'),
        setDefaultBillingAddress: translate(translation, 'setDefaultBillingAddress', 'Set as default billing address'),
        edit: translate(translation, 'edit', 'Edit'),
    };
    
    /**
     * Change the defaut delivery or default billing address.
     * This change will make the new address selected for the checkout session.
     * @param address to make the default one
     * @param type Whether this address should be considered a billing or a delivery address
     */
    async function changeDefaultAddresses(address: Address, type: 'billing'|'delivery') {
        const newAddress: Address = clone()(address);
        if (type === 'delivery') {
            newAddress.isDefaultDeliveryAddress = true;
            dispatch(updateDeliveryAddress(newAddress));
        }
        else if (type === 'billing') {
            newAddress.isDefaultBillingAddress = true;
            dispatch(updateBillingAddress(newAddress));
        }
        await dispatch(updateExternalAddress(customerID, newAddress));
    }

    return(
        <Box
            background={isDesktop ? "" : "light-1"}
            width="100vw"
            height={isDesktop ? "" : "100vh"}
            overflow={isDesktop ? "" : "scroll"}
            responsive={false}
            margin={{ bottom: 'medium' }}
            border= {isDesktop
                ? (theme?.config?.desktop?.boxBorder?? false)
                : false}
        >
            
                <TitleBar
                    title={strings.LAYER_TITLE}
                    icon={<Anchor><Close onClick={onClose} /></Anchor>}
                    borderSide={isDesktop
                        ? theme?.config?.desktop?.borderSide ?? 'bottom'
                        : 'bottom'}
                />

            <Step showBorder={false}>
                {/* 'Add New Address' button */}
                <Box flex="grow" direction="column">
                    <Button
                        label={strings.ADD_NEW_ADDRESS}
                        a11yTitle={strings.ADD_NEW_ADDRESS}
                        onClick={onAddNewAddress}
                        fill={true}
                    />
                    <Separator margin={{ vertical: 'large' }} />
                    {addresses.map((address, index) => {
                        return (
                            <Box key={index} direction="row" align='start'>
                                <RadioButton name='radio' 
                                    value={address.ID}
                                    checked={addressBookType === 'delivery' ? delivery.address.ID === address.ID  : billing.address.ID === address.ID} 
                                    onChange={async (event) => {
                                        const address = getAddressById(customer, event.target.value)
                                        if (address?.ID && addressBookType === 'billing') {
                                            dispatch(updateBillingAddress(address));
                                            newRelicData({ actionName: 'AddressBook', function: 'onChange', message:'user changed billing address', billingAddressId: address?.ID });
                                            setIsAddressChanged(true);
                                        }
                                        else if (address?.ID && addressBookType === 'delivery') {
                                            setIsLoadInProgress(true);
                                            const isAddressChangeOk = await dispatch(validateDeliveryAddressAndSaveToCart(address, getDeliveryAddress(delivery)));
                                            if (isAddressChangeOk) {
                                                if (address?.postcode) await dispatch(loadDeliveryMethods({location: address.postcode}, address.locale));
                                                setIsAddressChanged(true);
                                            }
                                            setIsLoadInProgress(false);
                                            newRelicData({ actionName: 'AddressBook', function: 'onChange', message:'user changed delivery address', deliveryAddressId: address?.ID });
                                            return;
                                        }
                                    }}
                                />
                                <Box pad={{left: "large"}} fill="horizontal">
                                    <EditableDetails
                                        main={
                                            <Box>
                                                <FormattedAddress
                                                    type="homeAddress"
                                                    details={address}
                                                />
                                            </Box>
                                        }
                                    />
                                    <Box pad={{top: "small"}}>
                                        <CheckBox
                                            checked={address.isDefaultDeliveryAddress}
                                            onChange={async (event) => {
                                                if (event.target.checked) {
                                                    setIsLoadInProgress(true);
                                                    const newAddress = clone()(address);
                                                    newAddress.isDefaultDeliveryAddress = true;
                                                    const isAddressChangeOk = await dispatch(validateDeliveryAddressAndSaveToCart(newAddress, getDeliveryAddress(delivery)));
                                                    if (isAddressChangeOk) {
                                                        if (newAddress?.postcode) await dispatch(loadDeliveryMethods({location: newAddress.postcode}, newAddress.locale));
                                                        await dispatch(updateExternalAddress(customerID, newAddress));
                                                        setIsAddressChanged(true);
                                                    }
                                                    setIsLoadInProgress(false);
                                                }
                                                // Do nothing on un-tick actions as we can't have no default address.
                                            }}
                                            label={<Text size="small">{strings.setDefaultDeliveryAddress}</Text>}
                                        />
                                        <Box pad={{top: "xxsmall"}} direction="row">
                                            <CheckBox
                                                checked={address.isDefaultBillingAddress}
                                                onChange={async (event) => {
                                                    if (event.target.checked) {
                                                        setIsLoadInProgress(true);
                                                        try {

                                                            await changeDefaultAddresses(address, 'billing');
                                                            setIsAddressChanged(true);
                                                        }
                                                        catch(error) {
                                                            newRelicData({ actionName: 'addressBook', function: 'onChange', message: { msg: (error as Error)?.message, type: 'unable to update address' }})
                                                            dispatch(updateNotification('address_error'));
                                                            console.error(error);
                                                        }
                                                        setIsLoadInProgress(false);
                                                    }
                                                }}
                                                label={<Text size="small">{strings.setDefaultBillingAddress}</Text>}
                                            />
                                            <Box align="end" flex="grow">
                                                <Anchor size="xsmall" onClick={() => onEditAddress(address.ID ?? '')}>
                                                    {strings.edit}
                                                </Anchor>
                                            </Box>
                                        </Box>
                                    </Box>
                                    {index < addresses.length - 1 && <Separator margin={{ top: 'medium', bottom: 'large' }} />}
                                </Box>
                            </Box>
                        );
                    })}
                    {<Separator margin={{ vertical: 'medium' }} />}
                    <Button
                        primary disabled={!isAddressChanged}
                        label={isLoadInProgress ? <LoadingIcon /> : strings.SAVE_ADDRESS}
                        fill={true}
                        a11yTitle={strings.SAVE_ADDRESS}
                        onClick={onClose}
                    />
                </Box>
            </Step>
        </Box>
    );
}

type AddressBookProps = {
    onClose: (event: React.MouseEvent) => void,
    onAddNewAddress: (event: React.MouseEvent) => void,
    onEditAddress: (addressId: string) => void,
}

export default AddressBook;
