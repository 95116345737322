import { AxiosInstance, AxiosResponse } from 'axios';
import { MeshConfig } from './types';


type MoneyAmount = {
    amount: string,
    currency: string,
};

type Image ={
    ID: string,
    altText: string|null,
    originalURL: string,
    resizingService: {
        href: string,
        originalURL: string,
        properties: {
            width: string,
            height: string,
        }
    },
    tags: []
}
export type OrderProductMeshResponse = {
    ID: string,
    SKU: string,
    status: string,
    clientID: string,
    GTIN: string,
    parentSKU: string,
    trackingSKU: string,
    fulfilment: null,
    fulfilmentMessage: null,
    optionType1: string,
    optionType2: string|null,
    optionValue1: string,
    optionValue2: string|null,
    name: string,
    type:'CartProduct' | 'PromotionGroup',
    brandName: string,
    resizedMainImage: Image,
    image: Image,
    quantity: number,
    refundedQuantity: number,
    hasRefunds: boolean,
    isDiscounted: boolean,
    bulkDiscountName: string|null,
    bulkDiscountSaving: {
        amount: string,
        currency: string,
    },
    bulkDiscountReasonCode: null,
    lineDiscountReasonCode: null,
    lineDiscountSaving: MoneyAmount,
    productPriceOverride: true,
    unitPrice: MoneyAmount,
    saving: MoneyAmount,
    previousUnitPrice: null,
    previousPrice: MoneyAmount,
    subtotal: MoneyAmount,
    categories: string[],
    cartProductNotification: {},
    productReference: string,
    productVariantCountryOfOrigin: string,
    productVariantCommodityCode: null,
    carrierCode: null,
    carrierReference: null,
    categoryGroupingName: string|null,
};

export type OrderMeshResponse = {
    id: string,
    ID: string,
    href: string,
    clientID: string,
    externalID: string|null,
    invoiceID: string,
    status: string,
    fraudCheckStatus: string,
    statusHistory: [],
    hasRefunds: boolean,
    hasExchanges: boolean,
    actions: {
        canCancel: boolean,
        canEdit: boolean,
        canRefund: boolean,
    },
    deliveryRefunded: boolean,
    isDiscounted: boolean,
    estimatedDeliveryDate: {
        hasRange: boolean,
        startDate: string,
        endDate: string,
    },
    channel: 'desktop'|'mobile',
    storeOrderID: string,
    downloadURL: string|null,
    paymentMethod: string,
    isOffline: boolean,
    paymentDetails: {
        gateway: string,
        isOffline: boolean,
        method: string,
        gatewayReference: string,
        isCaptured: boolean,
        amountCaptured: {
            amount: string,
            currency: string,
        }
    },
    dateAdded: string,
    lines: OrderProductMeshResponse[],
    productsSubtotal: MoneyAmount,
    deliveryMethod: {
        name: string,
        clientID: string,
    },
    deliveryTotal: MoneyAmount,
    giftcardsSubtotal: MoneyAmount,
    total: MoneyAmount,
    grandTotal: MoneyAmount,
    balanceToPay: MoneyAmount,
    bulkDiscount: null,
    tax: null,
    totalSavings: MoneyAmount,
    appliedDiscountCodes: [],
    customer: OrderCustomerMeshResponse,
    billingAddress: OrderAddressMeshResponse,
    isDeliverToStore: boolean,
    isCollectPlus: boolean,
    deliveryAddress: OrderAddressMeshResponse,
    deliveryNote: null,
    deliveryInstruction: string,
    selectedDeliveryDate: {
        date: string,
        timezone_type: number,
        timezone: string,
    } | null,
    selectedDeliveryTimeSlotStart: string | null,
    selectedDeliveryTimeSlotEnd: string | null,
    customerServiceInformation: {
        phone: string|null,
        email: string|null,
    },
    affiliateCode: null,
    transmitted: boolean,
    transported: boolean,
    hasFailed: boolean,
    trackingStatus: null,
    barcodeString: string,
    taxRate: null,
    customerServiceOrder: boolean,
    customerServiceAgent: null,
    shogunID: null,
    wmsID: null,
};

export type OrderAddressMeshResponse = {
    firstName: string,
    lastName: string,
    address1: string,
    address2: string|null,
    address3: string|null,
    town: string,
    county: string,
    postcode: string,
    locale: string,
};

export type OrderCustomerMeshResponse = {
    ID: string,
    email: string,
    fullName: string,
    phoneNumber: string,
    isGuestCustomer: boolean,
    marketingPreferencesURL: string,
    marketingCancellationURL: string,
};


function orders (client: AxiosInstance, meshConfig: MeshConfig) {

    const { channel } = meshConfig;

    async function getOrder(orderID: string, expand?: string): Promise<AxiosResponse<OrderMeshResponse>> {
        const queryParams: {channel: string, expand?: string} = {
            channel,
        };
        
        if (expand) queryParams.expand = expand;
        
        let orderResponse = await client.get<OrderMeshResponse>(`/orders/${orderID}`, {params: queryParams});

        return orderResponse;
    }

    return {
        getOrder,
    };
}

export type OrdersService = ReturnType<typeof orders>;

export default orders;
