import React, { useContext } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Box, Text, Button, ResponsiveContext } from "grommet";
import styled, { ThemeContext } from "styled-components";
import { RootState } from "../../store/reducers";
import { CustomTheme } from "../../theme/index";
import { translate } from "../../modules/translation";

const TextUnderlined = styled(Text)`
    text-decoration: underline;
`;

/**
 * The main use case for this component is to display an address block with an "Edit" icon on the top right corner,
 * but because it accepts React nodes we can use it for other cases where a similar layout is required (eg. delivery methods)
 * 
 * @param title {string} Title displayed in bold.
 * @param id {string} Used as a 'value' for click handlers.
 * @param main {string | node} Content for the main area (an address, payment method, etc.) - Required
 * @param extra {string | node} Content to be displayed on the top right corner. Eg. an 'edit' icon or text for shop distance.
 * @param isDetailsButton {boolean} If the component should have a 'View Store Details' button.
 * @param onDetailsClick {callback} Function executed when clicking on the 'View Store Details' button.
 */
function EditableDetails({ title, id, main, extra = '', isDetailsButton = false, onDetailsClick, showPadding = true } : EditableDetailsProps) {
    const theme: CustomTheme = useContext(ThemeContext);
    const size = React.useContext(ResponsiveContext);
    const translation = useSelector((state: RootState) => state.translation);

    const strings = {
        viewStoreDetails: translate(translation, 'viewStoreDetails', 'View Store Details')
    };

    let mainNode: React.ReactNode, extraNode: React.ReactNode;
    if (React.isValidElement(main)) {
        mainNode = main;
    }
    else { // should be a string
        mainNode = <Text size='medium'>{main}</Text>;
    }
    if (React.isValidElement(extra)) {
        extraNode = extra;
    }
    else { // should be a string
        extraNode = <Text weight={theme?.config?.titleFontWeight ?? 'bold'}>{extra}</Text>;
    }
    return (
        <Box
            gap="small"
            direction="row"
            fill="horizontal"
            pad={ size !== 'small' && extra !== '' && showPadding ? "small" : ''}
        >
            <Box
                gap="none"
                direction="column"
            >
                <Text margin="none" weight={theme?.config?.titleFontWeight ?? 'bold'}>{title}</Text>
                {mainNode}
                {isDetailsButton &&
                <Button
                    label={<TextUnderlined>{strings.viewStoreDetails}</TextUnderlined>}
                    plain={true}
                    value={id || title || 'View Store Details'}
                    focusIndicator={false}
                    onClick={onDetailsClick}
                />}
            </Box>
            <Box 
                pad="small"
                align="end"
                flex="grow"
            >
                {extraNode}
            </Box>
        </Box>
    );
};

type EditableDetailsProps = {
    title?: string,
    id?: string
    main: string | React.ReactNode,
    extra?: string | React.ReactNode,
    isDetailsButton?: boolean,
    onDetailsClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
    showPadding?: boolean
};


EditableDetails.propTypes = {
    title: PropTypes.string,
    id: PropTypes.string,
    main: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]).isRequired,
    extra: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    isDetailsButton: PropTypes.bool,
    onDetailsClick: PropTypes.func,
    showPadding: PropTypes.bool
};

export default EditableDetails;
