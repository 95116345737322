import { AxiosInstance, AxiosResponse } from 'axios';
import { MeshConfig } from './types';

type Country = {
    code: string,
    name: string,
    default: boolean,
    requiresState: boolean,
    pcaPredictSupported: boolean
};

export interface BillingCountriesResponse {
    name: string,
    count: number,
    countries: Country[]
}


function billingCountries (client: AxiosInstance, meshConfig: MeshConfig) {
    
    const { channel, locale} = meshConfig;
    
    async function getBillingCountries(): Promise<AxiosResponse<BillingCountriesResponse>>{
        let countriesResponse = await client.get<BillingCountriesResponse>(`/billingcountries`, {
            params: {
                channel,
                locale
            }
        });

        return countriesResponse;
    }

    return {
        getBillingCountries
    };
}

export type BillingCountriesService = ReturnType<typeof billingCountries>;

export default billingCountries;
