import adaptor from '../adaptors';
import { Address, Customer } from '../../modules/customer/types';
import { Cart } from '../../modules/cart/types';
import { Billing, Payment, PaymentMethod } from '../../modules/billing/types';
import { DeliveryMethod, DeliveryCountry, Store } from '../../modules/delivery/types';
import { RootState } from '../../store/reducers';
import { EnsightenEvent, EnsightenData, EnsightenFindAddressType, EnsightenPickupLocationSearchType } from './types';
import { Order } from '../../modules/order/types';

export interface EnsightenService {
    /**
     * Tracks a 'login' user action
     * @param customer Customer object
     */
    trackLogin: (customer: Customer, sendMarketingData: boolean) => Promise<void>,

    /**
     * Tracks a 'register' user action
     * @param customer Customer object
     */
    trackRegister: (customer: Customer, sendMarketingData: boolean, isOnExpressJourney: boolean, isNikeConnected?: boolean) => Promise<void>,

    /**
     * Tracks a 'select a delivery country' user action
     * @param countryName
     * @param countryCode
     */
    trackSelectDeliveryCountry: (country: DeliveryCountry) => Promise<void>,

    /**
     * Tracks a 'find address' user action, typically a PCA Predict request.
     */
    trackFindAddress: (type: EnsightenFindAddressType) => Promise<void>,

    /**
     * Tracks a 'add a new address' user action.
     */
    trackAddNewAddress: (address: Address, customer: Customer, sendMarketingData: boolean, isExpress: boolean, isNikeConnected: boolean) => Promise<void>,

    /**
     * Tracks an 'update an existing address' user action.
     */
    trackUpdateAddress: (address: Address, customer: Customer, sendMarketingData: boolean, isExpress: boolean,isNikeConnected: boolean) => Promise<void>,

    /**
     * Tracks a 'select delivery method' user action
     * @param deliveryMethod
     * @param cart
     */
    trackSelectDeliveryMethod: (deliveryMethod: DeliveryMethod, cart: Cart) => Promise<void>,

    /**
     * Tracks a 'search pickup locations' user action (getting a list of stores or post offices for Click & Collect)
     * @param type Search type.
     */
    trackPickupLocationSearch(type: EnsightenPickupLocationSearchType): Promise<void>

    /**
     * Tracks a 'select payment method' user action
     * @param paymentMethod
     * @param cart
     */
    trackSelectPaymentMethod: (paymentMethod: PaymentMethod, cart: Cart) => Promise<void>,
    
    /**
     * Tracks a 'select a store' user action
     * @param store
     */
    trackSelectStore(store: Store): Promise<void>

    /**
     * Tracks a 'view order confirmation page' user action
     * @param cart
     * @param payment
     */
    trackOrderConfirmation: (sendMarketingData: boolean, cart: Cart, payment: Payment, deliveryMethod: DeliveryMethod, customer: Customer, billing: Billing, order: Order, isNikeConnected: boolean) => Promise<void>,

    /**
     * Tracks a 'view delivery page' user action
     * @param cart
     * @param payment
     */
    trackDeliveryView: (cart: Cart, customer: Customer) => Promise<void>,

    /**
     * Tracks a 'view login page' user action
     * @param cart
     */
     trackLoginOrRegisterView: () => Promise<void>,

    /**
     * Tracks a 'view payment page' user action (from the payment page or the express payment page)
     * @param cart
     * @param payment
     */
    trackPaymentView: (cart: Cart, customer?: Customer, isNikeConnected?: boolean) => Promise<void>,

    /**
     * Tracks a 'forgot password link click' user action on the login page.
     */
    trackForgotPasswordLinkClick: () => Promise<void>,

    /**
     * Tracks a 'Error' 
     * @param customer Customer object
     */
     trackError: (message: string, errorType: string) => Promise<void>,

}


function init(state: RootState){
    const service = adaptor('trackers', 'ensighten', state) as EnsightenService;
    return service;
}

/**
 * Tracks a user action with Ensighten.
 * @param event {string} an Ensighten user action type like 'User.Login'
 * @param data An object with the event details. The expected type varies depending on the 'event'.
 */
export function fireEnsightenEvent<T extends EnsightenEvent>(event: T, data: EnsightenData<T>) {
    console.log('Ensighten', event, data);
    try {
        window.WHE(event, data);
    }
    catch (e) {
        console.log('Ensighten error', e);
    }
}

export default init;
