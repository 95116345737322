import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Box, Text } from "grommet";
import { ThemeContext } from 'styled-components';
import { CustomTheme } from '../../theme';

function TitleBar({ title, icon, showBorder = true, borderSide= 'bottom'}: TitleBarProps) {
    const theme: CustomTheme = useContext(ThemeContext);

    return (
        <Box
            pad="medium"
            align="start"
            gap="small"
            direction="row"
            flex={false}
            background="white"
            border={showBorder
                ?
                {
                    side: borderSide,
                    size: borderSide === 'bottom' ? (theme?.config?.stepSeparatorHeight ?? '16px') : '',
                    color: borderSide === 'bottom'  ? theme?.config?.stepSeparatorColour : 'light-2',
                }
                :
                false
            }
        >
            <Box fill="horizontal"
                 border={false}
                 style={{
                     'textTransform': theme?.config?.stepTextTransform ?? "unset",
                     'letterSpacing': theme?.config?.textLetterSpacing ?? "normal",
                 }}
            >
                <Text
                    size="large"
                    weight={theme?.config?.titleFontWeight ?? 'bold'}
                    margin="none"
                    color={theme?.config?.titleTextColor ?? ''}
                >
                    {title}
                </Text>
            </Box>
            <Box alignSelf="center">
                {icon}
            </Box>
        </Box>
    );
}

type TitleBarProps = {
    title: string,
    icon?: React.ReactNode,
    showBorder?: boolean,
    borderSide?: 'top'
        | 'left'
        | 'bottom'
        | 'right'
        | 'start'
        | 'end'
        | 'horizontal'
        | 'vertical'
        | 'all'
        | 'between';
};

TitleBar.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.node,
    showBorder: PropTypes.bool,
    borderSide: PropTypes.string
};

export default TitleBar;