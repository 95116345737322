import adaptor from './adaptors';
import { AppResponse } from './response';
import { RootState } from '../store/reducers';

export type Pages = {
    content: string,
    title: string,
};

export interface PagesService {
    getPages: (pageId:string) => Promise<AppResponse<Pages>>
}

function init(state: RootState){
    const service = adaptor('mesh', 'pages', state) as PagesService;
    return service;
}

export default init;
