import merge from 'deepmerge';
import defaultTheme, { CustomTheme } from '.';
import {
    Security,
    TickFilledChoice,
    TickEmptyChoice,
    TickFilledScotts,
} from '../components/Icon/index';
import './index';

const theme: CustomTheme = {
    config: {

        displayName: "Scotts",

        titleFontWeight: 'bold',

        appBackground: 'white',

        stepFontSize: 'large',
        stepFontWeight: 'bold',
        stepSeparatorHorizontalMargin: '16px',
        stepSeparatorHeight: '16px',
        stepSeparatorHeightMobile: '1px',
        stepSeparatorColour: 'light-2',
        stepSeparatorMargins: {
            vertical: 'large',
            horizontal: 'medium'
        },

        headerLogo: '/Logo-Scotts-Navigation.svg',
        headerLogoSize: {height: '18px'},
        headerShowLogo: true,
        headerBackgroundColor: '#252525',
        headerIconColor: 'white',
        headerHeight: '64px',
        headerTitleFontSize: 'medium',
        headerLogoContainerMinWidth: '75px',

        imageListBorder: true,

        summaryStepShowSeparator: true,
        summaryShowTotalsTitle: true,
        summaryTotalFontSize: 'large',
        summarySeparatorIsAbove: true,
        summarySeparatorMargins: {
            top: '14px',
            bottom: 'medium'
        },
        
        icons: {
            tickIcon: TickFilledChoice,
            untickIcon: TickEmptyChoice,
            securityIcon: Security,
            successTickIcon: TickFilledScotts,
        },

        fontSrc: 'https://fonts.googleapis.com/css?family=Muli:300,400,600,700&display=swap',
        requiredFieldStarColour: 'green',
        addColorToDiscounts: true,
    },

    global: {
        font: {
            family: 'Muli, sans-serif',
        },
        colors: {
            brand: 'black',
            red: {
                dark: '#E25656',
                light: '#E25656'
            },
            green: {
                dark: '#74E779',
                light: '#74E779'
            },
        },
    },

    button: {
        border: {
            radius: '0px'
        },
        extend: 'height: 48px; font-weight: bold',
    },

    checkBox: {
        check: {
            radius: '0px',
            thickness: '1px',
            extend: (props) => {
                return props.checked ? `background: white; border-color: #e7e8e9; box-shadow: none !important;` : `box-shadow: none !important;`;
            }
        },
        icons: {
            checked: TickFilledChoice,
        }
    },

    radioButton: {
        font: {
            weight: 'bold',
        }
    },

    tab: {
        border: {
            active: {
                color: 'brand'
            },
            hover: {
                color: 'brand'
            }
        },
    },

    formField: {
        error: {
            color: 'red',
        },
        border: {
            error: {
                color: 'red',
            }
        },
        // @ts-ignore
        extend: (props) => {
            return props.className === 'valid' ? `div { border-color: #74E779; }` : '';
        },
    },
};

const scottsTheme: CustomTheme = merge(defaultTheme, theme);

export default scottsTheme;