import { AuthToken } from '../session/types';

// constant types
export const UPDATE = 'checkout/customer/UPDATE';
export const UPDATE_EMAIL = 'checkout/customer/UPDATE_EMAIL';
export const UPDATE_PREFERENCES = 'checkout/customer/UPDATE_PREFERENCES';
export const UPDATE_IS_ON_GUEST_JOURNEY = 'checkout/customer/UPDATE_IS_ON_GUEST_JOURNEY';
export const UPDATE_ADDRESS_BOOK_TYPE = 'checkout/customer/UPDATE_ADDRESS_BOOK_TYPE';
export const UPDATE_AUTH_TOKEN = 'checkout/customer/UPDATE_AUTH_TOKEN';
export const UPDATE_MARKETING_OPT_INS = 'checkout/customer/UPDATE_MARKETING_OPT_INS';
export const UPDATE_CUSTOMER_IP = 'checkout/customer/UPDATE_CUSTOMER_IP';
export const SHOW_CLICK_AND_COLLECT = 'checkout/customer/SHOW_CLICK_AND_COLLECT';

// action types 
interface UpdateCustomer {
    type: typeof UPDATE,
    customer: Customer
}

interface UpdateCustomerPreferences {
    type: typeof UPDATE_PREFERENCES,
    preferences: CustomerPreferences,
}

interface SubmitEmail {
    type: typeof UPDATE_EMAIL,
    email: string,
    returning?: boolean
}

interface UpdateIsOnGuestJourney {
    type: typeof UPDATE_IS_ON_GUEST_JOURNEY,
    isOnGuestJourney: boolean,
}

interface UpdateAddressBookType {
    type: typeof UPDATE_ADDRESS_BOOK_TYPE,
    addressBookType: AddressBookType,
}

interface UpdateAuthToken {
    type: typeof UPDATE_AUTH_TOKEN,
    authToken: AuthToken,
}

interface UpdateMarketingOptIns {
    type: typeof UPDATE_MARKETING_OPT_INS,
    marketingOptIns: MarketingOptIns,
}

interface UpdateCustomerIP {
    type: typeof UPDATE_CUSTOMER_IP,
    ip: string,
}

interface showClickAndCollect {
    type: typeof SHOW_CLICK_AND_COLLECT,
    isClickAndCollectAvailable: boolean,
}

export type CustomerActionTypes = UpdateCustomer | UpdateCustomerPreferences
    | SubmitEmail | UpdateIsOnGuestJourney | UpdateAddressBookType
    | UpdateAuthToken | UpdateMarketingOptIns | UpdateCustomerIP | showClickAndCollect;


// other
export interface Customer {
    ID: string,
    email: string,
    returning: boolean,
    addresses: Address[],
    firstName: string,
    lastName: string,
    phone: string,
    isActive: boolean,
    isGuest: boolean,
    isOnGuestJourney?: boolean,
    isRegistered: boolean,
    addressBookType?: AddressBookType,
    additionalData?: AdditionalData,
    marketingPreferencesURL?: string,
    marketingCancellationURL?: string,
    preferences?: CustomerPreferences,
    marketingOptIns?: MarketingOptIns,
    ip?: string,
    isClickAndCollectAvailable?: boolean,
}

export type MarketingOptIns = {
    emailOptIn?: boolean,
    smsOptIn?: boolean,
    postOptIn?: boolean,
    thirdPartyOptIn?: boolean
}

export type CustomerPreferences = {
    deliveryMethodPreference?: {ID: string, value: string},
    paymentMethodPreference?: {ID: string, value: string},
    storePreference?: {ID: string, value: string},
    storeAddressPreference?: {value: string},
    collectionPreference?: {ID: string, value: string},
}

export type Address = {
    ID: string,
    firstName: string,
    lastName: string,
    email: string,
    phone?: string,
    country: string,
    address1: string,
    address2?: string,
    address3?: string,
    town: string,
    county?: string,
    postcode: string,
    isDefaultDeliveryAddress: boolean,
    isDefaultBillingAddress: boolean,
    locale: string,
};
//Used to cast values to in onFormChange() on the Login component.
export type AuthCredentials = {
    email: string,
    password: string,
    firstName: string,
    lastName: string,
}

export interface AdditionalData {
    authToken?: AuthToken
}

export type AddressBookType = 'delivery'|'billing'