import React, { useContext, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    Box, Button, Text, Layer, Anchor, ResponsiveContext, Grid, Stack,
} from "grommet";
import { ThemeContext } from 'styled-components';
import { RootState } from '../../store/reducers';
import { payments, tracking } from '../../services';
import { AppThunkDispatch } from '../../modules/thunk';
import {
    updateExternalCartAddressesAndCustomer, updateExternalCartDeliveryMethod,
    getCartId, getAllowToPayWithGiftCard,
    getFulfilmentGroups, getHasDropshipProducts, getEgiftcardDetails,
    getCartDeliveryMethod, makeZeroPayment} from '../../modules/cart';
import { getCollectionDetails,
    setDefaultAddressesIfNoAddressSet, updateArchivedHomeDeliveryPostcode,
    setDeliveryAddressFromDefault, selectMethod as selectDeliveryMethod,
    getIsGeorestrictionError,loadDeliveryMethods, updateStores, updatePostOffices, updateIsNotYouCollecting } from '../../modules/delivery';
import { CustomTheme } from '../../theme';
import { Edit, ArrowDown, ArrowUp } from '../Icon/index';
import { DeliveryOptionValue, CollectionDetails } from "../../modules/delivery/types";
import {
    getAddresses,
    getClickAndCollect,
    getIsGuest,
    getMarketingOptIns,
    getReturning,
    saveMarketingOptInChoice,
    updateAddressBookType
} from "../../modules/customer";
import {
    loadPaymentMethods, selectPaymentMethod,
    getPaymentMethodsData, getShowCardSelectorView,
} from "../../modules/billing";
import { Address } from "../../modules/customer/types";
import { clearNotification, getDialogExtraData, updateDialog, updateNotification } from '../../modules/notification';
import { DeliveryMapToProps } from "../../containers/DeliveryPage";
import { PageRefContext } from "../Page/Page";
import Step from "../Step/Step";
import Currency from "../Currency/Currency";
import OptionList, { OptionTypes } from "../OptionList/OptionList";
import PaymentMethodSelector from "../PaymentMethodSelector/PaymentMethodSelector";
import EditableDetails from "../EditableDetails/EditableDetails";
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay';
import LoadingIcon from '../LoadingIcon/LoadingIcon';
import HomeDeliveryPage from "../../containers/HomeDeliveryPage";
import ClickAndCollectPage from "../../containers/ClickAndCollectPage";
import StoreDetails from '../StoreDetails/StoreDetails';
import FormattedAddress from '../FormattedAddress/FormattedAddress';
import InfoBox from "../InfoBox/InfoBox";
import AddressBook from "../AddressBook/AddressBook";
import ProductsSummary from "../ProductsSummary/ProductsSummary";
import TotalSummary from "../TotalSummary/TotalSummary";
import PaymentCards from "../PaymentCards/PaymentCards";
import GiftCardForm from "../GiftCardForm/GiftCardForm";
import TitleBar from '../TitleBar/TitleBar';
import { getPrivacyPolicy, getFasciaAllowsPayWithGiftCards, getLocalisation } from '../../modules/config';
import styled from "styled-components";
import { getReturnURL, isCAndCAvailable } from '../../modules/config';
import { getPhraseWithLink, translate } from '../../modules/translation';
import { getIsServiceAgent } from '../../modules/session';
import newRelicData from "../../newrelic";

const FloatingBox = styled(Box)`
        position: -webkit-sticky;
        position: sticky;
        top: 0px;
        float: right;
        height: fit-content;
    `;

/**
 * Presentational component of the delivery page.
 * @param products {array} Cart products
 * @param deliveryOptions {array} Option objects from the store Delivery module
 * @param onSelectOption {callback} Function executed when changing delivery options
 * @param deliveryMethods {object} Contains delivery methods related to the selected delivery option (home or C&C).
 * @param onSelectMethod {callback} Function to execute when a new method is selected (radio button)
 * @param selectedStore {object} Store/location that has been selected (from Redux)
 * @param selectStore {callback} Executed when changes to the selected store (when clicking on the edit icon of the selected store)
 * @param showStoreDetailedView {boolean} True to show the 'Store Details' view
 * @param selectStoreForDetailedView {callback} Selects a store to show in the 'store details' view
 * @param collectionDetails {object} Name and phone of the person who will collect the C&C item.
 * @param updateCollectionDetails {callback} Executed when changes to the collection details for C&C (when clicking on the edit icon for these)
 * @param selectedPaymentMethod {object} The selected payment method (full object, or {} if none selected)
 */
function Delivery({
    products,
    deliveryOptions, onSelectOption, selectedDeliveryOption,
    deliveryMethods,
    selectedMethod, onSelectMethod,
    selectedStore, selectStore,
    showStoreDetailedView, selectStoreForDetailedView,
    collectionDetails, updateCollectionDetails,
    deliveryAddress, updateDeliveryAddress,
    billingAddress, updateBillingAddress, selectedPaymentMethod,
}: DeliveryProps) {
    const customer = useSelector((state: RootState) => state.customer);
    const billing = useSelector((state: RootState) => state.billing);
    const cart = useSelector((state: RootState) => state.cart);
    const config = useSelector((state: RootState) => state.config);
    const delivery = useSelector((state: RootState) => state.delivery);
    const translation = useSelector((state: RootState) => state.translation);
    const session = useSelector((state: RootState) => state.session);
    const notification = useSelector((state: RootState) => state.notification);

    const returnURL = getReturnURL(config);
    const cartURL = `${returnURL}cart`;

    const addresses = getAddresses(customer);
    const showCardSelectorLayer = getShowCardSelectorView(billing);
    const cartId = getCartId(cart);
    const hasDropshipProducts = getHasDropshipProducts(cart);
    const egiftcardDetails = getEgiftcardDetails(cart, config, translation);
    const fulfilmentGroups = getFulfilmentGroups(cart);
    const canUseGiftCards = getAllowToPayWithGiftCard(cart) && getFasciaAllowsPayWithGiftCards(config);
    const isServiceAgent = getIsServiceAgent(session);

    const privacyPolicy = getPrivacyPolicy(config);
    const isReturning = getReturning(customer);
    const isGuest = getIsGuest(customer);
    const showClickAndCollect = getClickAndCollect(customer);

    const strings = {
        home: {
            STEP_TITLE: translate(translation, 'deliveryAddress', 'Delivery Address'),
            STEP_BUTTON: translate(translation, 'addAddress', 'Add an Address'),
            DELIVERY_METHOD_TITLE: translate(translation, 'deliveryMethod', 'Delivery Method'),
        },
        clickAndCollect: {
            STEP_TITLE: translate(translation, 'storeAddress', 'Store Address'),
            STEP_BUTTON: translate(translation, 'findStore', 'Find a Store'),
            DELIVERY_METHOD_TITLE: translate(translation, 'cAndCOptions', 'Click & Collect Options'),
        },
        billing: {
            STEP_TITLE: translate(translation, 'billingAddress', 'Billing Address'),
            STEP_BUTTON: translate(translation, 'addAddress', 'Add an Address'),
        },
        CHECKOUT: translate(translation, 'checkoutSecurely', 'Checkout Securely'),
        CHECKOUT_ZERO: translate(translation, 'completeOrder', 'Complete Your Order'),
        CHECKOUT_SERVICE_AGENT: translate(translation, 'placeOrder', 'Place Order'),
        COLLECTION_DETAILS: translate(translation, 'collectionDetails', 'Collection Details'),
        ANATWINE_DELIVERY_MESSAGE: translate(translation, 'anatwineBrandsArriveSeparately', 'Brand partner items will be delivered direct from the brand and will arrive separately.'),
        selectingDeliveryMethods: translate(translation, 'selectingDeliveryMethods', 'Selecting delivery method'),
        deliveryOptions: translate(translation, 'deliveryOptions', 'Delivery Options'),
        totalsSummary: translate(translation, 'totalsSummary', 'Total Summary'),
        noDeliveryMethodForCAndC: translate(translation, 'noDeliveryMethodForCAndC', 'No delivery method available for Click & Collect. Please choose Home Delivery.'),
        noDeliveryMethodForHomeDelivery: translate(translation, 'noDeliveryMethodForHomeDelivery', 'No home delivery method available at this location. Please choose another delivery address.'),
        sameAsDeliveryAddress: translate(translation, 'sameAsDeliveryAddress', 'Same as delivery address'),
        myItems: translate(translation, 'myItems', 'My items'),
        paymentOptions: translate(translation, 'paymentOptions', 'Payment Options'),
        homeDelivery: translate(translation, 'homeDelivery', 'Home Delivery'),
        cAndC: translate(translation, 'cAndC', 'Click & Collect'),
        privacyNoticeInfo: translate(translation, 'privacyNoticeInfo', 'We will use your information in accordance with our (privacy notice).'),
        updatedOnDate: translate(translation, 'updatedOnDate', '', {date: privacyPolicy.date}),
        free: translate(translation, 'free', 'Free'),
    };
    let privacyPolicyTextString = strings.privacyNoticeInfo;
    if (privacyPolicy.date) privacyPolicyTextString += ` ${strings.updatedOnDate}`;
    const privacyPolicyText = getPhraseWithLink(privacyPolicyTextString, privacyPolicy.link, {size: 'small'});

    type DeliveryLayer = '' | 'deliveryAddress' | 'billingAddress' | 'clickAndCollect' | 'addressBook' | 'paymentCards';
    const [layerToShow, setLayerToShow] = useState<DeliveryLayer>('');
    //for desktop need to show an accordion rather than a layer
    const [accordionToShow, setAccordionToShow] = useState<DeliveryLayer>('')

    // Data about an address to edit through the Address Book
    const [addressIdToEdit, setAddressIdToEdit] = useState('');
    const [addressTypeToEdit, setAddressTypeToEdit] = useState<'billing' | 'delivery' | ''>('');

    const adyenData = getPaymentMethodsData(billing).adyenCard.adyenData;
    const isAdyenDataValid = getPaymentMethodsData(billing).adyenCard.isValid;
    const isGeorestrictionError = getIsGeorestrictionError(delivery);
    const size = React.useContext(ResponsiveContext);
    const isDesktop = size !== 'small';
    const isClickAndCollect = selectedDeliveryOption.value === 'clickAndCollect';
    const isHomeDelivery = selectedDeliveryOption.value === 'home';

    const [isPaymentListLoading, setPaymentListLoading] = useState(false);

    const [isNewPageLoading, setIsNewPageLoading] = useState(false);

    const [showMobileProductSummary, setShowMobileProductSummary] = useState(false);

    const paymentMethodButtonContainer = useRef<HTMLDivElement>(null);
    const deliveryAddressFormRef = useRef<HTMLDivElement>(null);
    const billingAddressFormRef = useRef<HTMLDivElement>(null);

    // this is to render payment buttons on delivery page
    const [isRenderOnDeliveryPage, setIsRenderOnDeliveryPage] = useState(false);
	const [showDeliveryMethodView, setShowDeliveryMethodView] = useState(false);
    const isZeroPayment = cart.balanceToPay.amount === "0.00" && cart.giftcardsApplied?.length;
    
    useEffect(() => {
        (async () => {
            const paymentModule = await payments()?.getPaymentMethodModule(selectedPaymentMethod.name);
            setIsRenderOnDeliveryPage(paymentModule?.renderOnDeliveryPage);
            if(paymentModule?.renderOnDeliveryPage) {
                try {
                    await dispatch(updateExternalCartAddressesAndCustomer());
                    // A preselected delivery method won't be on the external cart yet
                    if (!getCartDeliveryMethod(cart)?.ID && selectedMethod.ID) {
                        await dispatch(updateExternalCartDeliveryMethod(selectedMethod.ID, deliveryAddress.locale));
                    }
                } catch (error) {
                    setIsNewPageLoading(false);
                    dispatch(updateNotification('error-payment-delivery-page'));
                }
                paymentModule?.setHistory(history);
            }
        })()
        // eslint-disable-next-line
    }, [selectedPaymentMethod.name]);

    // Render the dynamic payment button if window size changes
    useEffect(() => {
        (async () => {
            const paymentModule = await payments()?.getPaymentMethodModule(selectedPaymentMethod.name);
            if(paymentModule?.renderOnDeliveryPage) {
                if(paymentMethodButtonContainer?.current?.innerHTML) {
                    paymentMethodButtonContainer.current.innerHTML  = ''
                }
                if(!isZeroPayment) paymentModule?.render();
            }
        })()
        // eslint-disable-next-line
    }, [selectedPaymentMethod.name, size, isZeroPayment]);

    const dispatch: AppThunkDispatch = useDispatch();

    useEffect(() => {
        tracking()?.trackDeliveryView(cart, customer);
        const localisation = getLocalisation(config);
        const locale = localisation.countryCode.toLowerCase();
        const removeDropshipProducts = hasDropshipProducts && deliveryAddress.locale !== locale;
        // isGeorestrictionError will be true if StartUp.tsx had a georestriction error when loading
        // the delivery methods based on the default delivery address.
        const removeRestrictedProducts = isGeorestrictionError;
        if ((isReturning && !isGuest)&& (removeDropshipProducts || removeRestrictedProducts)) {
            const hasPreexistingNotification = getDialogExtraData(notification)?.removeRestrictedProducts ?? false;
            dispatch(updateDialog('remove_georestricted_products_dialog', {
                removeDropshipProducts,
                removeRestrictedProducts: removeRestrictedProducts || hasPreexistingNotification,
            }));
        }
        
        if(selectedMethod.displayName){
            newRelicData({ actionName: 'delivery', function: 'useeffect', message: `default selected delivery method - ${selectedMethod.displayName}`, deliveryAddressId: deliveryAddress.ID, billingAddressId: billingAddress.ID, cartItemCount: cart.count });
        }
        newRelicData({ actionName: 'delivery', function: 'useeffect', message: `default selected delivery option - ${selectedDeliveryOption.value}`, desktop: isDesktop, deliveryAddressId: deliveryAddress.ID, billingAddressId: billingAddress.ID, cartItemCount: cart.count });
    // eslint-disable-next-line
    }, []);

    // Manage scrolling to the correct bloc when 'accordions' appear.
    useEffect(() => {
        if (isDesktop) {
            const formType = renderHomeDeliveryPage();
            const scrollOptions: ScrollIntoViewOptions = {behavior: 'smooth'};
            if (formType === 'deliveryAddress') {
                deliveryAddressFormRef.current?.scrollIntoView(scrollOptions);
            }
            else if (formType === 'billingAddress') {
                billingAddressFormRef.current?.scrollIntoView(scrollOptions);
            }
        }
        // eslint-disable-next-line
    }, [accordionToShow]);

    // Initialise the delivery methods radio buttons
    const radioMethods: OptionTypes[] = [];
    deliveryMethods.forEach(method => {
        const methodPrice = method.typeCategory === 'clickAndCollect'
                            ? method.price.amount === '0.00' ? <Text weight="bold">{strings.free}</Text> : <Currency currency={method.price.currency} amount={method.price.amount} /> 
                            : <Currency currency={method.price.currency} amount={method.price.amount} />;
        if((isClickAndCollect && method.typeCategory === 'clickAndCollect') || 
            (isHomeDelivery && method.typeCategory === 'home')) {
            radioMethods.push({
                id: method.ID,
                value: method.ID,
                label: method.displayName,
                description: method.description,
                hasTimeSlots: !!method.slots?.length,
				extra: <Text weight="bold">{methodPrice}</Text>,
				needsConfirmation: method.needsConfirmation
            });
        }
    });

    useEffect(() => {
        //remove selected delivery from state if that is not available in the avaialble methods
        const isSelectedMethodAvailable = radioMethods.find(item => item.id === selectedMethod.ID);
        if(selectedMethod.ID && !isSelectedMethodAvailable) {
            dispatch(selectDeliveryMethod(''));
        }
	});

    // Check if objects from Redux contain actual data (not {})
	const isStoreSelected = Object.keys(selectedStore).length > 0;
    const isCollectionDetailsSelected = Object.keys(collectionDetails).length > 0;
    const isDeliveryMethodSelected = Object.keys(selectedMethod).length > 0;
    const isHomeDetailsSelected = Object.keys(deliveryAddress).length > 0;
    const isBillingAddressSelected = Object.keys(billingAddress).length > 0;
	const isPaymentMethodSelected = Object.keys(selectedPaymentMethod).length > 0;

    useEffect(() => {
        if(radioMethods.length <= 0 && (isHomeDetailsSelected)) {
            setShowDeliveryMethodView(true);
        }
        // eslint-disable-next-line
    }, [deliveryMethods]);

    const history = useHistory();
    const pageRef = useContext(PageRefContext);
    const theme: CustomTheme = useContext(ThemeContext);
    const tickIcon = theme?.config?.icons?.tickIcon ? React.createElement(theme?.config?.icons?.tickIcon) : null;
    const arrowIcon = theme?.config?.icons?.arrowIcon ? React.createElement(theme?.config?.icons?.arrowIcon) : null;
    const arrowDisabledIcon = theme?.config?.icons?.arrowDisabledIcon ? React.createElement(theme?.config?.icons?.arrowDisabledIcon) : null;

    // calculates additional central margin for Desktop view
    const webBoxWidth = theme?.config?.desktop?.webBoxWidth ?? "800px";
    const desktopViewCentralMargin = theme?.config?.desktop?.desktopViewCentralMargin ?? "20px";
    const checkoutButtonLabel = <Text
                                    style={{
                                        'textTransform': theme?.config?.textTransform ?? "unset"
                                    }}
                                >
                                    {isServiceAgent ? strings.CHECKOUT_SERVICE_AGENT : isZeroPayment ? strings.CHECKOUT_ZERO : strings.CHECKOUT}
                                </Text>


    // Initialise the delivery option radio buttons
	const radioOptions: OptionTypes[] = [];

    let showCAndC = isCAndCAvailable(config, cart);

    if(!showClickAndCollect) {
        showCAndC = false;
    }
    
    deliveryOptions.forEach((option) => {
        // Hide C&C depending on config.
        if (!(option.value === 'clickAndCollect' && !showCAndC)) {
            radioOptions.push({
                id: option.value,
                label: option.value === 'home' ? strings.homeDelivery : strings.cAndC,
                value: option.value
            });
        }
    });

    // Get data from the cart. count is the sum of the quantities of each product
    const productCount = cart.count;
	const isSeparatorAbove = theme?.config?.summarySeparatorIsAbove ?? false;

    const paymentMethodSelector = <PaymentMethodSelector />;

    const anatwineDeliveryMessage = [strings.ANATWINE_DELIVERY_MESSAGE];
    fulfilmentGroups.forEach(group => {
        if (group.isDropShip && group.deliveryMessage) {
            anatwineDeliveryMessage.push(group.deliveryMessage);
        }
	});

    const deliveryMethodList = <Step title={strings[selectedDeliveryOption.value].DELIVERY_METHOD_TITLE}>
        <Box direction="column" flex="grow">
            { hasDropshipProducts &&
                <Box flex={false} background="light-3" pad="small" margin={{ bottom: "large" }}>
                    <InfoBox text={anatwineDeliveryMessage} />
                </Box>
            }

            { egiftcardDetails.length > 0 &&
                egiftcardDetails.map(giftCard => (
                    <Box flex={false} background="light-3" pad="small" margin={{ bottom: "large" }}>
                        <InfoBox text={giftCard.informationMessage} />
                    </Box>
                ))
            }

            <Stack fill={true}>
                <OptionList
                    options={radioMethods}
					defaultSelected={selectedMethod.ID}
                    onOptionChange={async event => {
						setPaymentListLoading(true);
						const methodId = event.target.value;
						dispatch(updateStores([])); // clear store state so that we aren't returning unavailable stores for same day c&c
						dispatch(updatePostOffices([]));
						if (selectedDeliveryOption.value !== 'clickAndCollect') {
							try {
								await dispatch(updateExternalCartDeliveryMethod(methodId, deliveryAddress.locale));
								await dispatch(loadPaymentMethods(cartId));
							}
							catch (error) {
								dispatch(updateNotification('error_loading_payment_methods'));
								console.error('Error loading the payment methods', error);
							}
						} else {
							// Do nothing just show the postcode input as this is needed for the next stage
							dispatch(selectDeliveryMethod(methodId));
							// if there has previously been a c&c used there may already be store location stored
							// in the case clear the previous address as further confirmation may be required.
							selectStore('');
							
							
						}
                        setPaymentListLoading(false);
                        setAccordionToShow('');
						setAddressTypeToEdit('');
                    }}
                />
                {isPaymentListLoading &&
                    <LoadingOverlay text={strings.selectingDeliveryMethods} />
				}
            </Stack>
        </Box>
	</Step>;
	
	const selectedDeliveryMethodSummary = <Box direction="row" margin={isDesktop && theme?.config?.stepBorder ? { "top": "medium" } : ''}>
		<Step
			title={strings[selectedDeliveryOption.value].DELIVERY_METHOD_TITLE}
			icon={
				<Anchor
					onClick={(event) => {
						dispatch(selectDeliveryMethod(''));
					} }>
					<Edit />
				</Anchor>}
		>
			<Box fill={true}>
				<Box direction="row">
					<Box fill={true} align="start">
						<Text weight={theme?.config?.stepFontWeight ?? 'bold'} size="large">{delivery.selectedMethod?.displayName}</Text>
					</Box>
					<Box fill={true} align="end">
						<Text weight={theme?.config?.stepFontWeight ?? 'bold'} size="large">
						{ delivery.selectedMethod?.typeCategory === 'clickAndCollect'
							? delivery.selectedMethod?.price.amount === '0.00' ? <Text weight="bold">{strings.free}</Text> : <Currency currency={delivery.selectedMethod?.price?.currency} amount={delivery.selectedMethod?.price?.amount} /> 
							: <Currency currency={delivery.selectedMethod?.price?.currency} amount={delivery.selectedMethod?.price?.amount} />
						}
                        </Text>
					</Box>
				</Box>
				<Box margin={{top: '15px'}} fill={true} pad={{top: 'medium'}}>{delivery.selectedMethod?.description}</Box>
			</Box>
		</Step>
	</Box>

    const deliveryOptionsRadioList = <Step title={strings.deliveryOptions}>
        <Box background="white" flex="grow">
            <OptionList
                options={radioOptions}
                defaultSelected={selectedDeliveryOption.value}
                onOptionChange={async event => {
                    const newValue = event.target.value;
                    newRelicData({ actionName: 'delivery', function: 'deliveryOptionsRadioList', message: `delivery option ${event.target.value} is selected`, desktop: isDesktop, deliveryAddressId: deliveryAddress.ID, billingAddressId: billingAddress.ID, cartItemCount: cart.count });

                    dispatch(selectPaymentMethod(''));
                    onSelectOption(newValue as DeliveryOptionValue);
                    // Empty the store from the details related to the old delivery option.
                    updateCollectionDetails({} as CollectionDetails);
                    setIsRenderOnDeliveryPage(false);

                    // Switching from 'home' to C&C
                    if (newValue === 'clickAndCollect') {
                        await dispatch(selectDeliveryMethod(''));
						dispatch(updateArchivedHomeDeliveryPostcode(deliveryAddress.postcode));
                        updateDeliveryAddress({} as Address);
                    }
                    // Switching from C&C to 'home'
                    else if (newValue === 'home') {
                        dispatch(updateArchivedHomeDeliveryPostcode(''));
                        dispatch(updateIsNotYouCollecting(false));
                        if(!isHomeDetailsSelected) {
                            await dispatch(setDeliveryAddressFromDefault());
                        }
                    }
                    selectStore('');
                    onSelectMethod('');
					if (newValue === "home" && (deliveryAddress.ID)) {
						setAccordionToShow("deliveryAddress");
					}
					else if (newValue === "clickAndCollect") {
						setAccordionToShow('clickAndCollect');
                        const locale = config.localisation.countryCode.toLowerCase();
                        await dispatch(loadDeliveryMethods({partialPostcode: true}, locale,true));
					}
                }}
            />
        </Box>
    </Step>;

    const addressBook = <AddressBook
        onClose={closeExtraView}
        onAddNewAddress={() => {
            setAddressIdToEdit('');
            if (addressTypeToEdit === 'delivery') {
                setAccordionToShow('deliveryAddress');
                setLayerToShow('deliveryAddress');
                dispatch(updateArchivedHomeDeliveryPostcode(deliveryAddress.postcode));
                updateDeliveryAddress({} as Address);
            }
            else if (addressTypeToEdit === 'billing') {
                updateBillingAddress({} as Address);
                setAccordionToShow('billingAddress');
                setLayerToShow('billingAddress');
            }
        }}
        onEditAddress={(addressId: string) => {
            setAddressIdToEdit(addressId);
            if (addressTypeToEdit === 'delivery') {
                setAccordionToShow('deliveryAddress');
                setLayerToShow('deliveryAddress');
            }
            else if (addressTypeToEdit === 'billing') {
                setAccordionToShow('billingAddress');
                setLayerToShow('billingAddress');
            }
        }}
    />;
    const iconStyles = {
        position: 'relative' as 'relative',
        order: 1,
        left: '10px',
    };

    const disableCheckout = !checkUserCanCheckout();
    const showCheckoutIcon = theme?.config?.showCheckoutIcon ?? false;
    const checkoutSecurelyButtonColor = !disableCheckout ? theme?.config?.checkoutSecurelyButtonColor : '';
    const checkoutIcon = showCheckoutIcon
        ? <Box style={iconStyles}>
            {disableCheckout ? arrowDisabledIcon : arrowIcon}
        </Box>
        : undefined ;

    const checkoutSecurelySubmitButton = <Button
        primary
        color= {checkoutSecurelyButtonColor}
        disabled={disableCheckout}
        label={isNewPageLoading ? <LoadingIcon /> : checkoutButtonLabel}
        fill={true}
        a11yTitle={strings.CHECKOUT}
        margin={{ top: isSeparatorAbove ? 'large' : 'none' }}
        icon={ checkoutIcon }
        onClick={async () => {
            newRelicData({ actionName: 'delivery', function: 'checkoutSecurelySubmitButton', message: 'user clicked checkout securely button', desktop: isDesktop  })
            setIsNewPageLoading(true);
            try {

                    await dispatch(saveMarketingOptInChoice(customer, {
                        ...getMarketingOptIns(customer),
                        smsOptIn: customer.marketingOptIns?.smsOptIn,
                        emailOptIn: customer.marketingOptIns?.emailOptIn
                    }));
                await dispatch(updateExternalCartAddressesAndCustomer());


                // A preselected delivery method won't be on the external cart yet
                if (!getCartDeliveryMethod(cart)?.ID && selectedMethod.ID) {
             
                    await dispatch(updateExternalCartDeliveryMethod(selectedMethod.ID, deliveryAddress.locale));
                }
        


                if (isServiceAgent) {
                    history.push('/serviceagentconfirmation');
                    return;
                }
                if(isZeroPayment) {
                    await dispatch(makeZeroPayment());
                    history.push('/confirm');
                } else {
                    let paymentMethod = selectedPaymentMethod.name;
                    if(selectedPaymentMethod?.additionalData?.hostedPaymentGateway === 'MolPay' &&  selectedPaymentMethod.name === 'CARD') {
                        paymentMethod = 'molpaycard';
                    }
                    const paymentModule = await payments().getPaymentMethodModule(paymentMethod);

                    // Check if we should bypass the payment page, ie. authorise() directly from here.
                    if (paymentModule?.authoriseOnDeliveryPage) {
                        paymentModule?.setHistory(history);
                        selectStoreForDetailedView('');
                        
                        try {
                            await paymentModule?.authorise();
                        }
                        catch(error) {
                            console.error(error);
                        }
                        finally {
                            setIsNewPageLoading(false);
                        }
                    }
                    // Any other payment will start on the payment page
                    else {
                        selectStoreForDetailedView('');
                        history.push('/payment');
                    }
                }
            }
            catch (error) {
                newRelicData({ actionName: 'delivery', function: 'checkoutSecurelySubmitButton', message: { error: (error as Error).message }, desktop: isDesktop  });
                setIsNewPageLoading(false);
                dispatch(updateNotification('error-payment-delivery-page'));
            }
        }}
    />;

    const paymentMethodCustomCTAPlaceholder = <Box
        margin={{top: isSeparatorAbove ? 'large' : undefined}}
        id="payment-method-container-delivery-page"
        ref={paymentMethodButtonContainer}
        align="center"
    />;


    /**
     * Hides the layer (mobile) / accordion (desktop) currently open.
     */
    async function closeExtraView() {
        setLayerToShow('');
        setAccordionToShow('');
        setAddressTypeToEdit('');

        dispatch(clearNotification());
        dispatch(setDefaultAddressesIfNoAddressSet());
    }

    /**
     * Clears data from redux and reopens the layer so the user can edit the details again.
     * @param {string} details details to clear from Redux store
     */
    function onEditDetails(details: 'collectionDetails' | 'selectedStore' | 'homeDetails' | 'billingDetails') {
        switch (details) {
            case 'collectionDetails':
                setLayerToShow('clickAndCollect');
                setAccordionToShow('clickAndCollect');
                break;
            case 'homeDetails':
                setLayerToShow('deliveryAddress');
                setAccordionToShow('deliveryAddress');
                break;
            case 'billingDetails':
                setLayerToShow('billingAddress');
                setAccordionToShow('billingAddress');
                break;
            case 'selectedStore':
                selectStore('');
                setLayerToShow('clickAndCollect');
                setAccordionToShow('clickAndCollect');
                break;
            default:
                break;
        }
    }

    function renderHomeDeliveryPage() {
        //TODO: accordionToShow is for desktop, and layerToShow is for mobile, but both should have the same value - so we could refactor to a single variable.
        let accordionOrLayerToShow = isDesktop ? accordionToShow : layerToShow;

		const collectionDetails = getCollectionDetails(delivery);

        if ( isDesktop && selectedDeliveryOption.value === 'clickAndCollect' && !billingAddress.ID && isStoreSelected && ((collectionDetails.firstName && collectionDetails.lastName) || collectionDetails.notYouCollectingFirstName) && isDeliveryMethodSelected ) {
            //This rule takes precedent because if the user has selected C&C, they can ONLY input a billing address manually.
            return 'billingAddress';
        } else if ( isDesktop && selectedDeliveryOption.value === 'home' && !deliveryAddress.ID) {
            //Allow them to enter the full address if there's no set address, and the user has selected home delivery
            return 'deliveryAddress';
        } else if (accordionOrLayerToShow === 'deliveryAddress' || accordionOrLayerToShow === 'billingAddress') {
            return accordionOrLayerToShow;
        }

        return '';
	}

    /**
     * Checks if all the delivery data needed for the checkout are entered
     * Used to determine if the 'Checkout Securely' button should be enabled or disabled.
     */
    function checkUserCanCheckout(): boolean {
        if (isNewPageLoading) {
            return false;
        }

        const isHomeDeliveryValid = selectedDeliveryOption.value === 'home' && isHomeDetailsSelected && (deliveryAddress.isDefaultBillingAddress || isBillingAddressSelected);
        const isClickCollectValid = selectedDeliveryOption.value === 'clickAndCollect' && isCollectionDetailsSelected && isStoreSelected && isBillingAddressSelected;
        let isPaymentMethodValid = isPaymentMethodSelected;

        if (isServiceAgent) {
            // Service agents don't select a payment method
            if ((isClickCollectValid || isHomeDeliveryValid) && isDeliveryMethodSelected) {
                return true;
            }
            return false;
        }
        // If the user is about to pay with a card, the form data must be valid.
        if (selectedPaymentMethod.name === 'CARD' && selectedPaymentMethod.additionalData?.hostedPaymentGateway !== 'MolPay') {
            if ((!adyenData.paymentMethod?.holderName && !adyenData.paymentMethod?.storedPaymentMethodId) || !isAdyenDataValid) isPaymentMethodValid = false;
        }

        if (isDeliveryMethodSelected && (isPaymentMethodValid || isZeroPayment) && (isClickCollectValid || isHomeDeliveryValid)) {
            return true;
        }
        return false;
    }

    //renderDeliveryMethods contains the logic to determine whether or not to render the delivery options.
    function renderDeliveryMethods() {
        switch (selectedDeliveryOption.value) {
            case 'home':
                //No additional checks here - we've already checked the length of the methods list above.
				if (isHomeDetailsSelected) {
					showDeliveryMethodsView();
                    return deliveryMethodList;
                }
                break;
            case 'clickAndCollect':
                //Only show options when collection details are set, and a store is selected
				if (isClickAndCollect && !isDeliveryMethodSelected) {
					showDeliveryMethodsView();
					
                    return deliveryMethodList;
                }    
                break;
            default:
                //Invalid selectedDeliveryOption.
                return null;
        }

        return null;
	}

	
    function showDeliveryMethodsView() {
        if(!showDeliveryMethodView) {
			setShowDeliveryMethodView(true);
        }
    }

    return (
            <Grid
                columns={isDesktop ? ['fit', 'fit'] : ['fill']}
                rows={['fit']}
                areas={isDesktop ? [
                    { name: 'left', start: [0, 0], end: [0, 0] },
                    { name: 'right', start: [1, 0], end: [1, 0] },
                ] : []}
            >
                {/** Desktop......................... */}
                {/*  This product summary box will only render in desktop/ iPad view */}
                {isDesktop ?
                    <FloatingBox gridArea="right">

                        <Step
                            title={translate(translation, 'basketSummary', `Summary (${productCount})`, { productCount })}
                            showSeparator={theme?.config?.summaryStepShowSeparator ?? true}
                            icon={<Anchor href={cartURL}><Edit/></Anchor>}
                        >
                            <Box
                                flex="grow"
                                width={webBoxWidth}
                                height={{max: theme?.config?.summaryDesktopBoxMaxHeight ?? '350px'}}
                                overflow={{vertical: productCount > 3 ? 'scroll' : 'auto'}}
                            >
                                <ProductsSummary />
                            </Box>
                        </Step>
                        <Step
                            title={theme?.config?.summaryShowTotalsTitle ? strings.totalsSummary : undefined}
                            showSeparator={false}
                        >
                            <Box direction="column" fill="horizontal">
                                <TotalSummary disableEmailAndSmsOptIn={disableCheckout}/>
                                { checkUserCanCheckout() && isRenderOnDeliveryPage === true && !isZeroPayment
                                    ? paymentMethodCustomCTAPlaceholder
                                    : checkoutSecurelySubmitButton
                                }
                            </Box>
                        </Step>
                        { privacyPolicy.link &&
                            <Box margin={{ left: "xsmall", top: "xsmall"}} direction="column">
                                {privacyPolicyText}
                            </Box>
                        }

                        {/* End right hand product summary box */}
                    </FloatingBox> : null}
                {/* End of summary box only rendering in desktop view */}


                {/* Contains whole Desktop and mobile view*/}
                <Box margin={isDesktop ? { right: desktopViewCentralMargin } : ""}>
				
					{/* Desktop delivery options*/}
                    { isDesktop && deliveryOptionsRadioList }
				
                    {/* Delivery Address Summary displayed in Desktop */}
                    {/* Home delivery - Home details OK:Display the home delivery details */}
                    {isDesktop && selectedDeliveryOption.value === 'home' && deliveryAddress.ID &&
                        <Box>
                            <Step title={strings['home'].STEP_TITLE}>
                                <Box flex="grow">
                                    <EditableDetails
                                        extra={
                                            <Anchor>
                                                <Edit onClick={(event) => {
                                                    newRelicData({ actionName: 'delivery', function: 'in anchor button edit click', message: 'user clicked edit address button'});
                                                    // We show the address book if the customer already has addresses
                                                    if (addresses.length) {
                                                        setAddressTypeToEdit('delivery');
                                                        dispatch(updateAddressBookType('delivery'));
                                                        setAccordionToShow('addressBook');
                                                    }
                                                    else {
                                                        setAddressTypeToEdit('delivery');
                                                        setAccordionToShow('deliveryAddress');
                                                    }
                                                }} />
                                            </Anchor>}
                                        main={<FormattedAddress type="homeAddress" details={deliveryAddress} />}
                                        showPadding={false}
                                    />
                                </Box>
                            </Step>
                        </Box>
				}

                {/** show the address book in Desktop to edit a delivery address */}
                {isDesktop && accordionToShow === 'addressBook' && addressTypeToEdit === 'delivery' &&
                    addressBook
				}				
				{/* RENDER THE SELECTED CLICK AND COLLECT METHOD SUMMARY BOX - DESKTOP */}
				{ isDesktop && selectedDeliveryOption.value === 'clickAndCollect' && isDeliveryMethodSelected &&
					selectedDeliveryMethodSummary
				}

				<Box gridArea="left" ref={deliveryAddressFormRef}>
					{ isDesktop && renderHomeDeliveryPage() === 'deliveryAddress' ?
						//ts-ignore the HomeDeliveryPage line because `renderHomeDeliveryPage()` sometimes returns '' - which leads to an error on formType. We do check for '' above though.
						//@ts-ignore
						<HomeDeliveryPage onClose={closeExtraView} formType={renderHomeDeliveryPage()} addressId={addressIdToEdit}/>
					:
						null
					}
				</Box>

				{/* RADIO OPTIONS TO SELECT HOME DELIVERY OR CLICK AND COLLECT FOR MOBILE */}
				{!isDesktop &&
					deliveryOptionsRadioList
				}
				
			    <Box margin={!isDesktop && theme?.config?.stepBorder ? { "top": "medium" } : ''}>
						{renderDeliveryMethods()}
				</Box>
				{/* RENDER THE SELECTED CLICK AND COLLECT METHOD SUMMARY BOX - MOBILE */}
				{!isDesktop && selectedDeliveryOption.value === 'clickAndCollect' && isDeliveryMethodSelected &&
					selectedDeliveryMethodSummary
				}

				{/* CLICK AND COLLECT STORE SELECTOR FOR DESKTOP */}
				{isDesktop && selectedDeliveryOption.value === 'clickAndCollect' && isDeliveryMethodSelected &&
					<Step>
						<Box flex="grow">
							<ClickAndCollectPage onClose={closeExtraView} />
						</Box>
					</Step>
                }
				
                {/* Home Delivery - No home details selected || C&C - Either no store selected or no collection details selected:
                Display button to open 'Home Delivery' or 'C&C' layer */}
                    {(
                        (selectedDeliveryOption.value === 'home' && !isHomeDetailsSelected) ||
                        (selectedDeliveryOption.value === 'clickAndCollect' && (!isStoreSelected || !isCollectionDetailsSelected))
                    )}

                    {/* Billing Section in desktop */}
                    {isDesktop && billingAddress.ID &&
                        <Box>
                            {/* Tickbox for default billing address (home delivery only) */}
                            {selectedDeliveryOption.value === 'home' && (isBillingAddressSelected && (deliveryAddress.ID === billingAddress.ID)) &&
                                <Step title={billingAddress.ID ? strings['billing'].STEP_TITLE : ''}>
                                    <Box flex="grow">
                                        <Box direction="row" justify="between">
                                            <Box direction="row" align='center'>
                                                {tickIcon} <Text margin={{ left: 'small' }}>{strings.sameAsDeliveryAddress}</Text>
                                            </Box>
                                            <Box>
                                                <Anchor>
                                                    <Edit onClick={(event) => {
                                                        // We show the address book if the customer already has addresses
                                                        if (addresses.length) {
                                                            setAddressTypeToEdit('billing');
                                                            dispatch(updateAddressBookType('billing'));
                                                            setAccordionToShow('addressBook');
                                                        }
                                                        else {
                                                            setAccordionToShow('billingAddress');
                                                        }
                                                    }} />
                                                </Anchor>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Step>                
                            }
                            {/* Full, specific (non-default) billing details (home delivery or C&C)*/}
                            {/* Only want show billing summary if it is different to the delivery address*/}
                            {(
                                ((selectedDeliveryOption.value === 'home') && (isBillingAddressSelected && (deliveryAddress.ID !== billingAddress.ID))) ||
                                ((selectedDeliveryOption.value === 'clickAndCollect') && (isBillingAddressSelected && isStoreSelected && isCollectionDetailsSelected && isDeliveryMethodSelected))
                            ) &&
                                <Step title={billingAddress.ID ? strings['billing'].STEP_TITLE : ''}>
                                    <Box flex="grow">
                                        <Box>
                                            <Box flex="grow">      
                                                <EditableDetails
                                                    extra={<Anchor><Edit onClick={() => {
                                                        // We show the address book if the customer already has addresses
                                                        if (addresses.length) {
                                                            setAddressTypeToEdit('billing');
                                                            dispatch(updateAddressBookType('billing'));
                                                            setAccordionToShow('addressBook');
                                                        }
                                                        else {
                                                            setAddressTypeToEdit('billing');
                                                            setAccordionToShow('billingAddress');
                                                        }
                                                    }} /></Anchor>}
                                                    main={<FormattedAddress type="homeAddress" details={billingAddress} />}
                                                    showPadding={false}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Step>
                            }
                        </Box>
                    }
                    {/** show the address book in Desktop to edit a billing address */}
                    {isDesktop && accordionToShow === 'addressBook' && addressTypeToEdit === 'billing' &&
                        addressBook
                    }
                    <Box gridArea="left" ref={billingAddressFormRef}>
                        { isDesktop && renderHomeDeliveryPage() === 'billingAddress' ?
                            //ts-ignore the HomeDeliveryPage line because `renderHomeDeliveryPage()` sometimes returns '' - which leads to an error on formType. We do check for '' above though.
                            //@ts-ignore
                            <HomeDeliveryPage onClose={closeExtraView} formType={renderHomeDeliveryPage()} addressId={addressIdToEdit}/>
                            :
                            null
                        }
                    </Box>

                    {/* Payment Methods Section only visible in Desktop */}
                    {isDesktop && isDeliveryMethodSelected && billingAddress.ID && !['billingAddress', 'deliveryAddress', 'addressBook'].includes(accordionToShow) &&
                        (
                            (selectedDeliveryOption.value === 'home' && deliveryAddress.ID) ||
                            (selectedDeliveryOption.value === 'clickAndCollect' && (isStoreSelected && isCollectionDetailsSelected && isDeliveryMethodSelected))
                        ) &&
                        <TitleBar title={strings.paymentOptions} borderSide={isDesktop
                            ? theme?.config?.desktop?.borderSide ?? 'bottom'
                            : 'bottom'} />
                    }

                    {/* Gift Cards on Desktop */}
                    {isDesktop && isDeliveryMethodSelected && canUseGiftCards && billingAddress.ID && !['billingAddress', 'deliveryAddress', 'addressBook'].includes(accordionToShow) &&
                        (
                            (selectedDeliveryOption.value === 'home' && deliveryAddress.ID) ||
                            (selectedDeliveryOption.value === 'clickAndCollect' && (isStoreSelected && isCollectionDetailsSelected && isDeliveryMethodSelected))
                        ) &&
                        <GiftCardForm/>
                    }

                    {/* Payment Methods Section only visible in Desktop */}
                    {(
                        isDesktop && isDeliveryMethodSelected && billingAddress.ID && !['billingAddress', 'deliveryAddress', 'addressBook'].includes(accordionToShow) && !isZeroPayment && !isServiceAgent &&
                        (
                            (selectedDeliveryOption.value === "home" && deliveryAddress.ID) ||
                            (selectedDeliveryOption.value === 'clickAndCollect' && (isStoreSelected && isCollectionDetailsSelected && isDeliveryMethodSelected))
                        )
                    ) &&
                        paymentMethodSelector
                    }

                    {/* end of desktop left hand box .....................*/}


                    {/* Store selected but no C&C delivery method:
                Error message */}
                    {(
                        (selectedDeliveryOption.value === 'clickAndCollect' && (isStoreSelected && isCollectionDetailsSelected)) && showDeliveryMethodView &&
                        radioMethods.length === 0
                    ) &&
                        <Box flex={false} background="light-3" pad="small" margin={{ vertical: "medium" }}>
                            <InfoBox text={strings.noDeliveryMethodForCAndC} />
                        </Box>
                    }
                    {/* Home Delivery - No home details selected || C&C - Either no store selected or no collection details selected:
                Display button to open 'Home Delivery' or 'C&C' layer */}
                    {(
                        (selectedDeliveryOption.value === 'home' && !isHomeDetailsSelected) ||
                        (selectedDeliveryOption.value === 'clickAndCollect' && (!isStoreSelected || !isCollectionDetailsSelected))
                    ) &&

                        // Add Address Button shown in mobile view only
                        <Box flex="grow">
						{!isDesktop &&
							(selectedDeliveryOption.value === 'home' || (selectedDeliveryOption.value === 'clickAndCollect' && isDeliveryMethodSelected)) &&
                                <Step title={strings[selectedDeliveryOption.value].STEP_TITLE}>
                                    <Box flex="grow">
                                        <Button
                                            label={strings[selectedDeliveryOption.value].STEP_BUTTON}
                                            a11yTitle={strings[selectedDeliveryOption.value].STEP_BUTTON}
                                            onClick={() => {
                                                if (selectedDeliveryOption.value === 'clickAndCollect') {
                                                    setLayerToShow('clickAndCollect');
                                                }
                                                else {
                                                    // We show the address book if the customer already has addresses
                                                    if (addresses.length) {
                                                        setAddressTypeToEdit('delivery');
                                                        setLayerToShow('addressBook');
                                                    }
                                                    else {
                                                        setLayerToShow('deliveryAddress');
                                                    }
                                                }
                                            }}
                                            fill={true}
                                        />
                                    </Box>
                                </Step>
                            }
                        </Box>
                    }

                    {/*  mobile only C&C - Store selected, collection details OK:
                Display of the store collection details */}
                    {!isDesktop && selectedDeliveryOption.value === 'clickAndCollect' && (isStoreSelected && isCollectionDetailsSelected) &&
                        <Box background="white" flex={false}>
                            <Step title={strings['clickAndCollect'].STEP_TITLE}>
                                <Box flex="grow">
                                    <EditableDetails
                                        title={selectedStore.name}
                                        extra={<Anchor><Edit onClick={() => onEditDetails('selectedStore')} /></Anchor>}
                                        main={<FormattedAddress type="storeAddress" details={selectedStore.address} />}
                                        isDetailsButton={true}
                                        onDetailsClick={() => selectStoreForDetailedView(selectedStore.ID)}
                                    />
                                </Box>
                            </Step>
                            <Step title={strings.COLLECTION_DETAILS}>
                                <Box flex="grow">
                                    <EditableDetails
                                        extra={<Anchor><Edit onClick={() => onEditDetails('collectionDetails')} /></Anchor>}
                                        main={<FormattedAddress type="collectionDetails" details={collectionDetails} />}
                                    />
                                </Box>
                            </Step>
                        </Box>
                    }

                    {/* Home delivery - Home details OK:
                Display the home delivery details */}
                    {!isDesktop && selectedDeliveryOption.value === 'home' && isHomeDetailsSelected &&
                        <Step title={strings['home'].STEP_TITLE}>
                            <Box flex="grow">
                                <EditableDetails
                                    extra={<Anchor><Edit onClick={() => {
                                        // We show the address book if the customer already has addresses
                                        if (addresses.length) {
                                            setAddressTypeToEdit('delivery');
                                            dispatch(updateAddressBookType('delivery'))
                                            setLayerToShow('addressBook');
                                        }
                                        else {
                                            setLayerToShow('deliveryAddress');
                                        }
                                    }} /></Anchor>}
                                    main={<FormattedAddress type="homeAddress" details={deliveryAddress} />}
                                />
                            </Box>
                        </Step>
                    }

                    {/* Home delivery, delivery address selected but no delivery method: Info box */}
                    {(
                        (selectedDeliveryOption.value === 'home' && isHomeDetailsSelected) && showDeliveryMethodView &&
                        radioMethods.length === 0
                    ) &&
                        <Box flex={false} background="light-3" pad="small" margin={{top: 'large', horizontal: 'medium'}}>
                            <InfoBox text={strings.noDeliveryMethodForHomeDelivery} />
                        </Box>
                    }

                    {/* Either CC or Home delivery: details filled in correctly:
                Display of the choice of delivery methods */}

                    {(
                        ((!isDesktop && radioMethods.length > 0) &&
                            (
                                (selectedDeliveryOption.value === 'home' && isHomeDetailsSelected) ||
                                (selectedDeliveryOption.value === 'clickAndCollect' && (isCollectionDetailsSelected))
                            ))
                    ) &&
                        <>
                            {/* ACTUAL LIST OF DELIVERY METHODS FOR MOBILE -   *************** Delivery Methods Section */}
                            {/* {deliveryMethodList} */}
                        </>
                    }

                    {
                        //On mobile, we want to show the button to toggle the billing address form if the user is on C&C without an address set.
                        (!isDesktop && selectedDeliveryOption.value === 'clickAndCollect' && isStoreSelected && isCollectionDetailsSelected && !billingAddress.ID) ?
                            <Box>
                                <Step title={strings.billing.STEP_TITLE}>
                                    <Box flex="grow">
                                        <Button
                                            label={strings.billing.STEP_BUTTON}
                                            a11yTitle={strings.billing.STEP_BUTTON}
                                            onClick={() => {
                                                    if (addresses.length) {
                                                        setAddressTypeToEdit('billing');
                                                        setLayerToShow('addressBook');
                                                    }
                                                    else {
                                                        setLayerToShow('billingAddress');
                                                    }
                                                }
                                            }
                                            fill={true}
                                        />
                                    </Box>
                                </Step>
                            </Box>
                        :
                            null
                    }

                    {/* Billing Section in mobile only */}
                    {!isDesktop && ((selectedDeliveryOption.value === 'home' && deliveryAddress.ID) ||
                        (selectedDeliveryOption.value === 'clickAndCollect' && billingAddress.ID)) ?
                        <Box flex="grow">
                            {/* Tickbox for default billing address (home delivery only) */}
                            {selectedDeliveryOption.value === 'home' && (isBillingAddressSelected && (deliveryAddress.ID === billingAddress.ID) &&
                                <Step title={billingAddress.ID ? strings['billing'].STEP_TITLE : ''}>
                                    <Box direction="row" justify="between">
                                            <Box direction="row" align='center'>
                                                {tickIcon} <Text margin={{ left: 'small' }}>{strings.sameAsDeliveryAddress}</Text>
                                            </Box>
                                            <Box>
                                                <Anchor>
                                                    <Edit onClick={(event) => {
                                                        // We show the address book if the customer already has addresses
                                                        if (addresses.length) {
                                                            setAddressTypeToEdit('billing');
                                                            dispatch(updateAddressBookType('billing'));
                                                            setLayerToShow('addressBook');
                                                        }
                                                        else {
                                                            setLayerToShow('billingAddress');
                                                        }
                                                    }} />
                                                </Anchor>
                                            </Box>
                                    </Box>
                                </Step>
                                    )
                                }

                                {/* Full, specific (non-default) billing details (home delivery or C&C)*/}
                                {/* Only want show billing summary if it is different to the delivery address*/}
                                {(!isDesktop &&
                                        (((selectedDeliveryOption.value === 'home') && (isBillingAddressSelected && (deliveryAddress.ID !== billingAddress.ID))) ||
                                        ((selectedDeliveryOption.value === 'clickAndCollect') && (isBillingAddressSelected && isStoreSelected && isCollectionDetailsSelected)))
                                    ) &&
                                    <Step title={billingAddress.ID ? strings['billing'].STEP_TITLE : ''}>
                                        <Box>
                                            <Box flex="grow">
                                                <EditableDetails
                                                    extra={<Anchor><Edit onClick={() => {
                                                        // We show the address book if the customer already has addresses
                                                        if (addresses.length) {
                                                            setAddressTypeToEdit('billing');
                                                            dispatch(updateAddressBookType('billing'))
                                                            setLayerToShow('addressBook');
                                                        }
                                                        else {
                                                            setAddressTypeToEdit('billing');
                                                            setLayerToShow('billingAddress');
                                                        }
                                                    }} /></Anchor>}
                                                    main={<FormattedAddress type="homeAddress" details={billingAddress} />}
                                                />
                                            </Box>
                                        </Box>
                                    </Step>
                                }                               
                        </Box> : null}
                    
                    {/* Either CC or Home delivery: details filled in correctly:
                Display of the choice of payment methods and billing details */}

                    {(
                        ((!isDesktop && radioMethods.length > 0 && isBillingAddressSelected) &&
                            (
                                (selectedDeliveryOption.value === 'home' && isHomeDetailsSelected) ||
                                (selectedDeliveryOption.value === 'clickAndCollect' && (isStoreSelected || isCollectionDetailsSelected))
                            ))
                    ) &&
                        <>

                            {/* Giftcard form if the cart is marked as eligible. */}
                            {canUseGiftCards && isDeliveryMethodSelected &&
                                <GiftCardForm/>
                            }

                            {/* Payment Methods Section */}
                            {isDeliveryMethodSelected && !isZeroPayment && !isServiceAgent &&
                                (
                                    (selectedDeliveryOption.value === 'home' && isHomeDetailsSelected) ||
                                    (selectedDeliveryOption.value === 'clickAndCollect' && ((isStoreSelected && collectionDetails.firstName) || isCollectionDetailsSelected))
                                ) &&
                                paymentMethodSelector
                            }
                        </>
                    }                                   

                    {/* Summary Section to only show in mobile view */}
                    {!isDesktop ?
                        <Box width={isDesktop ? webBoxWidth : '800px'}>
                            <Step
                                title={translate(translation, 'basketSummary', `Summary (${productCount})`, { productCount })}
                                showSeparator={theme?.config?.summaryStepShowSeparator ?? true}
                                icon={<Anchor href={cartURL}><Edit/></Anchor>}
                            >
                                <Box flex="grow" border={true} pad={{bottom: (isDesktop || !showMobileProductSummary ? '' : 'medium')}}>
                                    <TitleBar
                                        title={strings.myItems}
                                        icon={ <Anchor data-testid="myitems-summary" onClick={() => setShowMobileProductSummary(!showMobileProductSummary)}>
                                                { showMobileProductSummary
                                                    ? <ArrowUp/>
                                                    : <ArrowDown/>
                                                }
                                              </Anchor> }
                                        showBorder={false}
                                    />
                                    { showMobileProductSummary &&
                                        <ProductsSummary />
                                    }
                                </Box>
                            </Step>
                            <Step
                                title={theme?.config?.summaryShowTotalsTitle ? strings.totalsSummary : undefined}
                                showSeparator={false}
                            >
                                <Box direction="column" fill="horizontal">
                                    <TotalSummary disableEmailAndSmsOptIn={disableCheckout}/>
                                    { checkUserCanCheckout() && isRenderOnDeliveryPage === true && !isZeroPayment
                                        ? paymentMethodCustomCTAPlaceholder
                                        : checkoutSecurelySubmitButton
                                    }
                                </Box>
                                { privacyPolicy.link &&
                                    <Box margin={{ left: "xsmall", top: "xsmall"}} direction="row">
                                        {privacyPolicyText}
                                    </Box>
                                }

                            </Step>

                            {(layerToShow || showCardSelectorLayer) &&
                                <Layer target={pageRef?.current || undefined}
                                    responsive={false}
                                    animation="slide"
                                    position="bottom"
                                >
                                    {/* SHOW THE CLICK AND COLLECT PAGE ON MOBILE */}
                                    { !isDesktop && layerToShow === 'clickAndCollect' && isDeliveryMethodSelected &&
                                        <ClickAndCollectPage
                                            onClose={closeExtraView}
                                            mobileFullHeight={true}
                                            showStoreDetailsInCAndCView={true}
                                        />
                                    }
                                    { !isDesktop && renderHomeDeliveryPage() !== '' ?
                                        //ts-ignore the HomeDeliveryPage line because `renderHomeDeliveryPage()` sometimes returns '' - which leads to an error on formType. We do check for '' above though.
                                        //@ts-ignore
                                        <HomeDeliveryPage onClose={closeExtraView} formType={renderHomeDeliveryPage()} addressId={addressIdToEdit}/>
                                    :
                                        null
                                    }
                                    {layerToShow === 'addressBook' &&
                                        addressBook
                                    }
                                    {
                                        (!isDesktop && showCardSelectorLayer) &&
                                        <PaymentCards />
                                    }
                                </Layer>
                            }
                            {showStoreDetailedView && isStoreSelected && !isDesktop &&
                                <Layer target={pageRef?.current || undefined}
                                    responsive={false}
                                    animation="slide"
                                    position="bottom"
                                    onClickOutside={closeExtraView}
                                    onEsc={closeExtraView}
                                    full={true}
                                >
                                    <StoreDetails
                                        store={selectedStore}
                                        onGetDirections={() => { }}
                                        onCallStore={() => { }}
                                        onClose={() => selectStoreForDetailedView('')}
                                    />
                                </Layer>
                            }
                        </Box> : null}
                    {/* * End of Summary Section to only show in mobile view */}
                </Box>

            </Grid>
    );
}

type DeliveryProps = DeliveryMapToProps;

export default Delivery;
