import { ConfigActionTypes, UPDATE, Config, ConfigFilters } from './types'
import { AppThunk } from '../thunk';
import initConfigService from '../../services/config';
import { RootState } from '../../store/reducers';
import { Cart } from '../../modules/cart/types';
import { getHasDropshipProducts, getProducts } from "../cart";
import { local as browserLocalStorage } from "store2";
import { merge } from "lodash";
import newRelicData from "../../newrelic";

// Reducer
export const initialState: Config = {
    returnUrl: '',
    message: '',
    channel: 'mobile',
    store: 'blank',
    api: {
        mesh: {
            url: process.env.REACT_APP_MESH_ENV === 'prod' ? 'https://prod.jdgroupmesh.cloud' : 'https://uat.jdgroupmeshtest.cloud',
            apiKey: "93885D2F498549709C1273569D858123"
        },
        language: {
            url: "https://language.jdmesh.co/api/v1",
            apiKey: "AIFLY6JC3Q0GP4SVRSY2DW5SWILAIELJ",
        },
    },
    click_and_collect: true,
    click_and_collect_tabs: true,
    privacy_policy: {
        link: '',
        date: '',
        text: ''
    },
    concat_address_for_klarna: false,
    localisation: {
        language: {
            code: 'en-GB',
        },
        countryCode: 'GB',
        symbolsInPassword: true,
        restrictSameCharacter3TimesInPassword: true,
        passwordHelpText: '',
        distanceUnits: ''
    },
    adyen: {
        environment: process.env.REACT_APP_MESH_ENV === 'prod' ? 'live' : 'test',
        clientKey: '',
        merchantAccount: '',
        locale: 'en-GB',
        countryCode: 'GB',
    },
    apple_pay: {
        merchantId: '',
        merchantCountryCode: '',
        merchantDisplayName: '',
        label: '',
        supportedNetworks: [],
        merchantCapabilities: []
    },

    google_pay: {
        merchantDisplayName: '',
        allowedCardNetworks: ["AMEX", "DISCOVER", "ELECTRON", "ELO", "ELO_DEBIT", "INTERAC", "JCB", "MAESTRO", "MASTERCARD", "VISA"],
        allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
        allowedPaymentMethods: ["CARD"],
        allowedCountryCodes: ["UK"]
    },
    klarna_express: {
        region: 'eu',
        locale: 'en-GB',
        countryCode: 'GB',
    },
    openpay: {
        baseUrl: 'https://websales.training.myopenpay.co.uk',
    },
    pay_later_links: {

    },
    giftcards: {
        eGiftcardSku: '',
        physicalGiftcardSku : ''

    },
    custom_order_tracking: true,
    store_descriptions: {
        store : '',
        postOffice : ''
    },
    payment_options_descriptions: {
        homeDelivery : '',
        clickAndCollect : '',
    },
    show_marketing_checkboxes: false,
    show_cobrands: false,
    delivery_georestrictions: {
        brands: [],
    },
    marketing_sms_optout: true,
    express_optout_checkbox: false,
    marketing_all_optout_text: false,
    marketing_email_optout: true,
    hide_klarna_disclosure_links: false,
    execute_threeD: false,
    payinthree_disclosure_text: {
        required: false,
        value: '',
        link: '',
        linkLabel: '',
    },
    payinthree_express: {
        label: '',
    },
    nike_connected: false,
    bing_adds_id: '',
    marketing_details: {
        text: 'Want to hear about the latest offers and competitions and product drops? By signing up you agree to receiving marketing and online marketing on websites and social media. You can unsubscribe at anytime by clicking the unsubscribe link in any of the emails or SMS.',
        emailLabel: 'Yes to email',
        smsLabel: 'Yes to SMS'
    },
    enable_qualtrics: {
        id: '',
        channel: ''
    },
    country_unselected: false,
    show_translated_countries: false,
    country_details : [{
        code: '',
        name: '',
        default: false,
        requiresState: false,
        pcaPredictSupported: false
    }],
}

export default function reducer(state = initialState, action: ConfigActionTypes) {
    switch (action.type) {
        case UPDATE:
            return { ...action.config }
        default:
            return state;
    }
}

// Selectors
export const getApi = (state: Config, apiName: string) => state.api[apiName];
export const getMessage = (state: Config) => state.message;
export const getReturnURL = (state: Config) => state.returnUrl;
export const getChannel = (state: Config) => state.channel;
export const getStore = (state: Config) => state.store;
export const getPrivacyPolicy = (state: Config) => state.privacy_policy;
export const getLocalisation = (state: Config) => state.localisation;
export const getAdyen = (state: Config) => state.adyen;
export const getApplePay = (state: Config) => state.apple_pay;
export const getGooglePay = (state: Config) => state.google_pay;
export const getOpenpay = (state: Config) => state.openpay;
export const getKlarnaExpress = (state: Config) => state.klarna_express;
export const getCountryCode = (state: Config) => state.localisation.countryCode;
export const getDistanceUnits = (state: Config) => state.localisation.distanceUnits;
export const getPayLaterDetailsLinks = (state: Config) => state.pay_later_links;
export const getGiftCardSkuDetails = (state: Config) => state.giftcards;
export const getStoreDescriptions = (state: Config) => state.store_descriptions;
export const getPaymentOptionsDescriptions = (state: Config) => state.payment_options_descriptions;
export const getShowMarketingCheckboxes = (state: Config) => state.show_marketing_checkboxes;
export const getMarketingSmsOptout = (state: Config) => state.marketing_sms_optout;
export const getMarketingEmailOptout = (state: Config) => state.marketing_email_optout;
export const getExpressOptoutCheckbox = (state: Config) => state.express_optout_checkbox;
export const getGeorestrictedBrands = (state: Config) => state.delivery_georestrictions.brands;
export const getFasciaAllowsPayWithGiftCards = (state: Config) => {
    return !!state.giftcards.eGiftcardSku || !!state.giftcards.physicalGiftcardSku;
};
export const getPayInthreeExpressDetails = (state: Config) => state.payinthree_express;
export const isCAndCAvailable = (state: Config, cart: Cart) => {
    const products = getProducts(cart);
    const hasDropshipProducts = getHasDropshipProducts(cart);
    const giftcardSku = getGiftCardSkuDetails(state);
    const cartHasNonGiftCardProducts = products.some(product => product.parentSKU !== giftcardSku.eGiftcardSku) ;
    let isCAndCAvailable = true;

    if (!state.click_and_collect || !cartHasNonGiftCardProducts || (cartHasNonGiftCardProducts && hasDropshipProducts)) {
        isCAndCAvailable = false;
    }
    return isCAndCAvailable;
};
export const isMarketingAllOptoutText = (state: Config) => state.marketing_all_optout_text;
export const getIsNikeConnected = (state: Config) => state.nike_connected;
export const getMarketingDetails = (state: Config) => state.marketing_details;
export const getTranslatedCountryDetails = (state: Config) => state.country_details;
export const getShowTranslatedCountries = (state: Config) => state.show_translated_countries;

// Action Creators

export function updateConfig(config: Config): ConfigActionTypes {
    return {
        type: UPDATE,
        config
    };
}

// side effects, only as applicable
export function loadConfig(filters: ConfigFilters): AppThunk<void> {
    return async dispatch => {
        const response = await fetch('/config');
        const body = await response.json();
        dispatch(updateConfig(body))
    }
}

function getFasciaConfig() {
    const fasciaCountryCode = browserLocalStorage.get('countryCode')?.toUpperCase()
    const localisationMappings = [
        {
            languageCode: 'fr-FR',
            countryCode: 'FR',
        },
        {
            languageCode: 'it-IT',
            countryCode: 'IT',
        },
        {
            languageCode: 'es-ES',
            countryCode: 'ES',
        },
        {
            languageCode: 'nl-NL',
            countryCode: 'NL',
        },
        {
            languageCode: 'de-DE',
            countryCode: 'DE',
        },
        {
            languageCode: 'da-DK',
            countryCode: 'DK',
        },
        {
            languageCode: 'fi-FI',
            countryCode: 'FI',
        },
        {
            languageCode: 'sv-SE',
            countryCode: 'SE',
        },
        {
            languageCode: 'en-NZ',
            countryCode: 'NZ',
        },
        {
            languageCode: 'de-AT',
            countryCode: 'AT',
        },
        {
            languageCode: 'nl-BE',
            countryCode: 'BE',
        },
        {
            languageCode: 'en-IE',
            countryCode: 'IE',
        },
        {
            languageCode: 'en-GB',
            countryCode: 'GB',
        },
        {
            languageCode: 'en-MY',
            countryCode: 'MY',
        },
        {
            languageCode: 'pt-PT',
            countryCode: 'PT',
        },
        {
            languageCode: 'en-AU',
            countryCode: 'AU',
        },
        {
            languageCode: 'en-SG',
            countryCode: 'SG',
        },
        {
            languageCode: 'th-TH',
            countryCode: 'TH',
        },

    ]
    const matchingLocalization = localisationMappings.find((mapping) => mapping.countryCode === fasciaCountryCode);
    return matchingLocalization ? matchingLocalization : {languageCode: 'en-GB', countryCode: 'GB'}
}

export function setupConfig(store: string, channel: string, host: string): AppThunk<Promise<RootState>> {
    return async (dispatch, getState) => {
        const returnUrl: string = `https://${host}/`;
        const state = getState();
        const config = state.config;
        let configResponse = {};
        const fasciaConfig = getFasciaConfig();
        const localisationConfig =  {...config.localisation, countryCode: fasciaConfig.countryCode, language: {code: fasciaConfig.languageCode}};
        const adyenConfig = {...config.adyen, countryCode: fasciaConfig.countryCode, locale: fasciaConfig.languageCode}
        
        try {
            configResponse = await initConfigService(state).getAll(store);
        } catch (e) {
            // if we failed to get config we should be able to fallback 
            // to our defaults
            console.error(e);
            newRelicData({ actionName: 'config', function: 'setupConfig', message: 'firebase config unavailable'});
        }
        store = store || config.store;
        channel = channel || config.channel;
        const newConfig = {
            ...config, ...configResponse, store, channel, returnUrl,
            localisation: localisationConfig,
            adyen: adyenConfig
        };
        const mergedConfig = merge(newConfig,configResponse);
        await dispatch(updateConfig(mergedConfig))
        
        return getState();
    }
}
