import React, { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Anchor, Box, ResponsiveContext, Text } from 'grommet';
import { Close } from 'grommet-icons';
import { ThemeContext } from 'styled-components';
import { getMeshErrorAndDelete } from '../../services/mesh-sdk/client';
import { AppThunkDispatch } from '../../modules/thunk';
import { RootState } from '../../store/reducers';
import { CustomTheme } from '../../theme';
import PropTypes from "prop-types";
import { Information } from '../../components/Icon/index';
import { getNotificationDetails, getGenericErrorMessage, clearNotification } from '../../modules/notification';

function Notification({ textColour, background }: NotificationProps) {
    const fullState = useSelector((state: RootState) => state);
    const dispatch: AppThunkDispatch = useDispatch();
    const size = React.useContext(ResponsiveContext);
    const notificationDetails = getNotificationDetails(fullState, dispatch);
    const genericErrorMessage = getGenericErrorMessage(fullState.notification);

    const theme: CustomTheme = useContext(ThemeContext);
    const fontSize = theme?.config?.notificationFontSize ?? "12px";
    const boxHeight = theme?.config?.notificationBoxHeight ?? "48px";
    const iconsSize = theme?.config?.notificationIconsSize ?? "15px";
    const isDesktop = size !== 'small';

    /**
     * @returns Object with formatting data that depends on the notification type.
     */
    function getFormat() {
        switch(notificationDetails.type) {
            case 'info':
                return {
                    background: background ?? notificationDetails.backgroundColor ?? theme?.config?.appBackground ?? 'light-1',
                    fontWeight: notificationDetails.fontWeight ?? 'normal',
                    textColour: textColour ?? notificationDetails.textColor ?? 'black',
                    icon: notificationDetails.icon ? React.createElement(notificationDetails.icon) : <Information />,
                };
                
            case 'error':
            default:
                return {
                    background: background ?? notificationDetails.backgroundColor ?? theme?.config?.notificationErrorBackgroundColor ?? 'red',
                    fontWeight: notificationDetails.fontWeight ?? theme?.config?.notificationErrorFontWeight ?? 'bold',
                    textColour: textColour ?? notificationDetails.textColor ?? theme?.config?.notificationErrorTextColor ?? 'white',
                    icon: notificationDetails.icon ? React.createElement(notificationDetails.icon) : undefined,
                };
        }
    }

    const format = getFormat();
    const showCloseButton = !notificationDetails.hideNotificationCloseButton;

    if (!notificationDetails.message && !genericErrorMessage) return <></>;

    return (
        <Box
            height={{min: boxHeight}}
            flex={false}
            background={format.background}
            overflow="auto"
        >
            <Box
                direction="row"
                justify="between"
                alignContent="center"
                fill={true}
                margin={{vertical: 'auto'}}
            >
                {/* Left-side icon */}
                <Box
                    width={iconsSize}
                    justify="center"
                    flex={false}
                    margin={{horizontal: isDesktop ? 'large' : 'small'}}
                >
                    {format.icon &&
                        format.icon
                    }
                    {!format.icon &&
                        // Empty box taking the space an icon would take to keep the layout consistent
                        <Box width={iconsSize} flex={false}></Box>
                    }
                </Box>

                {/* Text */}
                <Box flex={true} justify="center" align={format.icon ? 'start' : 'center'}>
                    { !!notificationDetails.message &&
                        <Text
                            color={format.textColour}
                            weight={format.fontWeight}
                            size={fontSize}
                        >{`${notificationDetails.message} `}
                        { notificationDetails.anchorText &&
                            <Anchor
                                label={notificationDetails.anchorText}
                                color={format.textColour}
                                href={notificationDetails.href ?? undefined}
                                onClick={notificationDetails.onClick ?? undefined}
                            />
                        }
                        </Text>
                    }
                    {/* Generic message showing only if there isn't a more specific message */}
                    { !notificationDetails.message && !!genericErrorMessage &&
                        <Text
                            color={format.textColour}
                            weight={format.fontWeight}
                            size={fontSize}
                        >
                            {genericErrorMessage}
                        </Text>
                    }
                </Box>

                {/* Right-side icon (close button) */}
                <Box
                    width={iconsSize}
                    justify="center"
                    flex={false}
                    margin={{horizontal: isDesktop ? 'large' : 'small'}}
                >
                    {showCloseButton &&
                        <Box
                            onClick={() => {
                                getMeshErrorAndDelete();
                                dispatch(clearNotification());
                            }}
                        >
                            <Close size={iconsSize} />
                        </Box>
                    }
                </Box>
            </Box>
        </Box>
    )
}

type NotificationProps = {
    textColour?: string,
    background?: string,
    pad?: "xsmall" | "small" | "medium" | "large",
}

Notification.prototypes = {
    textColor: PropTypes.string,
    background: PropTypes.string,
    pad: PropTypes.string,
}

export default Notification;