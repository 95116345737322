import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';
import { ThemeContext } from 'styled-components';
import { CustomTheme } from '../../theme/index'


function Separator({height, margin, color}: SeparatorProps) {
    const theme: CustomTheme = useContext(ThemeContext);

    const defaultMargins = {vertical: 'small', horizontal: 'none'};
    const defaultColor = theme?.config?.separatorColour ?? 'light-2';

    return (
        <Box
            flex={false}
            height={height ?? '1px'}
            margin={margin ?? defaultMargins}
            background={color ?? defaultColor}
        />
    );
}

type SeparatorProps = {
    height?: string,
    margin?: string | {
        bottom?: string,
        end?: string,
        horizontal?: string,
        left?: string,
        right?: string,
        start?: string,
        top?: string,
        vertical?: string,
    },
    color?: string,
};


Separator.propTypes = {
    height: PropTypes.string,
    margin: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            bottom: PropTypes.string,
            end: PropTypes.string,
            horizontal: PropTypes.string,
            left: PropTypes.string,
            right: PropTypes.string,
            start: PropTypes.string,
            top: PropTypes.string,
            vertical: PropTypes.string,
        }),
    ]),
    color: PropTypes.string,
};

export default Separator;
