import React, { useEffect, useState, useContext, useRef } from "react";
import { ThemeContext, Box, Text, CheckBox, defaultProps, Form, ResponsiveContext, Tip, Image, RadioButton } from "grommet";
import { CreditCard, IconProps, CircleQuestion } from 'grommet-icons';
import styled from "styled-components";
import AdyenCheckout from '@adyen/adyen-web';
import {
    CbObjOnError as AdyenErrorState,
    CbObjOnConfigSuccess as AdyenConfigState,
    CbObjOnFocus as AdyenFocusState,
    CbObjOnFieldValid as AdyenFieldValidityState,
} from '@adyen/adyen-web/dist/types/components/internal/SecuredFields/lib/types';
import { CustomTheme } from '../../theme';
import { AdyenComponentData } from '../../services/payments/adyenHelper/types';
import ValidatedInput from '../ValidatedInput/ValidatedInput';
import { maxLength30Rule } from '../../lib/validate/validate';
import { getIsOnGuestJourney } from '../../modules/customer';
import { getLocalisation } from '../../modules/config';
import { translate } from '../../modules/translation';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/reducers";
import {
    getNewCard, isUnionPayEnabled, updateNewCard,
    updatePaymentMethodsData,
} from "../../modules/billing";
import { AppThunkDispatch } from "../../modules/thunk";

const StyledInputContainer = styled.div<AdyenCardPaymentInputContainerProps>`
    height: ${props => props.height};
    width: 100%;
    display: block;
    position: relative;
    margin: 8px 0;
    border: ${(props) => (props.status === 'error' ? "1px solid "+props.errorBorderColor :
        props.status === 'success' ? "1px solid "+props.successBorderColor : 
        "1px solid "+props.defaultBorderColor)};
    ${(props) => (props.focus ? "border: 1px solid "+props.focusBorderColor+";" : "")};
`;

const iconSize: string = defaultProps.theme?.icon?.size?.medium ?? '30px';
const boxPadSize: string = defaultProps.theme?.global?.edgeSize?.small ?? '16px';

const StyledCreditCardIcon = styled(CreditCard)<any>`${(props: AdyenCardPaymentIconProps) => props.iconstyles || ''}`;

/**
 * AdyenCardPaymentForm displays the inputs required for the user to enter their new card details.
 */
function AdyenCardPaymentForm(props: AdyenCardPaymentProps) {
    const theme = useContext(ThemeContext) as CustomTheme;
    const customer = useSelector((state: RootState) => state.customer);
    const billing = useSelector((state: RootState) => state.billing);
    const translation = useSelector((state: RootState) => state.translation);
    const config = useSelector((state: RootState) => state.config);
    const isOnGuestJourney = getIsOnGuestJourney(customer);
    const dispatch: AppThunkDispatch = useDispatch();
    const showCobrands = config.show_cobrands;

    type ThemeValue = {
        horizontal?: string,
        vertical?: string,
        light?: string,
    };
    const fontSize = theme?.text?.medium?.size ?? '14px';
    const inputPaddingHorizontal = (theme?.global?.input?.padding as ThemeValue)?.horizontal ?? '8px';
    const inputPaddingVertical = (theme?.global?.input?.padding as ThemeValue)?.vertical ?? '16px';
    const backgroundErrorColor = (theme?.global?.colors?.['background-error'] as ThemeValue)?.light ?? 'rgba(255,0,0,0.08)';
    const defaultTextColor = (theme?.global?.colors?.text as ThemeValue)?.light ?? '#444444';
    const placeholderTextColor = (theme?.global?.colors?.placeholder as string) ?? '#AAAAAA';
    const defaultBorderColor = (theme?.global?.colors?.border as ThemeValue)?.light ?? '#e7e8e9';
    const errorBorderColor = (theme?.global?.colors?.red as ThemeValue)?.light ?? '#FF0000';
    const focusBorderColor = (theme?.global?.focus?.border?.color as string) ?? '#4bd4b0';
    const successBorderColor = (theme?.global?.colors?.green as ThemeValue)?.light ?? defaultBorderColor;
    const cvvFieldWidth = (theme?.config?.cvvFieldWidth as string) ?? '130px';
    const successTickIcon = theme?.config?.icons?.successTickIcon? React.createElement(theme?.config?.icons?.successTickIcon): null;
    const errorTickIcon = theme?.config?.icons?.errorTickIcon? React.createElement(theme?.config?.icons?.errorTickIcon): null;
    const size = React.useContext(ResponsiveContext);
    const isDesktop = size !== 'small';

    const adyenCheckoutStyles = {
        base: {
            fontSize: fontSize,
            padding: `${inputPaddingVertical} ${inputPaddingHorizontal}`
        },
        error: {
            color: defaultTextColor,
            background: backgroundErrorColor
        },
        validated: {
            color: defaultTextColor
        },
        placeholder: {
            color: placeholderTextColor
        },
    };

    const strings = {
        cardNumber: translate(translation, 'cardNumber', 'Card Number'),
        expiryDate: translate(translation, 'expiryDate', 'Expiry Date'),
        saveCardDetails: translate(translation, 'saveCardDetails', 'Save card details for future use'),
        nameOnCard: translate(translation, 'nameOnCard', 'Name on Card'),
        CVVInfo: translate(translation, 'cvvInfo', 'CVV is an additional three or four digit security code that is printed (not embossed) on the front or the back of your card.'),

    };

    const adyenCheckoutAriaLabels = {
        lang: getLocalisation(config).language.code,
        encryptedCardNumber: {
            label: translate(translation, 'creditDebitCardNumber', 'Credit or debit card number'),
            iframeTitle: "Iframe for card data input field"
        },
        encryptedExpiryDate: {
            label: translate(translation, 'creditDebitCardExp', 'Credit or debit card expiration date'),
            iframeTitle: "Iframe for card data input field"
        },
        encryptedSecurityCode: {
            label: translate(translation, 'creditDebitCardSecurity', 'Credit or debit card security code'),
        },
    }
    
    const brandImageStyles = {
        width: '30px',
        height: '20px'
    };

    const iconStyles = {
        position: 'absolute' as 'absolute',
        top: '56%',
        transform: 'translateY(-50%)',
        left: `calc(100% - (${inputPaddingHorizontal} + ${iconSize}))`,
    };

    const infoIconStyles = {
        height: 'auto',
        buttom: '1px',
        display: 'inline-block',
        position: 'relative' as 'relative',
        top: '3px',
        cursor: 'pointer',
    };

    const initialStatuses = {
        encryptedCardNumber: 'default',
        encryptedExpiryDate: 'default',
        encryptedSecurityCode: 'default'
    };
    const initialErrorMessages = {
        encryptedCardNumber: '',
        encryptedExpiryDate: '',
        encryptedSecurityCode: ''
    };
    const initialFocus = {
        encryptedCardNumber: false,
        encryptedExpiryDate: false,
        encryptedSecurityCode: false
    };
    
    const initialBinLookUp = {
        brands: [],
        detectedBrands: [],
        supportedBrands: [],
        supportedBrandsRaw: [],
        type: '',
        
    };

    const translations = {
        'nl-NL': {
            'creditCard.expiryDateField.placeholder': 'MM/YY'
        },
        'da-DK': {
            'creditCard.expiryDateField.placeholder': 'MM/YY'
        },
        'es-ES': {
            'creditCard.expiryDateField.placeholder': 'MM/YY'
        },
        'th-TH': {
            'error.va.gen.01': 'ข้อมูลไม่ถูกต้อง',
            'error.va.gen.02': 'ข้อมูลไม่ถูกต้อง',
            'error.va.sf-cc-num.01': 'หมายเลขบัตรไม่ถูกต้อง',
            'error.va.sf-cc-num.02': 'หมายเลขบัตรที่ระบุไม่ตรงกับบัตร',
            'error.va.sf-cc-num.03': 'ไม่รองรับบัตรที่ระบุ',
            'error.va.sf-cc-dat.01': 'บัตรหมดอายุ',
            'error.va.sf-cc-dat.02': 'วันที่ระบุนั้นนานเกินไป'
        }
    };

    const [status, setStatus] = useState(initialStatuses);
    const [errorMessage, setErrorMessage] = useState(initialErrorMessages);
    const [focus, setFocus] = useState(initialFocus);
    const [binLookUp, setBinLookUp] = useState(initialBinLookUp);
    const [brandSelected, setBrandSelected] = useState('');

    const isCardSaved = useRef(false);
    const isFormValid = useRef(false);
    const holderName = useRef('');
    const adyenComponentData = useRef<AdyenComponentData|null>(null);

    /**
     * Inserts the holder name into the pre-saved adyen data to avoid it being overwritten.
     */
    function addHolderNameToAdyenData (adyenData: AdyenComponentData | null, name: string): AdyenComponentData | null {
        if (adyenData) {
            const adyenPaymentMethod = {
                ...adyenData.paymentMethod,
                holderName: name,
            };
            return {
                ...adyenData,
                paymentMethod: adyenPaymentMethod,
            };
        }
        else {
            return null;
        }
    }

    function handleAdyenOnChange(state: AdyenChangeState, component: AdyenCardPaymentComponent) {
        const updatedAdyenData = addHolderNameToAdyenData(state.data, holderName.current);
        adyenComponentData.current = updatedAdyenData;
        isFormValid.current = state.isValid;
        if (updatedAdyenData) {
            dispatch(updatePaymentMethodsData('adyenCard', {
                adyenData: updatedAdyenData,
                isValid: state.isValid,
                shouldSaveCard: isCardSaved.current,
            }));
        }
    }

    function handleAdyenOnValid(state:AdyenChangeState, component:AdyenCardPaymentComponent) {
        // Executed when the form switches between valid and invalid.
    }

    function handleAdyenConfigSuccess(state: AdyenConfigState) {
        // Executed when Adyen has loaded successfully
    }

    function handleAdyenFieldValid(state: AdyenFieldValidityState) {
        if (state.valid) {
            setStatus((prevStatus) => ({...prevStatus, [state.fieldType]: 'success'}));
            setErrorMessage((prevErrorMessage) => ({...prevErrorMessage, [state.fieldType]: ''}));
            if(state.encryptedFieldName === 'encryptedCardNumber'){
                dispatch(updateNewCard( {
                    ...getNewCard(billing),
                    lastFour: state.endDigits ? state.endDigits : '',
                }))
            }
        } else {
            setStatus((prevStatus) => ({...prevStatus, [state.fieldType]: 'default'}));
            setErrorMessage((prevErrorMessage) => ({...prevErrorMessage, [state.fieldType]: ''}));
        }
    }

    function handleAdyenError(state: AdyenErrorState) {
        setStatus((prevStatus) => ({...prevStatus, [state.fieldType]: 'error'}));
        setErrorMessage((prevErrorMessage) => ({...prevErrorMessage, [state.fieldType]: state.errorI18n}));
    }

    function handleAdyenFocus(state: AdyenFocusState) {
        if (state.focus) {
            setFocus({...initialFocus, [state.fieldType]: true});
        } else {
            setFocus({...initialFocus, [state.fieldType]: false});
        }
    }
    
    function handleAdyenOnBinLookup(state: AdyenBinLookupState) {
        setBinLookUp(
            {
                brands: state.brands || [],
                detectedBrands: state.detectedBrands || [],
                supportedBrands: state.supportedBrands || [],
                supportedBrandsRaw: state.supportedBrandsRaw || [],
                type: state.type || ''
            }
        );
    }

    useEffect(() => {
        // Clear in-memory adyen data upon first loading the form.
        dispatch(updatePaymentMethodsData('adyenCard', {
            adyenData: {},
            isValid: false,
            shouldSaveCard: false,
        }));

        const configuration = {
            translations: translations,
            ...props.config.adyenCardCheckoutConfig,
            onChange: handleAdyenOnChange
        }

        const options = {
            type: 'card',
            brands: ['mc', 'visa', 'amex', 'bcmc', 'maestro', 'cartebancaire'],
            styles: adyenCheckoutStyles,
            ariaLabels: adyenCheckoutAriaLabels,
            onValid: handleAdyenOnValid,
            onConfigSuccess: handleAdyenConfigSuccess,
            onFieldValid: handleAdyenFieldValid,
            onError: handleAdyenError,
            onFocus: handleAdyenFocus,
            onBinLookup: handleAdyenOnBinLookup
        };
        // If UnionPay payment method is active then allow UnionPay cards
        if (isUnionPayEnabled(billing)) {
            options.brands.push('cup');
        }
        ( async() => {
            const checkout = await AdyenCheckout(configuration);
            window.securedfields = checkout.create('securedfields', options).mount('#customCard-container');
        })();
    // eslint-disable-next-line
    }, []);
    
    const renderPaymentField = (fieldType: 'encryptedCardNumber' | 'encryptedExpiryDate' | 'encryptedSecurityCode', title: string, short = false, icon?: React.ReactNode) => {
        return (
            <Box margin={{"bottom": "medium"}} data-testid={`${fieldType}-container`}>
                <label>
                    <Text size="medium" weight={theme?.config?.formFieldLabelWeight ?? 500}>
                        {title}
                    </Text>
                    { title === "CVV" &&
                        <Tip
                            content={
                                <Box width={{ max: isDesktop? '400px' : 'large' }} >
                                    {strings.CVVInfo}
                                </Box>
                            }
                            dropProps={{ align:{left: 'right'} }}
                        >
                            <Box style={infoIconStyles}>
                                <CircleQuestion size="small" />
                            </Box>
                        </Tip>
                    }
                    <Box
                        direction="row"
                        style={{'position': 'relative'}}
                        width={short ? cvvFieldWidth : undefined}
                        pad={icon !== undefined ? {'right':`calc(${iconSize} + ${boxPadSize} + ${inputPaddingHorizontal})`} : {}}
                    >
                        <StyledInputContainer 
                            status={status[fieldType]}
                            focus={focus[fieldType]}
                            height={theme?.config?.adyenFormFieldHeight ?? '48px'}
                            inputPaddingHorizontal={inputPaddingHorizontal}
                            inputPaddingVertical={inputPaddingVertical}
                            defaultBorderColor={defaultBorderColor}
                            errorBorderColor={errorBorderColor}
                            focusBorderColor={focusBorderColor}
                            successBorderColor={successBorderColor}
                        >
                            <span data-cse={fieldType}></span>
                            {status[fieldType] === 'success' ?
                                <Box style={iconStyles} >{successTickIcon}</Box> :
                            status[fieldType] === 'error' ?
                                <Box style={iconStyles} >{errorTickIcon}</Box>: null}
                        </StyledInputContainer>
                        {icon !== undefined ? icon : ''}
                    </Box>
                    {
                        (fieldType === 'encryptedCardNumber' && showCobrands) ?
                            <Box direction="row" wrap={true}>
                                { binLookUp.supportedBrandsRaw.map((eachBinLookUp: AdyenSupportedBrandsRaw) => {
                                    return (
                                    <Box
                                        direction="row" align="center" pad='1%' width={isDesktop ? '40%' : '100%'} height='xxsmall' border={true}
                                    >
                                        { binLookUp.supportedBrandsRaw.length > 1 &&
                                        <RadioButton
                                            name='radio'
                                            checked={eachBinLookUp.brand === brandSelected}
                                            alt={eachBinLookUp.brand}
                                            data-value={eachBinLookUp.brand}
                                            value={eachBinLookUp.brand}
                                            onChange={(event) => {
                                                setBrandSelected(event.target.value);
                                                window.securedfields.dualBrandingChangeHandler(event.target.value);
                                            }
                                            }
                                        /> }
                                        <Image margin={ {left: 'xxsmall', right: 'xxsmall'}} style={brandImageStyles} src={eachBinLookUp.brandImageUrl}/>
                                        <Text size="xsmall" weight={500}>{eachBinLookUp.localeBrand}</Text>
                                    </Box>
                                    )
                                }) }
                            </Box> : ''
                    }
                    {errorMessage[fieldType] &&
                        <Text size="medium" color="red" margin={{"top": "xsmall"}}>{errorMessage[fieldType]}</Text>
                    }
                </label>
            </Box>
        );
    };

    return (
        <Form>
            <div id="customCard-container">
                <ValidatedInput
                    rules={[{ name: 'required', fieldName: strings.nameOnCard }, maxLength30Rule(strings.nameOnCard)]}
                    name="name"
                    label={strings.nameOnCard}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        holderName.current = event.currentTarget.value;
                        if (adyenComponentData.current) {
                            dispatch(updatePaymentMethodsData('adyenCard', {
                                adyenData: addHolderNameToAdyenData(adyenComponentData.current, event.currentTarget.value),
                                isValid: isFormValid.current,
                                shouldSaveCard: isCardSaved.current,
                            }));
                        }
                    }}
                />
                {renderPaymentField('encryptedCardNumber', strings.cardNumber)}
                {renderPaymentField('encryptedExpiryDate', strings.expiryDate)}
                {renderPaymentField('encryptedSecurityCode', 'CVV', true, <StyledCreditCardIcon iconstyles={iconStyles} data-testid="adyenCreditCardIcon" />)}
                { !isOnGuestJourney &&
                    <CheckBox
                        name="saveDetailsOption"
                        id="AdyenCardPayentForm-saveDetailsOption"
                        label={<Text size="small">{strings.saveCardDetails}</Text>}
                        checked={isCardSaved.current}
                        onChange={(event) => {
                            isCardSaved.current = event.target.checked;
                            if (adyenComponentData.current) {
                                dispatch(updatePaymentMethodsData('adyenCard', {
                                    adyenData: adyenComponentData.current,
                                    isValid: isFormValid.current,
                                    shouldSaveCard: event.target.checked,
                                }));
                            }
                        }}
                    />
                }
            </div>
        </Form>
    );
}

type AdyenCardPaymentProps = {
    config: {
        adyenCardCheckoutConfig: {
            locale: string,
            environment: string,
            clientKey: string,
        }
    },
};

export type AdyenCardPaymentComponent = {
    state: {
        data: {
            encryptedCardNumber: string,
            encryptedExpiryMonth: string,
            encryptedExpiryYear: string,
            encryptedSecurityCode: string
        },
        [x: string]: any,
    },
    [x: string]: any,
};

export type AdyenCardPaymentInputContainerProps = {
    status: string,
    focus?: boolean,
    height: string,
    inputPaddingHorizontal: string,
    inputPaddingVertical: string,
    defaultBorderColor: string,
    errorBorderColor: string,
    focusBorderColor: string,
    successBorderColor: string
};


export type AdyenChangeState = {
    data: AdyenComponentData,
    isValid: boolean,
};
type AdyenBinLookupState  = {
    type: string,
    detectedBrands: [],
    supportedBrands: [],
    brands: [],
    supportedBrandsRaw: [],
}

type AdyenSupportedBrandsRaw = {
    brand: string,
    brandImageUrl: string,
    cvcPolicy: string,
    enableLuhnCheck: boolean,
    expiryDatePolicy: string,
    localeBrand: string,
    paymentMethodVariant: string,
    showSocialSecurityNumber: boolean,
    supported: boolean
    
}

export interface AdyenCardPaymentIconProps extends IconProps {
    iconstyles: string;
};

export default AdyenCardPaymentForm;
