import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Text, Image, Anchor, ResponsiveContext } from 'grommet';
import { RootState } from '../../store/reducers';
import { getHasDropshipProducts, getFulfilmentGroups, getProducts } from '../../modules/cart';
import { getGiftCardSkuDetails } from '../../modules/config';
import ProductDetails from '../ProductDetails/ProductDetails';
import Separator from '../Separator/Separator';
import { AppThunkDispatch } from '../../modules/thunk';
import { pages } from "../../services";
import { updateDialog } from '../../modules/notification';
import { translate } from '../../modules/translation';
import Currency from '../Currency/Currency';
import { Product } from '../../modules/cart/types';

function ProductsSummary() {
    const cart = useSelector((state: RootState) => state.cart);
    const translation = useSelector((state: RootState) => state.translation);
    const hasDropshipProducts = getHasDropshipProducts(cart);
    const fulfilmentGroups = getFulfilmentGroups(cart);
    const config = useSelector((state: RootState) => state.config);
    const products = getProducts(cart);
    const dispatch: AppThunkDispatch = useDispatch();
    const giftcardSku = getGiftCardSkuDetails(config);
    const size = React.useContext(ResponsiveContext);
    const isDesktop = size !== 'small';

    const learnMoreHandler = async (pageId?: string) => {
        if (pageId) {
            const pageResponse = await pages().getPages(pageId);
            dispatch(updateDialog('anatwine_learn_more', pageResponse.data));
        }
    };

    const strings = {
        promotionalTotal: translate(translation, 'promotionalTotal', 'Promotional total'),
        learnMore: translate(translation, 'learnMore', 'Learn More'),
    }
    
    function productDisplay(product: Product)
    {
        switch (product.type) {
            case 'CartProduct':
                return <ProductDetails
                        product={product}
                        />
            case 'PromotionGroup': 
                return <Box flex={false} >
                    <Text weight="bold" size="medium" 
                          margin={!isDesktop 
                            ? {left: "xsmall", top: "xsmall","bottom": "xsmall"} 
                            : {top: "small","bottom": "small"}}
                    >
                        {product.name}
                    </Text>
                    { product?.products?.map((product, productIndex) => (
                        product.type === 'CartProduct' &&
                            <Box flex={false} margin={{bottom: "large"}} >
                                <ProductDetails
                                    product={product}
                                />
                            </Box>
                        ))
                    }
                    <Box direction="row" justify="between" margin={{top: 'medium'}} >
                        <Text size={"small"} margin={!isDesktop ? {left: "xsmall"} : ''}>
                            {strings.promotionalTotal}: (Saving <Currency amount={`${product.saving.amount}`} currency={product.saving.currency}  textFormat={{size: 'small'}} />)
                        </Text>
                        <Text size="small" weight="bold">
                            <Currency amount={`${product.subtotal.amount}`} currency={product.subtotal.currency} />
                        </Text>
                    </Box>
                </Box> 
        }    
    }

    return(
        <Box fill="horizontal">
        {
            hasDropshipProducts
                ?
                    // Products grouped by fulfilment groups, with a fulfilment message + logo on top of each group
                    fulfilmentGroups.map((group, groupIndex) => (
                        <Box key={groupIndex} flex={false}>
                            { group.products.map((product, productIndex) => ( 
                                <Box key={productIndex} flex={false}>
                                    <Box direction="row" align="end" margin={{bottom: 'small'}}>
                                        { product.trackingSKU === giftcardSku.eGiftcardSku ?
                                            <Text size="small" weight="bold" margin={{left: 'xsmall'}}>{group.fulfilmentMessage}  {product?.cartProductNotification?.deliveryType}</Text>
                                        :  
                                            group.fulfilmentMessageImage && 
                                            <>
                                                <Text size="small" weight="bold" margin={{left: 'xsmall'}}>{group.fulfilmentMessage} </Text>
                                                <Box width="25px" height="25px" margin={{left: 'xxsmall'}}>
                                                    <Image
                                                        src={group.fulfilmentMessageImage}
                                                        fill={false}
                                                        fit="contain"
                                                        />
                                                </Box>
                                            </>                                     
                                        }   
                                        {group.informationPage &&
                                            <Anchor
                                                size="small"
                                                onClick={() => {
                                                    learnMoreHandler(group.informationPage)
                                                }}
                                                margin={{left: 'xxsmall'}}>{strings.learnMore}
                                            </Anchor>
                                        }
                                    </Box>
                                        { productDisplay(product) }
                                        {
                                            // Add a separator except for the last item of the last group
                                            !(productIndex === group.products.length-1 && groupIndex === fulfilmentGroups.length-1)
                                            && <Separator margin={{ vertical: 'small' }} />
                                        }
                                </Box>
                                ))
                            }
                        </Box>
                    ))
                :
                    // Simple product list with no fulfilment details
                    products.map((product, index) => (
                        <Box key={index} flex={false}>
                            { productDisplay(product) }
                            {index < products.length - 1 && <Separator margin={{ vertical: 'large' }} />}
                        </Box>
                    ))
        }
        </Box>
    );
}

export default ProductsSummary;
