import { AppThunk } from '../thunk';
import { carts, deliveryCountries, tracking } from '../../services';
import { getCart, getCartId, getHasDropshipProducts, removeExternalCartDeliveryMethod, updateExternalCartDeliveryMethod } from '../cart';
import {
    SELECT_OPTION, UPDATE_STORES, SELECT_STORE, UPDATE_SELECTED_STORE_ID, SELECT_STORE_DETAILED_VIEW,
    UPDATE_COLLECTION_DETAILS, UPDATE_METHODS, SELECT_METHOD, SELECT_METHOD_BY_DISPLAY_NAME,
    UPDATE_DELIVERY_ADDRESS, UPDATE_DELIVERY_COUNTRIES, UPDATE_DELIVERY_MODULE, UPDATE_POST_OFFICES,
    SELECT_TIME_SLOT_ON_METHOD, UPDATE_ARCHIVED_HOME_DELIVERY_POSTCODE, UPDATE_IS_GEORESTRICTION_ERROR, CLEAR_DELIVERY_METHODS,
    Delivery, DeliveryActionTypes, Store, CollectionDetails, DeliveryMethods,
    DeliveryMethod, DeliveryOption, DeliveryOptionValue,
    DeliveryCountry, StoreCoordinates, DeliveryMethodSlot,
    CandCLocation,
    UPDATE_NON_JD_STORE_ID, UPDATE_IS_NOT_YOU_COLLECTING,
} from './types';
import { Address } from '../customer/types';
import { AppResponse } from '../../services/response';
import { getIsOnExpressJourney } from '../../modules/billing';
import { getCustomerID } from '../customer';
import { getDefaultDeliveryAddress, saveExternalAddress, getDefaultBillingAddress, getCustomerPreferences } from '../customer';
import { DeliveryMethodType } from './types';
import { Coordinates } from '../../services/mesh-sdk/carts';
import clone from 'rfdc';
import { getBillingAddress, updateBillingAddress } from '../billing';
import { getApi, getDistanceUnits, isCAndCAvailable, getLocalisation, 
    getTranslatedCountryDetails, getShowTranslatedCountries } from '../config';
import { getMeshErrorAndDelete } from '../../services/mesh-sdk/client';
import { updateDialog, clearNotification } from '../notification';
import newRelicData from '../../newrelic';


export const initialState: Delivery = {
    options: [
        {
            label: 'Home Delivery',
            value: 'home',
            selected: true
        },
        {
            label: 'Click & Collect',
            value: 'clickAndCollect',
            selected: false
        },
    ],
    // Delivery methods
    methods: {
        home: [],
        clickAndCollect: []
    },
    // The full method object selected by the user
    selectedMethod: {} as DeliveryMethod,
    // List of nearby stores
    stores: [] as Store[],
    // Count of stores
    totalNumberOfStores: 0,
    totalNumberOfPostOffices: 0,
    // List of nearby Post Offices.
    postOffices: [] as Store[],
    // Store selected for C&C
    selectedStore: {} as Store,
    // Store to view on the 'Store Details' view
    storeForDetailedView: {} as Store,
    // Whether or not to display the 'Store Details' view
    showStoreDetailedView: false,
    // Delivery postcode saved when we switch options from home delivery to C&C.
    archivedHomeDeliveryPostcode: '',
    // Customer details to use for C&C (name, phone)
    collectionDetails: {} as CollectionDetails,
    address: {} as Address,
    countries: [] as DeliveryCountry[],
    isGeorestrictionError: false,
    isNotYouCollecting: false,
};

// Reducer
export default function reducer(state = initialState, action: DeliveryActionTypes) {
    switch (action.type) {
        case UPDATE_DELIVERY_MODULE: {
            return {...action.payload};
        }

        case SELECT_OPTION: {
            const newOptions = state.options.map(option => {
                return {...option, selected: option.value === action.payload};
            });
            return {...state, options: newOptions};
        }

        case CLEAR_DELIVERY_METHODS: {
            return {...state, methods: {home:[], clickAndCollect:[]}};
        }

        case UPDATE_METHODS: {
            const shouldPreselect = action.payload.preselect;
            const preferredMethod = action.payload.customerPreferredMethodId;
            const allMethods = [
                ...action.payload.methods.home,
                ...action.payload.methods.clickAndCollect,
            ];

            // If the customer has a preferred method, preselect this one
            if (shouldPreselect && preferredMethod) {
                const selectedCustomerMethod = allMethods.find(method => method.ID === preferredMethod);
                if (selectedCustomerMethod) {
                    return {
                        ...state,
                        methods: putSelectedMethodOnTop(action.payload.methods, preferredMethod),
                        selectedMethod: selectedCustomerMethod,
                    };
                }
            }

            // If no customer method found, preselect the one platform marked as 'selected'
            const platformSelectedMethod = allMethods.find(method => method.selected);
            if (shouldPreselect && platformSelectedMethod) {
                const selectedOption = getSelectedDeliveryOption(state).value;
                if(selectedOption === platformSelectedMethod.typeCategory){
                    return {
                        ...state,
                        methods: action.payload.methods,
                        selectedMethod: platformSelectedMethod,
                    };
                }
            }

            // Preselection unsuccessful
            return {...state, methods: action.payload.methods};
        }

        case UPDATE_STORES: {
            if (action.payload.append) {
                return {
                    ...state,
                    stores: [
                        ...state.stores,
                        ...action.payload.stores,
                    ],
                };
            }
            if(action.payload.totalNumberOfStores) {
                return {
                    ...state,
                    stores: action.payload.stores,
                    totalNumberOfStores: action.payload.totalNumberOfStores,
                };
            }
            return {...state, stores: action.payload.stores};
        }
        
        case UPDATE_POST_OFFICES: {
            if (action.payload.append) {
                return {
                    ...state,
                    postOffices: [
                        ...state.postOffices,
                        ...action.payload.postOffices
                    ]
                };
            }
            return {...state, postOffices: action.payload.postOffices};
        }

        case SELECT_STORE: {
            // Empty payload: the component wants to clear the selection.
            if (action.payload === '') return {...state, selectedStore: {} as Store};

            const selection = getStoreByStoreId(state, action.payload);

            if (selection) return {...state, selectedStore: selection};
            return state;
        }

        case UPDATE_SELECTED_STORE_ID: {
            if (action.payload) {
                //Get the selected store from the state, and overwrite its ID with the payload
                const updatedStoreObject = {...state.selectedStore, ID: action.payload};
                //Set the selectedStore to the Store object containing the updated ID
                return {...state, selectedStore: updatedStoreObject};
            }

            console.error(`${UPDATE_SELECTED_STORE_ID} No store found with ID ${action.payload}, state unchanged`);
            return state;
        }

        case UPDATE_NON_JD_STORE_ID: {
            if (action.payload) {
                //Clone the Post offices so that we can easily update the ID without working directly on the state.
                const postOffices = clone()(state.postOffices)

                //find store - this will need updating as we add in more store types
                const matchIdx = state.postOffices.findIndex(postoffice => postoffice.ID === action.payload.storeId)

                if (matchIdx === -1) {
                    console.error(`${UPDATE_NON_JD_STORE_ID} No store found with ID ${action.payload.storeId}, state unchanged`);
                    return state;
                }

                postOffices[matchIdx].ID = action.payload.newId;

                //Set this to true to prevent further lookups/saving of the store address to platform.
                postOffices[matchIdx].isIdUpdated = true;

                return {...state, postOffices: postOffices};
            }

            return state
        }
        
        case SELECT_STORE_DETAILED_VIEW: {
            // Empty payload: the component wants to clear the selection.
            if (action.payload === '') {
                return {
                    ...state,
                    storeForDetailedView: {} as Store,
                    showStoreDetailedView: false
                };
            }
        
            const storeForDetailedView = getStoreByStoreId(state, action.payload);
            if (storeForDetailedView) {
                return {
                    ...state,
                    storeForDetailedView: storeForDetailedView,
                    showStoreDetailedView: true
                }
            };

            console.error(`${SELECT_STORE_DETAILED_VIEW} No store found with ID ${action.payload}, state unchanged`);
            return state;
        }

        case SELECT_METHOD: {
            // Empty payload: the component wants to clear the selection.
            if (action.payload === '') return {...state, selectedMethod: {} as DeliveryMethod};

            const selectedMethod = getDeliveryMethodByMethodId(state, action.payload);
            if (selectedMethod) return {...state, selectedMethod: selectedMethod};
            console.error(`${SELECT_METHOD} No delivery method found with ID ${action.payload}, state unchanged`);
            return state;
        }

        case SELECT_METHOD_BY_DISPLAY_NAME: {
            // Empty payload: the component wants to clear the selection.
            if (action.payload === '') return {...state, selectedMethod: {} as DeliveryMethod};

            const method = getDeliveryMethodByDisplayName(state, action.payload);
            if (method) return {...state, selectedMethod: method};
            console.error(`${SELECT_METHOD_BY_DISPLAY_NAME} No delivery method found with displayName ${action.payload}, state unchanged`);
            return state;
        }

        case SELECT_TIME_SLOT_ON_METHOD: {
            const { methodId, day, start, end } = action.payload;
            
            const method = getDeliveryMethodByMethodId(state, methodId);
            if (!method?.slots) return state;

            const updatedSlots = method.slots.map(slot => {
                const isSelected = slot.date === day && slot.start === start && slot.end === end;
                
                return {
                    ...slot,
                    selected: isSelected,
                } as DeliveryMethodSlot;
            });
            
            const option = state.options.find(option => option.selected === true)?.value as DeliveryOptionValue;
            const updatedMethods = state.methods[option].map(method => {
                if (method.ID === methodId) {
                    return {
                        ...method,
                        slots: updatedSlots,
                    }
                }
                else return method;
            });

            const selectedMethod = updatedMethods.find(updatedMethod => updatedMethod.ID === state.selectedMethod.ID);
            
            return {
                ...state,
                methods: {
                    ...state.methods,
                    [option]: updatedMethods,
                },
                // If the method being edited is the one selected, the updated slots are also added to `selectedMethod`
                ...(selectedMethod && {selectedMethod: selectedMethod}), // the `selectedMethod` key won't be added if the selectedMethod var is falsy
            };
        }

        case UPDATE_ARCHIVED_HOME_DELIVERY_POSTCODE: {
            return {...state, archivedHomeDeliveryPostcode: action.payload};
        }

        case UPDATE_COLLECTION_DETAILS: {
            return {...state, collectionDetails: action.payload};
        }

        case UPDATE_DELIVERY_ADDRESS: {
            return {...state, address: action.payload};
        }
        
        case UPDATE_DELIVERY_COUNTRIES: {
            return {...state, countries: action.payload};
        }

        case UPDATE_IS_GEORESTRICTION_ERROR: {
            return {...state, isGeorestrictionError: action.payload};
        }

        case UPDATE_IS_NOT_YOU_COLLECTING: {
            return {...state, isNotYouCollecting: action.payload};
        }

        default: {
            return state;
        }
    }
}

// Selectors
export const getDeliveryOptions = (state: Delivery) => state.options;
export const getSelectedDeliveryOption = (state: Delivery) => {
    return state.options.find(option => option.selected) as DeliveryOption;
};
export const getSelectedMethod = (state: Delivery) => state.selectedMethod;
export const getStores = (state: Delivery) => state.stores;
export const getStoresCount = (state: Delivery) => state.totalNumberOfStores;
export const getPostOffices = (state: Delivery) => state.postOffices;
export const getPostOfficesCount = (state: Delivery) => state.totalNumberOfPostOffices;
export const getClickAndCollectLocations = (state: Delivery) => {
    const allLocations = state.stores.concat(state.postOffices);
    return allLocations.sort((a, b) => parseFloat(a.distance) - parseFloat(b.distance));
};
export const getStoreByStoreId = (state: Delivery, id: string) => {
    //TODO: Potential refactor here - O(n) lookup since we'd have to iterate through every store and postoffice
    //Also, looking up a postoffice will always be slower here because postoffices come second in the null coalesce.
    const store = state.stores.find(store => store.ID === id) ?? state.postOffices.find(postoffice => postoffice.ID === id);
    return store as Store;
};
export const getStoreCoordinatesByStoreId = (state: Delivery, storeId: string) => {
    const { latitude, longitude } = getStoreByStoreId(state, storeId);
    return { latitude, longitude };
};
export const getSelectedStore = (state: Delivery) => state.selectedStore;
export const getStoreForDetailedView = (state: Delivery) => state.storeForDetailedView;
export const getShowStoreDetailedView = (state: Delivery) => state.showStoreDetailedView;
/** Holds the delivery address postcode before the delivery address itself is emptied from the state (happens when swiching from home delivery to C&C). */
export const getArchivedHomeDeliveryPostcode = (state: Delivery) => state.archivedHomeDeliveryPostcode;
export const getCollectionDetails = (state: Delivery) => state.collectionDetails;
export const getDeliveryAddress = (state: Delivery) => state.address;
export const getDeliveryCountries = (state: Delivery) => state.countries;
export const getIsGeorestrictionError = (state: Delivery) => state.isGeorestrictionError;
export const getIsNotYouCollecting = (state: Delivery) => state.isNotYouCollecting;
export const getMethods = (state: Delivery) => {
    const selectedOption = state.options.find(item => item.selected === true) as DeliveryOption;
    if (selectedOption) {
        return state.methods[selectedOption.value];
    }
    return [] as DeliveryMethod[];
};
export const isPostOfficeDeliveryAvailable = (state: Delivery, type: DeliveryMethodType) => {
    const methods = getMethods(state);
    return methods.some(method => method.type === type);
}
export const getDeliveryMethodsOfType = (state: Delivery, type: DeliveryMethodType) => {
    const methods = getMethods(state);
    return methods.filter(method => method.type === type);
};
export const getDeliveryMethodByMethodId = (state: Delivery, methodId: string) => {
    const deliveryOption = getSelectedDeliveryOption(state).value;
    return state.methods[deliveryOption].find(method => method.ID === methodId);
}
export const getDeliveryMethodByDisplayName = (state: Delivery, displayName: string) => {
    const deliveryOption = getSelectedDeliveryOption(state).value;
    return state.methods[deliveryOption].find(method => method.displayName === displayName);
}
/** Checks the delivery location (home, post office C&C or JD store C&C.) and returns the relevant delivery methods */
export const getDeliveryMethodsForSelectedLocation = (state: Delivery) => {
    const getselectedOption = getSelectedDeliveryOption(state).value;
    const isClickAndCollect = getselectedOption === 'clickAndCollect';
    if (isClickAndCollect ) {
        const selectedStore = getSelectedStore(state);
        const locationType = selectedStore.locationType;
        const clickAndCollectMethods = state.methods.clickAndCollect;

        const filteredCandCMethods = clickAndCollectMethods.filter(method => {
            if (locationType === CandCLocation.JD && method.type === 'C+C')
                return true;
            else if (locationType === CandCLocation.PostOffice && method.type === 'POLC')
                return true;
            else
                return false;
        });
        return filteredCandCMethods;
    }
    else { // Home delivery
        const homeMethods = state.methods.home;
        
        const filteredHomeMethods = homeMethods.filter(method => {
            return !['C+C', 'POLC'].includes(method.type);
        });
        return filteredHomeMethods;
    }
}

/**
 * @param state
 * @param methodId The ID of the method from which to get the days
 * @returns string[] of slot days for this method, eg. ['2021/05/01', '2021/05/02']
 */
export const getDeliverySlotDaysByMethodId = (state: Delivery, methodId: string) => {
    const method = getDeliveryMethodByMethodId(state, methodId);
    if (!method || !method.slots) return;

    const distinctDays: string[] = [];
    method.slots.forEach(slot => {
        if (!distinctDays.includes(slot.date)) {
            distinctDays.push(slot.date);
        }
    })
    return distinctDays;
}

/**
 * Get a specific slot for a delivery method
 * @param state
 * @param methodId The ID of the method from which to get the slot
 * @param query Param to tell which kind of slot we need, either:
 *              The green slot for a specific day
 *              The selected slot
 * @returns Slot object (or undefined if not found)
 */
export const getDeliverySlotByMethodId = (state: Delivery, methodId: string, query: {type: 'green'|'selected', day?: string}) => {
    const method = getDeliveryMethodByMethodId(state, methodId);
    if (!method || !method.slots) return;

    if (query.type === 'green' && query.day) {
        return method.slots.find(slot => slot.date === query.day && slot.greenSlot);
    }
    else if (query.type === 'selected') {
        return method.slots.find(slot => slot.selected);
    }
}


/**
 * Get the time slots of a delivery method, optionally filtered for a specific day
 * @param state
 * @param methodId The ID of the method from which to get the slots (whether home or C&C is inferred from the option selected in Redux)
 * @param day The day at which we want the time slots. If undefined, all slots for all days will be returned.
 * @returns The time slots for this method.
 */
export const getDeliverySlotsByMethodId = (state: Delivery, methodId: string, day?: string) => {
    const method = getDeliveryMethodByMethodId(state, methodId);
    if (!method || !method.slots) return;
    if (day) {
        return method.slots.filter(slot => slot.date === day);
    }
    return method.slots;
}


// Action Creators
export function updateDeliveryModule(deliveryState: Delivery): DeliveryActionTypes {
    return {
        type: UPDATE_DELIVERY_MODULE,
        payload: deliveryState,
    };
}

export function selectOption(selectedOption: string): DeliveryActionTypes {
    return {
        type: SELECT_OPTION,
        payload: selectedOption
    };
};
export function clearDeliveryMethods(){
    return {
        type: CLEAR_DELIVERY_METHODS,
    };
};

export function updateStores(stores: Store[], append = false, totalNumberOfStores?: number): DeliveryActionTypes {
    return {
        type: UPDATE_STORES,
        payload: {
            stores,
            append,
            totalNumberOfStores,
        }
    };
};

export function updatePostOffices(postOffices: Store[], append = false, totalNumberOfPostOffices?: number): DeliveryActionTypes {
    return {
        type: UPDATE_POST_OFFICES,
        payload: {
            postOffices,
            append,
            totalNumberOfPostOffices
        }
    };
};

export function updateMethods(methods: DeliveryMethods, preselect = true, customerPreferredMethodId?: string): DeliveryActionTypes {
    return {
        type: UPDATE_METHODS,
        payload: {
            methods,
            preselect,
            customerPreferredMethodId,
        }
    };
};

export function selectMethod(methodId: string): DeliveryActionTypes {
    return {
        type: SELECT_METHOD,
        payload: methodId
    };
};

export function selectDeliveryMethodByDisplayName(displayName: string): DeliveryActionTypes {
    return {
        type: SELECT_METHOD_BY_DISPLAY_NAME,
        payload: displayName
    };
};

export function selectTimeSlotOnDeliveryMethod(methodId: string, day: string, start: string, end: string): DeliveryActionTypes {
    return {
        type: SELECT_TIME_SLOT_ON_METHOD,
        payload: {methodId, day, start, end},
    };
};

export function updateIsGeorestrictionError(isError: boolean): DeliveryActionTypes {
    return {
        type: UPDATE_IS_GEORESTRICTION_ERROR,
        payload: isError,
    };
};

export function updateIsNotYouCollecting(notYouCollectingState: boolean): DeliveryActionTypes {
    return {
        type: UPDATE_IS_NOT_YOU_COLLECTING,
        payload: notYouCollectingState,
    };
};

export function selectStore(storeId: string): DeliveryActionTypes {
    return {
        type: SELECT_STORE,
        payload: storeId
    };
};

// Update the ID on the selectedStore object - this is used on non-JD C&C options where we have to save the address in platform.
export function updateSelectedStoreId(updatedStoreId: string): DeliveryActionTypes {
    return {
        type: UPDATE_SELECTED_STORE_ID,
        payload: updatedStoreId
    };
};

// Overwrite the ID of a store - this is used when we update 
export function updateNonJdStoreId(storeId: string, newId: string) {
    return {
        type: UPDATE_NON_JD_STORE_ID,
        payload: {
            storeId,
            newId,
        }
    };
}

// Select the store to show details of on the 'Store Details' view
export function selectStoreForDetailedView(storeId: string): DeliveryActionTypes {
    return {
        type: SELECT_STORE_DETAILED_VIEW,
        payload: storeId
    };
};

// Saves the current delivery address postcode before 'deliveryAddress' is wiped.
export function updateArchivedHomeDeliveryPostcode(postcode: string): DeliveryActionTypes {
    return {
        type: UPDATE_ARCHIVED_HOME_DELIVERY_POSTCODE,
        payload: postcode,
    };
};

export function updateCollectionDetails(collectionDetails: CollectionDetails): DeliveryActionTypes {
    return {
        type: UPDATE_COLLECTION_DETAILS,
        payload: collectionDetails
    };
};

export function updateDeliveryAddress(address: Address): DeliveryActionTypes {
    return {
        type: UPDATE_DELIVERY_ADDRESS,
        payload: address
    };
};

export function updateDeliveryCountries(countries: DeliveryCountry[]): DeliveryActionTypes {
    return {
        type: UPDATE_DELIVERY_COUNTRIES,
        payload: countries
    };
};

export function saveClickAndCollectLocation(storeId: string): AppThunk<void> {
    return async (dispatch, getState) => {
        let state = getState();
        let customer = state.customer;

        if (!customer.ID) {
            return;
        }

        //Get the Store info from the current state.
        const storeInfo = getStoreByStoreId(getState().delivery, storeId);
        
        if (storeInfo) {
            //If it isn't a JD store, we'll have to store the location to platform and get an ID
            //Check isIdUpdated because we don't want to run multiple calls to save the address in platform if a user keeps selecting stores.
            if (storeInfo.locationType !== CandCLocation.JD && !storeInfo.isIdUpdated ) {
                //Build the address object to save to platform
                let addressToUpdate: Address = {
                    ID: '',
                    firstName: customer.firstName,
                    lastName: customer.lastName,
                    email: customer.email,
                    phone: customer.phone,
                    address1: storeInfo.address.address1,
                    address2: storeInfo.address.address2 ?? '',
                    address3: storeInfo.address.address3 ?? '',
                    town: storeInfo.address.town,
                    county: storeInfo.address.county ?? '',
                    postcode: storeInfo.address.postcode,
                    country: storeInfo.address.country,
                    locale: storeInfo.address.locale,
                    isDefaultDeliveryAddress: false,
                    isDefaultBillingAddress: false,
                };

                //Save the address to platform.
                const savedAddressResponse = await dispatch(saveExternalAddress(customer.ID, addressToUpdate, true));

                /*
                Update the selected store with the ID that platform returned when we saved the address - this will overrwrite 
                the ID that was already there - which in the case of a Post Office C&C location, is the name of the Post Office.
                */
                dispatch(updateSelectedStoreId(savedAddressResponse.data.ID));

                //Update list of post offices with the new ID - this is so that the selectedStore ID stays in line with the ID on the list of Post Offices.
                dispatch(updateNonJdStoreId(storeId, savedAddressResponse.data.ID));
            }
        } else {
            console.log('Click and Collect location not found.');
        }
    }
}

// Side effects
export function loadDeliveryMethods(search: {location?: string, storeId?: string, partialPostcode?: boolean}, locale: string, defaultOptions?: boolean): AppThunk<void> {
    return async (dispatch, getState) => {
        const isExpress = getIsOnExpressJourney(getState().billing);
        const preferences = getCustomerPreferences(getState().customer);
        const cartId = getCartId(getState().cart);
        const delivery = getState().delivery;
        const getselectedOption = getSelectedDeliveryOption(delivery)?.value;
        const selectedMethod = getSelectedMethod(delivery);
        const isGeorestrictionError = getIsGeorestrictionError(delivery);

        if (cartId) {
            /*if ((!search.location && !search.storeId) || (search.location && search.storeId)) {
                throw Error('loadDeliveryMethods - Either a location or a store ID must be provided');
            }*/
            let serviceParams: string | StoreCoordinates  = '';
            if(defaultOptions === true) {
                search.partialPostcode = true;
            }
            else {
                if (search.storeId) {
                    try {
                        const { latitude, longitude } = getStoreCoordinatesByStoreId(getState().delivery, search.storeId);
                        serviceParams = {latitude, longitude};                       
                    } catch (error) {
                        console.warn("Store data couldn't be parsed", error);
                    }
                }
                else if (search.location) {
                    serviceParams = search.location;
                }
            }

            // Call to service with prepared parameters
            try {
                if(!isGeorestrictionError) {
                    const deliveryMethods = await carts().getCartDeliveryMethods(cartId, serviceParams, locale, search.partialPostcode || false);
                    if (deliveryMethods?.data) {
                        let prefDeliveryMethod: string | undefined = undefined;
                        if (preferences?.deliveryMethodPreference?.value) {
                            try {
                                const preferredDeliveryOption = JSON.parse(preferences.deliveryMethodPreference.value).option;
                                if (preferredDeliveryOption === getselectedOption) {
                                    prefDeliveryMethod = JSON.parse(preferences.deliveryMethodPreference.value).id
                                }
                            } catch (error) {
                                console.warn("The delivery method preference couldn't be parsed", error);
                            }
                        }
                        dispatch(updateMethods(deliveryMethods.data, !isExpress));

                        if (!isExpress) {
                            // Select a delivery method compatible with the new address
                            const allMethods = [
                                ...deliveryMethods.data.home,
                                ...deliveryMethods.data.clickAndCollect,
                            ];
                            // Either re-select the method already selected in redux...
                            let methodToSelect = allMethods.find(method => method.ID === selectedMethod.ID);

                            if (!methodToSelect) {
                                // ...or select the customer's preferred method
                                methodToSelect = allMethods.find(method => method.ID === prefDeliveryMethod);
                            }
                            if (!methodToSelect) {
                                // ... or select the method marked as 'selected' by Mesh
                                methodToSelect = allMethods.find(method => method.selected);
                            }
                            if (methodToSelect && (getselectedOption !== 'clickAndCollect')) {
                                await dispatch(updateExternalCartDeliveryMethod(methodToSelect.ID, locale));
                            } else {
                                await dispatch(removeExternalCartDeliveryMethod());
                            }
                        }
                    }
                }
            } catch (error) {
                if (error instanceof Error && error.message === 'GEORESTRICTED_DESTINATION') {
                    throw new Error('GEORESTRICTED_DESTINATION');
                }
                console.error(error);
            }
        }
    }
};

export function loadStores(location: string | Coordinates): AppThunk<void> {
    return async (dispatch, getState) => {
        const cartId = getCartId(getState().cart);
        if (!cartId) throw Error('No cart specified');
        const units = getDistanceUnits(getState().config);

        const stores = await carts().getStoresByLocation(cartId, location, units);
        if (stores.data) {
            dispatch(updateStores(stores.data.stores, false, stores.data.totalNumberOfStores));
            dispatch(clearNotification());
        }
        else {
            console.error('Stores response is invalid');
        }
    };
};

export function loadPostOffices(location: string | Coordinates): AppThunk<void> {
    return async (dispatch, getState) => {
        const cartId = getCartId(getState().cart);
        if (!cartId) throw Error('No cart specified');
        const units = getDistanceUnits(getState().config);

        const postOffices = await carts().getPostOfficesByLocation(cartId, location, units);
        if (postOffices.data) {
            dispatch(updatePostOffices(postOffices.data));
            dispatch(clearNotification());
        }
        else {
            console.error('Post Office response is invalid');
        }
    };
}

export function loadDeliveryCountries(): AppThunk<Promise<AppResponse<DeliveryCountry[]>>> {
    return async (dispatch, getState) => {
        const countryDetails = getTranslatedCountryDetails(getState().config);
        const translatedCountries = getShowTranslatedCountries(getState().config);
        
        try {
            const countries = translatedCountries ? { status: 200, data: countryDetails } : await deliveryCountries().getDeliveryCountries();
            dispatch(updateDeliveryCountries(countries.data));
            return countries;
        } catch(e) {
            console.error(`Delivery countries response is invalid`);
            newRelicData({ actionName: 'delivery', function:  'loadDeliveryCountries', message: (e as Error).message });
            return Promise.reject();
        }   
    }
};

export function appendStores(location: string | Coordinates, fromIndex: number): AppThunk<void> {
    const NUMBER_OF_STORES_TO_ADD = 5;

    return async (dispatch, getState) => {
        const cartId = getCartId(getState().cart);
        if (!cartId) throw Error('No cart specified');
        const units = getDistanceUnits(getState().config);

        const extraStores = await carts().getStoresByLocation(cartId, location, units, fromIndex, NUMBER_OF_STORES_TO_ADD);
        if (extraStores.data) {
            dispatch(updateStores(extraStores.data.stores, true));
        }
        else {
            console.error('Stores response is invalid');
        }
    };
};

export function appendPostoffices(location: string | Coordinates, fromIndex: number): AppThunk<void> {
    const NUMBER_OF_STORES_TO_ADD = 5;

    return async (dispatch, getState) => {
        const cartId = getCartId(getState().cart);
        if (!cartId) throw Error('No cart specified');
        const units = getDistanceUnits(getState().config);

        const postOffices = await carts().getPostOfficesByLocation(cartId, location, units, fromIndex, NUMBER_OF_STORES_TO_ADD);
        if (postOffices.data) {
            dispatch(updatePostOffices(postOffices.data));
            dispatch(clearNotification());
        }
        else {
            console.error('Post Office response is invalid');
        }
    };

};

/**
 * Select a payment method in Redux. Send a tracking event for it.
 * @param methodId
 */
export function selectDeliveryMethodByIdAndTrack(methodId: string): AppThunk<void> {
    return async (dispatch, getState) => {
        dispatch(selectMethod(methodId));
        const method = getDeliveryMethodByMethodId(getState().delivery, methodId);
        const cart = getCart(getState().cart);
        if (method) tracking().trackSelectDeliveryMethod(method, cart);
    };
};

/**
 * Select a payment method in Redux. Send a tracking event for it.
 * @param displayName
 */
export function selectDeliveryMethodByDisplayNameAndTrack(displayName: string): AppThunk<void> {
    return async (dispatch, getState) => {
        dispatch(selectDeliveryMethodByDisplayName(displayName));
        const method = getDeliveryMethodByDisplayName(getState().delivery, displayName);
        const cart = getCart(getState().cart);
        if (method) tracking().trackSelectDeliveryMethod(method, cart);
    };
};


/**
 * Sets the delivery address to the customer's default delivery address if no other currently set.
 * Same for the billing address.
 */
export function setDefaultAddressesIfNoAddressSet(): AppThunk<void> {
    return async (dispatch, getState) => {
        const delivery = getState().delivery;
        const billing = getState().billing;
        const customer = getState().customer;

        const selectedDeliveryOption = getSelectedDeliveryOption(delivery).value;
        const deliveryAddress = getDeliveryAddress(delivery);
        const billingAddress = getBillingAddress(billing);
        const defaultBillingAddress = getDefaultBillingAddress(customer);

        const isHomeDelivery = selectedDeliveryOption === 'home';
        const isHomeDetailsSelected = Object.keys(deliveryAddress).length > 0;
        const isBillingAddressSelected = Object.keys(billingAddress).length > 0;

        if (isHomeDelivery && !isHomeDetailsSelected) {
            dispatch(setDeliveryAddressFromDefault());
        }
        if (!isBillingAddressSelected && defaultBillingAddress) {
            dispatch(updateBillingAddress(defaultBillingAddress));
        }
    };
};

/**
 * Sets the delivery address to the default delivery address linked to the customer if there is one.
 * This will reload the delivery methods if it implies a change of postcode.
 */
export function setDeliveryAddressFromDefault(): AppThunk<void> {
    return async (dispatch, getState) => {
        const customer = getState().customer;
        const delivery = getState().delivery;
        const defaultDeliveryAddress = getDefaultDeliveryAddress(customer);
        const archivedHomeDeliveryPostcode = getArchivedHomeDeliveryPostcode(delivery);
        const isGeorestrictionError = getIsGeorestrictionError(delivery);

        if (defaultDeliveryAddress) {
            dispatch(updateDeliveryAddress(defaultDeliveryAddress));
            
            if (archivedHomeDeliveryPostcode !== defaultDeliveryAddress.postcode && !isGeorestrictionError) {
                dispatch(loadDeliveryMethods({location: defaultDeliveryAddress.postcode}, defaultDeliveryAddress.locale));
            }
            dispatch(updateArchivedHomeDeliveryPostcode(''));
        }
        // If no default address nothing happens.
    };
};

/**
 * Loads the preferred deatils linked to the customer if available.
 * Allows to preselect preferred Click and Collect details
 */
export function loadPreferredCAndCDetails(): AppThunk<void> {
    return async (dispatch, getState) => {
        const customer = getState().customer;
        const delivery = getState().delivery;
        const config = getState().config;
        const locale = config.localisation.countryCode.toLowerCase();
        const preferences = getCustomerPreferences(customer);
        const preferredPostcode = preferences?.storeAddressPreference?.value ?? '';
        const preferredStore = preferences?.storePreference?.value ?? '';
        const cart = getState().cart;
        const showCAndC = isCAndCAvailable(config, cart);

        if(showCAndC && preferences?.deliveryMethodPreference?.value && preferences?.collectionPreference?.value) {
            try {
                const preferredOptionType = JSON.parse(preferences?.deliveryMethodPreference?.value).option;
                dispatch(selectOption(preferredOptionType));
                if (preferredOptionType === 'clickAndCollect') {
                    await dispatch(loadStores(preferredPostcode));
                    if (isPostOfficeDeliveryAvailable(delivery, 'POLC')) {
                        await dispatch(loadPostOffices(preferredPostcode));
                    }
                    await dispatch(loadDeliveryMethods({storeId: preferredStore}, locale, true));
                    dispatch(selectStore(preferredStore));
                    let prefCollectionDetails = {
                        firstName: '',
                        lastName: '',
                        phone: '',
                        notYouCollectingFirstName: '',
                        notYouCollectingLastName: '',
                        notYouCollectingPhone: ''
                    };

                    prefCollectionDetails = JSON.parse(preferences?.collectionPreference?.value);
                    prefCollectionDetails = {
                        firstName: prefCollectionDetails.firstName,
                        lastName: prefCollectionDetails.lastName,
                        phone: prefCollectionDetails.phone,
                        notYouCollectingFirstName: prefCollectionDetails.notYouCollectingFirstName,
                        notYouCollectingLastName: prefCollectionDetails.notYouCollectingLastName,
                        notYouCollectingPhone: prefCollectionDetails.notYouCollectingPhone
                    };
                    dispatch(updateCollectionDetails(prefCollectionDetails));
                    dispatch(saveClickAndCollectLocation(preferredStore));
                    dispatch(selectStoreForDetailedView(''));
                    dispatch(updateDeliveryAddress({} as Address));
                }
            } catch (error) {
                console.warn("The collection details/ delivery method preference couldn't be parsed", error);
            }
        }
    };
};

/**
 * Attempts to add an address to cart but if a georestriction scenario is
 * encountered, an error dialog will pop up.
 * Georestrictions come from 2 different systems:
 * 1 - Anatwine: we have to check here on front end (hasDropshipProducts && address.locale !== 'gb')
 * 2 - Platform georestriction. This rely on catching an error when trying to add an address to cart.
 * @param address
 * @param previousAddress
 * @returns boolean True if the address has actually been changed, false otherwise (if any georestriction scenario was raised).
 */
export function validateDeliveryAddressAndSaveToCart(address: Address, previousAddress: Address|null): AppThunk<Promise<boolean>> {
    return async (dispatch, getState) => {
        const cart = getState().cart;
        const hasDropshipProducts = getHasDropshipProducts(cart);
        const config = getState().config;
        const localisation = getLocalisation(config);
        const customerId = getCustomerID(getState().customer);
        const locale = localisation.countryCode.toLowerCase();
        const removeDropshipProducts = hasDropshipProducts && address.locale !== locale;
        const api = getApi(config, 'mesh');
        const baseIdUrl = api.url;
        try {
            if(cart.ID) {
                // Save address to cart
                const cartPayload = {
                    deliveryAddress: {id: `${baseIdUrl}/addresses/${address.ID}`},
                    customer: {id: `${baseIdUrl}/customers/${customerId}`},
                };
                await carts().updateCart(cart.ID, cartPayload);
                await dispatch(updateIsGeorestrictionError(false));
                dispatch(clearNotification());
            }
        }
        catch (error) {
            newRelicData({ actionName: 'delivery', function: 'validateDeliveryAddressAndSaveToCart', message: { error:(error as Error)?.message, type:'unable to update address' }})
            if (error instanceof Error && error.message === 'GEORESTRICTED_DESTINATION') {
                const meshError = getMeshErrorAndDelete();
                if (meshError?.data.error.internalCode === 'GEORESTRICTED_DESTINATION') {
                    await dispatch(updateIsGeorestrictionError(true));
                    if((previousAddress && previousAddress.locale !== locale) ) {
                        await dispatch(clearDeliveryMethods());
                    }
                    let restrictedSkus = meshError?.data.error.message.split(',');
                    // If message === "" the split() results in restrictedSkus = [''] but we want just [].
                    if (restrictedSkus.length === 1 && restrictedSkus[0] === '') restrictedSkus = [];
                    const dialogData = {
                        removeDropshipProducts,
                        removeRestrictedProducts: true,
                        skusToRemove: restrictedSkus,
                    }
                    await dispatch(updateDialog('remove_georestricted_products_dialog', dialogData));
                    return false;
                }
            }
        }

        if (removeDropshipProducts && previousAddress) {
            // The external address is restored as we don't want the new one due to the anatwine restriction.
            const cartPayload = {
                deliveryAddress: {id: `${baseIdUrl}/addresses/${previousAddress.ID}`},
            };
            if(cart.ID) {
                await carts().updateCart(cart.ID, cartPayload);
            }
            const dialogData = {
                removeDropshipProducts: true,
                removeRestrictedProducts: false,
            };
            dispatch(updateDialog('remove_georestricted_products_dialog', dialogData));
            return false;
        }
        else {
            // No restriction of any kind, the address has been changed successfully
            dispatch(updateDeliveryAddress(address));
            return true;
        }
    }
}


// Helper functions
/**
 * Sorts the list of delivery methods so if one method has the ID provided it will be top of the list.
 * @param deliveryMethods
 * @param firstMethodId
 * @returns sorted deliveryMethods
 */
function putSelectedMethodOnTop(deliveryMethods: DeliveryMethods, firstMethodId?: string): DeliveryMethods {
    const homeMethods = deliveryMethods.home;
    homeMethods.sort(function(x, y){
        return x.ID === firstMethodId ? -1 : y.ID === firstMethodId ? 1 : 0;
    });

    const clickAndCollectMethods = deliveryMethods.clickAndCollect;
    clickAndCollectMethods.sort(function(x, y){
        return x.ID === firstMethodId ? -1 : y.ID === firstMethodId ? 1 : 0;
    });

    return {
        home: homeMethods,
        clickAndCollect: clickAndCollectMethods,
    };
}

