import initCartService, { CartService } from './carts';
import initConfigService, { ConfigService } from './config';
import initCustomersService, { CustomerService } from './customers';
import initDeliveryCountriesService, { DeliveryCountriesService } from './deliveryCountries';
import initBillingCountriesService, { BillingCountriesService } from './billingCountries';
import initPagesService, { PagesService } from './pages';
import initOrdersService, { OrdersService } from './orders';
import initAddressesService, { AddressesService } from './addresses';
import initTrackingService, { TrackingService } from './tracking';
import initPaymentsService, { PaymentService } from './payments';
import initSessionService, { SessionService } from './session';
import initLanguageFrameworksService, { LanguageFrameworksService } from './language';
import { Service } from './adaptors';
import { RootState } from '../store/reducers';


interface ServicesMap {
    [key: string]: Service 
}

const servicesMap: ServicesMap = {}

export function initServices(state: RootState){
    Object.assign(servicesMap, {
        carts: initCartService(state),
        config: initConfigService(state),
        customers: initCustomersService(state),
        deliveryCountries: initDeliveryCountriesService(state),
        billingCountries: initBillingCountriesService(state),
        orders: initOrdersService(state),
        addresses: initAddressesService(state),
        payments: initPaymentsService(state),
        tracking: initTrackingService(state),
        session: initSessionService(state),
        languageFrameworks: initLanguageFrameworksService(state),
        pages: initPagesService(state),
    })
}

export const payments = (): PaymentService => servicesMap.payments as PaymentService;
export const carts = (): CartService => servicesMap.carts as CartService;
export const config = (): ConfigService => servicesMap.config as ConfigService;
export const customers = (): CustomerService => servicesMap.customers as CustomerService;
export const deliveryCountries = (): DeliveryCountriesService => servicesMap.deliveryCountries as DeliveryCountriesService;
export const billingCountries = (): BillingCountriesService => servicesMap.billingCountries as BillingCountriesService;
export const pages = (): PagesService => servicesMap.pages as PagesService;
export const orders = (): OrdersService => servicesMap.orders as OrdersService;
export const addresses = (): AddressesService => servicesMap.addresses as AddressesService;
export const tracking = (): TrackingService => servicesMap.tracking as TrackingService;
export const session = (): SessionService => servicesMap.session as SessionService;
export const languageFrameworks = (): LanguageFrameworksService => servicesMap.languageFrameworks as LanguageFrameworksService;
