import merge from 'deepmerge';
import { CustomTheme } from '.';
import footpatrolTheme from "./footpatrol";

const theme: CustomTheme = {
    config: {
        displayName: "Footpatrol FI",
        headerLogoAndTextSpacing: {
            horizontal: '60px'
        },
    },
};

const footpatrolfiTheme: CustomTheme = merge(footpatrolTheme, theme);

export default footpatrolfiTheme;