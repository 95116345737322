import React, { useState, useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Tabs, Tab, Text, ResponsiveContext } from 'grommet';
import { ThemeContext } from 'styled-components';
import { CustomTheme } from '../../theme';
import { RootState } from '../../store/reducers';
import { AppThunkDispatch } from '../../modules/thunk';
import PropTypes from 'prop-types';
import OptionList, { OptionTypes } from '../OptionList/OptionList';
import { getLocalisation, getPayLaterDetailsLinks, getPayInthreeExpressDetails } from '../../modules/config';
import { clearNotification } from '../../modules/notification';
import { getCartId } from '../../modules/cart';
import {
    getPaymentMethods,
    getSelectedPaymentMethod,
    selectPaymentMethodAndTrack,
    loadPaymentMethods,
    updateRedirectDescription
} from '../../modules/billing';
import {
    getSelectedDeliveryOption
} from '../../modules/delivery';
import { translate } from '../../modules/translation';

import Separator from '../Separator/Separator';
import { getPaymentMethodRenderedExtras } from "../../lib/paymentMethodRenderedExtras/paymentMethodRenderedExtras";
import newRelicData from '../../newrelic';

/**
 * List of radio buttons. Each option can optionally display a subtitle below
 * and/or extra data (text or other component) on the right side.
 * 
 * @param paymentMethods {object} Arrays 'payNow' and 'payLater' with data for each payment method
 * @param activeTab {string} The tab to show on first render
 * @param savedCard {object} User saved card details (name, expiry date, etc.)
 */
function PaymentMethodSelector({ activeTab }: PaymentMethodSelectorProps) {

    const theme: CustomTheme = useContext(ThemeContext);
    const size = React.useContext(ResponsiveContext);
    const dispatch: AppThunkDispatch = useDispatch();
    const translation = useSelector((state: RootState) => state.translation);
    const billing = useSelector((state: RootState) => state.billing);
    const config = useSelector((state: RootState) => state.config);
    const cart = useSelector((state: RootState) => state.cart);
    const payLaterDetailsLinks = getPayLaterDetailsLinks(config);
    const localisation = getLocalisation(config);
    const delivery = useSelector((state: RootState) => state.delivery);
    const paymentMethods = getPaymentMethods(billing);
    const selectedMethod = getSelectedPaymentMethod(billing);
    const cartId = getCartId(cart);
    const selectedDeliveryOption = getSelectedDeliveryOption(delivery)?.value;
    const isClickAndCollect = selectedDeliveryOption === 'clickAndCollect';
    const isHomeDelivery = selectedDeliveryOption === 'home';
    const payInThreeLabel = getPayInthreeExpressDetails(config);

    const isPaymentMethodsEmpty = paymentMethods.payNow.length === 0 && paymentMethods.payLater.length === 0;
    const tabPadding = theme?.config?.stepSeparatorHorizontalMargin ?? '0px';
    const isDesktop = size !== 'small';

    const strings = {
        payNow: translate(translation, 'payNow', 'Pay Now'),
        payLater: translate(translation, 'payLater', 'Pay Later'),
    }

    // Tab to show first in priority order: tab of the selected method -> tab from 'activeTab' prop -> first tab (payNow)
    let defaultTabIndex = 0;
    if (activeTab === 'payLater') defaultTabIndex = 1;
    if (paymentMethods.payLater.find(method => method.id === selectedMethod.id)) defaultTabIndex = 1;
    const [activeTabIndex, setActiveTabIndex] = useState(defaultTabIndex);
    const [stockCheckCall, setStockCheckCall] = useState(false);

    // Set up the data for the 2 OptionList components
    const payNowOptions: any = [];
    let cardOption: any = null;
    let brainTreeOption: any = null;
    paymentMethods.payNow.forEach(method => {
        if (isHomeDelivery || (method.clickAndCollectSupported && isClickAndCollect)) {
            if (!method.active || method.id === 'UNIONPAY') {
                return; // Skip inactive methods and UNIONPAY
            }
            const {icon, description, link, disclosure} = getPaymentMethodRenderedExtras(method);
            const labelKey = method.id === 'CARD' || method.id === 'BRAINTREE' ? 'creditDebitCard' : method.label;
            const option = {
                id: method.id,
                label: translate(translation, labelKey, method.label),
                value: method.id,
                description,
                disclosure,
                extra: icon,
                link
            };

            switch (method.id) {
                case 'CARD':
                    cardOption = option;
                    break;
                case 'BRAINTREE':
                    brainTreeOption = option;
                    break;
                default:
                    payNowOptions.push(option);
                    break;
            }
        }
    });
    
    if(brainTreeOption) { 
        payNowOptions.unshift(brainTreeOption); 
    } else if(cardOption) {
        payNowOptions.unshift(cardOption); 
    }
    
    const payLaterOptions: any = [];

    paymentMethods.payLater.forEach(method => {

        if (isHomeDelivery || (method.clickAndCollectSupported === true && isClickAndCollect)) {
            const {icon, description, link,disclosure, disclosureLink, extraDisclosure} = getPaymentMethodRenderedExtras(method, payLaterDetailsLinks);
            let label = method.label;
            if (label === 'Afterpay' && localisation.countryCode.toUpperCase() === 'GB') {
                label = 'Clearpay';
            }
            if (label === 'PayPal Pay Later' &&  payInThreeLabel.label) {
                label = payInThreeLabel.label
            }
            payLaterOptions.push({
                id: method.id,
                label: translate(translation, label, label),
                value: method.id,
                description: description,
                disclosure: disclosure,
                extra: icon,
                link: link,
                disclosureLink: disclosureLink,
                extraDisclosure
            });
            if (method.id === 'AFTERPAY' && (localisation.countryCode.toUpperCase() === 'AU' || 'NZ')) {
                payLaterOptions.unshift(payLaterOptions.pop());
            }
        }

    });

    // Callback to save the selected payment method to Redux
    const onSelectOption = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(clearNotification());
        dispatch(updateRedirectDescription(''));
        dispatch(selectPaymentMethodAndTrack(event.currentTarget.value));
        // Stock check api call for every payment method selection 
        //dispatch(stockCheck(cartId));
        if(!stockCheckCall) setStockCheckCall(true);
        newRelicData({ actionName: 'PaymentMethodSelector', function: 'onSelectOption', message: `${event.target.value} payment method  selected`});
    };

    function tabTitle(label: string, isActive: boolean, fullWidth: boolean = false) {
        const weight = isActive ? 'bold' : 500
        const desktopTabWidth = theme?.config?.desktop?.tabWidth ?? "14.5vw";
        const mobileTabWidth = '50vw';
        const getWidth = (width: string) => {
            if (!fullWidth) return width;
            let num: number = parseFloat(width);
            return `${num * 2}vw`;
        }

        return (
            <Box
                width={isDesktop ? getWidth(desktopTabWidth) : `calc(${getWidth(mobileTabWidth)} - ${tabPadding})`}>
                <Text alignSelf="center" weight={weight}>{label}</Text>
            </Box>
        );
    }

    function makeListOfOptions(options: OptionTypes[], isPayLater: boolean) {
        const payLaterPadding = isDesktop ? {left: "15px"} : {left: "15px", right: "40px"};
        return (
            <Box pad={isPayLater ? payLaterPadding :
                    isDesktop ? {horizontal: "15px"} : {horizontal: 'medium'}}
                 margin={{top: getMargin(isPayLater)}}
            >
                <OptionList
                    options={options}
                    defaultSelected={selectedMethod.id}
                    onOptionChange={onSelectOption}
                    extras={isPayLater}
                />
            </Box>
        );
    }

    function getMargin(isPayLater: boolean) {
        if(isPayLater){
            return isDesktop ? "5px" : "0px";
        }
        return isDesktop ? "large" : 'medium';
    }

    useEffect(() => {
        if (isPaymentMethodsEmpty) {
            dispatch(loadPaymentMethods(cartId));
        }
        if(selectedMethod.id){
            newRelicData({ actionName: 'PaymentMethodSelector', function: 'useeffect', message: `default selected payment option is ${selectedMethod.id}`})
        }
    //eslint-disable-next-line    
    }, []);

    useEffect(() => {
        if (paymentMethods.payLater.find(method => method.id === selectedMethod.id)) {
            setActiveTabIndex(1);
        }

        // Dispatch the action only once if payment method selected by default for registered users
        /*if(!stockCheckCall && Object.keys(selectedMethod).length > 0) {
            dispatch(stockCheck(cartId));
            setStockCheckCall(true);
        }*/
    //eslint-disable-next-line
    }, [selectedMethod]);

    // Don't show the tabs with no content if the methods haven't yet loaded
    if (isPaymentMethodsEmpty) return <Box/>;

    return (
        <Box
            fill="horizontal"
            flex={false}
            border={isDesktop
                ? (theme?.config?.desktop?.boxBorder?? false)
                : false
            }
        >
            <Box fill="horizontal" background="white" pad={{bottom: '20px'}}>

                {payLaterOptions.length
                    ?
                    <Tabs
                        activeIndex={activeTabIndex}
                        onActive={(event) => setActiveTabIndex(event)}
                        justify="center"
                        alignControls="stretch"
                        flex={false}
                    >
                        <Tab title={tabTitle(strings.payNow, activeTabIndex === 0)}>
                            {makeListOfOptions(payNowOptions, false)}
                        </Tab>
                        <Tab title={tabTitle(strings.payLater, activeTabIndex === 1)}>
                            {makeListOfOptions(payLaterOptions, true)}
                        </Tab>
                    </Tabs>
                    :
                    makeListOfOptions(payNowOptions, false)
                }

                <Separator
                    height={isDesktop
                        ? theme?.config?.stepSeparatorHeight ?? '16px'
                        : theme?.config?.stepSeparatorHeightMobile ?? '16px'}
                    color= {isDesktop
                        ? (theme?.config?.desktop?.desktopStepSeparatorColour?? 'light-1' )
                        :  theme?.config?.stepSeparatorColour?? 'light-1'}
                    margin={{
                        horizontal: theme?.config?.stepSeparatorHorizontalMargin ?? '0px',
                        top: '20px'
                    }}
                />
            </Box>
        </Box>
    );
}

type PaymentMethodSelectorProps = {
    activeTab?: 'payNow' | 'payLater',
};

PaymentMethodSelector.propTypes = {
    activeTab: PropTypes.string,
};

export default PaymentMethodSelector;
