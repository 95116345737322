import React from 'react';
import PropTypes from 'prop-types';
import { Text, Box } from 'grommet';
import { StoreAddress, CollectionDetails } from '../../modules/delivery/types';
import { Address } from '../../modules/customer/types';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';
import { getIsNotYouCollecting } from '../../modules/delivery';


function FormattedAddress({type, details, textFormat}: FormattedAddressProps) {
    const delivery = useSelector((state: RootState) => state.delivery);
    const isNotYouCollecting = getIsNotYouCollecting(delivery);
    let formattedAddressText = <></>;

    switch(type) {
        case 'homeAddress':
            const homeDetails = details as Address;
            let homeString = `${homeDetails.firstName} ${homeDetails.lastName}`;
            homeString += `, ${makeSpacesNonBreaking(homeDetails.address1)}`;
            if (homeDetails.address2) homeString += `, ${makeSpacesNonBreaking(homeDetails.address2)}`;
            if (homeDetails.address3) homeString += `, ${makeSpacesNonBreaking(homeDetails.address3)}`;
            if (homeDetails.town) homeString += `, ${makeSpacesNonBreaking(homeDetails.town)}`;
            if (homeDetails.county) homeString += `, ${makeSpacesNonBreaking(homeDetails.county)}`;
            homeString += `, ${makeSpacesNonBreaking(homeDetails.postcode)}`;
            if (homeDetails.country) homeString += `, ${makeSpacesNonBreaking(homeDetails.country)}`;

            formattedAddressText = <Box fill="horizontal" direction="column">
                <Text {...textFormat}>{homeString}</Text>
                {homeDetails.phone && <Text {...textFormat}>{homeDetails.phone}</Text>}
            </Box>;
            break;

        case 'storeAddress':
            const storeDetails = details as StoreAddress;
            let storeString = storeDetails.address1;
            if (storeDetails.address2) storeString += `, ${makeSpacesNonBreaking(storeDetails.address2)}`;
            if (storeDetails.address3) storeString += `, ${makeSpacesNonBreaking(storeDetails.address3)}`;
            if (storeDetails.postcode) storeString += `, ${makeSpacesNonBreaking(storeDetails.postcode)}`;

            formattedAddressText = <Text {...textFormat}>{storeString}</Text>;
            break;
        
        case 'collectionDetails':
            const collectionDetails = details as CollectionDetails;
            const collectionString = `${collectionDetails.firstName} ${collectionDetails.lastName}`;
            const notYouCollectingString = `${collectionDetails.notYouCollectingFirstName} ${collectionDetails.notYouCollectingLastName}`;
            formattedAddressText = <Box fill="horizontal" direction="column">
                {isNotYouCollecting ?
                <>
                <Text {...textFormat}>{notYouCollectingString}</Text>
                <Text {...textFormat}>{collectionDetails.notYouCollectingPhone}</Text>
                </>
                :
                <>
                <Text {...textFormat}>{collectionString}</Text>
                <Text {...textFormat}>{collectionDetails.phone}</Text>
                </>
                }
            </Box>;
            break;

        default:
            break;
        
    }

    /**
     * Replace spaces by non-breaking spaces
     * @param string The string we don't want to break on a second line
     */
    function makeSpacesNonBreaking(string?: string) {
        return string?.replace(/ /g, '\xa0');
    }

    return formattedAddressText;
}


type FormattedAddressProps = {
    type: 'homeAddress' | 'storeAddress' | 'collectionDetails',
    details: Address | StoreAddress | CollectionDetails,
    textFormat?: {
        size?: string,
        weight?: 'normal' | 'bold' | number,
    }
};

FormattedAddress.propTypes = {
    type: PropTypes.string.isRequired,
    details: PropTypes.object.isRequired,
    textFormat: PropTypes.shape({
        size: PropTypes.string,
        weight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
};

export default FormattedAddress;
