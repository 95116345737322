import React, {useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Text } from "grommet";
import { getLocalisation } from "../../modules/config";
import { useSelector } from "react-redux";
import { RootState } from "../../store/reducers";

/**
 * Renders a Text component with price and currency symbol formatted to the user locale, eg. "£10.50" or "10,50 €".
 * @param amount {number|string} The sum
 * @param currency {string} The currency
 */
function Currency({ amount, currency = 'GBP', textFormat, strikeThrough }: CurrencyProps) {

    const [isIntlLoaded, setIntlLoaded] = useState(false);
    const config = useSelector((state: RootState) => state.config);
    const localisation = getLocalisation(config);
    const locale = localisation.language.code;

    useEffect(() => {
        const loadModules = async () => {
            // Loading the 'Intl' polyfill if needed (typically IE < 11)
            if (!global.Intl) {
                let intlModule = await import('intl');
                global.Intl = intlModule.default;
            }
            else {
                setIntlLoaded(true);
            }
            
            // The polyfill needs locales to be loaded separately, otherwise it shows everything as en-US.
            if (!Intl.NumberFormat.supportedLocalesOf([locale]).includes(locale)) {
                setIntlLoaded(false); // triggers component refresh, otherwise old data shows when switching locales in Storybook
                await import(`intl/locale-data/jsonp/${locale}.js`);
                setIntlLoaded(true);
            }
        }
        loadModules();
    });

    let formatter: Intl.NumberFormat;
    let amountNumber: number;
    let formattedAmount:string = '';
    let additionalStyling = strikeThrough? "line-through" : "";
    if (isIntlLoaded) {
        const options = {
            style: 'currency',
            currency: currency,
            ...(locale === 'th-TH' && { currencyDisplay: 'code' })
        };
        formatter = new Intl.NumberFormat(locale, options)
        amountNumber = Number(amount);
        formattedAmount = formatter.format(amountNumber);
    }
    
    return(
        <Text 
            {...textFormat} 
            style={{textDecoration: additionalStyling}}
        >
            {formattedAmount}
        </Text>
    );
}

type CurrencyProps = {
    amount: string | number,
    currency?: string,
    locale?: string,
    textFormat?: {
        size?: string,
        weight?: 'bold'|'normal'|number,
        color?: string,
    },
    strikeThrough?: boolean,
};

Currency.propTypes = {
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    currency: PropTypes.string,
    locale: PropTypes.string,
    textFormat: PropTypes.shape({
        size: PropTypes.string,
        textFormat: PropTypes.string
    }),
    strikeThrough: PropTypes.bool,
};

export default Currency;
