import { ApplePaySupportedNetwork, ApplePayMerchantCapability } from '../../services/payments/applePayHelper/types';
import { GooglePayAllowedCardNetworks, GooglePayAllowedAuthMethods, GooglePayAllowedPaymentMethods, GooglePayAllowedCountryCodes } from '../../services/payments/googlepayHelper/types';

export const UPDATE = 'checkout/config/UPDATE';

export interface Config {
    api: {
        [apiLabel: string]: ApiConfig
    },
    returnUrl: string,
    message: string,
    channel: string,
    store: string,
    click_and_collect: boolean,
    click_and_collect_tabs: boolean,
    privacy_policy: PrivacyPolicyConfig,
    concat_address_for_klarna: boolean,
    localisation: Localisation,
    adyen: AdyenConfig,
    apple_pay: ApplePayConfig,
    google_pay: GooglePayConfig,
    klarna_express: KlarnaExpressConfig,
    openpay: OpenpayConfig,
    custom_order_tracking: boolean,
    pay_later_links: PayLaterLinks,
    giftcards: GiftcardsConfig,
    store_descriptions: StoreDescriptions,
    payment_options_descriptions: PaymentOptionsDescriptions,
    show_marketing_checkboxes: boolean,
    show_cobrands: boolean,
    delivery_georestrictions: Georestrictions,
    marketing_sms_optout: boolean,
    express_optout_checkbox: boolean,
    enable_qualtrics: {
        id: string,
        channel: string
    },
    marketing_all_optout_text: boolean,
    marketing_email_optout: boolean,
    hide_klarna_disclosure_links : boolean,
    execute_threeD: boolean,
    payinthree_disclosure_text: PayInThreeText,
    payinthree_express: PayInThreeExpress,
    nike_connected: boolean,
    marketing_details: MarketingDetails,
    bing_adds_id: string,
    country_unselected: boolean,
    show_translated_countries: boolean,
    country_details: CountryConfig[],
}

export interface PrivacyPolicyConfig {
    link: string,
    date: string,
    text: string
}

export interface PayInThreeExpress {
    label: string,
}

export interface Localisation {
    language: {
        code: string,
    },
    countryCode: string,
    symbolsInPassword: boolean,
    restrictSameCharacter3TimesInPassword: boolean,
    passwordHelpText: string,
    distanceUnits: string
}
export interface AdyenConfig {
    environment: string,
    clientKey: string,
    merchantAccount: string,
    locale: string,
    countryCode: string,
}

export interface ApplePayConfig {
    merchantId: string,
    merchantCountryCode: string,
    merchantDisplayName: string,
    label: string,
    supportedNetworks: ApplePaySupportedNetwork[],
    merchantCapabilities: ApplePayMerchantCapability[],
}
export interface GooglePayConfig {
    merchantDisplayName: string,
    allowedCardNetworks: GooglePayAllowedCardNetworks[],
    allowedAuthMethods: GooglePayAllowedAuthMethods[],
    allowedPaymentMethods: GooglePayAllowedPaymentMethods[],
    allowedCountryCodes: GooglePayAllowedCountryCodes[],
}

export interface PayLaterLinks {
    [key: string]: string
}

export interface GiftcardsConfig {
    eGiftcardSku: string,
    physicalGiftcardSku: string
}

export interface ApiConfig {
    url: string
    apiKey: string
}

export interface KlarnaExpressConfig {
    region: string,
    locale: string,
    countryCode: string,
}

export interface OpenpayConfig {
    baseUrl: string,
}

export interface ConfigFilters {
    guest: boolean
}

export interface StoreDescriptions {
    store: string,
    postOffice: string
}

export interface PaymentOptionsDescriptions {
    homeDelivery: string,
    clickAndCollect: string,
}

interface Georestrictions {
    brands: string[],
}

interface UpdateConfig {
    type: typeof UPDATE
    config: Config
}

export interface PayInThreeText {
    required: boolean,
    value: string,
    link: string,
    linkLabel: string,
}
export interface MarketingDetails {
    text: string,
    emailLabel: string,
    smsLabel: string,
}

interface CountryConfig {
    code: string,
    name: string,
    default: boolean,
    requiresState: boolean,
    pcaPredictSupported: boolean,
}

export type ConfigActionTypes = UpdateConfig;