import { local as browserLocalStorage } from 'store2';
import { getCookie } from './lib/session/session';

export interface newrelicDataType {
   actionName: string;
   function: string;
   paymentName?: string;
   message?: string | any;
   resultCode?: string;
   type?: string;
   desktop?: boolean;
   cartId?: string;
   orderId?: string;
   isExpress?: boolean;
   deliveryMethod?: string;
   deliveryAddressId?:string;
   billingAddressId?:string;
   cartItemCount?: number;
   sessionId?: string;
   customerID?:string;
   customerEmail?:string;
}

function newRelicData(data: newrelicDataType) {
   const storeLocation = browserLocalStorage.get('store');
   const customerID = data.customerID ?? browserLocalStorage.get('customerId');
   const cartId = browserLocalStorage.get('cartId');
   const sessionId = getCookie('session.ID');

   // @ts-ignore
   if(window.newrelic) {
      // @ts-ignore
       window.newrelic.addPageAction(data.actionName, { cartId: cartId, store: storeLocation,  project: 'NC', paymentName: data.paymentName, function: data.function,  message: data.message, resultCode: data.resultCode, type: data.type, desktop: data.desktop, orderId: data.orderId, isExpress: data.isExpress, deliveryMethod: data.deliveryMethod, deliveryAddressId: data.deliveryAddressId, billingAddressId: data.billingAddressId, cartItemsCount: data.cartItemCount, sessionId: sessionId, customerID: customerID, customerEmail: data.customerEmail });
    }
}
export default newRelicData;