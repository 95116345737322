import React, { SyntheticEvent } from "react";
import PropTypes from "prop-types";
import { Box, Layer } from "grommet";
import { Close } from '../Icon/index';

/**
 * Displays content on the middle of the screen, with the previous view partially visible but unaccessible underneath.
 * 
 * @param children {ReactNode} Any html/jsx to be rendered.
 * @param onClose {function} Callback executed when the Close icon is clicked or the Esc key used.
 */
function Modal({ children, onClose, testid, showCloseIcon }: ModalProps) {
    return (
        <Layer
            full={true}
            responsive={false}
            background={{opacity: 0}}
            onEsc={onClose}
        >
            <Box
                direction="column"
                align="center"
                justify="center"
                fill="vertical"
            >
                <Box
                    direction="column"
                    align="end"
                    pad={{horizontal: 'medium'}}
                >
                    {showCloseIcon &&
                        <Box
                            data-testid={testid || 'close-modal-display'}
                            onClick={onClose}
                            margin={{bottom: 'medium'}}
                        >
                            <Close fill="white" />
                        </Box>
                    }
                    <Box>
                        {children}
                    </Box>
                </Box>
            </Box>
        </Layer>
    );
}

type ModalProps = {
    children: React.ReactNode,
    onClose?: (event: SyntheticEvent) => void,
    testid?: string,
    showCloseIcon?: boolean,
};

Modal.propTypes = {
    children: PropTypes.node.isRequired,
    onClose: PropTypes.func,
    testid: PropTypes.string,
    showCloseIcon: PropTypes.bool,
};

export default Modal;
