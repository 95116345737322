import { AxiosInstance, AxiosResponse } from 'axios';
import { CartUpdates, ZeroPaymentResponse, CartParams } from '../carts';
import { MeshConfig } from './types';
import { PostOfficeLocation, DeliveryMethodType } from '../../modules/delivery/types';
import { AppliedGiftCard, GiftCard, CustomisationSets } from '../../modules/cart/types';

type MoneyAmount = {
    amount: string, 
    currency: string
};

export type ProductResponse = {
    "$schema": string,
    products: ProductResponse[],
    SKU: string,
    parentSKU: string,
    trackingSKU: string,
    name: string,
    type: 'CartProduct' | 'PromotionGroup',
    brandName?: string,
    isDiscounted: boolean,
    image: {
        ID: string,
        altText?: string,
        originalURL: string,
        srcset?: string,
        resizingService: {
            href: string,
            originalURL: string,
            properties: {
                width: string,
                height: string
            }
        },
        tags: []
    },
    resizedMainImage: {
        ID: string,
        altText?: string,
        originalURL: string,
        resizingService: {
            href: string,
            originalURL: string,
            properties: {
                width: string,
                height: string
            }
        },
        tags: []
    },
    note: null,
    colourDescription: string | null,
    colour: string | null,
    optionValues: {
        Amount: string,
        Size: string
    },
    fulfilment?: {
        fulfilmentMessage: string,
        fulfilmentMessage2: string,
        imageURL: string,
        isDefault: boolean,
        deliveryMessage: {
            message: string,
            message2: string,
            imageURL: string,
            leadTime: string,
            informationPage: null,
            isDefault: boolean,
            deliveryMethod: {
                id: string,
                ID: string
            },
        },
        informationPage: {
            ID: string
        },
    },
    infoPageSlug: null,
    fulfilmentMessage?: string,
    stockPool?: {
        ID: string,
        links: {
            rel: string,
            href: string
        }[],
        owningStoreID: number,
        networkGroupID: string,
        networkID: string,
        deliveryLocales: string[],
        description: string,
        location: string,
        dropShip: boolean
    },
    priceOverride: null,
    additionalProducts: [],
    unitPrice: MoneyAmount,
    previousUnitPrice: MoneyAmount,
    previousPrice: MoneyAmount,
    saving: MoneyAmount,
    subtotal: MoneyAmount,
    unitPriceBeforeDiscounts: MoneyAmount,
    quantity: number,
    decimalQuantity: null,
    totalQuantity: number,
    customisationSets: CustomisationSets[] | null,
    cartProductsSubtotal: MoneyAmount,
    totalCartProductSavings: MoneyAmount,
    discretionaryDiscounts: [],
    discount_amount?: MoneyAmount,
    categories?: {
        ID: string,
        path: string,
        name: string,
        sortOrder: number,
        clientID: string,
        product: null
    }[],
    cartProductNotification?: {
        deliveryType?: string,
        scheduledDelivery?: string,
        personalMessage?: string,
        sendFrom?: string,
        sendTo?: string
    },
    isAvailable: boolean,
    status: string,
    salesTaxCode: null,
    onlyForPremiumMembers: boolean
};
export interface CartsResponse {
    ID: string,
    id: string,
    href: string, 
    reference: string, 
    paid: boolean,
    count: number,
    maximumCartValue?: MoneyAmount,
    channel: string,
    productsSubtotal: MoneyAmount,
    chargeSubtotal: MoneyAmount,
    productSubtotalBeforeDeductions: MoneyAmount,
    preSaleSubtotal: MoneyAmount,
    subtotalBeforeDiscounts: MoneyAmount,
    total: MoneyAmount,
    giftcards?: GiftCard[],
    giftcardsApplied?: AppliedGiftCard[],
    giftcardsSubtotal: MoneyAmount,
    balanceToPay: MoneyAmount,
    tax: null,
    customisationTotal: MoneyAmount,
    amountPaid: MoneyAmount,
    previousBalanceToPay: MoneyAmount,
    containsUnavailableProducts: boolean,
    eligibleForGiftCards: boolean,
    allowToPayWithGiftCard: boolean,
    containsPreOrderProducts: boolean,
    estimatedDeliveryDate: {
        hasRange: boolean,
        startDate: string,
        endDate: string
    },
    estimatedDispatchDate: null,
    customer?: {
        id: string,
        ID: string
    },
    billingAddress?: {
        id: string,
        ID: string
    },
    deliveryAddress?: {
        id: string,
        ID: string,
        clientID: null,
        name: string,
        title: string,
        firstName: string,
        lastName: string,
        address1: string,
        address2: string,
        address3: string,
        town: string,
        county: string,
        postcode: string,
        country: string,
        locale: string,
        email: string,
        phone: string,
        isPrimaryAddress: boolean,
        isPrimaryBillingAddress: boolean,
        canSetPrimaryAddress: boolean,
        canSetPrimaryBillingAddress: boolean
    },
    quotationDeliveryAddress: {
        town?: string,
        county?: string,
        postcode: string,
        country: string,
        locale: string
    },
    deliveryOptions: {
        id: string,
        links: {
                rel: string,
                href: string,
                targetSchema: string,
				needsConfirmation?: boolean
        }[]
    },
    delivery: {
        deliveryMethodID: string,
        deliveryMethodDisplayName: string,
        deliveryMethodDescription: string,
        date: null,
        allowsCrossLocaleDelivery: boolean,
        isExclusivelyCrossLocale: boolean,
        note: null
    },
    deliverySubtotal: MoneyAmount,
    rawCartTotal: MoneyAmount,
    deliveryNote: null,
    deliveryInformation: {
        note: null
    },
    deliveryStoreList: [],
    contents: ProductResponse[],
    contentsOverView: {
        [key: string]: {
            totalQuantity: number
        }
    },
    discountCodes: string[],
    cartDiscounts?: {
        title: string,
        isDelivery: boolean,
        saving: MoneyAmount,
    }[],
    exclusiveDiscretionaryDiscountAdded: boolean,
    discretionaryDiscounts: [],
    totalSavings: {
        productSavings: MoneyAmount,
        deliverySavings: MoneyAmount,
        total: MoneyAmount
    },
    note: null,
    conflictMessage: string,
    conflictCode: null,
    affiliateCode: null,
    allowCrossFascia: boolean,
    payPalCreditAvailable: boolean,
    selectedPaymentMethod: null,
    displayMarketingOptIn: boolean,
    mergeableContents: null,
    canCheckoutAsGuest: boolean
}


type PaymentMethod = {
    name: string,
    active: boolean,
    additionalData: {
        [key: string]: string
    },
    publicKey?: string,
    restrictedPaymentCountries?: string[]
};

type DeliveryMethod = {
    id: string,
    ID: string,
    links: {
        rel: string,
        href: string,
        method: string,
        body: {
            delivery: {
                deliveryMethodID: string
            }
        }
	}[],
	needsConfirmation?: boolean,
    clientID: string,
    name: string,
    type: DeliveryMethodType,
    description: string,
    price: MoneyAmount,
    previousPrice: MoneyAmount,
    isDiscounted: boolean,
    selected: boolean,
    isBookable: boolean,
    isCollectPlus: boolean,
    isCAndC: boolean,
    isPOLC: boolean,
    allowsCrossLocaleDelivery: boolean,
    isExclusivelyCrossLocale: boolean,
    isNominated: boolean,
    cutOffTime: string,
    slots?: TimeSlot[],
    displayPrice: MoneyAmount,
    freeDeliveryThresholdShortfall: null,
    estimatedDelivery: {
        lowerEstimate: {
            date: string,
            timezone_type: number,
            timezone: string
        },
        upperEstimate: {
            date: string,
            timezone_type: number,
            timezone: string
        }
    },
    informationPageId: null,
    metapackServiceCode: string
};

type TimeSlot = {
    links: {
        rel: string,
        href: string,
        method: string,
        body: {
            delivery: {
                deliveryMethodID: string,
                date: string,
                timeSlot: {
                    'start': string, // eg. "10:00:00"
                    'end': string, // eg. "11:00:00"
                }
            }
        }
    }[],
    date: string, // eg. "2021-05-13"
    start: string, // eg."10:00:00"
    end: string, // eg. "11:00:00",
    type: string // eg. "TIME SLOT",
    selected: boolean,
    greenSlot: boolean,
    recommended: boolean,
};

export type Coordinates = {
    latitude: string,
    longitude: string
};

export interface DeliveryOptionsForProposedAddressResponse {
    queryType: string,
    referencePostcode: string,
    deliveryMethods: DeliveryMethod[]
}

export interface DeliveryOptionsAvailabilityResponse {
	stores?: Store[],
	available: boolean,
	reason?: string
}

export interface PaymentMethodsResponse extends Array<PaymentMethod>{};

type Days = 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' | 'Sunday';

type OpeningInfo = {
    open: boolean,
    opensAt: string,
    closesAt: string,
    formattedOpeningHours: string
}

type Store = {
    storeID: string,
    href: string,
    clientID: string,
    name: string,
    fullFasciaName: string,
    address: {
        address1: string,
        address2: string | null,
        address3: string | null,
        town: string,
        county: string | null,
        postcode: string,
        country: string,
        locale: string
    },
    latitude: string,
    longitude: string,
    phone: string,
    openingHours: {
        [key in Days]: OpeningInfo
    },
    directions: null,
    distance: string,
    distanceUnits: string,
    fasciaCode: string,
    fasciaLogo: null
}

export interface StoresResponse {
    start: number,
    count: number,
    totalFound: number,
    stores: Store[]
};

export interface PostOfficeResponse {
    postoffices: PostOfficeLocation[],
};

export interface DeleteProductResponse {
    cartProductsSubtotal: MoneyAmount,
    cartCount: number,
};

function carts (client: AxiosInstance, meshConfig: MeshConfig) {

    const { channel, locale} = meshConfig;
    const countryLocale: string = locale;
    
    /**
     * Retrieves a cart from Mesh
     * @param cartId Cart ID
     * @param unavailableForGiftCardStatus Value for this Mesh param
     * @param locale Locale
     */
    async function getCart(cartId: string, unavailableForGiftCardStatus: 0 | 1 = 1): Promise<AxiosResponse<CartsResponse>>{
        let cartResponse = await client.get<CartsResponse>(`/carts/${cartId}`, {
            params: {
                channel,
                unavailableForGiftCardStatus,
            }
        })
        
        return cartResponse;
    }

    /**
     * Update an existing cart
     * @param cartId Cart ID
     * @param locale Locale
     */
    async function updateCart(cartId: string, updates: CartUpdates, locale: string = countryLocale): Promise<AxiosResponse<CartsResponse>>{
        const params: CartParams = {
            channel,
            unavailableForGiftCardStatus: 1,
            locale,
            debug: '1',
        }
        if(updates.proposedDeliveryLocale) {
            params.proposedDeliveryLocale = updates.proposedDeliveryLocale;
        }
        let cartResponse = await client.put<CartsResponse>(`/carts/${cartId}`, updates, {
            params,
            timeout: 60000
        });

        return cartResponse;
    }

    async function makeZeroPayment(cartId: string): Promise<AxiosResponse<ZeroPaymentResponse>>{
        let zeroPaymentResponse = await client.post<ZeroPaymentResponse>(`carts/${cartId}/giftCardPayments`);

        return zeroPaymentResponse;
    }

    /**
     * Retrieves payment methods from Mesh for a specific cart
     * @param cartId Cart ID
     * @param unavailableForGiftCardStatus Value for this Mesh param
     * @param locale Locale
     */
    async function getCartPaymentMethods(cartId: string, unavailableForGiftCardStatus: 0 | 1 = 1): Promise<AxiosResponse<PaymentMethodsResponse>>{
        let methodsResponse = await client.get<PaymentMethodsResponse>(`/carts/${cartId}/paymentMethods`, {
            params: {
                channel,
                unavailableForGiftCardStatus,
                locale
            },
            timeout: 60000
        })

        return methodsResponse;
    }

    /**
    * Retrieves the delivery methods available for the current cart state from Mesh.
    * 
    * @param cartId Cart ID
    */
    async function getCartDeliveryMethods(cartId: string): Promise<AxiosResponse<DeliveryMethod[]>> {

        const deliveryMethodsResponse = await client.get<DeliveryMethod[]>(`/carts/${cartId}/deliveryOptions`);

        return deliveryMethodsResponse;
    }

    /**
     * Retrieves the delivery methods available for a cart from Mesh based on the provided searches
     * 
     * @param cartId Cart ID
     * @param search If a string (postcode, town, etc.) it is passed on in the `q` query param.
     *               If it's coordinates, a store will have been selected and we query methods from this store.
     * @param locale Locale
     */
    async function getCartDeliveryMethodsForAddress(cartId: string, search: string | Coordinates, locale: string = countryLocale): Promise<AxiosResponse<DeliveryOptionsForProposedAddressResponse>> {
        let params: any;
        // Home Delivery
        if (typeof search === 'string') {
            params = {
                q: search,
                excludeCandC: 1,
                channel,
                deliveryLocale: locale.toLowerCase(),
            }
        }
        // Click and Collect
        else {
            params = {
                lat: search.latitude,
                long: search.longitude,
                excludeCandC: 0,
                channel,
                deliveryLocale: locale.toLowerCase(),
            }
        }
        
        const deliveryMethodsResponse = await client.get<DeliveryOptionsForProposedAddressResponse>(`/carts/${cartId}/deliveryOptionsForProposedAddress`, {params});

        return deliveryMethodsResponse; 
    }

	/**
     * Where there are Click and Collect Methods that require additional confirmation of availability this endpoint is called to check Fluent stock and if
	 * product is available, check the delivery service is currently available.
	 * 
     * @param cartId Cart ID
     * @param search If a string (postcode, town, etc.) it is passed on in the `q` query param.
     *               If it's coordinates, a store will have been selected and we query methods from this store.
     * @param locale Locale
     */
	async function getCartDeliveryOptionAvailability(cartId: string, locale: string = countryLocale, deliveryMethodID: string): Promise<AxiosResponse<DeliveryOptionsAvailabilityResponse>> {
        let params: any;
        // Click and Collect
            params = {
                channel,
                deliveryLocale: locale.toLowerCase(),
                postcode: locale.toLocaleLowerCase().replace(/ /g,'')
            }
        
        const deliveryOptionsAvailabilityResponse = await client.get<DeliveryOptionsAvailabilityResponse>(`/carts/${cartId}/deliveryMethods/${deliveryMethodID}/deliveryAvailabilityCheck`, {params});

        return deliveryOptionsAvailabilityResponse; 
    }




    /**
     * Retrieves a list of stores that are close to a provided location.
     * 
     * @param cartId Cart ID
     * @param location Search term (postcode, city or any partial string)
     * @param from Used for pagination. Eg. 'from = 2' will omit the first 2 results from the list that would otherwise be returned.
     * @param max Maximum number of store items returned
     */
    async function getStoresByLocation(cartId: string, location: string | Coordinates, units: string, from: number = 0, max: number = 10, unavailableForGiftCardStatus: number = 1): Promise<AxiosResponse<StoresResponse>> {        
        let locationParams;
        if (typeof location === "string") {
            locationParams = {
                query: location,
                units
            }
        } else {
            locationParams = {
                lat: location.latitude,
                lon: location.longitude,
                units
            }
        }
        
        const stores = await client.get<StoresResponse>(`/carts/${cartId}/delivery/storelocations`, {
            params: {
                channel,
                unavailableForGiftCardStatus,
                locale,
                ...locationParams,
                from,
                max,
            }
        });
        return stores;
    }

    /**
     * Retrieve a list of nearby Post Offices.
     * 
     * @param cartId 
     * @param postcode 
     */
    async function getPostOfficesByLocation(cartId: string, location: string | Coordinates, units: string, from: number = 0, max: number = 10, unavailableForGiftCardStatus: number = 1): Promise<AxiosResponse<PostOfficeResponse>> {
        let locationParams;
        if (typeof location === "string") {
            locationParams = {
                query: location,
                units
            }
        } else {
            locationParams = {
                lat: location.latitude,
                lon: location.longitude,
                units
            }
        }
        
        const postOffices = await client.get<any>(`/carts/${cartId}/delivery/postOfficeLocations`, {
            params: {
                channel,
                unavailableForGiftCardStatus,
                ...locationParams,
                from,
                max
            }
        });

        return postOffices;
    }

    /**
     * Delete a product from a Mesh cart by SKU.
     * @param cartId Cart ID
     * @param sku Product SKU
     */
    async function deleteProductFromCart(cartId: string, sku: string): Promise<AxiosResponse<DeleteProductResponse>>{
        let cartResponse = await client.delete<DeleteProductResponse>(`/carts/${cartId}/products/${sku}`);

        return cartResponse;
    }

    return {
        getCart,
        updateCart,
        makeZeroPayment,
        getCartPaymentMethods,
        getCartDeliveryMethods,
        getCartDeliveryMethodsForAddress,
		getCartDeliveryOptionAvailability,
        getStoresByLocation,
        getPostOfficesByLocation,
        deleteProductFromCart,
    };
}

export type CartsService = ReturnType<typeof carts>;

export default carts;
