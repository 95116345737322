import merge from 'deepmerge';
import defaultTheme, { CustomTheme } from '.';
import './index';

const theme: CustomTheme = {
    config: {
        displayName: "",
        headerShowLogo: false,
    },
};

// A JD theme with no logo
const blankTheme: CustomTheme = merge(defaultTheme, theme);

export default blankTheme;