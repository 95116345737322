import { combineReducers } from 'redux';
import configReducer from '../modules/config';
import cartReducer from '../modules/cart';
import deliveryReducer from '../modules/delivery';
import customerReducer from '../modules/customer';
import billingReducer from '../modules/billing';
import orderReducer from '../modules/order';
import notificationReducer from '../modules/notification';
import sessionReducer from '../modules/session';
import translationReducer from '../modules/translation';

const rootReducer = combineReducers({
    config: configReducer, 
    cart: cartReducer,
    delivery: deliveryReducer,
    customer: customerReducer,
    billing: billingReducer,
    order: orderReducer,
    notification: notificationReducer,
    session: sessionReducer,
    translation: translationReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export type StateName = 'config'|'cart'|'delivery'|'customer'|'billing'|'order'|'notification'|'translation';

export default rootReducer;