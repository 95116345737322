import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Box, Text, Table, TableBody, TableRow, TableCell } from "grommet";
import { RootState } from "../../store/reducers";
import { translate } from "../../modules/translation";

/**
 * A table is displayed containing the opening hours for each day for a particular shop.
 * The first row shows the opening times for the current day.
 * @param openingHours {object} Data from Platform containing the opening hours for each day for the shop.
 */
function OpeningHoursTable ({ openingHours } : OpeningHoursTableProps) {
    const translation = useSelector((state: RootState) => state.translation);

    const date = new Date();
    let currentDayNumber = date.getDay();
    currentDayNumber = currentDayNumber === 0 ? 6 : currentDayNumber-1; // 0 to be Monday, not Sunday.
    

    const strings = {
        openToday: translate(translation, 'openToday', 'Open Today'),
        days: [
            'Monday' as Day,
            'Tuesday' as Day,
            'Wednesday' as Day,
            'Thursday' as Day,
            'Friday' as Day,
            'Saturday' as Day,
            'Sunday' as Day,
        ],
    }

    const days = Object.keys(openingHours);
    const today = days[currentDayNumber] as Day;

    return(
        <Box fill="horizontal">
            <Table>
                <TableBody>
                    {/* First row - 'Open Today' */}
                    <TableRow >
                        <TableCell pad={{horizontal: 'small'}}>
                            <Text weight='bold'>{strings.openToday}</Text>
                        </TableCell>
                        <TableCell flex='grow' pad={{horizontal: 'small'}}>
                            <Text weight='bold' textAlign="end">
                                {openingHours[today].formattedOpeningHours}
                            </Text>
                        </TableCell>
                    </TableRow>

                    {/* Other rows - 1 for each day */}
                    {strings.days.map(day => {
                        if (day === today) return renderRowToday(day, openingHours[day]);
                        else return renderRowOtherDay(day, openingHours[day]);
                    })}
                </TableBody>
            </Table>
        </Box>
    );

    /**
     * Renders the row with text in bold
     * @param openingDay {object} Day name and opening hours for this day
     */
    function renderRowToday(day: Day, openingDay: OpeningInfo) {
        return(
            <TableRow key={today}>
                <TableCell pad={{horizontal: 'small'}}><Text weight='bold'>{translate(translation, day.toLowerCase(), day)}</Text></TableCell>
                <TableCell flex='grow' pad={{horizontal: 'small'}}>
                {openingDay.open ? (
                    <Text weight='bold' textAlign="end">{openingDay.formattedOpeningHours}</Text>
                ) : (
                   <Text weight='bold' textAlign="end">Closed</Text>
                )}
                </TableCell>
            </TableRow>
        );
    }

    /**
     * Renders the row with faint text (grey)
     * @param day {string} The day to render
     * @param openingDay {object} Day name and opening hours for this day
     */
    function renderRowOtherDay(day: Day, openingDay: OpeningInfo) {
        return(
            <TableRow key={day}>
                <TableCell pad={{horizontal: 'small'}}><Text color="grey">{translate(translation, day.toLowerCase(), day)}</Text></TableCell>
                <TableCell flex='grow' pad={{horizontal: 'small'}}>
                {openingDay.open ? (
                    <Text weight='bold' textAlign="end" color="grey">{openingDay.formattedOpeningHours}</Text>
                ) : (
                    <Text weight='bold' textAlign="end" color="grey">Closed</Text>
                )}
                </TableCell>

            </TableRow>
        );
    }
}

type OpeningHoursTableProps = {
    openingHours: {
        Monday: OpeningInfo,
        Tuesday: OpeningInfo,
        Wednesday: OpeningInfo,
        Thursday: OpeningInfo,
        Friday: OpeningInfo,
        Saturday: OpeningInfo,
        Sunday: OpeningInfo
    }
}

type OpeningInfo = {
    open?: boolean,
    opensAt?: string,
    closesAt?: string,
    formattedOpeningHours: string
}

type Day = 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' | 'Sunday';

OpeningHoursTable.propTypes = {
    openingHours: PropTypes.shape({
        Monday: PropTypes.shape({
            open: PropTypes.bool,
            opensAt: PropTypes.string,
            closesAt: PropTypes.string,
            formattedOpeningHours: PropTypes.string.isRequired
        }).isRequired,
        Tuesday: PropTypes.shape({
            open: PropTypes.bool,
            opensAt: PropTypes.string,
            closesAt: PropTypes.string,
            formattedOpeningHours: PropTypes.string.isRequired
        }).isRequired,
        Wednesday: PropTypes.shape({
            open: PropTypes.bool,
            opensAt: PropTypes.string,
            closesAt: PropTypes.string,
            formattedOpeningHours: PropTypes.string.isRequired
        }).isRequired,
        Thursday: PropTypes.shape({
            open: PropTypes.bool,
            opensAt: PropTypes.string,
            closesAt: PropTypes.string,
            formattedOpeningHours: PropTypes.string.isRequired
        }).isRequired,
        Friday: PropTypes.shape({
            open: PropTypes.bool,
            opensAt: PropTypes.string,
            closesAt: PropTypes.string,
            formattedOpeningHours: PropTypes.string.isRequired
        }).isRequired,
        Saturday: PropTypes.shape({
            open: PropTypes.bool,
            opensAt: PropTypes.string,
            closesAt: PropTypes.string,
            formattedOpeningHours: PropTypes.string.isRequired
        }).isRequired,
        Sunday: PropTypes.shape({
            open: PropTypes.bool,
            opensAt: PropTypes.string,
            closesAt: PropTypes.string,
            formattedOpeningHours: PropTypes.string.isRequired
        }).isRequired,
    }).isRequired
};

export default OpeningHoursTable;
