import adaptor from './adaptors';
import { AppResponse } from './response';
import { Cart, GiftCard } from '../modules/cart/types'
import { PaymentMethods } from '../modules/billing/types';
import { DeliveryMethods, StoreCoordinates, Store, StoreDetails, DeliveryMethodAvailability } from '../modules/delivery/types';
import { RootState } from '../store/reducers';
import { Coordinates } from './mesh-sdk/carts';

export type CartUpdates = {
    delivery?: {
        deliveryMethodID: string,
    } | null,
    deliveryAddress?: {
        id: string
    },
    billingAddress?: {
        id: string
    },
    customer?: {
        id: string
    },
    storeID?: string,
    nominatedCollector?: {
        firstName: string,
        lastName: string,
        phone: string,
    },
    deliveryQuotationAddress?: {
        locale: string,
        postcode: string
    },
    giftcards?: GiftCard[],
    proposedDeliveryLocale?:string,
};

export interface CartParams {
    proposedDeliveryLocale?:string,
    unavailableForGiftCardStatus?: number,
    channel?: string,
    locale?: string,
    debug?: string
}

export interface ZeroPaymentResponse {
    isPaid: boolean,
    orderID: string, // this is in fact an 'invoiceID'
    orderClientID: string, // the actual 'order id'
}

export interface CartService {
    getCart: (cartId: string) => Promise<AppResponse<Cart>>,
    updateCart: (cartId: string, updates: CartUpdates, locale?: string) => Promise<AppResponse<Cart>>,
    makeZeroPayment: (cartId: string) => Promise<AppResponse<ZeroPaymentResponse>>,
    getCartPaymentMethods: (cartId: string) => Promise<AppResponse<PaymentMethods>>,
	getCartDeliveryMethodAvailability: (cartId: string, location: string, paymentMethodID: string) => Promise<AppResponse<DeliveryMethodAvailability>>,
    getCartDeliveryMethods: (cartId: string, search: string | StoreCoordinates, locale: string, partialPostcode? : boolean) => Promise<AppResponse<DeliveryMethods>>,
    getStoresByLocation: (cartId: string, location: string | Coordinates, units: string, from?: number, max?: number) => Promise<AppResponse<StoreDetails>>,
    getPostOfficesByLocation: (cartId: string, location: string | Coordinates, units: string, from?: number, max?: number) => Promise<AppResponse<Store[]>>,
    deleteProductFromCart: (cartId: string, sku: string) => Promise<AppResponse<void>>,
}


function init(state: RootState){
    const service = adaptor('mesh', 'carts', state) as CartService;
    return service;
}

export default init;
