import { AxiosInstance, AxiosResponse } from 'axios';
import { LanguageConfig } from './types';

const CHECKOUT_FRAMEWORK = 'checkout';


export interface PhrasesResponse {
    phrases: {
        [key: string]: string,
    },
}

function languageFrameworks (client: AxiosInstance, languageConfig: LanguageConfig) {
    async function getPhrases(languageCode: string): Promise<AxiosResponse<PhrasesResponse>> {
        let phrasesResponse = await client.get<PhrasesResponse>(`/frameworks/${CHECKOUT_FRAMEWORK}/lang/${languageCode}`);
        return phrasesResponse;
    }

    return {
        getPhrases,
    };
}

export type LanguageFrameworksService = ReturnType<typeof languageFrameworks>;

export default languageFrameworks;
