import React, { useContext, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/reducers";
import PropTypes from "prop-types";
import { debounce } from "throttle-debounce";
import { Box, Text, TextInput, Anchor } from "grommet";
import { FormSearch } from "grommet-icons";
import { addresses } from "../../services";
import { AddressRetrieved } from "../../services/addresses";
import LoadingIcon from "../LoadingIcon/LoadingIcon";
import styled from "styled-components";
import { Location } from '../../components/Icon/index';
import { ThemeContext } from 'styled-components';
import { CustomTheme } from "../../theme";
import { translate } from '../../modules/translation';
/**
 * LocationInput is a basic text input in which the user will type a location (city, postcode).
 * Events are fired on each key stroke, and on click on the compass icon if one is set.
 *
 * @param inputId {string} Text to pass as HTML ID tag to the input element
 * @param defaultLocation {string} The input field will be pre-filled with this value.
 * @param onChange {function} Callback for each key stroke of the user in the input.
 * @param onClickLocation {function} Callback for a click on the compass icon.
 * @param onSelect {function} Callback with details on the address from the selected 'retrieve' suggestion
 * @param maxLength {string} Bigger text will be truncated
 * @param hasCompassIcon {boolean} Whether or not showing a clickable compass icon
 * @param title {string} Text to be displayed as title
 * @param placeholder {string} Placeholder text in the input field
 * @param suggestionsEnabled {boolean} Whether or not to attempt to load address suggestions
 * @param locale {string} 2-letter code, used by the predict service to know which country to get predicted addresses from.
 * @param isLoading {bool} whether the list of locations is currently being retreived
 */
function LocationInput({
    inputId,
    defaultLocation,
    onChange,
    onClickLocation,
    onSelect,
    maxLength = 30,
    hasCompassIcon=true,
    title,
    placeholder,
    suggestionsEnabled = false,
    locale,
    isLoading,
    disableGeolocate
}: LocationInputProps) {
	const theme: CustomTheme = useContext(ThemeContext);
	const delivery = useSelector((state: RootState) => state.delivery);
    const translation = useSelector((state: RootState) => state.translation);

    if (!title) {
        if(disableGeolocate){
            title = translate(translation, 'findStoreNearMe', 'Find A Store Near Me');
        }else{
            title = translate(translation, 'findStore', 'Find A Store');
        }
    }
    if (!placeholder){
        placeholder = translate(translation, 'typeAddressOrPostcode', 'Start typing address or postcode');
        if(disableGeolocate){
            placeholder = translate(translation, 'typePostcode', 'Enter your postcode');
        }
    }

    // Truncate the default location string if its length > max.
    if (defaultLocation)
        defaultLocation = defaultLocation.substring(0, maxLength);

    const [location, setLocation] = React.useState(defaultLocation || "");
    const [charCount, setCharCount] = React.useState(location.length);
    const [suggestions, setSuggestions] = React.useState<
        { label?: React.ReactNode; value?: any }[] | undefined
		>();

    const StyledAnchor = styled(Anchor)`
      text-decoration: none;
      width: 100%;
      padding: 15px;
      border-bottom: solid 1px #e7e8e9;
      &:hover {
        background-color: #f2f2f2;
        text-decoration: none;
      }
    `;

	useEffect(() => {
		if (delivery.selectedMethod.typeCategory === 'clickAndCollect') {
			setLocation('');
		}
		
	}, [delivery.selectedMethod])


    /**
     * Callback executed when the user types into an address finder input.
     * Loads suggestions if suggestionsEnabled is set.
     * Does nothing if not enough characters are typed.
     * @param event Contains the string typed
     */
    function onTextInputChange(event: React.ChangeEvent<HTMLInputElement>) {
        const newLocation = event.target.value;
        if (newLocation.length <= maxLength) {
            setCharCount(newLocation.length);
            setLocation(newLocation);

            if (suggestionsEnabled) {
                updateSuggestions(newLocation);
            }
        }
        if (onChange) onChange(event);
    }

    const MULTI_CALLS_DELAY = 500; // TODO use config
    /**
     * Refreshes the list of displayed suggestions after a delay.
     * @param inputText Text typed in the field
     * @param lastId ID of the selected suggestion group
     */
    const updateSuggestions = useMemo(
        () =>
            debounce(
                MULTI_CALLS_DELAY,
                async (inputText: string, lastId?: string) => {
                    let MINIMUM_CHAR_INPUT = 4;
                    if ( locale === 'ie' ) {
                        MINIMUM_CHAR_INPUT = 3;
                    }
                    if (inputText.length < MINIMUM_CHAR_INPUT) {
                        setSuggestions(undefined);
                        return;
                    }
                    const result = await addresses().getPredictedAddresses(
                        inputText,
                        lastId,
                        locale
                    );
                    setSuggestions(
                        result.data.map((item: any) => {
                            return {
                                query: inputText,
                                label: item.addressString,
                                value: item.ID,
                                type: item.rel,
                            };
                        })
                    );
                }
            ),
        [locale]
    );

    /**
     * Callback executed when the user selects a suggestion.
     * Update the suggestions if a group of suggestion is selected.
     * Send back the full address to the parent component if a unique suggestion is selected.
     * @param event Contains details on the selected suggestion
     */
    async function onSelectSuggestion(suggestion: any) {
        const suggestionType: "select" | "refine" = suggestion.type;
        const suggestionId: string = suggestion.value;
        const suggestionQuery: string = suggestion.query;

        if (suggestionType === "select") {
            const response = await addresses().getRetrievedAddress(
                suggestionId,
                locale
            );
            const fullAddress = response.data;

            if (onSelect) onSelect(fullAddress, inputId ?? "LocationInput");
        } else {
            // 'refine' suggestion
            updateSuggestions(suggestionQuery, suggestionId);
        }
    }

    function renderCompass() {
        if (hasCompassIcon && isLoading) {
            return <LoadingIcon color="black" fill={false} />;
        } else if (hasCompassIcon) {
            return (
                <Location
                    aria-label="Compass"
                    onClick={(event) => {
                        if (onClickLocation) onClickLocation(event);
                    }}
                />
            );
        }
    }

    return (
        <Box direction="column" fill="horizontal">
            {/* Title row */}
            <Box direction="row" margin="none" pad={{vertical: 'small'}}>
                <Text weight={theme?.config?.stepFontWeight ?? 'bold'} size="large">{title}</Text>
                <Box flex="grow" align="end">
                    <Text>{`${charCount}/${maxLength}`}</Text>
                </Box>
            </Box>

            {/* Form field row */}
            <Box
                direction="row"
                align="center"
                pad={{horizontal: 'small', vertical: 'none'}}
                round="none"
                border={{
                    side: "all",
                }}
            >
                <FormSearch />
                <TextInput
                    placeholder={placeholder}
                    id={inputId}
                    value={location}
                    size ="medium"
                    type="text"
                    plain={true}
                    onChange={onTextInputChange}
                    onSelect={onSelectSuggestion}
                    autoComplete="new-password"
                    disabled={isLoading}
                />
                {disableGeolocate === false ?
                    renderCompass()
                    : ''
                }
            </Box>
            {suggestions && (
                <Box direction="column" align="start" fill="horizontal"  border="all" key="locationInput">
                    {suggestions.map((suggestion, index) => (
                        <StyledAnchor
                            weight="normal"
                            size="small"
                            onClick={() =>
                                onSelectSuggestion(suggestion)
                            }
                            key={index}
                        >
                            {suggestion.label}
                        </StyledAnchor>
                    ))}
                </Box>
            )}
        </Box>
    );
}

type LocationInputProps = {
    inputId?: string,
    defaultLocation?: string,
    maxLength?: number,
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onClickLocation?: (event: React.MouseEvent) => void,
    hasCompassIcon?: boolean,
    title?: string,
    placeholder?: string,
    suggestionsEnabled?: boolean,
    onSelect?: (fullAddress: AddressRetrieved, inputId: string) => void,
    locale?: string,
    isLoading?: boolean,
    disableGeolocate?: boolean
};

LocationInput.propTypes = {
    inputId: PropTypes.string,
    defaultLocation: PropTypes.string,
    maxLength: PropTypes.number,
    onChange: PropTypes.func,
    onClickLocation: PropTypes.func,
    hasCompassIcon: PropTypes.bool,
    title: PropTypes.string,
    placeholder: PropTypes.string,
    onSelect: PropTypes.func,
    locale: PropTypes.string,
    isLoading: PropTypes.bool,
};

export default LocationInput;
