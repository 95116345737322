import { Address } from '../customer/types';
import { Product } from '../cart/types';
import { Customer } from '../customer/types';

// constant types
export const UPDATE = 'checkout/order/UPDATE';

// action types
interface UpdateOrder {
    type: typeof UPDATE,
    payload: Order,
}

export type OrderActionTypes = UpdateOrder;

// other
export type MoneyAmount = {
    amount: string,
    currency: string,
};

export type Order = {
    ID: string,
    isFirstOrderView?: boolean,
    invoiceID: string,
    status: string,
    isDiscounted: boolean,
    estimatedDeliveryDate?: {
        hasRange: boolean,
        deliveryDate?: string,
        startDate?: string,
        endDate?: string,
    },
    channel: string,
    dateAdded?: string,
    courier?: {
        name: string,
    }
    paymentDetails: {
        gateway: string,
        method: string,
        isCaptured: boolean,
        amountCaptured: MoneyAmount,
    },
    products: Product[],
    productsSubtotal: MoneyAmount,
    deliveryMethod: {
        name: string,
    },
    paymentMethod: string,
    deliveryTotal: MoneyAmount,
    giftcardsSubtotal: MoneyAmount,
    total: MoneyAmount,
    grandTotal: MoneyAmount,
    balanceToPay: MoneyAmount,
    totalSavings: MoneyAmount,
    customer: Customer,
    billingAddress: Address,
    deliveryAddress: Address,
    isDeliverToStore: boolean,
    appliedDiscountCodes: MoneyAmount,
    selectedDeliveryDate: {
        date: string,
        timezone_type: number,
        timezone: string,
    } | null,
    selectedDeliveryTimeSlotStart: string | null,
    selectedDeliveryTimeSlotEnd: string | null,
    hasFailed: boolean,
    orderShippingType: string
    orderShippingProvider?: string
};
