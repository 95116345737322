import { RootState } from '../../store/reducers';
import { DefaultApi as SessionsClient } from '../sessions-sdk';
import { SessioApiGansFpG9nO9jOs as SessionBody } from '../sessions-sdk/models'
import { SessionService } from '../session';


function session(client: SessionsClient) : SessionService{

    async function getSession(id: string){
        const response = await client.sessionGet(id);
        return response;
    }

    async function updateSession(id: string, body: SessionBody){
        const response = await client.sessionPut(body, id);
        return response;
    }

    return {
        getSession,
        updateSession
    }

}

export default function sessionsAdaptor(state: RootState){
    const sessionsClient = new SessionsClient();
    return {
        session: session(sessionsClient)
    }
}


