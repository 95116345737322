import { Box, Text } from 'grommet'
import React, { InputHTMLAttributes } from 'react'

export default function SquareCheckBox(props: InputHTMLAttributes<HTMLInputElement>) {
  const { name, id, checked, onChange, disabled } = props;

  return (
    <Box direction='row'>
      <input disabled={disabled} type="checkbox" id={id} value={name} checked={checked} onChange={onChange} />
      <label><Text size="small">{name}</Text></label>
    </Box>
  )
}