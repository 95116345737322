import adaptor from './adaptors';
import { AppResponse } from './response';
import { DeliveryCountry } from '../modules/delivery/types';
import { RootState } from '../store/reducers';

export interface DeliveryCountriesService {
    getDeliveryCountries: () => Promise<AppResponse<DeliveryCountry[]>>
}


function init(state: RootState){
    const service = adaptor('mesh', 'deliveryCountries', state) as DeliveryCountriesService;
    return service;
}

export default init;
