import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, ResponsiveContext, Anchor } from 'grommet';
import { RootState } from '../../store/reducers';
import { getAdyen } from '../../modules/config';
import {
    getSelectedSavedCard, getSavedCards,
    getShowCardSelectorView, updateShowCardSelectorView,
    getSelectedPaymentMethod,
} from '../../modules/billing';
import { AppThunkDispatch } from '../../modules/thunk';
import { translate } from '../../modules/translation';
import AdyenCardPaymentForm from '../AdyenCardPaymentForm/AdyenCardPaymentForm';
import AdyenReturningCardForm from '../AdyenReturningCardForm/AdyenReturningCardForm';
import PaymentCards from '../PaymentCards/PaymentCards';


/**
 * Component to be inserted into the list provided by PaymentMethodSelector.
 * Will show a full adyen payment form, a CVV-only form for returning adyen users, or a UI to select between saved cards.
 */
function PaymentMethodSelectorAdyenCardItem() {
    const dispatch: AppThunkDispatch = useDispatch();

    const size = React.useContext(ResponsiveContext);
    const isDesktop = size !== 'small';
    const config = useSelector((state: RootState) => state.config);
    const billing = useSelector((state: RootState) => state.billing);
    const translation = useSelector((state: RootState) => state.translation);

    const adyenConfig = getAdyen(config);
    const savedCards = getSavedCards(billing);
    const selectedSavedCard = getSelectedSavedCard(billing);
    const showCardSelector = getShowCardSelectorView(billing);
    const selectedPaymentMethod = getSelectedPaymentMethod(billing);

    const adyenComponentConfig = {
        adyenCardCheckoutConfig: {
            locale: adyenConfig.locale,
            environment: adyenConfig.environment,
            clientKey: selectedPaymentMethod?.additionalData?.paymentServiceDetails?.clientKey,
        }
    };

    const hasSavedCards = savedCards.length > 0;

    const strings = {
        useOtherCard: translate(translation, 'useOtherCard', 'Use another card'),
    };

    // The adyen forms won't show if adyen card is not the selected payment method.
    if (selectedPaymentMethod.id !== 'CARD') return null;

    return (
        <Box>
            { showCardSelector && isDesktop
                ?
                    <Box margin={{top: 'medium'}}>
                        <PaymentCards />
                    </Box>
                :
                    <>
                        { selectedSavedCard.name
                            ?
                                // Form asking for a CVV only
                                <AdyenReturningCardForm
                                    config={adyenComponentConfig}
                                />

                            :
                                // Form asking for all card data
                                <AdyenCardPaymentForm
                                    config={adyenComponentConfig}
                                />
                        }
                        { hasSavedCards &&
                            <Box direction="row" gap="medium" margin={{'top': 'medium'}}>
                                <Anchor size="medium" 
                                    onClick={() => { dispatch(updateShowCardSelectorView(true));
                                }}>
                                    {strings.useOtherCard}
                                </Anchor>
                            </Box>
                        }
                    </>
            }
        </Box>
    );
}

export default PaymentMethodSelectorAdyenCardItem;
