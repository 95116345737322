import merge from 'deepmerge';
import { CustomTheme } from '.';
import jdsportsukTheme from "./index";


const theme: CustomTheme = {
    config: {
        displayName: "JD Sports AT",
        showAddress1And2OnSameLine: true,
        faviconLogo: 'jdsportsuk/favicon.ico'
    },
};

const jdsportsatTheme: CustomTheme = merge(jdsportsukTheme, theme);

export default jdsportsatTheme;