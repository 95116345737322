import React, { ChangeEvent, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, RadioButton, Text, ResponsiveContext } from "grommet";
import { ThemeContext } from 'styled-components';
import { CustomTheme } from '../../theme';
import Separator from '../Separator/Separator';
import TimeSlotSelector from '../TimeSlotSelector/TimeSlotSelector';

/**
 * List of radio buttons. Each option can optionally display a subtitle below
 * and/or extra data (text or other component) on the right side.
 * 
 * @param options {array} Array of objects containing all the data for each option (label, value, etc.)
 * @param defaultSelected {string} The value of the object to be selected by default. If not defined, no component will be selected.
 * @param onOptionChange {function} Callback for the change event.
 */
function OptionList({ options, defaultSelected, onOptionChange, extras } : OptionListProps) {
    // Pre-select the item that has the value of defaultSelected if defined.
    const [selected, setSelected] = React.useState(defaultSelected || null);

    //to update state on prop change
    useEffect(() => {
        setSelected(defaultSelected || null)
        // eslint-disable-next-line
    }, [defaultSelected]);

    const theme: CustomTheme = React.useContext(ThemeContext);
    const bulletSize: string = theme?.radioButton?.size ?? '24px';
    const bulletGapBreakpoint: string = theme?.radioButton?.gap ?? 'small';
    const size = React.useContext(ResponsiveContext);
    const isDesktop = size !== 'small';
    const iconWidth = extras ?
                        isDesktop ? '85px' : '55px' :
                        '';

    return (
        (!extras ?
            <Box
                direction="column"
                align="start"
                fill="horizontal"
            >
                {options.map((option, index) => (
                    <Box key={option.id} fill="horizontal">
                        <Box direction="column" flex={true} pad={{right: 'medium'}}  >
                            <Box fill={true} direction="row" align="start">
                                <RadioButton
                                    name='radio'
                                    value={option.value}
                                    checked={selected === option.value}
                                    onChange={(event) => {
                                        setSelected(option.value);
                                        if (onOptionChange) onOptionChange(event);
                                    }}
                                    label={option.label}
                                />
                                <Box flex="grow" width={iconWidth} align="end">
                                    {option.extra}
                                </Box>
                            </Box>
                            <Box margin={{left: bulletGapBreakpoint}} pad={{left: bulletSize}} flex={false}>
                                <Text size="small">{option.description} {option.link} </Text>
                            </Box>
                        </Box>

                        {/* Time Slot selector if applicable. Will show only if the option is selected */}
                        { option.hasTimeSlots && (selected === option.value) &&
                        <Box
                            margin={{left: bulletGapBreakpoint, top: 'medium'}}
                            pad={{left: bulletSize}}
                        >
                            <TimeSlotSelector methodId={option.id} />
                        </Box>
                        }

                        {/* Add a separation line between items */}
                        {index !== options.length-1 && <Separator margin={{vertical: "medium"}} />}
                    </Box>
                ))}
            </Box> :
                <Box
                    direction="column"
                    align="start"
                    fill="horizontal"
                >
                    {options.map((option, index) => (
                        <Box key={option.id} fill="horizontal">
                            <Box fill={true} direction="row" align="start">
                                <Box direction="column" flex={true} pad={{vertical: 'medium'}}  >
                                    <RadioButton
                                        name='radio'
                                        value={option.value}
                                        checked={selected === option.value}
                                        onChange={(event) => {
                                            setSelected(option.value);
                                            if (onOptionChange) onOptionChange(event);
                                        }}
                                        label={option.label}
                                    />
                                    <Box margin={{left: bulletGapBreakpoint}} pad={{left: bulletSize}} flex={false}>
                                        <Text size="small">{option.description} {option.link} </Text>
                                    </Box>
                                    {option.disclosure ?
                                        <Box margin={{left: bulletGapBreakpoint}} pad={{left: bulletSize, top:"small"}} flex={false}>
                                            <Text size="small">{option.disclosure} {option.disclosureLink}</Text>
                                        </Box> :''
                                    }
                                    {option.extraDisclosure ?
                                        <Box margin={{left: bulletGapBreakpoint}} pad={{left: bulletSize, top:"small"}} flex={false}>
                                            <Text size="small">{option.extraDisclosure}</Text>
                                        </Box> :''
                                    }
                                </Box>
                                <Box flex={false} width={iconWidth} style={{'padding':'10px'}}>
                                    {option.extra}
                                </Box>
                            </Box>


                            {/* Time Slot selector if applicable. Will show only if the option is selected */}
                            { option.hasTimeSlots && (selected === option.value) &&
                            <Box
                                margin={{left: bulletGapBreakpoint, top: 'medium'}}
                                pad={{left: bulletSize}}
                            >
                                <TimeSlotSelector methodId={option.id} />
                            </Box>
                            }

                            {/* Add a separation line between items */}
                            {index !== options.length-1 && <Separator margin={{vertical: "0px"}} />}
                        </Box>
                    ))}
                </Box>

        )
    );
}

type OptionListProps = {
    options: OptionTypes[],
    defaultSelected?: string
    onOptionChange?: (event: ChangeEvent<HTMLInputElement>) => void,
    size?: string,
    extras?: boolean
};

export type OptionTypes = {
    id: string,
    label: string,
    value: string,
    description?: string | React.ReactNode,
    disclosure?: string | React.ReactNode,
    extra?: string | React.ReactNode,
    hasTimeSlots?: boolean,
    link?:  React.ReactNode,
	size?: string,
	needsConfirmation?: boolean,
	disclosureLink?: React.ReactNode,
    extraDisclosure?: string | React.ReactNode,
};

OptionList.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        description: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.node
        ]),
        extra: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.node
        ]),
        hasTimeSlots: PropTypes.bool,
    })),
    defaultSelected: PropTypes.string,
    onOptionChange: PropTypes.func,
    size : PropTypes.string
};

export default OptionList;
