import { RootState } from '../store/reducers';
import { Phrases } from '../modules/translation/types';
import adaptor from './adaptors';
import { AppResponse } from './response';

export interface LanguageFrameworksService {
    getPhrases: (languageCode: string) => Promise<AppResponse<Phrases>>,
}

function init(state: RootState){
    const service = adaptor('language', 'languageFrameworks', state) as LanguageFrameworksService;
    return service;
}

export default init;
