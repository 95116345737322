
/**
 * Converts a date into a long localised string for display.
 * @param isoDate eg. '2021-05-26'
 * @param locale As expected by the Intl JS module, eg. 'en-GB'.
 * @returns Localised long date like: 'Wednesday, 26 May 2021'
 */
export function localisedDate(isoDate: string, locale: string) {
    isoDate = dateStringToIso(isoDate);
    const dayFormatter = new Intl.DateTimeFormat(locale, {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });

    return dayFormatter.format(new Date(isoDate));
}

/**
 * Converts a date into a string with ordinal day, month and year for display.
 * @param isoDate eg. '2021-11-01'
 * @param locale As expected by the Intl JS module, eg. 'en-GB'.
 * @returns Localised date like: '11th November 2021'
 */

export function formatScheduledDate(isoDate: string, locale: string) {
    isoDate = dateStringToIso(isoDate);
    const dayFormatter = new Intl.DateTimeFormat(locale, {
        year: 'numeric',
        month: 'long',
    });
    const remainingDate = dayFormatter.format(new Date(isoDate));
    const formattedDate = getOrdinalNumber(isoDate, locale) + " " + remainingDate;

    return formattedDate;
}

/**
 * Converts a date into a string with hours and minute for display.
 * @param isoDate eg. '2021-11-01 10:23:30'
 * @param locale As expected by the Intl JS module, eg. 'en-GB'.
 * @returns time in hours:minutes format : '10:23'
 */
export function localisedTime(isoDate: string, locale: string) {
    isoDate = dateStringToIso(isoDate);
    const timeFormatter = new Intl.DateTimeFormat(locale, {
        hour: "numeric",
        minute: 'numeric'

    });
    return timeFormatter.format(new Date(isoDate));
}

/**
 * Converts a day into a string with ordinal number format.
 * @param isoDate eg. '2021-11-01 10:23:30'
 * @param locale As expected by the Intl JS module, eg. 'en-GB'.
 * @returns ordinal number of a day : 1st
 */
function getOrdinalNumber(isoDate: string, locale: string) {
    isoDate = dateStringToIso(isoDate);
    const pluralRules = new Intl.PluralRules(locale, {
        type: 'ordinal'
    })
    const suffixes = {
        'zero': 'th',
        'one': 'st',
        'two': 'nd',
        'few': 'rd',
        'many': 'th',
        'other': 'th',
    }
    const convertToOrdinal = (number: number) => `${number}${suffixes[pluralRules.select(number)]}`;
    const date = new Date(isoDate).getDate();
    return convertToOrdinal(date);
}

/**
 * The space in eg. '2021-11-01 10:23:30' is invalid ISO 8601 and should be the character 'T'.
 * Chrome fixes that on the fly but eg. Safari on IOS will throw an error due to the date being malformed.
 * @param date eg. '2021-11-01 10:23:30'
 * @return ISO date string, eg. '2021-11-01T10:23:30'
 */
function dateStringToIso(date: string) {
    return date.replace(' ', 'T');
}