import React,{useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/reducers';
import { AppThunkDispatch } from '../../modules/thunk';
import { getNotificationDetails } from '../../modules/notification';
import { tracking } from '../../services';

/**
 * Error Update
 */
function ErrorDetails() {
    const fullState = useSelector((state: RootState) => state);
    const dispatch: AppThunkDispatch = useDispatch();
    const notificationDetails = getNotificationDetails(fullState, dispatch);
    useEffect(() => {
        if(notificationDetails.message !== '')
        {
        tracking()?.trackError(notificationDetails.code, notificationDetails.message); 
        }
    // eslint-disable-next-line
    }, [notificationDetails.message]);

    return <></>;

}

export default ErrorDetails;
