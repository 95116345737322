import merge from 'deepmerge';
import{ CustomTheme } from '.';
import choiceTheme from './choicegb';
import './index';

const theme: CustomTheme = {
    config: {
        displayName: "Hipstore",
        headerLogo: '/Logo-Hipstore-Navigation.png',
        fontSrc: 'https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700&display=swap',
        titleFontWeight: 'bold',
        stepFontWeight: 'bold',
    },

    global: {
        font: {
            family: '"Open Sans", sans-serif',
        },
    },

    button: {
        border: {
            radius: '0px'
        },
        extend: 'height: 48px; font-weight: bold',
    },

    radioButton: {
        font: {
            weight: 'bold',
        }
    },

};

const hipstoreTheme: CustomTheme = merge(choiceTheme, theme);

export default hipstoreTheme;
