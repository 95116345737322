import { session as sessionModule } from 'store2';
import store from '../../store';
import { updateCart } from '../../modules/cart';
import { Cart } from '../../modules/cart/types';
import { updateDeliveryModule } from '../../modules/delivery';
import { Delivery } from '../../modules/delivery/types';
import { updateBillingModule } from '../../modules/billing';
import { Billing } from '../../modules/billing/types';
import { updateCustomer } from '../../modules/customer';
import { Customer } from '../../modules/customer/types';
import { updateConfig } from '../../modules/config';
import { Config } from '../../modules/config/types';
import { StateName } from '../../store/reducers';
import { initialState } from '../../modules/test-utils';
import { updateTranslationModule } from '../../modules/translation';
import { Translation } from '../../modules/translation/types';

class LocalStore {
    /**
     * Set an entry to the browser session
     * @param key
     * @param value
     * @param overwrite
     */
    set(key: string, value: string, overwrite?: boolean) {
            sessionModule.set(key, value, overwrite);
    }

    /**
     * Retrieve a value from the browser session
     * @param key Retrieve an existing value
     * @param deleteAfter Whether to delete the entry after retrieval
     */
    get(key: string, deleteAfter = false) {
        const value = sessionModule.get(key);
        if (value && deleteAfter) sessionModule.remove(key);
        return value;
    }

    /**
     * Save one, several or all states to the browser session.
     * @param reducers The state(s) to save (name or array of names). If not provided, the entire store will be saved.
     */
    saveStatesToStorage(reducers?: StateName | StateName[]) {
        if (reducers === undefined) {
            const allReducerNames = Object.keys(initialState) as StateName[];
            allReducerNames.forEach(reducer => {
                sessionModule.set(`state.${reducer}`, store.getState()[reducer]);
            });
        }
        else if (Array.isArray(reducers)) {
            reducers.forEach(reducer => {
                sessionModule.set(`state.${reducer}`, store.getState()[reducer]);
            });
        }
        else {
            sessionModule.set(`state.${reducers}`, store.getState()[reducers]);
        }
        
    }

    /**
     * Get all the reducers' data temporarily in the browser session back to Redux.
     * @param deleteAfter Whether to clear all reducer data from the browser session afterwards.
     */
    populateStateFromStorage(deleteAfter = false) {
        const reducers = Object.keys(initialState) as StateName[];
        let retrievedObject: any;
        reducers.forEach(reducer => {
            retrievedObject = sessionModule.get(`state.${reducer}`);
            if (retrievedObject) {
                switch (reducer){
                    case 'cart':
                        store.dispatch(updateCart(retrievedObject as Cart));
                        break;
                    case 'delivery':
                        store.dispatch(updateDeliveryModule(retrievedObject as Delivery));
                        break;
                    case 'customer':
                        store.dispatch(updateCustomer(retrievedObject as Customer));
                        break;
                    case 'billing':
                        store.dispatch(updateBillingModule(retrievedObject as Billing));
                        break;
                    case 'config':
                        store.dispatch(updateConfig(retrievedObject as Config));
                        break;
                    case 'translation':
                        store.dispatch(updateTranslationModule(retrievedObject as Translation));
                        break;
                }
            }
        });

        if (deleteAfter) {
            reducers.forEach(reducer => {
                sessionModule.remove(`state.${reducer}`);
            });
        }
    }
}

const localStore = new LocalStore();
export default localStore;
