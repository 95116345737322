import React from 'react';
import { AxiosResponse } from 'axios';
import {
    NotificationState, NotificationActionTypes, NotificationDetails, NotificationExtraData,
    DialogDetails, DialogExtraData,
    UPDATE_NOTIFICATION, UPDATE_DIALOG, UPDATE_GENERIC_ERROR, CLEAR_NOTIFICATION,
} from './types';
import { deleteProductsFromCart, getGeorestrictedProducts, reloadCartContents } from '../cart';
import { getProducts, getCart } from '../cart';
import { RootState } from '../../store/reducers';
import { AppThunkDispatch } from '../thunk';
import { getReturnURL } from '../config';
import { Text, Box } from 'grommet';
import Separator from '../../components/Separator/Separator';
import { MeshError } from '../../services/mesh-sdk/client';
import { getCurrentUrl, getReturnUrlFromCurrentHostName } from '../../lib/url/url';
import { local as browserLocalStorage } from 'store2';
import { translate } from '../translation';
import { Translation } from '../translation/types';
import { getDeliveryAddress, getMethods as getDeliveryMethods, loadDeliveryMethods } from '../delivery';

// Reducer

export const initialState: NotificationState = {
    notificationCode: '',
    notificationData: {},
    genericErrorType: '',
    genericErrorData: undefined,
    dialogCode: '',
    dialogData: {},
}

export default function reducer(state = initialState, action: NotificationActionTypes) {
    switch (action.type) {
        case UPDATE_NOTIFICATION: {
            return {
                ...state,
                notificationCode: action.payload.notificationCode,
                notificationData: action.payload.notificationData,
            };
        }
        
        case CLEAR_NOTIFICATION: {
            return {
                ...state,
                notificationCode: '',
                notificationData: {},
                genericErrorType: '',
                genericErrorData: undefined,
            };
        }

        case UPDATE_DIALOG: {
            return {
                ...state,
                dialogCode: action.payload.dialogCode,
                dialogData: action.payload.dialogData,
            };
        }

        case UPDATE_GENERIC_ERROR: {
            return {
                ...state,
                genericErrorType: action.payload.genericErrorType,
                genericErrorData: action.payload.genericErrorData,
            };
        }

        default: {
            return state;
        }
    }
}

// Selectors
export const getGenericErrorMessage = (state: NotificationState) => {
    switch (state.genericErrorType) {
        case 'mesh_error': {
            const errorData = state.genericErrorData as AxiosResponse<MeshError>;
            const status = errorData.status ?? ''; // HTML response code from Axios
            const code = errorData.data.error?.details ?? '';
            const message = errorData.data.error?.message ?? 'Unknown error';
            return `Mesh error. ${status} ${code} ${message}`;
        }

        default:
            return '';
    }
};

export const getNotificationCode = (state: NotificationState) => state.notificationCode;
export const getNotificationDetails = (fullState: RootState, dispatch: AppThunkDispatch): NotificationDetails => {
    const notificationCode = fullState.notification.notificationCode;
    const translation = fullState.translation;
    
    if (notificationCode === '') return {type: 'error',code:'', message: ''};

    switch(notificationCode) {
        case "invalid_credentials": {
            const returnURL = getReturnURL(fullState.config);
            const forgottenPasswordHref = `${returnURL}myaccount/forgot-password/`;
            return {
                type: 'error',
                message: translate(translation, 'err_invalid_credentials', "Sorry we could not log you in. The credentials supplied were not recognised."),
                anchorText: translate(translation, 'forgotPassword', "Forgotten your Password?"),
                href: forgottenPasswordHref,
                code : notificationCode
            };
        }

        case "login_error": {
            return {
                type: 'error',
                message: translate(translation, 'err_login_error', "An error occurred when logging you in."),
                code : notificationCode
            };
        }

        case "PASSWORD_ERROR_4": {
            return {
                type: 'error',
                message: "Password must contain atleast one letter.",
                code : notificationCode
            };
        }
        
        case "PASSWORD_ERROR_6": {
            return {
                type: 'error',
                message: "Password cannot contain the same character 3 times consecutively",
                code : notificationCode
            };
        }

        case "CUSTOMER_POST_2":
        case "CUSTOMER_POST_4": {
            const returnURL = getReturnURL(fullState.config);
            const forgottenPasswordHref = `${returnURL}myaccount/forgot-password/`;
            return {
                type: 'error',
                message: translate(translation, 'emailAlreadyRegistered', "This email address is already registered"),
                anchorText: translate(translation, 'forgotPassword', "Forgotten your password?"),
                href: forgottenPasswordHref,
                code : notificationCode
            };
        }

        case "ADD_PASSWORD_TO_GUEST": {
            return {
                type: 'error',
                message: translate(translation, 'err_ADD_PASSWORD_TO_GUEST', "There was a problem creating your account. Your order was still successful."),
                code : notificationCode
            };
        }

        case "CART_DELIVERY_OPTIONS_GET_2":
        case "CART_DELIVERY_OPTIONS_GET_4":
        case "CART_PROPOSED_ADDRESS_2":
        case "CART_PROPOSED_ADDRESS_4": {
            return {
                type: 'error',
                message: translate(translation, 'err_invalidPostcode', "The postcode entered is invalid. Please enter a valid postcode to continue"),
                code : notificationCode
            };
        }

        case "address_invalid_error": {
            return {
                type: 'error',
                message: translate(translation, 'err_address_invalid_error', "The address details you have entered are invalid, please try again."),
                code : notificationCode
            };
        }

        case "address_error": {
            return {
                type: 'error',
                message: translate(translation, 'err_address_error', "The address couldn't be updated."),
                code : notificationCode
            };
        }

        case "delivery_method_update_error": {
            return {
                type: 'error',
                message: translate(translation, 'err_delivery_method_update_error', "An error occurred when updating your delivery method."),
                code : notificationCode
            };
        }

        case "PRODUCT_DETAILS_GET_3": {
            return {
                type: 'error',
                message: translate(translation, 'err_PRODUCT_DETAILS_GET_3', "The product selected is unavailable."),
                code : notificationCode
            };
        }

        case "express_payment_error": {
            return {
                type: 'error',
                message: translate(translation, 'err_express_payment_error', "The payment method couldn't be initialised."),
                code : notificationCode
            };
        }

        case "redirect_page_unknown_method": {
            return {
                type: 'error',
                message: translate(translation, 'err_redirect_page_unknown_method', "This payment method is invalid."),
                code : notificationCode
            };
        }

        case "redirect_page_redirection_error": {
            return {
                type: 'error',
                message: translate(translation, 'err_redirect_page_redirection_error', "Redirection error."),
                code : notificationCode
            };
        }

        case "error_payment_delivery_page": {
            return {
                type: 'error',
                message: translate(translation, 'err_error_payment_delivery_page', "An error occurred when the payment method was being initialised."),
                code : notificationCode
            };
        }

        case "error_loading_payment_methods": {
            return {
                type: 'error',
                message: translate(translation, 'err_error_loading_payment_methods', "An error occurred when loading the payment methods."),
                code : notificationCode
            };
        }

        case "error_cant_get_saved_adyen_cards": {
            return {
                type: 'error',
                message: translate(translation, 'err_error_cant_get_saved_adyen_cards', "Sorry we couldn’t load your saved card(s). Please enter your payment details."),
                code : notificationCode
            };
        }

        case "delete_product_from_cart_error": {
            return {
                type: 'error',
                message: translate(translation, 'err_delete_product_from_cart_error', "An error occured when deleting items from the cart."),
                code : notificationCode
            };
        }
        case "cart_load_error": {
            return {
                type: 'error',
                message: translate(translation, 'err_cart_load_error', "An error occured when loading the cart."),
                code : notificationCode
            };
        }

        case "remove_georestricted_products_notification": {
            const removeDropshipProducts = fullState.notification.notificationData?.removeDropshipProducts ?? false;
            const removeRestrictedProducts = fullState.notification.notificationData?.removeRestrictedProducts ?? false;
            const skusToRemove = fullState.notification.notificationData?.skusToRemove ?? [];
            const cart = getCart(fullState.cart);
            const config = fullState.config;
            const allProducts = getProducts(fullState.cart);
            const productsToRemove = getGeorestrictedProducts(config, cart, removeDropshipProducts, removeRestrictedProducts);
            skusToRemove.forEach(sku => {
                const skuInList = productsToRemove.some(product => product.trackingSKU === sku);
                if (!skuInList) {
                    const productWithSku = allProducts.filter(product => product.trackingSKU === sku);
                    productsToRemove.push(productWithSku[0]);
                }
            });

            const itemCounter = `${productsToRemove.reduce((count, product) => count + product.quantity, 0)} of ${allProducts.reduce((count, product) => count + product.quantity, 0)}`;

            return {
                type: 'error',
                message: translate(
                    translation,
                    'err_anatwine_no_international_delivery',
                    `${itemCounter} items in your bag are unavailable for international delivery. Please remove before continuing.`,
                    {number: itemCounter},
                ),
                anchorText: translate(translation, 'removeItems', "Remove Items"),
                onClick: () => {
                    dispatch(clearNotification());
                    dispatch(updateDialog('remove_georestricted_products_dialog', { removeDropshipProducts, removeRestrictedProducts, skusToRemove}));
                },
                hideNotificationCloseButton: true,
                code : notificationCode
            };
        }

        case "anatwine_items_removed_info": {
            const deletionsCount = fullState.notification.notificationData?.deletedProductsCount ?? '';
            return {
                type: 'info',
                message: translate(translation,
                    'anatwine_items_removed_info',
                    `${deletionsCount} item(s) have been removed from your basket`,
                    {number: deletionsCount},
                   
                ),
                code : notificationCode
            };
        }

        case "error_no_delivery_method_for_delivery_option": {
            return {
                type: 'error',
                message: translate(translation, 'err_error_no_delivery_method_for_delivery_option', "No delivery method found for the selected option."),
                code : notificationCode
            };
        }

    
        case "stores_not_found": {
            return {
                type: 'error',
                message: translate(translation, 'err_stores_not_found', "Unable to find any stores for the area specified."),
                code : notificationCode
            };
        }   
        
        // PAYMENT METHODS ERRORS
        case "payment_method_initialise_error": {
            const method = fullState.notification.notificationData?.methodName ?? '';
            if (!method) {
                console.error('payment_method_initialise_error - method name not provided');
            }

            let message = '';
            if (method.toUpperCase() === 'CARD') {
                message = translate(translation, 'err_cardPaymentInitialiseError', `The card payment couldn't be initialised.`);
            }
            else {
                message = translate(translation, 'err_methodInitialiseError', `${method} couldn't be initialised.`, { method });
            }
            return {
                type: 'error',
                message,
                code : notificationCode
            };
        }

        case "payment_method_finalise_error": {
            const method = fullState.notification.notificationData?.methodName ?? '';
            if (!method) {
                console.error('payment_method_finalise_error - method name not provided');
            }

            let message = '';
            if (method.toUpperCase() === 'CARD') {
                message = translate(translation, 'err_cardPaymentFinaliseError', `The card payment couldn't be finalised.`);
            }
            else {
                message = translate(translation, 'err_paymentMethodError', `An error occurred during the payment with ${method}.`, { method });
            }
            return {
                type: 'error',
                message,
                code : notificationCode
            };
        }

        // Payment Service
        case "payment_service_error": {
            const code = fullState.notification.notificationData?.code;
            const message = fullState.notification.notificationData?.message;
            return {
                type: 'error',
                message: translate(translation,
                    'err_payment_service_error',
                    `Error with the payment service. Code ${code}: ${message}`,
                    {code: code ?? '', message: message ?? ''}
                ),
                code : notificationCode
            };
        }

        case "transaction_capture_failed_incorrect_amount": {
            return {
                type: 'error',
                message: translate(translation, 'err_transaction_capture_failed_incorrect_amount', "Transaction capture failed, incorrect amount detected"),
                code : notificationCode
            };
        }

        case "payment_gateway_error": {
            return {
                type: 'error',
                message: translate(translation, 'err_payment_gateway_error', "Transaction capture failed, error with the payment gateway"),
                code : notificationCode
            };
        }
        
        case 'CC_05' : { // for incorrect cvv in molpay 
            return {
                type: 'error',
                message: translate(translation, 'err_incorrectCVV', "Incorrect CVV. Please input the correct CVV and try again"),
                code : notificationCode
            };
        }

        case 'CC_54' : { //for expired card details in molpay 
            return {
                type: 'error',
                message: translate(translation, 'err_expiredCard', "The card used for the transaction has expired"),
                code : notificationCode
            };
        }

        case 'CC_14' : { //for invalid card number in molpay
            return {
                type: 'error',
                message: translate(translation, 'err_invalidCard', "Please enter valid card number"),
                code : notificationCode
            };
        }

        // Adyen Errors
        case "adyen_payment_refused": {
            const refusalCode = fullState.notification.notificationData?.code;
            const refusalMessage = fullState.notification.notificationData?.message;
            const customMessage = refusalCode ? getAdyenFailureCustomMessage('refusal', refusalCode, translation) : undefined;
            return {
                type: 'error',
                message: customMessage ?? translate(translation,
                    'err_adyen_payment_refused',
                    `Adyen payment refused. Code ${refusalCode}: ${refusalMessage}`,
                    {code: refusalCode ?? '', message: refusalMessage ?? ''}
                ),
                code : notificationCode
            };
        }
        
        case "adyen_error": {
            const code = fullState.notification.notificationData?.code;
            let message = fullState.notification.notificationData?.message;
            const customMessage = code ? getAdyenFailureCustomMessage('error', code, translation) : undefined;
            return {
                type: 'error',
                message: customMessage ?? translate(translation,
                    'err_adyen_error',
                    `Error with Adyen gateway. Code ${code}: ${message}`,
                    {code: code ?? '', message: message ?? ''},
                ),
                code : notificationCode
            };
        }

        case "adyen_payment_cancelled": {
            return {
                type: 'error',
                message: translate(translation, 'err_adyen_payment_cancelled', "The payment has been cancelled."),
                code : notificationCode
            };
        }

        //Apple Pay Errors
        case "apple_pay_error_unsupported_device": {
            return {
                type: 'error',
                message: translate(translation, 'err_apple_pay_error_unsupported_device', "Apple Pay is not available on this device."),
                code : notificationCode
            };
        }

        // ShopBack payment Errors
        case "shopback_invalid_phone_number": {
            return {
                type: 'error',
                message: translate(translation, 'err_shopback_invalid_phone_number', "Your phone number is not compatible with ShopBack. Please choose another payment method or enter a different phone number."),
                code : notificationCode
            };
        }

        // Paypal Errors
        case "paypal_delivery_methods_error": {
            return {
                type: 'error',
                message: translate(translation, 'err_delivery_methods_loading_error', "An error occurred when loading the delivery methods."),
                code : notificationCode
            };
        }

        //Clearpay errors
        case "cpap_hpp_close_error":
        case "cpex_hpp_close_error": {
            return {
                type: 'error',
                message: translate(translation, 'err_paymentClosedUnexpectedly', "The payment window closed unexpectedly."),
                code : notificationCode
            };
        }
        
        case "error_with_billing_address": { 
            return {
                type: 'error',
                message: 'An error occurred with billing address, firstname and lastname are required.',
                code : notificationCode
            };
        }

        //Braintree errors
        case "braintree_fields_invalid": {
            return {
                type: 'error',
                message: translate(translation, 'validation_someFieldsInvalid', "One or more fields are invalid."),
                code : notificationCode
            };
        }

        case "gift_card_error": {
            return {
                type: 'error',
                message: translate(translation, 'err_gift_card_error', "An error occurred when adding the gift card to the cart."),
                code : notificationCode    
            };
        }
        case "delete_card_error": {
            return {
                type: 'error',
                message: translate(translation, 'err_delete_card_error', "An error occurred when deleting the card."),
                code : notificationCode
            };
        }

        default: {
            return {
                type: 'error',
                message: translate(translation, 'err_defaultError', "An error occurred."),
                code : notificationCode
            };
        }
    }
};

export const getDialogCode = (state: NotificationState) => state.dialogCode;

export const getDialogDetails = (fullState: RootState, dispatch: AppThunkDispatch): DialogDetails | undefined => {
    const dialogCode = fullState.notification.dialogCode;
    const translation = fullState.translation;
    
    const getDialogBackToCart = (titleKey: string, titleFallbackTranslation: string, bodyKey: string, bodyFallbackTranslation: string) => {
        const returnURL = getReturnURL(fullState.config);
        const cartURL = `${returnURL}cart`;
        const clearCartCache = async () => {
            await dispatch(reloadCartContents());
            window.location.replace(cartURL);
        };
        return {
            title: translate(translation, titleKey, titleFallbackTranslation),
            showCloseIcon: false,
            body: React.createElement(
                Text, {},
                translate(translation, bodyKey, bodyFallbackTranslation),
            ),
            buttons: [{
                label: translate(translation, 'ok', "Ok"),
                onClick: clearCartCache,
            }]
        };
    }
    
    switch(dialogCode) {
        case 'out_of_stock_return_to_cart': {
            return getDialogBackToCart('currentlyOOS', 'Currently Out of Stock', 'itemsOOSPleaseRemove', 'Oops, looks like one or more of your items is now out of stock. Please remove these items before continuing with checkout.');
        }
        case 'unavailable_for_gift_cards': {
            return getDialogBackToCart('unavailableProducts', 'Unavailable For Gift Card', 'removeUnavailableForGiftGard', 'One of the products in the cart cannot be purchased using GIFT CARD. Please remove these items before continuing with checkout.');
        }
        case 'account_creation_success': {
            return {
                title: translate(translation, 'succeeded', "Succeeded"),
                showCloseIcon: false,
                body: React.createElement(
                    Text, {},
                    translate(translation, 'accountCreationSuccess', "Your account has been created"),
                ),
                buttons: [{
                    label: translate(translation, 'ok', "Ok"),
                    onClick: () => {
                        dispatch(clearDialog());
                    }
                }]
            };
        }

        case 'remove_georestricted_products_dialog': {
            const removeDropshipProducts = fullState.notification.dialogData?.removeDropshipProducts ?? false;
            const removeRestrictedProducts = fullState.notification.dialogData?.removeRestrictedProducts ?? false;
            const skusToRemove = fullState.notification.dialogData?.skusToRemove ?? [];
            const cart = getCart(fullState.cart);
            const config = fullState.config;
            const productsToRemove = getGeorestrictedProducts(config, cart, removeDropshipProducts, removeRestrictedProducts);
            const allProducts = getProducts(cart);

            skusToRemove.forEach(sku => {
                const skuInList = productsToRemove.some(product => product.trackingSKU === sku);
                if (!skuInList) {
                    const productWithSku = allProducts.filter(product => product.trackingSKU === sku);
                    productsToRemove.push(productWithSku[0]);
                }
            });

            const numberItemsToRemove = productsToRemove.reduce((count, product) => count + product.quantity, 0);
            const totalItems = allProducts.reduce((count, product) => count + product.quantity, 0);

            const descriptionText = React.createElement(Text, {},
                translate(translation, '',
                    `${numberItemsToRemove} of ${totalItems} items in your bag are unavailable for international delivery. Please remove these items before continuing with checkout.`,
                    {number: numberItemsToRemove, total: totalItems},
                )
            );

            // @ts-ignore
            const separator = React.createElement(Separator, {margin: {vertical: 'medium'}});
            
            const anatwineProducts = React.createElement(Box, null, productsToRemove.map(product => {
                const nbsp = '\xa0'; // non-breaking space
                const sizeString = product.optionValues?.Size ?? null;

                const size = translate(translation, 'productDetailsSize', `Size:${nbsp}${sizeString}`, {data: sizeString});
                const code = translate(translation, 'productDetailsCode', `Code:${nbsp}${product.parentSKU}`, {data: product.parentSKU});
                const qty = translate(translation, 'productDetailsQty', `Qty:${nbsp}${product.quantity}`, {data: product.quantity});
                let detailsString = sizeString ? `${size} |${nbsp}` : '';
                detailsString += `${code} |${nbsp}${qty}`;

                return React.createElement(
                    Box, {key: product.SKU},
                        React.createElement(Box, {key: product.SKU},
                            separator,
                            React.createElement(Box, null,
                                React.createElement(Text, {weight: "bold"}, product.name),
                                React.createElement(Text, null, detailsString)
                            )
                        )
                );
            }));

            const description = React.createElement(Box, null, descriptionText, anatwineProducts, separator);

            const removeItems = async () => {
                const cartWithProductsRemoved = await dispatch(deleteProductsFromCart(productsToRemove));
                if (!cartWithProductsRemoved || cartWithProductsRemoved.products.length < 1) {
                    // No product left in the cart, we redirect to the cart page.
                    dispatch(clearDialog());
                    window.location.href = `${getReturnURL(fullState.config)}cart`;
                }
                else {
                    // The removal of some products might bring new delivery methods
                    const selectedAddress = getDeliveryAddress(fullState.delivery);
                    if (getDeliveryMethods(fullState.delivery).length <= 0 && selectedAddress.postcode) {
                        await dispatch(loadDeliveryMethods({location: selectedAddress.postcode}, selectedAddress.locale));
                    }
                    dispatch(updateNotification('anatwine_items_removed_info', {deletedProductsCount: numberItemsToRemove}));
                    dispatch(clearDialog());
                }
            };

            return {
                title: translate(translation, 'itemsNoInternationalDelivery', "Item(s) unavailable for international delivery"),
                showCloseIcon: true,
                body: description,
                buttons: [{
                    label: translate(translation, 'removeItemsFromBag', "Remove item(s) from bag"),
                    onClick: removeItems,
                }]
            };
        }

        case 'anatwine_learn_more': {

            const fulfilmentData = fullState.notification.dialogData?.content;
            const title = fullState.notification.dialogData?.title;
            const props = {
                dangerouslySetInnerHTML: {
                    __html: fulfilmentData,
                },
            };
            const description = React.createElement('div', props);

            return {
                title: title,
                showCloseIcon: true,
                body: description,
                buttons: []
            };
        }

        case 'klarna_express_cancelled': {
            const backURL = getReturnURL(fullState.config);
            const cartURL = `${backURL}cart`;
            const clearCartCache = async () => {
                await dispatch(reloadCartContents());
                window.location.replace(cartURL);
            };
            const description = translate(translation, 'err_errorWhenPayingNoMoneyTaken', "There was an error when attempting to pay, please try again. No money has been taken and your cart is still intact.");
            return {
                title: translate(translation, 'error', "Error"),
                showCloseIcon: false,
                body: React.createElement(Text, {}, description),
                buttons: [{
                    label: translate(translation, 'ok', "Ok"),
                    onClick: clearCartCache,
                }]
            };
        }
        
        case 'adyen_refusal_code_refused': {
            const description = translate(translation, 'err_errorWhenPayingNoMoneyTaken', "There was an error when attempting to pay, please try again. No money has been taken and your cart is still intact.");
            return {
                title: translate(translation, 'error', "Error"),
                showCloseIcon: false,
                body: React.createElement(Text, {}, description),
                buttons: [{
                    label: translate(translation, 'ok', "Ok"),
                    onClick: () => {
                        dispatch(clearDialog());
                        if (window.location.pathname.includes('/paymentredirect')) {
                            // Without setTimeout the redirection happens before the clearDialog has any effect.
                            setTimeout(() => {
                                window.location.href = `${getCurrentUrl()}/delivery`;
                            }, 800);
                        }
                    },
                }],
            };
        }

        case 'adyen_issue_on_redirection': {
            const refusalCode = fullState.notification.dialogData?.title;
            const refusalMessage = fullState.notification.dialogData?.content;
            const customMessage = refusalCode ? getAdyenFailureCustomMessage('refusal', refusalCode, translation) : undefined;
            const genericMessage = translate(translation,
                'err_adyen_payment_refused',
                `Adyen payment refused. Code ${refusalCode}: ${refusalMessage}`,
                {code: refusalCode ?? '', message: refusalMessage ?? ''},
            );
            let description = customMessage ?? genericMessage;
            if (!refusalCode && refusalMessage) {
                description = refusalMessage;
            }

            return {
                title: translate(translation, 'error', "Error"),
                showCloseIcon: false,
                body: React.createElement(Text, {}, description),
                buttons: [{
                    label: translate(translation, 'ok', "Ok"),
                    onClick: () => {
                        dispatch(clearDialog());
                        if (window.location.pathname.includes('/paymentredirect')) {
                            // Without setTimeout the redirection happens before the clearDialog has any effect.
                            setTimeout(() => {
                                window.location.href = `${getCurrentUrl()}/delivery`;
                            }, 800);
                        }
                    },
                }],
            };
        }
        case 'session_expired': {
            const returnHost = browserLocalStorage.get('host');
            const returnUrlFromHost = getReturnUrlFromCurrentHostName();
            let returnUrl = '';

            if (returnHost) {
                returnUrl = `https://${returnHost}/cart`;
            }
            else if (returnUrlFromHost) {
                returnUrl = `${returnUrlFromHost}/cart`;
            }

            const description = returnUrl
                ? translate(translation, 'err_sessionExpiredCartIntact', "Unfortunately your checkout session has expired, but your cart is still intact.")
                : translate(translation, 'err_sessionExpired', "Unfortunately your checkout session has expired.");

            const buttons = returnUrl
                ?
                    [{
                        label: translate(translation, 'returnToBasket', "Return to basket page"),
                        onClick: () => {
                            window.location.href = returnUrl;
                        },
                    }]
                : [];

            return {
                title: translate(translation, 'error', "Error"),
                showCloseIcon: false,
                body: React.createElement(Text, {}, description),
                buttons,
            };
        }


        default: {
            return undefined;
        }
    }
}

export const getDialogExtraData = (state: NotificationState) => state.dialogData;

// Action Creators

export function updateNotification(notificationCode: string, notificationData?: NotificationExtraData): NotificationActionTypes {

    return {
        type: UPDATE_NOTIFICATION,
        payload: {
            notificationCode: notificationCode,
            notificationData: notificationData ?? {},
        },
    }
}

export function updateGenericError(genericErrorType: string, genericErrorData: any): NotificationActionTypes {

    return {
        type: UPDATE_GENERIC_ERROR,
        payload: {
            genericErrorType,
            genericErrorData,
        },
    }
}

export function clearNotification(): NotificationActionTypes {
    return {
        type: CLEAR_NOTIFICATION,
    };
}

export function updateDialog(dialogCode: string, dialogData?: DialogExtraData): NotificationActionTypes {

    return {
        type: UPDATE_DIALOG,
        payload: {
            dialogCode: dialogCode,
            dialogData: dialogData ?? {},
        },
    }
}

export function clearDialog(): NotificationActionTypes {
    return {
        type: UPDATE_DIALOG,
        payload: {
            dialogCode: '',
            dialogData: {},
        },
    }
}



// Helper functions

/**
 * Return a custom message for a specific Adyen error or refusal
 * @param failureType Error or refusal
 * @param code {stringified number} The Adyen error code or refusal code, see:
 * https://docs.adyen.com/development-resources/refusal-reasons
 * https://docs.adyen.com/development-resources/error-codes
 * @returns A string with the custom message if one was found, or undefined if nothing found.
 */
export function getAdyenFailureCustomMessage(failureType: 'error'|'refusal', code: string, translation: Translation) {
    switch (failureType) {
        case 'refusal': {
            switch (code) {
                case '20': {
                    return translate(translation, 'err_fraud', "Unfortunately, there has been a problem processing your payment. Please email order.authorisations@jdplc.com, quoting the email address that you attempted to place the order within the subject line and we will be happy to assist in getting this resolved.");
                }
                case '24': { // Incorrect CVV
                    return translate(translation, 'err_incorrectCVV', "Incorrect CVV. Please input the correct CVV and try again.");
                }
                case '11':{ // 3D Not Authenticated
                    return translate(translation, 'err_3dsFailure', "3D secure authentication has failed, you may want to check that your billing address is the same as the registered address for you debit / credit card")
                }

                default:
                    return;
            }
        }

        case 'error': {
            switch (code) {
                case '2': { // Invalid expire Date
                    return translate(translation, 'err_transaction_refused', "The transaction was refused");
                }
                case '6': { // Expired Date
                    return translate(translation, 'err_transaction_expired', "The card used for the transaction has expired");
                }
                case '8':{
                    return translate(translation, 'err_invalidCard',"Please enter valid card number.")
                }
                case '20': {
                    return translate(translation, 'err_fraud', "Unfortunately, there has been a problem processing your payment. Please email order.authorisations@jdplc.com, quoting the email address that you attempted to place the order within the subject line and we will be happy to assist in getting this resolved.");
                }
                case '24': { // Incorrect CVV
                    return translate(translation, 'err_incorrectCVV', "Incorrect CVV. Please input the correct CVV and try again.");
                }
                default:
                    return;
            }
        }

        default:
            return;
    }
}