import { Address } from '../customer/types';

// constant types
export const UPDATE_DELIVERY_MODULE = 'checkout/delivery/UPDATE_DELIVERY_MODULE';
export const SELECT_OPTION = 'checkout/delivery/SELECT_OPTION';
export const UPDATE_METHODS = 'checkout/delivery/UPDATE_METHODS';
export const SELECT_METHOD = 'checkout/delivery/SELECT_METHOD';
export const SELECT_METHOD_BY_DISPLAY_NAME = 'checkout/delivery/SELECT_METHOD_BY_DISPLAY_NAME';
export const SELECT_TIME_SLOT_ON_METHOD = 'checkout/delivery/SELECT_TIME_SLOT_ON_METHOD';
export const UPDATE_STORES = 'checkout/delivery/UPDATE_STORES';
export const UPDATE_POST_OFFICES = 'checkout/delivery/UPDATE_POST_OFFICES';
export const SELECT_STORE = 'checkout/delivery/SELECT_STORE';
export const UPDATE_SELECTED_STORE_ID = 'checkout/delivery/UPDATE_SELECTED_STORE_ID';
export const UPDATE_NON_JD_STORE_ID = 'checkout/delivery/UPDATE_NON_JD_STORE_ID';
export const SELECT_STORE_DETAILED_VIEW = 'checkout/delivery/SELECT_STORE_DETAILED_VIEW';
export const UPDATE_COLLECTION_DETAILS = 'checkout/delivery/UPDATE_COLLECTION_DETAILS';
export const UPDATE_SHOW_DESTINATION_VIEW = 'checkout/delivery/UPDATE_SHOW_DESTINATION_VIEW';
export const UPDATE_ARCHIVED_HOME_DELIVERY_POSTCODE = 'checkout/delivery/UPDATE_ARCHIVED_HOME_DELIVERY_POSTCODE';
export const UPDATE_DELIVERY_ADDRESS = 'checkout/delivery/UPDATE_DELIVERY_ADDRESS';
export const UPDATE_DELIVERY_COUNTRIES = 'checkout/delivery/UPDATE_COUNTRIES';
export const UPDATE_IS_GEORESTRICTION_ERROR = 'checkout/delivery/UPDATE_IS_GEORESTRICTION_ERROR';
export const UPDATE_IS_NOT_YOU_COLLECTING = 'checkout/delivery/UPDATE_IS_NOT_YOU_COLLECTING';
export const CLEAR_DELIVERY_METHODS = 'checkout/delivery/CLEAR_DELIVERY_METHODS';

// action types
interface updateDeliveryModule {
    type: typeof UPDATE_DELIVERY_MODULE,
    payload: Delivery,
};

interface selectOption {
    type: typeof SELECT_OPTION,
    payload: string
};
interface clearDeliveryMethods {
    type: typeof CLEAR_DELIVERY_METHODS,
    methods: { home:[], clickAndCollect:[] },
};


interface updateStores {
    type: typeof UPDATE_STORES,
    payload: {
        stores: Store[],
        append: boolean,
        totalNumberOfStores?: number
    }
};

interface updatePostOffices {
    type: typeof UPDATE_POST_OFFICES,
    payload: {
        postOffices: Store[],
        append: boolean,
        totalNumberOfPostOffices?: number
    }
};

interface updateMethods {
    type: typeof UPDATE_METHODS,
    payload: {
        methods: DeliveryMethods,
        preselect: boolean,
        customerPreferredMethodId?: string,
		needsConfirmation?: boolean,
    }
};

interface selectMethod {
    type: typeof SELECT_METHOD,
    payload: string
};

interface selectDeliveryMethodByDisplayName {
    type: typeof SELECT_METHOD_BY_DISPLAY_NAME,
    payload: string
};

interface selectTimeSlotOnDeliveryMethod {
    type: typeof SELECT_TIME_SLOT_ON_METHOD,
    payload: {
        methodId: string,
        day: string,
        start: string,
        end: string
    },
};

interface updateCollectionDetails {
    type: typeof UPDATE_COLLECTION_DETAILS,
    payload: CollectionDetails
};

interface updateDeliveryAddress {
    type: typeof UPDATE_DELIVERY_ADDRESS,
    payload: Address
};

interface updateDeliveryCountries {
    type: typeof UPDATE_DELIVERY_COUNTRIES,
    payload: DeliveryCountry[]
};

interface selectStore {
    type: typeof SELECT_STORE,
    payload: string
};

interface updateSelectedStoreId {
    type: typeof UPDATE_SELECTED_STORE_ID,
    payload: string
};

interface updateNonJdStoreId {
    type: typeof UPDATE_NON_JD_STORE_ID,
    payload: {
        storeId: string,
        newId: string,
    }
}

interface selectStoreForDetailedView {
    type: typeof SELECT_STORE_DETAILED_VIEW,
    payload: string
};

interface updateArchivedHomeDeliveryPostcode {
    type: typeof UPDATE_ARCHIVED_HOME_DELIVERY_POSTCODE,
    payload: string
};
interface updateIsGeorestrictedError {
    type: typeof UPDATE_IS_GEORESTRICTION_ERROR,
    payload: boolean
};
interface updateIsNotYouCollecting {
    type:  typeof UPDATE_IS_NOT_YOU_COLLECTING,
    payload: boolean
}

export type DeliveryActionTypes = selectOption | updateStores | selectStore | updateSelectedStoreId |
    updateNonJdStoreId | selectStoreForDetailedView | updateDeliveryModule |
    updateCollectionDetails | updateMethods | selectMethod | selectDeliveryMethodByDisplayName |
    selectTimeSlotOnDeliveryMethod | updateDeliveryCountries |
    updateDeliveryAddress | updatePostOffices | updateArchivedHomeDeliveryPostcode |
    updateIsGeorestrictedError | updateIsNotYouCollecting | clearDeliveryMethods;

export type DeliveryOption = {
    label: string,
    value: DeliveryOptionValue,
    selected: boolean
};

export type DeliveryMethods = {
    [key in DeliveryOptionValue]: DeliveryMethod[]
};

export type DeliveryMethodType = '' | 'D' | 'S' | 'C+C' | 'POLC' | 'COLLECT+' | 'V' | 'Deliveroo';
export type DeliveryMethodTypeCategory = 'home' | 'clickAndCollect';

export type DeliveryMethod = {
    ID: string,
    type: DeliveryMethodType,
    typeCategory: DeliveryMethodTypeCategory,
    displayName: string,
    description: string,
    price: {
        amount: string,
        currency: string,
    },
    slots?: DeliveryMethodSlot[],
    selected: boolean,
	needsConfirmation?: boolean
};

export type DeliveryMethodSlot = {
    date: string, // eg. "2021-05-13"
    start: string, // eg."10:00:00"
    end: string, // eg. "11:00:00",
    displayTime: string, // eg. "10:00 - 11:00",
    type: string // eg. "TIME SLOT",
    selected: boolean,
    greenSlot: boolean,
    recommended: boolean,
}

export type DeliveryMethodAvailability = {
	stores?: Store[],
	available: boolean,
	reason?: string,
}

export type DeliveryOptionValue = 'home' | 'clickAndCollect';

export type StoreCoordinates = {
    latitude: string,
    longitude: string
}

export type StoreAddress = {
    address1: string,
    address2: string | null,
    address3: string | null,
    town: string,
    county: string | null,
    postcode: string,
    country: string,
    locale: string
};

export type Store = {
    ID: string,
    clientId?: string,
    name: string,
    locationType?: CandCLocation,
    address: StoreAddress,
    latitude: string,
    longitude: string,
    phone: string | null,
    openingHours: {
        [key in WeekDays]: OpeningTimes
    },
    distance: string,
    distanceUnits: string,
    isIdUpdated?: boolean,
    fullFasciaName?: string,
    href?: string,
    fasciaCode?: string,
};

export type StoreDetails = {
    stores: Store[],
    totalNumberOfStores: number,
}

// The types of C&C locations that we support delivery to
export enum CandCLocation {
    JD,
    PostOffice,
}

export type WeekDays = 'Monday'|'Tuesday'|'Wednesday'|'Thursday'|'Friday'|'Saturday'|'Sunday';

export type OpeningTimes = {
    open: boolean,
    opensAt: string,
    closesAt: string,
    formattedOpeningHours: string
};

export type CollectionDetails = {
    firstName: string,
    lastName: string,
    phone?: string
    notYouCollectingFirstName: string,
    notYouCollectingLastName: string,
    notYouCollectingPhone: string
}

export type DeliveryCountry = {
    code: string,
    name: string,
    default: boolean,
    requiresState: boolean,
    pcaPredictSupported: boolean
}

export type PostOfficeOpeningHours = {
    openTime: string,
    closeTime: string,
};

export type PostOfficeLocation = {
    name: string,
    address: {
        address1: string,
        address2: string | null,
        town: string,
        county: string,
        postcode: string,
    }
    openingHours: {
        monday: PostOfficeOpeningHours,
        tuesday: PostOfficeOpeningHours,
        wednesday: PostOfficeOpeningHours,
        thursday: PostOfficeOpeningHours,
        friday: PostOfficeOpeningHours,
        saturday: PostOfficeOpeningHours,
        sunday: PostOfficeOpeningHours,
    },
    lat: string,
    lng: string
    distance: string,
    distanceUnits: string
};

export type DeliveryLayer = '' | 'deliveryAddress' | 'billingAddress' | 'editAddress' | 'clickAndCollect' | 'addressBook';

export interface Delivery {
    options: DeliveryOption[],
    methods: DeliveryMethods,
    selectedMethod: DeliveryMethod,
    stores: Store[],
    totalNumberOfStores: number,
    totalNumberOfPostOffices: number,
    postOffices: Store[],
    selectedStore: Store,
    storeForDetailedView: Store,
    showStoreDetailedView: boolean,
    archivedHomeDeliveryPostcode: string,
    collectionDetails: CollectionDetails,
    address: Address,
    countries: DeliveryCountry[],
    isGeorestrictionError: boolean,
    isNotYouCollecting: boolean,
};
