import merge from 'deepmerge';
import { SecurityOutline } from '../components/Icon';
import jdTheme, { CustomTheme } from '.';

const theme: CustomTheme = {
    config: {
        displayName: "Footpatrol",

        headerLogo: '/Logo-Footpatrol-Navigation.png',
        headerLogoSize: {height: 'auto', width: '100px'},
        headerShowLogo: true,
        headerBackgroundColor: 'white',
        faviconLogo: 'footpatrolgb/favicon.ico',

        fontSrc: 'https://fonts.googleapis.com/css2?family=Carrois+Gothic&display=swap',

        icons: {
            securityIcon: SecurityOutline,
        },
        titleFontWeight: 'bold',
        stepFontWeight: 'bold',
    },

    global: {
        font: {
            family: '"Carrois Gothic", sans-serif',
        },
    },

    button: {
        border: {
            radius: '0px'
        },
        extend: 'height: 48px; font-weight: bold',
    },

    radioButton: {
        font: {
            weight: 'bold',
        }
    },

};

const footpatrolTheme: CustomTheme = merge(jdTheme, theme);

export default footpatrolTheme;
