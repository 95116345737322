import adaptor from './adaptors';
import { AppResponse } from './response';
import { BillingCountry } from '../modules/billing/types';
import { RootState } from '../store/reducers';

export interface BillingCountriesService {
    getBillingCountries: () => Promise<AppResponse<BillingCountry[]>>
}


function init(state: RootState){
    const service = adaptor('mesh', 'billingCountries', state) as BillingCountriesService;
    return service;
}

export default init;
