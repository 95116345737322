import { AxiosInstance, AxiosResponse } from 'axios';
import { MeshConfig } from './types';

type MoneyAmount = {
    amount: string,
    currency: string
}

export interface PaymentResponse {
    ID: string,
    href: string,
    invoiceID: string,
    invoiceClientID: string,
    externalReference?: null,
    cartID: string,
    cart: {id: string},
    receiptNo?: null,
    receiptText?: null,
    amount: MoneyAmount,
    gateway: string,
    isPaid: boolean,
    isCaptured: boolean,
    status: string,
    isOffline: boolean,
    order?: string,
    dateAdded: string,
    dateModified: string,
    response: {
        type: string,
        message?: string,
    },
    transactions: {
        ID: string,
        date: string,
        type: string,
    }[],
}

function payment(client: AxiosInstance, meshConfig: MeshConfig) {
    
    const { channel } = meshConfig;
    
    /**
     * Tell Platform that the payment is completed.
     * @param paymentID ID of the Mesh 'Payment' object
     * @param locale
     */
    async function completePayment(paymentID: string, locale: string): Promise<AxiosResponse<PaymentResponse>> {
        const queryParams = {
            params: {
                unavailableForGiftCardStatus: 1,
                locale,
                channel
            }
        };
        const payload = {
            '3DSecure': {
                merchantURL: 'http://localhost:3000/', // TODO make a config service
                completionURL: 'http://localhost:3000/confirm',
            }
        };
        
        let paymentResponse = await client.post<PaymentResponse>(`/payments/${paymentID}/complete`, payload, queryParams)
            .then((response) => {
                return response;
            }).catch((err) => {
                return err;
            });
        
        return paymentResponse;
    }
    
    async function getMeshPayment(paymentID: string, locale: string): Promise<AxiosResponse<PaymentResponse>> {
        const queryParams = {
            params: {
                locale,
                channel,
            }
        };
        
        return await client.get<PaymentResponse>(`/payments/${paymentID}`, queryParams);
    }
    
    return {
        completePayment,
        getMeshPayment,
    };
}
export type PaymentService = ReturnType<typeof payment>;
export default payment;