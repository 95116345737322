import merge from 'deepmerge';
import { CustomTheme } from '.';
import baseTheme from './basegb';
import './index';

const theme: CustomTheme = {
    config: {
        displayName: "Oi Polloi",
        headerLogo: '/Logo-OiPolloi-Navigation.svg',
        headerLogoSize: {height: 'auto', width: '72'},
    },
};

const oipolloiTheme: CustomTheme = merge(baseTheme, theme);

export default oipolloiTheme;
