import {
    LanguageEnvironment,
    LanguageEnvironmentConfigs,
    LanguageClientConfig,
} from './types';

const config = {
    timeout: 10000,
}

const environmentConfigs: LanguageEnvironmentConfigs = {
    uat: {
        baseURL: "https://language.jdmesh.co/api/v1",
    },
    prod: {
        baseURL: "https://language.jdmesh.co/api/v1",
    },
}

export function createGlobalHeaders(apiKey: string) {
    return {
        'X-Api-Key' : apiKey,
    };
}


/**
 * 
 * @param environment - language service env: uat, prod or a url to use a non-standard url.
 * @param apiKey - api key to use
 * @param basePath - path to prefix api calls with
 */
export function createLanguageClientConfig(environment: LanguageEnvironment | string, apiKey: string, basePath: string): LanguageClientConfig {
    const globalHeaders = createGlobalHeaders(apiKey);
    const languageUrl = environmentConfigs[environment] ? environmentConfigs[environment].baseURL : environment;
    const baseURL = `${languageUrl}${basePath}`;

    return {
        ...config,
        ...environmentConfigs[environment],
        baseURL,
        headers: globalHeaders,
        globalParams: {
            'api_key': apiKey,
        },
    };
}
