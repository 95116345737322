import React, { useContext, useState, useEffect, ChangeEvent } from "react";
import isEqual from 'deep-equal';
import { useSelector, useDispatch } from 'react-redux';
import clone from 'rfdc';
import { AppThunkDispatch } from '../../modules/thunk';
import { RootState } from '../../store/reducers';
import { Box, Form, Select, FormField, CheckBox, Button, Text, ResponsiveContext } from 'grommet';
import { Close } from '../Icon/index';
import { tracking } from "../../services";
import { AddressRetrieved } from '../../services/addresses'
import { HomeDeliveryMapToProps } from "../../containers/HomeDeliveryPage";
import TitleBar from '../TitleBar/TitleBar';
import Step from '../Step/Step';
import ValidatedInput from '../ValidatedInput/ValidatedInput';
import InfoBox from '../InfoBox/InfoBox';
import LocationInput from '../LocationInput/LocationInput';
import { maxLength30Rule, requiredRule, postcodeRule, nameRule, addressRule, getError } from '../../lib/validate/validate';
import {
    getAddresses, getCustomerID, getEmail, updateExternalAddress, saveExternalAddress,
    updateMarketingOptIns, getMarketingOptIns, saveMarketingOptInChoice, getCustomerPreferences
} from '../../modules/customer';
import { updateExternalCartAddressesAndCustomer, updateExternalCartDeliveryMethod } from '../../modules/cart';
import {
    getDeliveryAddress, getIsGeorestrictionError,
    updateIsGeorestrictionError,
    validateDeliveryAddressAndSaveToCart
} from '../../modules/delivery';
import { Address, CustomerPreferences } from '../../modules/customer/types';
import { DeliveryCountry } from '../../modules/delivery/types';
import { translate } from '../../modules/translation';
import { getMarketingSmsOptout, getShowMarketingCheckboxes } from '../../modules/config';
import { CustomTheme } from "../../theme";
import { ThemeContext } from 'styled-components';
import LoadingIcon from '../LoadingIcon/LoadingIcon';
import {
    getNotificationCode, updateNotification, clearNotification,
} from '../../modules/notification';
import Notification from '../Notification/Notification';
import { PageRefContext } from '../Page/Page';
import { getIsOnExpressJourney } from "../../modules/billing";
import newRelicData from "../../newrelic";

/**
 * Address form, used to edit billing or delivery addresses
 * @param onClose Callback executed when clicking on the Close icon
 * @param formType If used to edit an existing address, tells if this address is considered a billing or a delivery address
 * @param addressId When using the Address Book, this will contain the ID of the address to load
 */
function HomeDelivery({
    onClose,
    deliveryCountries, billingCountries, loadDeliveryCountries, loadBillingCountries,
    deliveryAddress, updateDeliveryAddress,
    billingAddress, updateBillingAddress,
    selectedDeliveryOption, loadDeliveryMethods,
    formType, addressId,
}: HomeDeliveryProps & HomeDeliveryMapToProps) {

    const customer = useSelector((state: RootState) => state.customer);
    const delivery = useSelector((state: RootState) => state.delivery);
    const billing = useSelector((state: RootState) => state.billing);
    const translation = useSelector((state: RootState) => state.translation);
    const config = useSelector((state: RootState) => state.config);

    const [savingInProgress, setSaving] = useState(false);
    const [hasAddresses, setHasAddresses] = useState(false);
    const addresses = getAddresses(customer);
    const customerID = getCustomerID(customer);
    const isOnExpressJourney = getIsOnExpressJourney(billing);
    const email = getEmail(customer);
    const customerPreferences = getCustomerPreferences(customer);
    const pageRef = useContext(PageRefContext);
    const isNikeConnected = config.nike_connected;
    const dispatch: AppThunkDispatch = useDispatch();

    const strings = {
        deliveryAddress: {
            STEP_TITLE: translate(translation, 'deliveryAddress', 'Delivery Address'),
        },
        billingAddress: {
            STEP_TITLE: translate(translation, 'billingAddress', 'Billing Address'),
        },
        editAddress: {
            STEP_TITLE: translate(translation, 'address', 'Address'),
        },
        addressRequired: translate(translation, 'addressRequired', 'An address is required.'),
        addressFinder: translate(translation, 'addressFinder', 'Address Finder'),
        enterAddressManually: translate(translation, 'enterAddressManually', 'Enter Address Manually'),
        state: translate(translation, 'state', 'State'),
        region: translate(translation, 'region', 'Region'),
        province: translate(translation, 'province', 'Province'),
        countyOptional: translate(translation, 'countyOptional', 'County (optional)'),
        unitNumber: translate(translation, 'unitNumber', 'Unit Number'),
        addressLine1: translate(translation, 'addressLine1', 'Address line 1'),
        addressLine2: translate(translation, 'addressLine2', 'Address line 2 (optional)'),
        townCity: translate(translation, 'townCity', 'Town/City'),
        postcode: translate(translation, 'postcode', 'Postcode'),
        findDifferentAddress: translate(translation, 'findDifferentAddress', 'Find a Different Address'),
        addAddress: translate(translation, 'addAddress', 'Add an Address'),
        contactDetails: translate(translation, 'contactDetails', 'Contact Details'),
        firstName: translate(translation, 'firstName', 'First Name'),
        lastName: translate(translation, 'lastName', 'Last Name'),
        contactNumber: translate(translation, 'contactNumber', 'Contact Number'),
        phoneHelperText: translate(translation, 'phoneHelperText', '8 - 14 characters and should only contain numbers or a “+” symbol'),
        contactForOrderUpdates: translate(translation, 'contactForOrderUpdates', 'We will contact you with updates on your order'),
        setDefaultDeliveryAddress: translate(translation, 'setDefaultDeliveryAddress', 'Set as default delivery address'),
        setDefaultBillingAddress: translate(translation, 'setDefaultBillingAddress', 'Set as default billing address'),
        saveContinue: translate(translation, 'saveContinue', 'Save and Continue'),
        country: translate(translation, 'country', 'Country'),
        marketingOptInSms: translate(translation, 'marketingOptInSms', 'We will share our latest launches, offers and new drops with you via SMS, unless you untick this box.'),
    };

    let address: Address
    if (addressId) address = addresses.find(address => address.ID === addressId) as Address;
    else if (formType === 'deliveryAddress') address = deliveryAddress;
    else if (formType === 'billingAddress') address = billingAddress;
    else { throw new Error('Error rendering address form') }
    if (!address) { throw new Error('Error rendering address form') }

    const [detailsTyped, setDetailsTyped] = React.useState<FormDetails>({
        ID: address.ID ?? '',
        firstName: address.firstName ?? customer.firstName ?? '',
        lastName: address.lastName ?? customer.lastName ?? '',
        phone: address.phone ?? '',
        address1: address.address1 ?? '',
        address2: address.address2 ?? '',
        address3: address.address3 ?? '',
        town: address.town ?? '',
        county: address.county ?? '',
        postcode: address.postcode ?? '',
        country: address.country ?? '',
        locale: address.locale ?? '',
        // Don't pre-tick default buttons for billing addresses
        isDefaultDeliveryAddress: address.isDefaultDeliveryAddress ?? formType === 'deliveryAddress',
        isDefaultBillingAddress: address.isDefaultBillingAddress ?? formType === 'deliveryAddress',
    });

    const countries = formType === 'deliveryAddress' ? deliveryCountries : billingCountries;
    const showMarketingCheckboxes = getShowMarketingCheckboxes(config);
    const initialSmsOptIn = getAddresses(customer).length === 0
        ? getMarketingSmsOptout(config) // New/guest users (with no address yet) have not formally opted in but will see the box ticked/unticked based on firebase config.
        : getMarketingOptIns(customer)?.smsOptIn ?? true;
    const [isSMSOptInTicked, setIsSMSOptInTicked] = React.useState(initialSmsOptIn);
    const [addressFinderView, setAddressFinderView] = React.useState<"predict" | "manual" | "retrieved">(getDefaultAddressView());
    const [addressError, setAddressError] = React.useState(false);

    const size = React.useContext(ResponsiveContext);
    const theme: CustomTheme = useContext(ThemeContext);
    // style for Desktop
    const desktopBoxWidth = theme?.config?.desktop?.boxWidth ?? "80vw";
    const isDesktop = size !== 'small';
    const showAddressLinesOnSameLine = theme?.config?.showAddress1And2OnSameLine && isDesktop;

    // Error update
    const notification = useSelector((state: RootState) => state.notification);
    const notificationCode = getNotificationCode(notification);
    
    const isGeorestrictionError = getIsGeorestrictionError(delivery);
    const styledLabel = <Text weight={theme?.config?.formFieldLabelWeight ?? 500}><Text color={theme?.config?.requiredFieldStarColour}>*</Text>{strings.country}</Text>;


    React.useEffect(() => {
        if (!deliveryCountries.length && !billingCountries.length) {
            loadDeliveryCountries();
            loadBillingCountries();
        }

        if (!addressId) {
            const defaultCountry = countries.find(item => item.default);
                setDetailsTyped((prevState: FormDetails) => {
                    let newState: any = {
                        country: (defaultCountry !== undefined) ? defaultCountry.name : "",
                        locale: (defaultCountry !== undefined) ? defaultCountry.code : "",
                    };

                    return { ...prevState, ...newState };
                });
        }

    }, [loadDeliveryCountries, loadBillingCountries, deliveryCountries, billingCountries, countries, addressId])

    // Only set hasAddresses on the first render (don't update when the address count is incremented while the form is submitted)
    useEffect(() => {
        if (addresses.length) setHasAddresses(true);
    // eslint-disable-next-line
    }, []);
    
    async function handleFormSubmit(event: any) {
        let addressValid = false;
        let registeredUserPrefrences = true;

        // reset notification message
        dispatch(clearNotification());

        setSaving(true);
        switch (selectedDeliveryOption.value) {
            case 'home':
                addressValid = !(detailsTyped.address1 === "" || detailsTyped.town === "" || detailsTyped.postcode === "");
                break;
            case 'clickAndCollect':
                addressValid = !(detailsTyped.firstName === "" || detailsTyped.lastName === "");
        }

        setAddressError(!addressValid);

        if (addressValid) {
            // No anatwine issue: we save the address
            let addressToSave: Address = {
                ID: detailsTyped.ID,
                firstName: detailsTyped.firstName,
                lastName: detailsTyped.lastName,
                email: email, // Email from the customer object
                phone: detailsTyped.phone,
                address1: detailsTyped.address1,
                address2: detailsTyped.address2,
                address3: detailsTyped.address3,
                town: detailsTyped.town,
                county: detailsTyped.county,
                postcode: detailsTyped.postcode,
                country: detailsTyped.country,
                isDefaultDeliveryAddress: detailsTyped.isDefaultDeliveryAddress,
                isDefaultBillingAddress: detailsTyped.isDefaultBillingAddress,
                locale: detailsTyped.locale,
            };

            try {
                // No ID: the address is saved in Mesh as a new address
                const isNewAddress = addressToSave.ID ? false : true;
                // Country excluded when checking if any update as the display name might be different.
                const hasSmsOptInChanged = customer.marketingOptIns?.smsOptIn !== isSMSOptInTicked;
                const hasAddressChanged = !isEqual(
                    { ...addressToSave, country: '', smsOptIn: isSMSOptInTicked },
                    { ...address, country: '', smsOptIn: customer.marketingOptIns?.smsOptIn }
                );
                await dispatch(saveMarketingOptInChoice(customer, {
                    ...getMarketingOptIns(customer),
                    smsOptIn: customer.marketingOptIns?.smsOptIn,
                    emailOptIn: customer.marketingOptIns?.emailOptIn
                }));
                if (isNewAddress) {
                    const needCustomerUpdate = customer.phone ? false : true;
                    const savedAddressResponse = await dispatch(saveExternalAddress(customerID, addressToSave, false, true, needCustomerUpdate));
                    addressToSave.ID = savedAddressResponse.data.ID;
                    newRelicData({ actionName: 'homedelivery', function: 'handleFormSubmit', message: 'new address' });
                }
                // If the address was preexisting, it is updated in Mesh.
                else if (hasAddressChanged) {
                    newRelicData({ actionName: 'homedelivery', function: 'handleFormSubmit', message: 'user has changed address' });
                    await dispatch(updateExternalAddress(customerID, addressToSave));
                }

                // The list of delivery methods is updated if the delivery address has changed
                if (selectedDeliveryOption.value === 'home') {
                    if (
                        formType === 'deliveryAddress' ||
                        (formType === 'billingAddress' && addressToSave.isDefaultDeliveryAddress)
                    ){
                        const isCartAddressUpdated = await dispatch(validateDeliveryAddressAndSaveToCart(addressToSave, getDeliveryAddress(delivery)));
                        if (!isCartAddressUpdated) {
                            setSaving(false);
                            return;
                        }
                    }
                }

                // Update Ensighten
               !isNikeConnected && dispatch(updateMarketingOptIns({smsOptIn: isSMSOptInTicked}));
                const ensightenCustomer = clone()(customer); // Clone to avoid having customer not yet updated from the dispatch above.
                if (ensightenCustomer.marketingOptIns) ensightenCustomer.marketingOptIns.smsOptIn = isSMSOptInTicked;
                if(isNikeConnected && !customer.isGuest && Object.keys(customerPreferences as CustomerPreferences).length > 0){
                    registeredUserPrefrences = false;
                }
                if(isNewAddress){
                    tracking()?.trackAddNewAddress(addressToSave, ensightenCustomer, registeredUserPrefrences ? showMarketingCheckboxes : false, isOnExpressJourney, isNikeConnected);
                }
                else if (hasAddressChanged) {
                    tracking()?.trackUpdateAddress(addressToSave, ensightenCustomer, registeredUserPrefrences ? showMarketingCheckboxes : false, isOnExpressJourney, isNikeConnected);
                }

                // Update customer opt-ins in Mesh
                if (!isNikeConnected && showMarketingCheckboxes && hasSmsOptInChanged) {
                    await dispatch(saveMarketingOptInChoice(customer, {
                        ...getMarketingOptIns(customer),
                        smsOptIn: isSMSOptInTicked,
                    }));
                }

                // The redux store is updated
                if (formType === 'deliveryAddress') {
                        await loadDeliveryMethods(addressToSave.postcode, addressToSave.locale);
                        updateDeliveryAddress(addressToSave);
                        newRelicData({ actionName: 'homedelivery', function: 'handleFormSubmit', deliveryAddressId: addressToSave.ID, message: 'form type is delivery Address'  });
                        if(delivery.selectedMethod.ID) {
                            await dispatch(updateExternalCartDeliveryMethod(delivery.selectedMethod.ID, detailsTyped.locale));
                        }

                    if (addressToSave.isDefaultBillingAddress) {
                        updateBillingAddress(addressToSave);
                    }
                }

                else if (formType === 'billingAddress') {
                    updateBillingAddress(addressToSave);
                    dispatch(updateExternalCartAddressesAndCustomer());
                    newRelicData({ actionName: 'homedelivery', function: 'handleFormSubmit', billingAddressId: addressToSave.ID, message: 'form type is billing Address' });
                    if (addressToSave.isDefaultDeliveryAddress) {
                        updateDeliveryAddress(addressToSave);
                    }
                }
                if (formType === 'deliveryAddress') {
                    pageRef?.current?.scrollTo(0,0);
                }
                onClose(event);
            }
            catch (error) {
                newRelicData({ actionName: 'homeDelivery', function: 'handleFormSubmit', message: { msg: (error as Error)?.message, type: 'unable to update address' }});
                if((error as Error)?.message === 'CART_PROPOSED_ADDRESS_4'){
                    dispatch(updateNotification('CART_PROPOSED_ADDRESS_4'));
                    setSaving(false);
                    return false;
                }
                console.error('address error', error);
                dispatch(updateNotification('address_error'));
                setSaving(false);
            }
        } else {
            dispatch(updateNotification('address_invalid_error'));
            setSaving(false);
            console.error('Details entered invalid', detailsTyped, addressValid, addressError);
        }
    };

    /**
     * Applies changes to the collection details form (controlled form)
     * @param values Values up-to-date with what has just been typed.
     */
    const handleFormChange = (values: unknown) => {
        const newValues = values as FormDetails;
        setDetailsTyped((prevState: FormDetails) => {
            let newState: any = {
                firstName: newValues.firstName,
                lastName: newValues.lastName,
                phone: newValues.phone,
                address1: newValues.address1,
                address2: newValues.address2,
                address3: newValues.address3,
                town: newValues.town,
                county: newValues.county,
                postcode: newValues.postcode,
                country: newValues.country,
                locale: newValues.locale,
                isDefaultDeliveryAddress: newValues.isDefaultDeliveryAddress ?? true,
                isDefaultBillingAddress: newValues.isDefaultBillingAddress ?? true
            };

            return { ...prevState, ...newState };
        });
    }
    
    function getDefaultAddressView() {
        if (detailsTyped.address1 !== "" && detailsTyped.town !== "" && detailsTyped.postcode !== "") {
            return "retrieved";
        }
        return "predict";
    }

    function handleManualAddressButtonClick() {
        setAddressFinderView("manual");
        setDetailsTyped((prevState: FormDetails) => {
            return {
                ...prevState,
                address1: '',
                address2: '',
                town: '',
                county: '',
                postcode: ''
            };
        });
    };

    /**
     * Callback executed when the user selects a suggestion item from an 'address finder' input.
     * Update the form to extra fields and populate these fields with the retrieved address.
     * @param fullAddress Details of the address selected from the suggestion
     */
    async function fillInSuggestedAddress(fullAddress: AddressRetrieved) {
        setAddressFinderView('retrieved');
        setDetailsTyped((prevState: FormDetails) => {
            return {
                ...prevState,
                address1: fullAddress.address1,
                address2: fullAddress.address2,
                town: fullAddress.town,
                county: fullAddress.county,
                postcode: fullAddress.postcode,
            };
        });
        tracking().trackFindAddress('PCA Predict');
    };

    function renderAddressFinder() {
        return (
            <div>
                <Box direction="row" margin={{ 'top': 'small', 'bottom': 'none' }}>
                    <LocationInput
                        inputId="AddressFinder"
                        maxLength={30}
                        hasCompassIcon={false}
                        title={strings.addressFinder}
                        suggestionsEnabled={true}
                        onSelect={fillInSuggestedAddress}
                        locale={detailsTyped.locale}
                    />
                </Box>
                {addressError &&
                    <Box direction="row" margin={{ 'top': 'small', 'bottom': 'none' }}>
                        <Text color="red">{strings.addressRequired}</Text>
                    </Box>
                }
                <Box direction="row" margin={{ 'top': 'medium', 'bottom': 'medium' }}>
                    <Button
                        type="button"
                        label={strings.enterAddressManually}
                        onClick={() => handleManualAddressButtonClick()}
                        data-testid="enterManualAddressBtn"
                        fill="horizontal"
                    />
                </Box>
            </div>
        );
    }

    function renderAddressInputs(country?: DeliveryCountry) {
        let countyRules = [maxLength30Rule(''),addressRule('')];
        let countyLabel = '';
        switch(country?.code) {
            // Make 'State' or 'Province' mandatory for USA and Canada for Adyen
            // Make 'State' mandatory for Singapore and Malaysia for Rely    
            case 'us':
            case 'au':
                countyRules.push(requiredRule('State'));
                countyLabel = strings.state;
                break;
            case 'ca':
                countyRules.push(requiredRule('Province'));
                countyLabel = strings.province;
                break;
            case 'sg':
                countyRules.push(requiredRule('State'));
                countyLabel = strings.state;
                break;
            case 'my':
                countyRules.push(requiredRule('State'));
                countyLabel = strings.state;
                break;  
            case 'nz':
                countyLabel = strings.region;
                break;  
            default:
                countyLabel = strings.countyOptional;
                break;
        }

        let addressLine1Rules = [maxLength30Rule(strings.addressLine1),addressRule(strings.addressLine1)];
        let addressLine1Label = '';
        let addressLine2Rules = [maxLength30Rule(strings.addressLine2),addressRule(strings.addressLine2)];
        let addressLine2Label = '';
        let addressLine3 = false;

        if(country?.code === 'sg') {
             addressLine1Rules = [];
             addressLine2Rules = [];
            addressLine1Rules.push(requiredRule(strings.unitNumber),maxLength30Rule(strings.unitNumber));
            addressLine1Label = strings.unitNumber;
            addressLine2Rules.push(requiredRule(strings.addressLine1),maxLength30Rule(strings.addressLine1));
            addressLine2Label = strings.addressLine1;
            addressLine3 = true;
        }
        else {
            addressLine1Rules.push(requiredRule(strings.addressLine1));
            addressLine1Label = strings.addressLine1;
            const countryCodes = ['de','at'];
            if(countryCodes.some(countrycode => countrycode === country?.code)){
                addressLine2Rules.push(requiredRule(strings.addressLine2));
            }
            addressLine2Label = strings.addressLine2;
        }

        let postCodeLocale = detailsTyped.locale.toUpperCase();
        return (
            <div>
                <Box
                    direction={showAddressLinesOnSameLine ? 'row' : 'column'}
                    gap={showAddressLinesOnSameLine ? 'small' : undefined}
                >
                    <Box flex={showAddressLinesOnSameLine ? 'grow' : undefined}>
                        <ValidatedInput
                            rules={addressLine1Rules}
                            name="address1"
                            label={addressLine1Label}
                            data-testid="address1"
                            value={detailsTyped['address1']}
                            addStarIfRequired={true}
                        />
                    </Box>
                    <ValidatedInput
                        rules={addressLine2Rules}
                        name="address2"
                        label={addressLine2Label}
                        value={detailsTyped['address2']}
                        addStarIfRequired={true}
                    />
                </Box>
                {addressLine3 &&
                    <ValidatedInput
                        rules={[maxLength30Rule(strings.addressLine2), addressRule]}
                        label={strings.addressLine2}
                        name="address3"
                        value={detailsTyped['address3']}
                    />  
                }    
                <ValidatedInput
                    rules={[requiredRule(strings.townCity), maxLength30Rule(strings.townCity), addressRule]}
                    name="town"
                    label={strings.townCity}
                    value={detailsTyped['town']}
                    addStarIfRequired={true}
                />
                <ValidatedInput
                    rules={countyRules}
                    label={countyLabel}
                    name="county"
                    value={detailsTyped['county']}
                    addStarIfRequired={true}
                />
                <ValidatedInput
                    rules={[requiredRule(strings.postcode), maxLength30Rule(strings.postcode), postcodeRule(postCodeLocale,strings.postcode)]}
                    label={strings.postcode}
                    name="postcode"
                    value={detailsTyped['postcode']}
                    addStarIfRequired={true}
                />

                {country?.pcaPredictSupported &&
                    <Box direction="row" margin={{ 'top': 'small', 'bottom': 'medium' }}>
                        <Button
                            type="button"
                            label={strings.findDifferentAddress}
                            onClick={(event) => { setAddressFinderView("predict") }}
                            data-testid="addressFinderBtn"
                            fill="horizontal"
                        />
                    </Box>
                }
            </div>
        );
    }

    function renderAddress() {
        const country = countries.find(item => item.code === detailsTyped.locale);
        if (addressFinderView === "predict" && country?.pcaPredictSupported) {
            return renderAddressFinder();
        }

        return renderAddressInputs(country);
    }


    return (
        <Box
            background={isDesktop ? '' : { color: "light-1" }}
            width={isDesktop ? desktopBoxWidth : "100vw"}
            overflow={!isDesktop ? "scroll" : ""}
            responsive={false}
            margin={{bottom:"small"}}
        >
            <Box >
                {/* only show title in mobile view or when editing*/}
                {!isDesktop || addresses.length ?
                    <TitleBar
                        title={strings.addAddress}
                        icon={<Close
                            onClick={(event) => {
                                dispatch(clearNotification());
                                onClose(event);
                            }}
                        />}
                        borderSide={isDesktop ? theme?.config?.desktop?.borderSide ?? 'bottom' : 'bottom'}
                    />
                    : null}
                <Form
                    value={detailsTyped}
                    onSubmit={handleFormSubmit}
                    onChange={handleFormChange}
                >
                    <Box>
                        <Step title={strings.contactDetails}>
                            <Box width={isDesktop ? "100vw" : ''} >
                                <ValidatedInput
                                    rules={[requiredRule(strings.firstName), maxLength30Rule(strings.firstName), nameRule]}
                                    name="firstName"
                                    label={strings.firstName}
                                    value={detailsTyped.firstName}
                                    addStarIfRequired={true}
                                />
                                <ValidatedInput
                                    rules={[requiredRule(strings.lastName), maxLength30Rule(strings.lastName), nameRule]}
                                    name="lastName"
                                    label={strings.lastName}
                                    value={detailsTyped.lastName}
                                    addStarIfRequired={true}
                                />
                                <ValidatedInput
                                    rules={[requiredRule(strings.contactNumber), { name: 'phone',fieldName:strings.contactNumber }, maxLength30Rule(strings.contactNumber)]}
                                    label={strings.contactNumber}
                                    name="phone"
                                    type="tel"
                                    help={<Text size="small">{strings.phoneHelperText}</Text>}
                                    value={detailsTyped.phone}
                                    addStarIfRequired={true}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                        handleFormChange({...detailsTyped, phone:e});
                                    }}
                                />
                                <InfoBox
                                    text={strings.contactForOrderUpdates}
                                    background="white"
                                />
                                { showMarketingCheckboxes && !isNikeConnected &&
                                    <Box margin={{top: 'medium'}}>
                                        <CheckBox
                                            checked={isSMSOptInTicked}
                                            onChange={event => {
                                                setIsSMSOptInTicked(event.target.checked);
                                            }}
                                            label={<Text size="small">{strings.marketingOptInSms}</Text>}
                                        />
                                    </Box>
                                }
                            </Box>
                        </Step>
                    </Box>

                    

                        <Step title={strings[formType].STEP_TITLE} showSeparator={!isDesktop ?? true}>
                            <Box width={isDesktop ? desktopBoxWidth : ''} >
                                <FormField
                                    label={ detailsTyped.country === "" ? styledLabel : strings.country }
                                    validate={() => {
                                        if (detailsTyped.country === "") {
                                            const error = getError(detailsTyped.country, [requiredRule(strings.country)]);
                                            return error.message
                                        }
                                    }}
                                >
                                    <Select
                                        options={countries}
                                        valueKey={{ key: 'name', reduce: true }}
                                        labelKey="name"
                                        onChange={({ option: country }: { option: DeliveryCountry }) => {
                                            if(isGeorestrictionError) {
                                                dispatch(updateIsGeorestrictionError(false));
                                                dispatch(clearNotification());
                                            }   
                                            tracking().trackSelectDeliveryCountry(country);
                                            setDetailsTyped((prevState: FormDetails) => {
                                                return {
                                                    ...prevState,
                                                    country: country.name ?? '',
                                                    locale: country.code ?? '',
                                                }
                                            });

                                            if (country.pcaPredictSupported) {
                                                setAddressFinderView("predict");
                                            } else {
                                                setAddressFinderView("manual");
                                            };
                                        }}
                                        value={(detailsTyped.country === "") ? "" : detailsTyped.country}
                                        data-testid="countrySelect"
                                    />
                                </FormField>

                                {renderAddress()}

                                { hasAddresses && // Only offer default addresses tickboxes if we already have other address that can revert to non-default.
                                    <Box direction="column" gap="small" margin={{ 'bottom': 'small' }}>
                                        {selectedDeliveryOption.value === 'home' &&
                                            //Only show this as an option on home deliveries.
                                            <CheckBox
                                                checked={detailsTyped.isDefaultDeliveryAddress}
                                                onChange={(event) => setDetailsTyped((prevState: FormDetails) => {
                                                    return { ...prevState, isDefaultDeliveryAddress: event.target.checked }
                                                })}
                                                label={strings.setDefaultDeliveryAddress}
                                            />
                                        }
                                        <CheckBox
                                            checked={detailsTyped.isDefaultBillingAddress}
                                            onChange={(event) => setDetailsTyped((prevState: FormDetails) => {
                                                return { ...prevState, isDefaultBillingAddress: event.target.checked }
                                            })}
                                            label={strings.setDefaultBillingAddress}
                                            data-testid="defaultBillingAddressCheckbox"
                                        />
                                    </Box>
                                }

                                {
                                    notificationCode !== ""
                                    && !["anatwine_items_removed_info", "no_international_delivery_notification"].includes(notificationCode)
                                    && !isDesktop &&
                                    
                                    <Notification />
                                }
                            </Box>

                        </Step>
                    
                    <Box direction="row"
                        border= {isDesktop
                            ? (theme?.config?.desktop?.boxBorder ?? false)
                            : false}>
                        <Button
                            margin={isDesktop
                                ? { horizontal: "medium",vertical:"medium" }
                                : { horizontal: 'medium', bottom: '100px' }}
                            primary
                            type="submit"
                            label={savingInProgress ? <LoadingIcon /> : strings.saveContinue}
                            fill="horizontal"
                        />
                    </Box>

                </Form>
            </Box>
        </Box>
    );
};

type FormDetails = {
    ID: string,
    firstName: string,
    lastName: string,
    phone: string,
    address1: string,
    address2: string,
    address3: string,
    town: string,
    county: string,
    postcode: string,
    country: string,
    locale: string,
    isDefaultDeliveryAddress: boolean,
    isDefaultBillingAddress: boolean
}

type HomeDeliveryProps = {
    formType: addressTypes,
    addressId?: string,
    onClose: (event: React.MouseEvent) => void
}

export type addressTypes = 'deliveryAddress' | 'billingAddress';

export default HomeDelivery;
