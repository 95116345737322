
import { ResizingServiceImage } from '../../lib/imageResizing/types'

// constant types
export const UPDATE = 'checkout/cart/UPDATE';

// action types 
interface UpdateCart {
    type: typeof UPDATE, 
    cart: Cart
}

export type CartActionTypes = UpdateCart;

// other 
export interface MoneyAmount {
    amount: string, 
    currency: string
} 

export interface GiftCard {
    cardNumber: string,
    securityNumber?: string | null,
    pin?: string,
    currentBalance?: MoneyAmount,
}

export interface AppliedGiftCard {
    storeID: string,
    cardNumber: string,
    cardType: string,
    amount: MoneyAmount,
    remainingBalance: MoneyAmount,
}

export interface DeliveryDate {
    hasRange: boolean,
    startDate: string,
    endDate: string
}

export interface DeliveryOptions {
    id: string, 
    links: DeliveryOption[]
}

export interface DeliveryOption {
    rel?: string,
    href: string,
    targetSchema?: string
}

export interface DeliveryMethod {
    ID: string,
    displayName: string,
    description?: string
}

export interface Image {
    ID: string | null, 
    altText?: string | null,
    originalURL: string,
    srcset?: string,
    resizingService: ResizingServiceImage
}

export interface Customisations
{
    customisation : {
        href: string,
        ID: string,
        name: string,
        displayName: string,
        description: string,
        isOptional: boolean,
        type: string,
        customisationType: string | null,
        icon: {
            name: string,
            URL: string
        },
        minLength: number,
        maxLength: number,
        allowedCharacters: string,
        allowedValues:  string | null
    },
    value: string
}

export interface CustomisationSets {
    ID: string,
    href: string,
    name: string,
    clientI: string,
    appliesTo: string,
    sortOrder: number,
    price: MoneyAmount,
    SKU: string,
    icon:  string | null,
    customisations: Customisations[]
}



export interface Product {
    SKU: string,
    parentSKU: string,
    trackingSKU?: string,
    categories?: {
        ID: string,
        path: string,
        name: string,
        sortOrder: number,
        clientID: string,
        product: null
    }[],
    name: string,
    type: string,
    brandName?: string | null,
    isDiscounted?: boolean,
    discountAmount?: MoneyAmount,
    image: Image,
    colourDescription?: string | null,
    colour?: string | null,
    optionValues: {
        [option: string]: string
    },
    unitPrice: MoneyAmount,
    subtotal: MoneyAmount,
    previousPrice: MoneyAmount,
    previousUnitPrice: MoneyAmount | null,
    saving: MoneyAmount,
    unitPriceBeforeDiscounts: MoneyAmount ,
    quantity: number,
    customisationSets: CustomisationSets[] | null,
    conflictCode?: string | null,
    conflictMessage?: string | null,
    dateAdded?: string,
    products?: Product[],
    stockPool?: {
        ID?: string,
        isDropship?: boolean,
    },
    status?: string,
    fulfilment?: {
        fulfilmentMessage?: string,
        fulfilmentMessage2?: string,
        imageURL?: string,
        isDefault?: boolean,
        deliveryMessage?: {
            message?: string,
            message2?: string,
            imageURL?: string,
            leadTime?: string, // eg. "5-6"
            isDefault?: boolean,
        },
        informationPage?: {
            ID?: string,
        }
    },
    cartProductNotification?: CartProductNotificationDetails,
    categoryGroupingName?: string | null,
}


export interface Cart {
    ID: string,
    reference: string, 
    paid: boolean,
    channel: string,
    count:number,
    rawCartTotal: MoneyAmount,
    subtotal: MoneyAmount,
    total: MoneyAmount,
    balanceToPay: MoneyAmount,
    tax: MoneyAmount | null,
    giftcardsSubtotal: MoneyAmount,
    subtotalBeforeDiscounts: MoneyAmount,
    cartDiscounts?: {
        title: string,
        isDelivery: boolean,
        saving: MoneyAmount,
    }[],
    containsUnavailableProducts: boolean,
    eligibleForGiftCards: boolean,
    allowToPayWithGiftCard: boolean,
    giftcards?: GiftCard[],
    giftcardsApplied?: AppliedGiftCard[],
    estimatedDeliveryDate?: DeliveryDate | null,
    estimatedDispatchDate?: DeliveryDate | null,
    customer: any | null,
    billingAddress: any | null,
    deliveryAddress: any | null,
    deliveryOptions: DeliveryOptions | null,
	discountCodes: string[],
    delivery?: {
        deliveryMethodID: string,
        deliveryMethodDisplayName: string,
        deliveryMethodDescription: string,
        date: null,
        allowsCrossLocaleDelivery: boolean,
        isExclusivelyCrossLocale: boolean,
        storeID: string,
        note: any,
        nominatedCollector?: {
            firstName: string,
            lastName: string,
            phone: string,
        }
    } | null,
    deliveryMethod: DeliveryMethod | null,
    deliverySubtotal: MoneyAmount,
    products: Product[],
    productsSubtotal: MoneyAmount,
    totalDiscount: MoneyAmount,
    totalSavings: {
        productSavings: MoneyAmount,
        deliverySavings: MoneyAmount,
        total: MoneyAmount
    }
}

export interface FulfilmentGroup {
    ID: string,
    products: Product[],
    isDropShip: boolean,
    fulfilmentMessage?: string,
    fulfilmentMessage2?: string,
    fulfilmentMessageImage?: string,
    deliveryMessage?: string,
    informationPage?: string,
}

export interface CartProductNotificationDetails {
    deliveryType?: string,
    scheduledDelivery?: string,
    personalMessage?: string,
    sendFrom?: string,
    sendTo?: string
}

export interface GiftCardScheduleDetails {
    deliveryType?: string,
    scheduledDelivery?: string,
    informationMessage?: string
}

export interface PaymentServiceCart {
    ID: string
} 