import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../store/reducers';
import {
    getDeliveryAddress, getDeliveryCountries, updateDeliveryAddress,
    loadDeliveryCountries, getSelectedDeliveryOption,
    loadDeliveryMethods
} from '../modules/delivery';
import { getBillingAddress, getBillingCountries, updateBillingAddress, loadBillingCountries } from '../modules/billing';
import { updateNotification } from '../modules/notification';
import HomeDelivery from '../components/HomeDelivery/HomeDelivery';
import { Address } from '../modules/customer/types';


const mapStateToProps = (state : RootState) => {
    return {
        deliveryAddress: getDeliveryAddress(state.delivery),
        deliveryCountries: getDeliveryCountries(state.delivery),
        billingAddress: getBillingAddress(state.billing),
        billingCountries: getBillingCountries(state.billing),
        selectedDeliveryOption: getSelectedDeliveryOption(state.delivery)
    }
};

const mapDispatchToProps = (
    // TODO: set up proper union type of actions when all actions are set up
    dispatch: ThunkDispatch<any, any, any>
) => {
    return {
        loadDeliveryCountries: () => dispatch(loadDeliveryCountries()),
        loadBillingCountries: () => dispatch(loadBillingCountries()),
        updateDeliveryAddress: (details: Address) => dispatch(updateDeliveryAddress(details)),
        updateBillingAddress: (address: Address) => dispatch(updateBillingAddress(address)),
        loadDeliveryMethods: (postcode: string, locale: string) => dispatch(loadDeliveryMethods({ location: postcode }, locale)),
        updateNotification: (details: string) => dispatch(updateNotification(details))
    };
};

export type HomeDeliveryMapToProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const HomeDeliveryPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(HomeDelivery);

export default HomeDeliveryPage;
