import { RootState } from '../../store/reducers';
import { Phrases } from '../../modules/translation/types';
import { createResponse } from '../response';
import languageSDK from '../language-sdk';
import { LanguageConfig, LanguageEnvironment, LanguageFrameworksClient } from '../language-sdk/types';
import { LanguageFrameworksService } from '../language';


function languageFrameworksAdaptor(client: LanguageFrameworksClient): LanguageFrameworksService {

    async function getPhrases(languageCode: string){
        const phrasesResponse = await client.getPhrases(languageCode);
        const { status, data, headers } = phrasesResponse;
        return createResponse<Phrases>(status, data.phrases, headers);
    }

    return {
        getPhrases,
    }

}

export default function languageAdaptor(state: RootState){
    const config = state.config;

    const languageConfig: LanguageConfig = {
        environment: process.env.REACT_APP_MESH_ENV as LanguageEnvironment ?? 'uat',
        baseUrl: config.api.language?.url,
        apiKey: config.api.language?.apiKey,
        store: config.store,
    }

    const language = languageSDK(languageConfig);

    return {
        languageFrameworks: languageFrameworksAdaptor(language.languageFrameworks),
    };
}
