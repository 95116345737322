import { createStore, applyMiddleware } from 'redux';
import reducer from './reducers';
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist'
import localforage from 'localforage';
import { local as browserLocalStorage, session as sessionModule } from 'store2';

let reduxMiddleware = [thunk];
const persistConfig = {
    key: 'state',
    storage: localforage,
}
const persistedReducer = persistReducer(persistConfig, reducer);


// Only includes in DEV mode
if (process.env.NODE_ENV === 'development') {
    const freeze = require('redux-freeze')
    reduxMiddleware.push(freeze)
}

const serializedState:any = browserLocalStorage.get('state');
const localSate = JSON.parse(serializedState);

function findCartInParams(): boolean {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('cart') !== null ? true : false;
}

export const store = localSate
    ? createStore(persistedReducer, localSate , composeWithDevTools(applyMiddleware(...reduxMiddleware)))
    : createStore(persistedReducer , composeWithDevTools(applyMiddleware(...reduxMiddleware)));


if(localSate && !findCartInParams()){
    for (const [reducer, reducerValue] of Object.entries(localSate)) {
        sessionModule.set(`state.${reducer}`, reducerValue, true);
    }
    sessionModule.set('auth.refresh_token', browserLocalStorage.get('authRefreshToken'), true);
    sessionModule.set('auth.access_token', browserLocalStorage.get('authToken'), true);
} else if(findCartInParams()) {
    browserLocalStorage.remove('state');
    browserLocalStorage.remove('authRefreshToken');
    browserLocalStorage.remove('authToken');
    
    sessionModule.remove('auth.refresh_token');
    sessionModule.remove('auth.access_token');
    sessionModule.remove('state.cart');
    sessionModule.remove('state.customer');
}

export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;

// @ts-ignore
export const persistor = persistStore(store)

export default store;
