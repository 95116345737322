import { AxiosInstance, AxiosResponse } from 'axios';
import { MeshConfig } from './types';

type AddressPredicted = {
    ID: string,
    links: {
        rel: 'refine'|'select',
        href: string
    }[],
    address1?: string,
    address2?: string,
    address3?: string,
    addressString: string,
    country: string,
    postCode: string,
    locale: string,
};

export interface AddressPredictResponse extends Array<AddressPredicted>{};

type AddressRetrieved = {
    address1: string,
    address2: string,
    town: string,
    county: string,
    postcode: string,
    country: string,
    locale: string
};

export interface AddressRetrieveResponse extends Array<AddressRetrieved>{};


function addresses (client: AxiosInstance, meshConfig: MeshConfig) {

    const { channel, locale} = meshConfig;
    const countryLocale: string = locale;

    /**
     * Get a list of address suggestions based on a query string
     * @param query Location (town, postcode, partial string)
     * @param lastId ID of the chosen item from a previous query (for refine)
     * @param locale Locale
     */
    async function getPredictedAddresses(query: string, lastId?: string, locale: string = countryLocale): Promise<AxiosResponse<AddressPredictResponse>>{
        const params: {locale: string, channel: string, query:string, lastId?: string} = {
            locale,
            channel,
            query
        };
        if (lastId) {
            params.lastId = lastId;
        }

        return await client.get<AddressPredictResponse>(`/addresses/lookup/predict`, {params});
    }

    /**
     * Get a specific address
     * @param retrieve ID of the suggestion we got from a previous query
     * @param locale Locale
     */
    async function getRetrievedAddress(retrieve: string, locale: string = countryLocale): Promise<AxiosResponse<AddressRetrieveResponse>>{
        return await client.get<AddressRetrieveResponse>(`/addresses/lookup/predict`, {
            params: {
                locale,
                channel,
                retrieve
            }
        });
    }

    return {
        getPredictedAddresses,
        getRetrievedAddress,
    };
}

export type AddressesService = ReturnType<typeof addresses>;

export default addresses;
