import React from 'react';
import { Text, Anchor, TextProps } from 'grommet';
import { languageFrameworks } from '../../services';
import { AppThunk } from "../thunk";
import {
    UPDATE_PHRASES, UPDATE_TRANSLATION_MODULE,
    TranslationActionTypes, Translation, Phrases,
} from './types';
import newRelicData from "../../newrelic";

export const initialState: Translation = {
    phrases: {},
};

// Reducer
export default function reducer(state = initialState, action: TranslationActionTypes) {
    switch (action.type) {
        case UPDATE_TRANSLATION_MODULE: {
            return action.payload;
        }

        case UPDATE_PHRASES: {
            return {
                ...state,
                phrases: action.payload,
            };
        }

        default: {
            return state;
        }
    }
}

// Selectors
/**
 * @param state
 * @param key Translation key
 * @param fallbackTranslation Text to use if the translation is not found
 * @param variables Example: if the phrase is "Pay with {provider}", variables could be: {provider: Klarna}
 * @returns The text corresponding to the translation key
 */
export const translate = (state: Translation, key: string, fallbackTranslation: string, variables?: {[key:string]: string|number}) => {
    let translation = state.phrases[key];
    if (!translation) {
        return fallbackTranslation;
    }
    if (variables) {
        for (key in variables) {
            const search = new RegExp(`{${key}}`, 'g');
            try {
                translation = translation.replace(search, variables[key].toString());
            }
            catch (error) {
                // Likely the 'value' is invalid for toString(), maybe undefined
                translation = translation.replace(search, '');
            }
        }
    }
    return translation;
};

// Actions
export function updateTranslationModule(state: Translation): TranslationActionTypes {
    return {
        type: UPDATE_TRANSLATION_MODULE,
        payload: state,
    };
}

export function updatePhrases(phrases: Phrases): TranslationActionTypes {
    return {
        type: UPDATE_PHRASES,
        payload: phrases,
    };
}

// Side Effects
/**
 * Get phrases from the language service and put them to Redux.
 * @param languageCode Language to translate to. Example: 'fr-FR'
 */
export function loadTranslationPhrases(languageCode: string, retries: number = 0): AppThunk<void> {
    return async (dispatch, getState) => {
        try {
            const phrasesResponse = await languageFrameworks().getPhrases(languageCode);
            dispatch(updatePhrases(phrasesResponse.data));
        } catch(error) {
            if(retries < 2) {
                return dispatch(loadTranslationPhrases(languageCode, retries + 1))
            } else {
                newRelicData({ actionName: 'translation', function: 'loadTranslationPhrases', message: (error as Error).message })
                return Promise.reject();
            }
        }
    };
};


// Helper Functions
/**
 * Expects a translated phrase with 1 piece of text between () and a link. Makes this a text element.
 * @param phrase The translated phrase
 * @param link The URL for the link
 * @returns A React text element part of which will be an anchor with the provided 'link' as its href.
 */
export function getPhraseWithLink(phrase: string, link: string, textStyle?: TextProps) {
    const match = phrase.match(/(.*)\((.+)\)(.*)/);
    if (match && match.length > 1) {
        const linkElement = React.createElement(Anchor, {
            target: "_blank",
            href: link,
        }, match[2]);
        return React.createElement(Text, textStyle, match[1], linkElement, match[3]);
    }
    else return React.createElement(Text, textStyle, phrase);
}