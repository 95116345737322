import { CartActionTypes, UPDATE, Cart, GiftCard, FulfilmentGroup, GiftCardScheduleDetails, Product } from './types'
import { AppThunk } from '../thunk';
import { carts } from '../../services';
import { getCustomerID, getCustomer as getCustomerState, getEmail, updateCustomer } from '../customer';
import {
    getDeliveryAddress, selectDeliveryMethodByIdAndTrack,
    getSelectedStore, getCollectionDetails, getSelectedDeliveryOption,
    getDeliverySlotByMethodId, getSelectedMethod as getSelectedDeliveryMethod,
    getMethods as getDeliveryMethods,
	updateStores,
	updatePostOffices
} from '../delivery';
import { getBillingAddress, updateGiftCardError } from '../billing';
import { getApi, getGeorestrictedBrands, getGiftCardSkuDetails, getLocalisation } from '../config';
import { updateDialog, updateNotification } from '../notification';
import { customers } from '../../services';
import { AppResponse } from '../../services/response';
import { CandCLocation, Delivery } from '../delivery/types';
import { CartUpdates } from '../../services/carts';
import { MoneyAmount, Order } from '../order/types';
import { updatePayment } from "../../modules/billing";
import { getSession, putSession } from "../../modules/session";
import { formatScheduledDate, localisedTime } from "../../lib/date/date";
import { Config } from "../config/types";
import { selectMethod } from "../../modules/delivery";
import { Billing } from '../billing/types';
import { Address, Customer } from '../customer/types';
import { Translation } from '../translation/types';
import { translate } from '../translation';
import newRelicData from '../../newrelic';

const initialMoneyAmount = {
    amount: "0.00",
    currency: "GBP"
}

export const initialState: Cart = {
    ID: "", 
    reference: "",
    paid: false,
    count:0,
    channel: "desktop",
    giftcardsSubtotal: initialMoneyAmount,
    giftcards: [],
    subtotalBeforeDiscounts: initialMoneyAmount,
    containsUnavailableProducts: false,
    eligibleForGiftCards: true,
    allowToPayWithGiftCard: true,
    products: [],
    customer: null, 
    billingAddress: null,
    deliveryAddress: null,
    deliveryOptions: null,
    deliveryMethod: null,
    deliverySubtotal: initialMoneyAmount,
    delivery: null,
    productsSubtotal: initialMoneyAmount,
    rawCartTotal: initialMoneyAmount,
    subtotal: initialMoneyAmount,
    total: initialMoneyAmount,
    balanceToPay: initialMoneyAmount,
    totalDiscount: initialMoneyAmount,
    tax: null,
    totalSavings: {
        productSavings: initialMoneyAmount,
        deliverySavings: initialMoneyAmount,
        total: initialMoneyAmount
    },
    discountCodes: []
}


// Reducer
export default function reducer(state = initialState, action: CartActionTypes) {
    switch (action.type) {
        case UPDATE:
            return { ...action.cart }
        default:
            return state;
    }
}

// Selectors
export const getCart = (state: Cart) => state;
export const getCustomer = (state: Cart) => state.customer;
export const getProducts = (state: Cart) => state.products;
export const getDropshipProducts = (state: Cart) => state.products.filter(product => product.stockPool?.isDropship);
export const getProductsSubtotal = (state: Cart) => state.productsSubtotal;
export const getProductSubtotalBeforeDiscounts = (state: Cart) => state.subtotalBeforeDiscounts;
export const getCartDeliveryMethod = (state: Cart) => state.deliveryMethod;
export const getTotal = (state: Cart) => state.balanceToPay;
export const getSubTotal = (state: Cart) => state.subtotal;
export const getRawCartTotal = (state: Cart) => state.rawCartTotal;
export const getDiscount = (state: Cart) => state.totalDiscount;
export const getCartId = (state: Cart) => state.ID;
export const getDeliveryTotal = (state: Cart) => state.deliverySubtotal;
export const getAppliedGiftCards = (state: Cart) => state.giftcardsApplied;
export const getIsEligibleForGiftCards = (state: Cart) => {
    return state.eligibleForGiftCards && !getHasGiftCardUnavailableProducts(state);
};
export const getAllowToPayWithGiftCard = (state: Cart) => state.allowToPayWithGiftCard;
export const getGiftCards = (state: Cart) => state.giftcards;
export const getGiftCardSubtotal = (state: Cart) => state.giftcardsSubtotal;
export const getTotalCartDiscounts = (state: Cart) => {
    let total = 0.00;

    if (!!state.cartDiscounts) {
        //If the cart contains cartDiscounts, loop through the array and add up the total.
        state.cartDiscounts.forEach(element => {
            total += parseFloat(element.saving.amount);
        });
    }

    return {
        amount: total.toFixed(2), 
        currency: state.total.currency //Grab the currency from the total - since it's always set.
    } as MoneyAmount
}
export const getTotalProductDiscounts = (state: Cart) => state.totalSavings.productSavings;

/**
 * Filters the cart products to keep only those that have a brand name that matches
 * one of the brand names in the config array delivery_georestrictions.brands
 * 
 */
export const getProductsGeorestrictedByBrand = (config: Config, cart: Cart) => {
    const georestrictedBrands = getGeorestrictedBrands(config);
    return cart.products.filter(product => {
        return georestrictedBrands.some(brand => brand.toUpperCase() === product.brandName?.toUpperCase());
    });
};

export const getGeorestrictedProducts = (config: Config, cart: Cart, includeDropshipRestrictions: boolean, includeBrandRestrictions: boolean) => {
    let restrictedProducts: Product[] = [];
    if (includeDropshipRestrictions) restrictedProducts = restrictedProducts.concat(getDropshipProducts(cart));
    if (includeBrandRestrictions) restrictedProducts = restrictedProducts.concat(getProductsGeorestrictedByBrand(config, cart));

    // Remove duplications of products both anatwine and brand-restricted.
    const uniqueSkus: string[] = [];
    const finalList = restrictedProducts.filter(product => {
        const isDuplicate = uniqueSkus.includes(product.SKU);
        if (!isDuplicate) {
            uniqueSkus.push(product.SKU);
            return true;
        }
        return false;
    });
    return finalList;
};

/**
 * Create an Order object from data vailable locally in Redux.
 * It might not be possible to load the order object from Platform (eg. guest users for which we can't get an OAuth token mandatory for a GET Order call), in which case this function can be used
 * as fallback.
 * @param cart state
 * @param delivery state
 * @param billing state
 * @param customer state
 * @returns Order object
 */
export const getOrderDetailsFromState = (cart: Cart, delivery: Delivery, billing: Billing, customer: Customer): Order => {
    let order: Order = {
        ID: billing.payment.orderClientID,
        products: cart.products,
        deliveryAddress: delivery.address,
        billingAddress: billing.address,
        customer: customer,
        deliveryMethod: {name: delivery.selectedMethod.displayName},
        paymentMethod: '',
        // values kept here for consistency with the Order type, but unused on the confirmation page
        invoiceID: '',
        status: billing.payment.status,
        isDiscounted: cart.products.some(product => product.isDiscounted),
        estimatedDeliveryDate: {hasRange: false},
        channel: cart.channel,
        paymentDetails: {
            gateway: '',
            method: billing.selectedPaymentMethod.name,
            isCaptured: false,
            amountCaptured: {amount: '0.00', currency: 'GBP'},
        },
        productsSubtotal: cart.productsSubtotal,
        deliveryTotal: cart.deliverySubtotal,
        giftcardsSubtotal: cart.giftcardsSubtotal,
        total: cart.total,
        grandTotal: cart.balanceToPay,
        balanceToPay: cart.balanceToPay,
        totalSavings: {amount: '0.00', currency: 'GBP'},
        appliedDiscountCodes: cart.totalDiscount,
        isDeliverToStore: delivery.selectedMethod.typeCategory === 'clickAndCollect',
        selectedDeliveryDate: null,
        selectedDeliveryTimeSlotStart: null,
        selectedDeliveryTimeSlotEnd: null,
        hasFailed: false,
        orderShippingType: '',
        orderShippingProvider: ''
    };

    const selectedDeliveryMethod = getSelectedDeliveryMethod(delivery);
    const selectedDeliveryMethodSlot = getDeliverySlotByMethodId(delivery, selectedDeliveryMethod.ID, {type: 'selected'});
    if (selectedDeliveryMethodSlot) {
        const deliverySlotData = {
            selectedDeliveryDate: {
                date: selectedDeliveryMethodSlot.date,
                timezone_type: 0,
                timezone: '',
            },
            selectedDeliveryTimeSlotStart: selectedDeliveryMethodSlot.start,
            selectedDeliveryTimeSlotEnd: selectedDeliveryMethodSlot.end,
        };
        order = {
            ...order,
            ...deliverySlotData
        };
    }

    return order;
};

/**
 * Check if the cart has Anatwine products
 * @param state
 * @returns true if at least 1 product has isDropship === true.
 */
export const getHasDropshipProducts = (state: Cart) => {
    const products = getProducts(state);
    return products.some(product => product.stockPool?.isDropship === true);
};

/**
 * Check if the cart has unavailable products purchase through gift card
 * @param state
 * @returns true if at least 1 product has status === UNAVAILABLE FOR GIFT CARDS.
 */
export const getHasGiftCardUnavailableProducts = (state: Cart) => {
    const products = getProducts(state);
    return products.some(product => product.status === 'UNAVAILABLE FOR GIFT CARDS');
};

/**
 * Check if the cart has multiple gift card products
 * @param cart state
 * @param config state
 * @returns gift card product count
 */
export const getEgiftcardProductCount = (cartState: Cart, configState: Config) => {
    const giftcardSku = getGiftCardSkuDetails(configState);
    let giftCardsProductCount = 0;

    getProducts(cartState).forEach(item => {
        if (item.trackingSKU === giftcardSku.eGiftcardSku) {
            giftCardsProductCount++;
        }
    });
    return giftCardsProductCount;
};

/**
 * Check if the cart has gift card products
 * @param cart state
 * @param config state
 * @returns gift card scheduled details if gift card product is added in basket
 */
export const getEgiftcardDetails = (cartState: Cart, configState: Config, translationState: Translation) => {
    const localisation = getLocalisation(configState);
    const giftcardSku = getGiftCardSkuDetails(configState);
    const giftCardsProductCount = getEgiftcardProductCount(cartState, configState);
    const egiftcardDetails: GiftCardScheduleDetails[] = [];

    getProducts(cartState).forEach(item => {
        if(item.trackingSKU === giftcardSku.eGiftcardSku) {
            const scheduledDelivery = item.cartProductNotification?.scheduledDelivery as string;
            const formateDate = formatScheduledDate(scheduledDelivery, localisation.language.code);
            const formatedTime = localisedTime(scheduledDelivery, localisation.language.code);
            const amount: string = giftCardsProductCount > 1 ? item.optionValues.Amount + ' ' : '';
            const eGiftCardInformationMessage = translate(
                translationState,
                'eGiftCardInformationMessage',
                `Your ${amount} eGift Card will be delivered via ${item.cartProductNotification?.deliveryType} on ${formateDate}, at ${formatedTime}. There is no charge for eDelivery.`,
                {
                    moneyAmount: amount,
                    deliveryType: item.cartProductNotification?.deliveryType ?? '',
                    date: formateDate,
                    time: formatedTime,
                },
            );

            egiftcardDetails.push({
                deliveryType: item.cartProductNotification?.deliveryType as string,
                scheduledDelivery: scheduledDelivery,
                informationMessage: eGiftCardInformationMessage
            });
        }
    });
    return egiftcardDetails;
}


/**
 * Group the cart products into fulfilment groups. A group correspond to a stockpool and has fulfillment messages, a logo and one or more products.
 * @param state
 * @returns Array of fulfilment objects.
 */
export const getFulfilmentGroups = (state: Cart) => {
    const products = getProducts(state);
    const uniqueStockPoolIDs = Array.from(new Set(products.map(product => product.stockPool?.ID)));

    const fulfilmentGroups = [] as FulfilmentGroup[];
    uniqueStockPoolIDs.forEach(stockpoolId => {
        const productsFromStockpool = products.filter(product => product.stockPool?.ID === stockpoolId);
        fulfilmentGroups.push({
            ID: stockpoolId ?? '',
            isDropShip: productsFromStockpool[0].stockPool?.isDropship ?? false,
            fulfilmentMessage: productsFromStockpool[0].fulfilment?.fulfilmentMessage,
            fulfilmentMessage2: productsFromStockpool[0].fulfilment?.fulfilmentMessage2,
            fulfilmentMessageImage: productsFromStockpool[0].fulfilment?.imageURL,
            deliveryMessage: productsFromStockpool[0].fulfilment?.deliveryMessage?.message,
            products: productsFromStockpool,
            informationPage: productsFromStockpool[0].fulfilment?.informationPage?.ID,
        });
    });
    return fulfilmentGroups;
}

// Action Creators
export function updateCart(cart: Cart): CartActionTypes {
    return {
        type: UPDATE,
        cart
    };
}

// Side effects
export function loadCart(cartId: string): AppThunk<Promise<AppResponse<Cart>>> {
    return async dispatch => {
        try {
            const cart = await carts().getCart(cartId);
            dispatch(updateCart(cart.data));
            return cart;

        } catch(e) {
            console.error(`Cart '${cartId}' has no data`, e);
            newRelicData({ actionName: 'cart', function: 'loadCart', message: (e as Error).message });
            return Promise.reject();
        }
    };
}

/**
 * Add the delivery address currently in Redux to the external cart.
 * @param address An address to save. If not provided it is assumed we want the delivery address in Redux.
 */
export function updateExternalCartDeliveryAddress(address?: Address): AppThunk<void> {
    return async (dispatch, getState) => {
        const delivery = getState().delivery;
        const cart = getState().cart;
        const cartId = getCartId(cart);
        const deliveryAddress = address ?? getDeliveryAddress(delivery);
        const api = getApi(getState().config, 'mesh');
        const baseIdUrl = api.url;
        let deliveryAddressId = deliveryAddress.ID;
        const cartPayload = {
            deliveryAddress: {id: `${baseIdUrl}/addresses/${deliveryAddressId}`},
        };

        try {
            if(cartId) {
                await carts().updateCart(cartId, cartPayload);
            }
        }
        catch (error) {
            newRelicData({ actionName: 'cart', function: 'updateExternalCartDeliveryAddress', message: { error:(error as Error)?.message, type: 'unable to update address' }});
            dispatch(updateNotification('address_error'));
            console.error(error);
        }
    }
}

/**
 * Add the customer, billing and delivery addresses to the cart.
 */
export function updateExternalCartAddressesAndCustomer(): AppThunk<void> {
    return async (dispatch, getState) => {
        const delivery = getState().delivery;
        const cartId = getCartId(getState().cart);
        const customer = getCustomerState(getState().customer);
        const customerId = getCustomerID(getState().customer);
        const deliveryOption = getSelectedDeliveryOption(delivery).value;
        const deliveryAddress = getDeliveryAddress(delivery);
        const collectionDetails = getCollectionDetails(delivery);
        const billingAddress = getBillingAddress(getState().billing);
        const api = getApi(getState().config, 'mesh');

        // Update missing customer name from delivery details if needed
        if (!customer.firstName || !customer.lastName) {
            let customerUpdates: {[key: string]: any} | null = null;
            if (deliveryOption === 'home' && deliveryAddress.firstName && deliveryAddress.lastName) {
                customerUpdates = {
                    firstName: deliveryAddress.firstName,
                    lastName: deliveryAddress.lastName,
                    phone: customer.phone || deliveryAddress.phone || '',
                };
            }
            else if (deliveryOption === 'clickAndCollect' && collectionDetails.firstName && collectionDetails.lastName) {
                customerUpdates = {
                    firstName: collectionDetails.firstName,
                    lastName: collectionDetails.lastName,
                    phone: customer.phone || collectionDetails.phone || '',
                };
            }
            
            if (customerUpdates) {
                const updateCustomerResponse = await customers().patchCustomer(customerId, customerUpdates);
                if (updateCustomerResponse) {
                    dispatch(updateCustomer(updateCustomerResponse.data));
                }
            }
        }

        // Delivery method time slot if applicable
        const deliveryMethod = getSelectedDeliveryMethod(delivery);
        const timeSlot = getDeliverySlotByMethodId(delivery, deliveryMethod.ID, {type: 'selected'});
        const timeSlotPayload = {
            deliveryMethodID: deliveryMethod.ID,
            date: timeSlot?.date,
            timeSlot: {
                start: timeSlot?.start,
                end: timeSlot?.end,
            },
        };


        let newCart = null;
        let  cartPayload = null;
        const baseIdUrl = api.url;

        if (deliveryOption === 'home') {
            let billingAddressId = billingAddress.ID;
            let deliveryAddressId = deliveryAddress.ID;
            if (!billingAddressId && deliveryAddress.isDefaultBillingAddress) {
                billingAddressId = deliveryAddressId;
            }

            cartPayload = {
                customer: {id: `${baseIdUrl}/customers/${customerId}`},
                billingAddress: billingAddressId === '' ? undefined : {id: `${baseIdUrl}/addresses/${billingAddressId}`},
                deliveryAddress: deliveryAddressId === '' ? undefined : {id: `${baseIdUrl}/addresses/${deliveryAddressId}`},
                delivery: timeSlot ? timeSlotPayload : undefined,
            };
        }
        else { // deliveryOption === 'clickAndCollect'
            let billingAddressId = billingAddress.ID;
            //Get the selected delivery store.
            const selectedStore = getSelectedStore(getState().delivery);

            cartPayload = {
                customer: {id: `${baseIdUrl}/customers/${customerId}`},
                billingAddress: !billingAddressId ? undefined : {id: `${baseIdUrl}/addresses/${billingAddressId}`},
            };

            //If the C&C location isn't a JD store, we need to add the Address ID (returned from when we saved the address in platform)
            if (selectedStore?.ID && selectedStore.locationType !== CandCLocation.JD) {
                cartPayload = {...cartPayload, ...{deliveryAddress: {id: `${baseIdUrl}/addresses/${selectedStore.ID}`}}};
            }
        }
        
        newCart = await carts()
            .updateCart(cartId, cartPayload)
            .catch( error => {
                console.error('Error saving cart', error);
                if (error instanceof Error && error?.message === 'GEORESTRICTED_DESTINATION') {
                    const dialogData = {
                        removeDropshipProducts: true,
                        removeRestrictedProducts: true,
                    };
                    dispatch(updateDialog('remove_georestricted_products_dialog', dialogData));
                    throw Error(error?.message);
                }
            });
        if (newCart) dispatch(updateCart(newCart.data));
    };
}

/**
 * Add the customer to the cart.
 */
export function updateCustomerToCart(): AppThunk<void> {
    return async (dispatch, getState) => {
        const cartId = getCartId(getState().cart);
        const customerId = getCustomerID(getState().customer);
        const email = getEmail(getState().customer);
        const formattedEmail = email.slice(email.indexOf('@') - 4);
        const api = getApi(getState().config, 'mesh');        
        let newCart = null;
        let  cartPayload = null;
        const baseIdUrl = api.url;
        cartPayload = {
            customer: {id: `${baseIdUrl}/customers/${customerId}`}
        };
        newRelicData({ actionName: 'cart', function: 'updateCustomerToCart', message: 'customer ID before updating to cart', customerID:customerId, customerEmail: formattedEmail });
        newCart = await carts()
            .updateCart(cartId, cartPayload)
            .catch(error => console.error('Error saving cart', error));
        if (newCart) dispatch(updateCart(newCart.data));
    };
}

/**
 * Check that the selected Click and Collect Method is available
 */

export function updateDeliveryMethodAvailability(methodID: string, proposedLocale: string): AppThunk<Promise<boolean>> {
	return async (dispatch, getState) => {
        const cartID = getCartId(getState().cart);
		try {
                const deliveryMethodAvailability = await carts().getCartDeliveryMethodAvailability(cartID, proposedLocale, methodID);
                if (deliveryMethodAvailability.data?.available) {
                    const stores = deliveryMethodAvailability?.data?.stores;
                    if (stores !== undefined) {
                        dispatch(updateStores(stores, false, stores.length)); 
                    } 
                    dispatch(updatePostOffices([]));
                }            
                return (deliveryMethodAvailability.data?.reason && deliveryMethodAvailability.data?.reason.indexOf('Exception: No Latlong established from google') > 0) ? true : false;			
        }
        catch (error) {
            console.error('Error saving cart', error);
            return false;
        }
	}
}

/**
 * Add a delivery method to the cart of the external service. Update the Redux cart object accordingly.
 * This also updates Redux with the selected delivery method.
 */
export function updateExternalCartDeliveryMethod(methodID: string, proposedLocale?: string): AppThunk<void> {
    return async (dispatch, getState) => {
        const delivery = getState().delivery;
        const cartID = getCartId(getState().cart);
        const selectedStore = getSelectedStore(delivery);
        const collectionDetails = getCollectionDetails(delivery);
        const deliveryMethods = getDeliveryMethods(delivery);
        const selectedDeliveryOption = getSelectedDeliveryOption(delivery);
        const isClickAndCollect = selectedDeliveryOption.value === 'clickAndCollect';
        const isMethodAllowed = deliveryMethods.some(method => method.ID === methodID);
        
        // If the method we want to select is not part of the delivery method list, we do nothing.
        if (!isMethodAllowed) return;
        
        const payload: any = {delivery: {deliveryMethodID: methodID}};
        
        if (selectedStore.ID) {
            /*
                If it's a C&C to a JD store, we need to save the store ID to the cart.
                For any other C&C location, we will add the delivery address to the cart later (on submit).
            */
            if (selectedStore.locationType === CandCLocation.JD) payload.delivery.storeID = selectedStore.ID;

            if (collectionDetails.firstName && collectionDetails.lastName) {
                payload.delivery.nominatedCollector = {
                    firstName: collectionDetails.firstName,
                    lastName: collectionDetails.lastName,
                    phone: collectionDetails.phone,
                }
            }
        }
        if(proposedLocale) {
            payload.proposedDeliveryLocale = proposedLocale
        }

        try {
            if ((selectedStore.ID && isClickAndCollect) || (!isClickAndCollect)) {
                const newCart = await carts().updateCart(cartID, payload);
                if (newCart) dispatch(updateCart(newCart.data));
            }
            dispatch(selectDeliveryMethodByIdAndTrack(methodID));
        }
        catch (error) {
            console.error('Error saving cart', error)
        }
    };
}

export function removeExternalCartDeliveryMethod(): AppThunk<void> {
    return async (dispatch, getState) => {
        const cartID = getCartId(getState().cart);
        const payload : CartUpdates = {delivery: null};

        const newCart =await carts()
            .updateCart(cartID, payload)
            .catch(error => { console.error('Error saving cart', error) });

        if (newCart) dispatch(updateCart(newCart.data));
        dispatch(selectMethod(''));
    };
}

export function removeGiftCard(cardNumber: string): AppThunk<void> {
    return async(dispatch, getState) => {
        const cartID = getCartId(getState().cart);
        const currentGiftCards : GiftCard[] = getGiftCards(getState().cart) ?? [];

        
        //Filter out the giftcard that matches the specified cardNumber
        const filteredGiftCards = currentGiftCards.filter(card => {
            return card.cardNumber !== cardNumber
        });

        //If filteredGiftCards is shorter than currentGiftCards, a card has been removed and we need to perform an update.
        if (filteredGiftCards.length < currentGiftCards.length) {
            const updates: CartUpdates = {
                giftcards: filteredGiftCards,
            }; 

            const newCart = await carts()
            .updateCart(cartID, updates)
            .catch(error => {console.error('Error removing gift card', error)});

            if (newCart) dispatch(updateCart(newCart.data));
        }
    };
}

export function makeZeroPayment(): AppThunk<void> {
    return async(dispatch, getState) => {
        const cartID = getCartId(getState().cart);
        try {
            const zeroPaymentResponse = await carts().makeZeroPayment(cartID);
            dispatch(updatePayment(
                zeroPaymentResponse.data.orderClientID,
                'PAID',
                zeroPaymentResponse.data.isPaid,
                zeroPaymentResponse.data.orderID, // this 'orderID' is actually an invoiceID
            ));
        }
        catch (error) {
            console.error('Error while paying with gift card', error)

            dispatch(updateNotification('error_zero_payment'));
        }
    };
}

/**
 * Add a gift card to the card
 * 
 * @param cardNumber 
 * @param pin 
 */
export function addGiftCard(cardNumber: string, pin: string): AppThunk<void> {
    return async(dispatch, getState) => {
        const cartID = getCartId(getState().cart);
        const cartGiftCards = getGiftCards(getState().cart);

        //Check whether giftCards is undefined and handle it here.
        var giftCards: GiftCard[] = (cartGiftCards === undefined) ? [] as GiftCard[] : Object.assign([], cartGiftCards);

        const newGiftCard: GiftCard = {
            cardNumber,
            pin
        }

        //Add the new gift card to the gift cards that get added to the cart
        giftCards.push(newGiftCard)

        //Create an updates object with the gift card
        const updates: CartUpdates = {
            giftcards: giftCards,
        };

        try {
            const newCart = await carts().updateCart(cartID, updates);
            if (newCart) dispatch(updateCart(newCart.data));
            dispatch(updateGiftCardError(false));
        }
        catch (error) {
            console.error('Error adding gift card', error)
            dispatch(updateGiftCardError(true));
        }
    };
}

/**
 * Delete all the given products from the external cart, then reload the updated cart.
 */
export function deleteProductsFromCart(products: Product[]): AppThunk<Promise<Cart|undefined>> {
    return async (dispatch, getState) => {
        const session = getSession(getState().session);
        const cartID = getCartId(getState().cart);
        const skus = products.map(product => product.SKU);

        try {
            // Remove anatwine products from the cart
            const deleteRequests = skus.map(sku => carts().deleteProductFromCart(cartID, sku));
            await Promise.all(deleteRequests);
            // Reload the cart and update the redux state.
            const newCart = await dispatch(loadCart(cartID));
            await dispatch(putSession({
                ...session,
                commands: {
                    ...session.commands,
                    invalidateCartCache: true,
                },
            }));
            return newCart.data;
        }
        catch (error) {
            dispatch(updateNotification('delete_product_from_cart_error'));
        }
    };
}

/**
 * clear cart cache and then reload the updated cart.
 */
 export function reloadCartContents(): AppThunk<void> {
    return async (dispatch, getState) => {
        const session = getSession(getState().session);
        const cartID = getCartId(getState().cart);
        try {
            // Reload the cart and update the redux state.
            await dispatch(loadCart(cartID));
            await dispatch(putSession({
                ...session,
                commands: {
                    ...session.commands,
                    invalidateCartCache: true,
                },
            }))
        }
        catch (error) {
            dispatch(updateNotification('cart_load_error'));
        }
    };
}


