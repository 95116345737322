import merge from 'deepmerge';
import defaultTheme, { CustomTheme } from '.';
import {
    SecurityLock,
    TickFilledChoice,
    TickEmptyChoice,
    TickFilledTessuti,
    ArrowTessuti,
    ArrowDisabledTessuti, 
} from '../components/Icon/index';
import './index';

const theme: CustomTheme = {
    config: {

        displayName: "Tessuti",

        titleFontWeight: 'bold',

        appBackground: 'white',

        stepFontSize: 'large',
        stepFontWeight: 'bold',
        stepSeparatorHorizontalMargin: '16px',
        stepSeparatorHeight: '16px',
        stepSeparatorHeightMobile: '1px',
        stepSeparatorColour: 'light-2',
        stepSeparatorMargins: {
            vertical: 'large',
            horizontal: 'medium'
        },
        textTransform: 'uppercase',

        headerLogo: '/Logo-Tessuti-Navigation.png',
        headerLogoSize: {height: '18px'},
        headerShowLogo: true,
        headerBackgroundColor: '#252525',
        headerIconColor: 'white',
        headerHeight: '64px',
        headerTitleFontSize: 'large',
        headerLogoContainerMinWidth: '75px',

        imageListBorder: true,

        summaryStepShowSeparator: true,
        summaryShowTotalsTitle: true,
        summaryTotalFontSize: 'large',
        summarySeparatorIsAbove: true,
        summarySeparatorMargins: {
            top: '14px',
            bottom: 'medium'
        },
        
        icons: {
            tickIcon: TickFilledChoice,
            untickIcon: TickEmptyChoice,
            securityIcon: SecurityLock,
            successTickIcon: TickFilledTessuti,
            arrowIcon: ArrowTessuti,
            arrowDisabledIcon: ArrowDisabledTessuti,
        },

        fontSrc: 'https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600',
        requiredFieldStarColour: 'green',
        checkoutSecurelyButtonColor: '#d5581a',
        showCheckoutIcon: true,
        addColorToDiscounts: true,
    },

    global: {
        font: {
            family: 'Montserrat, sans-serif',
        },
        colors: {
            brand: 'black',
            red: {
                dark: '#B10000',
                light: '#B10000'
            },
            green: {
                dark: '#5B9E76',
                light: '#5B9E76'
            },
        },
    },

    button: {
        border: {
            radius: '0px'
        },
        extend: 'height: 48px; font-weight: bold',
    },

    checkBox: {
        check: {
            radius: '0px',
            thickness: '1px',
            extend: (props) => {
                return props.checked ? `background: white; border-color: #e7e8e9; box-shadow: none !important;` : `box-shadow: none !important;`;
            }
        },
        icons: {
            checked: TickFilledChoice,
        }
    },

    radioButton: {
        font: {
            weight: 'bold',
        }
    },

    tab: {
        border: {
            active: {
                color: 'brand'
            },
            hover: {
                color: 'brand'
            }
        },
    },

    formField: {
        error: {
            color: 'red',
        },
        border: {
            error: {
                color: 'red',
            }
        },
        // @ts-ignore
        extend: (props) => {
            return props.className === 'valid' ? `div { border-color: #5B9E76; }` : '';
        },
    },
};

const tessutiTheme: CustomTheme = merge(defaultTheme, theme);

export default tessutiTheme;