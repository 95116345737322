import React, { useEffect, useState }  from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { local as browserLocalStorage } from 'store2';
import { AppThunkDispatch } from '../../modules/thunk';
import LoadingIcon from '../LoadingIcon/LoadingIcon';
import { loadCart } from '../../modules/cart';
import { updateAuthToken, getCustomerIP, loadCustomerIP, updateClickAndCollect, getCustomerPreferences } from '../../modules/customer';
import { RootState } from '../../store/reducers';


import { Box, Text} from "grommet";
import {
    loadBillingCountries, getBillingCountries,
    loadPaymentMethods, selectPaymentMethodAndTrack,
    updateIsOnExpressJourney, getIsOnExpressJourney,
} from "../../modules/billing";
import { loadDeliveryCountries, getDeliveryCountries, selectOption, loadPreferredCAndCDetails } from '../../modules/delivery';
import { loadCustomer, setDeliveryMethods } from '../../modules/customer';
import { getSession, loadSession, putSession, updateIsServiceAgent } from '../../modules/session';
import { setupConfig } from '../../modules/config';
import { BillingCountry } from "../../modules/billing/types";
import {DeliveryCountry, StoreCoordinates} from "../../modules/delivery/types";
import { Customer, CustomerPreferences } from "../../modules/customer/types";
import { Cart } from "../../modules/cart/types";
import { useQuery } from '../../lib/url/url';
import localStore from "../../lib/localStore/localStore";
import { AppResponse } from '../../services/response';
import { carts, customers, initServices } from '../../services';
import { Session } from '../../modules/session/types';
import { updateDialog } from '../../modules/notification';
import { loadTranslationPhrases, translate } from '../../modules/translation';
import { v4 as uuid } from "uuid";
import newRelicData from "../../newrelic";
/**
 * StartUp is the first view a user will see when migrating to the app
 * this checks the required data has been provided and does some pre-loading
 * 
 */
function StartUp() {

    const history = useHistory();
    const query = useQuery();
    const cart = useSelector((state: RootState) => state.cart);
    const customer = useSelector((state: RootState) => state.customer);
    const translation = useSelector((state: RootState) => state.translation);
    const deliveryCountries = useSelector((state: RootState) => getDeliveryCountries(state.delivery));
    const billingCountries = useSelector((state: RootState) => getBillingCountries(state.billing));
    const isOnExpressJourney = useSelector((state: RootState) => getIsOnExpressJourney(state.billing));
    const config = useSelector((state: RootState) => state.config);
    const dispatch: AppThunkDispatch = useDispatch();
    const [loaded, setLoaded] = useState(false);
    const store = query.get("store") ?? '';
    const channel = query.get("channel") ?? '';
    const host = query.get("host") ?? '';
    const expressPayment = decodeURI(query.get("expressPayment") ?? '');
    const customerIP = getCustomerIP(customer);
    const isServiceAgent = query.get("isServiceAgent") ?? false;
    const customerPreferences = getCustomerPreferences(customer);

    browserLocalStorage.set('store', store);
    browserLocalStorage.set('channel', channel);
    browserLocalStorage.set('host', host);
    browserLocalStorage.set('cartId', query.get('cart'));

    useEffect(() => {

        if(expressPayment) {
            dispatch(updateIsOnExpressJourney(true));
        }

        if (isServiceAgent) dispatch(updateIsServiceAgent(true));

        async function load(){
            
            // try to load from session before falling back to query params.
            let session: Session = {ID: ''};

            try {
                session = await dispatch(loadSession(host));
                newRelicData({ actionName: 'startUp', function: 'load', message: session });
            }
            catch(e){
                newRelicData({ actionName: 'startUp', function: 'load', message: (e as Error).message } );
                console.log(e);
                console.warn('Unable to find session from cookie.')
            }

            const cartId = session.cartID ? session.cartID : query.get("cart");
            if(!cartId) {
                newRelicData({ actionName: 'startUp', function: 'load', message: 'cart id not available' });
                dispatch(updateDialog('session_expired'));
            }
            
            if(session.cartID !== query.get("cart")){
                newRelicData({ actionName: 'startUp', function: 'load', message: `Session cartID (${session.cartID}) not matching with Query cart param (${query.get("cart")})`, cartId: session.cartID});
            }
            
            const customerId = session.customerID ?? query.get("customer");
            customerId && newRelicData({ actionName: 'startUp', function: 'load', message: `customerID from session ${session.customerID}`, customerID: session.customerID});
            browserLocalStorage.set('customerId',customerId);
            
            const auth = session.authToken?.access_token ?? query.get("auth");
            if (auth !== null) {
                const customerAuthData = JSON.parse(atob(auth.split(".")[1]));
                const sessionExpireTime = customerAuthData.exp;
                if(sessionExpireTime * 1000 < new Date().getTime()) {
                    newRelicData({ actionName: 'startUp', function: 'load', message: `session expired for customer id ${session.customerID}` });
                    await dispatch(updateDialog('session_expired'));
                }
            }
            const locale = config.localisation.countryCode.toLowerCase();
            const serviceParams: string | StoreCoordinates  = '';
            if (auth) {
                dispatch(updateAuthToken({access_token: auth}));
            }
            // Re-initialise the OAuth tokens
            localStore.set('auth.access_token', auth || '');
            localStore.set('auth.refresh_token', '');

            const redirectToCheckout = async (cart: Cart, customer: Customer) => {
                browserLocalStorage.set('hasPreference', false);
                if (cart && cart.ID === cartId) {
                    if (expressPayment && loaded) {
                        //set preferences for user logged in from Myaccount
                        if(customerId && customerId != null) {
                            const customerPreferencesResponse = await customers().getCustomerPreferences(customerId);
                            if (Object.keys(customerPreferencesResponse.data).length > 0) {
                                browserLocalStorage.set('hasPreference', true);
                            }
                        }
                        dispatch(selectOption('')); // no preselection between home or C&C
                        await dispatch(loadPaymentMethods(cartId, expressPayment));
                        dispatch(selectPaymentMethodAndTrack(expressPayment));
                        const deliveryMethods = await carts().getCartDeliveryMethods(cartId, serviceParams, locale, true);
                        if (deliveryMethods?.data?.clickAndCollect && deliveryMethods?.data?.clickAndCollect.length <= 0) {
                            await dispatch(updateClickAndCollect(false));
                        }
                        browserLocalStorage.set('uuid', uuid());
                        newRelicData({actionName: 'startUp', function: 'redirectToCheckout', message: 'user redirecting to express checkout page' });
                        history.push('/expresspayment')
                    }
                    else {
                        if (customer.ID) {
                            if(!cart.paid) {
                                if(cart.customer.ID && (cart.customer.ID !== session.customerID)){
                                    newRelicData({actionName: 'startUp', function: 'redirectToCheckout', message: `cart customer id (${cart.customer.ID}) value differs from session customer id (${session.customerID})`, cartId: session.cartID });
                                }
                                if(Object.keys(customerPreferences as CustomerPreferences).length > 0) {
                                    browserLocalStorage.set('hasPreference', true);
                                }
                                browserLocalStorage.set('uuid', uuid());
                                const deliveryMethods = await carts().getCartDeliveryMethods(cartId, serviceParams, locale, true);
                                if (deliveryMethods?.data?.clickAndCollect && deliveryMethods?.data?.clickAndCollect.length <= 0) {
                                    await dispatch(updateClickAndCollect(false));
                                }
                                newRelicData({actionName: 'startUp', function: 'redirectToCheckout', message: 'registered user redirecting to delivery page' });
                                history.push('/delivery');
                            } else {
                                //cart already paid, we remove the cart from the website
                                const sessionState = getSession(session);
                                dispatch(putSession({
                                    ...sessionState,
                                    cartID: '',
                                    commands: {
                                        ...sessionState.commands,
                                        invalidateCartCache: true,
                                    }
                                }));
                                newRelicData({actionName: 'startUp', function: 'redirectToCheckout', message: 'cart already paid, removed cart id from session' });
                                window.location.href = `https://${host}/`;
                            }
                        } else if (!isOnExpressJourney) {
                            newRelicData({actionName: 'startUp', function: 'redirectToCheckout', message: 'guest user redirecting to login page' });
                            // cart loaded
                            history.push('/login')
                        }
                    }
                    
                }
            }
            
            if(cart.paid) {
                //cart already paid, we remove the cart from the website
                const sessionState = getSession(session);
                dispatch(putSession({
                    ...sessionState,
                    cartID: '',
                    commands: {
                        ...sessionState.commands,
                        invalidateCartCache: true,
                    }
                }));
                newRelicData({actionName: 'startUp', function: 'redirectToCheckout', message: 'cart already paid, removed cart id from session' });
                window.location.href = `https://${host}/`;
            } else {
                // if we alreday have the info in state redirect now
                await redirectToCheckout(cart, customer);
                newRelicData({actionName: 'startUp', function: 'redirectToCheckout', message: 'redirecting to checkout' });
            }
            
            const currentState = await dispatch(setupConfig(store, channel, host));

            // now we have config set up we can initialise the services
            initServices(currentState);
            const languageCode = currentState.config.localisation.language.code;
            !Object.keys(translation.phrases).length && dispatch(loadTranslationPhrases(languageCode));

            let actions: (Promise<AppResponse<Customer>>|
                            Promise<AppResponse<Cart>>|
                            Promise<AppResponse<BillingCountry[]>|
                            Promise<AppResponse<DeliveryCountry[]>>>|
                            Promise<void>)[] = [

            ];

            if (!billingCountries.length){
                actions.push(dispatch(loadBillingCountries()))
            }

            if (!deliveryCountries.length){
                actions.push(dispatch(loadDeliveryCountries()))
            }
            
            if (cartId && !cart.ID) {
                actions.push(dispatch(loadCart(cartId)));
            }
            // User data ignored on the express journey as we'll create a new guest user instead
            if (auth && customerId && !customer.ID && !expressPayment){
                let previousActions = [...actions];

                const loadCustomerAndDelivery = async () => {
                    const loadedCustomer = await dispatch(loadCustomer(customerId));
                    const email = loadedCustomer.data.email;
                    const formattedEmail = email.slice(email.indexOf('@') - 4);
                    newRelicData({ actionName: 'startUp', function: 'loadCustomerAndDelivery', message: `registered customer id ${session.customerID}`, customerEmail: formattedEmail, customerID: customerId});
                    await Promise.allSettled(previousActions);
                    if (loadedCustomer) {
                        await dispatch(setDeliveryMethods(loadedCustomer.data));
                    }
                }
                
                actions.push(loadCustomerAndDelivery());
            }

            await Promise.allSettled(actions);

            if (auth && customerId) {
                await dispatch(loadPreferredCAndCDetails());
            }

            setLoaded(true);
        }

        if(customerIP === '') {
            dispatch(loadCustomerIP());
        }
        load();
    // eslint-disable-next-line
    }, [loaded]);

    return (
        <Box
            flex="grow"
            justify="center"
        >
            <Box gap="medium">
                <LoadingIcon color="black" size="50px"/>
                <Text alignSelf="center">{translate(translation, 'redirectingToCheckout', '')}</Text>
            </Box>
        </Box>
    )
}

export default StartUp;