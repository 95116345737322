import { session as store2 } from 'store2';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import clone from 'rfdc';
import { MeshClientConfig } from './types';

let errorResponse: AxiosResponse<MeshError> | undefined;

export type MeshError = {
    error: {
        message: string,
        details: string,
        internalCode?: string,
    },
};

/**
 * @description Creates an Axios instance with some defaults. This can be a single
 * place to set up api keys, global params and base URL's
 * 
 * @param config 
 */
export function createMeshClient(config: MeshClientConfig): AxiosInstance {
    const { baseURL, timeout, headers, globalParams } = config;
    const client = axios.create({
        baseURL,
        timeout,
        headers,
        params: globalParams
    });

    /**
     * The error message is returned in different places
     * dependent on the error
     */
    client.interceptors.response.use(res => res, error => {
        let err;
        if (error) {
            // status 400/500
            if (error.response) {
                errorResponse = error.response;
                
                // if there is a message provide this.
                if (error.response.data.error) {
                    err = error.response.data.error;
                    if (err.internalCode) {
                        throw new Error(err.internalCode);
                    }
                    else if (err.message) {
                        throw new Error(err.message);
                    }
                    else {
                        throw new Error(error.response.data.error);
                    }
                }
                // request made no response received
                else if (error.request) {
                    throw new Error(error.request);
                } else {
                    throw new Error(error);
                }
            }
        }
    });


    // fixes problems with axios and global params
    /**
     *
     * 
     *  The following should be the 'correct' way to set gloabl params
     *  but there are issues with POST and OPTIONS requests this way and 
     *  params are not always added to requests.  
     *
     *  const client = axios.create({
     *       baseURL,
     *      timeout,
     *      headers,
     *      params: globalParams
     *  })
     * 
     * 
     * To get around this for the time being a custom interceptor can add the
     * global params to every request for us. 
     */
    client.interceptors.request.use(config => {
        config.params = {
            // add your default ones
            ...globalParams,
            // spread the request's params
            ...config.params,
        };

        const authToken = store2.get('auth.access_token');
        if (authToken) {
            config.headers = {
                ...config.headers,
                Authorization: `Bearer ${authToken}`,
            };
        }
        return config;
    });

    return client;
}

export function getMeshErrorAndDelete() {
    const err = clone()(errorResponse);
    errorResponse = undefined;
    return err;
}