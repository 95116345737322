import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../store/reducers';
import {
    getClickAndCollectLocations, loadStores, appendStores,
    selectStore, getSelectedStore, loadDeliveryMethods,
    getStoreForDetailedView, selectStoreForDetailedView,
    getShowStoreDetailedView,
    getCollectionDetails, updateCollectionDetails, loadPostOffices, saveClickAndCollectLocation, appendPostoffices
} from '../modules/delivery';
import ClickAndCollect from '../components/ClickAndCollect/ClickAndCollect';
import { CollectionDetails } from '../modules/delivery/types';
import { Coordinates } from '../services/mesh-sdk/carts';


const mapStateToProps = (state : RootState) => {
    return {
        stores: getClickAndCollectLocations(state.delivery),
        selectedStore: getSelectedStore(state.delivery),
        storeForDetailedView: getStoreForDetailedView(state.delivery),
        showStoreDetailedView: getShowStoreDetailedView(state.delivery),
        collectionDetails: getCollectionDetails(state.delivery)
    }
};

const mapDispatchToProps = (
    // TODO: set up proper union type of actions when all actions are set up
    dispatch: ThunkDispatch<any, any, any>
) => {
    return {
        loadStores: (location: string | Coordinates) => dispatch(loadStores(location)),
        appendStores: (location: string | Coordinates, fromIndex = 0) => dispatch(appendStores(location, fromIndex)),
        appendPostOffice: (location: string | Coordinates, fromIndex = 0) => dispatch(appendPostoffices(location, fromIndex)),
        selectStore: (storeId : string) => dispatch(selectStore(storeId)),
        selectStoreForDetailedView: (storeId : string) => dispatch(selectStoreForDetailedView(storeId)),
        updateCollectionDetails: (details: CollectionDetails) => dispatch(updateCollectionDetails(details)),
        loadDeliveryMethods: (storeId: string, locale: string, defaultOptions: boolean) => dispatch(loadDeliveryMethods({storeId}, locale, defaultOptions)),
        loadPostOffices: (location: string | Coordinates) => dispatch(loadPostOffices(location)),
        saveClickAndCollectLocation: (storeId: string) => dispatch(saveClickAndCollectLocation(storeId)),
    };
};

export type ClickAndCollectMapToProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const ClickAndCollectPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(ClickAndCollect)

export default ClickAndCollectPage;
