import { AxiosInstance } from 'axios';
import { AppResponse } from '../response';
import { MeshConfig } from './types';

export interface HostedPaymentResponse {
    invoiceClientID: string,
    invoiceID: string,
    status: string,
    ID: string,
    isPaid: boolean,
    terminalEndPoints: {
        cardEntryURL: string,
        hostedPageURL: string,
        successURL: string,
        failureURL: string,
        timeoutURL: string
    }
}

type hostedPaymentResponse = {
    invoiceClientID: string,
    status: string,
    ID: string,
    isPaid: boolean,
    terminalEndPoints: {
        cardEntryURL: string,
        hostedPageURL: string,
        successURL: string,
        failureURL: string,
        timeoutURL: string
    },
}

function hostedPayment(client: AxiosInstance, meshConfig: MeshConfig) {

    const { channel } = meshConfig;

    /**
     * Send the hosted payment type "PAYPAL" to Hosted Payment endpoint 
     * @param cartId Cart ID
     */
    async function initHostedPaypalPayment(
        cartId: string,
        type: string,
        locale: string,
        terminals: {
            "timeoutURL": string,
            "failureURL": string,
            "successURL": string,
        }): Promise<AppResponse<HostedPaymentResponse>> {
        const body = {
            type,
            terminals,
        };
        const queryParam = {
            params: {
                unavailableForGiftCardStatus: 1,
                debug: 1,
                isCartTakeover: 0,
                locale,
                channel,
            }
        };

        let hostedResponse = await client.post<HostedPaymentResponse>(`/carts/${cartId}/hostedPayment`, body, queryParam)
        return hostedResponse
    }

    async function authoriseHostedPayment(paymentID: string, HostedPaymentPageResult: string): Promise<AppResponse<HostedPaymentResponse>> {
        const body = { HostedPaymentPageResult };
        const queryParam = {
            params: {
                unavailableForGiftCardStatus: 1,
                channel,
                debug: 1,
                isCartTakeover: 0,
            }
        };
        let hostedResponse = await client.post<hostedPaymentResponse>(`/payments/${paymentID}/hostedpaymentresult`, body, queryParam)
            .then((response) => {
                return response;
            }).catch((err) => {
                console.log(err, 'err in auth hosted payment');
                return err;
            });
        return hostedResponse;
    }

    return {
        initHostedPaypalPayment,
        authoriseHostedPayment,
    }

}
export type PaymentService = ReturnType<typeof hostedPayment>;

export default hostedPayment;
