import firebase from 'firebase';
import 'firebase/remote-config';
import { initialise } from '../../lib/firebase/firebase';
import { AppConfig, ConfigService } from '../config';
import { RootState } from '../../store/reducers';


export function configAdaptor() : ConfigService  {
    let remoteConfig: firebase.remoteConfig.RemoteConfig | undefined;
    let firebaseApp: firebase.app.App | undefined; 

    async function getAll(store: string){
        if(!store) {
            console.error('Store not provided to load config');
            return {};
        }
        if (!firebaseApp) {
            firebaseApp = initialise(store);
        }

        let init = await checkConfigInitialised();

        if (init){
            let config = remoteConfig?.getAll();
            if(config) {
                return convertFirebaseConfigToAppConfig(remoteConfig, config);
            } else {
                throw new Error('Unabled to load configuration.')
            }
        } else {
            throw new Error('Unabled to load configuration.')
        }
    }

    async function checkConfigInitialised() : Promise<boolean> {
        let config: boolean | undefined = false; 
        try {
            remoteConfig = firebaseApp?.remoteConfig();
            await remoteConfig?.fetchAndActivate();
            // this looks off, but as long as fetchAndActivate
            // doesn't throw an error we're good and want this to be true
            config = true;
        } catch (e){
            // if throttled try again
            console.error(e);
        }
        return !!config;
    }

    return {
        getAll
    }
}

function firebaseAdaptor(state: RootState){
    return {
        config: configAdaptor()
    }
}

export function convertFirebaseConfigToAppConfig(remoteConfig: firebase.remoteConfig.RemoteConfig | undefined, config: { [key: string]: firebase.remoteConfig.Value }): AppConfig {
    let appConfig: AppConfig = {};
    Object.keys(config).forEach((key) => {
        if (key.includes("web_")) {
            let appFriendlyName = key.replace('web_', '');
            appConfig[appFriendlyName] = checkForJSON(remoteConfig?.getValue(key));
        }
    })
    return appConfig;
}

function checkForJSON(value: firebase.remoteConfig.Value | undefined) {
    if (!value) {
        return value;
    }
    try {
        const parsedJSON = JSON.parse(value.asString());
        return parsedJSON;
    } catch (e) {
        return value;
    }

}

export default firebaseAdaptor;