import { Cart, Product, PaymentServiceCart } from '../../modules/cart/types';
import { Customer, Address } from '../../modules/customer/types';
import { Store } from '../../modules/delivery/types';
import { DefaultApi as MeshPaymentsClient } from '../payment-sdk';
import { PaymentUpdate } from '../payment-sdk/models';
import { createResponse } from '../response';
import { PaymentMeshPaymentsService } from '../payments';
import { RootState } from '../../store/reducers';
import { AdyenPaymentMethodsResponse, PaymentLineItem } from '../payments/adyenHelper/types';
import { KlarnaExpressPaymentMethodsResponse } from '../payments/klarnaHelper/types';
import { OpenpayPaymentMethodsResponse } from '../payments/openpay/Openpay';
import { ShopBackPaymentMethodsResponse } from '../payments/shopback/Shopback';
import { RelyPaymentMethodsResponse } from '../payments/rely/Rely';
import { CreditGuardPaymentMethodsResponse } from '../payments/creditguard/Creditguard';
import { PaypalExpressPaymentMethodsResponse } from '../payments/paypalHelper/types';
import { local as browserLocalStorage } from 'store2';
import newRelicData from '../../newrelic';


export function gatewayAdaptor(client: MeshPaymentsClient, fascia: string, channel: string): PaymentMeshPaymentsService {

    /**
     * Converts an address object from this app into an object expected by the payment service.
     * @param address object from Redux
     * @returns Address to send to the payment service
     */
    function convertAppAddressToPaymentAddress(address: Address) {
        return {
            ID: address.ID ?? '',
            title: '',
            firstName: address.firstName,
            lastName: address.lastName,
            phone: address.phone ?? '',
            mobile: address.phone ?? '',
            address1: address.address1,
            address2: address.address2 ?? '',
            town: address.town,
            county: address.county ?? '',
            country: address.country,
            postcode: address.postcode,
            locale: address.locale,
            coordinates: {
                latitude: 0,
                longitude: 0,
            }
        };
    }

    /**
     * Converts a Store object from this app into an address object expected by the payment service.
     * @param store object from Redux
     * @param phone The customer phone (the store phone is irrelevant in this case)
     * @returns Address to send to the payment service
     */
    function convertStoreToPaymentAddress(store: Store, phone: string) {
        const address = store.address;
        return {
            ID: store.ID ?? '',
            phone: phone ?? '',
            mobile: phone ?? '',
            address1: address.address1,
            address2: address.address2 ?? '',
            town: address.town,
            county: address.county ?? '',
            country: address.country,
            postcode: address.postcode,
            locale: address.locale,
            coordinates: {
                latitude: Number(store.latitude),
                longitude: Number(store.longitude),
            }
        };
    }

    function getPaymentLineItemsFromAppProducts(appCart:Cart) : PaymentLineItem[] {
        const promotionProducts:any = [];
        const allProducts = appCart.products.map(product => {
            //check for promotion group products
            if (product.products) {
                for (let promotionProduct of product.products) {
                    promotionProducts.push(getProductDataforPayment(promotionProduct, true, appCart));
                }
                return {} as PaymentLineItem;
            }
            return getProductDataforPayment(product, false,appCart);
        });
    
        let productsForPaymentMethod = [...allProducts, ...promotionProducts];
        return productsForPaymentMethod.filter(product => product.name);
    }

    function getProductDataforPayment(product:Product, promotionalProduct = false,appCart:Cart) {
        return {
            ID: product.SKU,
            itemID: product.SKU,
            name: product.name,
            quantity: product.quantity,
            unitPrice: {
                amount: promotionalProduct ? product?.subtotal?.amount : product?.unitPrice?.amount,
                currency: product?.unitPrice?.currency,
            },
            discountAmount: product.discountAmount ? [{
                type: appCart.cartDiscounts?.[0]?.title ?? '',
                name: appCart.cartDiscounts?.[0]?.title ?? '',
                amount: {
                    amount: product.discountAmount?.amount ?? '0',
                    currency: product.discountAmount?.currency ?? 'GBP',
                }
            }] : []
        }    
    }

    /**
     * Makes a cart object expected by the payment service from the data available in Redux.
     * @param appCart Cart in Redux
     * @param appCustomer Customer in Redux
     * @param appBilling Billing Address
     * @param appDelivery Delivery Address
     */
    function makePaymentCart(appCart: Cart, appCustomer: Customer, appBilling: Address, appDelivery: Address, selectedStore: Store|null, countryCode: string) {
        const billingAddress = convertAppAddressToPaymentAddress(appBilling);
        
        let shippingAddress;
        if (selectedStore) {
            shippingAddress = convertStoreToPaymentAddress(selectedStore, appCustomer.phone);
        }
        else {
            shippingAddress = convertAppAddressToPaymentAddress(appDelivery);
        }
        return {
            ID: appCart.ID,
            currency: appCart.total.currency,
            balanceToPay: {
                amount: appCart.balanceToPay.amount,
                currency: appCart.balanceToPay.currency,
            },
            customer: {
                ID: appCustomer.ID,
                clientID: appCustomer.ID,
                email: appCustomer.email,
                title: '',
                firstName: appCustomer.firstName,
                lastName: appCustomer.lastName,
                phone: appCustomer.phone,
                mobile: '',
                preferredContactMethod: '',
                isGuest: appCustomer.isGuest,
            },
            billingAddress,
            shippingAddress,
            lines: getPaymentLineItemsFromAppProducts(appCart),
            deliveryMethod: {
                ID: appCart.deliveryMethod?.ID ?? '',
                amount: {
                    amount: appCart.deliverySubtotal?.amount ?? '0',
                    currency: appCart.deliverySubtotal?.currency ?? 'GBP',
                },
                displayName: appCart.deliveryMethod?.displayName ?? '',
                description: appCart.deliveryMethod?.description ?? '',
            },
            locale: countryCode ?? 'gb',
            deductions: appCart.cartDiscounts ? appCart.cartDiscounts.map(discount => {
                return {
                    type: discount.title,
                    name: discount.title,
                    amount: {
                        amount: discount.saving.amount ?? '0',
                        currency: discount.saving.currency ?? 'GBP',
                    },
                }
            }) : [],
        };
    }

    async function getAvailableMethodsForCart<T extends PaymentProvider>(provider: T, cart: Cart, customer: Customer, billingAddress: Address, deliveryAddress: Address, locale: string) {
        const paymentServiceCart: object = makePaymentCart(cart, customer, billingAddress, deliveryAddress, null, locale);
        const uuid = browserLocalStorage.get('uuid');
        const response = await client.getAvailableMethodsForCart(paymentServiceCart, uuid, channel, fascia, provider)
            .catch(error => {throw new Error(error)});
        const { status, data, headers } = response;
        // The type of the return value depends on the 'provider': adyen -> adyenresponse, etc.
        const parsedResponse: PaymentMethodsResponse<T> = data.rawResponse ? JSON.parse(data.rawResponse) : "";
        
        return createResponse(status, parsedResponse, headers);
    }

    async function initPayment(
        isExpress: boolean,
        provider: PaymentProvider,
        method: string,
        merchantReference: string,
        cart: Cart,
        customer: Customer,
        billingAddress: Address,
        deliveryAddress: Address,
        store: Store|null,
        locale: string,
        initPayload: string,
    ) {

        const paymentCart = isExpress ? makePaymentCart(cart, customer, billingAddress, deliveryAddress, store, locale) : { ID: cart.ID };

        const requestBody: object = {
            provider: provider,
            method: method,
            merchantReference,
            type: 'Init',
            cart: paymentCart,
            initPayload,
        }
        const uuid = browserLocalStorage.get('uuid');
        const queryParams: object = (provider === 'paypalexpress' || provider === 'paypal') ? { params : { requiredOrderCreation : true } } : { params : {} };
        newRelicData({ actionName: 'meshPayments', function: 'initPayment', message: 'Before postInitPayment call'});
        const response = await client.postInitPayment(requestBody, uuid, channel, fascia, provider, method, queryParams);
        const { status, data, headers } = response;
        newRelicData({ actionName: 'meshPayments', function: 'initPayment', message: 'After postInitPayment call'});
        return createResponse(status, data, headers);
    }

    async function finalisePayment(provider: PaymentProvider, method: string, gatewayReference: string, result: string, transactionID: string) {

        const requestBody = {
            gatewayReference,
            result
        }

        const uuid = browserLocalStorage.get('uuid');
        newRelicData({ actionName: 'meshPayments', function: 'finalisePayment', message: 'Before postPaymentResult call'});
        const response = await client.postPaymentResult(requestBody, uuid, channel, fascia, provider, method, transactionID);
        const { status, data, headers } = response;
        newRelicData({ actionName: 'meshPayments', function: 'finalisePayment', message: {msg: 'After postPaymentResult call', status: status }});
        return createResponse(status, data, headers);
    }

    async function updatePayment(provider: PaymentProvider, method: string, updatePayload: string, transactionID: string, type: string) {
        const requestBody = {
            provider,
            method,
            updatePayload: updatePayload,
            paymentID: transactionID,
            type,
        } as PaymentUpdate;

        const uuid = browserLocalStorage.get('uuid');
        newRelicData({ actionName: 'meshPayments', function: 'updatePayment', message: 'Before postUpdatePayment call'});
        const response = await client.postUpdatePayment(requestBody, uuid, channel, fascia, provider, method, transactionID);
        const { status, data, headers } = response;
        newRelicData({ actionName: 'meshPayments', function: 'finalisePayment', message: {msg: 'After postUpdatePayment call', status: status }});
        return createResponse(status, data, headers);
    }


    async function prePaymentStockCheck(isExpress: boolean, provider: PaymentProvider, method: string, cart: PaymentServiceCart) {
        const uuid = browserLocalStorage.get("uuid");
        const requestBody = { cart };
        const queryParams = isExpress ? { params: { isExpress } } : { params: {} };
        const response = await client.postPrePaymentCheck(requestBody, uuid, channel, fascia, provider, method, queryParams);
        const { status, data, headers } = response;
        return createResponse(status, data, headers);
    }

    return {
        getAvailableMethodsForCart,
        initPayment,
        finalisePayment,
        updatePayment,
        prePaymentStockCheck,
    }
}



function meshPaymentsAdaptor(state: RootState) {
    const meshPayments = new MeshPaymentsClient();
    const store = state.config.store;
    const channel = state.config.channel;
    return {
        gateway: gatewayAdaptor(meshPayments, store, channel),
    }
}

export default meshPaymentsAdaptor;

export type PaymentProvider = 'adyen'|'klarna'|'klarnaexpress'|'clearpay'|'afterpay'|'openpay'|'laybuy'|'paypal'|'paypalexpress'|'clearpayexpress'|'braintree'|'shopback'|'rely'|'creditguard'|'molpay'|'afterpayexpress'|'wechat';

export type PaymentMethodsResponse<T> = T extends 'adyen' ? AdyenPaymentMethodsResponse
    : T extends 'klarnaexpress' ? KlarnaExpressPaymentMethodsResponse
    : T extends 'shopback' ? ShopBackPaymentMethodsResponse
    : T extends 'openpay' ? OpenpayPaymentMethodsResponse
    : T extends 'paypalexpress' ? PaypalExpressPaymentMethodsResponse
    : T extends 'rely' ? RelyPaymentMethodsResponse
    : T extends 'creditguard' ? CreditGuardPaymentMethodsResponse
    : any;
